import React, {useEffect} from 'react';
import GridItem from "../eGridItem/index";
import "./index.css"
import Swal from 'sweetalert2';
import secureLocalStorage from "react-secure-storage";

const Grid = ({ itens, setItens, Updatestatus, inventory}) => {

  const onDelete = (ID) => {


    if(secureLocalStorage.getItem('acp') !== 'CONSULTOR'){
    if (inventory !== 'S'){
      const newArray = itens.filter((transaction) => transaction.int_id !== ID);
      setItens(newArray);
     // localStorage.setItem("transactions", JSON.stringify(newArray)); 
    }else{
      Swal.fire({
        icon: 'error',
        title: 'ATENÇÃO',
        text: 'Não é possível excluir um item incluido ao cadastro de ativos.',
        confirmButtonColor: "#222d32",
    })
    }
  }


  };

  useEffect(() => {
    Updatestatus(itens);
  }, [itens]);



  return (
    <>
    <div className="listagem-itens">
     <table>
        <tbody>
		      <tr id="grid-header">
            <th className="Title">Codigo produto</th>
            <th className="Title">Descrição do produto</th>
            <th className="Title">ID Produto</th>
            <th className="Title">Quantidade</th>
            <th className="Title">Valor Unit.</th>
            <th className="Title">Ação</th>
          </tr> 
          </tbody>    
          {itens?.map((item, index) => (
            <GridItem key={index} item={item} onDelete={onDelete} Updatestatus={Updatestatus}/>
          ))}
       
	  </table>
    </div>
    </>
  );
};

export default Grid;
