import React, { useState, useEffect } from "react";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import Swal from 'sweetalert2';
import './index.css';
import StepNavigation from "../Step/stepNavigation";
import Star from '../Star'
import ModalPrincipal from '../../components/ModalAlert/modal'
import Loading from '../../components/Loading'
import { AxiosResponse } from "axios";
import api from '../../Services/api'
import secureLocalStorage from "react-secure-storage";


const DetalhamentoTicket = (props: any) => {
  const items: number[] = [...(new Array(5).keys() as any)];

  const labelArray = ['Em Preparação', 'Aguardando Envio', 'Em Campo', 'Retornada de Campo']
  const [currentStep, updateCurrentStep] = useState(1);
  const [activeIndex, setActiveIndex] = useState<number>();

  const [executado, setExecutado] = useState(props.executado);
  const [resolvidat, setResolvidat] = useState("");
  const [bloquear, setbloquear] = useState('Tcontainer');
  const [observacaoValue, setObservacaoValue] = useState("");

  const [openModal, setOpenModal] = useState(false)
  const [qualAlert, setQualAlert] = useState(<></>)
  
  const [descricao, setDescricao] = useState("");

  const [opcoes, setOpcoes] = useState(
    executado !== undefined ?
      (executado === 1 ?
        <span> <input className="input" type="RADIO" name="OPCAO" checked disabled /> SIM
          <input className="input" type="RADIO" name="OPCAO" disabled /> NÃO</span> :
        <span> <input className="input" type="RADIO" name="OPCAO" disabled /> SIM
          <input className="input" type="RADIO" name="OPCAO" checked disabled /> NÃO</span>)
      :
      <span > <input className="input" type="radio" name="OPCAO" value="1" onChange={(e) => { setResolvidat(e.target.value) }} /> SIM
        <input className="input" type="radio" name="OPCAO" value="0" onChange={(e) => { setResolvidat(e.target.value) }} /> NÃO</span>
  );


  useEffect(() => {

    setQualAlert(<div className='div-alert'><Loading /></div>)
    setOpenModal(true)

    visualizarClassif();

    if(props.data.ast_description !== null){
      setDescricao(props.data.ast_description)
    }else{
      setDescricao(props.data.loc_description)
    }

  }, []);

  async function visualizarClassif() {
    let ret: AxiosResponse;

    try {
      ret = await api.get("approvetask/listar", {
        params: {
          id: props.data.tck_id
        }
      })

      if (ret.data.status === 200) {

        setbloquear('Tcontainer2')
        setOpcoes((ret.data.data[0].apt_sortedout === 1 ?
          <span> <input className="input" type="RADIO" name="OPCAO" checked disabled /> SIM
            <input className="input" type="RADIO" name="OPCAO" disabled /> NÃO</span> :
          <span> <input className="input" type="RADIO" name="OPCAO" disabled /> SIM
            <input className="input" type="RADIO" name="OPCAO" checked disabled /> NÃO</span>))

        setResolvidat(ret.data.data[0].apt_sortedout);
        setActiveIndex(Number(ret.data.data[0].apt_classification - 1))
        setObservacaoValue(ret.data.data[0].apt_observation);

        setOpenModal(false)

      } else {
        setOpenModal(false)
      }
    } catch (e) {
      setOpenModal(false)

    }
  }

  function updateStep(step: any) {
    updateCurrentStep(step);
  }


  useEffect(() => {
    setbloquear('Tcontainer')
    if (props.data.situacaotask === 20) {
      updateCurrentStep(1)
    } else if (props.data.situacaotask === 30) {
      updateCurrentStep(2)
    } else if (props.data.situacaotask === 40) {
      updateCurrentStep(3)
    } else if (props.data.situacaotask === 50) {
      updateCurrentStep(4)
    } else if (props.data.situacaotask === 60) {
      updateCurrentStep(5)
    }

  }, []);




  const onClickStar = (index: number) => {
    setActiveIndex((oldState) => (oldState === index ? undefined : index));
  };

  function fecharModal2() {

    setOpenModal(false)

  }

  function fecharModal3() {

    setOpenModal(false)
    props.listarTicketAbertos();
    props.fecharOpenModalIncluirNovo();
  }


  async function ConcluirCLassificacao() {
    let ret: AxiosResponse;

    setQualAlert(<div className='div-alert'><Loading /></div>)
    setOpenModal(true)

    if (resolvidat === "") {

      setQualAlert(<div className='div-alert'>
        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
        <div className='div-info-alert'><span className="info-alert">Selecione SIM ou NÃO para indicar se sua demanda foi resolvida.</span></div>
        <div className='btn-alert' onClick={fecharModal2}><span className="btn-alert-lab">OK</span></div>
      </div>)

    } else if (activeIndex === undefined && resolvidat === '1') {

      setQualAlert(<div className='div-alert'>
        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
        <div className='div-info-alert'><span className="info-alert">Escolha uma das estrelas para sinalizar sua nota para o atendimento de 1 a 5.</span></div>
        <div className='btn-alert' onClick={fecharModal2}><span className="btn-alert-lab">OK</span></div>
      </div>)

    } else if (resolvidat === '1' && activeIndex !== undefined) {

      const dados = [{
        tck_id: props.data.tck_id,
        apt_sortedout: resolvidat,
        apt_classification: Number(activeIndex + 1) || null,
        apt_observation: observacaoValue,
        age_id_insert: secureLocalStorage.getItem('iag'), 
      }]

      try {
        ret = await api.post("approvetask/insert", {
          data: dados
        });

        if (ret.data.status === 200) {

          setQualAlert(<div className='div-alert'>
            <span className="fa-regular fa-circle-check ControleIconAlert"></span>
            <div className='div-info-alert'><span className="info-alert">Classificação realizada com sucesso.</span></div>
            <div className='btn-alert' onClick={fecharModal3}><span className="btn-alert-lab">OK</span></div>
          </div>)


        }
      } catch (e: any) {

        setQualAlert(<div className='div-alert'>
          <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Não foi possível concluir a classificação.</span></div>
          <div className='btn-alert' onClick={fecharModal2}><span className="btn-alert-lab">OK</span></div>
        </div>)


      }

    } else if (resolvidat === '0') {

      let estrelaIndex: any

     if(activeIndex === undefined){
      estrelaIndex = null
     }else{
      estrelaIndex = activeIndex + 1
     }

      const dados = [{
        tck_id: props.data.tck_id,
        apt_sortedout: resolvidat,
        apt_classification: estrelaIndex,
        apt_observation: observacaoValue,
        age_id_insert: secureLocalStorage.getItem('iag')
      }] 

     
      setQualAlert(<div className='div-alert'>
        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
        <div className='div-info-alert-atencao'><span className="info-alert-atencao">Deseja recriar essa solicitação?</span></div>
        <div className='div-info-alert'><span className="info-alert">Você não poderá reverter.</span></div>

        <div className='btn-alert-div'>
          <span className='btn-alert-esc' onClick={() => criarNovaTask(dados)}>
            <span className="btn-alert-lab">SIM</span>
          </span>
          <span className='btn-alert-dir' onClick={fecharModal2}>
            <span className="btn-alert-lab">NÃO</span>
          </span>
        </div>
      </div>)

      setOpenModal(true)

    }

  }

  async function criarNovaTask(dados2: any) {
    let ret: AxiosResponse;
    setQualAlert(<div className='div-alert'><Loading /></div>)
    setOpenModal(true)


    const dados = {
      ctc_id: props.data.ctc_id || null,
      unt_id: props.data.unt_id || null,
      tck_message: props.data.tck_message,
      age_id: secureLocalStorage.getItem('iag'),
      tck_requester: props.data.tck_requester,
      tck_responsible: props.data.tck_responsible, //colocar o usuario 
      tck_telephone: props.data.tck_telephone,
      stn_id: 20,
      ast_id: props.data.ast_id,
      tty_id: props.data.tty_id,
      loc_id: props.data.loc_id || null,
      tsk_userdestination: props.data.tck_userdestination || null,
      tsk_origemid: props.data.tsk_id
    }

    try {
      ret = await api.post("ticket/recriarinsertticket", {
        data: dados,
        data2: dados2
      });

      if (ret.data.status === 200) {



        setQualAlert(<div className='div-alert'>
          <span className="fa-regular fa-circle-check ControleIconAlert"></span>
          <div className='div-info-alert'><span className="info-alert">Chamado recriado com sucesso.</span></div>
          <div className='btn-alert' onClick={fecharModal3}><span className="btn-alert-lab">OK</span></div>
        </div>)




      }
    } catch (e: any) {

      setQualAlert(<div className='div-alert'>
        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
        <div className='div-info-alert'><span className="info-alert">Não foi possível concluir o cadastramento.</span></div>
        <div className='btn-alert' onClick={fecharModal2}><span className="btn-alert-lab">OK</span></div>
      </div>)

      setOpenModal(true)

    }
  }

  return (
    <>
      <div className="cont-ticket">
        <div className="containerStep">
          <StepNavigation labelArray={labelArray} currentStep={currentStep} updateStep={updateStep}></StepNavigation>
        </div>
        <div className="detalhamentoTicket">
          <h1>Ticket: {props.data.tck_id}</h1>
          <h1>ID: {props.data.tsk_id}</h1>
          <h1>Status: {props.data.stn_description}</h1>
          
          <h1>Ativo / Local: {descricao}</h1>
          <h1>data de Abertura: {props.data.tck_datetimeopening.substring(8, 10)}/{props.data.tck_datetimeopening.substring(5, 7)}/{props.data.tck_datetimeopening.substring(0, 4)} {props.data.tck_datetimeopening.substring(11, 16)}</h1>
          <h1>Solicitante: {props.data.tck_requester}</h1>
          <h1>Descrição do Problema: {props.data.tck_message}</h1>
        </div>
      </div>


      {props.data.situacaotask === 20 || props.data.situacaotask === 30 || props.data.situacaotask === 40 || props.data.situacaotask === 60 ? (
        null
      ) : <div className={bloquear} >
        <div className="selecao">
          <h2 className='solicitacao'>Sua demanda foi resolvida?</h2>
          {opcoes}
        </div>

        <div className="selecao-estrela">
          <h2 className='solicitacao'>O QUE VOCÊ ACHOU DO NOSSO ATENDIMENTO?</h2>
          {items.map((index) => (
            <Star
              onClick={() => onClickStar(index)}
              key={`star_${index}`}
              isActive={index <= activeIndex!}
            />
          ))}
        </div>
        <textarea className="textarea-estrela" placeholder="Deixar Seu Comentário"
          onChange={(e) => setObservacaoValue(e.target.value)} value={observacaoValue}>
        </textarea>

        <button className="button-estrela" onClick={ConcluirCLassificacao}>
          Enviar
        </button>
      </div>}

      <ModalPrincipal isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)}>
        <div className='container-modal-alert'>
          {qualAlert}
        </div>
      </ModalPrincipal>
    </>
  );
};

export default DetalhamentoTicket;
