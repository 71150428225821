import React, { useEffect, useState } from 'react'
import Nav from "../../components/Nav";
import Header from "../../components/Header";
import GridPerfilAcesso from '../../components/GridPerfilAcesso'
import api from "../../Services/api";
import { AxiosResponse } from "axios";
import Loading from '../../components/Loading';
import secureLocalStorage from "react-secure-storage";
import ModalAlert from '../../components/ModalAlert/modal'
import ModalIncluirNovo from '../../components/ModalIncluirNovo/modal'
import { useNavigate } from 'react-router';
import { GoPasskeyFill } from "react-icons/go";
import FormPerfilacesso from "../../components/FormPerfilacesso";

function PerfilAcesso() {

  const navigate = useNavigate();

  const [exibirGrid, setExibirGrid] = useState(<></>);
  const [qualGrid, setQualGrid] = useState(<></>)
  const [qualTitulo, setQualTitulo] = useState('')
  const [openModalIncluirNovo, setOpenModalIncluirNovo] = useState(false)
  const [qualAlert, setQualAlert] = useState(<></>)
  const [openModal, setOpenModal] = useState(false)


  useEffect(() => {

    setQualAlert(<div className='div-alert'><Loading /></div>)
    setOpenModal(true)

    validatToken();

  }, []);



  async function validatToken() {

    let ret: AxiosResponse;
    let valor: any;

    const _user = secureLocalStorage.getItem('t')

    api.defaults.headers.common['Authorization'] = _user as ''

    try {

      ret = await api.get("/status/validar")

      if (ret.data.status === 200) {

        secureLocalStorage.setItem('t', ret.data.data);
        secureLocalStorage.setItem('acp', ret.data.decodedToken.acp_integrationid);
        secureLocalStorage.setItem('n', ret.data.decodedToken.name);
        secureLocalStorage.setItem('iag', ret.data.decodedToken.id);
        secureLocalStorage.setItem('cy', ret.data.decodedToken.company);


        setExibirGrid(<GridPerfilAcesso
          th1='Perfil de acesso'
          th2='Identificador Alternativo'
          endPoint='accessprofile/listar'
          consult='N'
          col1='0'
          col2='2'
          col3='3'
          ambiente='%'
          converter="S"
          pag='myrecords'
          buscarmyrecords='myrecords/buscarmyrecords'
          fecharModal={fecharModalToken}
          permitirEditar={permitirEditar}
          visualizar={visualizar}
        />)

        setOpenModal(false)

      }

    } catch (e: any) {

      if (e.response.data.status === 403) {

        setQualAlert(<div className='div-alert'>
          <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
          <div className='btn-alert' onClick={fecharModalToken}><span className="btn-alert-lab">OK</span></div>
        </div>)

        setOpenModal(true)

      }
    }

  }

  function copiar(id: any){

  }

  function visualizar(id: any, descricao: any){
    setQualGrid(<FormPerfilacesso idPerfil={id}/>)
    setQualTitulo(descricao)

    setOpenModalIncluirNovo(true)
  }

  function permitirEditar(){
    setQualAlert(<div className='div-alert'>
    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
    <div className='div-info-alert'><span className="info-alert">Perfis de acesso padrão não podem ser editados.</span></div>
    <div className='btn-alert' onClick={fecharAlert}><span className="btn-alert-lab">OK</span></div>
  </div>)

  setOpenModal(true)
  }

  function fecharAlert(){
    setOpenModal(false)
  }

  function fecharModalToken() {

    secureLocalStorage.removeItem('t')
    secureLocalStorage.removeItem('acp')
    secureLocalStorage.removeItem('n')
    secureLocalStorage.removeItem('iag')
    navigate('/login');
    setOpenModal(false)

  }

  function fecharOpenModalIncluirNovo() {
    setOpenModalIncluirNovo(false)
  }

  return (
    <>
      <Nav />
      <div className="main-content">
        <Header />
        <main>
          <div className='containerPrincipal'>
            <div className='div-titulo-pages'>
              <span className='fa fa-key' />
              <h1 className='div-titulo-pages-text'>Perfis de acesso</h1>
            </div>
            {exibirGrid}
          </div>
        </main>
      </div>


      <ModalIncluirNovo isOpen={openModalIncluirNovo} setModalOpen={() => setOpenModalIncluirNovo(!openModalIncluirNovo)}>
        <>
          <div className='title-modal-incluir'>
            <h1 className="modal-IncluirNovo-title">{qualTitulo}</h1>
            <div onClick={fecharOpenModalIncluirNovo}>
              <button className="fa fa-times icone-modal-IncluirNovo" data-dismiss="modal" aria-label="Close"></button>
            </div>
          </div>

          <div className='scrol-modal'>
            {qualGrid}
          </div>
        </>
      </ModalIncluirNovo>


      <ModalAlert isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)}>
        <div className='container-modal-alert'>
          {qualAlert}
        </div>
      </ModalAlert>

    </>
  );

}

export default PerfilAcesso;