import "./table.scss"; 
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Typography, Box } from "@mui/material";

const List = (props) => {

  return (
    <TableContainer component={Paper} className="table">
      <Table aria-label="simple table" className="table-tab">
        <TableHead>
          <TableRow className="tableHeader">
            <TableCell className="tableCellHeader">{props.title2}</TableCell>
            <TableCell className="tableCellHeader">{props.title3}</TableCell>
            <TableCell className="tableCellHeader">{props.title4}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data.map((row) => (
            <TableRow 
              className="tableRow" 
              key={row.valor1} 
              onClick={() => props.setarValor(row.valor1, row.valor2, row.valor5)}
            >
              <TableCell className="tableCell">{row.valor2}</TableCell>
              <TableCell className="tableCell">{row.valor3}</TableCell>
              <TableCell className="tableCell">R$ {row.valor4}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default List;
