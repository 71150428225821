import React, { useState, useEffect } from 'react'
import './index.css'
import { AxiosResponse } from "axios";
import api from '../../Services/api'
import ModalAlert from '../ModalAlert/modal'
import secureLocalStorage from "react-secure-storage";
import Loading from '../Loading';
import { useNavigate } from 'react-router';
import Select from 'react-select'
import { MdOutlineAddCircle } from 'react-icons/md';
import ModalIncluirNovo from '../../components/ModalIncluirNovo/modal'
import GridSecundario from '../../components/GridSecundario'
import FormSubGrupoPreventiva from '../../components/FormSubGrupoPreventiva'


function FormTipoPreventiva(props: any) {

    const navigate = useNavigate();
    const dadosFab = (sessionStorage.getItem("dadosFab") || '{}');
    let userFab = JSON.parse(dadosFab);
    const [id, setId] = useState(userFab.pvt_id || '')
    const [active, setActive] = useState(userFab.pvt_active != undefined ? Boolean(userFab.pvt_active) : true)
    const [description, setDescription] = useState(userFab.pvt_description || '')
    const [alternativeIdentifier, setAlternativeIdentifier] = useState(userFab.pvt_alternativeidentifier || '')
    const [descriptionIni, setDescriptionIni] = useState(userFab.pvt_description || '')
    const [alternativeIdentifierIni, setAlternativeIdentifierIni] = useState(userFab.pvt_alternativeidentifier || '')
    const [subGrupoPreventiva, setSubGrupoPreventiva] = useState(String(userFab.psg_id || ''))
    const [entidade, setEntidade] = useState(String(userFab.ett_id || ''))

    const [openModal, setOpenModal] = useState(false)
    const [qualAlert, setQualAlert] = useState(<></>)
    const [titulo, setTitulo] = useState('');
    const [qualGrid, setQualGrid] = useState(<></>)

    const [optionsPreventiva, setOptionsPreventiva] = useState([])
    const [optionsSubPreventiva, setOptionsSubPreventiva] = useState([])
    const [optionsEntidade, setOptionsEntidade] = useState([])
    
    const [openModalIncluirNovo, setOpenModalIncluirNovo] = useState(false)

    useEffect(() => {

        validatToken();
    }, []);


    async function validatToken() {

        let ret: AxiosResponse;
        let valor: any;

        const _user = secureLocalStorage.getItem('t')

        api.defaults.headers.common['Authorization'] = _user as ''

        try {

            ret = await api.get("/status/validar")

            if (ret.data.status === 200) {

                secureLocalStorage.setItem('t', ret.data.data);
                secureLocalStorage.setItem('acp', ret.data.decodedToken.acp_integrationid);
                secureLocalStorage.setItem('n', ret.data.decodedToken.name);
                secureLocalStorage.setItem('iag', ret.data.decodedToken.id);
                secureLocalStorage.setItem('cy', ret.data.decodedToken.company);

                GetTipoPreventiva()
                GetSubGrupoPreventiva()
                GetTipoEntidade()

            }
        } catch (e: any) {

            if (e.response.data.status === 403) {

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
                    <div className='btn-alert' onClick={fecharModalToken}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)

            }
        }

    }

    async function GetTipoEntidade() {
        let ret: AxiosResponse;
        let valor: any;

        try {
            ret = await api.get("entitytype/listar")
            if (ret.data.status === 200) {
                valor = ret.data.data;

                const arr = [] as any;
                let result = valor;
                result.map((user: any) => {
                    return arr.push({ value: String(user.ett_id), label: user.ett_description });
                });

                setOptionsEntidade(arr)

            }
        } catch (e) {
            setOptionsEntidade([])

        }
    }


    async function GetSubGrupoPreventiva() {
        let ret: AxiosResponse;
        let valor: any;

        try {
            ret = await api.get("preventivesubgroup/listar")
            if (ret.data.status === 200) {
                valor = ret.data.data;


                const arr = [] as any;
                let result = valor;
                result.map((user: any) => {
                    return arr.push({ value: String(user.psg_id), label: user.psg_description });
                });

                setOptionsSubPreventiva(arr)

            }
        } catch (e) {
            setOptionsSubPreventiva([])

        }
    }

    async function GetTipoPreventiva() {
        let ret: AxiosResponse;
        let valor: any;

        try {
            ret = await api.get("preventivetype/listar")
            if (ret.data.status === 200) {
                valor = ret.data.data;

                setOptionsPreventiva(valor)

            }
        } catch (e) {
            setOptionsPreventiva([])

        }
    }



    function fecharModalToken() {

        secureLocalStorage.removeItem('t')
        secureLocalStorage.removeItem('acp')
        secureLocalStorage.removeItem('n')
        secureLocalStorage.removeItem('iag')
        navigate('/login');
        setOpenModal(false)

    }

    function ConfirmarContrato() {

        if (id === undefined || id === '') {
            insertContrato();
        } else {
            UpdateContrato();
        }
    }


    function fecharModal() {
        setOpenModal(false)
    }

    async function UpdateContrato() {



        const FiltrarDescricao = optionsPreventiva.filter(
            (e: any) => {
                return e.pvt_description.toLowerCase() === description.toLowerCase()
            }
        );

        const FiltrarAlternativeIdentifier = optionsPreventiva.filter(
            (e: any) => {
                return e.pvt_alternativeidentifier.toLowerCase() === alternativeIdentifier.toLowerCase()
            }
        );


        if (descriptionIni.toLowerCase() !== description.toLowerCase() && FiltrarDescricao.length > 0) {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Descrição já está em uso.</span></div>
                <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
            </div>)
            setOpenModal(true)
        } else
            if (description === "") {
                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Digite a descrição.</span></div>
                    <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                </div>)
                setOpenModal(true)
            } else
                if (alternativeIdentifier === "") {
                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Digite o identificador alternativo.</span></div>
                        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                    </div>)
                    setOpenModal(true)
                } else if (alternativeIdentifierIni.toLowerCase() !== alternativeIdentifier.toLowerCase() && FiltrarAlternativeIdentifier.length > 0) {

                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Identificador alternativo já está em uso.</span></div>
                        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                    </div>)
                    setOpenModal(true)
                }else
                if (entidade === "") {
                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Selecione o tipo de entidade.</span></div>
                        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                    </div>)
                    setOpenModal(true)
                }

                else {

                    let ret: AxiosResponse;
                    const dados = {
                        pvt_id: id,
                        pvt_active: active,
                        pvt_description: description,
                        pvt_alternativeidentifier: alternativeIdentifier,
                        psg_id: subGrupoPreventiva || null,
                        ett_id: entidade
                    }

                    try {
                        ret = await api.put("preventivetype/update", {
                            data: dados,
                        });
                        if (ret.data.status === 200) {
                            props.voltar()
                        }
                    } catch (e: any) {
                        setQualAlert(<div className='div-alert'>
                            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                            <div className='div-info-alert'><span className="info-alert">Não foi possível concluir a alteração.</span></div>
                            <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                        </div>)
                        setOpenModal(true)
                    }
                }

    }

    async function insertContrato() {
        let ret: AxiosResponse;


        const FiltrarDescricao = optionsPreventiva.filter(
            (e: any) => {
                return e.pvt_description.toLowerCase() === description.toLowerCase()
            }
        );

        const FiltrarAlternativeIdentifier = optionsPreventiva.filter(
            (e: any) => {
                return e.pvt_alternativeidentifier.toLowerCase() === alternativeIdentifier.toLowerCase()
            }
        );


        if (FiltrarDescricao.length > 0) {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Descrição já está em uso.</span></div>
                <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
            </div>)
            setOpenModal(true)
        } else
            if (description === "") {
                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Digite a descrição.</span></div>
                    <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                </div>)
                setOpenModal(true)
            } else
                if (alternativeIdentifier === "") {
                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Digite o identificador alternativo.</span></div>
                        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                    </div>)
                    setOpenModal(true)
                } else if (FiltrarAlternativeIdentifier.length > 0) {

                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Identificador alternativo já está em uso.</span></div>
                        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                    </div>)
                    setOpenModal(true)
                }else
                if (entidade === "") {
                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Selecione o tipo de entidade.</span></div>
                        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                    </div>)
                    setOpenModal(true)
                }

                else {
                    const dados = [{
                        pvt_active: active,
                        pvt_description: description,
                        pvt_alternativeidentifier: alternativeIdentifier,
                        psg_id: subGrupoPreventiva || null,
                        ett_id: entidade
                    }]

                    try {
                        ret = await api.post("preventivetype/insert", {
                            data: dados,
                        });

                        if (ret.data.status === 200) {
                            props.voltar()
                        }
                    } catch (e: any) {
                        setQualAlert(<div className='div-alert'>
                            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                            <div className='div-info-alert'><span className="info-alert">Não foi possivel concluir o cadastramento.</span></div>
                            <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                        </div>)

                        setOpenModal(true)
                    }
                }
    }

    function ValitarActive() {
        let e = document.getElementById("checkbox-active-fab") as HTMLInputElement;

        if (e.checked) {

            setActive(true)
        } else {

            setActive(false)
        }
    }

    function voltar() {

        props.voltar();

    }

    const customStyles = {
        control: (provided: any, state: any) => ({
            ...provided,
            background: '#fff',
            minHeight: '30px',
            height: '30px',
            borderColor: state.isFocused ? 'grey' : '#92989e',

        }),


        valueContainer: (provided: any, state: any) => ({
            ...provided,
            height: '30px',
            padding: '0 6px'
        }),

        input: (provided: any, state: any) => ({
            ...provided,
            margin: '0px',
            fontSize: 14,

        }),
        indicatorsContainer: (provided: any, state: any) => ({
            ...provided,
            height: '30px',
        }),
    };

    function fecharOpenModalIncluirNovo() {
        setOpenModalIncluirNovo(false)
    }

    function finalizarModal2() {
        setOpenModal(false)
    }

    //------------------------SubgrupoPreventiva-----------------------------------

    function incluirSubgrupoPreventiva() {

        setTitulo('Cadastrar Subgrupo Preventiva')
        setQualGrid((<><GridSecundario
            Iconeadd={ChamarCadastrotipoLocal}
            Iconeditar={EditartipoLocal}
            iconeExcluir={tipoLocalDeletar}
            PegarValorGrid={PegarValorGridtipoLocal}
            th1='Ativo'
            th2='ID'
            th3='Descrição'
            th4='Identificador Alternativo'
            th5=''
            th6=''
            endPoint='preventivesubgroup/buscarpreventivesubgroup'
            consult='N'
            col1='1'
            col2='2'
            col3='3'
            ambiente={'%'}
            pag="ok"
        /></>))

        setOpenModalIncluirNovo(true)

    }
    
    
    function ChamarCadastrotipoLocal() {
        sessionStorage.removeItem("dadosFab");
        setQualGrid(<><FormSubGrupoPreventiva voltar={BtnVoltartipoLocal} /></>)
    }

    async function EditartipoLocal(obj: any) {

        sessionStorage.setItem("dadosFab", JSON.stringify(obj));
        setQualGrid(<><FormSubGrupoPreventiva voltar={BtnVoltartipoLocal} /></>)
    }

    function tipoLocalDeletar(obj: any) {

        setQualAlert(<div className='div-alert'>
            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
            <div className='div-info-alert-atencao'><span className="info-alert-atencao">Deseja deletar o registro?</span></div>
            <div className='div-info-alert'><span className="info-alert">Você não poderá reverter.</span></div>

            <div className='btn-alert-div'>
                <span className='btn-alert-esc' onClick={() => deletartipoLocal(obj.lty_id)}>
                    <span className="btn-alert-lab">SIM</span>
                </span>
                <span className='btn-alert-dir' onClick={finalizarModal2}>
                    <span className="btn-alert-lab">NÃO</span>
                </span>
            </div>
        </div>)

        setOpenModal(true)

    }



    async function deletartipoLocal(id: any) {

        setOpenModal(false)

        setQualGrid(<><Loading /></>)
        let ret: AxiosResponse;
        try {
            ret = await api.delete("preventivesubgroup/delete", {
                data: { id: id }
            });
            if (ret.data.status === 200) {

                setQualGrid((<><GridSecundario
                    Iconeadd={ChamarCadastrotipoLocal}
                    Iconeditar={EditartipoLocal}
                    iconeExcluir={tipoLocalDeletar}
                    PegarValorGrid={PegarValorGridtipoLocal}
                    th1='Ativo'
                    th2='ID'
                    th3='Descrição'
                    th4='Identificador Alternativo'
                    th5=''
                    th6=''
                    endPoint='preventivesubgroup/buscarpreventivesubgroup'
                    consult='N'
                    col1='1'
                    col2='2'
                    col3='3'
                    ambiente={'%'}
                    pag="ok"
                /></>))
                GetSubGrupoPreventiva()

            }
        } catch (e: any) {
            setQualGrid((<><GridSecundario
                Iconeadd={ChamarCadastrotipoLocal}
                Iconeditar={EditartipoLocal}
                iconeExcluir={tipoLocalDeletar}
                PegarValorGrid={PegarValorGridtipoLocal}
                th1='Ativo'
                th2='ID'
                th3='Descrição'
                th4='Identificador Alternativo'
                th5=''
                th6=''
                endPoint='preventivesubgroup/buscarpreventivesubgroup'
                consult='N'
                col1='1'
                col2='2'
                col3='3'
                ambiente={'%'}
                pag="ok"
            /></>))
        }
    }

    function BtnVoltartipoLocal() {

        setQualGrid((<><GridSecundario
            Iconeadd={ChamarCadastrotipoLocal}
            Iconeditar={EditartipoLocal}
            iconeExcluir={tipoLocalDeletar}
            PegarValorGrid={PegarValorGridtipoLocal}
            th1='Ativo'
            th2='ID'
            th3='Descrição'
            th4='Identificador Alternativo'
            th5=''
            th6=''
            endPoint='preventivesubgroup/buscarpreventivesubgroup'
            consult='N'
            col1='1'
            col2='2'
            col3='3'
            ambiente={'%'}
            pag="ok"
        /></>))
        GetSubGrupoPreventiva()
    }

    function PegarValorGridtipoLocal(valor: any) {

        setSubGrupoPreventiva(String(valor.psg_id))
        setOpenModalIncluirNovo(false)

    }



    //-------------------------------------------------ADD CONTRATO


  

    return (
        <>
            <div className="container-modal-pric">
                <div className="class-checkbox">
                    <input type="checkbox" id="checkbox-active-fab" checked={active} onChange={ValitarActive} />
                    <h4 className="lab-ativo">Ativo</h4>
                </div>
                <div className="container-cad-prod4">
                    <div id="content1">
                        <div className="input-box-ativos">
                            <span className="details">Descrição *</span>
                            <input
                                autoComplete="off"
                                type="text"
                                onChange={(e) => setDescription(e.target.value)}
                                value={description} />
                        </div>
                    </div>
                    <div id="content2">
                        <div className="input-box-ativos">
                            <span className="details">Identificador Alternativo *</span>
                            <input
                                autoComplete="off"
                                type="text"
                                onChange={(e) => setAlternativeIdentifier(e.target.value)}
                                value={alternativeIdentifier} />
                        </div>
                    </div>
                </div>


                <div className="container-cad-prod4">
                    <div id="content1">
                        <div className="select-box-ativos-select">

                        <div className='details-flex'>
                                        <span className="details">Subgrupo Preventiva</span>
                                        <MdOutlineAddCircle className='details-flex-icon-ativo' />
                                        <span className="details-esc-ativo" onClick={incluirSubgrupoPreventiva}>Incluir Novo</span>
                                    </div>


                            <Select
                                maxMenuHeight={70}
                                placeholder={'Selecione...'}
                                options={optionsSubPreventiva}
                                styles={customStyles}
                                value={optionsSubPreventiva.filter(function (option: any) {
                                    return option.value === subGrupoPreventiva;
                                })}
                                onChange={(options: any) =>
                                    !options ? setSubGrupoPreventiva("") : setSubGrupoPreventiva(options.value)
                                }

                                isClearable
                                noOptionsMessage={() => "Não encontrado..."}
                                theme={(theme: any) => ({
                                    ...theme,
                                    borderRadius: 4,
                                    colors: {
                                        ...theme.colors,
                                        primary25: '#ebebeb',
                                        primary: 'black',
                                    },
                                })}
                            />
                        </div>
                    </div>

                    <div id="content2">
                        <div className="select-box-ativos-select">
                            <div className='details-flex'>
                                <span className="details">Tipo de Entidade *</span>
                            </div>
                            <Select
                                maxMenuHeight={70}
                                placeholder={'Selecione...'}
                                options={optionsEntidade}
                                styles={customStyles}
                                value={optionsEntidade.filter(function (option: any) {
                                    return option.value === entidade;
                                })}
                                onChange={(options: any) =>
                                    !options ? setEntidade("") : setEntidade(options.value)
                                }

                                isClearable
                                noOptionsMessage={() => "Não encontrado..."}
                                theme={(theme: any) => ({
                                    ...theme,
                                    borderRadius: 4,
                                    colors: {
                                        ...theme.colors,
                                        primary25: '#ebebeb',
                                        primary: 'black',
                                    },
                                })}
                            />
                        </div>
                    </div>


                </div>




                <div className="Bt-button-cad">
                    <button type="submit" onClick={ConfirmarContrato}>
                        <i className="fa fa-check-circle icon-ok-btn"></i> Salvar
                    </button>
                    <button type="submit" onClick={props.voltar}>
                        <i className="fa fa-times icon-cancelar-btn"></i> Cancelar
                    </button>
                </div>

            </div>

            <ModalIncluirNovo isOpen={openModalIncluirNovo} setModalOpen={() => setOpenModalIncluirNovo(!openModalIncluirNovo)}>
                <div className='title-modal-incluir'>
                    <h1 className="modal-IncluirNovo-title">{titulo}</h1>
                    <div onClick={fecharOpenModalIncluirNovo}>
                      <button className="fa fa-times icone-modal-IncluirNovo" data-dismiss="modal" aria-label="Close"/>
                    </div>
                </div>
                <div className='modal-scrol'>

                    {qualGrid}

                </div>
            </ModalIncluirNovo>


            <ModalAlert isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)}>
                <div className='container-modal-alert'>
                    {qualAlert}
                </div>
            </ModalAlert>
        </>
    );

}

export default FormTipoPreventiva;