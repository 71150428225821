import React, { useState, useEffect } from 'react';
import Nav from "../../components/Nav";
import Header from "../../components/Header";
import './index.css';
import Select from 'react-select';
import api from '../../Services/api';
import { AxiosResponse } from "axios";
import { MdOutlineAddCircle } from 'react-icons/md';
import GridSecundario from '../../components/GridSecundario';
import FormTipoEquipe from '../../components/FormTipoEquipe';
import Loading from '../../components/Loading';
import { useNavigate } from 'react-router';
import DualListBox from "react-dual-listbox";
import ModalPrincipal from '../../components/ModalAlert/modal';
import secureLocalStorage from "react-secure-storage";
import 'react-dual-listbox/lib/react-dual-listbox.css';
import ModalIncluirNovo from '../../components/ModalIncluirNovo/modal';
import { useParams } from 'react-router-dom';


interface FormPerfilacessoProps {
    idPerfil: string; // ou `id?: number` se `id` for opcional
}

// Interface para permissões de usuários
interface UserPermission {
    fea_description: string;
    fea_id: number;
    prm_create: number;
    prm_view: number;
    prm_edit: number;
    prm_delete: number;
}

// Componente para exibir categoria de permissões
const PermissionCategory: React.FC<{
    title: string;
    permissions: UserPermission[];
    onPermissionChange: (id: number, field: keyof UserPermission, value: number) => void;
}> = ({ title, permissions, onPermissionChange }) => (
    <fieldset className="div-info-fieldset">
        <legend className="div-info-legend">{title}</legend>
        <div className="div-info-grid-container">
            {permissions.map((permission) => (
                <div key={permission.fea_id} className="div-info-permission-item">
                    <span className="div-info-permission-title">{permission.fea_description}</span>
                    {['prm_create', 'prm_view', 'prm_edit', 'prm_delete'].map((field) => (
                        <label key={field} className="div-info-label">
                            <input
                                disabled
                                type="checkbox"
                                checked={permission[field as keyof UserPermission] === 1}
                                onChange={(e) =>
                                    onPermissionChange(permission.fea_id, field as keyof UserPermission, e.target.checked ? 1 : 0)
                                }
                                className="div-info-checkbox"
                            />
                            {field === 'prm_create' ? 'Incluir / Alterar' : field.charAt(4).toUpperCase() + field.slice(5)}
                        </label>
                    ))}
                </div>
            ))}
        </div>
    </fieldset>
);

const FormPerfilacesso: React.FC<FormPerfilacessoProps> = ({ idPerfil }) => {

    const [qualTitulo, setQualTitulo] = useState('')
    const [qualGrid, setQualGrid] = useState(<></>)

    const [permissionsData, setPermissionsData] = useState<UserPermission[]>([]);
    const [active, setActive] = useState(true);
    const [description, setDescription] = useState('');
    const [integrationid, setIntegrationId] = useState('');
    const [descriptionIni, setDescriptionIni] = useState('');
    const [integrationidIni, setIntegrationIdIni] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [qualAlert, setQualAlert] = useState(<></>);
    const [openModalIncluirNovo, setOpenModalIncluirNovo] = useState(false);

    const [descriptionDisabled, setDescriptionDisabled] = useState(false)
    const [integrationidDisabled, setIntegrationidDisabled] = useState(false)


    const [confirmarDisabled, setConfirmarDisabled] = useState(false)
    const [cancelarDisabled, setCancelarDisabled] = useState(false)
    const [activeDisabled, setActiveDisabled] = useState(false)
    const [optionsPermissions, setOptionsPermissions] = useState([])

    const navigate = useNavigate();
    let { id } = useParams();

    useEffect(() => {

        setQualAlert(<div className='div-alert'><Loading /></div>);
        setOpenModal(true);
        validateToken();
        
    }, []);

    const handlePermissionChange = (id: number, field: keyof UserPermission, value: number) => {
        setPermissionsData((prevPermissions) =>
            prevPermissions.map((permission) =>
                permission.fea_id === id ? { ...permission, [field]: value } : permission
            )
        );
    };

    const validateToken = async () => {
        const _user = secureLocalStorage.getItem('t');
        api.defaults.headers.common['Authorization'] = _user as '';

        try {
            const ret = await api.get("/status/validar");
            if (ret.data.status === 200) {
                secureLocalStorage.setItem('t', ret.data.data);
                await loadPermissions();
                await GetPermissions();
            }
        } catch (e: any) {
            if (e.response?.data?.status === 403) {
                setQualAlert(
                    <div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
                        <div className='btn-alert' onClick={() => navigate('/login')}><span className="btn-alert-lab">OK</span></div>
                    </div>
                );
            }
        }
    };

    const loadPermissions = async () => {

        if (/^\d+$/.test(idPerfil || '')) {
            try {
                const ret = await api.get("permissions/permissionseditar", { params: { id: idPerfil } });
                if (ret.data.status === 200) {
                    const { listardataPermissions, listarAccessprofile } = ret.data.data;

                    setPermissionsData(listardataPermissions);
                    setActive(Boolean(listarAccessprofile[0].acp_active));
                    setDescription(listarAccessprofile[0].acp_description);
                    setIntegrationId(listarAccessprofile[0].acp_integrationid);
                    setDescriptionIni(listarAccessprofile[0].acp_description);
                    setIntegrationIdIni(listarAccessprofile[0].acp_integrationid);

                    setOpenModal(false);

                } else {
                    navigate('/accessprofile/edit/' + idPerfil + '/n');
                    setOpenModal(false);
                }
            } catch {
                navigate('/accessprofile/edit/' + idPerfil + '/n');
                setOpenModal(false);
            }
        } else {
            navigate('/accessprofile/edit/' + idPerfil + '/n');
            setOpenModal(false);
        }
    };

    async function GetPermissions() {
        let ret: AxiosResponse;
        let valor: any;
        try {
            ret = await api.get("permissions/verificacao")
            if (ret.data.status === 200) {
                valor = ret.data.data;

                setOptionsPermissions(valor)

            }
        } catch (e) {
            setOptionsPermissions([])
        }
    }

    function finalizarModal1() {
        navigate('/accessprofile');
    }

    function finalizarModal2() {
        setOpenModal(false)
    }

    function fecharModal() {

        secureLocalStorage.removeItem('t')
        secureLocalStorage.removeItem('acp')
        secureLocalStorage.removeItem('n')
        secureLocalStorage.removeItem('iag')
        navigate('/login');
        setOpenModal(false)

    }

    async function confirmar() {

        setQualAlert(<div className='div-alert'><Loading /></div>)
        setOpenModal(true)

        const Filtrardescription = optionsPermissions.filter(
            (e: any) => {
                return e.acp_description.toLowerCase() === description.toLowerCase()
            }
        );

        const FiltrarIntegrationid = optionsPermissions.filter(
            (e: any) => {
                return e.acp_integrationid.toLowerCase() === integrationid.toLowerCase()
            }
        );

        if (description === '') {
            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Digite a descrição.</span></div>
                <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
            </div>)
        } else
            if (integrationid === '') {
                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Digite o ID para Integração.</span></div>
                    <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                </div>)
            } else if (descriptionIni.toLowerCase() !== description.toLowerCase() && Filtrardescription.length > 0) {

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Descrição já está em uso.</span></div>
                    <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                </div>)


            } else if (integrationidIni.toLowerCase() !== integrationid.toLowerCase() && FiltrarIntegrationid.length > 0) {

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">ID para Integração já está em uso.</span></div>
                    <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                </div>)


            } else {

                const dados = {
                    acp_id: id,
                    acp_active: active,
                    acp_description: description,
                    acp_integrationid: integrationid,
                }

                let ret: AxiosResponse;

                try {
                    ret = await api.put("permissions/update", {
                        dadosagenda: dados,
                        permissionsData: permissionsData
                    });
                    if (ret.data.status === 200) {

                        setQualAlert(<div className='div-alert'>
                            <span className="fa-regular fa-circle-check ControleIconAlert"></span>
                            <div className='div-info-alert'><span className="info-alert">Alteração realizada com sucesso!</span></div>
                            <div className='btn-alert' onClick={finalizarModal1}><span className="btn-alert-lab">OK</span></div>
                        </div>)
                    }
                } catch (e: any) {


                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Não foi possivel concluir o cadastramento.</span></div>
                        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                    </div>)
                }

            }

    }

    function ValitarActive() {
        let e = document.getElementById("checkbox-active") as HTMLInputElement;

        if (e.checked) {

            setActive(true)
        } else {

            setActive(false)
        }
    }

    function cancelar() {
        navigate('/accessprofile');
    }

    function fecharOpenModalIncluirNovo() {
        setOpenModalIncluirNovo(false)
    }

    return (
        <>


                <PermissionCategory
                    title="Permissões"
                    permissions={permissionsData}
                    onPermissionChange={handlePermissionChange}
                />


        </>
    );
};

export default FormPerfilacesso;
