import React, { useEffect, useState } from 'react'
import Nav from "../../components/Nav";
import Header from "../../components/Header";
import GridPrincipal from '../../components/GridPrincipal'
import { AxiosResponse } from "axios";
import api from "../../Services/api";
import Loading from '../../components/Loading';
import { useNavigate } from 'react-router';
import secureLocalStorage from "react-secure-storage";
import ModalPrincipal from '../../components/ModalAlert/modal'
import './index.css'

function Produto() {
    const navigate = useNavigate();

    const [qualInf, setQualInf] = useState(<></>)
    const [titulo, setTitulo] = useState('');
    const [exibirGrid, setExibirGrid] = useState(<></>);

    const [openModal, setOpenModal] = useState(false)
    const [qualAlert, setQualAlert] = useState(<></>)

    useEffect(() => {

     //   setQualAlert(<div className='div-alert'><Loading /></div>)
      //  setOpenModal(true)

        validatToken();

    }, []);



    async function validatToken() {

        let ret: AxiosResponse;
        let valor: any;

        const _user = secureLocalStorage.getItem('t')

        api.defaults.headers.common['Authorization'] = _user as ''

        try {

            ret = await api.get("/status/validar")

            if (ret.data.status === 200) {

                secureLocalStorage.setItem('t', ret.data.data);
                secureLocalStorage.setItem('acp', ret.data.decodedToken.acp_integrationid);
                secureLocalStorage.setItem('n', ret.data.decodedToken.name);
                secureLocalStorage.setItem('iag', ret.data.decodedToken.id);
                secureLocalStorage.setItem('cy', ret.data.decodedToken.company);

                setExibirGrid(<GridPrincipal
                    iconeExcluir={ModalDeletar}
                    th1='Ativo'
                    th2='ID'
                    th3='Fornecedor'
                    th4='Número da Nota'
                    th5='Itens'
                    th6='Data da Inclusão'
                    endPoint='invoice/buscarinvoice'
                    col1='1'
                    col2='0'
                    col3='2'
                    col4='3'
                    col5=''
                    col6='4'
                    fromAdd='/invoice/add'
                    fromEdit='/invoice/edit'
                    btn="S"
                    incluirInventario={confirmarInclusao}
                    pag="invoice"
                    buscarinvoice='invoice/buscarinvoice'
                    fecharModal={fecharModal}
                />)

                setOpenModal(false)
            }

        } catch (e: any) {

            if (e.response.data.status === 403) {

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
                    <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)

            }
        }

    }


    function fecharModal() {

        secureLocalStorage.removeItem('t')
        secureLocalStorage.removeItem('acp')
        secureLocalStorage.removeItem('n')
        secureLocalStorage.removeItem('iag')
        navigate('/login');
        setOpenModal(false)

    }


    function confirmarInclusao(obj: any) {

 if(secureLocalStorage.getItem('acp') !== 'CONSULTOR'){
        setQualAlert(<div className='div-alert'>
            <span className="fa fa-question-circle ControleIconAlertint"></span>
            <div className='div-info-alert-atencao'><span className="info-alert-atencao">Deseja incluir os registros?</span></div>
            <div className='div-info-alert'><span className="info-alert">Você não poderá alterar os itens da nota, após a inclusão. Deseja continuar?</span></div>

            <div className='btn-alert-div'>
                <span className='btn-alert-esc' onClick={() => incluirInventario2(obj)}>
                    <span className="btn-alert-lab">SIM</span>
                </span>
                <span className='btn-alert-dir' onClick={finalizarModal2}>
                    <span className="btn-alert-lab">NÃO</span>
                </span>
            </div>
        </div>)

        setOpenModal(true)
 }
    }

    function finalizarModal2() {

        setOpenModal(false)

    }

    async function incluirInventario2(obj: any) {

        setQualAlert(<><Loading /></>)

        let ret: AxiosResponse;
        let valor: any;

        try {
            ret = await api.get("invoiceitems/exibiritens", {
                params: {
                    id: String(obj),
                }
            })

            if (ret.data.status === 200) {

                valor = ret.data.data;

           

                if (valor[0].int_status === 'S') {


                    setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Os itens já foram incluidos.</span></div>
                    <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                </div>)
    
                setOpenModal(true)

                } else {

                    try {
                        ret = await api.post("asset/insert", {
                            data: valor,
                            id: secureLocalStorage.getItem('iag'),
                        });
                        if (ret.data.status === 200) {

                            setQualAlert(<div className='div-alert'>
                                <span className="fa-regular fa-circle-check ControleIconAlert"></span>
                                <div className='div-info-alert'><span className="info-alert">Cadastro realizado com sucesso.</span></div>
                                <div className='btn-alert' onClick={finalizarModal1}><span className="btn-alert-lab">OK</span></div>
                            </div>)

                            setOpenModal(true)
                        }

                    } catch (e: any) {

                        setQualAlert(<div className='div-alert'>
                            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                            <div className='div-info-alert'><span className="info-alert">Não foi possivel concluir o cadastramento.</span></div>
                            <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                        </div>)

                    }
                }

            }
        } catch (e) {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Não foi possivel concluir o cadastramento.</span></div>
                <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
            </div>)

            setOpenModal(true)
        }

    }

    function ModalDeletar(obj: any) {

     
        if(secureLocalStorage.getItem('acp') !== 'CONSULTOR'){
        if (obj.inc_inclusion === 'N') {
           
            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">Deseja deletar o registro?</span></div>
                <div className='div-info-alert'><span className="info-alert">Você não poderá reverter.</span></div>

                <div className='btn-alert-div'>
                    <span className='btn-alert-esc' onClick={() => deletarNotas(obj.inc_id)}>
                        <span className="btn-alert-lab">SIM</span>
                    </span>
                    <span className='btn-alert-dir' onClick={fecharModal1}>
                        <span className="btn-alert-lab">NÃO</span>
                    </span>
                </div>
            </div>)

            setOpenModal(true)


        } else if (obj.inc_inclusion === 'S') {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">A nota fiscal não pode ser excluída, pois está vinculada ao cadastro de ativos.</span></div>
                <div className='btn-alert' onClick={fecharModal1}><span className="btn-alert-lab">OK</span></div>
            </div>)

            setOpenModal(true)
        }
    }
    }

    function fecharModal1() {
        setOpenModal(false)
    }

    async function deletarNotas(id: any) {

        let ret: AxiosResponse;
        try {
            ret = await api.delete("invoice/delete", {
                data: { id: id }
            });
            if (ret.data.status === 200) {

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-check ControleIconAlert"></span>
                    <div className='div-info-alert'><span className="info-alert">A nota fiscal foi excluída com sucesso.</span></div>
                    <div className='btn-alert' onClick={finalizarModal1}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)

            }
        } catch (e: any) {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Não foi possivel concluir o cadastramento.</span></div>
                <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
            </div>)

            setOpenModal(true)
        }
    }

    function finalizarModal1() {
        window.location.reload();
    }


    return (
        <>
            <Nav />
            <div className="main-content">
                <Header />
                <main>
                    <div className='containerPrincipal'>
                        <div className='ContainerBody'>
                            <div className='div-titulo-pages'>
                                <span className='fa fa-file-text ControleIcon' />
                                <h1 className='div-titulo-pages-text'>Notas Fiscais</h1>
                            </div>

                        </div>
                        <div className='grid-container-principal'>
                            {exibirGrid}
                        </div>
                    </div>
                </main>
            </div>

            <ModalPrincipal isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)}>
                <div className='container-modal-alert'>
                    {qualAlert}
                </div>
            </ModalPrincipal>

        </>
    );

}

export default Produto;