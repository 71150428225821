import React from "react";
import GaugeChart from "react-gauge-chart";
import './SLAChart.css'; // Adicione o arquivo de estilo
import Aviso01 from '../Aviso01/index';


const SLAChart = (props) => {
  return (
    <div className="featured-ProgressBar">
      <div className="top">
        <h1 className="title">Indicador de SLA dos Atendimentos</h1>
      </div>

      {props.obj > 0 ? (
        <>
      <div className="gauge-container">
        <GaugeChart
          id="sla-gauge-chart"
          nrOfLevels={5}  // Número de seções no gráfico
          colors={["#ff0000", "#ff6600", "#ffff00", "#66ff66", "#00cc00"]} // Cores para cada seção
          arcWidth={0.3}  // Largura do arco
          percent={0.50}  // Posição do ponteiro, 85% como exemplo (dentro do SLA)
          textColor="#000000"  // Cor do texto
        />
      </div>
      <div className="gauge-labels">
        <span>Crítico</span>
        <span>Atrasado</span>
        <span>Dentro do Prazo</span>
        <span>Bom</span>
        <span>Excelente</span>
      </div>
      </>
      ) : (
        <Aviso01 />
      )}

    </div>
  );
};

export default SLAChart;
