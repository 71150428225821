import React, {useState} from 'react';
import './style.css'
import {FcViewDetails } from "react-icons/fc";

const Aviso01 = () => {
    return (
        <div className='container-loader'>
            <FcViewDetails className='icon-aviso'/>
            <h1 className='loader-text-aviso'>Não há informações a serem exibidas...</h1>
        </div>

     )
}

export default Aviso01;