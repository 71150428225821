import React, { useState, useEffect } from 'react'
import Nav from "../../components/Nav";
import Header from "../../components/Header";
import './index.css'
import Select from 'react-select'
import api from '../../Services/api'
import { AxiosResponse } from "axios";
import { MdOutlineAddCircle } from 'react-icons/md';
import GridSecundario from '../../components/GridSecundario'
import FormTipoAgente from '../../components/FormTipoAgente';
import Loading from '../../components/Loading';
import { useNavigate } from 'react-router';
import secureLocalStorage from "react-secure-storage";
import ModalPrincipal from '../../components/ModalAlert/modal'
import ModalIncluirNovo from '../../components/ModalIncluirNovo/modal'

function UsuariosAdd() {
	const navigate = useNavigate();

	const [openModalIncluirNovo, setOpenModalIncluirNovo] = useState(false)
	const [qualTitulo, setQualTitulo] = useState('')
	const [qualGrid, setQualGrid] = useState(<></>)
	const [openModal, setOpenModal] = useState(false)

	const [qualAlert, setQualAlert] = useState(<></>)

	const [integrationId, setIntegrationId] = useState('')
	const [name, setName] = useState('')
	const [login, setLogin] = useState('')
	const [password, setPassword] = useState('')
	const [observation, setObservation] = useState('')
	const [country, setCountry] = useState('')
	const [state, setState] = useState('')
	const [city, setCity] = useState('')
	const [neighborhood, setNeighborhood] = useState('')
	const [street, setStreet] = useState('')
	const [streetNumber, setStreetNumber] = useState('')
	const [streetNumberCompl, setStreetNumberCompl] = useState('')
	const [zipcode, setZipcode] = useState('')
	const [phone, setPhone] = useState('')
	const [mobilePhone, setMobilePhone] = useState('')
	const [mail, setMail] = useState('')
	const [imeiLastSync, setImeiLastSync] = useState('')
	const [dateHourLastSync, setDateHourLastSync] = useState('')
	const [agg_id, setAgg_id] = useState('')
	const [active, setActive] = useState(true)
	const [lastGeoPosition, setLastGeoPosition] = useState('')
	const [geoPosition, setGeoPosition] = useState('')
	const [batteryLeve, setBatteryLeve] = useState('')
	const [mobilePlatformVersion, setMobilePlatformVersion] = useState('')
	const [mobileUser, setMobileUser] = useState(false)
	const [centerWebUser, setCenterWebUser] = useState(false)
	const [acp_id, setAcp_id] = useState('')
	const [dateTimeInsert, setDateTimeInsert] = useState('');
	const [dateTimeUpdate, setDateTimeUpdate] = useState('');
	const [id_insert, setId_insert] = useState('');
	const [phoneddd, setPhoneddd] = useState('');
	const [phoneddi, setPhoneddi] = useState('');
	const [mobilePhoneddd, setMobilePhoneddd] = useState('');
	const [mobilePhoneddi, setMobilePhoneddi] = useState('');
	const [callportal, setCallportal] = useState(false)
	const [optionsAgentGroup, setOptionsAgentGroup] = useState([])
	const [optionsAccessProfile, setOptionsAccessProfile] = useState([])

	const [activeDisabled, setActiveDisabled] = useState(false)
	const [nomeDisabled, setNomeDisabled] = useState(false)
	const [loginDisabled, setLoginDisabled] = useState(false)
	const [passwordDisabled, setPasswordDisabled] = useState(false)
	const [mobilePhonedddDisabled, setMobilePhonedddDisabled] = useState(false)
	const [mobilePhoneddiDisabled, setMobilePhoneddiDisabled] = useState(false)
	const [mobilePhoneDisabled, setMobilePhoneDisabled] = useState(false)
	const [phoneddiDisabled, setPhoneddiDisabled] = useState(false)
	const [phonedddDisabled, setPhoneddDDisabled] = useState(false)
	const [phoneDisabled, setPhoneDisabled] = useState(false)
	const [mailDisabled, setMailDisabled] = useState(false)
	const [observationDisabled, setObservationDisabled] = useState(false)
	const [isDisabledAgg_id, setIsDisabledAgg_id] = useState(false)
	const [countryDisabled, setCountryDisabled] = useState(false)
	const [stateDisabled, setStateDisabled] = useState(false)
	const [cityDisabled, setCityDisabled] = useState(false)
	const [neighborhoodDisabled, setNeighborhoodDisabled] = useState(false)
	const [streetDisabled, setStreetDisabled] = useState(false)
	const [streetNumberDisabled, setStreetNumberDisabled] = useState(false)
	const [streetNumberComplDisabled, setStreetNumberComplDisabled] = useState(false)
	const [zipcodeDisabled, setZipcodeDisabled] = useState(false)
	const [isDisabledAcp_id, setIsDisabledAcp_id] = useState(false)
	const [mobileUserDisabled, setMobileUserDisabled] = useState(false)
	const [centerWebUserDisabled, setCenterWebUserDisabled] = useState(false)
	const [confirmarUserDisabled, setConfirmarUserDisabled] = useState(false)
	const [cancelarDisabled, setCancelarDisabled] = useState(false)
	const [integrationIdDisabled, setIntegrationIdDisabled] = useState(false)
	const [callportalDisabled, setCallportalDisabled] = useState(false)
	const [optionsUser, setOptionsUser] = useState([])
	const [tipoUser, setTipoUser] = useState('')

	useEffect(() => {


		setQualAlert(<div className='div-alert'><Loading /></div>)
		setOpenModal(true)

		let today = new Date();
		let date =
			today.getFullYear() +
			"-" +
			(today.getMonth() + 1).toString().padStart(2, "0") +
			"-" +
			today.getDate().toString().padStart(2, "0");
		let time =
			today.getHours().toString().padStart(2, "0") +
			":" +
			today.getMinutes().toString().padStart(2, "0");
		let JuntarDate = date + "T" + time;
		setDateTimeInsert(JuntarDate)

		validatToken();

		setMobileUserDisabled(true)
		setCenterWebUserDisabled(true)
		setCallportalDisabled(true)

	}, []);


	async function GetUser() {
		let ret: AxiosResponse;
		let valor: any;
		try {
			ret = await api.get("user/verificacao")
			if (ret.data.status === 200) {
				valor = ret.data.data;

				setOptionsUser(valor)

			}
		} catch (e) {
			setOptionsUser([])
		}
	}

	async function validatToken() {

		let ret: AxiosResponse;
		let valor: any;


		const _user = secureLocalStorage.getItem('t')

		api.defaults.headers.common['Authorization'] = _user as ''

		try {

			ret = await api.get("/status/validar")

			if (ret.data.status === 200) {

				secureLocalStorage.setItem('t', ret.data.data);
				secureLocalStorage.setItem('acp', ret.data.decodedToken.acp_integrationid);
				secureLocalStorage.setItem('n', ret.data.decodedToken.name);
				secureLocalStorage.setItem('iag', ret.data.decodedToken.id);
				secureLocalStorage.setItem('cy', ret.data.decodedToken.company);

				await GetAccessProfile();
				await GetAgentGroup();
				await GetUser();

				if (ret.data.decodedToken.acp_integrationid === 'DESENVOLVEDOR' || ret.data.decodedToken.acp_integrationid === 'ADMINISTRADOR') {
					setTipoUser(ret.data.decodedToken.acp_integrationid)
					setActiveDisabled(false)
					setNomeDisabled(false)
					setLoginDisabled(false)
					setPasswordDisabled(false)
					setMobilePhonedddDisabled(false)
					setMobilePhoneddiDisabled(false)
					setMobilePhoneDisabled(false)
					setPhoneddiDisabled(false)
					setPhoneddiDisabled(false)
					setPhoneddiDisabled(false)
					setPhoneDisabled(false)
					setMailDisabled(false)
					setObservationDisabled(false)
					setIsDisabledAgg_id(false)
					setCountryDisabled(false)
					setStateDisabled(false)
					setCityDisabled(false)
					setNeighborhoodDisabled(false)
					setStreetDisabled(false)
					setStreetNumberDisabled(false)
					setStreetNumberComplDisabled(false)
					setZipcodeDisabled(false)
					setIsDisabledAcp_id(false)
					setConfirmarUserDisabled(false)
					setCancelarDisabled(false)
					setPhoneddDDisabled(false)
					setIntegrationIdDisabled(false)

				} else if (ret.data.decodedToken.acp_integrationid === 'CONSULTOR') {
					setTipoUser(ret.data.decodedToken.acp_integrationid)
					setActiveDisabled(true)
					setNomeDisabled(true)
					setLoginDisabled(true)
					setPasswordDisabled(true)
					setMobilePhonedddDisabled(true)
					setMobilePhoneddiDisabled(true)
					setMobilePhoneDisabled(true)
					setPhoneddiDisabled(true)
					setPhoneddiDisabled(true)
					setPhoneddiDisabled(true)
					setPhoneDisabled(true)
					setMailDisabled(true)
					setObservationDisabled(true)
					setIsDisabledAgg_id(true)
					setCountryDisabled(true)
					setStateDisabled(true)
					setCityDisabled(true)
					setNeighborhoodDisabled(true)
					setStreetDisabled(true)
					setStreetNumberDisabled(true)
					setStreetNumberComplDisabled(true)
					setZipcodeDisabled(true)
					setIsDisabledAcp_id(true)
					setMobileUserDisabled(true)
					setCenterWebUserDisabled(true)
					setConfirmarUserDisabled(true)
					setCancelarDisabled(true)
					setPhoneddDDisabled(true)
					setIntegrationIdDisabled(true)
				}

				setOpenModal(false)
			}

		} catch (e: any) {

			if (e.response === undefined) {
				setQualAlert(<div className='div-alert'>
					<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
					<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
					<div className='div-info-alert'><span className="info-alert">Sem comunicação com o servidor.</span></div>
					<div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
				</div>)

				setOpenModal(true)
			} else
				if (e.response.status === 403) {

					setQualAlert(<div className='div-alert'>
						<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
						<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
						<div className='div-info-alert'><span className="info-alert">Token inválido ou expirado.</span></div>
						<div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
					</div>)

					setOpenModal(true)

				} else {
					setQualAlert(<div className='div-alert'>
						<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
						<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
						<div className='div-info-alert'><span className="info-alert">Não foi possível concluir sua solicitação.</span></div>
						<div className='btn-alert' onClick={fecharModal2}><span className="btn-alert-lab">OK</span></div>
					</div>)

					setOpenModal(true)
				}
		}

	}


	function fecharModal2() {
		setOpenModal(false)
	}

	function fecharModal() {

		secureLocalStorage.removeItem('t')
		secureLocalStorage.removeItem('acp')
		secureLocalStorage.removeItem('n')
		secureLocalStorage.removeItem('iag')
		navigate('/login');
		setOpenModal(false)

	}


	async function GetAccessProfile() {
		let ret: AxiosResponse;
		let valor: any;
		try {
			ret = await api.get("accessprofile/listar")
			if (ret.data.status === 200) {
				valor = ret.data.data;
				const arr = [] as any;
				let result = valor;

				

				if(secureLocalStorage.getItem('acp') === 'DESENVOLVEDOR'){
					result.map((user: any) => {
						return arr.push({ value: String(user.acp_id), label: user.acp_description });
					});
	
					setOptionsAccessProfile(arr)
				}else{

					const usuario = result.filter(
						(e: any) => {
							return e.acp_id !== 1
						}
					);


					usuario.map((user: any) => {
						return arr.push({ value: String(user.acp_id), label: user.acp_description });
					});
	
					setOptionsAccessProfile(arr)
				}

				

			}
		} catch (e) {
			setOptionsAccessProfile([])
		}
	}

	async function GetAgentGroup() {
		let ret: AxiosResponse;
		let valor: any;
		try {
			ret = await api.get("agentgroup/listar")
			if (ret.data.status === 200) {
				valor = ret.data.data;
				const arr = [] as any;
				let result = valor;
				result.map((user: any) => {
					return arr.push({ value: String(user.agg_id), label: user.agg_description });
				});
				setOptionsAgentGroup(arr)

			}
		} catch (e) {
			setOptionsAgentGroup([])
		}
	}


	function ValitarActive() {
		let e = document.getElementById("checkbox-active") as HTMLInputElement;

		if (e.checked) {

			setActive(true)
		} else {

			setActive(false)
		}
	}

	function ValitarActivemobileuser() {
		let e = document.getElementById("checkbox-mobileuser") as HTMLInputElement;

		if (e.checked) {

			setMobileUser(true)
		} else {

			setMobileUser(false)
		}
	}

	function ValitarActiveCenterwebuser() {
		let e = document.getElementById("checkbox-centerwebuser") as HTMLInputElement;

		if (e.checked) {

			setCenterWebUser(true)
		} else {

			setCenterWebUser(false)
		}
	}

	function ValitarActiveCallportal() {
		let e = document.getElementById("checkbox-callportal") as HTMLInputElement;

		if (e.checked) {

			setCallportal(true)
		} else {

			setCallportal(false)
		}
	}

	const customStyles = {
		control: (provided: any, state: any) => ({
			...provided,
			background: '#fff',
			minHeight: '30px',
			height: '30px',
			borderColor: state.isFocused ? 'grey' : '#92989e',

		}),


		valueContainer: (provided: any, state: any) => ({
			...provided,
			height: '30px',
			padding: '0 6px'
		}),

		input: (provided: any, state: any) => ({
			...provided,
			margin: '0px',
			fontSize: 14,

		}),
		indicatorsContainer: (provided: any, state: any) => ({
			...provided,
			height: '30px',
		}),
	};


	async function ChamarModalAgentGroup(tipoModal: any) {

		let ret: AxiosResponse;
		let valor: any;


		const _user = secureLocalStorage.getItem('t')

		api.defaults.headers.common['Authorization'] = _user as ''

		try {

			ret = await api.get("/status/validar")

			if (ret.data.status === 200) {

				secureLocalStorage.setItem('t', ret.data.data);
				secureLocalStorage.setItem('acp', ret.data.decodedToken.acp_integrationid);
				secureLocalStorage.setItem('n', ret.data.decodedToken.name);
				secureLocalStorage.setItem('iag', ret.data.decodedToken.id);
				secureLocalStorage.setItem('cy', ret.data.decodedToken.company);

				if (tipoUser === 'DESENVOLVEDOR' || tipoUser === 'ADMINISTRADOR') {

					if (tipoModal === 'Função') {
						setOpenModalIncluirNovo(true)
						sessionStorage.removeItem("dadosTipoAgente");
						setQualTitulo('Cadastrar Função')
						setQualGrid((<><GridSecundario PegarValorGrid={PegarValorGrid} Iconeadd={ChamarCadastroAgentGroup}
							Iconeditar={EditarAgentGroup}
							iconeExcluir={AgentGroupDeletar}
							th1='Ativo'
							th2='ID'
							th3='Descrição'
							th4='Identificador Alternativo'
							th5=''
							th6=''
							endPoint='agentgroup/buscaragentgroup'
							consult='N'
							col1='1'
							col2='2'
							col3='3'
							ambiente='%'
							pag="ok"
						/></>)
						)
					}
				}


			}

		} catch (e: any) {

			if (e.response === undefined) {
				setQualAlert(<div className='div-alert'>
					<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
					<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
					<div className='div-info-alert'><span className="info-alert">Sem comunicação com o servidor.</span></div>
					<div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
				</div>)

				setOpenModal(true)
			} else
				if (e.response.status === 403) {

					setQualAlert(<div className='div-alert'>
						<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
						<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
						<div className='div-info-alert'><span className="info-alert">Token inválido ou expirado.</span></div>
						<div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
					</div>)

					setOpenModal(true)

				} else {
					setQualAlert(<div className='div-alert'>
						<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
						<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
						<div className='div-info-alert'><span className="info-alert">Não foi possível concluir sua solicitação.</span></div>
						<div className='btn-alert' onClick={fecharModal2}><span className="btn-alert-lab">OK</span></div>
					</div>)

					setOpenModal(true)
				}
		}




	}

	function ChamarCadastroAgentGroup() {
		sessionStorage.removeItem("dadosTipoAgente");
		setQualGrid(<><FormTipoAgente voltar={BtnVoltarAgentGroup} /></>)
	}

	function BtnVoltarAgentGroup() {

		setQualGrid((<><GridSecundario PegarValorGrid={PegarValorGrid} Iconeadd={ChamarCadastroAgentGroup}
			Iconeditar={EditarAgentGroup}
			iconeExcluir={AgentGroupDeletar}
			th1='Ativo'
			th2='ID'
			th3='Descrição'
			th4='Identificador Alternativo'
			th5=''
			th6=''
			endPoint='agentgroup/buscaragentgroup'
			consult='N'
			col1='1'
			col2='2'
			col3='3'
			ambiente='%'
			pag="ok"
		/></>))

		GetAgentGroup()
	}

	async function EditarAgentGroup(obj: any) {
		sessionStorage.setItem("dadosTipoAgente", JSON.stringify(obj));
		setQualGrid(<><FormTipoAgente voltar={BtnVoltarAgentGroup} /></>)
	}

	function AgentGroupDeletar(obj: any) {



		setQualAlert(<div className='div-alert'>
			<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
			<div className='div-info-alert-atencao'><span className="info-alert-atencao">Deseja deletar o registro?</span></div>
			<div className='div-info-alert'><span className="info-alert">Você não poderá reverter.</span></div>

			<div className='btn-alert-div'>
				<span className='btn-alert-esc' onClick={() => deletarAgentGroup(obj.agg_id)}>
					<span className="btn-alert-lab">SIM</span>
				</span>
				<span className='btn-alert-dir' onClick={finalizarModal2}>
					<span className="btn-alert-lab">NÃO</span>
				</span>
			</div>
		</div>)

		setOpenModal(true)


	}

	async function deletarAgentGroup(id: any) {
		setQualAlert(<div className='div-alert'><Loading /></div>)
		setOpenModal(true)
		setQualGrid(<><Loading /></>)
		let ret: AxiosResponse;
		try {
			ret = await api.delete("agentgroup/delete", {
				data: { id: id }
			});
			if (ret.data.status === 200) {

				setQualGrid((<><GridSecundario PegarValorGrid={PegarValorGrid} Iconeadd={ChamarCadastroAgentGroup}
					Iconeditar={EditarAgentGroup}
					iconeExcluir={AgentGroupDeletar}
					th1='Ativo'
					th2='ID'
					th3='Descrição'
					th4='Identificador Alternativo'
					th5=''
					th6=''
					endPoint='agentgroup/buscaragentgroup'
					consult='N'
					col1='1'
					col2='2'
					col3='3'
					ambiente='%'
					pag="ok"
				/></>))

				GetAgentGroup()
			}
		} catch (e: any) {
				setQualGrid((<><GridSecundario PegarValorGrid={PegarValorGrid} Iconeadd={ChamarCadastroAgentGroup}
					Iconeditar={EditarAgentGroup}
					iconeExcluir={AgentGroupDeletar}
					th1='Ativo'
					th2='ID'
					th3='Descrição'
					th4='Identificador Alternativo'
					th5=''
					th6=''
					endPoint='agentgroup/buscaragentgroup'
					consult='N'
					col1='1'
					col2='2'
					col3='3'
					ambiente='%'
					pag="ok"
				/></>))
		}

		setOpenModal(false)
	}

	function PegarValorGrid(valor: any) {

		setAgg_id(String(valor.agg_id))
		setOpenModalIncluirNovo(false)

	}


	async function confirmarUser() {

		let ret: AxiosResponse;
		let valor: any;

		setQualAlert(<div className='div-alert'><Loading /></div>)
		setOpenModal(true)


		const _user = secureLocalStorage.getItem('t')

		api.defaults.headers.common['Authorization'] = _user as ''

		try {

			ret = await api.get("/status/validar")

			if (ret.data.status === 200) {


				const FiltrarLogin = optionsUser.filter(
					(e: any) => {
						return e.age_login.toLowerCase() === login.toLowerCase()
					}
				);

				const FiltrarNome = optionsUser.filter(
					(e: any) => {
						return e.age_name.toLowerCase() === name.toLowerCase()
					}
				);

				const Filtrarintegrationid = optionsUser.filter(
					(e: any) => {
					  return String(e.age_integrationid || "").toLowerCase() === integrationId.toLowerCase()
					}
				  );

				  
				if (FiltrarNome.length > 0) {

					setQualAlert(<div className='div-alert'>
						<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
						<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
						<div className='div-info-alert'><span className="info-alert">Nome já está em uso.</span></div>
						<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
					</div>)


				} else
					if (FiltrarLogin.length > 0) {

						setQualAlert(<div className='div-alert'>
							<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
							<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
							<div className='div-info-alert'><span className="info-alert">Login já está em uso.</span></div>
							<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
						</div>)

					}else if (integrationId !== "" && Filtrarintegrationid.length > 0) {

						setQualAlert(<div className='div-alert'>
							<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
							<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
							<div className='div-info-alert'><span className="info-alert">ID para Integração já está em uso.</span></div>
							<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
						</div>)
	
	
					}
					
					else
						if (name === "") {

							setQualAlert(<div className='div-alert'>
								<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
								<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
								<div className='div-info-alert'><span className="info-alert">Digite o nome.</span></div>
								<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
							</div>)


						} else if (login === "") {

							setQualAlert(<div className='div-alert'>
								<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
								<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
								<div className='div-info-alert'><span className="info-alert">Digite o login.</span></div>
								<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
							</div>)


						} else if (password === "") {

							setQualAlert(<div className='div-alert'>
								<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
								<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
								<div className='div-info-alert'><span className="info-alert">Digite a senha.</span></div>
								<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
							</div>)

						} else if (mail === "") {

							setQualAlert(<div className='div-alert'>
								<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
								<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
								<div className='div-info-alert'><span className="info-alert">Digite o E-mail.</span></div>
								<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
							</div>)



						} else if (acp_id === "") {
							setQualAlert(<div className='div-alert'>
								<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
								<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
								<div className='div-info-alert'><span className="info-alert">Selecione o perfil de acesso.</span></div>
								<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
							</div>)

						} else {
							let ret: AxiosResponse;

							const dados = [{
								age_integrationid: integrationId,
								age_name: name,
								age_login: login,
								age_password: password,
								age_observation: observation,
								age_country: country,
								age_state: state,
								age_city: city,
								age_neighborhood: neighborhood,
								age_street: street,
								age_streetnumber: streetNumber,
								age_streetnumbercompl: streetNumberCompl,
								age_zipcode: zipcode,
								age_phone: phone,
								age_mobilephone: mobilePhone,
								age_email: mail,
								agg_id: agg_id || null,
								age_active: active,
								age_mobileuser: mobileUser,
								age_centerwebuser: centerWebUser,
								acp_id,
								age_id_insert: secureLocalStorage.getItem('iag'),
								age_id_lastupdate: null,
								age_phoneddd: phoneddd,
								age_phoneddi: phoneddi,
								age_mobilephoneddd: mobilePhoneddd,
								age_mobilephoneddi: mobilePhoneddi,
								etp_id: secureLocalStorage.getItem('cy'),
								age_callportal: callportal
							}];

							try {
								ret = await api.post("user/insert", {
									data: dados,
								});
								if (ret.data.status === 200) {


									setQualAlert(<div className='div-alert'>
										<span className="fa-regular fa-circle-check ControleIconAlert"></span>
										<div className='div-info-alert'><span className="info-alert">Cadastro realizado com sucesso!</span></div>
										<div className='btn-alert' onClick={finalizarModal1}><span className="btn-alert-lab">OK</span></div>
									</div>)



								}
							} catch (e: any) {

								if (e.response === undefined) {
									setQualAlert(<div className='div-alert'>
										<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
										<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
										<div className='div-info-alert'><span className="info-alert">Sem comunicação com o servidor.</span></div>
										<div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
									</div>)

									setOpenModal(true)
								} else
									if (e.response.status === 403) {

										setQualAlert(<div className='div-alert'>
											<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
											<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
											<div className='div-info-alert'><span className="info-alert">Token inválido ou expirado.</span></div>
											<div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
										</div>)

										setOpenModal(true)

									} else {
										setQualAlert(<div className='div-alert'>
											<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
											<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
											<div className='div-info-alert'><span className="info-alert">Não foi possível concluir sua solicitação.</span></div>
											<div className='btn-alert' onClick={fecharModal2}><span className="btn-alert-lab">OK</span></div>
										</div>)

										setOpenModal(true)
									}


							}
						}

			}

		} catch (e: any) {

			if (e.response.data.status === 403) {

				if (e.response === undefined) {
					setQualAlert(<div className='div-alert'>
						<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
						<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
						<div className='div-info-alert'><span className="info-alert">Sem comunicação com o servidor.</span></div>
						<div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
					</div>)

					setOpenModal(true)
				} else
					if (e.response.status === 403) {

						setQualAlert(<div className='div-alert'>
							<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
							<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
							<div className='div-info-alert'><span className="info-alert">Token inválido ou expirado.</span></div>
							<div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
						</div>)

						setOpenModal(true)

					} else {
						setQualAlert(<div className='div-alert'>
							<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
							<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
							<div className='div-info-alert'><span className="info-alert">Não foi possível concluir sua solicitação.</span></div>
							<div className='btn-alert' onClick={fecharModal2}><span className="btn-alert-lab">OK</span></div>
						</div>)

						setOpenModal(true)
					}


			}
		}


	}


	function finalizarModal1() {
		navigate('/user');
	}

	function finalizarModal2() {
		setOpenModal(false)
	}

	function cancelar() {
		navigate('/user');
	}

	const maskOnlyNumbers = (value: any) => {
		return value.replace(/\D/g, "");
	};

	function validarPerfil(id: any) {


		if (id === '6') {

			setAcp_id(id)
			setCenterWebUser(false)
			setMobileUser(true)
			setMobileUserDisabled(true)
			setCenterWebUserDisabled(true)
			setCallportal(false)
			setCallportalDisabled(true)
		} else if (id === '') {

			setCenterWebUser(false)
			setMobileUser(false)
			setMobileUserDisabled(false)
			setCenterWebUserDisabled(false)
			setAcp_id(id)
			setCallportal(false)
			setCallportalDisabled(false)

		} else if (id === '5') {

			setAcp_id(id)
			setCenterWebUser(true)
			setMobileUser(false)
			setMobileUserDisabled(true)
			setCenterWebUserDisabled(true)
			setCallportalDisabled(false)
		} else if (id === '3') {

			setAcp_id(id)
			setCenterWebUser(true)
			setMobileUser(false)
			setMobileUserDisabled(true)
			setCenterWebUserDisabled(true)
			setCallportal(true)
			setCallportalDisabled(false)
		} else if (id === '4') {
			setAcp_id(id)
			setCenterWebUser(false)
			setMobileUser(false)
			setMobileUserDisabled(true)
			setCenterWebUserDisabled(true)
			setCallportal(true)
			setCallportalDisabled(true)
		} else if (id === '1' || id === '2') {

			setAcp_id(id)
			setCenterWebUser(true)
			setMobileUser(true)
			setCenterWebUserDisabled(true)
			setMobileUserDisabled(false)
			setCenterWebUserDisabled(true)
			setCallportal(true)
			setCallportalDisabled(false)
		} else {
			setCenterWebUser(false)
			setMobileUser(false)
			setMobileUserDisabled(false)
			setCenterWebUserDisabled(false)
			setAcp_id(id)
			setCallportal(false)
			setCallportalDisabled(false)
		}
	}

	function fecharOpenModalIncluirNovo() {
		setOpenModalIncluirNovo(false)
	}


	return (
		<>
			<Nav />
			<div className="main-content">
				<Header />
				<main>
					<div className='containerPrincipal'>
						<div className="title-Page">
							<div className="title-text">
								<div className="size-x circle">1</div>
								<h2>Informações Gerais</h2>
							</div>
							<hr />
						</div>

						<div className="class-checkbox">
							<input type="checkbox" id="checkbox-active" checked={active} onChange={ValitarActive} disabled={activeDisabled} />
							<h4 className="lab-ativo">Ativo</h4>
						</div>

						<div className="container-cad-prod1">
							<div id="content1">
								<div className="input-box-ativos">
									<span className="details">Nome *</span>
									<input
										autoComplete="nope"
										type="text"
										onChange={(e) => setName(e.target.value)}
										value={name}
										disabled={nomeDisabled}
									/>

								</div>
							</div>
							<div id="content2">
								<div className="input-box-ativos">
									<span className="details">Login *</span>
									<input
										autoComplete="nope"
										type="text"
										onChange={(e) => setLogin(e.target.value)}
										value={login}
										disabled={loginDisabled}
									/>
								</div>
							</div>
							<div id="content3">
								<div className="input-box-ativos">
									<span className="details">Senha *</span>
									<input
										autoComplete="nope"
										type="password"
										onChange={(e) => setPassword(e.target.value)}
										value={password}
										disabled={passwordDisabled}
									/>
								</div>
							</div>
						</div>






						<div className="container-cad-prod10">
							<div id="content1">
								<div className="input-box-ativos">
									<span className="details">
										ID para Integração</span>
									<input
										autoComplete="nope"
										type="text"
										onChange={(e) => setIntegrationId(e.target.value)}
										value={integrationId}
										disabled={integrationIdDisabled}
									/>
								</div>
							</div>

							<div id="content2">
								<div className="input-box-ativos">
									<span className="details">DDI</span>
									<input
										autoComplete="nope"
										maxLength={3}
										type="text"
										onChange={(e) => setMobilePhoneddi(maskOnlyNumbers(e.target.value))}
										value={mobilePhoneddi}
										disabled={mobilePhoneddiDisabled}
									/>
								</div>
							</div>


							<div id="content3">
								<div className="input-box-ativos">
									<span className="details">DDD</span>
									<input
										autoComplete="nope"
										maxLength={3}
										type="text"
										onChange={(e) => setMobilePhoneddd(maskOnlyNumbers(e.target.value))}
										value={mobilePhoneddd}
										disabled={mobilePhonedddDisabled}
									/>
								</div>
							</div>

							<div id="content4">
								<div className="input-box-ativos">
									<span className="details">Celular</span>
									<input
										autoComplete="nope"
										maxLength={18}
										type="text"
										onChange={(e) => setMobilePhone(maskOnlyNumbers(e.target.value))}
										value={mobilePhone}
										disabled={mobilePhoneDisabled}
									/>
								</div>
							</div>

							<div id="content5">
								<div className="input-box-ativos">
									<span className="details">DDI</span>
									<input
										autoComplete="nope"
										maxLength={3}
										type="text"
										onChange={(e) => setPhoneddi(maskOnlyNumbers(e.target.value))}
										value={phoneddi}
										disabled={phoneddiDisabled}
									/>
								</div>
							</div>

							<div id="content6">
								<div className="input-box-ativos">
									<span className="details">DDD</span>
									<input
										autoComplete="nope"
										maxLength={3}
										type="text"
										onChange={(e) => setPhoneddd(maskOnlyNumbers(e.target.value))}
										value={phoneddd}
										disabled={phonedddDisabled}
									/>
								</div>
							</div>

							<div id="content7">
								<div className="input-box-ativos">
									<span className="details">Telefone</span>
									<input
										autoComplete="nope"
										maxLength={18}
										type="text"
										onChange={(e) => setPhone(maskOnlyNumbers(e.target.value))}
										value={phone}
										disabled={phoneDisabled}
									/>
								</div>
							</div>

						</div>


						<div className="container-cad-prod11">

							<div id="content1">
								<div className="input-box-ativos">
									<span className="details">E-mail *</span>
									<input
										autoComplete="nope"
										type="text"
										onChange={(e) => setMail(e.target.value)}
										value={mail}
										disabled={mailDisabled}
									/>
								</div>
							</div>

							<div id="content2">
								<div className="select-box-ativos-select">
									<div className='details-flex'>
										<span className="details">Função</span>
										<MdOutlineAddCircle className='details-flex-icon-ativo' onClick={() => ChamarModalAgentGroup('Função')} />
										<span className="details-esc-ativo" onClick={() => ChamarModalAgentGroup('Função')}>Incluir Novo</span>
									</div>
									<Select
										placeholder={'Selecione...'}
										options={optionsAgentGroup}
										styles={customStyles}
										value={optionsAgentGroup.filter(function (option: any) {
											return option.value === agg_id;
										})}
										onChange={(options: any) =>
											!options ? setAgg_id("") : setAgg_id(options.value)
										}
										isClearable
										noOptionsMessage={() => "Não encontrado..."}
										theme={(theme: any) => ({
											...theme,
											borderRadius: 4,
											colors: {
												...theme.colors,
												primary25: '#ebebeb',
												primary: 'black',
											},
										})}
										isDisabled={isDisabledAgg_id}
									/>
								</div>
							</div>
							<div id="content3">
								<div className="input-box-ativos">
									<span className="details">IMEI Última Sincronização</span>
									<input
										autoComplete="nope"
										type="text"
										disabled={true}
										onChange={(e) => setImeiLastSync(e.target.value)}
										value={imeiLastSync}
									/>
								</div>
							</div>
						</div>

						<div className="container-cad-prod6">
							<div id="content1">
								<div className="input-box-ativos">
									<span className="details">Versão App Mobile</span>
									<input
										autoComplete="nope"
										type="text"
										disabled={true}
										onChange={(e) => setMobilePlatformVersion(e.target.value)}
										value={mobilePlatformVersion}
									/>
								</div>
							</div>
							<div id="content2">
								<div className="input-box-ativos">
									<span className="details">Último Acesso Móvel</span>
									<input
										type="datetime-local"
										disabled
										value={dateHourLastSync}
									/>
								</div>
							</div>
							<div id="content3">
								<div className="input-box-ativos">
									<span className="details">Data da Inclusão</span>
									<input
										type="datetime-local"
										value={dateTimeInsert}
										disabled
									/>
								</div>
							</div>

							<div id="content4">
								<div className="input-box-ativos">
									<span className="details">Data da Última Atualização</span>
									<input
										type="datetime-local"
										value={dateTimeUpdate}
										disabled
									/>
								</div>
							</div>
						</div>

						<div className="container-cad-prod3">
							<div className="textareaBox-ativo">
								<span className="details">Observação</span>
								<textarea
									onChange={(e) => setObservation(e.target.value)}
									value={observation}
									disabled={observationDisabled}
								/>
							</div>
						</div>

						<div className="title-Page">
							<div className="title-text">
								<div className="size-x circle">2</div>
								<h2>Endereço</h2>
							</div>
							<hr />
						</div>

						<div className="container-cad-prod6">

							<div id="content1">
								<div className="input-box-ativos">
									<span className="details">País</span>
									<input
										autoComplete="nope"
										type="text"
										onChange={(e) => setCountry(e.target.value)}
										value={country}
										disabled={countryDisabled}
									/>
								</div>
							</div>
							<div id="content2">
								<div className="input-box-ativos">
									<span className="details">Estado</span>
									<input
										autoComplete="nope"
										type="text"
										onChange={(e) => setState(e.target.value)}
										value={state}
										disabled={stateDisabled}
									/>
								</div>
							</div>
							<div id="content3">
								<div className="input-box-ativos">
									<span className="details">Cidade</span>
									<input
										autoComplete="nope"
										type="text"
										onChange={(e) => setCity(e.target.value)}
										value={city}
										disabled={cityDisabled}
									/>
								</div>
							</div>
							<div id="content4">
								<div className="input-box-ativos">
									<span className="details">Bairro</span>
									<input
										autoComplete="nope"
										type="text"
										onChange={(e) => setNeighborhood(e.target.value)}
										value={neighborhood}
										disabled={neighborhoodDisabled}
									/>
								</div>
							</div>
						</div>

						<div className="container-cad-prod12">
							<div id="content1">
								<div className="input-box-ativos">
									<span className="details">Logradouro</span>
									<input
										autoComplete="nope"
										type="text"
										onChange={(e) => setStreet(e.target.value)}
										value={street}
										disabled={streetDisabled}
									/>
								</div>
							</div>
							<div id="content2">
								<div className="input-box-ativos">
									<span className="details">Número</span>
									<input
										autoComplete="nope"
										type="text"
										onChange={(e) => setStreetNumber(e.target.value)}
										value={streetNumber}
										disabled={streetNumberDisabled}
									/>
								</div>
							</div>
							<div id="content3">
								<div className="input-box-ativos">
									<span className="details">Complemento</span>
									<input
										autoComplete="nope"
										type="text"
										onChange={(e) => setStreetNumberCompl(e.target.value)}
										value={streetNumberCompl}
										disabled={streetNumberComplDisabled}
									/>
								</div>
							</div>
							<div id="content4">
								<div className="input-box-ativos">
									<span className="details">CEP</span>
									<input
										autoComplete="nope"
										type="text"
										onChange={(e) => setZipcode(e.target.value)}
										value={zipcode}
										disabled={zipcodeDisabled}
									/>
								</div>
							</div>
						</div>

						<div className="title-Page">
							<div className="title-text">
								<div className="size-x circle">3</div>
								<h2>Permissões</h2>
							</div>
							<hr />
						</div>

						<div className="container-cad-prod21">

							<div id="content1">
								<div className="select-box-ativos-select">
									<span className="details">Perfil de Acesso *</span>
									<Select
										maxMenuHeight={140}
										placeholder={'Selecione...'}
										options={optionsAccessProfile}
										styles={customStyles}
										value={optionsAccessProfile.filter(function (option: any) {
											return option.value === acp_id;
										})}
										onChange={(options: any) =>
											!options ? validarPerfil("") : validarPerfil(options.value)
										}
										isClearable
										noOptionsMessage={() => "Não encontrado..."}
										theme={(theme: any) => ({
											...theme,
											borderRadius: 4,
											colors: {
												...theme.colors,
												primary25: '#ebebeb',
												primary: 'black',
											},
										})}
										isDisabled={isDisabledAcp_id}
									/>
								</div>
							</div>
						</div>

						<div className="container-cad-prod6">
							<div id="content1">
								<div className="class-checkbox-user2">
									<input type="checkbox" id="checkbox-mobileuser" disabled={mobileUserDisabled} checked={mobileUser} onChange={ValitarActivemobileuser} />
									<h4 className="lab-ativo">Acessar Dispositivo Móvel</h4>
								</div>
							</div>

							<div id="content2">
								<div className="class-checkbox-user2">
									<input type="checkbox" id="checkbox-callportal" disabled={callportalDisabled} checked={callportal} onChange={ValitarActiveCallportal} />
									<h4 className="lab-ativo">Acessar Portal de Chamado</h4>
								</div>
							</div>
							<div id="content3">
								<div className="class-checkbox-user2">
									<input type="checkbox" id="checkbox-centerwebuser" disabled={centerWebUserDisabled} checked={centerWebUser} onChange={ValitarActiveCenterwebuser} />
									<h4 className="lab-ativo">Acessar Center</h4>
								</div>
							</div>
						</div>


						<div className="Bt-button-cad">
							<button type="submit" onClick={confirmarUser} disabled={confirmarUserDisabled}>
								<i className="fa fa-check-circle icon-ok-btn"></i> Salvar
							</button>
							<button type="submit" onClick={cancelar} disabled={cancelarDisabled}>
								<i className="fa fa-times icon-cancelar-btn"></i> Cancelar
							</button>
						</div>

					</div>
				</main>
			</div>

			<ModalIncluirNovo isOpen={openModalIncluirNovo} setModalOpen={() => setOpenModalIncluirNovo(!openModalIncluirNovo)}>
				<>
					<div className='title-modal-incluir'>
						<h1 className="modal-IncluirNovo-title">{qualTitulo}</h1>

						<div onClick={fecharOpenModalIncluirNovo}>
							<button className="fa fa-times icone-modal-IncluirNovo" />
						</div>
					</div>

					<div className='scrol-modal'>
						{qualGrid}
					</div>
				</>
			</ModalIncluirNovo>

			<ModalPrincipal isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)}>

				<div className='container-modal-alert'>
					{qualAlert}
				</div>
			</ModalPrincipal>

		</>
	);

}

export default UsuariosAdd;