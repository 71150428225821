import React, { useEffect, useState, useRef } from 'react'
import Nav from "../../components/Nav";
import Header from "../../components/Header";
import GridPrincipal from '../../components/GridPrincipal'
import api from "../../Services/api";
import { AxiosResponse } from "axios";
import ModalPrincipal from '../../components/ModalAlert/modal'
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from 'react-router';
import Loading from '../../components/Loading';

import {FaUser} from "react-icons/fa";

function Usuarios() {

    const navigate = useNavigate();
    const [openModal, setOpenModal] = useState(false)
    const [qualAlert, setQualAlert] = useState(<></>)
    const [exibirGrid, setExibirGrid] = useState(<></>);

    useEffect(() => {

       // setQualAlert(<div className='div-alert'><Loading /></div>)
		//setOpenModal(true)
        
        validatToken();

    }, []);

    async function validatToken() {

        let ret: AxiosResponse;
        let valor: any;

        const _user = secureLocalStorage.getItem('t')


        api.defaults.headers.common['Authorization'] = _user as ''

        try {

            ret = await api.get("/status/validar")

            if (ret.data.status === 200) {

                secureLocalStorage.setItem('t', ret.data.data);
                secureLocalStorage.setItem('acp', ret.data.decodedToken.acp_integrationid);
               secureLocalStorage.setItem('n', ret.data.decodedToken.name);
                secureLocalStorage.setItem('iag', ret.data.decodedToken.id);
                secureLocalStorage.setItem('cy', ret.data.decodedToken.company);

    

                setExibirGrid(<GridPrincipal
                    iconeExcluir={ModalDeletar}
                    th1='Ativo'
                    th2='ID'
                    th3='Nome'
                    th4='Login'
                    th5='Tipos de pessoas'
                    th6='Data da Inclusão'
                    endPoint='user/buscaruser'
                    col1='1'
                    col2='0'
                    col3='2'
                    col4='3'
                    col5='4'
                    col6='5'
                    fromAdd='/user/add'
                    fromEdit='/user/edit'
                    btnDelete='N'
                    pag="user"
                    buscaruser='user/buscaruser'
                    agentgroup='agentgroup/listar'
                    accessprofile='accessprofile/listar'
                    fecharModal={fecharModal}
                />)

                setOpenModal(false)
            }

        } catch (e: any) {

            if (e.response === undefined) {
				setQualAlert(<div className='div-alert'>
					<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
					<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
					<div className='div-info-alert'><span className="info-alert">Sem comunicação com o servidor.</span></div>
					<div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
				</div>)

				setOpenModal(true)
			} else
				if (e.response.status === 403) {

					setQualAlert(<div className='div-alert'>
						<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
						<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
						<div className='div-info-alert'><span className="info-alert">Token inválido ou expirado.</span></div>
						<div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
					</div>)

					setOpenModal(true)

				} else {
					setQualAlert(<div className='div-alert'>
						<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
						<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
						<div className='div-info-alert'><span className="info-alert">Não foi possível concluir sua solicitação.</span></div>
						<div className='btn-alert' onClick={fecharModal2}><span className="btn-alert-lab">OK</span></div>
					</div>)

					setOpenModal(true)
				}
        }

    }

    	
    function fecharModal2() {
		setOpenModal(false)
	}
    
    function fecharModal() {

        secureLocalStorage.removeItem('t')
        secureLocalStorage.removeItem('acp')
        secureLocalStorage.removeItem('n')
        secureLocalStorage.removeItem('iag')
        navigate('/login');
        setOpenModal(false)

    }




    function ModalDeletar(obj: any) {

        const _type = secureLocalStorage.getItem('acp')

        if (_type === 'DESENVOLVEDOR' || _type === 'ADMINISTRADOR') {
            setOpenModal(true)

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">Deseja deletar o registro?</span></div>
                <div className='div-info-alert'><span className="info-alert">Você não poderá reverter.</span></div>

                <div className='btn-alert-div'>
                    <span className='btn-alert-esc' onClick={() => deletarProduto(obj.age_id)}>
                        <span className="btn-alert-lab">SIM</span>
                    </span>
                    <span className='btn-alert-dir' onClick={finalizarModal2}>
                        <span className="btn-alert-lab">NÃO</span>
                    </span>
                </div>
            </div>
            )
        } else if (_type === 'CONSULTOR') {
            setOpenModal(true)
            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert'><span className="info-alert">O seu perfil de acesso não permite deletar registros.</span></div>
                <div className='btn-alert' onClick={confirmarPerfil}><span className="btn-alert-lab">OK</span></div>
             </div>)

        }
    }
    
    function confirmarPerfil() {
        setOpenModal(false)
    }


    function finalizarModal2() {
        setOpenModal(false)
    }

    async function deletarProduto(id: any) {
        let ret: AxiosResponse;

        setQualAlert(<div className='div-alert'><Loading /></div>)
		setOpenModal(true)

        try {
            ret = await api.delete("user/delete", {
                data: { id: id }
            });
            if (ret.data.status === 200) {
               
                setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-check ControleIconAlert"></span>
                <div className='div-info-alert'><span className="info-alert">Usuário deletado com sucesso.</span></div>
                <div className='btn-alert' onClick={finalizarModal1}><span className="btn-alert-lab">OK</span></div>
            </div>)
      
            }
        } catch (e: any) {
 
            setQualAlert(<div className='div-alert'>
            <span className="fa-regular fa-circle-check ControleIconAlert"></span>
            <div className='div-info-alert'><span className="info-alert">Não foi possível deletar o usuário.</span></div>
            <div className='btn-alert' onClick={finalizarModal1}><span className="btn-alert-lab">OK</span></div>
        </div>)

        }
    }

    function finalizarModal1() {
		window.location.reload();
	}




    return (
        <>
            <Nav />
            <div className="main-content">
                <Header />
                <main>
                    <div className='containerPrincipal'>
                        <div className='ContainerBody'>
                            <div className='div-titulo-pages'>

                            <FaUser />

                                <h1 className='div-titulo-pages-text'>Usuários</h1>
                            </div>

                        </div>
                        <div className='grid-container-principal'>
                            {exibirGrid}
                        </div>
                    </div>
                </main>
            </div>

            <ModalPrincipal isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)}>
                <div className='container-modal-alert'>
                    {qualAlert}
                </div>
            </ModalPrincipal>
        </>
    );

}

export default Usuarios;