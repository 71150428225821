
import React, { useState, useEffect } from 'react'
import Nav from "../../components/Nav";
import Header from "../../components/Header";
import './index.css'
import { MdOutlineAddCircle } from 'react-icons/md';
import Select from 'react-select'
import FormSubcategoria from '../../components/FormSubcategoria';
import FormFabricantes from '../../components/FormFabricantes';
import FormMarca from '../../components/FormMarca';
import FormModelo from '../../components/FormModelo';
import MobileForm from '../../components/MobileForm'
import NotebookForm from '../../components/NotebookForm'
import DesktopForm from '../../components/DesktopForm'
import MonitorForm from '../../components/MonitorForm'
import ServidorForm from '../../components/ServidorForm'
import { AxiosResponse } from "axios";
import api from '../../Services/api'
import { useNavigate } from 'react-router';
import GridSecundario from '../../components/GridSecundario'
import Loading from '../../components/Loading';
import InputCust from '../../components/InputCust'
import secureLocalStorage from "react-secure-storage";
import ModalIncluirNovo from '../../components/ModalIncluirNovo/modal'
import ModalAlert from '../../components/ModalAlert/modal'

function ProdutoAdd() {

    const navigate = useNavigate();
    const [qualGrid, setQualGrid] = useState(<></>)
    const [qualTitulo, setQualTitulo] = useState('')
    const [id, setId] = useState('')
    const [active, setActive] = useState(true)
    const [description, setDescription] = useState('')
    const [alternativeIdentifier, setAlternativeIdentifier] = useState('')
    const [category, setCategory] = useState('')
    const [subcategory, setSubcategory] = useState('')
    const [inclusiondate, setInclusiondate] = useState('');
    const [changedate, setChangedate] = useState('');
    const [manufacturer, setManufacturer] = useState('')
    const [brand, setBrand] = useState('')
    const [model, setModel] = useState('')
    const [observation, setObservation] = useState('')
    const [carregaFabricante, setCarregaFabricante] = useState(GetFabricante);

    const [dadosCampoCust, setDadosCampoCust] = useState([]);
    const [qualForm, setQualForm] = useState(<></>)
    const [dadosForm, setDadosForm] = useState([])

    const [optionsCategory, setOptionsCategory] = useState([])
    const [optionSubcategory, setOptionSubcategory] = useState([])
    const [optionsManufacturer, setOptionsManufacturer] = useState([])
    const [optionsManufacturerCad, setOptionsManufacturerCad] = useState([])
    const [optionsBrand, setOptionsBrand] = useState([])
    const [optionsModel, setOptionsModel] = useState([])

    const [optionsProduct, setOptionsProduct] = useState([])

    const [isDisabledCategory, setIsDisabledCategory] = useState(true)
    const [openModalIncluirNovo, setOpenModalIncluirNovo] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [qualAlert, setQualAlert] = useState(<></>)

    const [activeDisabled, setActiveDisabled] = useState(false)
    const [descriptionDisabled, setDescriptionDisabled] = useState(false)
    const [alternativeIdentifierDisabled, setAlternativeIdentifierDisabled] = useState(false)
    const [categoryDisabled, setCategoryDisabled] = useState(false)
    const [subcategoryDisabled, setSubcategoryDisabled] = useState(false)
    const [manufacturerDisabled, setManufacturerDisabled] = useState(false)
    const [brandDisabled, setBrandDisabled] = useState(false)
    const [modelDisabled, setModelDisabled] = useState(false)
    const [observationDisabled, setObservationDisabled] = useState(false)
    const [confirmarDisabled, setConfirmarDisabled] = useState(false)
    const [cancelarDisabled, setCancelarDisabled] = useState(false)
    const [camposCustDisabled, setCamposCustDisabled] = useState(false)

    const [acpDisabled, setAcpDisabled] = useState(false)

    useEffect(() => {

        setQualAlert(<div className='div-alert'><Loading /></div>)
        setOpenModal(true)

        let today = new Date();
        let date =
            today.getFullYear() +
            "-" +
            (today.getMonth() + 1).toString().padStart(2, "0") +
            "-" +
            today.getDate().toString().padStart(2, "0");
        let time =
            today.getHours().toString().padStart(2, "0") +
            ":" +
            today.getMinutes().toString().padStart(2, "0");
        let JuntarDate = date + "T" + time;

        setInclusiondate(JuntarDate)

        validatToken();



    }, []);



    function fecharOpenModalIncluirNovo() {
        setOpenModalIncluirNovo(false)
    }

    async function GetProduct() {
        let ret: AxiosResponse;
        let valor: any;
        try {
            ret = await api.get("product/verificacaoproduto")
            if (ret.data.status === 200) {
                valor = ret.data.data;

                setOptionsProduct(valor)

            }
        } catch (e) {
            setOptionsProduct([])
        }
    }

    async function validatToken() {

        let ret: AxiosResponse;
        let valor: any;

        const _user = secureLocalStorage.getItem('t')

        api.defaults.headers.common['Authorization'] = _user as ''

        try {

            ret = await api.get("/status/validar")

            if (ret.data.status === 200) {

                secureLocalStorage.setItem('t', ret.data.data);
                secureLocalStorage.setItem('acp', ret.data.decodedToken.acp_integrationid);
                secureLocalStorage.setItem('n', ret.data.decodedToken.name);
                secureLocalStorage.setItem('iag', ret.data.decodedToken.id);
                secureLocalStorage.setItem('cy', ret.data.decodedToken.company);


                GetCategoria();
                GetProduct();

                if (ret.data.decodedToken.acp_integrationid === 'DESENVOLVEDOR' || ret.data.decodedToken.acp_integrationid === 'ADMINISTRADOR') {

                    setDescriptionDisabled(false)
                    setDescriptionDisabled(false)
                    setAlternativeIdentifierDisabled(false)
                    setCategoryDisabled(false)
                    setSubcategoryDisabled(false)
                    setManufacturerDisabled(false)
                    setBrandDisabled(false)
                    setModelDisabled(false)
                    setObservationDisabled(false)
                    setConfirmarDisabled(false)
                    setCancelarDisabled(false)
                    setActiveDisabled(false)
                    setCamposCustDisabled(false)
                    setAcpDisabled(false)
                } else if (ret.data.decodedToken.acp_integrationid === 'CONSULTOR') {

                    setDescriptionDisabled(true)
                    setDescriptionDisabled(true)
                    setAlternativeIdentifierDisabled(true)
                    setCategoryDisabled(true)
                    setSubcategoryDisabled(true)
                    setManufacturerDisabled(true)
                    setBrandDisabled(true)
                    setModelDisabled(true)
                    setObservationDisabled(true)
                    setConfirmarDisabled(true)
                    setCancelarDisabled(true)
                    setActiveDisabled(true)
                    setCamposCustDisabled(true)
                    setAcpDisabled(true)
                }

                setOpenModal(false)

            }

        } catch (e: any) {

            if (e.response.data.status === 403) {

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
                    <div className='btn-alert' onClick={fecharModalToken}><span className="btn-alert-lab">OK</span></div>
                </div>)


            }
        }

    }

    function fecharModalToken() {
        secureLocalStorage.removeItem('t')
        secureLocalStorage.removeItem('acp')
        secureLocalStorage.removeItem('n')
        secureLocalStorage.removeItem('iag')
        navigate('/login');
        setOpenModal(false)
    }

    function informacoes() {
        const dados = {
            id: id,
            active: active,
            description: description,
            alternativeIdentifier: alternativeIdentifier,
            category: category,
            subcategory: subcategory,
            manufacturer: manufacturer,
            brand: brand,
            model: model,
            observation: observation
        }

        return dados;
    }


    async function GetCategoria() {
        let ret: AxiosResponse;
        let valor: any;
        try {
            ret = await api.get("category/listar")
            if (ret.data.status === 200) {
                valor = ret.data.data;
                const arr = [] as any;
                let result = valor;
                result.map((user: any) => {
                    return arr.push({ value: String(user.ctg_id), label: user.ctg_description });
                });
                setOptionsCategory(arr)

            }
        } catch (e) {
            setOptionsCategory([])
        }
    }

    async function GetFabricante() {
        let ret: AxiosResponse;
        let valor: any;
        setBrand("")
        try {
            ret = await api.get("manufacturer/listar")
            if (ret.data.status === 200) {
                valor = ret.data.data;

                const arr = [] as any;
                let result = valor;
                result.map((user: any) => {
                    return arr.push({ value: String(user.mfr_id), label: user.mfr_description });
                });
                setOptionsManufacturer(arr)
                setOptionsManufacturerCad(valor)
            }
        } catch (e) {
            setOptionsManufacturer([])
            setOptionsManufacturerCad([])
        }
    }

    async function GetMarca(fabricante: any) {
        let ret: AxiosResponse;
        let valor: any;
        setOptionsModel([])
        setOptionsBrand([])
        if (fabricante === "Selecione..." || fabricante === "") {
            setManufacturer("")
            setBrand("")
            setModel("")
            setOptionsBrand([])
            setOptionsModel([])
        } else {
            setQualAlert(<div className='div-alert'><Loading /></div>)
            setOpenModal(true)
            setManufacturer(fabricante)
            setBrand("")
            setModel("")
            try {
                ret = await api.get("brand/listar", {
                    params: {
                        id: fabricante
                    }
                })
                if (ret.data.status === 200) {
                    valor = ret.data.data;

                    const arr = [] as any;
                    let result = valor;
                    result.map((user: any) => {
                        return arr.push({ value: String(user.brn_id), label: user.brn_description });
                    });
                    setOptionsBrand(arr)
                }else{
                    setOptionsBrand([])
                }
                setOpenModal(false)
            } catch (e) {
                setOpenModal(false)
                setOptionsBrand([])
            }
        }
    }

    async function GetModelo(marca: any) {
        let ret: AxiosResponse;
        let valor: any;
        setOptionsModel([])

        if (marca === "Selecione..." || marca === "") {
            setBrand("")
            setModel("")
            setOptionsModel([])
        } else {
            setQualAlert(<div className='div-alert'><Loading /></div>)
            setOpenModal(true)

            setBrand(marca)
            try {
                ret = await api.get("model/listar", {
                    params: {
                        id: marca
                    }
                })
                if (ret.data.status === 200) {
                    valor = ret.data.data;
                    const arr = [] as any;
                    let result = valor;
                    result.map((user: any) => {
                        return arr.push({ value: String(user.mde_id), label: user.mde_description });
                    });
                    setOptionsModel(arr)
                }else{
                    setOptionsModel([])
                }
                setOpenModal(false)
            } catch (e) {
                setOpenModal(false)
                setOptionsModel([])
            }
        }
    }

    function ChamarModalProduto(tipoModal: any) {

        if (tipoModal === 'Fabricante') {
            setOptionsModel([])
            sessionStorage.removeItem("dadosFab");
            setQualTitulo('Cadastrar Fabricante')
            setQualGrid((<><GridSecundario
                PegarValorGrid={PegarValorGridManufacturer}
                Iconeadd={ChamarCadastroFabricante}
                Iconeditar={EditarFabricante}
                iconeExcluir={FabricanteDeletar}
                th1='Ativo'
                th2='ID'
                th3='Descrição'
                th4='Identificador Alternativo'
                th5=''
                th6=''
                endPoint='manufacturer/buscarmanufacturer'
                consult='N'
                col1='1'
                col2='2'
                col3='3'
                ambiente='%'
                dbtnDelete="N"
                pag='ok'
            /></>)
            )
            setOpenModalIncluirNovo(true)
        } else if (tipoModal === 'Marca') {
            if (manufacturer === "" || manufacturer === undefined || manufacturer === "Selecione...") {

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Selecione o fabricante.</span></div>
                    <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)
            } else {
                setBrand("")
                setOptionsModel([])
                setQualTitulo('Cadastrar Marca')
                setQualGrid((<><GridSecundario PegarValorGrid={PegarValorGridBrand} Iconeadd={ChamarCadastroMarca} Iconeditar={EditarMarca} iconeExcluir={MarcaDeletar}
                    th1='Ativo'
                    th2='ID'
                    th3='Descrição'
                    th4='Identificador Alternativo'
                    th5=''
                    th6=''
                    endPoint='brand/buscarbrand'
                    consult='N'
                    col1='1'
                    col2='2'
                    col3='3'
                    ambiente={'%'}
                    params={manufacturer}
                    dbtnDelete="N"
                    pag="ok"
                /></>))

                setOpenModalIncluirNovo(true)
            }
        } else if (tipoModal === 'Modelos') {
            if (brand === "" || brand === undefined || brand === "Selecione...") {

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Selecione a marca.</span></div>
                    <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                </div>)
                setOpenModal(true)

            }
            else {
                setModel("")

                setQualTitulo('Cadastrar Modelo')
                setQualGrid((<><GridSecundario PegarValorGrid={PegarValorGridModel} Iconeadd={ChamarCadastroModelo} Iconeditar={EditarModelo} iconeExcluir={ModeloDeletar}
                    th1='Ativo'
                    th2='ID'
                    th3='Descrição'
                    th4='Identificador Alternativo'
                    th5=''
                    th6=''
                    endPoint='model/buscarmodel'
                    consult='N'
                    col1='1'
                    col2='2'
                    col3='3'
                    ambiente={'%'}
                    params={brand}
                    dbtnDelete="N"
                    pag="ok"
                /></>))

                setOpenModalIncluirNovo(true)
            }
        } else if (tipoModal === 'Subcategoria') {


            if (category === "" || category === undefined || category === null) {


                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Selecione a categoria para visualizar uma nova subcategoria.</span></div>
                    <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)
            }
            else {



                setSubcategory("");

                setQualTitulo('Cadastrar Subcategoria')
                setQualGrid((<><GridSecundario
                    PegarValorGrid={PegarValorGridSubCategoria}
                    Iconeadd={ChamarCadastroSubcategoria}
                    Iconeditar={EditarSubcategoria}
                    iconeExcluir={ModeloSubcategoria}
                    th1='Ativo'
                    th2='ID'
                    th3='Descrição'
                    th4='Identificador Alternativo'
                    th5=''
                    th6=''
                    endPoint='subcategory/buscarsubcategory'
                    consult='N'
                    col1='1'
                    col2='2'
                    col3='3'
                    ambiente={'%'}
                    params={category}
                    dbtnDelete="N"
                    pag="ok"
                /></>))

                setOpenModalIncluirNovo(true)

            }

        }



    }

    //=================================Subcategoria=============================================
    function ChamarCadastroSubcategoria() {
        sessionStorage.removeItem("dadosSub");
        setQualGrid(<><FormSubcategoria voltar={BtnVoltarSubcategoria} category={category} /></>)
    }

    function BtnVoltarSubcategoria() {
        setQualGrid((<><GridSecundario
            PegarValorGrid={PegarValorGridSubCategoria}
            Iconeadd={ChamarCadastroSubcategoria}
            Iconeditar={EditarSubcategoria}
            iconeExcluir={ModeloSubcategoria}
            th1='Ativo'
            th2='ID'
            th3='Descrição'
            th4='Identificador Alternativo'
            th5=''
            th6=''
            endPoint='subcategory/buscarsubcategory'
            consult='N'
            col1='1'
            col2='2'
            col3='3'
            ambiente={'%'}
            params={category}
            dbtnDelete="N"
            pag="ok"
        /></>))
        IncluirCategoria(category)

    }

    async function EditarSubcategoria(obj: any) {
        sessionStorage.setItem("dadosSub", JSON.stringify(obj));
        setQualGrid(<><FormSubcategoria voltar={BtnVoltarSubcategoria} category={category} /></>)
    }


    function ModeloSubcategoria(obj: any) {

        setOpenModal(true)

        setQualAlert(<div className='div-alert'>
            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
            <div className='div-info-alert-atencao'><span className="info-alert-atencao">Deseja deletar o registro?</span></div>
            <div className='div-info-alert'><span className="info-alert">Você não poderá reverter.</span></div>

            <div className='btn-alert-div'>
                <span className='btn-alert-esc' onClick={() => deletarSubcategoria(obj.stg_id)}>
                    <span className="btn-alert-lab">SIM</span>
                </span>
                <span className='btn-alert-dir' onClick={finalizarModal2}>
                    <span className="btn-alert-lab">NÃO</span>
                </span>
            </div>
        </div>)

    }

    async function deletarSubcategoria(id: any) {

        setOpenModal(false)

        setQualGrid(<><Loading /></>)
        let ret: AxiosResponse;
        try {
            ret = await api.delete("subcategory/delete", {
                data: { id: id }
            });
            if (ret.data.status === 200) {

                setQualGrid((<><GridSecundario
                    PegarValorGrid={PegarValorGridSubCategoria}
                    Iconeadd={ChamarCadastroSubcategoria}
                    Iconeditar={EditarSubcategoria}
                    iconeExcluir={ModeloSubcategoria}
                    th1='Ativo'
                    th2='ID'
                    th3='Descrição'
                    th4='Identificador Alternativo'
                    th5=''
                    th6=''
                    endPoint='subcategory/buscarsubcategory'
                    consult='N'
                    col1='1'
                    col2='2'
                    col3='3'
                    ambiente={'%'}
                    params={category}
                    dbtnDelete="N"
                    pag="ok"
                /></>))
                IncluirCategoria(category)
            }
        } catch (e: any) {
            setQualGrid((<><GridSecundario
                PegarValorGrid={PegarValorGridSubCategoria}
                Iconeadd={ChamarCadastroSubcategoria}
                Iconeditar={EditarSubcategoria}
                iconeExcluir={ModeloSubcategoria}
                th1='Ativo'
                th2='ID'
                th3='Descrição'
                th4='Identificador Alternativo'
                th5=''
                th6=''
                endPoint='subcategory/buscarsubcategory'
                consult='N'
                col1='1'
                col2='2'
                col3='3'
                ambiente={'%'}
                params={category}
                dbtnDelete="N"
                pag="ok"
            /></>))
            IncluirCategoria(category)
        }
    }


    //=================================Modelo=============================================
    async function EditarModelo(obj: any) {
        sessionStorage.setItem("dadosFab", JSON.stringify(obj));
        setQualGrid(<><FormModelo voltar={BtnVoltarModelo} manufacturer={manufacturer} brand={brand} /></>)
    }

    function ChamarCadastroModelo() {
        sessionStorage.removeItem("dadosFab");
        setQualGrid(<><FormModelo voltar={BtnVoltarModelo} manufacturer={manufacturer} brand={brand} /></>)
    }

    function BtnVoltarModelo() {
        setQualGrid((<><GridSecundario PegarValorGrid={PegarValorGridModel} Iconeadd={ChamarCadastroModelo} Iconeditar={EditarModelo} iconeExcluir={ModeloDeletar}
            th1='Ativo'
            th2='ID'
            th3='Descrição'
            th4='Identificador Alternativo'
            th5=''
            th6=''
            endPoint='model/buscarmodel'
            consult='N'
            col1='1'
            col2='2'
            col3='3'
            ambiente={'%'}
            params={brand}
            dbtnDelete="N"
            pag="ok"
        /></>))

        GetModelo(brand)

    }

    function ModeloDeletar(obj: any) {

        setOpenModal(true)

        setQualAlert(<div className='div-alert'>
            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
            <div className='div-info-alert-atencao'><span className="info-alert-atencao">Deseja deletar o registro?</span></div>
            <div className='div-info-alert'><span className="info-alert">Você não poderá reverter.</span></div>

            <div className='btn-alert-div'>
                <span className='btn-alert-esc' onClick={() => deletarModelo(obj.mde_id)}>
                    <span className="btn-alert-lab">SIM</span>
                </span>
                <span className='btn-alert-dir' onClick={finalizarModal2}>
                    <span className="btn-alert-lab">NÃO</span>
                </span>
            </div>
        </div>)


    }

    async function deletarModelo(id: any) {
        setOpenModal(false)
        setQualGrid(<><Loading /></>)
        let ret: AxiosResponse;
        try {
            ret = await api.delete("model/delete", {
                data: { id: id }
            });
            if (ret.data.status === 200) {

                setQualGrid((<><GridSecundario PegarValorGrid={PegarValorGridModel} Iconeadd={ChamarCadastroModelo} Iconeditar={EditarModelo} iconeExcluir={ModeloDeletar}
                    th1='Ativo'
                    th2='ID'
                    th3='Descrição'
                    th4='Identificador Alternativo'
                    th5=''
                    th6=''
                    endPoint='model/buscarmodel'
                    consult='N'
                    col1='1'
                    col2='2'
                    col3='3'
                    ambiente={'%'}
                    params={brand}
                    dbtnDelete="N"
                    pag="ok"
                /></>))

                GetModelo(brand)

            }
        } catch (e: any) {
            setQualGrid((<><GridSecundario PegarValorGrid={PegarValorGridModel} Iconeadd={ChamarCadastroModelo} Iconeditar={EditarModelo} iconeExcluir={ModeloDeletar}
                th1='Ativo'
                th2='ID'
                th3='Descrição'
                th4='Identificador Alternativo'
                th5=''
                th6=''
                endPoint='model/buscarmodel'
                consult='N'
                col1='1'
                col2='2'
                col3='3'
                ambiente={'%'}
                params={brand}
                dbtnDelete="N"
                pag="ok"
            /></>))

        }
    }
    //=================================Modelo=============================================

    //=================================Marca=============================================
    async function EditarMarca(obj: any) {
        sessionStorage.setItem("dadosFab", JSON.stringify(obj));
        setQualGrid(<><FormMarca voltar={BtnVoltarMarca} manufacturer={manufacturer} /></>)
    }

    function ChamarCadastroMarca() {
        sessionStorage.removeItem("dadosFab");
        setQualGrid(<><FormMarca voltar={BtnVoltarMarca} manufacturer={manufacturer} /></>)
    }

    function BtnVoltarMarca() {
        setQualGrid((<><GridSecundario PegarValorGrid={PegarValorGridBrand} Iconeadd={ChamarCadastroMarca} Iconeditar={EditarMarca} iconeExcluir={MarcaDeletar}
            th1='Ativo'
            th2='ID'
            th3='Descrição'
            th4='Identificador Alternativo'
            th5=''
            th6=''
            endPoint='brand/buscarbrand'
            consult='N'
            col1='1'
            col2='2'
            col3='3'
            ambiente={'%'}
            params={manufacturer}
            dbtnDelete="N"
            pag="ok"
        /></>))
        GetMarca(manufacturer);
    }

    function MarcaDeletar(obj: any) {



        setQualAlert(<div className='div-alert'>
            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
            <div className='div-info-alert-atencao'><span className="info-alert-atencao">Deseja deletar o registro?</span></div>
            <div className='div-info-alert'><span className="info-alert">Você não poderá reverter.</span></div>

            <div className='btn-alert-div'>
                <span className='btn-alert-esc' onClick={() => deletarMarca(obj.brn_id)}>
                    <span className="btn-alert-lab">SIM</span>
                </span>
                <span className='btn-alert-dir' onClick={finalizarModal2}>
                    <span className="btn-alert-lab">NÃO</span>
                </span>
            </div>
        </div>)

        setOpenModal(true)
    }

    async function deletarMarca(id: any) {

        setOpenModal(false)
        setQualGrid(<><Loading /></>)
        let ret: AxiosResponse;
        try {
            ret = await api.delete("brand/delete", {
                data: { id: id }
            });
            if (ret.data.status === 200) {

                setQualGrid((<><GridSecundario PegarValorGrid={PegarValorGridBrand} Iconeadd={ChamarCadastroMarca} Iconeditar={EditarMarca} iconeExcluir={MarcaDeletar}
                    th1='Ativo'
                    th2='ID'
                    th3='Descrição'
                    th4='Identificador Alternativo'
                    th5=''
                    th6=''
                    endPoint='brand/buscarbrand'
                    consult='N'
                    col1='1'
                    col2='2'
                    col3='3'
                    ambiente={'%'}
                    params={manufacturer}
                    dbtnDelete="N"
                    pag="ok"
                /></>))
                GetMarca(manufacturer);

            }
        } catch (e: any) {
            setQualGrid((<><GridSecundario PegarValorGrid={PegarValorGridBrand} Iconeadd={ChamarCadastroMarca} Iconeditar={EditarMarca} iconeExcluir={MarcaDeletar}
                th1='Ativo'
                th2='ID'
                th3='Descrição'
                th4='Identificador Alternativo'
                th5=''
                th6=''
                endPoint='brand/buscarbrand'
                consult='N'
                col1='1'
                col2='2'
                col3='3'
                ambiente={'%'}
                params={manufacturer}
                dbtnDelete="N"
                pag="ok"
            /></>))
        }
    }
    //=================================Marca=============================================


    //=================================Fabricant=============================================
    async function EditarFabricante(obj: any) {
        sessionStorage.setItem("dadosFab", JSON.stringify(obj));
        setQualGrid(<><FormFabricantes voltar={BtnVoltarFabricante} /></>)
    }

    function ChamarCadastroFabricante() {
        sessionStorage.removeItem("dadosFab");
        setQualGrid(<><FormFabricantes voltar={BtnVoltarFabricante} /></>)
    }

    function BtnVoltarFabricante() {

        setQualGrid((<><GridSecundario
            PegarValorGrid={PegarValorGridManufacturer}
            Iconeadd={ChamarCadastroFabricante}
            Iconeditar={EditarFabricante}
            iconeExcluir={FabricanteDeletar}
            th1='Ativo'
            th2='ID'
            th3='Descrição'
            th4='Identificador Alternativo'
            th5=''
            th6=''
            endPoint='manufacturer/buscarmanufacturer'
            consult='N'
            col1='1'
            col2='2'
            col3='3'
            ambiente='%'
            dbtnDelete="N"
            pag='ok'
        /></>)
        )
        GetFabricante();
        setManufacturer("");
    }

    function FabricanteDeletar(obj: any) {


        setOpenModal(true)

        setQualAlert(<div className='div-alert'>
            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
            <div className='div-info-alert-atencao'><span className="info-alert-atencao">Deseja deletar o registro?</span></div>
            <div className='div-info-alert'><span className="info-alert">Você não poderá reverter.</span></div>

            <div className='btn-alert-div'>
                <span className='btn-alert-esc' onClick={() => deletarFabricante(obj.mfr_id)}>
                    <span className="btn-alert-lab">SIM</span>
                </span>
                <span className='btn-alert-dir' onClick={finalizarModal2}>
                    <span className="btn-alert-lab">NÃO</span>
                </span>
            </div>
        </div>)

    }

    function finalizarModal2() {
        setOpenModal(false)
    }

    async function deletarFabricante(id: any) {
        setOpenModal(false)
        setQualGrid(<><Loading /></>)
        let ret: AxiosResponse;
        try {
            ret = await api.delete("manufacturer/delete", {
                data: { id: id }
            });
            if (ret.data.status === 200) {

                setQualGrid((<><GridSecundario
                    PegarValorGrid={PegarValorGridManufacturer}
                    Iconeadd={ChamarCadastroFabricante}
                    Iconeditar={EditarFabricante}
                    iconeExcluir={FabricanteDeletar}
                    th1='Ativo'
                    th2='ID'
                    th3='Descrição'
                    th4='Identificador Alternativo'
                    th5=''
                    th6=''
                    endPoint='manufacturer/buscarmanufacturer'
                    consult='N'
                    col1='1'
                    col2='2'
                    col3='3'
                    ambiente='%'
                    dbtnDelete="N"
                    pag='ok'
                /></>)
                )
                GetFabricante();
                setManufacturer("");
            }
        } catch (e: any) {
            setQualGrid((<><GridSecundario
                PegarValorGrid={PegarValorGridManufacturer}
                Iconeadd={ChamarCadastroFabricante}
                Iconeditar={EditarFabricante}
                iconeExcluir={FabricanteDeletar}
                th1='Ativo'
                th2='ID'
                th3='Descrição'
                th4='Identificador Alternativo'
                th5=''
                th6=''
                endPoint='manufacturer/buscarmanufacturer'
                consult='N'
                col1='1'
                col2='2'
                col3='3'
                ambiente='%'
                dbtnDelete="N"
                pag='ok'
            /></>)
            )
        }
    }
    //=================================Fabricant=============================================

    function ValitarActive() {
        let e = document.getElementById("checkbox-active") as HTMLInputElement;

        if (e.checked) {

            setActive(true)
        } else {

            setActive(false)
        }
    }


    function Inclurdados(data: any) {
        setDadosForm(data)
    }


    function fecharModal() {
        setOpenModal(false)
    }

    async function Confirmar() {
        let ret: AxiosResponse;
        let valor: any;


        setQualAlert(<div className='div-alert'><Loading /></div>)
        setOpenModal(true)

        const _user = secureLocalStorage.getItem('t')

        api.defaults.headers.common['Authorization'] = _user as ''

        try {

            ret = await api.get("/status/validar")

            if (ret.data.status === 200) {


                const FiltrarDescricao = optionsProduct.filter(
                    (e: any) => {
                        return e.pdc_description.toLowerCase() === description.toLowerCase()
                    }
                );

                const FiltrarAlternativeIdentifier = optionsProduct.filter(
                    (e: any) => {
                        return e.pdc_alternativeidentifier.toLowerCase() === alternativeIdentifier.toLowerCase()
                    }
                );


                if (description === "") {

                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Digite a descrição.</span></div>
                        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                    </div>)

                } else if (FiltrarDescricao.length > 0) {

                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Descrição já está em uso.</span></div>
                        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                    </div>)

                }

                else if (alternativeIdentifier === "") {

                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Digite o identificador alternativo.</span></div>
                        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                    </div>)

                }



                else if (FiltrarAlternativeIdentifier.length > 0) {

                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Identificador alternativo já está em uso.</span></div>
                        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                    </div>)

                }

                else
                    if (category === "" || category === "Selecione...") {

                        setQualAlert(<div className='div-alert'>
                            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                            <div className='div-info-alert'><span className="info-alert">Selecione a categoria.</span></div>
                            <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                        </div>)

                    } else
                        if (subcategory === "" || subcategory === "Selecione...") {

                            setQualAlert(<div className='div-alert'>
                                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                                <div className='div-info-alert'><span className="info-alert">Selecione a subcategoria.</span></div>
                                <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                            </div>)

                        } else {
                            let JuntarObj = dadosForm as any;

                            const dados = {
                                pdc_active: active,
                                pdc_description: description,
                                pdc_alternativeidentifier: alternativeIdentifier,
                                ctg_id: category,
                                stg_id: subcategory,
                                mfr_id: manufacturer,
                                brn_id: brand,
                                mde_id: model,
                                pdc_observation: observation,
                                age_id_insert: secureLocalStorage.getItem('iag'),
                            }
                            const newObj = { ...dados, ...JuntarObj };
                            insertProduto([newObj])

                        }

            }

        } catch (e: any) {

            if (e.response.data.status === 403) {

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
                    <div className='btn-alert' onClick={fecharModalToken}><span className="btn-alert-lab">OK</span></div>
                </div>)


            }
        }

    }

    function finalizarModal1() {
        navigate('/product');
    }

    async function insertProduto(dados: any) {
        let ret: AxiosResponse;


        try {
            ret = await api.post("product/insert", {
                data: dados,
                dadosCampoCust: dadosCampoCust
            });
            if (ret.data.status === 200) {

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-check ControleIconAlert"></span>
                    <div className='div-info-alert'><span className="info-alert">Cadastro realizado com sucesso!</span></div>
                    <div className='btn-alert' onClick={finalizarModal1}><span className="btn-alert-lab">OK</span></div>
                </div>)





            }
        } catch (e: any) {


            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Não foi possivel concluir o cadastramento.</span></div>
                <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
            </div>)
        }
    }

    function Cancelar() {
        navigate('/product');
    }

    function incluirDadosCamposCust(dados: any) {
        setDadosCampoCust(dados)
    }

    const customStyles = {
        control: (provided: any, state: any) => ({
            ...provided,
            background: '#fff',
            minHeight: '30px',
            height: '30px',
            borderColor: state.isFocused ? 'grey' : '#92989e',

        }),


        valueContainer: (provided: any, state: any) => ({
            ...provided,
            height: '30px',
            padding: '0 6px'
        }),

        input: (provided: any, state: any) => ({
            ...provided,
            margin: '0px',
            fontSize: 14,

        }),
        indicatorsContainer: (provided: any, state: any) => ({
            ...provided,
            height: '30px',
        }),
    };

    async function IncluirCategoria(categoria: any) {

        let ret: AxiosResponse;
        let valor: any;

        setQualForm(<></>)
        setManufacturer('')
        setBrand('')
        setModel('')

        if (categoria === '' || categoria === 'Selecione...') {
            setSubcategory('');
            setCategory('');
            setOptionSubcategory([])
            setIsDisabledCategory(true)
        } else {
            setQualAlert(<div className='div-alert'><Loading /></div>)
            setOpenModal(true)
            setSubcategory("")
            setCategory(categoria)
            setIsDisabledCategory(false)
            try {
                ret = await api.get("subcategory/listar", {
                    params: {
                        id: categoria
                    }
                })
                if (ret.data.status === 200) {
                    valor = ret.data.data;

                    const arr = [] as any;
                    let result = valor;
                    result.map((user: any) => {
                        return arr.push({ value: String(user.stg_id), label: user.stg_description });
                    });
                    setOptionSubcategory(arr)

                }else{
                    setOptionSubcategory([])
                }
                setOpenModal(false)
            } catch (e) {
                setOpenModal(false)
                setOptionSubcategory([])
            }
        }

    };

    function IncluirSubcategoria(subcategoria: any) {
        setSubcategory(subcategoria)
        setQualForm(<></>)
        setManufacturer('')
        setBrand('')
        setModel('')

        if (subcategoria === 'Selecione...') {
            setQualForm(<></>)
        } else if (subcategoria === '1' || subcategoria === '3') {
            setQualForm(<MobileForm data={[]} informacoes={informacoes} test={Inclurdados} acpDisabled={acpDisabled} />)
        } else if (subcategoria === '4') {
            setQualForm(<NotebookForm data={[]} informacoes={informacoes} test={Inclurdados} acpDisabled={acpDisabled} />)
        }
        else if (subcategoria === '5') {
            setQualForm(<DesktopForm data={[]} informacoes={informacoes} test={Inclurdados} acpDisabled={acpDisabled} />)
        } else if (subcategoria === '2') {
            setQualForm(<MonitorForm data={[]} informacoes={informacoes} test={Inclurdados} acpDisabled={acpDisabled} />)
        } else if (subcategoria === '17') {
            setQualForm(<ServidorForm data={[]} informacoes={informacoes} test={Inclurdados} acpDisabled={acpDisabled} />)
        }
    }

    function PegarValorGridSubCategoria(valor: any) {

        setSubcategory(String(valor.stg_id))
        setOpenModalIncluirNovo(false)

    }

    function PegarValorGridManufacturer(valor: any) {

        GetMarca(String(valor.mfr_id))

        setOpenModalIncluirNovo(false)

    }

    function PegarValorGridBrand(valor: any) {

        if (manufacturer === "") {
            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Selecione o fabricante.</span></div>
                <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
            </div>)
        } else {

            GetModelo(String(valor.brn_id))
            setOpenModalIncluirNovo(false)
        }
    }

    function PegarValorGridModel(valor: any) {

        if (brand === "") {
            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Selecione a marca.</span></div>
                <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
            </div>)
        } else {
            setModel(String(valor.mde_id))
            setOpenModalIncluirNovo(false)
        }
    }

    return (
        <>
            <Nav />
            <div className="main-content">
                <Header />
                <main>
                    <div className='container-principal'>
                        <div className="title-Page">
                            <div className="title-text">
                                <div className="size-x circle">1</div>
                                <h2>Defina as informações gerais do Produto</h2>
                            </div>
                            <hr />
                        </div>
                        <div className="class-checkbox">
                            <input type="checkbox" id="checkbox-active" disabled={activeDisabled} checked={active} onChange={ValitarActive} />
                            <h4 className="lab-ativo">Ativo</h4>
                        </div>

                        <div className="container-cad-prod1">
                            <div id="content1">
                                <div className="input-box-ativos">
                                    <span className="details">Descrição *</span>
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        onChange={(e) => setDescription(e.target.value)}
                                        value={description}
                                        disabled={descriptionDisabled}
                                    />
                                </div>
                            </div>
                            <div id="content2">
                                <div className="input-box-ativos">
                                    <span className="details">Identificador Alternativo *</span>
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        onChange={(e) => setAlternativeIdentifier(e.target.value)}
                                        value={alternativeIdentifier}
                                        disabled={alternativeIdentifierDisabled}
                                    />
                                </div>
                            </div>
                            <div id="content3">
                                <div className="select-box-ativos-select">
                                    <span className="details">Categoria *</span>
                                    <Select
                                        maxMenuHeight={150}
                                        placeholder={'Selecione...'}
                                        options={optionsCategory}
                                        styles={customStyles}
                                        value={optionsCategory.filter(function (option: any) {
                                            return option.value === category;
                                        })}
                                        onChange={(options: any) =>
                                            !options ? IncluirCategoria("") : IncluirCategoria(options.value)
                                        }
                                        isClearable
                                        noOptionsMessage={() => "Não encontrado..."}
                                        theme={(theme: any) => ({
                                            ...theme,
                                            borderRadius: 4,
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#ebebeb',
                                                primary: 'black',
                                            },
                                        })}
                                        isDisabled={categoryDisabled}
                                    />
                                </div>
                            </div>
                        </div>



                        <div className="container-cad-prod2">
                            <div id="content1">
                                <div className="select-box-ativos-select">
                                    <div className='details-flex'>
                                        <span className="details">Subcategoria *</span>
                                        <MdOutlineAddCircle className='details-flex-icon-ativo' />
                                        <span className="details-esc-ativo" onClick={() => ChamarModalProduto('Subcategoria')}>Incluir Novo</span>
                                    </div>

                                    <Select
                                        maxMenuHeight={160}
                                        placeholder={'Selecione...'}
                                        options={optionSubcategory}
                                        styles={customStyles}
                                        value={optionSubcategory.filter(function (option: any) {
                                            return option.value === subcategory;
                                        })}
                                        onChange={(options: any) =>
                                            !options ? IncluirSubcategoria("") : IncluirSubcategoria(options.value)
                                        }

                                        isClearable
                                        noOptionsMessage={() => "Não encontrado..."}
                                        theme={(theme: any) => ({
                                            ...theme,
                                            borderRadius: 4,
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#ebebeb',
                                                primary: 'black',
                                            },
                                        })}
                                        isDisabled={isDisabledCategory}
                                    />
                                </div>
                            </div>
                            <div id="content2">
                                <div className="input-box-ativos">
                                    <span className="details">Data da inclusão</span>
                                    <input
                                        type="datetime-local"
                                        id="DATA-TIME"
                                        name="DATE-TIME"
                                        disabled
                                        value={inclusiondate}
                                    />
                                </div>
                            </div>
                            <div id="content3">
                                <div className="input-box-ativos">
                                    <span className="details">Data da última atualização</span>
                                    <input
                                        type="datetime-local"
                                        id="DATA-TIME"
                                        name="DATE-TIME"
                                        disabled
                                        value={changedate}
                                    />
                                </div>
                            </div>
                        </div>


                        <div className="container-cad-prod2">
                            <div id="content1">
                                <div className="select-box-ativos-select">
                                    <div className='details-flex'>
                                        <span className="details">Fabricante</span>
                                        <MdOutlineAddCircle className='details-flex-icon-ativo' />
                                        <span className="details-esc-ativo" onClick={() => ChamarModalProduto('Fabricante')}>Incluir Novo</span>
                                    </div>
                                    <Select
                                        maxMenuHeight={150}
                                        placeholder={'Selecione...'}
                                        options={optionsManufacturer}
                                        styles={customStyles}
                                        value={optionsManufacturer.filter(function (option: any) {
                                            return option.value === manufacturer;
                                        })}
                                        onChange={(options: any) =>
                                            !options ? GetMarca("") : GetMarca(options.value)
                                        }
                                        isClearable
                                        noOptionsMessage={() => "Não encontrado..."}
                                        theme={(theme: any) => ({
                                            ...theme,
                                            borderRadius: 4,
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#ebebeb',
                                                primary: 'black',
                                            },
                                        })}
                                        isDisabled={manufacturerDisabled}
                                    />
                                </div>
                            </div>
                            <div id="content2">
                                <div className="select-box-ativos-select">
                                    <div className='details-flex'>
                                        <span className="details">Marca</span>
                                        <MdOutlineAddCircle className='details-flex-icon-ativo' />
                                        <span className="details-esc-ativo" onClick={() => ChamarModalProduto('Marca')}>Incluir Novo</span>
                                    </div>
                                    <Select
                                        maxMenuHeight={150}
                                        placeholder={'Selecione...'}
                                        options={optionsBrand}
                                        styles={customStyles}
                                        value={optionsBrand.filter(function (option: any) {
                                            return option.value === brand;
                                        })}
                                        onChange={(options: any) =>
                                            !options ? GetModelo("") : GetModelo(options.value)
                                        }
                                        isClearable
                                        noOptionsMessage={() => "Não encontrado..."}
                                        theme={(theme: any) => ({
                                            ...theme,
                                            borderRadius: 4,
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#ebebeb',
                                                primary: 'black',
                                            },
                                        })}
                                        isDisabled={brandDisabled}
                                    />
                                </div>

                            </div>
                            <div id="content3">
                                <div className="select-box-ativos-select">
                                    <div className='details-flex'>
                                        <span className="details">
                                            Modelo</span>
                                        <MdOutlineAddCircle className='details-flex-icon-ativo' />
                                        <span className="details-esc-ativo" onClick={() => ChamarModalProduto('Modelos')}>Incluir Novo</span>
                                    </div>
                                    <Select
                                        maxMenuHeight={150}
                                        placeholder={'Selecione...'}
                                        options={optionsModel}
                                        styles={customStyles}
                                        value={optionsModel.filter(function (option: any) {
                                            return option.value === model;
                                        })}
                                        onChange={(options: any) =>
                                            !options ? setModel("") : setModel(options.value)
                                        }
                                        isClearable
                                        noOptionsMessage={() => "Não encontrado..."}
                                        theme={(theme: any) => ({
                                            ...theme,
                                            borderRadius: 4,
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#ebebeb',
                                                primary: 'black',
                                            },
                                        })}
                                        isDisabled={modelDisabled}
                                    />
                                </div>
                            </div>
                        </div>


                        <div className="container-cad-prod-obs">
                            <div className="textareaBox-ativo">
                                <span className="details">Observação</span>
                                <textarea
                                    onChange={(e) => setObservation(e.target.value)}
                                    value={observation}
                                    disabled={observationDisabled}
                                />
                            </div>
                        </div>

                        <InputCust id={id} incluirDadosCamposCust={incluirDadosCamposCust} camposCustDisabled={camposCustDisabled} />

                        {qualForm}

                        <div className="Bt-button-cad">
                            <button type="submit" onClick={Confirmar} disabled={confirmarDisabled}>
                                <i className="fa fa-check-circle icon-ok-btn"></i> Salvar
                            </button>
                            <button type="submit" onClick={Cancelar} disabled={cancelarDisabled}>
                                <i className="fa fa-times icon-cancelar-btn"></i> Cancelar
                            </button>
                        </div>

                    </div>
                </main>
            </div>


            <ModalIncluirNovo isOpen={openModalIncluirNovo} setModalOpen={() => setOpenModalIncluirNovo(!openModalIncluirNovo)}>
                <>
                    <div className='title-modal-incluir'>
                        <h1 className="modal-IncluirNovo-title">{qualTitulo}</h1>
                        <div onClick={fecharOpenModalIncluirNovo}>
                            <button className="fa fa-times icone-modal-IncluirNovo" data-dismiss="modal" aria-label="Close"></button>
                        </div>
                    </div>

                    <div className='scrol-modal'>
                        {qualGrid}
                    </div>
                </>
            </ModalIncluirNovo>

            <ModalAlert isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)}>
                <div className='container-modal-alert'>
                    {qualAlert}
                </div>
            </ModalAlert>

        </>
    );

}

export default ProdutoAdd;