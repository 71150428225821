
import React, { useState, useEffect } from 'react'
import Nav from "../../components/Nav";
import Header from "../../components/Header";
import './index.css'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import AtividadesAtivo from '../../components/AtividadesAtivo'
import { AxiosResponse } from "axios";
import api from '../../Services/api'
import GridEditInventario from '../../components/GridEditInventario'
import GridRelacionamento from '../../components/GridRelacionamento'
import GridExibirAlt from '../../components/GridExibirAlt'
import Loading from '../../components/Loading/index'
import { MdOutlineAddCircle } from 'react-icons/md';
import QRCode from 'react-qr-code'
import QRCodeLink from 'qrcode'
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router'
import Select from 'react-select'
import ModalIncluirNovo from '../../components/ModalIncluirNovo/modal'
import ModalAlert from '../../components/ModalAlert/modal'
import secureLocalStorage from "react-secure-storage";

function LocalVisualizacao() {
  const navigate = useNavigate();
  let { id } = useParams();
  const [openModalIncluirNovo, setOpenModalIncluirNovo] = useState(false)
  const [tabIndex, setTabIndex] = useState(-1);
  const [titulo, setTitulo] = useState('');


  const [qualGrid, setQualGrid] = useState(<></>)
  const [qualGridRelacionamento, setQualGridRelacionamento] = useState(<></>)
  const [qModalRelacionamento, setQmodalRelacionamento] = useState('')
  const [qModalOcorrencia, setQmodalOcorrencia] = useState('')
  const [qModalDespesas, setQmodalDespesas] = useState('')
  const [qModalMovimentacao, setQmodalMovimentacao] = useState('')
  const [openModal, setOpenModal] = useState(false)
  const [qualAlert, setQualAlert] = useState(<></>)
  //--------------dados visualização--------------------

  const [tipoEntidade, setTipoEntidade] = useState('Ativos');
  const [categoriaAtivo, setCategoriaAtivo] = useState('');
  const [exibirCatAtivo, setExibirCatAtivo] = useState('S');
  //--------------dados atividade--------------------
  const [exibiAtividade, setExibiAtividade] = useState(<></>);
  //--------------qrcode--------------------
  const [qrcodeLink, setQrcodeLink] = useState('')
  const [gerarQrcode, setGerarQrcode] = useState('')
  const [link, setLink] = useState('')

  //--------------CAMPOS PADRÃO--------------------
  const [loc_description, setLoc_description] = useState('');
  const [loc_alternativeidentifier, setLoc_alternativeidentifier] = useState('');
  const [loc_pavement, setLoc_pavement] = useState('');
  const [unt_id, setUnt_id] = useState('');
  const [ctc_id, setCtc_id] = useState('');
  const [lty_id, setLty_id] = useState('');
  const [loc_datetimeinsert, setLoc_datetimeinsert] = useState('');
  const [loc_datetimeupdate, setLoc_datetimeupdate] = useState('');
  const [loc_phoneddd, setLoc_phoneddd] = useState('');
  const [loc_phoneddi, setLoc_phoneddi] = useState('');
  const [loc_phone, setLoc_phone] = useState('');
  const [loc_mobilephoneddd, setLoc_mobilephoneddd] = useState('');
  const [loc_mobilephoneddi, setLoc_mobilephoneddi] = useState('');
  const [loc_mobilephone, setLoc_mobilephone] = useState('');
  const [loc_email, setLoc_email] = useState('');
  const [loc_observation, setLoc_observation] = useState('');
  const [unt_description, setUnt_description] = useState('');
  const [ctc_description, setCtc_description] = useState('');
  const [lty_description, setLty_description] = useState('');

   //--------------dados ocorrencia--------------------
   const [idOcorrencia, setIdOcorrencia] = useState('');
   const [solicitanteOcorrencia, setSolicitanteOcorrencia] = useState('');
   const [condicaoAtivoOcorrencia, setCondicaoAtivoOcorrencia] = useState('');
   const [observationOcorrencia, setObservationOcorrencia] = useState('');
   //--------------dados ocorrencia--------------------
   
   const [solicitanteOcorrenciaDisabled, setsolicitanteOcorrenciaDisabled] = useState(false)
  const [observationOcorrenciaDisabled, setSobservationOcorrenciaDisabled] = useState(false)
  const [insertOcorrenciaDisabled, setInsertOcorrenciaDisabled] = useState(false)
  const [tipoManutencaoDisabled, setTipoManutencaoDisabled] = useState(false)
  const [valorDisabled, setValorDisabled] = useState(false)
  const [observationCustosDisabled, setbservationCustosDisabled] = useState(false)
   const [insertCustosDisabled, setInsertCustosDisabled] = useState(false)

     //--------------dados Custos--------------------
  const [idCustos, setIdCustos] = useState('');
  const [tipoManutencao, setTipoManutencao] = useState('');
  const [valor, setValor] = useState('');
  const [observationCustos, setObservationCustos] = useState('');
  //--------------dados Custos--------------------

  const [optiontipo, setOptiontipo] = useState([
    {
      value: "Manutenção",
      label: "Manutenção"
    },
    {
      value: "Conserto",
      label: "Conserto"
    }
  ])
  
  useEffect(() => {

    setQualAlert(<div className='div-alert'><Loading /></div>)
    setOpenModal(true)

    validatToken();

  }, []);

  async function validatToken() {

    let ret: AxiosResponse;
    let valor: any;

    const _user = secureLocalStorage.getItem('t')

    api.defaults.headers.common['Authorization'] = _user as ''

    try {

      ret = await api.get("/status/validar")

      if (ret.data.status === 200) {

        secureLocalStorage.setItem('t', ret.data.data);
        secureLocalStorage.setItem('acp', ret.data.decodedToken.acp_integrationid);
        secureLocalStorage.setItem('n', ret.data.decodedToken.name);
        secureLocalStorage.setItem('iag', ret.data.decodedToken.id);
        secureLocalStorage.setItem('cy', ret.data.decodedToken.company);



        if (ret.data.decodedToken.acp_integrationid === 'DESENVOLVEDOR' || ret.data.decodedToken.acp_integrationid === 'ADMINISTRADOR') {



        } else if (ret.data.decodedToken.acp_integrationid === 'CONSULTOR') {


        }

        await inventoryditar('')

      }

    } catch (e: any) {

      if (e.response.data.status === 403) {

        setQualAlert(<div className='div-alert'>
          <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
          <div className='btn-alert' onClick={fecharModalToken}><span className="btn-alert-lab">OK</span></div>
        </div>)


      }
    }

  }

  function fecharModalToken() {
    secureLocalStorage.removeItem('t')
    secureLocalStorage.removeItem('acp')
    secureLocalStorage.removeItem('n')
    secureLocalStorage.removeItem('iag')
    navigate('/login');
    setOpenModal(false)
  }

  async function inventoryditar(tab: any) {

    setQualGridRelacionamento(<div className='div-alert'><Loading /></div>)

    let ret: AxiosResponse;
    let valor: any;

    if (/^\d+$/.test(id || '') === true) {

      try {
        ret = await api.get("local/visaogerallocal", {
          params: {
            id: id
          }
        })
        if (ret.data.status === 200) {



          valor = ret.data.data[0];


          setLoc_description(valor.loc_description)
          setLoc_description(valor.loc_description)
          setLoc_alternativeidentifier(valor.loc_alternativeidentifier)
          setLoc_pavement(valor.loc_pavement)
          setUnt_id(valor.Unt_id)
          setCtc_id(valor.Ctc_id)
          setLty_id(valor.lty_id)

          setLoc_datetimeinsert(valor.loc_datetimeinsert?.substring(8, 10)
            + "/" + valor.loc_datetimeinsert?.substring(5, 7)
            + "/" + valor.loc_datetimeinsert?.substring(0, 4)
            + " " + valor.loc_datetimeinsert?.substring(11, 16))

          setLoc_datetimeupdate(valor.loc_datetimeupdate?.substring(8, 10)
            + "/" + valor.loc_datetimeupdate?.substring(5, 7)
            + "/" + valor.loc_datetimeupdate?.substring(0, 4)
            + " " + valor.loc_datetimeupdate?.substring(11, 16))


          if (valor.loc_phoneddd !== null) {
            if (valor.loc_phone !== null) {
              setLoc_phone("(" + valor.loc_phoneddd + ") " + valor.loc_phone)
            } 
          }else{
            if (valor.loc_phone !== null) {
              setLoc_phone(valor.loc_phone)
            } 
          }

          if (valor.loc_mobilephoneddd !== null) {
            if (valor.loc_mobilephone !== null) {
              setLoc_mobilephone("(" + valor.loc_mobilephoneddd + ") " + valor.loc_mobilephone)
            }
          }else{
            if (valor.loc_mobilephone !== null) {
              setLoc_mobilephone(valor.loc_mobilephone)
            }
          }


          setLoc_email(valor.loc_email)
          setLoc_observation(valor.loc_observation)
          setUnt_description(valor.unt_description);
          setCtc_description(valor.ctc_description);
          setLty_description(valor.lty_description);


          setGerarQrcode('N')
          if (sessionStorage.getItem("QUALTAB") === '0' || sessionStorage.getItem("QUALTAB") === null || sessionStorage.getItem("QUALTAB") === undefined || sessionStorage.getItem("QUALTAB") === '') {
            setTabIndex(0)
          } else
            if (sessionStorage.getItem("QUALTAB") === '1') {
              setTabIndex(1)

            } else
              if (sessionStorage.getItem("QUALTAB") === '2') {
                setTabIndex(2)
                ChamaGridOcorrencia();
              } else
                if (sessionStorage.getItem("QUALTAB") === '3') {
                  setTabIndex(3)
                  ChamaGridDespesas()
                }
          if (tab === '4' || sessionStorage.getItem("QUALTAB") === '4') {


            setTabIndex(4)
            ChamaAtividades()

          }







        } else {
          navigate('/asset/visualizar/' + id + '/n');
        }
      } catch (e) {
        navigate('/asset/visualizar/' + id + '/n');
      }

    } else {
      navigate('/asset/visualizar/' + id + '/n');
    }

    setOpenModal(false)

  }


  function ChamaResumo() {
    setTabIndex(0)
  }

async  function ChamaGridRelacionamento() {

  setQualGridRelacionamento(<div className='div-alert'><Loading /></div>)
  
  setTabIndex(1)

    let ret: AxiosResponse;

    try {
      ret = await api.get("local/listarativoloc", {
        params: {
          id: id
        }
      })
      if (ret.data.status === 200) {
        setQualGridRelacionamento(<><GridExibirAlt
          Iconeadd={Relacionamento}
          th1='Descrição'
          th2='Identificador Alternativo'
          th3='ID'
          endPoint='local/listarativoloc'
          consult='S'
          col1={0}
          col2={24}
          col3={2}
          col4={0}
          ambiente={id}
          conc='S'
          acao='N'
        
        /></>)

      } else {

        setQualGridRelacionamento(<div className='panel-content-visu-aviso'>
          <h1 className='panel-content-aviso'>Não há Movimentação Disponíveis.</h1>
        </div>)

      }
    } catch (e) {
      
      setQualGridRelacionamento(<div className='panel-content-visu-aviso'>
        <h1 className='panel-content-aviso'>Não há Movimentação Disponíveis.</h1>
      </div>)

    }
  }

 function Relacionamento(){
  setQualAlert(<div className='div-alert'>
  <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
  <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
  <div className='div-info-alert'><span className="info-alert">Não é possível criar relacionamento no modo Local</span></div>
  <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
</div>)

setOpenModal(true)
  }
 //=======================Despesas============================

 async function ChamaGridDespesas() {
    
    setQualGridRelacionamento(<div className='div-alert'><Loading /></div>)
    sessionStorage.setItem("QUALTAB", '3');
    let ret: AxiosResponse;

    try {
      ret = await api.get("costs/listarcostsloc", {
        params: {
          id: id
        }
      })
      if (ret.data.status === 200) {
        setQualGridRelacionamento(<><GridExibirAlt 
          iconeExcluir={ModalDeletarDespesas}
          Iconeditar={editarDespesas} 
          Iconeadd={Despesas}
          th1='Tipo'
          th2='Valor'
          th3='ID'
          endPoint='costs/listarcostsloc'
          consult='S'
          col1={0}
          col2={4}
          col3={3}
          col4={0}
          ambiente={id}
          conc='S'
          acao='S'
        /></>)

      } else {
        setQualGridRelacionamento(<div className='panel-content-visu-aviso'>
          <h1 className='panel-content-aviso'>Não há manutenções disponíveis.</h1>
          <h1 className='panel-content-aviso-add' onClick={Despesas}>Adicionar Manutenções</h1>
        </div>)
      }
    } catch (e) {
      setQualGridRelacionamento(<div className='panel-content-visu-aviso'>
        <h1 className='panel-content-aviso'>Não há manutenções disponíveis.</h1>
        <h1 className='panel-content-aviso-add' onClick={Despesas}>Adicionar Manutenções</h1>
      </div>)
    }

  }

  function ModalDeletarDespesas(obj: any) {
    if(secureLocalStorage.getItem('acp') !== 'CONSULTOR'){
    if (obj.cst_typecost === 'Compra') {

      setQualAlert(<div className='div-alert'>
        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
        <div className='div-info-alert'><span className="info-alert">Não é possível deletar valor de compra.</span></div>
        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
      </div>)

      setOpenModal(true)



    } else {

      setQualAlert(<div className='div-alert'>
        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
        <div className='div-info-alert-atencao'><span className="info-alert-atencao">Deseja deletar o registro?</span></div>
        <div className='div-info-alert'><span className="info-alert">Você não poderá reverter.</span></div>

        <div className='btn-alert-div'>
          <span className='btn-alert-esc' onClick={() => deletarRelacionamentoDespesas(obj.cst_id, obj.loc_id)}>
            <span className="btn-alert-lab">SIM</span>
          </span>
          <span className='btn-alert-dir' onClick={finalizarModal2}>
            <span className="btn-alert-lab">NÃO</span>
          </span>
        </div>
      </div>)

      setOpenModal(true)


    }
  }
  }

  async function deletarRelacionamentoDespesas(id: any, loc_id: any) {

    let ret: AxiosResponse;

    try {
      ret = await api.delete("costs/delete", {
        data: {
          id: id,
          loc_id: loc_id,
          age_id: secureLocalStorage.getItem('iag'),
        }
      });
      if (ret.data.status === 200) {


        sessionStorage.setItem("QUALTAB", '3');
        window.location.reload();
        setOpenModal(false)


      }
    } catch (e: any) {

      setQualAlert(<div className='div-alert'>
        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
        <div className='div-info-alert'><span className="info-alert">Não foi possivel excluir.</span></div>
        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
      </div>)

      setOpenModal(true)

    }
  }

  function editarDespesas(obj: any) {

    if (obj !== null) {
      if (obj.cst_typecost === 'Compra') {

        setQualAlert(<div className='div-alert'>
          <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Não é possível editar valor de compra.</span></div>
          <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
        </div>)

        setOpenModal(true)


      } else {
        setTitulo('Editar Manutenções');
        setIdCustos(obj.cst_id);
        setTipoManutencao(obj.cst_typecost);
        setValor(obj.cst_value);
        setObservationCustos(obj.cst_observation);
        setQmodalRelacionamento('N');
        setQmodalOcorrencia('N');
        setQmodalDespesas('S');
        setQmodalMovimentacao('N');
        setGerarQrcode('N')
        setOpenModalIncluirNovo(true)
      }
    }

  }

  function Despesas() {
    if(secureLocalStorage.getItem('acp') !== 'CONSULTOR'){
    setTitulo('Adicionar Manutenções');
    setQmodalRelacionamento('N')
    setQmodalOcorrencia('N')
    setQmodalDespesas('S')
    setQmodalMovimentacao('N');
    setGerarQrcode('N')
    setIdCustos('');
    setTipoManutencao('');
    setValor('');
    setObservationCustos('');
    setOpenModalIncluirNovo(true)
    }
  }

   //=======================Despesas============================

   function fecharModal8() {

    sessionStorage.setItem("QUALTAB", '3');
    window.location.reload();
    setOpenModal(false)
  }


 async function ChamaAtividades() {
    setTabIndex(4)

    setQualGridRelacionamento(<div className='div-alert'><Loading /></div>)
    sessionStorage.setItem("QUALTAB", '4');

    let ret: AxiosResponse;
    let valor: any;
    try {
      ret = await api.get("activity/listaractivityloc", {
        params: {
          id: id
        }
      })
      if (ret.data.status === 200) {
        valor = ret.data.data as [];

        setExibiAtividade(<><AtividadesAtivo data={valor} /></>)
      } else {
        setExibiAtividade(<h1 className='panel-content-aviso-atv'>Não há atividades vinculadas a esse ativos.</h1>)
      }
    } catch (e) {

      setExibiAtividade(<h1 className='panel-content-aviso-atv'>Não há atividades vinculadas a esse ativos.</h1>)
    }

  }


  function handleQrcode() {
    setLink(String(loc_alternativeidentifier))
    handleGenerate(loc_alternativeidentifier)
    setTitulo('Gerar QR Code');
    setTipoEntidade('Ativos');
    setCategoriaAtivo("");
    setQmodalRelacionamento('N')
    setExibirCatAtivo('N')
    setQmodalOcorrencia('N')
    setQmodalMovimentacao('N');
    setQmodalDespesas('N');
    setGerarQrcode('S')

    setOpenModalIncluirNovo(true)
  }

  function handleGenerate(link_url: any) {
    QRCodeLink.toDataURL(link_url, {
      width: 600,
      margin: 3
    }, function (err, url) {
      setQrcodeLink(url)
    })
  }

  function fecharOpenModalIncluirNovo() {
    setOpenModalIncluirNovo(false)
  }

  //---------------------OCORRENCIA------------------------------------------

  async function ChamaGridOcorrencia() {

    setQualGridRelacionamento(<div className='div-alert'><Loading /></div>)
    sessionStorage.setItem("QUALTAB", '2');
    let ret: AxiosResponse;
    let valor: any;
    try {
      ret = await api.get("occurrence/listaroccurrenceloc", {
        params: {
          id: id
        }
      })
      if (ret.data.status === 200) {
        setQualGridRelacionamento(<><GridExibirAlt 
          iconeExcluir={ModalDeletarOcorrencia} 
          Iconeditar={EditarOcorrencia} 
          Iconeadd={Ocorrencia}
          th1='Assunto'
          th2='Requisitante'
          th3='ID'
          endPoint='occurrence/listaroccurrenceloc'
          consult='S'
          col1={0}
          col2={5}
          col3={3}
          col4={0}
          ambiente={id}
          acao='S'
        /></>)

      } else {
        setQualGridRelacionamento(<div className='panel-content-visu-aviso'>
          <h1 className='panel-content-aviso'>Não há Ocorrências Disponíveis.</h1>
          <h1 className='panel-content-aviso-add' onClick={Ocorrencia}>Adicionar Ocorrências</h1>
        </div>)
      }
    } catch (e) {
      setQualGridRelacionamento(<div className='panel-content-visu-aviso'>
        <h1 className='panel-content-aviso'>Não há Ocorrências Disponíveis.</h1>
        <h1 className='panel-content-aviso-add' onClick={Ocorrencia}>Adicionar Ocorrências</h1>
      </div>)
    }
  
  }

  function ModalDeletarOcorrencia(obj: any) {

    if(secureLocalStorage.getItem('acp') !== 'CONSULTOR'){
       setQualAlert(<div className='div-alert'>
         <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
         <div className='div-info-alert-atencao'><span className="info-alert-atencao">Deseja deletar o registro?</span></div>
         <div className='div-info-alert'><span className="info-alert">Você não poderá reverter.</span></div>
   
         <div className='btn-alert-div'>
           <span className='btn-alert-esc' onClick={() => deletarRelacionamentoOcorrencia(obj.occ_id, obj.loc_id)}>
             <span className="btn-alert-lab">SIM</span>
           </span>
           <span className='btn-alert-dir' onClick={finalizarModal2}>
             <span className="btn-alert-lab">NÃO</span>
           </span>
         </div>
       </div>)
   
       setOpenModal(true)
    }
   
     }
   
     async function deletarRelacionamentoOcorrencia(idOcorrencia: any, idInventory: any) {
   
       let ret: AxiosResponse;
   
       try {
         ret = await api.delete("occurrence/delete", {
           data: {
             id: idOcorrencia,
             loc_id: id,
             age_id: secureLocalStorage.getItem('iag'),
           }
         });
         if (ret.data.status === 200) {
   
   
          sessionStorage.setItem("QUALTAB", '2');
          window.location.reload();
          setOpenModal(false)
   
   
         }
       } catch (e: any) {
   
         setQualAlert(<div className='div-alert'>
           <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
           <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
           <div className='div-info-alert'><span className="info-alert">Não foi possivel Exluir.</span></div>
           <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
         </div>)
   
         setOpenModal(true)
   
   
       }
     }

     function EditarOcorrencia(obj: any) {

      if (obj !== null) {
        setTitulo('Editar Ocorrência');
        setIdOcorrencia(obj.occ_id)
        setSolicitanteOcorrencia(obj.occ_requester);
        setCondicaoAtivoOcorrencia(obj.occ_assetcondition);
        setObservationOcorrencia(obj.occ_occurrence);
        setQmodalRelacionamento('N')
        setQmodalOcorrencia('S')
        setQmodalMovimentacao('N');
        setQmodalDespesas('N');
        setGerarQrcode('N')
        setOpenModalIncluirNovo(true)
      }
  
    }
  
    function Ocorrencia() {
      if(secureLocalStorage.getItem('acp') !== 'CONSULTOR'){
      setTitulo('Adicionar Ocorrência');
      setIdOcorrencia('')
      setSolicitanteOcorrencia('');
      setCondicaoAtivoOcorrencia('');
      setObservationOcorrencia('');
      setQmodalDespesas('N')
      setQmodalRelacionamento('N')
      setQmodalOcorrencia('S')
      setQmodalMovimentacao('N');
      setGerarQrcode('N')
      setOpenModalIncluirNovo(true)
      }
    }

    function fecharModal() {
      setOpenModal(false)
    }

    function fecharModal5() {

      sessionStorage.setItem("QUALTAB", '2');
      window.location.reload();
      setOpenModal(false)
    }

    function finalizarModal2() {
      setOpenModal(false)
    }

    async function insertOcorrencia() {
      let ret: AxiosResponse;
  
      setQualAlert(<div className='div-alert'><Loading /></div>)
      setOpenModal(true)
  
      if (solicitanteOcorrencia === '') {
  
        setQualAlert(<div className='div-alert'>
          <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Digite o requisitante.</span></div>
          <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
        </div>)
  
        setOpenModal(true)
  
      }
      else if (observationOcorrencia === "") {
  
        setQualAlert(<div className='div-alert'>
          <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Digite o assunto.</span></div>
          <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
        </div>)
  
        setOpenModal(true)
  
  
      } else
        if (idOcorrencia === '') {
          const dados = [{
            loc_id: id,
            age_id_insert: secureLocalStorage.getItem('iag'),
            occ_occurrence: observationOcorrencia,
            occ_assetcondition: 'EMUSO',
            occ_requester: solicitanteOcorrencia
          }]
  
          try {
            ret = await api.post("occurrence/insert", {
              data: dados,
            });
            if (ret.data.status === 200) {
  
              setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-check ControleIconAlert"></span>
                <div className='div-info-alert'><span className="info-alert">Ocorrência Incluida com sucesso.</span></div>
                <div className='btn-alert' onClick={fecharModal6}><span className="btn-alert-lab">OK</span></div>
              </div>)
  
  
              setOpenModal(true)
  
  
  
            }
          } catch (e: any) {
  
            setQualAlert(<div className='div-alert'>
              <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
              <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
              <div className='div-info-alert'><span className="info-alert">Não foi possível concluir o cadastramento.</span></div>
              <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
            </div>)
  
            setOpenModal(true)
  
  
          }
        } else {

          const dados = {
            loc_id: id,
            age_id_insert: secureLocalStorage.getItem('iag'),
            occ_occurrence: observationOcorrencia,
            occ_assetcondition: 'EMUSO',
            occ_requester: solicitanteOcorrencia,
            occ_id: idOcorrencia
          }
  
          try {
            ret = await api.put("occurrence/update", {
              data: dados,
            });
            if (ret.data.status === 200) {
  
  
              setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-check ControleIconAlert"></span>
                <div className='div-info-alert'><span className="info-alert">A ocorrência foi alterada com sucesso.</span></div>
                <div className='btn-alert' onClick={fecharModal7}><span className="btn-alert-lab">OK</span></div>
              </div>)
  
  
              setOpenModal(true)
  
  
  
            }
          } catch (e: any) {
  
            setQualAlert(<div className='div-alert'>
              <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
              <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
              <div className='div-info-alert'><span className="info-alert">Não foi possível concluir a alteração.</span></div>
              <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
            </div>)
  
            setOpenModal(true)
  
  
          }
        }
    }

    function cancelarOcorrencia() {
      sessionStorage.setItem("QUALTAB", '2');
      window.location.reload();
    }

    function fecharModal7() {

      sessionStorage.setItem("QUALTAB", '2');
      window.location.reload();
      setOpenModal(false)
    }

    function fecharModal6() {

      sessionStorage.setItem("QUALTAB", '2');
      window.location.reload();
      setOpenModal(false)
    }

    function incluirValor(value: any) {
      var valor = value
      valor = valor + '';
      valor = parseInt(valor.replace(/[\D]+/g, ''));
      valor = valor + '';
      valor = valor.replace(/([0-9]{2})$/g, ".$1");
      if (valor.length > 6) {
        valor = valor.replace(/([0-9]{3}),([0-9]{2}$)/g, "$1.$2");
      }
      setValor(valor)
      if (valor == 'NaN') {
        setValor('');
      }
    }

    function ValidarTipoManutencao(tipo: any) {

      if (tipo === '') {
        setTipoManutencao('');
      } else {
        setTipoManutencao(tipo);
      }
    }

    async function insertCustos() {
      let ret: AxiosResponse;
  
      setQualAlert(<div className='div-alert'><Loading /></div>)
      setOpenModal(true)
  
      if (tipoManutencao === '') {
  
        setQualAlert(<div className='div-alert'>
          <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Selecione o tipo de manutenção.</span></div>
          <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
        </div>)
  
        setOpenModal(true)
  
  
      } else if (valor === '') {
  
        setQualAlert(<div className='div-alert'>
          <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Digite o valor.</span></div>
          <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
        </div>)
  
        setOpenModal(true)
  
      } else if (observationCustos === '') {
  
        setQualAlert(<div className='div-alert'>
          <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Digite uma descrição para a manutenção.</span></div>
          <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
        </div>)
  
        setOpenModal(true)
  
      }
  
      else
        if (idCustos === '') {
          const dados = [{
            loc_id: id,
            age_id: secureLocalStorage.getItem('iag'),
            cst_typecost: tipoManutencao,
            cst_value: valor,
            cst_observation: observationCustos
          }]
  
          try {
            ret = await api.post("costs/insert", {
              data: dados,
            });
            if (ret.data.status === 200) {
  
  
              setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-check ControleIconAlert"></span>
                <div className='div-info-alert'><span className="info-alert">Alteração realizada com sucesso.</span></div>
                <div className='btn-alert' onClick={fecharModal9}><span className="btn-alert-lab">OK</span></div>
              </div>)
  
  
              setOpenModal(true)
  
  
  
              //inclurAtividadeDespesas(ret.data.data)
            }
          } catch (e: any) {
  
            setQualAlert(<div className='div-alert'>
              <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
              <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
              <div className='div-info-alert'><span className="info-alert">Não foi possível concluir o cadastramento.</span></div>
              <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
            </div>)
  
            setOpenModal(true)
  
  
          }
        } else {
          const dados = {
            loc_id: id,
            age_id: secureLocalStorage.getItem('iag'),
            cst_observation: observationCustos,
            cst_value: valor,
            cst_typecost: tipoManutencao,
            cst_id: idCustos
          }
  
          try {
            ret = await api.put("costs/update", {
              data: dados,
            });
            if (ret.data.status === 200) {
  
              setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-check ControleIconAlert"></span>
                <div className='div-info-alert'><span className="info-alert">Alteração realizada com sucesso.</span></div>
                <div className='btn-alert' onClick={fecharModal10}><span className="btn-alert-lab">OK</span></div>
              </div>)
  
  
              setOpenModal(true)
  
  
            }
          } catch (e: any) {
  
            setQualAlert(<div className='div-alert'>
              <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
              <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
              <div className='div-info-alert'><span className="info-alert">Não foi possível concluir a alteração.</span></div>
              <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
            </div>)
  
            setOpenModal(true)
  
  
  
          }
        }
    }

    function fecharModal10() {
      sessionStorage.setItem("QUALTAB", '3');
      window.location.reload();
      setOpenModal(false)
    }
  
    function fecharModal9() {

      sessionStorage.setItem("QUALTAB", '3');
      window.location.reload()
      setOpenModal(false)
    }

    function cancelarDespesas() {
      setTitulo('Adicionar Manutenções');
      setQmodalRelacionamento('N')
      setQmodalOcorrencia('N')
      setQmodalDespesas('S')
  
      sessionStorage.setItem("QUALTAB", '3');
      window.location.reload();
    }

    const customStyles = {
      control: (provided: any, state: any) => ({
        ...provided,
        background: '#fff',
        minHeight: '30px',
        height: '30px',
        borderColor: state.isFocused ? 'grey' : '#92989e',
  
      }),
  
  
      valueContainer: (provided: any, state: any) => ({
        ...provided,
        height: '30px',
        padding: '0 6px'
      }),
  
      input: (provided: any, state: any) => ({
        ...provided,
        margin: '0px',
        fontSize: 14,
  
      }),
      indicatorsContainer: (provided: any, state: any) => ({
        ...provided,
        height: '30px',
      }),
    };
    
  return (
    <>
      <Nav />
      <div className="main-content">
        <Header />
        <main>
          <div className='containerPrincipal'>

            <h1 className='titulo-card-visualizacao'>{loc_description}</h1>
            <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>

              <div className="container-cad-prod28">
                <div id="content1">
                  <TabList>
                    <Tab onClick={ChamaResumo} >
                      <p>Resumo</p>
                    </Tab>
                    <Tab onClick={ChamaGridRelacionamento}>
                      <p>Relacionamento</p>
                    </Tab>
                    <Tab onClick={ChamaGridOcorrencia}>
                      <p>Ocorrência</p>
                    </Tab>
                    <Tab onClick={ChamaGridDespesas}>
                      <p>Manutenções</p>
                    </Tab>



                    <Tab onClick={ChamaAtividades}>
                      <p>Atividade</p>
                    </Tab>

                  </TabList>
                </div>



                <div id="content2">
                  <TabPanel>
                    <div className="panel-content">

                      <div className='panel-content-visu'>
                        <h2>ID: </h2>
                        <h2 className='resumo-bd'>{id}</h2>
                      </div>
                      <div className='panel-content-visu-qrcode'>
                        <h2>QR Code: </h2>
                        <div className='btn-qrcode' onClick={handleQrcode}> <span className='fa fa-qrcode icon-qrcode'></span></div>
                      </div>
                      <div className='panel-content-visu'>
                        <h2>Identificador Alternativo: </h2>
                        <h2 className='resumo-bd'>{loc_alternativeidentifier}</h2>
                      </div>
                      <div className='panel-content-visu'>
                        <h2>Pavimento: </h2>
                        <h2 className='resumo-bd'>{loc_pavement}</h2>
                      </div>
                      <div className='panel-content-visu'>
                        <h2>Tipo de Local: </h2>
                        <h2 className='resumo-bd'>{lty_description}</h2>
                      </div>
                      <div className='panel-content-visu'>
                        <h2>Contrato: </h2>
                        <h2 className='resumo-bd'>{ctc_description}</h2>
                      </div>
                      <div className='panel-content-visu'>
                        <h2>Unidade: </h2>
                        <h2 className='resumo-bd'>{unt_description}</h2>
                      </div>
                      <div className='panel-content-visu'>
                        <h2>Celular: </h2>
                        <h2 className='resumo-bd'>{loc_mobilephone}</h2>
                      </div>
                      <div className='panel-content-visu'>
                        <h2>Telefone: </h2>
                        <h2 className='resumo-bd'>{loc_phone}</h2>
                      </div>
                      <div className='panel-content-visu'>
                        <h2>E-mail: </h2>
                        <h2 className='resumo-bd'>{loc_email}</h2>
                      </div>
                      <div className='panel-content-visu'>
                        <h2>Observação: </h2>
                        <h2 className='resumo-bd'>{loc_observation}</h2>
                      </div>
                      <div className='panel-content-visu'>
                        <h2>Data da Inclusão: </h2>
                        <h2 className='resumo-bd'>{loc_datetimeinsert}</h2>
                      </div>
                      <div className='panel-content-visu'>
                        <h2>Data da Última Atualização: </h2>
                        <h2 className='resumo-bd'>{loc_datetimeupdate}</h2>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>

                    {qualGridRelacionamento}

                  </TabPanel>
                  <TabPanel>

                    {qualGridRelacionamento}

                  </TabPanel>

                  <TabPanel>

                    {qualGridRelacionamento}

                  </TabPanel>

                  <TabPanel>
                    <div className="panel-content">
                      {exibiAtividade}
                    </div>
                  </TabPanel>
                </div>
              </div>
            </Tabs>
          </div>
        </main>
      </div>


      <ModalIncluirNovo isOpen={openModalIncluirNovo} setModalOpen={() => setOpenModalIncluirNovo(!openModalIncluirNovo)}>
        <div className='title-modal-incluir'>
          <h1 className="modal-IncluirNovo-title">{titulo}</h1>
          <div onClick={fecharOpenModalIncluirNovo}>
          <button className="fa fa-times icone-modal-IncluirNovo" data-dismiss="modal" aria-label="Close"></button>
          </div>
        </div>
        <div>
          <div className='container-modal-incluir'>

          {qModalDespesas
              === 'S' ? (
              <>

                <div className='modal-visu-inventatio'>
                  <div className="container-InventarioEdit2">
                    <div id="content1">

                    <div className="select-box-ativos-select">
                        <div className='details-flex'>
                          <span className="details">Tipo *</span>
                        </div>

                        <Select
                          maxMenuHeight={80}
                          placeholder={'Selecione...'}
                          options={optiontipo}
                          styles={customStyles}
                          value={optiontipo.filter(function (option: any) {
                            return option.value === tipoManutencao;
                          })}
                          onChange={(options: any) =>
                            !options ? ValidarTipoManutencao("") : ValidarTipoManutencao(options.value)
                          }
                          isClearable
                          noOptionsMessage={() => "Não encontrado..."}
                          theme={(theme: any) => ({
                            ...theme,
                            borderRadius: 4,
                            colors: {
                              ...theme.colors,
                              primary25: '#ebebeb',
                              primary: 'black',
                            },
                          })}
                        />
                      </div>

                    </div>

                    <div id="content2">
                      <div className="input-box-ativos">
                        <div className='details-flex'>
                          <span className="details">Valor *</span>
                        </div>
                        <input
                          autoComplete="off"
                          type="text"
                          onChange={(e) => incluirValor(e.target.value)}
                          value={valor}
                          placeholder='R$'
                          disabled={valorDisabled}
                        />

                      </div>
                    </div>
                  </div>
                  <div className="container-cad-prod3">
                    <div className="textareaBox-ativo">
                      <span className="details">Informação sobre a manutenção *</span>
                      <textarea
                        onChange={(e) => setObservationCustos(e.target.value)}
                        value={observationCustos}
                        disabled={observationCustosDisabled}
                      />
                    </div>
                  </div>
                  <div className="Bt-button-cad">
                    <button type="submit" onClick={insertCustos} disabled={insertCustosDisabled}>
                      <i className="fa fa-check-circle icon-ok-btn"></i> Salvar
                    </button>
                    <button type="submit" onClick={cancelarDespesas}>
                      <i className="fa fa-times icon-cancelar-btn" ></i> Cancelar
                    </button>
                  </div>

                </div>


              </>
            ) : null}

          {qModalOcorrencia
              === 'S' ? (
              <>
                <div className='modal-visu-inventatio'>
                  <div className="textareaBox-ativo">

                    <div className="input-box-ativos">
                      <div className='details-flex'>
                        <span className="details">Requisitante *</span>
                      </div>
                      <input
                        autoComplete="off"
                        type="text"
                        onChange={(e) => setSolicitanteOcorrencia(e.target.value)} 
                        value={solicitanteOcorrencia}
                        disabled={solicitanteOcorrenciaDisabled}
                      />

                    </div>


                  </div>
                  <div className="container-cad-prod3">
                    <div className="textareaBox-ativo">
                      <span className="details">Assunto *</span>
                      <textarea
                        onChange={(e) => setObservationOcorrencia(e.target.value)} 
                        value={observationOcorrencia}
                        disabled={observationOcorrenciaDisabled}
                      />
                    </div>
                  </div>
                  <div className="Bt-button-cad">
                    <button type="submit" onClick={insertOcorrencia} disabled={insertOcorrenciaDisabled}>
                      <i className="fa fa-check-circle icon-ok-btn"></i> Salvar
                    </button>
                    <button type="submit" onClick={cancelarOcorrencia}>
                      <i className="fa fa-times icon-cancelar-btn" ></i> Cancelar
                    </button>
                  </div>

                </div>
              </>
            ) : null}
            {gerarQrcode === 'S' ? (
              <div className='modal-visu-qrcode'>
                <QRCode
                  value={link}
                />
                <a href={qrcodeLink} download={`${loc_alternativeidentifier}.png`} className='gerarQr'>Baixar QR Code</a>
              </div>
            ) : null}
          </div>

        </div>
      </ModalIncluirNovo>

      <ModalAlert isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)}>
        <div className='container-modal-alert'>
          {qualAlert}
        </div>
      </ModalAlert>

    </>
  );

}

export default LocalVisualizacao;