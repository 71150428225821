import React, { useState, useEffect } from 'react'
import Nav from "../../components/Nav";
import Header from "../../components/Header";
import './index.css'
import Select from 'react-select'
import api from '../../Services/api'
import { AxiosResponse } from "axios";
import { MdOutlineAddCircle } from 'react-icons/md';
import { useNavigate } from 'react-router';
import ModalPrincipal from '../../components/ModalAlert/modal'
import Loading from '../../components/Loading'
import secureLocalStorage from "react-secure-storage";
import ModalIncluirNovo from '../../components/ModalIncluirNovo/modal'
import GridSecundario from '../../components/GridSecundario'
import FormTipoTarefa from '../../components/FormTipoTarefa'
import FormTipoCorretiva from '../../components/FormTipoCorretiva';
import FormTipoPreventiva from '../../components/FormTipoPreventiva';
import { v4 as uuidv4 } from 'uuid';

function TarefasAdd() {
    const navigate = useNavigate();

    const [openModalIncluirNovo, setOpenModalIncluirNovo] = useState(false)
    const [qualTitulo, setQualTitulo] = useState('')
    const [qualGrid, setQualGrid] = useState(<></>)
    const [openModal, setOpenModal] = useState(false)
    const [qualAlert, setQualAlert] = useState(<></>)
    const [tsk_id, setTsk_id] = useState('');
    const [tty_id, setTty_id] = useState('');
    const [tsk_active, setTsk_active] = useState(true);
    const [tsk_recreate, setTsk_recreate] = useState(false);
    const [tsk_notification, setTsk_notification] = useState(false);
    const [age_id, setAge_id] = useState('');
    const [tea_id, setTea_id] = useState('');
    const [tsf_id, setTsf_id] = useState('');
    const [ast_id, setAst_id] = useState('');
    const [tsk_integrationid, setTsk_integrationid] = useState('');
    const [tsk_priority, setTsk_priority] = useState('');
    const [stn_id, setStn_id] = useState('');
    const [src_id, setSrc_id] = useState(String(1));
    const [tsk_datetimeinsert, setTsk_datetimeinsert] = useState('');
    const [tsk_datetimeupdate, setTsk_datetimeupdate] = useState('');
    const [tsk_observation, setTsk_observation] = useState('');
    const [tsk_responsible, setTsk_responsible] = useState('');
    const [tsk_requester, setTsk_requester] = useState('');
    const [tsk_frequency, setTsk_frequency] = useState('');
    const [tsk_callback, setTsk_callback] = useState('');
    const [tsk_telephone, setTsk_telephone] = useState('');
    const [agd_id, setAgd_id] = useState('');
    const [tsk_agendadescription, setTsk_agendadescription] = useState('');
    const [crt_id, setCrt_id] = useState('');
    const [pvt_id, setPvt_id] = useState('');
    const [tsk_technicalinstruction, setTsk_technicalinstruction] = useState('');
    const [tsk_image1, setTsk_image1] = useState('');
    const [tsk_image2, setTsk_image2] = useState('');
    const [tsk_scheduleinitialdatehour, setTsk_scheduleinitialdatehour] = useState('');
    const [tsk_schedulefinaldatehour, setTsk_schedulefinaldatehour] = useState('');
    const [tsk_waitingtime, setTsk_waitingtime] = useState('');
    const [ivb_id, setIvb_id] = useState('');

    const [contractDest, setContractDest] = useState('');
    const [unityDest, setUnityDest] = useState('');
    const [pavementMov, setPavementMov] = useState('');
    const [departmentMov, setDepartmentMov] = useState('');
    const [user, setUser] = useState('');


    const [optionContract, setOptionContract] = useState([])
    const [optionUnityDest, setOptionUnityDest] = useState([])
    const [optionspavementMov, setOptionspavementMov] = useState([])
    const [optionsDepartmentMov, setOptionsDepartmentMov] = useState([])

    const [optionsTasktype, setOptionsTasktype] = useState([])
    const [optionsAgent, setOptionsAgent] = useState([])
    const [optionsEquipe, setOptionsEquipe] = useState([])
    const [optionsForma, setOptionsForma] = useState([])
    const [optionsAtivo, setOptionsAtivo] = useState([])
    const [optionsSituacao, setOptionsSituacao] = useState([])
    const [optionsFonte, setOptionsFonte] = useState([])
    const [optionsTipoPreventiva, setOptionsTipoPreventiva] = useState([])
    const [optionsCorrectivetype, setOptionsCorrectivetype] = useState([])

    const [tipo, setTipo] = useState('');
    const [isDisabledForma, setIsDisabledForma] = useState(true)
    const [isDisabledCorretiva, setIsDisabledCorretiva] = useState(true)
    const [isDisabledPreventiva, setIsDisabledPreventiva] = useState(true)

    const [alternativeIdentifier, setAlternativeIdentifier] = useState('')
    const [serialNumber, setSerialNumber] = useState('')
    const [tombamento, setTombamento] = useState('')
    const [mac, setMac] = useState('')

    const [alternativeIdentifierIni, setAlternativeIdentifierIni] = useState('')
    const [serialNumberIni, setSerialNumberIni] = useState('')
    const [tombamentoIni, setTombamentoIni] = useState('')
    const [macIni, setMacIni] = useState('')

    const [optionsAtivoComp, setOptionsAtivoComp] = useState([])

    const [entidade, setEntidade] = useState('');



    const [optionContractAtivo, setOptionContractAtivo] = useState([])
    const [optionUnityDestAtivo, setOptionUnityDestAtivo] = useState([])
    const [optionspavementMovAtivo, setOptionspavementMovAtivo] = useState([])
    const [optionsDepartmentMovAtivo, setOptionsDepartmentMovAtivo] = useState([])



    const [contractLocal, setContractLocal] = useState('');
    const [unityLocal, setUnityLocal] = useState('');
    const [pavementLocal, setPavementLocal] = useState('');
    const [local, setLocal] = useState('');









    useEffect(() => {

        setQualAlert(<div className='div-alert'>
            <span className="far fa-question-circle ControleIconAlertquestion"></span>
            <div className='div-info-alert-atencao'>
                <span className="info-alert-atencao">ATENÇÃO</span></div>
            <div className='div-info-alert'>
                <span className="info-alert">
                    Para qual tipo você deseja criar uma tarefa?</span></div>
            <div className='btn-flex'>
                <div className='btn-alert-f' onClick={chamarLocal}>
                    <span className="btn-alert-lab-flex">LOCAL</span>
                </div>

                <div className='btn-alert-f' onClick={chamarAtivo}>
                    <span className="btn-alert-lab-flex">ATIVO</span>
                </div>
            </div>
        </div>)

        setOpenModal(true)



        let today = new Date();
        let date =
            today.getFullYear() +
            "-" +
            (today.getMonth() + 1).toString().padStart(2, "0") +
            "-" +
            today.getDate().toString().padStart(2, "0");
        let time =
            today.getHours().toString().padStart(2, "0") +
            ":" +
            today.getMinutes().toString().padStart(2, "0");
        let teste = date + "T" + time;
        setTsk_datetimeinsert(teste);


    }, []);



    async function validatToken(tipoid: any) {

        let ret: AxiosResponse;
        let valor: any;


        const _user = secureLocalStorage.getItem('t')

        api.defaults.headers.common['Authorization'] = _user as ''

        try {

            ret = await api.get("/status/validar")

            if (ret.data.status === 200) {

                secureLocalStorage.setItem('t', ret.data.data);
                secureLocalStorage.setItem('acp', ret.data.decodedToken.acp_integrationid);
                secureLocalStorage.setItem('n', ret.data.decodedToken.name);
                secureLocalStorage.setItem('iag', ret.data.decodedToken.id);
                secureLocalStorage.setItem('cy', ret.data.decodedToken.company);

                GetTasktype(tipoid);
                GetAgent();
                GetEquipe();
                GetForma();
                GetAtivo();
                GetSituacao();
                GetSource();
                GetPreventiva(tipoid);
                GetCorrectivetype(tipoid);

                setOpenModal(false)
            }

        } catch (e: any) {

            if (e.response.data.status === 403) {

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
                    <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)

            }
        }

    }

    async function chamarAtivo() {
        setEntidade('2')
        validatToken('2');
        await GetContratoLoc();
        setOpenModal(false)
    }

    async function chamarLocal() {
        setEntidade('1')
        validatToken('1');
        await GetContratoLoc();
        await GetLoc()
        setOpenModal(false)
    }

    function fecharModal() {

        secureLocalStorage.removeItem('t')
        secureLocalStorage.removeItem('acp')
        secureLocalStorage.removeItem('n')
        secureLocalStorage.removeItem('iag')
        navigate('/login');
        setOpenModal(false)

    }

    async function GetContrato() {
        let ret: AxiosResponse;
        let valor: any;
        try {
            ret = await api.get("contract/listar")
            if (ret.data.status === 200) {
                valor = ret.data.data;

                const arr = [] as any;
                let result = valor;
                result.map((user: any) => {
                    return arr.push({ value: String(user.ctc_id + '_' + user.ctc_description), label: user.ctc_description });
                });
                setOptionContract(arr)
            }
        } catch (e) {
            setOptionContract([])
        }
    }


    async function GetUnidadeSelDest(id: any) {
        let ret: AxiosResponse;
        let valor: any;


        if (id === '' || id === 'Selecione...' || id === 'undefined' || id === undefined) {
            setUnityDest('')
            setOptionUnityDest([])

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Selecione o contrato de destino.</span></div>
                <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
            </div>)

            setOpenModal(true)

        } else {
            try {
                ret = await api.get("unity/listar", {
                    params: {
                        id: id
                    }
                })
                if (ret.data.status === 200) {
                    valor = ret.data.data;


                    const arr = [] as any;
                    let result = valor;
                    result.map((user: any) => {
                        return arr.push({ value: String(user.unt_id + "_" + user.unt_description), label: user.unt_description });
                    });
                    setOptionUnityDest(arr)

                }
                setOpenModal(false)

            } catch (e) {
                setOpenModal(false)

                setOptionUnityDest([]);
            }
        }

    }

    async function GetDepartmentMOV(unidade: any) {
        let ret: AxiosResponse;
        let valor: any;

        try {
            ret = await api.get("local/listar", {
                params: {
                    id: unidade
                }
            })
            if (ret.data.status === 200) {
                valor = ret.data.data;

                const arr = [] as any;
                let result = valor;
                result.map((user: any) => {
                    return arr.push({ value: String(user.loc_pavement), label: user.loc_pavement });
                });

                setOptionspavementMov(arr)

            }
            setOpenModal(false)
        } catch (e) {
            setOpenModal(false)
            setOptionspavementMov([])
        }
    }

    async function GetDepartmentPavimentMOV(pavimento: any) {
        let ret: AxiosResponse;
        let valor: any;

        try {
            ret = await api.get("local/listarlocpaviment", {
                params: {
                    id: unityDest.split('_')[0],
                    paviment: pavimento
                }
            })
            if (ret.data.status === 200) {
                valor = ret.data.data;

                const arr = [] as any;
                let result = valor;
                result.map((user: any) => {
                    return arr.push({ value: String(user.loc_id), label: user.loc_description + " ("+ user.loc_alternativeidentifier + ")"});
                });
                setOptionsDepartmentMov(arr)
            }
            setOpenModal(false)
        } catch (e) {
            setOpenModal(false)
            setOptionsDepartmentMov([])
        }
    }

    const customStyles = {
        control: (provided: any, state: any) => ({
            ...provided,
            background: '#fff',
            minHeight: '30px',
            height: '30px',
            borderColor: state.isFocused ? 'grey' : '#92989e',

        }),


        valueContainer: (provided: any, state: any) => ({
            ...provided,
            height: '30px',
            padding: '0 6px'
        }),

        input: (provided: any, state: any) => ({
            ...provided,
            margin: '0px',
            fontSize: 14,

        }),
        indicatorsContainer: (provided: any, state: any) => ({
            ...provided,
            height: '30px',
        }),
    };

    function ValitarActive() {
        let e = document.getElementById("checkbox-active") as HTMLInputElement;

        if (e.checked) {

            setTsk_active(true)
        } else {

            setTsk_active(false)
        }
    }

    function ValitarNotification() {
        let e = document.getElementById("checkbox-notification") as HTMLInputElement;

        if (e.checked) {

            setTsk_notification(true)
        } else {

            setTsk_notification(false)
        }
    }

    function ValitarRecreate() {
        let e = document.getElementById("checkbox-recreate") as HTMLInputElement;

        if (e.checked) {

            setTsk_recreate(true)
        } else {

            setTsk_recreate(false)
        }
    }


    async function GetTasktype(tipoid: any) {
        let ret: AxiosResponse;
        let valor: any;
        try {
            ret = await api.get("tasktype/listar")
            if (ret.data.status === 200) {
                valor = ret.data.data;
                const arr = [] as any;

                let tasktype: any


                if (tipoid === '1') {
                 
                    tasktype = valor.filter(
                        (e: any) => {
                            return (e.tty_id !== 1 && e.tty_id !== 3)
                        }
                    );
                } else {
                   
                    tasktype = valor.filter(
                        (e: any) => {
                            return e.tty_id !== 1
                        }
                    );
                }

              

                tasktype.map((user: any) => {
                    return arr.push({ value: String(user.tty_id), label: user.tty_description });
                });

                setOptionsTasktype(arr)

            }
        } catch (e) {
            setOptionsTasktype([])
        }
    }

    async function GetAgent() {
        let ret: AxiosResponse;
        let valor: any;
        try {
            ret = await api.get("user/listar")
            if (ret.data.status === 200) {
                valor = ret.data.data;
                const arr = [] as any;
                let result = valor;
                result.map((user: any) => {
                    return arr.push({ value: String(user.age_id), label: user.age_name });
                });

                setOptionsAgent(arr)

            }
        } catch (e) {
            setOptionsAgent([])
        }
    }

    function SelecionarAgent(id: any) {

        if (id === '') {
            setAge_id("")
            setTea_id("")
            setTsf_id("")
            setIsDisabledForma(true)
        } else {
            setTea_id("")
            setTsf_id("")
            setAge_id(id)
            setIsDisabledForma(true)
        }
    }


    async function GetEquipe() {
        let ret: AxiosResponse;
        let valor: any;
        try {
            ret = await api.get("team/listar")
            if (ret.data.status === 200) {
                valor = ret.data.data;
                const arr = [] as any;
                let result = valor;
                result.map((user: any) => {
                    return arr.push({ value: String(user.tae_id), label: user.tae_description });
                });

                setOptionsEquipe(arr)

            }
        } catch (e) {
            setOptionsEquipe([])
        }
    }

    function SelecionarEquipe(id: any) {

        if (id === '') {
            setTea_id("")
            setAge_id("")
            setTsf_id("")
            setIsDisabledForma(true)
        } else {
            setAge_id("")
            setTea_id(id)
            setIsDisabledForma(false)
        }
    }

    async function GetForma() {
        let ret: AxiosResponse;
        let valor: any;
        try {
            ret = await api.get("taskform/listar")
            if (ret.data.status === 200) {
                valor = ret.data.data;
                const arr = [] as any;
                let result = valor;
                result.map((user: any) => {
                    return arr.push({ value: String(user.tsf_id), label: user.tsf_description });
                });

                setOptionsForma(arr)

            }
        } catch (e) {
            setOptionsForma([])
        }
    }

    async function GetAtivo() {
        let ret: AxiosResponse;
        let valor: any;
        try {
            ret = await api.get("asset/exibir")
            if (ret.data.status === 200) {
                valor = ret.data.data;


                setOptionsAtivoComp(valor)

                const arr = [] as any;
                let result = valor;
                result.map((user: any) => {
                    return arr.push({ value: String(user.ast_id), label: user.ast_alternativeidentifier ? user.ast_description + " (" + user.ast_alternativeidentifier + ")" : user.ast_description });
                });

                setOptionsAtivo(arr)

            }
        } catch (e) {
            setOptionsAtivo([])
        }
    }

    async function GetSituacao() {
        let ret: AxiosResponse;
        let valor: any;
        try {
            ret = await api.get("tasksituation/listar")
            if (ret.data.status === 200) {
                valor = ret.data.data;
                const arr = [] as any;
                let result = valor;

                const sit = valor.filter(
                    (e: any) => {
                        return (e.stn_id !== 70 && e.stn_id !== 80);
                    }
                );

                sit.map((user: any) => {
                    return arr.push({ value: String(user.stn_id), label: user.stn_description });
                });

                setOptionsSituacao(arr)

            }
        } catch (e) {
            setOptionsSituacao([])
        }
    }

    async function GetSource() {
        let ret: AxiosResponse;
        let valor: any;
        try {
            ret = await api.get("source/listar")
            if (ret.data.status === 200) {
                valor = ret.data.data;
                const arr = [] as any;
                let result = valor;
                result.map((user: any) => {
                    return arr.push({ value: String(user.src_id), label: user.src_description });
                });

                setOptionsFonte(arr)

            }
        } catch (e) {
            setOptionsFonte([])
        }
    }


    async function GetPreventiva(idtipo: any) {
        let ret: AxiosResponse;
        let valor: any;
        try {
            ret = await api.get("preventivetype/listarpreventivetypeentity", {
                params: {
                    id: idtipo
                }
            })
            if (ret.data.status === 200) {
                valor = ret.data.data;
                const arr = [] as any;
                let result = valor;
                result.map((user: any) => {
                    return arr.push({ value: String(user.pvt_id), label: user.pvt_description });
                });

                setOptionsTipoPreventiva(arr)

            }
        } catch (e) {
            setOptionsTipoPreventiva([])
        }
    }


    async function GetCorrectivetype(idtipo: any) {
        let ret: AxiosResponse;
        let valor: any;
        try {
            ret = await api.get("correctivetype/listarcorrectivetypeentity", {
                params: {
                    id: idtipo
                }
            })
            if (ret.data.status === 200) {
                valor = ret.data.data;
                const arr = [] as any;
                let result = valor;

                result.map((user: any) => {
                    return arr.push({ value: String(user.crt_id), label: user.crt_description });
                });

                setOptionsCorrectivetype(arr)

            }
        } catch (e) {
            setOptionsCorrectivetype([])
        }
    }



    async function SalvarTask() {
        let ret: AxiosResponse;


        setQualAlert(<div className='div-alert'><Loading /></div>)
        setOpenModal(true)



        if (tty_id === "") {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Selecione o tipo de tarefa.</span></div>
                <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
            </div>)

            setOpenModal(true)

        } else if (tty_id === "1" || tty_id === "2" || tty_id === "3" || tty_id === "4") {

            const FiltrarAlternativeIdentifier = optionsAtivoComp.filter(
                (e: any) => {
                    return String(e.ast_alternativeidentifier || "").toLowerCase() === (alternativeIdentifier || "").toLowerCase()
                }
            );

            const FiltrarSerialNumber = optionsAtivoComp.filter(
                (e: any) => {
                    return String(e.ast_serialnumber || "").toLowerCase() === (serialNumber || "").toLowerCase()
                }
            );

            const FiltrarTombamento = optionsAtivoComp.filter(
                (e: any) => {
                    return String(e.ast_tombamento || "").toLowerCase() === (tombamento || "").toLowerCase()
                }
            );

            if (entidade === '2' && ast_id === "") {

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Selecione o ativo.</span></div>
                    <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)

            } else if (stn_id === "") {

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Selecione a situação.</span></div>
                    <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)

            } else if (stn_id === '30' && age_id === "" && tea_id === "") {

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Selecione quem vai executar a tarefa (Usuário ou Equipe).</span></div>
                    <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)


            } else if (stn_id === '40' && age_id === "" && tea_id === "") {


                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Selecione quem vai executar a tarefa (Usuário ou Equipe).</span></div>
                    <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)


            } else if (tea_id !== "" && tsf_id === "") {


                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Selecione a forma de execução da equipe.</span></div>
                    <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)

            } else if (tipo === "3" && departmentMov === "") {


                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Selecione o destino da movimentação.</span></div>
                    <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)

            } else if (entidade === "1" && local === "") {


                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Selecione o local.</span></div>
                    <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)

            } else if (tty_id === "2" && crt_id === "") {

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Selecione o tipo de corretiva.</span></div>
                    <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)

            } else if (tty_id === '2' && tsk_observation === "") {
                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Digite o problema encontrato no campo de observação.</span></div>
                    <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)
            } else if (tty_id === "4" && pvt_id === "") {

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Selecione o Subgrupo Preventiva.</span></div>
                    <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)

            } else if (tty_id === "3" && alternativeIdentifier === '') {
                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Digite o identificador alternativo do ativo.</span></div>
                    <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)
            } else if (tty_id === "3" && alternativeIdentifier !== "" && alternativeIdentifierIni !== alternativeIdentifier && FiltrarAlternativeIdentifier.length > 0) {

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Identificador alternativo já está em uso.</span></div>
                    <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                </div>)

            } else if (tty_id === "3" && serialNumber !== "" && serialNumberIni !== serialNumber && FiltrarSerialNumber.length > 0) {

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Número de série já está em uso.</span></div>
                    <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)

            } else if (tty_id === "3" && tombamento !== "" && tombamentoIni !== tombamento && FiltrarTombamento.length > 0) {

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Tombamento já está em uso.</span></div>
                    <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)
            } else if (tsk_scheduleinitialdatehour === "") {

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Selecione a data/hora inicial.</span></div>
                    <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)

            } else if (tsk_schedulefinaldatehour !== "" && new Date(tsk_schedulefinaldatehour) <= new Date(tsk_scheduleinitialdatehour)) {
                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">A data final não pode ser menor ou igual a data incial.</span></div>
                    <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)

            } else if (tsk_schedulefinaldatehour !== "" && new Date(tsk_schedulefinaldatehour) < new Date()) {

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">A data final não pode ser menor que data atual.</span></div>
                    <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)

            } else {

                let contractDestMod: any;
                let unityDestMod: any;


                if(tty_id === '3' ){
                    contractDestMod = contractDest
                    unityDestMod = unityDest
                }else{
                    contractDestMod = null
                    unityDestMod = null
                }

                let locModo: any;

                if(entidade === '1'){
                    locModo = local
                }else if(entidade === '2' && tty_id === '3'){
                    locModo = departmentMov
                }else{
                    locModo = null
                }

                const newUuid = uuidv4()

                const dados = [{
                    tsk_active,
                    tsk_recreate,
                    tsk_notification,
                    tty_id: tty_id || null,
                    age_id: age_id || null,
                    tea_id: tea_id || null,
                    tsf_id: tsf_id || null,
                    ast_id: ast_id || null,
                    tsk_integrationid: tsk_integrationid || null,
                    stn_id: stn_id || null,
                    tsk_priority: tsk_priority || null,
                    src_id: src_id || null,
                    tsk_observation: tsk_observation || null,
                    tsk_responsible: tsk_responsible || null,
                    tsk_requester: tsk_requester || null,
                    tsk_telephone: tsk_telephone || null,
                    tsk_agendadescription: tsk_agendadescription || null,
                    agd_id: agd_id || null,
                    tsk_callback: tsk_callback || null,
                    tsk_frequency: tsk_frequency || null,
                    crt_id: crt_id || null,
                    pvt_id: pvt_id || null,
                    tsk_technicalinstruction: tsk_technicalinstruction || null,
                    tsk_image1: tsk_image1 || null,
                    tsk_image2: tsk_image2 || null,
                    ivb_id: ivb_id || null,
                    tsk_scheduleinitialdatehour,
                    tsk_schedulefinaldatehour: tsk_schedulefinaldatehour || null,
                    tsk_waitingtime: tsk_waitingtime || null,
                    ctc_id: contractDestMod || null,
                    unt_id: unityDestMod || null,
                    loc_id: locModo || null,
                    tsk_userdestination: user || null,
                    age_id_insert: secureLocalStorage.getItem('iag'),
                    ett_id: entidade,
                    tsk_accesstoken: newUuid
                }]

                console.log(dados)

                let dadosComp = [] as any

                if (tty_id === '3') {

                    if (alternativeIdentifier === alternativeIdentifierIni && serialNumberIni === serialNumber &&
                        tombamentoIni === tombamento && macIni === mac) {

                    } else {
                        dadosComp = [{
                            ast_alternativeidentifier: alternativeIdentifier,
                            ast_serialnumber: serialNumber,
                            ast_tombamento: tombamento,
                            ast_mac: mac,
                            ast_id: ast_id
                        }]

                    }
                }

                try {
                    ret = await api.post("task/insert", {
                        data: dados,
                        dadosComp: dadosComp
                    });
                    if (ret.data.status === 200) {

                        setQualAlert(<div className='div-alert'>
                            <span className="fa-regular fa-circle-check ControleIconAlert"></span>
                            <div className='div-info-alert'><span className="info-alert">Tarefa criada com sucesso.</span></div>
                            <div className='btn-alert' onClick={finalizarModal1}><span className="btn-alert-lab">OK</span></div>
                        </div>)
                    }

                } catch (e: any) {
                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert'><span className="info-alert">Não é possível criar a Tarefa.</span></div>
                        <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                    </div>)
                }
            }

        } else {



            if (entidade === '2' && ast_id === "") {

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Selecione o ativo.</span></div>
                    <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)

            } else if (entidade === "1" && local === "") {


                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Selecione o local.</span></div>
                    <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)

            } else if (stn_id === "") {

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Selecione a situação.</span></div>
                    <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)

            } else if (stn_id === '30' && age_id === "" && tea_id === "") {

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Selecione quem vai executar a tarefa (Usuário ou Equipe).</span></div>
                    <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)


            } else if (stn_id === '40' && age_id === "" && tea_id === "") {


                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Selecione quem vai executar a tarefa (Usuário ou Equipe).</span></div>
                    <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)


            } else if (tea_id !== "" && tsf_id === "") {


                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Selecione a forma de execução da equipe.</span></div>
                    <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)

            } else if (tsk_scheduleinitialdatehour === "") {

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Selecione a data/hora inicial.</span></div>
                    <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)

            } else if (tsk_schedulefinaldatehour !== "" && new Date(tsk_schedulefinaldatehour) <= new Date(tsk_scheduleinitialdatehour)) {
                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">A data final não pode ser menor ou igual a data inicial.</span></div>
                    <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)

            } else if (tsk_schedulefinaldatehour !== "" && new Date(tsk_schedulefinaldatehour) < new Date()) {

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">A data final não pode ser menor que data atual.</span></div>
                    <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)

            } else {

                const newUuid = uuidv4()
                
                const dados = [{
                    tsk_active,
                    tsk_recreate,
                    tsk_notification,
                    tty_id: tty_id || null,
                    age_id: age_id || null,
                    tea_id: tea_id || null,
                    tsf_id: tsf_id || null,
                    ast_id: ast_id || null,
                    tsk_integrationid: tsk_integrationid || null,
                    stn_id: stn_id || null,
                    tsk_priority: tsk_priority || null,
                    src_id: src_id || null,
                    tsk_observation: tsk_observation || null,
                    tsk_responsible: tsk_responsible || null,
                    tsk_requester: tsk_requester || null,
                    tsk_telephone: tsk_telephone || null,
                    tsk_agendadescription: tsk_agendadescription || null,
                    agd_id: agd_id || null,
                    tsk_callback: tsk_callback || null,
                    tsk_frequency: tsk_frequency || null,
                    crt_id: crt_id || null,
                    pvt_id: pvt_id || null,
                    tsk_technicalinstruction: tsk_technicalinstruction || null,
                    tsk_image1: tsk_image1 || null,
                    tsk_image2: tsk_image2 || null,
                    ivb_id: ivb_id || null,
                    tsk_scheduleinitialdatehour,
                    tsk_schedulefinaldatehour: tsk_schedulefinaldatehour || null,
                    tsk_waitingtime: tsk_waitingtime || null,
                    ctc_id: contractDest || null,
                    unt_id: unityDest || null,
                    loc_id: local || null,
                    tsk_userdestination: user || null,
                    age_id_insert: secureLocalStorage.getItem('iag'),
                    ett_id: entidade,
                    tsk_accesstoken: newUuid
                }]

                console.log(dados)

                let dadosComp = [] as any

                try {
                    ret = await api.post("task/insert", {
                        data: dados,
                        dadosComp: dadosComp
                    });
                    if (ret.data.status === 200) {

                        setQualAlert(<div className='div-alert'>
                            <span className="fa-regular fa-circle-check ControleIconAlert"></span>
                            <div className='div-info-alert'><span className="info-alert">Tarefa criada com sucesso.</span></div>
                            <div className='btn-alert' onClick={finalizarModal1}><span className="btn-alert-lab">OK</span></div>
                        </div>)
                    }

                } catch (e: any) {
                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert'><span className="info-alert">Não é possível realizar a movimentação</span></div>
                        <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                    </div>)
                }

            }



        }

    }


    function finalizarModal1() {
        navigate('/task');
    }

    function finalizarModal2() {
        setOpenModal(false)
    }

    function validarDataFim(id: any) {

        if (tsk_scheduleinitialdatehour !== "") {

            setTsk_schedulefinaldatehour(id)

        } else {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Selecione a data/hora inicial.</span></div>
                <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
            </div>)

            setOpenModal(true)

        }
    }


    function incluirTipoManutencao(id: any) {

        if (id === "") {
            setTipo('');
            setTty_id(id);
            setUnityDest("")
            setPavementMov('');
            setDepartmentMov('');
            setContractDest('')
            setUser("");
            setOptionspavementMov([])
            setOptionUnityDest([])
            setOptionsDepartmentMov([])
            setTsk_responsible("")
            setTsk_requester("")
            setTsk_telephone("")
            setTsk_agendadescription("")
            setAgd_id("")
            setTsk_callback("")
            setTsk_frequency("")
            setCrt_id("")
            setPvt_id("")
            setIvb_id("")

        } else if (id === '1') {
            setTty_id('')
            setUnityDest("")
            setPavementMov('');
            setDepartmentMov('');
            setContractDest('')
            setUser("");
            setOptionspavementMov([])
            setOptionUnityDest([])
            setOptionsDepartmentMov([])
            setTipo('')
            setTsk_responsible("")
            setTsk_requester("")
            setTsk_telephone("")
            setTsk_agendadescription("")
            setAgd_id("")
            setTsk_callback("")
            setTsk_frequency("")
            setCrt_id("")
            setPvt_id("")
            setIvb_id("")

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Você deve criar esse tipo de tarefa pelo modo inventário mobile.</span></div>
                <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
            </div>)

            setOpenModal(true)

        } else if (id === '2') {


            setIsDisabledCorretiva(false)
            setIsDisabledPreventiva(true)
            setPvt_id("")

            setUnityDest("")
            setPavementMov('');
            setDepartmentMov('');
            setContractDest('')
            setUser("");
            setOptionspavementMov([])
            setOptionUnityDest([])
            setOptionsDepartmentMov([])
            setTipo('2')
            setTty_id(id)
            setTsk_responsible("")
            setTsk_requester("")
            setTsk_telephone("")
            setTsk_agendadescription("")
            setAgd_id("")
            setTsk_callback("")
            setTsk_frequency("")
            setCrt_id("")
            setPvt_id("")
            setIvb_id("")
        }
        else if (id === '3') {
            GetContrato()
            setTipo(id)
            setTty_id(id)
            setTsk_responsible("")
            setTsk_requester("")
            setTsk_telephone("")
            setTsk_agendadescription("")
            setAgd_id("")
            setTsk_callback("")
            setTsk_frequency("")
            setCrt_id("")
            setPvt_id("")
            setIvb_id("")
        }
        else if (id === '4') {

            setIsDisabledCorretiva(true)
            setIsDisabledPreventiva(false)
            setCrt_id("")
            setUnityDest("")
            setPavementMov('');
            setDepartmentMov('');
            setContractDest('')
            setUser("");
            setOptionspavementMov([])
            setOptionUnityDest([])
            setOptionsDepartmentMov([])
            setTipo('4')
            setTty_id(id)
            setTsk_responsible("")
            setTsk_requester("")
            setTsk_telephone("")
            setTsk_agendadescription("")
            setAgd_id("")
            setTsk_callback("")
            setTsk_frequency("")
            setCrt_id("")
            setPvt_id("")
            setIvb_id("")
        } else {
            setTipo('');
            setTty_id(id);
            setUnityDest("")
            setPavementMov('');
            setDepartmentMov('');
            setContractDest('')
            setUser("");
            setOptionspavementMov([])
            setOptionUnityDest([])
            setOptionsDepartmentMov([])
            setTsk_responsible("")
            setTsk_requester("")
            setTsk_telephone("")
            setTsk_agendadescription("")
            setAgd_id("")
            setTsk_callback("")
            setTsk_frequency("")
            setCrt_id("")
            setPvt_id("")
            setIvb_id("")
        }
    }

    function IncluirContratoDest(Contrato: any) {


        if (Contrato === '') {
            setUnityDest("")
            setPavementMov('');
            setDepartmentMov('');
            setContractDest('')
            setUser("");

            setOptionspavementMov([])
            setOptionUnityDest([])
            setOptionsDepartmentMov([])

        } else {
            setQualAlert(<div className='div-alert'><Loading /></div>)
setOpenModal(true)
            setUnityDest("")
            setPavementMov('');
            setDepartmentMov('');
            setUser("");
            setOptionspavementMov([])
            setOptionsDepartmentMov([])
            GetUnidadeSelDest(Contrato.split('_')[0])
            setContractDest(Contrato)

        }
    }

    function IncluirUnityMOV(Unidade: any) {

        if (Unidade === '') {
            setUnityDest('')
            setOptionspavementMov([])
            setPavementMov('');
            setOptionsDepartmentMov([])
            setDepartmentMov('');
            setUser("");
        } else {
            setQualAlert(<div className='div-alert'><Loading /></div>)
setOpenModal(true)
            setOptionspavementMov([])
            setPavementMov('');
            setOptionsDepartmentMov([])
            setDepartmentMov('');
            setUser("");
            setUnityDest(Unidade)
            GetDepartmentMOV(Unidade.split('_')[0])

        }
    }

    function IncluirLocaisMov(id: any) {
        if (id === '') {
            setUser("");
            setPavementMov("")
            setOptionsDepartmentMov([])
            setDepartmentMov('');

        } else {
            setQualAlert(<div className='div-alert'><Loading /></div>)
            setOpenModal(true)
            setPavementMov(id)
            GetDepartmentPavimentMOV(id)
        }
    }

    //--------------------------------------------- Tipo de Tarefa

    function ChamarModalTipoAtividade() {

        setTty_id("")

        sessionStorage.removeItem("dadosTipoTarefa");
        setQualTitulo('Cadastrar Tipo de Tarefa')
        setQualGrid((<><GridSecundario 
            PegarValorGrid={PegarValorGridTty_id} 
            Iconeadd={ChamarCadastroTipoAtividade} Iconeditar={EditarTipoAtividade} iconeExcluir={TipoAtividadeDeletar}
            th1='Ativo'
            th2='ID'
            th3='Descrição'
            th4='Identificador Alternativo'
            th5=''
            th6=''
            endPoint='tasktype/buscartasktype'
            consult='N'
            col1='1'
            col2='2'
            col3='3'
            ambiente='%'
            dbtnDelete='N'
            dbtnAdd='N'
            pag="ok"
        /></>))
        setOpenModalIncluirNovo(true)

    }


    function ChamarCadastroTipoAtividade() {
        sessionStorage.removeItem("dadosTipoTarefa");
        setQualGrid(<><FormTipoTarefa voltar={BtnVoltarTipoAtividade} /></>)
    }

    function BtnVoltarTipoAtividade() {

        setQualGrid((<><GridSecundario PegarValorGrid={PegarValorGridTty_id} Iconeadd={ChamarCadastroTipoAtividade} Iconeditar={EditarTipoAtividade} iconeExcluir={TipoAtividadeDeletar}
            th1='Ativo'
            th2='ID'
            th3='Descrição'
            th4='Identificador Alternativo'
            th5=''
            th6=''
            endPoint='tasktype/buscartasktype'
            consult='N'
            col1='1'
            col2='2'
            col3='3'
            ambiente='%'
            dbtnDelete='N'
            dbtnAdd='N'
            pag="ok"
        /></>))
        setOptionsTasktype([])
        GetTasktype(entidade);
    }

    async function EditarTipoAtividade(obj: any) {

        sessionStorage.setItem("dadosTipoTarefa", JSON.stringify(obj));
        setQualGrid(<><FormTipoTarefa voltar={BtnVoltarTipoAtividade} /></>)
    }

    function TipoAtividadeDeletar(obj: any) {

        setOpenModal(true)

        setQualAlert(<div className='div-alert'>
            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
            <div className='div-info-alert-atencao'><span className="info-alert-atencao">Deseja deletar o registro?</span></div>
            <div className='div-info-alert'><span className="info-alert">Você não poderá reverter.</span></div>

            <div className='btn-alert-div'>
                <span className='btn-alert-esc' onClick={() => deletarTipoAtividade(obj.tty_id)}>
                    <span className="btn-alert-lab">SIM</span>
                </span>
                <span className='btn-alert-dir' onClick={finalizarModal2}>
                    <span className="btn-alert-lab">NÃO</span>
                </span>
            </div>
        </div>)

    }

    async function deletarTipoAtividade(id: any) {

        setOpenModal(false)
        setQualGrid(<><Loading /></>)

        let ret: AxiosResponse;
        try {
            ret = await api.delete("tasktype/delete", {
                data: { id: id }
            });
            if (ret.data.status === 200) {

                setQualGrid((<><GridSecundario PegarValorGrid={PegarValorGridTty_id} Iconeadd={ChamarCadastroTipoAtividade} Iconeditar={EditarTipoAtividade} iconeExcluir={TipoAtividadeDeletar}
                    th1='Ativo'
                    th2='ID'
                    th3='Descrição'
                    th4='Identificador Alternativo'
                    th5=''
                    th6=''
                    endPoint='tasktype/buscartasktype'
                    consult='N'
                    col1='1'
                    col2='2'
                    col3='3'
                    ambiente='%'
                    dbtnDelete='N'
                    dbtnAdd='N'
                    pag="ok"
                /></>))
                setOptionsTasktype([])
                GetTasktype(entidade);
            }
        } catch (e: any) {
            setQualGrid((<><GridSecundario PegarValorGrid={PegarValorGridTty_id} Iconeadd={ChamarCadastroTipoAtividade} Iconeditar={EditarTipoAtividade} iconeExcluir={TipoAtividadeDeletar}
                th1='Ativo'
                th2='ID'
                th3='Descrição'
                th4='Identificador Alternativo'
                th5=''
                th6=''
                endPoint='tasktype/buscartasktype'
                consult='N'
                col1='1'
                col2='2'
                col3='3'
                ambiente='%'
                dbtnDelete='N'
                dbtnAdd='N'
                pag="ok"
            /></>))
            setOptionsTasktype([])
            GetTasktype(entidade);
        }

        setOpenModal(false)
    }


    function PegarValorGridTty_id(valor: any) {

        if (valor.tty_id === 1) {
            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Você deve criar esse tipo de tarefa pelo modo inventário mobile.</span></div>
                <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
            </div>)

            setOpenModal(true)
        } else {

            incluirTipoManutencao(String(valor.tty_id))
            setOpenModalIncluirNovo(false)
        }


    }


    function fecharOpenModalIncluirNovo() {
        setOpenModalIncluirNovo(false)
    }

    //=================================Corretiva=============================================


    function ChamarModalTipoCorretiva() {

        if (tty_id !== '4') {
            setQualTitulo('Tipo Corretiva')
            setQualGrid(<><GridSecundario PegarValorGrid={PegarValorGridCorretiva}
                Iconeadd={ChamarCadastroTipoCorretiva}
                Iconeditar={EditarTipoCorretiva}
                iconeExcluir={tipoCorretivaDeletar}
                th1='Ativo'
                th2='ID'
                th3='Descrição'
                th4='Identificador Alternativo'
                th5=''
                th6=''
                endPoint='correctivetype/buscarcorrectivetype'
                consult='N'
                col1='1'
                col2='2'
                col3='3'
                params={entidade}
                pag="ok"
            /></>)

            setOpenModalIncluirNovo(true)
            sessionStorage.removeItem("dadosFab");
        }
    }

    function ChamarCadastroTipoCorretiva() {
        sessionStorage.removeItem("dadosFab");
        setQualTitulo('Tipo Corretiva')
        setQualGrid(<><FormTipoCorretiva voltar={BtnVoltarTipoCorretiva} /></>)
    }

    async function EditarTipoCorretiva(obj: any) {
        sessionStorage.setItem("dadosFab", JSON.stringify(obj));
        setQualTitulo('Tipo Corretiva')
        setQualGrid(<><FormTipoCorretiva voltar={BtnVoltarTipoCorretiva} /></>)
    }

    function tipoCorretivaDeletar(obj: any) {

        setQualAlert(<div className='div-alert'>
            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
            <div className='div-info-alert-atencao'><span className="info-alert-atencao">Deseja deletar o registro?</span></div>
            <div className='div-info-alert'><span className="info-alert">Você não poderá reverter.</span></div>

            <div className='btn-alert-div'>
                <span className='btn-alert-esc' onClick={() => deletarTipoCorretiva(obj.crt_id)}>
                    <span className="btn-alert-lab">SIM</span>
                </span>
                <span className='btn-alert-dir' onClick={finalizarModal2}>
                    <span className="btn-alert-lab">NÃO</span>
                </span>
            </div>
        </div>)

        setOpenModal(true)


    }

    async function deletarTipoCorretiva(id: any) {
        setOpenModal(false)
        setQualGrid(<><Loading /></>)
        let ret: AxiosResponse;
        try {
            ret = await api.delete("correctivetype/delete", {
                data: { id: id }
            });
            if (ret.data.status === 200) {
                setQualTitulo('Tipo Corretiva')
                setQualGrid(<><GridSecundario PegarValorGrid={PegarValorGridCorretiva}
                    Iconeadd={ChamarCadastroTipoCorretiva}
                    Iconeditar={EditarTipoCorretiva}
                    iconeExcluir={tipoCorretivaDeletar}
                    th1='Ativo'
                    th2='ID'
                    th3='Descrição'
                    th4='Identificador Alternativo'
                    th5=''
                    th6=''
                    endPoint='correctivetype/buscarcorrectivetype'
                    consult='N'
                    col1='1'
                    col2='2'
                    col3='3'
                    params={entidade}
                    pag="ok"
                /></>)

                GetCorrectivetype(entidade);
            }
        } catch (e: any) {
            setQualTitulo('Tipo Corretiva')
            setQualGrid(<><GridSecundario PegarValorGrid={PegarValorGridCorretiva}
                Iconeadd={ChamarCadastroTipoCorretiva}
                Iconeditar={EditarTipoCorretiva}
                iconeExcluir={tipoCorretivaDeletar}
                th1='Ativo'
                th2='ID'
                th3='Descrição'
                th4='Identificador Alternativo'
                th5=''
                th6=''
                endPoint='correctivetype/buscarcorrectivetype'
                consult='N'
                col1='1'
                col2='2'
                col3='3'
                params={entidade}
                pag="ok"
            /></>)

            GetCorrectivetype(entidade);
        }
    }

    function BtnVoltarTipoCorretiva() {
        setQualTitulo('Tipo Corretiva')
        setQualGrid(<><GridSecundario PegarValorGrid={PegarValorGridCorretiva}
            Iconeadd={ChamarCadastroTipoCorretiva}
            Iconeditar={EditarTipoCorretiva}
            iconeExcluir={tipoCorretivaDeletar}
            th1='Ativo'
            th2='ID'
            th3='Descrição'
            th4='Identificador Alternativo'
            th5=''
            th6=''
            endPoint='correctivetype/buscarcorrectivetype'
            consult='N'
            col1='1'
            col2='2'
            col3='3'
            params={entidade}
            pag="ok"
        /></>)

        GetCorrectivetype(entidade);
    }

    function PegarValorGridCorretiva(valor: any) {
        setCrt_id(String(valor.crt_id))
        setOpenModalIncluirNovo(false)
    }


    //=================================Preventiva=============================================

    function PegarValorGridPreventiva(valor: any) {
        setPvt_id(String(valor.pvt_id))
        setOpenModalIncluirNovo(false)
    }

    function ChamarModalTipoPreventiva() {

        if (tty_id !== '2') {
            setPvt_id("")
            setQualTitulo('Tipo Preventiva')
            setQualGrid(<><GridSecundario PegarValorGrid={PegarValorGridPreventiva}
                Iconeadd={ChamarCadastroTipoPreventiva}
                Iconeditar={EditarTipoPreventiva}
                iconeExcluir={tipoPreventivaDeletar}
                th1='Ativo'
                th2='ID'
                th3='Descrição'
                th4='Identificador Alternativo'
                th5=''
                th6=''
                endPoint='preventivetype/buscarpreventivetype'
                consult='N'
                col1='1'
                col2='2'
                col3='3'
                params={entidade}
                pag="ok"
            /></>)

            setOpenModalIncluirNovo(true)
            sessionStorage.removeItem("dadosFab");
        }
    }


    function ChamarCadastroTipoPreventiva() {
        sessionStorage.removeItem("dadosFab");
        setQualTitulo('Tipo Preventiva')
        setQualGrid(<><FormTipoPreventiva voltar={BtnVoltarTipoPreventiva} /></>)
    }

    async function EditarTipoPreventiva(obj: any) {
        sessionStorage.setItem("dadosFab", JSON.stringify(obj));
        setQualTitulo('Tipo Preventiva')
        setQualGrid(<><FormTipoPreventiva voltar={BtnVoltarTipoPreventiva} /></>)
    }

    function tipoPreventivaDeletar(obj: any) {

        setQualAlert(<div className='div-alert'>
            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
            <div className='div-info-alert-atencao'><span className="info-alert-atencao">Deseja deletar o registro?</span></div>
            <div className='div-info-alert'><span className="info-alert">Você não poderá reverter.</span></div>

            <div className='btn-alert-div'>
                <span className='btn-alert-esc' onClick={() => deletarTipoPreventiva(obj.pvt_id)}>
                    <span className="btn-alert-lab">SIM</span>
                </span>
                <span className='btn-alert-dir' onClick={finalizarModal2}>
                    <span className="btn-alert-lab">NÃO</span>
                </span>
            </div>
        </div>)

        setOpenModal(true)


    }

    async function deletarTipoPreventiva(id: any) {
        setOpenModal(false)
        setQualGrid(<><Loading /></>)
        let ret: AxiosResponse;
        try {
            ret = await api.delete("preventivetype/delete", {
                data: { id: id }
            });
            if (ret.data.status === 200) {
                setQualTitulo('Tipo Preventiva')
                setQualGrid(<><GridSecundario PegarValorGrid={PegarValorGridPreventiva}
                    Iconeadd={ChamarCadastroTipoPreventiva}
                    Iconeditar={EditarTipoPreventiva}
                    iconeExcluir={tipoPreventivaDeletar}
                    th1='Ativo'
                    th2='ID'
                    th3='Descrição'
                    th4='Identificador Alternativo'
                    th5=''
                    th6=''
                    endPoint='preventivetype/buscarpreventivetype'
                    consult='N'
                    col1='1'
                    col2='2'
                    col3='3'
                    params={entidade}
                    pag="ok"
                /></>)

                GetPreventiva(entidade)

            }
        } catch (e: any) {
            setQualTitulo('Tipo Preventiva')
            setQualGrid(<><GridSecundario PegarValorGrid={PegarValorGridPreventiva}
                Iconeadd={ChamarCadastroTipoPreventiva}
                Iconeditar={EditarTipoPreventiva}
                iconeExcluir={tipoPreventivaDeletar}
                th1='Ativo'
                th2='ID'
                th3='Descrição'
                th4='Identificador Alternativo'
                th5=''
                th6=''
                endPoint='preventivetype/buscarpreventivetype'
                consult='N'
                col1='1'
                col2='2'
                col3='3'
                params={entidade}
                pag="ok"
            /></>)

            GetPreventiva(entidade)
        }
    }

    function BtnVoltarTipoPreventiva() {
        setQualTitulo('Tipo Preventiva')
        setQualGrid(<><GridSecundario PegarValorGrid={PegarValorGridPreventiva}
            Iconeadd={ChamarCadastroTipoPreventiva}
            Iconeditar={EditarTipoPreventiva}
            iconeExcluir={tipoPreventivaDeletar}
            th1='Ativo'
            th2='ID'
            th3='Descrição'
            th4='Identificador Alternativo'
            th5=''
            th6=''
            endPoint='preventivetype/buscarpreventivetype'
            consult='N'
            col1='1'
            col2='2'
            col3='3'
            params={entidade}
            pag="ok"
        /></>)

        GetPreventiva(entidade)

    }

    const maskOnlyNumbers = (value: any) => {
        return value.replace(/\D/g, "");
    };


    async function Getinventoryeditar(id: any) {

        if (id === "") {
            setAst_id("")
        } else {

            setAst_id(id)
            let ret: AxiosResponse;
            let valor: any;

            try {
                ret = await api.get("asset/asseteditar", {
                    params: {
                        id: id
                    }
                })
                if (ret.data.status === 200) {
                    valor = ret.data.data[0];

                    setAlternativeIdentifier(valor.ast_alternativeidentifier)
                    setSerialNumber(valor.ast_serialnumber)
                    setTombamento(valor.ast_tombamento)
                    setMac(valor.ast_mac)

                    setAlternativeIdentifierIni(valor.ast_alternativeidentifier)
                    setSerialNumberIni(valor.ast_serialnumber)
                    setTombamentoIni(valor.ast_tombamento)
                    setMacIni(valor.ast_mac)

                }
            } catch (e) {

            }
        }
    }


    //--------------------------------------------- Tipo de tarefas

    //-----------------------ALTERAÇÃO---------------------------------

    function IncluirContratoLoc(Contrato: any) {


        if (Contrato === '') {

            setContractLocal('');
            setUnityLocal('');
            setPavementLocal('');
            setLocal('');
            setAst_id("")
            setOptionUnityDestAtivo([])
            setOptionspavementMovAtivo([])
            setOptionsDepartmentMovAtivo([])
            setOptionsAtivo([])
           
            GetAtivo()
            GetLoc()

        } else {

            setQualAlert(<div className='div-alert'><Loading /></div>)
            setOpenModal(true)
   
            setUnityLocal('');
            setPavementLocal('');
            setLocal('');
            setOptionUnityDestAtivo([])
            setOptionspavementMovAtivo([])
            setOptionsDepartmentMovAtivo([])
            setOptionsAtivo([])
            setAst_id("")

            GetUnidadeLoc(Contrato.split('_')[0])

            setContractLocal(Contrato)



        }
    }


    async function GetUnidadeLoc(id: any) {
        let ret: AxiosResponse;
        let valor: any;


        if (id === '' || id === 'Selecione...' || id === 'undefined' || id === undefined) {
            setUnityLocal('');
            setOptionUnityDestAtivo([])

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Selecione o contrato de destino.</span></div>
                <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
            </div>)

            setOpenModal(true)

        } else {
            try {
                ret = await api.get("unity/listar", {
                    params: {
                        id: id
                    }
                })
                if (ret.data.status === 200) {
                    valor = ret.data.data;


                    const arr = [] as any;
                    let result = valor;
                    result.map((user: any) => {
                        return arr.push({ value: String(user.unt_id + "_" + user.unt_description), label: user.unt_description });
                    });
                    setOptionUnityDestAtivo(arr)

                }
                setOpenModal(false)

            } catch (e) {
                setOpenModal(false)

                setOptionUnityDestAtivo([]);
            }
        }

    }


    async function GetLoc() {
        let ret: AxiosResponse;
        let valor: any;
        try {
            ret = await api.get("local/listarlocal")
            if (ret.data.status === 200) {
                valor = ret.data.data;

                const arr = [] as any;
                let result = valor;

                result.map((user: any) => {
                    return arr.push({ value: String(user.loc_id), label: user.loc_description + " ("+ user.loc_alternativeidentifier + ")"});
                });

                setOptionsDepartmentMovAtivo(arr)
            }
        } catch (e) {
            setOptionsDepartmentMovAtivo([])
        }
    }


    async function GetContratoLoc() {
        let ret: AxiosResponse;
        let valor: any;
        try {
            ret = await api.get("contract/listar")
            if (ret.data.status === 200) {
                valor = ret.data.data;

                const arr = [] as any;
                let result = valor;
                result.map((user: any) => {
                    return arr.push({ value: String(user.ctc_id + '_' + user.ctc_description), label: user.ctc_description });
                });
                setOptionContractAtivo(arr)
            }
        } catch (e) {
            setOptionContractAtivo([])
        }
    }


    function IncluirUnityLoc(Unidade: any) {

        if (Unidade === '') {

     
            setUnityLocal('');
            setPavementLocal('');
            setLocal('');
           setOptionspavementMovAtivo([])
           setOptionsDepartmentMovAtivo([])
           setOptionsAtivo([])
           setAst_id("")

        } else {
            setQualAlert(<div className='div-alert'><Loading /></div>)
            setOpenModal(true)

            setUnityLocal('');
            setPavementLocal('');
            setLocal('');
            setOptionspavementMovAtivo([])
            setOptionsDepartmentMovAtivo([])
            setOptionsAtivo([])
            setAst_id("")
           setUnityLocal(Unidade)
           
           GetDepartmentLoc(Unidade.split('_')[0])

        }
    }


    async function GetDepartmentLoc(unidade: any) {
        let ret: AxiosResponse;
        let valor: any;

        try {
            ret = await api.get("local/listar", {
                params: {
                    id: unidade
                }
            })
            if (ret.data.status === 200) {
                valor = ret.data.data;

                const arr = [] as any;
                let result = valor;
                result.map((user: any) => {
                    return arr.push({ value: String(user.loc_pavement), label: user.loc_pavement });
                });

                setOptionspavementMovAtivo(arr)

            }
            setOpenModal(false)
        } catch (e) {
            setOpenModal(false)
            setOptionspavementMovAtivo([])
        }
    }

    function IncluirLocaisLoc(id: any) {
        if (id === '') {
        
            setPavementLocal('');
            setLocal('');
            setOptionsDepartmentMovAtivo([])
            setOptionsAtivo([])
            setAst_id("")

        } else {

            setQualAlert(<div className='div-alert'><Loading /></div>)
            setOpenModal(true)
            setLocal('');
            setAst_id("")
            setOptionsAtivo([])
            setPavementLocal(id)
            GetDepartmentPavimentLoc(id)

        }
    }

    async function GetDepartmentPavimentLoc(pavimento: any) {
        let ret: AxiosResponse;
        let valor: any;

        try {
            ret = await api.get("local/listarlocpaviment", {
                params: {
                    id: unityLocal.split('_')[0],
                    paviment: pavimento
                }
            })
            if (ret.data.status === 200) {
                valor = ret.data.data;

                const arr = [] as any;
                let result = valor;
                result.map((user: any) => {
                    return arr.push({ value: String(user.loc_id), label: user.loc_description + " ("+ user.loc_alternativeidentifier + ")"});
                });
                setOptionsDepartmentMovAtivo(arr)
            }
            setOpenModal(false)
        } catch (e) {
            setOpenModal(false)
            setOptionsDepartmentMovAtivo([])
        }
    }

 async function incluirLocAst(id: any){

        if (id === '') {
        

            setLocal('');
            setAst_id("")
            setOptionsAtivo([])
        } else {

            setQualAlert(<div className='div-alert'><Loading /></div>)
            setOpenModal(true)
            setAst_id("")
            setLocal(id);
          await GetAtivoLoc(id)
        }

    }


    async function GetAtivoLoc(local: any) {
        let ret: AxiosResponse;
        let valor: any;

        try {
            ret = await api.get("local/listarativoloc", {
                params: {
                    id: local
                 }
            })
            if (ret.data.status === 200) {
                valor = ret.data.data;

                const arr = [] as any;
                let result = valor;
                result.map((user: any) => {
                    return arr.push({ value: String(user.ast_id), label: user.ast_alternativeidentifier ? user.ast_description + " (" + user.ast_alternativeidentifier + ")" : user.ast_description });
                });
                setOptionsAtivo(arr)
            }
            setOpenModal(false)
        } catch (e) {
            setOpenModal(false)
            setOptionsAtivo([])
        }
    }

    
    return (
        <>
            <Nav />
            <div className="main-content">
                <Header />
                <main>
                    <div className='containerPrincipal'>
                        <div className="title-Page">
                            <div className="title-text">
                                <div className="size-x circle">1</div>
                                <h2>Informações Gerais</h2>
                            </div>
                            <hr />
                        </div>

                        <div className="container-cad-prod60">
                            <div id="content1">
                                <div className="class-checkbox">
                                    <input type="checkbox" id="checkbox-active" checked={tsk_active} onChange={ValitarActive} />
                                    <h4 className="lab-ativo">Ativo</h4>
                                </div>
                            </div>
                            <div id="content2">
                                <div className="class-checkbox">
                                    <input type="checkbox" id="checkbox-recreate" checked={tsk_recreate} onChange={ValitarRecreate} />
                                    <h4 className="lab-ativo">Recriar tarefa após finalizar</h4>
                                </div>
                            </div>
                            <div id="content3">
                                <div className="class-checkbox">
                                    <input type="checkbox" id="checkbox-notification" checked={tsk_notification} onChange={ValitarNotification} />
                                    <h4 className="lab-ativo">Notificação</h4>
                                </div>
                            </div>
                        </div>


                       
                           
                            <div className="container-cad-prod35">
                                <div id="content1">
                                    <div className="select-box-ativos-select">
                                        <div className='details-flex'>
                                            <span className="details">Tipo de Tarefa *</span>
                                            <MdOutlineAddCircle className='details-flex-icon-ativo' onClick={ChamarModalTipoAtividade} />
                                            <span className="details-esc-ativo" onClick={ChamarModalTipoAtividade}>Incluir Novo</span>
                                        </div>

                                        <Select
                                            placeholder={'Selecione...'}
                                            options={optionsTasktype}
                                            styles={customStyles}
                                            value={optionsTasktype.filter(function (option: any) {
                                                return option.value === tty_id;
                                            })}
                                            onChange={(options: any) =>
                                                !options ? incluirTipoManutencao("") : incluirTipoManutencao(options.value)
                                            }
                                            isClearable
                                            noOptionsMessage={() => "Não encontrado..."}
                                            theme={(theme: any) => ({
                                                ...theme,
                                                borderRadius: 4,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#ebebeb',
                                                    primary: 'black',
                                                },
                                            })}
                                        />
                                    </div>
                                </div>

                                <div id="content2">
                                    <div className="input-box-ativos">
                                        <span className="details">ID para Integração</span>
                                        <input
                                            type="text"
                                            autoComplete="off"
                                            onChange={(e) => setTsk_integrationid(e.target.value)}
                                            value={tsk_integrationid}
                                        />
                                    </div>
                                </div>
                            </div>
                        
























                        





























                       

                            <div className="container-cad-prod4">
                                <div id="content1">
                                    <div className="select-box-ativos-select">
                                        <span className="details">Contrato *</span>
                                        <Select
                                            maxMenuHeight={200}
                                            placeholder={'Selecione...'}
                                            options={optionContractAtivo}
                                            styles={customStyles}
                                            value={optionContractAtivo.filter(function (option: any) {
                                                return option.value === contractLocal;
                                            })}
                                            onChange={(options: any) =>
                                                !options ? IncluirContratoLoc("") : IncluirContratoLoc(options.value)
                                            }
                                            isClearable
                                            noOptionsMessage={() => "Não encontrado..."}
                                            theme={(theme: any) => ({
                                                ...theme,
                                                borderRadius: 4,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#ebebeb',
                                                    primary: 'black',
                                                },
                                            })}
                                        />
                                    </div>
                                </div>
                                <div id="content2">
                                    <div className="select-box-ativos-select">
                                        <span className="details">Unidade *</span>
                                        <Select
                                            maxMenuHeight={200}
                                            placeholder={'Selecione...'}
                                            options={optionUnityDestAtivo}
                                            styles={customStyles}
                                            value={optionUnityDestAtivo.filter(function (option: any) {
                                                return option.value === unityLocal;
                                            })}
                                            onChange={(options: any) =>
                                                !options ? IncluirUnityLoc("") : IncluirUnityLoc(options.value)
                                            }
                                            isClearable

                                            noOptionsMessage={() => "Não encontrado..."}
                                            theme={(theme: any) => ({
                                                ...theme,
                                                borderRadius: 4,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#ebebeb',
                                                    primary: 'black',
                                                },
                                            })}
                                        />
                                    </div>
                                </div>
                            </div>

                        {entidade === '1' ? (<>
                            <div className="container-cad-prod4">
                                <div id="content1">
                                    <div className="select-box-ativos-select">
                                        <span className="details">Pavimento *</span>
                                        <Select
                                            maxMenuHeight={200}
                                            placeholder={'Selecione...'}
                                            options={optionspavementMovAtivo}
                                            styles={customStyles}
                                            value={optionspavementMovAtivo.filter(function (option: any) {
                                                return option.value === pavementLocal;
                                            })}
                                            onChange={(options: any) =>
                                                !options ? IncluirLocaisLoc("") : IncluirLocaisLoc(options.value)
                                            }
                                            isClearable
                                            noOptionsMessage={() => "Não encontrado..."}
                                            theme={(theme: any) => ({
                                                ...theme,
                                                borderRadius: 4,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#ebebeb',
                                                    primary: 'black',
                                                },
                                            })}
                                        />
                                    </div>
                                </div>
                                <div id="content2">
                                    <div className="select-box-ativos-select">
                                        <span className="details">Local *</span>
                                        <Select
                                            maxMenuHeight={200}
                                            placeholder={'Selecione...'}
                                            options={optionsDepartmentMovAtivo}
                                            styles={customStyles}
                                            value={optionsDepartmentMovAtivo.filter(function (option: any) {
                                                return option.value === local;
                                            })}
                                            onChange={(options: any) =>
                                                !options ? setLocal("") : setLocal(options.value)
                                            }
                                            isClearable
                                            noOptionsMessage={() => "Não encontrado..."}
                                            theme={(theme: any) => ({
                                                ...theme,
                                                borderRadius: 4,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#ebebeb',
                                                    primary: 'black',
                                                },
                                            })}
                                        />
                                    </div>
                                </div>

                            </div>

                        </>) : null}


                        {entidade === '2' ? (<>

                            <div className="container-cad-prod34">
                                <div id="content1">
                                    <div className="select-box-ativos-select">
                                        <span className="details">Pavimento *</span>
                                        <Select
                                            maxMenuHeight={200}
                                            placeholder={'Selecione...'}
                                            options={optionspavementMovAtivo}
                                            styles={customStyles}
                                            value={optionspavementMovAtivo.filter(function (option: any) {
                                                return option.value === pavementLocal;
                                            })}
                                            onChange={(options: any) =>
                                                !options ? IncluirLocaisLoc("") : IncluirLocaisLoc(options.value)
                                            }
                                            isClearable
                                            noOptionsMessage={() => "Não encontrado..."}
                                            theme={(theme: any) => ({
                                                ...theme,
                                                borderRadius: 4,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#ebebeb',
                                                    primary: 'black',
                                                },
                                            })}
                                        />
                                    </div>
                                </div>
                                <div id="content2">
                                    <div className="select-box-ativos-select">
                                        <span className="details">Local *</span>
                                        <Select
                                            maxMenuHeight={200}
                                            placeholder={'Selecione...'}
                                            options={optionsDepartmentMovAtivo}
                                            styles={customStyles}
                                            value={optionsDepartmentMovAtivo.filter(function (option: any) {
                                                return option.value === local;
                                            })}
                                            onChange={(options: any) =>
                                                !options ? incluirLocAst("") : incluirLocAst(options.value)
                                            }
                                            isClearable
                                            noOptionsMessage={() => "Não encontrado..."}
                                            theme={(theme: any) => ({
                                                ...theme,
                                                borderRadius: 4,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#ebebeb',
                                                    primary: 'black',
                                                },
                                            })}
                                        />
                                    </div>
                                </div>

                                <div id="content3">
                                    <div className="select-box-ativos-select">
                                        <span className="details">Ativo *</span>
                                        <Select
                                            maxMenuHeight={220}
                                            placeholder={'Selecione...'}
                                            options={optionsAtivo}
                                            styles={customStyles}
                                            value={optionsAtivo.filter(function (option: any) {
                                                return option.value === ast_id;
                                            })}
                                            onChange={(options: any) =>
                                                !options ? Getinventoryeditar("") : Getinventoryeditar(options.value)
                                            }
                                            isClearable
                                            noOptionsMessage={() => "Não encontrado..."}
                                            theme={(theme: any) => ({
                                                ...theme,
                                                borderRadius: 4,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#ebebeb',
                                                    primary: 'black',
                                                },
                                            })}
                                        />
                                    </div>
                                </div>

                            </div>


                         </>) : null}






































































                        <div className="container-cad-prod6">


                            <div id="content1">
                                <div className="select-box-ativos-select">
                                    <span className="details">Usuário</span>
                                    <Select
                                        placeholder={'Selecione...'}
                                        options={optionsAgent}
                                        styles={customStyles}
                                        value={optionsAgent.filter(function (option: any) {
                                            return option.value === age_id;
                                        })}
                                        onChange={(options: any) =>
                                            !options ? SelecionarAgent("") : SelecionarAgent(options.value)
                                        }
                                        isClearable
                                        noOptionsMessage={() => "Não encontrado..."}
                                        theme={(theme: any) => ({
                                            ...theme,
                                            borderRadius: 4,
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#ebebeb',
                                                primary: 'black',
                                            },
                                        })}
                                    />
                                </div>
                            </div>
                            <div id="content2">
                                <div className="select-box-ativos-select">
                                    <span className="details">Equipe</span>
                                    <Select
                                        placeholder={'Selecione...'}
                                        options={optionsEquipe}
                                        styles={customStyles}
                                        value={optionsEquipe.filter(function (option: any) {
                                            return option.value === tea_id;
                                        })}
                                        onChange={(options: any) =>
                                            !options ? SelecionarEquipe("") : SelecionarEquipe(options.value)
                                        }
                                        isClearable
                                        noOptionsMessage={() => "Não encontrado..."}
                                        theme={(theme: any) => ({
                                            ...theme,
                                            borderRadius: 4,
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#ebebeb',
                                                primary: 'black',
                                            },
                                        })}
                                    />
                                </div>
                            </div>

                            <div id="content3">
                                <div className="select-box-ativos-select">
                                    <span className="details">Forma</span>
                                    <Select
                                        placeholder={'Selecione...'}
                                        options={optionsForma}
                                        styles={customStyles}
                                        isDisabled={isDisabledForma}
                                        value={optionsForma.filter(function (option: any) {
                                            return option.value === tsf_id;
                                        })}
                                        onChange={(options: any) =>
                                            !options ? setTsf_id("") : setTsf_id(options.value)
                                        }
                                        isClearable
                                        noOptionsMessage={() => "Não encontrado..."}
                                        theme={(theme: any) => ({
                                            ...theme,
                                            borderRadius: 4,
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#ebebeb',
                                                primary: 'black',
                                            },
                                        })}
                                    />
                                </div>
                            </div>
                            <div id="content4">
                                <div className="select-box-ativos-select">
                                    <span className="details">Situação *</span>
                                    <Select
                                        placeholder={'Selecione...'}
                                        options={optionsSituacao}
                                        styles={customStyles}
                                        value={optionsSituacao.filter(function (option: any) {
                                            return option.value === stn_id;
                                        })}
                                        onChange={(options: any) =>
                                            !options ? setStn_id("") : setStn_id(options.value)
                                        }
                                        isClearable
                                        noOptionsMessage={() => "Não encontrado..."}
                                        theme={(theme: any) => ({
                                            ...theme,
                                            borderRadius: 4,
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#ebebeb',
                                                primary: 'black',
                                            },
                                        })}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="container-cad-prod6">
                            <div id="content1">
                                <div className="input-box-ativos">
                                    <span className="details">Prioridade</span>
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        onChange={(e) => setTsk_priority(maskOnlyNumbers(e.target.value))}
                                        value={tsk_priority}
                                    />
                                </div>
                            </div>

                            <div id="content2">
                                <div className="select-box-ativos-select">
                                    <span className="details">Origem *</span>
                                    <Select
                                        placeholder={'Selecione...'}
                                        options={optionsFonte}
                                        styles={customStyles}
                                        value={optionsFonte.filter(function (option: any) {
                                            return option.value === src_id;
                                        })}
                                        onChange={(options: any) =>
                                            !options ? setSrc_id("") : setSrc_id(options.value)
                                        }
                                        isClearable
                                        isDisabled={true}
                                        noOptionsMessage={() => "Não encontrado..."}
                                        theme={(theme: any) => ({
                                            ...theme,
                                            borderRadius: 4,
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#ebebeb',
                                                primary: 'black',
                                            },
                                        })}
                                    />
                                </div>
                            </div>
                            <div id="content3">
                                <div className="input-box-ativos">
                                    <span className="details">Data da inclusão</span>
                                    <input
                                        type="datetime-local"
                                        id="DATA-TIME"
                                        name="DATE-TIME"
                                        disabled
                                        onChange={(e) => setTsk_datetimeinsert(e.target.value)}
                                        value={tsk_datetimeinsert}
                                    />
                                </div>
                            </div>

                            <div id="content4">
                                <div className="input-box-ativos">
                                    <span className="details">Data da Última Atualização</span>
                                    <input
                                        type="datetime-local"
                                        id="DATA-TIME"
                                        name="DATE-TIME"
                                        disabled
                                        onChange={(e) => setTsk_datetimeupdate(e.target.value)}
                                        value={tsk_datetimeupdate}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="container-cad-prod4">
                            <div id="content1">
                                <div className="input-box-ativos">
                                    <span className="details">Imagem 1</span>
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        id="Tsk_image1"
                                        onChange={(e) => setTsk_image1(e.target.value)}
                                        value={tsk_image1}
                                    />
                                </div>
                        </div>
                            <div id="content2">
                                <div className="input-box-ativos">
                                    <span className="details">Imagem 2</span>
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        id="Tsk_image2"
                                        onChange={(e) => setTsk_image2(e.target.value)}
                                        value={tsk_image2}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="container-3-ativo">
                            <div className="textareaBox-ativo">
                                <span className="details">Observação</span>
                                <textarea
                                    onChange={(e) => setTsk_observation(e.target.value)}
                                    value={tsk_observation}
                                />
                            </div>
                        </div>

                        {tipo === '2' || tipo === '4' ? (<>

                            <div className="container-cad-prod2">
                                <div id="content1">
                                    <div className="input-box-ativos">
                                        <span className="details">Responsável</span>
                                        <input
                                            type="text"
                                            autoComplete="off"
                                            onChange={(e) => setTsk_responsible(e.target.value)}
                                            value={tsk_responsible} />

                                    </div>
                                </div>
                                <div id="content2">
                                    <div className="input-box-ativos">
                                        <span className="details">Requisitante</span>
                                        <input
                                            type="text"
                                            autoComplete="off"
                                            onChange={(e) => setTsk_requester(e.target.value)}
                                            value={tsk_requester} />

                                    </div>
                                </div>
                                <div id="content3">
                                    <div className="input-box-ativos">
                                        <span className="details">Telefone</span>
                                        <input
                                            type="text"
                                            autoComplete="off"
                                            onChange={(e) => setTsk_telephone(e.target.value)}
                                            value={tsk_telephone} />
                                    </div>
                                </div>
                            </div>

                            <div className="container-cad-prod2">
                                <div id="content1">
                                    <div className="input-box-ativos">
                                        <span className="details">Descrição da Agenda</span>
                                        <input
                                            type="text"
                                            autoComplete="off"
                                            disabled
                                            onChange={(e) => setTsk_agendadescription(e.target.value)}
                                            value={tsk_agendadescription} />

                                    </div>
                                </div>
                                <div id="content2">
                                    <div className="input-box-ativos">
                                        <span className="details">ID Agenda</span>
                                        <input
                                            type="text"
                                            autoComplete="off"
                                            disabled
                                            onChange={(e) => setAgd_id(e.target.value)}
                                            value={agd_id} />

                                    </div>
                                </div>
                                <div id="content3">
                                    <div className="input-box-ativos">
                                        <span className="details">Callback</span>
                                        <input
                                            type="text"
                                            autoComplete="off"
                                            disabled
                                            onChange={(e) => setTsk_callback(e.target.value)}
                                            value={tsk_callback} />
                                    </div>
                                </div>
                            </div>

                            <div className="container-cad-prod2">
                                <div id="content1">
                                    <div className="input-box-ativos">
                                        <span className="details">Frequência</span>
                                        <input
                                            type="text"
                                            autoComplete="off"
                                            onChange={(e) => setTsk_frequency(e.target.value)}
                                            value={tsk_frequency}
                                            disabled
                                        />

                                    </div>
                                </div>
                                <div id="content2">
                                    <div className="select-box-ativos-select">
                                        <div className='details-flex'>
                                            <span className="details">Tipo Corretiva *</span>
                                            <MdOutlineAddCircle className='details-flex-icon-ativo' onClick={ChamarModalTipoCorretiva} />
                                            <span className="details-esc-ativo" onClick={ChamarModalTipoCorretiva}>Incluir Novo</span>
                                        </div>

                                        <Select
                                            placeholder={'Selecione...'}
                                            options={optionsCorrectivetype}
                                            styles={customStyles}
                                            isDisabled={isDisabledCorretiva}
                                            value={optionsCorrectivetype.filter(function (option: any) {
                                                return option.value === crt_id;
                                            })}
                                            onChange={(options: any) =>
                                                !options ? setCrt_id("") : setCrt_id(options.value)
                                            }
                                            isClearable
                                            noOptionsMessage={() => "Não encontrado..."}
                                            theme={(theme: any) => ({
                                                ...theme,
                                                borderRadius: 4,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#ebebeb',
                                                    primary: 'black',
                                                },
                                            })}
                                        />
                                    </div>
                                </div>
                                <div id="content3">
                                    <div className="select-box-ativos-select">

                                        <div className='details-flex'>
                                            <span className="details">Tipo Preventiva *</span>
                                            <MdOutlineAddCircle className='details-flex-icon-ativo' onClick={ChamarModalTipoPreventiva} />
                                            <span className="details-esc-ativo" onClick={ChamarModalTipoPreventiva}>Incluir Novo</span>
                                        </div>

                                        <Select
                                            placeholder={'Selecione...'}
                                            options={optionsTipoPreventiva}
                                            styles={customStyles}
                                            isDisabled={isDisabledPreventiva}
                                            value={optionsTipoPreventiva.filter(function (option: any) {
                                                return option.value === pvt_id;
                                            })}
                                            onChange={(options: any) =>
                                                !options ? setPvt_id("") : setPvt_id(options.value)
                                            }
                                            isClearable
                                            noOptionsMessage={() => "Não encontrado..."}
                                            theme={(theme: any) => ({
                                                ...theme,
                                                borderRadius: 4,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#ebebeb',
                                                    primary: 'black',
                                                },
                                            })}
                                        />
                                    </div>
                                </div>
                            </div>
                        </>) : null}

                        {tipo === '1' ? (<>
                            <div className="container-cad-prod17">
                                <div id="content1">
                                    <div className="input-box-ativos">
                                        <span className="details">ID Inventário</span>
                                        <input
                                            type="text"
                                            autoComplete="off"
                                            onChange={(e) => setIvb_id(e.target.value)}
                                            value={ivb_id}
                                            disabled
                                        />

                                    </div>
                                </div>

                            </div>

                        </>) : null}


                        <div className="container-3-ativo">
                            <div className="textareaBox-ativo">
                                <span className="details">Instrução Para o Técnico</span>
                                <textarea
                                    onChange={(e) => setTsk_technicalinstruction(e.target.value)}
                                    value={tsk_technicalinstruction}
                                />
                            </div>
                        </div>


                        {tipo === '3' ? (<>

                            <h1 className="spa-aten">
                                <b> Atenção:</b> Selecione o destino da movimentação.
                            </h1>


                            <div className="container-cad-prod4">
                                <div id="content1">
                                    <div className="select-box-ativos-select">
                                        <span className="details">Contrato *</span>
                                        <Select
                                            maxMenuHeight={200}
                                            placeholder={'Selecione...'}
                                            options={optionContract}
                                            styles={customStyles}
                                            value={optionContract.filter(function (option: any) {
                                                return option.value === contractDest;
                                            })}
                                            onChange={(options: any) =>
                                                !options ? IncluirContratoDest("") : IncluirContratoDest(options.value)
                                            }
                                            isClearable
                                            noOptionsMessage={() => "Não encontrado..."}
                                            theme={(theme: any) => ({
                                                ...theme,
                                                borderRadius: 4,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#ebebeb',
                                                    primary: 'black',
                                                },
                                            })}
                                        />
                                    </div>
                                </div>
                                <div id="content2">
                                    <div className="select-box-ativos-select">
                                        <span className="details">Unidade *</span>
                                        <Select
                                            maxMenuHeight={200}
                                            placeholder={'Selecione...'}
                                            options={optionUnityDest}
                                            styles={customStyles}
                                            value={optionUnityDest.filter(function (option: any) {
                                                return option.value === unityDest;
                                            })}
                                            onChange={(options: any) =>
                                                !options ? IncluirUnityMOV("") : IncluirUnityMOV(options.value)
                                            }
                                            isClearable

                                            noOptionsMessage={() => "Não encontrado..."}
                                            theme={(theme: any) => ({
                                                ...theme,
                                                borderRadius: 4,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#ebebeb',
                                                    primary: 'black',
                                                },
                                            })}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="container-cad-prod19">
                                <div id="content1">
                                    <div className="select-box-ativos-select">
                                        <span className="details">Pavimento *</span>
                                        <Select
                                            maxMenuHeight={200}
                                            placeholder={'Selecione...'}
                                            options={optionspavementMov}
                                            styles={customStyles}
                                            value={optionspavementMov.filter(function (option: any) {
                                                return option.value === pavementMov;
                                            })}
                                            onChange={(options: any) =>
                                                !options ? IncluirLocaisMov("") : IncluirLocaisMov(options.value)
                                            }
                                            isClearable
                                            noOptionsMessage={() => "Não encontrado..."}
                                            theme={(theme: any) => ({
                                                ...theme,
                                                borderRadius: 4,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#ebebeb',
                                                    primary: 'black',
                                                },
                                            })}
                                        />
                                    </div>
                                </div>
                                <div id="content2">
                                    <div className="select-box-ativos-select">
                                        <span className="details">Local *</span>
                                        <Select
                                            maxMenuHeight={200}
                                            placeholder={'Selecione...'}
                                            options={optionsDepartmentMov}
                                            styles={customStyles}
                                            value={optionsDepartmentMov.filter(function (option: any) {
                                                return option.value === departmentMov;
                                            })}
                                            onChange={(options: any) =>
                                                !options ? setDepartmentMov("") : setDepartmentMov(options.value)
                                            }
                                            isClearable
                                            noOptionsMessage={() => "Não encontrado..."}
                                            theme={(theme: any) => ({
                                                ...theme,
                                                borderRadius: 4,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#ebebeb',
                                                    primary: 'black',
                                                },
                                            })}
                                        />
                                    </div>
                                </div>
                                <div id="content3">
                                    <div className="input-box-ativos">
                                        <span className="details">Nome Usuário</span>
                                        <input
                                            id="user"
                                            onChange={(e) => setUser(e.target.value)}
                                            value={user}
                                        />
                                    </div>
                                </div>
                            </div>

                            <h1 className="spa-aten">
                                <b> Atenção:</b> Informações complementares do ativo.
                            </h1>

                            <div className="container-cad-prod6">
                                <div id="content1">
                                    <div className="input-box-ativos">
                                        <span className="details">Identificador Alternativo *</span>
                                        <input
                                            type="text"
                                            autoComplete="off"
                                            onChange={(e) => setAlternativeIdentifier(e.target.value)}
                                            value={alternativeIdentifier}
                                        />
                                    </div>
                                </div>

                                <div id="content2">
                                    <div className="input-box-ativos">
                                        <span className="details">Número de Série</span>
                                        <input
                                            type="text"
                                            autoComplete="off"
                                            onChange={(e) => setSerialNumber(e.target.value)}
                                            value={serialNumber}
                                        />
                                    </div>
                                </div>
                                <div id="content3">
                                    <div className="input-box-ativos">
                                        <span className="details">Tombamento</span>
                                        <input
                                            type="text"
                                            autoComplete="off"
                                            onChange={(e) => setTombamento(e.target.value)}
                                            value={tombamento}
                                        />
                                    </div>
                                </div>

                                <div id="content4">
                                    <div className="input-box-ativos">
                                        <span className="details">MAC</span>
                                        <input
                                            type="text"
                                            autoComplete="off"
                                            onChange={(e) => setMac(e.target.value)}
                                            value={mac}
                                        />
                                    </div>
                                </div>
                            </div>

                        </>) : null}


                        <div className="title-Page">
                            <div className="title-text">
                                <div className="size-x circle">2</div>
                                <h2>Defina quando esta tarefa deve ser executada</h2>
                            </div>
                            <hr />
                        </div>

                        <div className="container-cad-prod18">
                            <div id="content1">
                                <div className="input-box-ativos">
                                    <span className="details">Data/Hora Inicial *</span>
                                    <input
                                        type="datetime-local"
                                        id="DATA-TIME"
                                        name="DATE-TIME"
                                        onChange={(e) => setTsk_scheduleinitialdatehour(e.target.value)}
                                        value={tsk_scheduleinitialdatehour}
                                    />
                                </div>
                            </div>

                            <div id="content2">
                                <div className="input-box-ativos">
                                    <span className="details">Data/Hora Final</span>
                                    <input
                                        type="datetime-local"
                                        id="DATA-TIME"
                                        name="DATE-TIME"
                                        onChange={(e) => validarDataFim(e.target.value)}
                                        value={tsk_schedulefinaldatehour}
                                    />
                                </div>
                            </div>

                        </div>

                        <div className="Bt-button-cad">
                            <button type="submit" onClick={SalvarTask}>
                                <i className="fa fa-check-circle icon-ok-btn"></i> Salvar
                            </button>
                            <button type="submit" onClick={finalizarModal1}>
                                <i className="fa fa-times icon-cancelar-btn"></i> Cancelar
                            </button>
                        </div>

                    </div>
                </main>
            </div>

            <ModalIncluirNovo isOpen={openModalIncluirNovo} setModalOpen={() => setOpenModalIncluirNovo(!openModalIncluirNovo)}>
                <>
                    <div className='title-modal-incluir'>
                        <h1 className="modal-IncluirNovo-title">{qualTitulo}</h1>
                        <div onClick={fecharOpenModalIncluirNovo}>
                            <button className="fa fa-times icone-modal-IncluirNovo" data-dismiss="modal" aria-label="Close"></button>
                        </div>
                    </div>

                    <div className='scrol-modal'>
                        {qualGrid}
                    </div>
                </>
            </ModalIncluirNovo>

            <ModalPrincipal isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)}>
                <div className='container-modal-alert'>
                    {qualAlert}
                </div>
            </ModalPrincipal>

        </>
    );

}

export default TarefasAdd;