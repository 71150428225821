import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

interface Permission {
  age_id: number;
  age_name: string;
  acp_description: string;
  feature_name: string;
  prm_create: number;
  prm_view: number;
  prm_edit: number;
  prm_delete: number;
}

interface ProtectedRoutesProps {
  roleRequired?: string;
}

function useAuth() {
  const _user = secureLocalStorage.getItem('t'); // Recupera o usuário armazenado
  const permissionsData = secureLocalStorage.getItem("pms");

  // Verifica se permissionsData é uma string antes de parsear
  const permissions: Permission[] = typeof permissionsData === 'string' 
    ? JSON.parse(permissionsData) 
    : [];

  return {
    auth: Boolean(_user), // Verifica se o usuário existe
    permissions: permissions || [], // Define permissões como um array vazio se não houver
  };
}

function ProtectedRoutes(props: ProtectedRoutesProps) {
  const { auth, permissions } = useAuth();

  if (props.roleRequired) {
    const requiredFeatures = props.roleRequired.split(","); // Divide as permissões requeridas

    const hasPermission = requiredFeatures.some((feature: string) =>
      permissions.some(permission => permission.feature_name === feature && permission.prm_view)
    );

    if (auth) {
      return hasPermission ? (
        <Outlet />
      ) : (
        <Navigate to="/denied" />
      );
    } else {
      return <Navigate to="/login" />;
    }
  } else {
    return auth ? <Outlet /> : <Navigate to="/login" />;
  }
}

export default ProtectedRoutes;
