
import React, { useEffect, useState } from 'react'
import Nav from "../../components/Nav";
import Header from "../../components/Header";
import { AxiosResponse } from "axios";
import GridRelatorios from '../../components/GridRelatorios'
import RelatorioAtivosCadastrados from '../../components/GeradorPdf/RelatorioAtivosCadastrados'
import RelatorioAtivosEstoque from '../../components/GeradorPdf/RelatorioAtivosEstoque'
import RelatorioAtivosEmUso from '../../components/GeradorPdf/RelatorioAtivosEmUso'
import RelatorioAtivosForaUso from '../../components/GeradorPdf/RelatorioAtivosForaUso'
import secureLocalStorage from "react-secure-storage";
import ModalPrincipal from '../../components/ModalAlert/modal'
import { useNavigate } from 'react-router';
import api from "../../Services/api";
import Loading from '../../components/Loading';

function Relatorios(props:any) {

    const navigate = useNavigate();
    const [exibirGrid, setExibirGrid] = useState(<></>);
    const [optionsAtivosCategoria, setOptionsAtivosCategoria] = useState([])
    const [valortotalativos, setValorTotalativos] = useState('');
    const [openModal, setOpenModal] = useState(false)
    const [qualAlert, setQualAlert] = useState(<></>)

    useEffect(() => {

      
		setQualAlert(<div className='div-alert'><Loading /></div>)
		setOpenModal(true)

    validatToken();


  }, []);


  async function validatToken() {

		let ret: AxiosResponse;
		let valor: any;

		const _user = secureLocalStorage.getItem('t')

		api.defaults.headers.common['Authorization'] = _user as ''

		try {

			ret = await api.get("/status/validar")

			if (ret.data.status === 200) {


				secureLocalStorage.setItem('t', ret.data.data);
				secureLocalStorage.setItem('acp', ret.data.decodedToken.acp_integrationid);
				secureLocalStorage.setItem('n', ret.data.decodedToken.name);
				secureLocalStorage.setItem('iag', ret.data.decodedToken.id);
				secureLocalStorage.setItem('cy', ret.data.decodedToken.company);

        setExibirGrid(<GridRelatorios
          th1='ID'
          th2='Descrição'
          endPoint='report/listarrelatorio'
          consult='N'
          col1='0'
          col2='1'
          ChamarModalCad={ChamarRelatorio}
        />)

				setOpenModal(false)

			}

		} catch (e: any) {

			if (e.response.data.status === 403) {

				setQualAlert(<div className='div-alert'>
					<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
					<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
					<div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
					<div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
				</div>)


			}
		}

	}

  function fecharModal() {

    secureLocalStorage.removeItem('t')
    secureLocalStorage.removeItem('acp')
    secureLocalStorage.removeItem('n')
    secureLocalStorage.removeItem('iag')
    navigate('/login');
    setOpenModal(false)

}


  function ChamarRelatorio(data:any){

    if(data.myr_id === 1){
      RelatorioAtivosCadastrados()
    }else if (data.myr_id === 2){
      RelatorioAtivosEmUso()
    }else if (data.myr_id === 3){
      RelatorioAtivosEstoque()
    }else if (data.myr_id === 4){
      RelatorioAtivosForaUso()
    }
 
  }
  
    return (
        <>
            <Nav />
            <div className="main-content">
                <Header />
                <main>
                    <div className='containerPrincipal'>
                        <div className='div-titulo-pages'>
                            <span className='fa-solid fa-file-contract' />
                            
                            <h1 className='div-titulo-pages-text'>Relatórios</h1>
                           
                        </div>                     
                          {exibirGrid}
                        </div>
                </main>
            </div>

            
         <ModalPrincipal isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)}>
                <div className='container-modal-alert'>
                    {qualAlert}
                </div>
            </ModalPrincipal>

        </>
    );

}

export default Relatorios;