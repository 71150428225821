import React, { useState, useEffect } from 'react';
import './index.css'

const Accordion = (props: any) => {

  const [expandedCategory, setExpandedCategory] = useState(null);

  const [constante, setConstante] = useState('');

  const handleCategoryClick = (category: any) => {
    setExpandedCategory(expandedCategory === category ? null : category);
  };

  function pegarValor(item: any) {
    props.pegarValorFormulaValor(item)
  }

  function pegarConst(){

    if(constante !== ''){
      props.pegarValorFormulaValor({
        "id": constante,
        "label": constante,
        "tipo": "constante"
    })
    }
  
  }

  useEffect(() => {



}, []);

  return (
    <div className="container">


{props.etapasvalidationformulas === undefined ? (
        <>
          {Object.keys(props.data).map((category) => (
            <div key={category} className="accordion">
              <button
                onClick={() => handleCategoryClick(category)}
                className="accordion-header"
              >
                {category}
                <span className="icon">
                  {expandedCategory === category ? '-' : '+'}
                </span>
              </button>
              {expandedCategory === category && (
                <div className="accordion-content">
                  {props.data[category].map((item: any) => (
                    <div key={item.id} className="item-accordion" onClick={() => pegarValor(item)}>
                      {item.label}
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </>
      ) : null}

      {Number(props.etapasvalidationformulas) === 0 ? (
        <>
          {Object.keys(props.data).map((category) => (
            <div key={category} className="accordion">
              <button
                onClick={() => handleCategoryClick(category)}
                className="accordion-header"
              >
                {category}
                <span className="icon">
                  {expandedCategory === category ? '-' : '+'}
                </span>
              </button>
              {expandedCategory === category && (
                <div className="accordion-content">
                  {props.data[category].map((item: any) => (
                    <div key={item.id} className="item-accordion" onClick={() => pegarValor(item)}>
                      {item.label}
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </>
      ) : null}

      {Number(props.etapasvalidationformulas) === 1 ? (
        <>
          {Object.keys(props.operadorComparacao).map((category) => (
            <div key={category} className="accordion">
              <button
                onClick={() => handleCategoryClick(category)}
                className="accordion-header"
              >
                {category}
                <span className="icon">
                  {expandedCategory === category ? '-' : '+'}
                </span>
              </button>
              {expandedCategory === category && (
                <div className="accordion-content">
                  {props.operadorComparacao[category].map((item: any) => (
                    <div key={item.id} className="item-accordion" onClick={() => pegarValor(item)}>
                      {item.label}
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </>
      ) : null}

      {Number(props.etapasvalidationformulas) === 2 ? (
        <>
          <div className='etapasvalidationformulas2'>
            <h1>Constante: </h1>
            <div className="input-box-ativos">
              <input
                type="text"
                onChange={(e) => setConstante(e.target.value)}
                value={constante}
                autoComplete="off"
              />
            </div>
            <div className='add-etapasvalidationformulas2' onClick={pegarConst}>
              <h1 className='lab-etapasvalidationformulas2'>Adicionar</h1>
              </div>
          </div>
        </>
      ) : null}

      {Number(props.etapasvalidationformulas) === 3 ? (
        <>
          {Object.keys(props.operador).map((category) => (
            <div key={category} className="accordion">
              <button
                onClick={() => handleCategoryClick(category)}
                className="accordion-header"
              >
                {category}
                <span className="icon">
                  {expandedCategory === category ? '-' : '+'}
                </span>
              </button>
              {expandedCategory === category && (
                <div className="accordion-content">
                  {props.operador[category].map((item: any) => (
                    <div key={item.id} className="item-accordion" onClick={() => pegarValor(item)}>
                      {item.label}
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </>
      ) : null}


    </div>
  );
};

export default Accordion;