
import React, { useState, useEffect } from 'react'
import Nav from "../../components/Nav";
import Header from "../../components/Header";
import './index.css'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { AxiosResponse } from "axios";
import api from '../../Services/api'
import Loading from '../../components/Loading/index'
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router'
import Select from 'react-select'
import ModalIncluirNovo from '../../components/ModalIncluirNovo/modal'
import ModalAlert from '../../components/ModalAlert/modal'
import secureLocalStorage from "react-secure-storage";
import moment from 'moment';
import GridLocais from '../../components/GridLocais'
import PersonalizadoData from "../../components/PersonalizadoData";

function InventarioVisualizacao() {

  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false)
  const [qualAlert, setQualAlert] = useState(<></>)
  const [tabIndex, setTabIndex] = useState(0);

  const [optionsAgent, setOptionsAgent] = useState([])
  const [optionsEquipe, setOptionsEquipe] = useState([])
  const [optionsForma, setOptionsForma] = useState([])
  const [optionsSituacao, setOptionsSituacao] = useState([])
  const [optionsTasktype, setOptionsTasktype] = useState([])
  const [optionsTipoPreventiva, setOptionsTipoPreventiva] = useState([])
  const [optionsFrequency, setOptionsFrequency] = useState([])
  const [dadosLocais, setDadosLocais] = useState([]);
  const [dadosLocal, setDadosLocal] = useState([]);
  const [dadosPlanejador, setDadosPlanejador] = useState([]);

  const [ativo, setAtivo] = useState(true);
  const [recriar, SetRecriar] = useState(false);
  const [desativar, SetDesativar] = useState(false);
  const [descricao, setDescricao] = useState('');

  const [pessoa, SetPessoa] = useState('');
  const [equipe, SetEquipe] = useState('');
  const [forma, SetForma] = useState('');
  const [tipoTarefa, SetTipoTarefa] = useState('');
  const [subtipo, SetSubtipo] = useState('');
  const [situacao, SetSituacao] = useState('');
  const [frequencia, SetFrequencia] = useState('');
  const [freq_dias, SetFreq_dias] = useState('');
  const [pular_dom, SetPular_dom] = useState(false)
  const [pular_sab, SetPular_sab] = useState(false)
  const [dataIncial, SetDataIncial] = useState('');
  const [datafim, SetDatafim] = useState('');
  const [horaIncial, SetHoraIncial] = useState('');
  const [horafim, SetHorafim] = useState('');
  const [d1, SetD1] = useState(false);
  const [d2, SetD2] = useState(false);
  const [d3, SetD3] = useState(false);
  const [d4, SetD4] = useState(false);
  const [d5, SetD5] = useState(false);
  const [d6, SetD6] = useState(false);
  const [d7, SetD7] = useState(false);
  const [proximaExec, setProximaExec] = useState('');
  const [dateproximaExec, setDateProximaExec] = useState(new Date());
  const [msgDia, SetMsgDia] = useState('');
  const [notificacao, SetNotificacao] = useState(false);
  const [tts, SetTts] = useState(false);
  const [sms, SetSms] = useState(false);
  const [email, SetEMail] = useState(false);
  const [observacao, SetObservacao] = useState(''); //ATENÇÃO FALTA INCLUIR
  const [mail, setMail] = useState('')
  const [frequencia10, setFrequencia10] = useState('');
  const [dadosPersonalizado2, setDadosPersonalizado2] = useState([]);
  const [antecipar, SetAntecipar] = useState('');
  const [date, setDate] = useState('');
  const [proximaExecFunc, setProximaExecFunc] = useState('');
  const [entidade, setEntidade] = useState('');
  const [endPoint, setEndPoint] = useState('');

  const [isDisabledForma, setIsDisabledForma] = useState(true)


  const [contract, setContract] = useState('');
  const [unity, setUnity] = useState('');
  
  const [pavement, setPavement] = useState('');
  const [ast_id, setAst_id] = useState('');
  const [loc_id, setLoc_id] = useState('');

  const [optionsDepartment, setOptionsDepartment] = useState([])
  const [optionspavement, setOptionspavement] = useState([])
  const [optionContract, setOptionContract] = useState([])
  const [optionUnity, setOptionUnity] = useState([])
  const [optionAtivo, setOptionAtivo] = useState([])

  useEffect(() => {

    chamarPeg()

  }, []);

  function chamarPeg() {
    setQualAlert(<div className='div-alert'>
      <span className="far fa-question-circle ControleIconAlertquestion"></span>
      <div className='div-info-alert-atencao'>
        <span className="info-alert-atencao">ATENÇÃO</span></div>
      <div className='div-info-alert'>
        <span className="info-alert">
          Para qual tipo você deseja criar a manutenção preventiva?</span></div>
      <div className='btn-flex'>
        <div className='btn-alert-f' onClick={chamarLocal}>
          <span className="btn-alert-lab-flex">LOCAL</span>
        </div>

        <div className='btn-alert-f' onClick={chamarAtivo}>
          <span className="btn-alert-lab-flex">ATIVO</span>
        </div>
      </div>
    </div>)

    setOpenModal(true)
  }

  function chamarAtivo() {
    GetContrato()
    listarAtivosEmUso()
    setEntidade('2')
    setEndPoint('assetplanner/listar')
    validatToken(2);
    setOpenModal(false)
  }

  function chamarLocal() {
    GetLocal()
    GetContrato()
    setEntidade('1')
    setEndPoint('assetplanner/listarloc')
    validatToken(1);
    setOpenModal(false)
  }


  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      background: '#fff',
      minHeight: '30px',
      height: '30px',
      borderColor: state.isFocused ? 'grey' : '#92989e',

    }),


    valueContainer: (provided: any, state: any) => ({
      ...provided,
      height: '30px',
      padding: '0 6px'
    }),

    input: (provided: any, state: any) => ({
      ...provided,
      margin: '0px',
      fontSize: 14,

    }),
    indicatorsContainer: (provided: any, state: any) => ({
      ...provided,
      height: '30px',
    }),
  };

  async function validatToken(idtipo: any) {

    let ret: AxiosResponse;
    let valor: any;

    const _user = secureLocalStorage.getItem('t')

    api.defaults.headers.common['Authorization'] = _user as ''

    try {

      ret = await api.get("/status/validar")

      if (ret.data.status === 200) {

        secureLocalStorage.setItem('t', ret.data.data);
        secureLocalStorage.setItem('acp', ret.data.decodedToken.acp_integrationid);
        secureLocalStorage.setItem('n', ret.data.decodedToken.name);
        secureLocalStorage.setItem('iag', ret.data.decodedToken.id);
        secureLocalStorage.setItem('cy', ret.data.decodedToken.company);


        GetPlanejador(idtipo)



        if (ret.data.decodedToken.acp_integrationid === 'DESENVOLVEDOR' || ret.data.decodedToken.acp_integrationid === 'ADMINISTRADOR') {



        } else if (ret.data.decodedToken.acp_integrationid === 'CONSULTOR') {


        }


      }

    } catch (e: any) {

      if (e.response.data.status === 403) {

        setQualAlert(<div className='div-alert'>
          <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
          <div className='btn-alert' onClick={fecharModalToken}><span className="btn-alert-lab">OK</span></div>
        </div>)


      }
    }

  }

  async function GetPlanejador(idtipo: any) {
    let ret: AxiosResponse;
    let valor: any;
    try {
      ret = await api.get("planner/listar")
      if (ret.data.status === 200) {
        valor = ret.data.data;


        await GetAgent();
        await GetForma();
        await GetEquipe();
        await GetSituacao();
        await GetTasktype();
        await GetPreventiva(idtipo);
        await GetFrequencia();

        setOpenModal(false)

        setDadosPlanejador(valor)

      } else {

        await GetAgent();
        await GetForma();
        await GetEquipe();
        await GetSituacao();
        await GetTasktype();
        await GetPreventiva(idtipo);
        await GetFrequencia();

        setOpenModal(false)


      }
    } catch (e) {

      setOpenModal(false)
      setDadosPlanejador([])
    }
  }

  function ValitarActive() {
    let e = document.getElementById("checkbox-active") as HTMLInputElement;

    if (e.checked) {

      setAtivo(true)
    } else {

      setAtivo(false)
    }
  }

  function ValitarDesable() {
    let e = document.getElementById("checkbox-desativar") as HTMLInputElement;

    if (e.checked) {

      SetDesativar(true)
    } else {

      SetDesativar(false)
    }
  }

  function ValitarRecreate() {
    let e = document.getElementById("checkbox-recreate") as HTMLInputElement;

    if (e.checked) {
      SetRecriar(true)
    } else {

      SetRecriar(false)
    }
  }

  function fecharModalToken() {
    secureLocalStorage.removeItem('t')
    secureLocalStorage.removeItem('acp')
    secureLocalStorage.removeItem('n')
    secureLocalStorage.removeItem('iag')
    navigate('/login');
    setOpenModal(false)
  }

  function PessoaAjuste(pessoa: any) {

    if (pessoa === '') {
      SetPessoa('')
      SetEquipe('')
      SetForma('')
      setIsDisabledForma(true)
    } else {
      SetPessoa(pessoa)
      SetEquipe('')
      SetForma('')
      setIsDisabledForma(true)
    }

  }

  async function GetAgent() {
    let ret: AxiosResponse;
    let valor: any;
    try {
      ret = await api.get("user/listar")
      if (ret.data.status === 200) {
        valor = ret.data.data;
        const arr = [] as any;
        let result = valor;
        result.map((user: any) => {
          return arr.push({ value: String(user.age_id), label: user.age_name });
        });

        setOptionsAgent(arr)

      }
    } catch (e) {
      setOptionsAgent([])
    }
  }

  function EquipeAjuste(equipe: any) {
    if (equipe === '') {
      SetEquipe('')
      SetPessoa('')
      SetForma("")
      setIsDisabledForma(true)
    } else {
      SetEquipe(equipe)
      SetPessoa('')
      setIsDisabledForma(false)
    }
  }

  function FormaAjuste(forma: any) {

    if (forma === 'Selecione...') {
      SetForma('')
      SetPessoa('')
    } else {
      SetForma(forma)
      SetPessoa('')
    }
  }

  async function GetEquipe() {
    let ret: AxiosResponse;
    let valor: any;
    try {
      ret = await api.get("team/listar")
      if (ret.data.status === 200) {
        valor = ret.data.data;
        const arr = [] as any;
        let result = valor;
        result.map((user: any) => {
          return arr.push({ value: String(user.tae_id), label: user.tae_description });
        });

        setOptionsEquipe(arr)

      }
    } catch (e) {
      setOptionsEquipe([])
    }
  }

  async function GetForma() {
    let ret: AxiosResponse;
    let valor: any;
    try {
      ret = await api.get("taskform/listar")
      if (ret.data.status === 200) {
        valor = ret.data.data;
        const arr = [] as any;
        let result = valor;
        result.map((user: any) => {
          return arr.push({ value: String(user.tsf_id), label: user.tsf_description });
        });

        setOptionsForma(arr)

      }
    } catch (e) {
      setOptionsForma([])
    }
  }

  async function GetSituacao() {
    let ret: AxiosResponse;
    let valor: any;
    try {
      ret = await api.get("tasksituation/listar")
      if (ret.data.status === 200) {
        valor = ret.data.data;
        const arr = [] as any;
        let result = valor;

        const situacao = valor.filter(
          (e: any) => {
            return (e.stn_id !== 60 && e.stn_id !== 50 && e.stn_id !== 70 && e.stn_id !== 80)
          }
        );

        situacao.map((user: any) => {
          return arr.push({ value: String(user.stn_id), label: user.stn_description });
        });



        setOptionsSituacao(arr)

      }
    } catch (e) {
      setOptionsSituacao([])
    }
  }

  async function GetTasktype() {
    let ret: AxiosResponse;
    let valor: any;
    try {
      ret = await api.get("tasktype/listar")
      if (ret.data.status === 200) {
        valor = ret.data.data;
        const arr = [] as any;

        const tasktype = valor.filter(
          (e: any) => {
            return (e.tty_id !== 1 && e.tty_id !== 2 && e.tty_id !== 3)
          }
        );

        tasktype.map((user: any) => {
          return arr.push({ value: String(user.tty_id), label: user.tty_description });
        });

        setOptionsTasktype(arr)

      }
    } catch (e) {
      setOptionsTasktype([])
    }
  }

  async function GetPreventiva(idtipo: any) {
    let ret: AxiosResponse;
    let valor: any;
    try {
      ret = await api.get("preventivetype/listarpreventivetypeentity", {
        params: {
          id: idtipo
        }
      })
      if (ret.data.status === 200) {
        valor = ret.data.data;
        const arr = [] as any;


        valor.map((user: any) => {
          return arr.push({ value: String(user.pvt_id), label: user.pvt_description });
        });

        setOptionsTipoPreventiva(arr)

      }
    } catch (e) {
      setOptionsTipoPreventiva([])
    }
  }

  async function GetFrequencia() {
    let ret: AxiosResponse;
    let valor: any;
    try {
      ret = await api.get("frequency/listar")
      if (ret.data.status === 200) {
        valor = ret.data.data;
        const arr = [] as any;
        let result = valor;
        result.map((user: any) => {
          return arr.push({ value: String(user.fqc_id), label: user.fqc_description });
        });

        setOptionsFrequency(arr)

      }
    } catch (e) {
      setOptionsFrequency([])
    }
  }

  const [daysMonthsYears, setDaysMonthsYears] = useState({
    days: 0,
    months: 0,
    years: 0,
  });

  function showDate(dateString: any, daysMonthsYears: any) {
    const dateToShow = new Date(dateString);

    let timeI = document.getElementById("TIMEINI") as HTMLInputElement;

    if (!dateString || !dateToShow.getTime()) return '';

    dateToShow.setUTCHours(3);

    const { days, months, years } = daysMonthsYears;

    if (days) dateToShow.setDate(dateToShow.getDate() + Number(days));
    if (months) dateToShow.setMonth(dateToShow.getMonth() + Number(months));
    if (years) dateToShow.setFullYear(dateToShow.getFullYear() + Number(years));
    return setProximaExec(dateToShow.toLocaleDateString() + ' ' + timeI.value), setDateProximaExec(dateToShow)

  }

  function Frequencia() {
    let datain = document.getElementById("DATAINI") as HTMLInputElement;
    setDate(datain.value);

    let freq = document.getElementById("FREQUEC") as HTMLInputElement;

    let PROXE = document.getElementById("PROX") as HTMLInputElement;
    let dataI = document.getElementById("DATAINI") as HTMLInputElement;
    let dataF = document.getElementById("DATAFIM") as HTMLInputElement;
    let timeI = document.getElementById("TIMEINI") as HTMLInputElement;
    let timeF = document.getElementById("TIMEFIM") as HTMLInputElement;

    let checkd1 = document.getElementById("checkbox-D1") as HTMLInputElement;
    let checkd2 = document.getElementById("checkbox-D2") as HTMLInputElement;
    let checkd3 = document.getElementById("checkbox-D3") as HTMLInputElement;
    let checkd4 = document.getElementById("checkbox-D4") as HTMLInputElement;
    let checkd5 = document.getElementById("checkbox-D5") as HTMLInputElement;
    let checkd6 = document.getElementById("checkbox-D6") as HTMLInputElement;
    let checkd7 = document.getElementById("checkbox-D7") as HTMLInputElement;
    let checkboxpularsab = document.getElementById("checkbox-pular-sab") as HTMLInputElement;
    let checkboxpulardom = document.getElementById("checkbox-pular-dom") as HTMLInputElement;

    let vfreqdias = document.getElementById("validar-freqdias") as HTMLInputElement;
    SetDataIncial(dataI.value)
    SetHoraIncial(timeI.value)
    SetHorafim(timeF.value)
    //SetFrequencia(frequencia)
    setProximaExecFunc('OK')


    if (frequencia === '1') {
      setDadosPersonalizado2([])
      checkd1.disabled = true;
      checkd2.disabled = true;
      checkd3.disabled = true;
      checkd4.disabled = true;
      checkd5.disabled = true;
      checkd6.disabled = true;
      checkd7.disabled = true;
      SetD1(false)
      SetD2(false)
      SetD3(false)
      SetD4(false)
      SetD5(false)
      SetD6(false)
      SetD7(false)
      checkboxpularsab.disabled = false;
      checkboxpulardom.disabled = false;
      showDate(datain.value, { ...daysMonthsYears, days: Number(1), months: Number(0), years: Number(0) });

      SetFreq_dias("")
      setFrequencia10('N')
    } else if (frequencia === '2') {
      setDadosPersonalizado2([])
      SetPular_sab(false)
      SetPular_dom(false)
      setFrequencia10('N')
      checkd1.disabled = false;
      checkd2.disabled = false;
      checkd3.disabled = false;
      checkd4.disabled = false;
      checkd5.disabled = false;
      checkd6.disabled = false;
      checkd7.disabled = false;
      checkboxpularsab.disabled = true;
      checkboxpulardom.disabled = true;
      SetFreq_dias("")


      if (checkd1.checked) {
        SetD1(true)
      } else {

        SetD1(false)
      }
      if (checkd2.checked) {
        SetD2(true)
      } else {
        SetD2(false)
      }
      if (checkd3.checked) {
        SetD3(true)
      } else {
        SetD3(false)
      }
      if (checkd4.checked) {
        SetD4(true)
      } else {
        SetD4(false)
      }
      if (checkd5.checked) {
        SetD5(true)
      } else {
        SetD5(false)
      }
      if (checkd6.checked) {
        SetD6(true)
      } else {
        SetD6(false)
      }
      if (checkd7.checked) {
        SetD7(true)
      } else {
        SetD7(false)
      }

      const dt = new Date(dataI.value);


      const atual = dt.getDay();

      var newArray = [checkd2.checked, checkd3.checked, checkd4.checked, checkd5.checked, checkd6.checked, checkd7.checked, checkd1.checked];


      // 2
      var newArrayPt1 = newArray.slice(atual + 1)
      var newArrayPt2 = newArray.slice(0, atual + 1)



      var pos = newArrayPt1.indexOf(true) + (atual + 1);

      if (newArrayPt1.indexOf(true) === -1) {

        pos = newArrayPt2.indexOf(true);
      }

      const prox = pos;

      if (pos === -1) {
        showDate(datain.value, { ...daysMonthsYears, days: Number(0), months: Number(0), years: Number(0) });
      } else {

        if (prox <= atual) {

          showDate(dataI.value, { ...daysMonthsYears, days: Number((7 - atual) + prox), months: Number(0), years: Number(0) });
        } else {

          showDate(dataI.value, { ...daysMonthsYears, days: Number((prox - atual)), months: Number(0), years: Number(0) });
        }

      }

    } else if (frequencia === '3') {
      setDadosPersonalizado2([])
      checkboxpularsab.disabled = false;
      checkboxpulardom.disabled = false;
      setFrequencia10('N')
      checkd1.disabled = true;
      checkd2.disabled = true;
      checkd3.disabled = true;
      checkd4.disabled = true;
      checkd5.disabled = true;
      checkd6.disabled = true;
      checkd7.disabled = true;
      SetD1(false)
      SetD2(false)
      SetD3(false)
      SetD4(false)
      SetD5(false)
      SetD6(false)
      SetD7(false)
      showDate(datain.value, { ...daysMonthsYears, days: Number(15), months: Number(0), years: Number(0) });

      SetFreq_dias("")
    } else if (frequencia === '4') {
      setDadosPersonalizado2([])
      checkboxpularsab.disabled = false;
      checkboxpulardom.disabled = false;
      setFrequencia10('N')
      checkd1.disabled = true;
      checkd2.disabled = true;
      checkd3.disabled = true;
      checkd4.disabled = true;
      checkd5.disabled = true;
      checkd6.disabled = true;
      checkd7.disabled = true;
      SetD1(false)
      SetD2(false)
      SetD3(false)
      SetD4(false)
      SetD5(false)
      SetD6(false)
      SetD7(false)

      SetFreq_dias("")
      showDate(datain.value, { ...daysMonthsYears, days: Number(0), months: Number(1), years: Number(0) });
    } else if (frequencia === '5') {
      setDadosPersonalizado2([])
      checkboxpularsab.disabled = false;
      checkboxpulardom.disabled = false;
      setFrequencia10('N')
      checkd1.disabled = true;
      checkd2.disabled = true;
      checkd3.disabled = true;
      checkd4.disabled = true;
      checkd5.disabled = true;
      checkd6.disabled = true;
      checkd7.disabled = true;
      SetD1(false)
      SetD2(false)
      SetD3(false)
      SetD4(false)
      SetD5(false)
      SetD6(false)
      SetD7(false)

      SetFreq_dias("")
      showDate(datain.value, { ...daysMonthsYears, days: Number(0), months: Number(2), years: Number(0) });
    } else if (frequencia === '6') {
      setDadosPersonalizado2([])
      checkboxpularsab.disabled = false;
      checkboxpulardom.disabled = false;
      setFrequencia10('N')
      checkd1.disabled = true;
      checkd2.disabled = true;
      checkd3.disabled = true;
      checkd4.disabled = true;
      checkd5.disabled = true;
      checkd6.disabled = true;
      checkd7.disabled = true;
      SetD1(false)
      SetD2(false)
      SetD3(false)
      SetD4(false)
      SetD5(false)
      SetD6(false)
      SetD7(false)

      SetFreq_dias("")
      showDate(datain.value, { ...daysMonthsYears, days: Number(0), months: Number(3), years: Number(0) });
    } else if (frequencia === '7') {
      setDadosPersonalizado2([])
      checkboxpularsab.disabled = false;
      checkboxpulardom.disabled = false;
      setFrequencia10('N')
      checkd1.disabled = true;
      checkd2.disabled = true;
      checkd3.disabled = true;
      checkd4.disabled = true;
      checkd5.disabled = true;
      checkd6.disabled = true;
      checkd7.disabled = true;
      SetD1(false)
      SetD2(false)
      SetD3(false)
      SetD4(false)
      SetD5(false)
      SetD6(false)
      SetD7(false)

      SetFreq_dias("")
      showDate(datain.value, { ...daysMonthsYears, days: Number(0), months: Number(6), years: Number(0) });
    } else if (frequencia === '8') {
      setDadosPersonalizado2([])
      checkboxpularsab.disabled = false;
      checkboxpulardom.disabled = false;
      setFrequencia10('N')
      checkd1.disabled = true;
      checkd2.disabled = true;
      checkd3.disabled = true;
      checkd4.disabled = true;
      checkd5.disabled = true;
      checkd6.disabled = true;
      checkd7.disabled = true;
      SetD1(false)
      SetD2(false)
      SetD3(false)
      SetD4(false)
      SetD5(false)
      SetD6(false)
      SetD7(false)

      SetFreq_dias("")
      showDate(datain.value, { ...daysMonthsYears, days: Number(0), months: Number(0), years: Number(1) });
    } else if (frequencia === '9') {
      setDadosPersonalizado2([])
      checkboxpularsab.disabled = false;
      checkboxpulardom.disabled = false;
      checkd1.disabled = true;
      checkd2.disabled = true;
      checkd3.disabled = true;
      checkd4.disabled = true;
      checkd5.disabled = true;
      checkd6.disabled = true;
      checkd7.disabled = true;
      SetD1(false)
      SetD2(false)
      SetD3(false)
      SetD4(false)
      SetD5(false)
      SetD6(false)
      SetD7(false)
      setFrequencia10('N')
      SetFreq_dias(vfreqdias.value)

      showDate(datain.value, { ...daysMonthsYears, days: Number(vfreqdias.value), months: Number(0), years: Number(0) });

    } else if (frequencia === '10') {
      checkboxpularsab.disabled = false;
      checkboxpulardom.disabled = false;
      checkd1.disabled = true;
      checkd2.disabled = true;
      checkd3.disabled = true;
      checkd4.disabled = true;
      checkd5.disabled = true;
      checkd6.disabled = true;
      checkd7.disabled = true;
      SetD1(false)
      SetD2(false)
      SetD3(false)
      SetD4(false)
      SetD5(false)
      SetD6(false)
      SetD7(false)
      setFrequencia10('S')

      SetFreq_dias("")
      localStorage.setItem("dadosPersonalizado2", JSON.stringify([]));
      showDate(datain.value, { ...daysMonthsYears, days: Number(0), months: Number(0), years: Number(0) });
    }
    else if (frequencia === '11') {
      setDadosPersonalizado2([])
      checkboxpularsab.disabled = false;
      checkboxpulardom.disabled = false;
      // showDate(datain.value, { ...daysMonthsYears, days: Number(0), months: Number(0), years: Number(0) });
      checkd1.disabled = true;
      checkd2.disabled = true;
      checkd3.disabled = true;
      checkd4.disabled = true;
      checkd5.disabled = true;
      checkd6.disabled = true;
      checkd7.disabled = true;
      SetD1(false)
      SetD2(false)
      SetD3(false)
      SetD4(false)
      SetD5(false)
      SetD6(false)
      SetD7(false)
      setFrequencia10('N')
      showDate(datain.value, { ...daysMonthsYears, days: Number(0), months: Number(0), years: Number(0) });
      SetFreq_dias(vfreqdias.value)


      if (vfreqdias.value === '' && datain.value === "") {

      } else {


        let status = moment(datain.value + ' ' + timeI.value).add(Number(vfreqdias.value), 'hour').format('DD/MM/YYYY HH:mm:ss');
        setProximaExec(status)

      }

    }

  }


  function validaDataFim(id: any) {

    if (id !== "") {

      if (dataIncial !== "") {

        if (new Date(id) < new Date(dataIncial)) {

          setQualAlert(<div className='div-alert'>
            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
            <div className='div-info-alert'><span className="info-alert">A data final não pode ser menor que data inicial.</span></div>
            <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
          </div>)

          setOpenModal(true)

        } else {
          SetDatafim(id)
        }
      } else {
        SetDatafim(id)
      }
    } else {
      SetDatafim(id)
    }


  }


  function finalizarModal2() {
    setOpenModal(false)
  }


  function FrequenciaInlcuir(id: any) {



    if (id === '') {
      setFrequencia10('N')
      SetFrequencia(id)
      setProximaExec('')
    } else {
      let datain = document.getElementById("DATAINI") as HTMLInputElement;
      setDate(datain.value);

      let freq = document.getElementById("FREQUEC") as HTMLInputElement;

      let PROXE = document.getElementById("PROX") as HTMLInputElement;
      let dataI = document.getElementById("DATAINI") as HTMLInputElement;
      let dataF = document.getElementById("DATAFIM") as HTMLInputElement;
      let timeI = document.getElementById("TIMEINI") as HTMLInputElement;
      let timeF = document.getElementById("TIMEFIM") as HTMLInputElement;

      let checkd1 = document.getElementById("checkbox-D1") as HTMLInputElement;
      let checkd2 = document.getElementById("checkbox-D2") as HTMLInputElement;
      let checkd3 = document.getElementById("checkbox-D3") as HTMLInputElement;
      let checkd4 = document.getElementById("checkbox-D4") as HTMLInputElement;
      let checkd5 = document.getElementById("checkbox-D5") as HTMLInputElement;
      let checkd6 = document.getElementById("checkbox-D6") as HTMLInputElement;
      let checkd7 = document.getElementById("checkbox-D7") as HTMLInputElement;
      let checkboxpularsab = document.getElementById("checkbox-pular-sab") as HTMLInputElement;
      let checkboxpulardom = document.getElementById("checkbox-pular-dom") as HTMLInputElement;

      let vfreqdias = document.getElementById("validar-freqdias") as HTMLInputElement;
      SetDataIncial(dataI.value)
      SetHoraIncial(timeI.value)
      SetHorafim(timeF.value)
      SetFrequencia(id)
      setProximaExecFunc('OK')

      if (id === '1') {
        checkd1.disabled = true;
        checkd2.disabled = true;
        checkd3.disabled = true;
        checkd4.disabled = true;
        checkd5.disabled = true;
        checkd6.disabled = true;
        checkd7.disabled = true;
        SetD1(false)
        SetD2(false)
        SetD3(false)
        SetD4(false)
        SetD5(false)
        SetD6(false)
        SetD7(false)
        checkboxpularsab.disabled = false;
        checkboxpulardom.disabled = false;
        showDate(datain.value, { ...daysMonthsYears, days: Number(1), months: Number(0), years: Number(0) });

        SetFreq_dias("")
        setFrequencia10('N')
      } else if (id === '2') {
        SetPular_sab(false)
        SetPular_dom(false)
        setFrequencia10('N')
        checkd1.disabled = false;
        checkd2.disabled = false;
        checkd3.disabled = false;
        checkd4.disabled = false;
        checkd5.disabled = false;
        checkd6.disabled = false;
        checkd7.disabled = false;
        checkboxpularsab.disabled = true;
        checkboxpulardom.disabled = true;
        SetFreq_dias("")


        if (checkd1.checked) {
          SetD1(true)
        } else {

          SetD1(false)
        }
        if (checkd2.checked) {
          SetD2(true)
        } else {
          SetD2(false)
        }
        if (checkd3.checked) {
          SetD3(true)
        } else {
          SetD3(false)
        }
        if (checkd4.checked) {
          SetD4(true)
        } else {
          SetD4(false)
        }
        if (checkd5.checked) {
          SetD5(true)
        } else {
          SetD5(false)
        }
        if (checkd6.checked) {
          SetD6(true)
        } else {
          SetD6(false)
        }
        if (checkd7.checked) {
          SetD7(true)
        } else {
          SetD7(false)
        }

        const dt = new Date(dataI.value);


        const atual = dt.getDay();

        var newArray = [checkd2.checked, checkd3.checked, checkd4.checked, checkd5.checked, checkd6.checked, checkd7.checked, checkd1.checked];


        // 2
        var newArrayPt1 = newArray.slice(atual + 1)
        var newArrayPt2 = newArray.slice(0, atual + 1)



        var pos = newArrayPt1.indexOf(true) + (atual + 1);

        if (newArrayPt1.indexOf(true) === -1) {

          pos = newArrayPt2.indexOf(true);
        }

        const prox = pos;

        if (pos === -1) {
          showDate(datain.value, { ...daysMonthsYears, days: Number(0), months: Number(0), years: Number(0) });
        } else {

          if (prox <= atual) {

            showDate(dataI.value, { ...daysMonthsYears, days: Number((7 - atual) + prox), months: Number(0), years: Number(0) });
          } else {

            showDate(dataI.value, { ...daysMonthsYears, days: Number((prox - atual)), months: Number(0), years: Number(0) });
          }

        }

      } else if (id === '3') {


        checkboxpularsab.disabled = false;
        checkboxpulardom.disabled = false;
        setFrequencia10('N')
        checkd1.disabled = true;
        checkd2.disabled = true;
        checkd3.disabled = true;
        checkd4.disabled = true;
        checkd5.disabled = true;
        checkd6.disabled = true;
        checkd7.disabled = true;
        SetD1(false)
        SetD2(false)
        SetD3(false)
        SetD4(false)
        SetD5(false)
        SetD6(false)
        SetD7(false)
        showDate(datain.value, { ...daysMonthsYears, days: Number(15), months: Number(0), years: Number(0) });

        SetFreq_dias("")
      } else if (id === '4') {
        checkboxpularsab.disabled = false;
        checkboxpulardom.disabled = false;
        setFrequencia10('N')
        checkd1.disabled = true;
        checkd2.disabled = true;
        checkd3.disabled = true;
        checkd4.disabled = true;
        checkd5.disabled = true;
        checkd6.disabled = true;
        checkd7.disabled = true;
        SetD1(false)
        SetD2(false)
        SetD3(false)
        SetD4(false)
        SetD5(false)
        SetD6(false)
        SetD7(false)

        SetFreq_dias("")
        showDate(datain.value, { ...daysMonthsYears, days: Number(0), months: Number(1), years: Number(0) });
      } else if (id === '5') {
        checkboxpularsab.disabled = false;
        checkboxpulardom.disabled = false;
        setFrequencia10('N')
        checkd1.disabled = true;
        checkd2.disabled = true;
        checkd3.disabled = true;
        checkd4.disabled = true;
        checkd5.disabled = true;
        checkd6.disabled = true;
        checkd7.disabled = true;
        SetD1(false)
        SetD2(false)
        SetD3(false)
        SetD4(false)
        SetD5(false)
        SetD6(false)
        SetD7(false)

        SetFreq_dias("")
        showDate(datain.value, { ...daysMonthsYears, days: Number(0), months: Number(2), years: Number(0) });
      } else if (id === '6') {
        checkboxpularsab.disabled = false;
        checkboxpulardom.disabled = false;
        setFrequencia10('N')
        checkd1.disabled = true;
        checkd2.disabled = true;
        checkd3.disabled = true;
        checkd4.disabled = true;
        checkd5.disabled = true;
        checkd6.disabled = true;
        checkd7.disabled = true;
        SetD1(false)
        SetD2(false)
        SetD3(false)
        SetD4(false)
        SetD5(false)
        SetD6(false)
        SetD7(false)

        SetFreq_dias("")
        showDate(datain.value, { ...daysMonthsYears, days: Number(0), months: Number(3), years: Number(0) });
      } else if (id === '7') {
        checkboxpularsab.disabled = false;
        checkboxpulardom.disabled = false;
        setFrequencia10('N')
        checkd1.disabled = true;
        checkd2.disabled = true;
        checkd3.disabled = true;
        checkd4.disabled = true;
        checkd5.disabled = true;
        checkd6.disabled = true;
        checkd7.disabled = true;
        SetD1(false)
        SetD2(false)
        SetD3(false)
        SetD4(false)
        SetD5(false)
        SetD6(false)
        SetD7(false)

        SetFreq_dias("")
        showDate(datain.value, { ...daysMonthsYears, days: Number(0), months: Number(6), years: Number(0) });
      } else if (id === '8') {
        checkboxpularsab.disabled = false;
        checkboxpulardom.disabled = false;
        setFrequencia10('N')
        checkd1.disabled = true;
        checkd2.disabled = true;
        checkd3.disabled = true;
        checkd4.disabled = true;
        checkd5.disabled = true;
        checkd6.disabled = true;
        checkd7.disabled = true;
        SetD1(false)
        SetD2(false)
        SetD3(false)
        SetD4(false)
        SetD5(false)
        SetD6(false)
        SetD7(false)

        SetFreq_dias("")
        showDate(datain.value, { ...daysMonthsYears, days: Number(0), months: Number(0), years: Number(1) });
      } else if (id === '9') {
        checkboxpularsab.disabled = false;
        checkboxpulardom.disabled = false;
        checkd1.disabled = true;
        checkd2.disabled = true;
        checkd3.disabled = true;
        checkd4.disabled = true;
        checkd5.disabled = true;
        checkd6.disabled = true;
        checkd7.disabled = true;
        SetD1(false)
        SetD2(false)
        SetD3(false)
        SetD4(false)
        SetD5(false)
        SetD6(false)
        SetD7(false)
        setFrequencia10('N')
        SetFreq_dias(vfreqdias.value)

        showDate(datain.value, { ...daysMonthsYears, days: Number(vfreqdias.value), months: Number(0), years: Number(0) });

      } else if (id === '10') {
        checkboxpularsab.disabled = false;
        checkboxpulardom.disabled = false;
        checkd1.disabled = true;
        checkd2.disabled = true;
        checkd3.disabled = true;
        checkd4.disabled = true;
        checkd5.disabled = true;
        checkd6.disabled = true;
        checkd7.disabled = true;
        SetD1(false)
        SetD2(false)
        SetD3(false)
        SetD4(false)
        SetD5(false)
        SetD6(false)
        SetD7(false)
        setFrequencia10('S')

        SetFreq_dias("")
        localStorage.setItem("dadosPersonalizado2", JSON.stringify([]));
        showDate(datain.value, { ...daysMonthsYears, days: Number(0), months: Number(0), years: Number(0) });
      }
      else if (id === '11') {
        checkboxpularsab.disabled = false;
        checkboxpulardom.disabled = false;
        // showDate(datain.value, { ...daysMonthsYears, days: Number(0), months: Number(0), years: Number(0) });
        checkd1.disabled = true;
        checkd2.disabled = true;
        checkd3.disabled = true;
        checkd4.disabled = true;
        checkd5.disabled = true;
        checkd6.disabled = true;
        checkd7.disabled = true;
        SetD1(false)
        SetD2(false)
        SetD3(false)
        SetD4(false)
        SetD5(false)
        SetD6(false)
        SetD7(false)
        setFrequencia10('N')
        showDate(datain.value, { ...daysMonthsYears, days: Number(0), months: Number(0), years: Number(0) });
        SetFreq_dias(vfreqdias.value)


        if (vfreqdias.value === '' && datain.value === "") {

        } else {


          let status = moment(datain.value + ' ' + timeI.value).add(Number(vfreqdias.value), 'hour').format('DD/MM/YYYY HH:mm:ss');
          setProximaExec(status)

        }

      }
    }

  }

  function carregalocal(valor: any) {

    setDadosLocal(valor)
  }

  function dadosLoc(obj: any) {

    setDadosLocais(obj)
  }

  function ValidarPular_sab() {
    let e = document.getElementById("checkbox-pular-sab") as HTMLInputElement;
    if (e.checked) {

      SetPular_sab(true)
    } else {

      SetPular_sab(false)
    }
  }

  function ValidarPular_dom() {
    let e = document.getElementById("checkbox-pular-dom") as HTMLInputElement;
    if (e.checked) {

      SetPular_dom(true)
    } else {

      SetPular_dom(false)
    }
  }


  function ValitarNotificacao() {
    let e = document.getElementById("checkbox-notificacao") as HTMLInputElement;
    if (e.checked) {

      SetNotificacao(true)

    } else {

      SetNotificacao(false)

    }
  }

  function ValitarTTS() {
    let e = document.getElementById("checkbox-tts") as HTMLInputElement;
    if (e.checked) {

      SetTts(true)

    } else {

      SetTts(false)

    }
  }

  function ValitarSMS() {
    let e = document.getElementById("checkbox-sms") as HTMLInputElement;
    if (e.checked) {

      SetSms(true)

    } else {

      SetSms(false)

    }
  }

  function ValitarEMail() {
    let e = document.getElementById("checkbox-email") as HTMLInputElement;
    if (e.checked) {

      SetEMail(true)

    } else {
      setMail("")
      SetEMail(false)

    }
  }

  function personalizado2func(valor: any) {
    setDadosPersonalizado2(valor)

  }

  async function salvar() {


    setQualAlert(<div className='div-alert'><Loading /></div>)
    setOpenModal(true)

    const temp_rows = dadosLocais.filter(
      (e: any) => {
        return e.ok === 1
      }
    );


    const FiltrarDescricao = dadosPlanejador.filter(
      (e: any) => {
        return e.pla_description.toLowerCase() === descricao.toLowerCase()
      }
    );

    if (FiltrarDescricao.length > 0) {

      setQualAlert(<div className='div-alert'>
        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
        <div className='div-info-alert'><span className="info-alert">Descrição já está em uso.</span></div>
        <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
      </div>)

    } else
      if (descricao === '') {

        setQualAlert(<div className='div-alert'>
          <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Digite a descrição.</span></div>
          <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
        </div>)


      }
      else if (pessoa === '' && equipe === '') {


        setQualAlert(<div className='div-alert'>
          <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Selecione o usuário ou equipe desejada.</span></div>
          <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
        </div>)


      } else if (equipe !== '' && forma === '') {

        setQualAlert(<div className='div-alert'>
          <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Selecione o modo de equipe desejada.</span></div>
          <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
        </div>)

      }
      else if (situacao === '') {

        setQualAlert(<div className='div-alert'>
          <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Selecione a situação desejada.</span></div>
          <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
        </div>)

      } else if (tipoTarefa === "") {

        setQualAlert(<div className='div-alert'>
          <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Selecione o tipo de tarefa.</span></div>
          <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
        </div>)

      } else if (tipoTarefa === "4" && subtipo === "") {

        setQualAlert(<div className='div-alert'>
          <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Selecione o tipo de preventiva.</span></div>
          <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
        </div>)

      } else if (dataIncial === '') {

        setQualAlert(<div className='div-alert'>
          <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Selecione a data inicial desejada.</span></div>
          <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
        </div>)

      } else if (horaIncial === '') {

        setQualAlert(<div className='div-alert'>
          <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Selecione a hora inicial desejada.</span></div>
          <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
        </div>)

      } else if (datafim !== '' && horafim === '') {

        setQualAlert(<div className='div-alert'>
          <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Selecione a hora final desejada.</span></div>
          <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
        </div>)

      } else if (horafim !== '' && datafim === '') {

        setQualAlert(<div className='div-alert'>
          <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Selecione a data final desejada.</span></div>
          <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
        </div>)

      } else if (datafim !== '' && datafim < dataIncial) {

        setQualAlert(<div className='div-alert'>
          <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Data final não pode ser menor que data incial.</span></div>
          <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
        </div>)

      } else
        if (dataIncial === datafim && horafim < horaIncial) {

          setQualAlert(<div className='div-alert'>
            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
            <div className='div-info-alert'><span className="info-alert">A hora final não pode ser menor ou igual que hora inicial.</span></div>
            <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
          </div>)

        }
        else if (frequencia === '' || frequencia === 'Selecione...') {

          setQualAlert(<div className='div-alert'>
            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
            <div className='div-info-alert'><span className="info-alert">Selecione a frequência desejada.</span></div>
            <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
          </div>)

        } else if (frequencia === '2' && d1 === false && d2 === false && d3 === false && d4 === false && d5 === false && d6 === false && d7 === false) {

          setQualAlert(<div className='div-alert'>
            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
            <div className='div-info-alert'><span className="info-alert">Selecione ao menos um dia.</span></div>
            <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
          </div>)

        } else if (frequencia === '9' && freq_dias === "") {

          setQualAlert(<div className='div-alert'>
            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
            <div className='div-info-alert'><span className="info-alert">Digite a quantidade de dias.</span></div>
            <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
          </div>)

        } else if (frequencia === '10' && dadosPersonalizado2.length === 0) {

          setQualAlert(<div className='div-alert'>
            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
            <div className='div-info-alert'><span className="info-alert">Selecione as datas e horas personalizadas.</span></div>
            <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
          </div>)

        }

        else if (frequencia === '11' && freq_dias === "") {

          setQualAlert(<div className='div-alert'>
            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
            <div className='div-info-alert'><span className="info-alert">Digite a quantidade de horas desejada.</span></div>
            <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
          </div>)

        } else if (email === true && mail === '') {

          setQualAlert(<div className='div-alert'>
            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
            <div className='div-info-alert'><span className="info-alert">Digite o E-mail.</span></div>
            <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
          </div>)

        }
        else if (entidade === '1' && loc_id === '') {

          setQualAlert(<div className='div-alert'>
            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
            <div className='div-info-alert'><span className="info-alert">Selecione o local.</span></div>
            <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
          </div>)

        }
        else if (entidade === '2' && ast_id === '') {

          setQualAlert(<div className='div-alert'>
            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
            <div className='div-info-alert'><span className="info-alert">Selecione o ativo.</span></div>
            <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
          </div>)

        }
        else {

          let datafims = ''

          if (datafim === '') {
            datafims = ''
          } else {
            datafims = (moment(datafim).format("DD/MM/YYYY"))
          }


          if (tipoTarefa === '') {
            SetTipoTarefa('')
          }

          if (subtipo === '') {
            SetSubtipo('')
          }

          let validaPessoa = null
          let validaEquipe = null
          let validaForma = null
          let validaTipoTarefa = null
          let validasubtipo = null
          let validarEntidade = null

          if (entidade === '1') {
            validarEntidade = loc_id
          } else if (entidade === '2') {
            validarEntidade = null
          }
         
          if (pessoa === '') {
            validaPessoa = (null)
          } else {
            validaPessoa = (pessoa)
          }


          if (equipe === '') {
            validaEquipe = (null)
          } else {
            validaEquipe = equipe
          }


          if (forma === '') {
            validaForma = (null)
          } else {
            validaForma = forma
          }

          if (tipoTarefa === '') {
            validaTipoTarefa = (null)
          } else {
            validaTipoTarefa = tipoTarefa
          }

          if (subtipo === '') {
            validasubtipo = null
          } else {
            validasubtipo = subtipo
          }

          const dados = [{
            pla_description: descricao,
            pla_active: ativo,
            age_id: validaPessoa,
            tea_id: validaEquipe,
            stn_id: situacao,
            fqc_id: frequencia,
            pla_amount: freq_dias,
            pla_skipsunday: pular_dom,
            pla_skipsaturday: pular_sab,
            pla_startdate: dataIncial,
            pla_enddate: datafim || null,
            pla_starttime: horaIncial,
            pla_endtime: horafim || null,
            pla_sunday: d1,
            pla_monday: d2,
            pla_tuesday: d3,
            pla_wednesday: d4,
            pla_thursday: d5,
            pla_friday: d6,
            pla_saturday: d7,
            pla_nextexecution: dateproximaExec,
            tsf_id: validaForma,
            tty_id: validaTipoTarefa,
            pla_anticipate: antecipar || null,
            pla_activitymode: 1,
            pla_advancenotification: msgDia || null,
            pla_notification: notificacao,
            pla_tts: tts,
            pla_sms: sms,
            pla_mail: email,
            pla_recreate: recriar,
            pla_disable: desativar,
            pla_observation: observacao,
            pvt_id: validasubtipo,
            pla_email: mail,
            ett_id: entidade,
            loc_id: validarEntidade || null,
            ast_id: ast_id || null,
            age_id_insert: secureLocalStorage.getItem('iag'),
          }]


          //-----------------------------------LOCAIS----------------------

      
          let ret: AxiosResponse;

          try {
            ret = await api.post("planner/insert", {
              dadosagenda: dados,
            });
            if (ret.data.status === 200) {

              setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-check ControleIconAlert"></span>
                <div className='div-info-alert'><span className="info-alert">Cadastro realizado com sucesso.</span></div>
                <div className='btn-alert' onClick={finalizarModal1}><span className="btn-alert-lab">OK</span></div>
              </div>)
            }
          } catch (e: any) {


            setQualAlert(<div className='div-alert'>
              <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
              <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
              <div className='div-info-alert'><span className="info-alert">Não foi possivel concluir o cadastramento.</span></div>
              <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
            </div>)
          }


        }

  }


  function fecharModal() {
    setOpenModal(false)
  }

  function finalizarModal1() {
    navigate('/planner');
  }

  function incluirOque() {
    setTabIndex(0)
  }

  function incluirQuando() {
    setTabIndex(1)
  }

  function incluirOnde() {
    setTabIndex(2)
  }

  function cancelar() {
    navigate('/planner');
  }

  //--------------------------------------------------
  //--------------------------------------------------
  //--------------------------------------------------
  //--------------------------------------------------

  async function GetLocal() {
    let ret: AxiosResponse;
    let valor: any;
    try {
      ret = await api.get("local/listarlocal")
      if (ret.data.status === 200) {
        valor = ret.data.data;

        const arr = [] as any;
        let result = valor;
        result.map((user: any) => {
          return arr.push({ value: String(user.loc_id), label: user.loc_description + " ("+ user.loc_alternativeidentifier + ")" });
        });
        setOptionsDepartment(arr)
      }
    } catch (e) {
      setOptionsDepartment([])
    }
  }

  async function GetContrato() {
    let ret: AxiosResponse;
    let valor: any;
    try {
      ret = await api.get("contract/listar")
      if (ret.data.status === 200) {
        valor = ret.data.data;

        const arr = [] as any;
        let result = valor;
        result.map((user: any) => {
          return arr.push({ value: String(user.ctc_id), label: user.ctc_description });
        });
        setOptionContract(arr)
      }
    } catch (e) {
      setOptionContract([])
    }
  }


  function IncluirContrato(Contrato: any) {

   

    if (Contrato === '' || Contrato === 'Selecione...') {
      GetLocal()
      setAst_id("")
      listarAtivosEmUso()
      setContract('')
      setOptionUnity([])
      setUnity("")
      setLoc_id("")
      setOptionspavement([])
      setPavement("")

    } else {
      setQualAlert(<div className='div-alert'><Loading /></div>)
      setOpenModal(true)
      setOptionAtivo([])
      setOptionspavement([])
      setOptionsDepartment([])
      GetUnidadeSel(Contrato.split('_')[0])
      setContract(Contrato)
    }
  }


  async function GetUnidadeSel(id: any) {
    let ret: AxiosResponse;
    let valor: any;

    setOptionUnity([]);

    if (id === '' || id === 'Selecione...' || id === 'undefined' || id === undefined) {
      setUnity('')
      setOptionUnity([])

      setQualAlert(<div className='div-alert'>
        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
        <div className='div-info-alert'><span className="info-alert">Selecione o contrato.</span></div>
        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
      </div>)

      setOpenModal(true)

    } else {
      try {
        ret = await api.get("unity/listar", {
          params: {
            id: id
          }
        })
        if (ret.data.status === 200) {
          valor = ret.data.data;


          const arr = [] as any;
          let result = valor;
          result.map((user: any) => {
            return arr.push({ value: String(user.unt_id), label: user.unt_description });
          });
          setOptionUnity(arr)
        }
        setOpenModal(false)
      } catch (e) {
        setOpenModal(false)
        setOptionUnity([]);
      }
    }

  }


  function IncluirUnity(Unidade: any) {

    if (Unidade === '') {
      setUnity('')
      setOptionsDepartment([])
      setLoc_id("")
      setOptionspavement([])
      setPavement("")
    } else {

      setQualAlert(<div className='div-alert'><Loading /></div>)
      setOpenModal(true)

      setOptionsDepartment([])
      setLoc_id("")
      setOptionspavement([])
      setPavement("")
      GetDepartment(Unidade.split('_')[0])
      setUnity(Unidade)
    }
  }


  async function GetDepartment(unidade: any) {
    let ret: AxiosResponse;
    let valor: any;

    setOptionspavement([])
    setOptionsDepartment([])
    try {
      ret = await api.get("local/listar", {
        params: {
          id: unidade
        }
      })
      if (ret.data.status === 200) {
        valor = ret.data.data;

        const arr = [] as any;
        let result = valor;
        result.map((user: any) => {
          return arr.push({ value: String(user.loc_pavement), label: user.loc_pavement });
        });

        setOptionspavement(arr)

      }
      setOpenModal(false)
    } catch (e) {
      setOpenModal(false)
      setOptionsDepartment([])
    }
  }


  function IncluirLocais(locais: any) {

    if (locais === '') {
      setLoc_id("")
      setPavement("")
      setOptionsDepartment([])
    } else {
      setQualAlert(<div className='div-alert'><Loading /></div>)
setOpenModal(true)
      setLoc_id("")
      setPavement(locais)
      GetDepartmentPaviment(locais)
    }

  }


  async function GetDepartmentPaviment(pavimento: any) {
    let ret: AxiosResponse;
    let valor: any;

    try {
      ret = await api.get("local/listarlocpaviment", {
        params: {
          id: unity.split('_')[0],
          paviment: pavimento
        }
      })
      if (ret.data.status === 200) {
        valor = ret.data.data;

        const arr = [] as any;
        let result = valor;
        result.map((user: any) => {
          return arr.push({ value: String(user.loc_id), label: user.loc_description });
        });
        setOptionsDepartment(arr)
      }
      setOpenModal(false)
    } catch (e) {
      setOpenModal(false)
      setOptionsDepartment([])
    }
  }

  function incluirDepartment(valor: any) {

    if (valor === "") {
      setLoc_id("")
    } else {
      setQualAlert(<div className='div-alert'><Loading /></div>)
      setOpenModal(true)
      setLoc_id(valor)
      listarListarativodepart(valor)
    }
  }

  async function listarAtivosEmUso() {
    let ret: AxiosResponse;
    let valor: any;

    try {
        ret = await api.get("asset/listaressetemuso")
        if (ret.data.status === 200) {
            valor = ret.data.data;

            const arr = [] as any;
            let result = valor;
            result.map((user: any) => {
                return arr.push({ value: String(user.ast_id), label: user.ast_description + " (" + user.ast_alternativeidentifier + ")"});
            });
            setOptionAtivo(arr)

        }
    } catch (e) {
        setOptionUnity([]);
    }

}

  async function listarListarativodepart(id: any) {
    let ret: AxiosResponse;
    let valor: any;

    try {
        ret = await api.get("local/listarativoloc", {
            params: {
                id: id
            }
        })
        if (ret.data.status === 200) {
            valor = ret.data.data;

            const arr = [] as any;
            let result = valor;
            result.map((user: any) => {
                return arr.push({ value: String(user.ast_id), label: user.ast_description + " (" + user.ast_alternativeidentifier + ")" });
            });
            setOptionAtivo(arr)

        }
        setOpenModal(false)
    } catch (e) {
      setOpenModal(false)
        setOptionUnity([]);
    }

}

function incluirTipoTarefa(id: any){

  if(id === ''){
    SetTipoTarefa("")
    setOptionsTipoPreventiva([])
  }if(id === '4'){
    SetTipoTarefa(id)
    GetPreventiva(entidade)
  }else{
    setOptionsTipoPreventiva([])
    SetTipoTarefa(id)
    SetSubtipo("")

  }

}


  return (
    <>
      <Nav />
      <div className="main-content">
        <Header />
        <main>
          <div className='containerPrincipal'>
           
            <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>

      

              <div className="container-cad-prod32">
                <div id="content1">
                  <TabList>
                    <Tab onClick={incluirOque}>
                      <span className='fa-solid fa-user ControleIcon'></span>
                      <span className="lab-icone-pln">O Que/Quem </span>
                    </Tab>
                    <Tab onClick={incluirQuando}>
                      <span className='fa-solid fa-calendar-plus ControleIcon'></span>
                      <span className="lab-icone-pln">Quando</span>
                    </Tab>
                    <Tab onClick={incluirOnde}>
                      <span className='fa-solid fa-map-location ControleIcon'></span>
                      <span className="lab-icone-pln">Onde</span>
                    </Tab>


                  </TabList>
                </div>



                <div id="content2">
      
                  <div className="planner-panel">

                    <TabPanel>

                      <div className="container-cad-prod47">

                        <div id="content1">
                          <div className="input-box3">
                            <div className="input-c">
                              <input type="checkbox" id="checkbox-active" checked={ativo} onChange={ValitarActive} />
                              <span className="details">Ativo</span>
                            </div>
                          </div>
                        </div>

                        <div id="content2">
                          <div className="input-box3">
                            <div className="input-c">
                              <input type="checkbox" id="checkbox-desativar" checked={desativar} onChange={ValitarDesable} />
                              <span className="details">Desativar após execução</span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="container-cad-prod4">

                        <div id="content1">
                          <div className="input-box-ativos">
                            <span className="details">Descrição *</span>
                            <input
                              type="text"
                              autoComplete="off"
                              onChange={(e) => setDescricao(e.target.value)}
                              value={descricao}
                            />
                          </div>
                        </div>

                        <div id="content2">
                          <div className="select-box-ativos-select">
                            <span className="details">Usuário</span>
                            <Select
                              placeholder={'Selecione...'}
                              options={optionsAgent}
                              styles={customStyles}
                              value={optionsAgent.filter(function (option: any) {
                                return option.value === pessoa;
                              })}
                              onChange={(options: any) =>
                                !options ? PessoaAjuste("") : PessoaAjuste(options.value)
                              }
                              isClearable
                              noOptionsMessage={() => "Não encontrado..."}
                              theme={(theme: any) => ({
                                ...theme,
                                borderRadius: 4,
                                colors: {
                                  ...theme.colors,
                                  primary25: '#ebebeb',
                                  primary: 'black',
                                },
                              })}
                            />
                          </div>
                        </div>

                      </div>

                      <div className="container-cad-prod4">
                        <div id="content1">
                          <div className="select-box-ativos-select">
                            <span className="details">Equipe</span>
                            <Select
                              placeholder={'Selecione...'}
                              options={optionsEquipe}
                              styles={customStyles}
                              value={optionsEquipe.filter(function (option: any) {
                                return option.value === equipe;
                              })}
                              onChange={(options: any) =>
                                !options ? EquipeAjuste("") : EquipeAjuste(options.value)
                              }
                              isClearable
                              noOptionsMessage={() => "Não encontrado..."}
                              theme={(theme: any) => ({
                                ...theme,
                                borderRadius: 4,
                                colors: {
                                  ...theme.colors,
                                  primary25: '#ebebeb',
                                  primary: 'black',
                                },
                              })}
                            />
                          </div>
                        </div>

                        <div id="content2">
                          <div className="select-box-ativos-select">
                            <span className="details">Forma</span>
                            <Select
                              placeholder={'Selecione...'}
                              options={optionsForma}
                              styles={customStyles}
                              isDisabled={isDisabledForma}
                              value={optionsForma.filter(function (option: any) {
                                return option.value === forma;
                              })}
                              onChange={(options: any) =>
                                !options ? FormaAjuste("") : FormaAjuste(options.value)
                              }
                              isClearable
                              noOptionsMessage={() => "Não encontrado..."}
                              theme={(theme: any) => ({
                                ...theme,
                                borderRadius: 4,
                                colors: {
                                  ...theme.colors,
                                  primary25: '#ebebeb',
                                  primary: 'black',
                                },
                              })}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="container-cad-prod2">
                        <div id="content1">
                          <div className="select-box-ativos-select">
                            <span className="details">Situação *</span>
                            <Select
                              placeholder={'Selecione...'}
                              options={optionsSituacao}
                              styles={customStyles}
                              value={optionsSituacao.filter(function (option: any) {
                                return option.value === situacao;
                              })}
                              onChange={(options: any) =>
                                !options ? SetSituacao("") : SetSituacao(options.value)
                              }
                              isClearable
                              noOptionsMessage={() => "Não encontrado..."}
                              theme={(theme: any) => ({
                                ...theme,
                                borderRadius: 4,
                                colors: {
                                  ...theme.colors,
                                  primary25: '#ebebeb',
                                  primary: 'black',
                                },
                              })}
                            />
                          </div>
                        </div>
                        <div id="content2">
                          <div className="select-box-ativos-select">
                            <span className="details">Tipo de Tarefa *</span>
                            <Select
                              placeholder={'Selecione...'}
                              options={optionsTasktype}
                              styles={customStyles}
                              value={optionsTasktype.filter(function (option: any) {
                                return option.value === tipoTarefa;
                              })}
                              onChange={(options: any) =>
                                !options ? incluirTipoTarefa("") : incluirTipoTarefa(options.value)
                              }
                              isClearable
                              noOptionsMessage={() => "Não encontrado..."}
                              theme={(theme: any) => ({
                                ...theme,
                                borderRadius: 4,
                                colors: {
                                  ...theme.colors,
                                  primary25: '#ebebeb',
                                  primary: 'black',
                                },
                              })}
                            />
                          </div>
                        </div>
                        <div id="content3">
                          <div className="select-box-ativos-select">

                            <span className="details">Tipo Preventiva</span>
                            <Select
                              placeholder={'Selecione...'}
                              options={optionsTipoPreventiva}
                              styles={customStyles}

                              value={optionsTipoPreventiva.filter(function (option: any) {
                                return option.value === subtipo;
                              })}
                              onChange={(options: any) =>
                                !options ? SetSubtipo("") : SetSubtipo(options.value)
                              }
                              isClearable
                              noOptionsMessage={() => "Não encontrado..."}
                              theme={(theme: any) => ({
                                ...theme,
                                borderRadius: 4,
                                colors: {
                                  ...theme.colors,
                                  primary25: '#ebebeb',
                                  primary: 'black',
                                },
                              })}
                            />
                          </div>
                        </div>

                      </div>

                      <div className="container-cad-prod-obs">
                        <div className="textareaBox-ativo">
                          <span className="details">Observação</span>
                          <textarea
                            onChange={(e) => SetObservacao(e.target.value)}
                            value={observacao}
                          />
                        </div>
                      </div>

                    </TabPanel>



                    <TabPanel>
                      <div className="container-cad-prod2">
                        <div id="content1">
                          <div className="input-box-ativos">
                            <span className="details">Data Inicial *</span>
                            <input
                              type="date" id="DATAINI"
                              value={dataIncial}
                              onChange={Frequencia}
                            />
                          </div>
                        </div>
                        <div id="content2">
                          <div className="input-box-ativos">
                            <span className="details">Hora Inicial *</span>
                            <input
                              type="time" id="TIMEINI" onChange={Frequencia}
                              value={horaIncial} />
                          </div>
                        </div>
                        <div id="content3">
                          <div className="input-box-ativos">
                            <span className="details">Prox. Execução</span>
                            <input
                              type="text" disabled
                              id="PROX"
                              onChange={(e) => setProximaExec(e.target.value)}
                              value={proximaExec}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="container-cad-prod2">
                        <div id="content1">
                          <div className="input-box-ativos">
                            <span className="details">Data Final</span>
                            <input
                              type="date" id="DATAFIM" onChange={(e) => validaDataFim(e.target.value)} value={datafim}
                            />
                          </div>
                        </div>
                        <div id="content2">
                          <div className="input-box-ativos">
                            <span className="details">Hora Final</span>
                            <input
                              type="time" id="TIMEFIM" onChange={(e) => SetHorafim(e.target.value)} value={horafim} />
                          </div>
                        </div>
                        <div id="content3">
                          <div className="input-box-ativos">
                            <span className="details">Antecipar Tarefa</span>
                            <input
                              autoComplete="off"
                              onChange={(e) => SetAntecipar(e.target.value)}
                              value={antecipar}
                              type="number"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="container-cad-prod2">
                        <div id="content1">
                          <div className="select-box-ativos-select">
                            <span className="details">Frequência *</span>
                            <Select
                              maxMenuHeight={200}
                              id="FREQUEC"
                              placeholder={'Selecione...'}
                              options={optionsFrequency}
                              styles={customStyles}
                              value={optionsFrequency.filter(function (option: any) {
                                return option.value === frequencia;
                              })}
                              onChange={(options: any) =>
                                !options ? FrequenciaInlcuir("") : FrequenciaInlcuir(options.value)
                              }
                              isClearable
                              noOptionsMessage={() => "Não encontrado..."}
                              theme={(theme: any) => ({
                                ...theme,
                                borderRadius: 4,
                                colors: {
                                  ...theme.colors,
                                  primary25: '#ebebeb',
                                  primary: 'black',
                                },
                              })}
                            />
                          </div>
                        </div>
                        <div id="content2">
                          <div className="input-box-ativos">
                            <span className="details">Quantidade</span>
                            <input
                              type="number"
                              onChange={Frequencia}
                              id="validar-freqdias"
                              value={freq_dias}
                            />
                          </div>
                        </div>
                        <div id="content3">
                          <div className="input-box-ativos">

                            <span className="details">Antecipar Not.</span>
                            <input
                              onChange={(e) => SetMsgDia(e.target.value)}
                              value={msgDia}
                              type="number"
                            />
                          </div>
                        </div>
                      </div>



                      {email === true ?
                        <div className="container-cad-prod3">
                          <div id="content1">
                            <div className="input-box-ativos">
                              <span className="details">E-mail</span>
                              <input
                                type="text"
                                autoComplete="off"
                                onChange={(e) => setMail(e.target.value)}
                                value={mail}
                              />
                            </div>
                          </div>
                        </div>
                        : null}



                      <div className="container-cad-prod33">

                        <div id="content1">

                          <div>
                            <div className="input-c">
                              <input type="checkbox" id="checkbox-D1" checked={d1} onChange={Frequencia} />
                              <span className="details">Domingo</span>
                            </div>

                            <div className="input-c">
                              <input type="checkbox" id="checkbox-D2" checked={d2} onChange={Frequencia} />
                              <span className="details">Segunda - Feira</span>
                            </div>


                            <div className="input-c">
                              <input type="checkbox" id="checkbox-D3" checked={d3} onChange={Frequencia} />
                              <span className="details">Terça - Feira</span>
                            </div>

                            <div className="input-c">
                              <input type="checkbox" id="checkbox-D4" checked={d4} onChange={Frequencia} />
                              <span className="details">Quarta - Feira</span>
                            </div>

                            <div className="input-c">
                              <input type="checkbox" id="checkbox-D5" checked={d5} onChange={Frequencia} />
                              <span className="details">Quinta - Feira</span>
                            </div>

                            <div className="input-c">
                              <input type="checkbox" id="checkbox-D6" checked={d6} onChange={Frequencia} />
                              <span className="details">Sexta - Feira</span>
                            </div>

                            <div className="input-c">
                              <input type="checkbox" id="checkbox-D7" checked={d7} onChange={Frequencia} />
                              <span className="details">Sábado</span>
                            </div>
                          </div>
                        </div>


                        <div id="content2">

                          <div className="input-box3">
                            <div className="input-c">
                              <input type="checkbox" id="checkbox-pular-sab" checked={pular_sab} onChange={ValidarPular_sab} />
                              <span className="details">Pular Sábado</span>
                            </div>

                            <div className="input-c">
                              <input type="checkbox" id="checkbox-pular-dom" checked={pular_dom} onChange={ValidarPular_dom} />
                              <span className="details">Pular Domingo</span>
                            </div>
                          </div>
                        </div>

                        <div id="content3">

                          <div className="input-box3">
                            <div className="input-c">
                              <input type="checkbox" id="checkbox-notificacao" checked={notificacao} onChange={ValitarNotificacao} />
                              <span className="details">Notificação</span>
                            </div>

                            <div className="input-c">
                              <input type="checkbox" id="checkbox-email" checked={email} onChange={ValitarEMail} />
                              <span className="details">E-Mail</span>
                            </div>


                          </div>
                        </div>
                      </div>



                      {frequencia10 === 'S' ?
                        <PersonalizadoData frequencia={frequencia} personalizado2func={personalizado2func} dataIncial={dataIncial} dados={dadosPersonalizado2} />
                        : null}


                    </TabPanel>



                    <TabPanel>





                      <div className="container-cad-prod4">
                        <div id="content1">
                          <div className="select-box-ativos-select">
                            <div className='details-flex'>
                              <span className="details">Contrato</span>
                            </div>
                            <Select
                             maxMenuHeight={200}
                              placeholder={'Selecione...'}
                              options={optionContract}
                              styles={customStyles}
                              value={optionContract.filter(function (option: any) {
                                return option.value === contract;
                              })}
                              onChange={(options: any) =>
                                !options ? IncluirContrato("") : IncluirContrato(options.value)
                              }
                              isClearable
                              noOptionsMessage={() => "Não encontrado..."}
                              theme={(theme: any) => ({
                                ...theme,
                                borderRadius: 4,
                                colors: {
                                  ...theme.colors,
                                  primary25: '#ebebeb',
                                  primary: 'black',
                                },
                              })}
                            />
                          </div>
                        </div>

                        <div id="content2">

                          <div className="select-box-ativos-select">
                            <div className='details-flex'>
                              <span className="details">Unidade</span>
                            </div>

                            <Select
                              maxMenuHeight={200}
                              placeholder={'Selecione...'}
                              options={optionUnity}
                              styles={customStyles}
                              value={optionUnity.filter(function (option: any) {
                                return option.value === unity;
                              })}
                              onChange={(options: any) =>
                                !options ? IncluirUnity("") : IncluirUnity(options.value)
                              }
                              isClearable
                              noOptionsMessage={() => "Não encontrado..."}
                              theme={(theme: any) => ({
                                ...theme,
                                borderRadius: 4,
                                colors: {
                                  ...theme.colors,
                                  primary25: '#ebebeb',
                                  primary: 'black',
                                },
                              })}
                            />
                          </div>
                        </div>
                      </div>

                      {entidade === '1' ?
                        <div className="container-cad-prod4">
                          <div id="content1">
                            <div className="select-box-ativos-select">
                              <span className="details">Pavimento</span>
                              <Select
                                maxMenuHeight={200}
                                placeholder={'Selecione...'}
                                options={optionspavement}
                                styles={customStyles}
                                value={optionspavement.filter(function (option: any) {
                                  return option.value === pavement;
                                })}
                                onChange={(options: any) =>
                                  !options ? IncluirLocais("") : IncluirLocais(options.value)
                                }
                                isClearable
                                noOptionsMessage={() => "Não encontrado..."}
                                theme={(theme: any) => ({
                                  ...theme,
                                  borderRadius: 4,
                                  colors: {
                                    ...theme.colors,
                                    primary25: '#ebebeb',
                                    primary: 'black',
                                  },
                                })}
                              />
                            </div>
                          </div>

                          <div id="content2">
                            <div className="select-box-ativos-select">
                              <div className='details-flex'>
                                <span className="details">Local</span>
                              </div>
                              <Select
                               maxMenuHeight={200}
                                placeholder={'Selecione...'}
                                options={optionsDepartment}
                                styles={customStyles}
                                value={optionsDepartment.filter(function (option: any) {
                                  return option.value === loc_id;
                                })}
                                onChange={(options: any) =>
                                  !options ? incluirDepartment("") : incluirDepartment(options.value)
                                }
                                isClearable
                                noOptionsMessage={() => "Não encontrado..."}
                                theme={(theme: any) => ({
                                  ...theme,
                                  borderRadius: 4,
                                  colors: {
                                    ...theme.colors,
                                    primary25: '#ebebeb',
                                    primary: 'black',
                                  },
                                })}
                              />
                            </div>
                          </div>
                        </div>
                        :
                        <div className="container-cad-prod2">
                          <div id="content1">
                            <div className="select-box-ativos-select">
                              <span className="details">Pavimento</span>
                              <Select
                                maxMenuHeight={200}
                                placeholder={'Selecione...'}
                                options={optionspavement}
                                styles={customStyles}
                                value={optionspavement.filter(function (option: any) {
                                  return option.value === pavement;
                                })}
                                onChange={(options: any) =>
                                  !options ? IncluirLocais("") : IncluirLocais(options.value)
                                }
                                isClearable
                                noOptionsMessage={() => "Não encontrado..."}
                                theme={(theme: any) => ({
                                  ...theme,
                                  borderRadius: 4,
                                  colors: {
                                    ...theme.colors,
                                    primary25: '#ebebeb',
                                    primary: 'black',
                                  },
                                })}
                              />
                            </div>
                          </div>

                          <div id="content2">
                            <div className="select-box-ativos-select">
                              <div className='details-flex'>
                                <span className="details">Local</span>
                              </div>
                              <Select
                               maxMenuHeight={200}
                                placeholder={'Selecione...'}
                                options={optionsDepartment}
                                styles={customStyles}
                                value={optionsDepartment.filter(function (option: any) {
                                  return option.value === loc_id;
                                })}
                                onChange={(options: any) =>
                                  !options ? incluirDepartment("") : incluirDepartment(options.value)
                                }
                                isClearable
                                noOptionsMessage={() => "Não encontrado..."}
                                theme={(theme: any) => ({
                                  ...theme,
                                  borderRadius: 4,
                                  colors: {
                                    ...theme.colors,
                                    primary25: '#ebebeb',
                                    primary: 'black',
                                  },
                                })}
                              />
                            </div>
                          </div>

                          <div id="content3">
                                            <div className="select-box-ativos-select">
                                                <span className="details">Ativo *</span>
                                                <Select
                                                    maxMenuHeight={200}
                                                    placeholder={'Selecione...'}
                                                    options={optionAtivo}
                                                    styles={customStyles}
                                                    value={optionAtivo.filter(function (option: any) {
                                                        return option.value === ast_id;
                                                    })}
                                                    onChange={(options: any) =>
                                                        !options ? setAst_id("") : setAst_id(options.value)
                                                    }
                                                    isClearable
                                                    noOptionsMessage={() => "Não encontrado..."}
                                                    theme={(theme: any) => ({
                                                        ...theme,
                                                        borderRadius: 4,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#ebebeb',
                                                            primary: 'black',
                                                        },
                                                    })}
                                                />
                                            </div>
                                        </div>
                        </div>
                      }

                    </TabPanel>

                    <div className="Bt-button-cad">
                      <button type="submit" onClick={salvar}>
                        <i className="fa fa-check-circle icon-ok-btn"></i> Salvar
                      </button>
                      <button type="submit" onClick={cancelar}>
                        <i className="fa fa-times icon-cancelar-btn"></i> Cancelar
                      </button>
                    </div>

                  </div>
                </div>
              </div>
            </Tabs>
          </div>
        </main>
      </div>

      <ModalAlert isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)}>
        <div className='container-modal-alert'>
          {qualAlert}
        </div>
      </ModalAlert>

    </>
  );

}

export default InventarioVisualizacao;