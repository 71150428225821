import React, { useEffect, useState, useRef } from 'react'
import Nav from "../../components/Nav";
import Header from "../../components/Header";
import Papa from 'papaparse';
import './index.css';
import GridImportacao from '../../components/GridImportacao'
import { AxiosResponse } from "axios";
import GridSecundarioModelos from '../../components/GridSecundarioModelos'
import FormModeloImportacao from '../../components/FormModeloImportacao'
import FormLogExecucao from '../../components/FormLogExecucao'
import Loading from '../../components/Loading';
import axios from 'axios';
import secureLocalStorage from "react-secure-storage";
import ModalPrincipal from '../../components/ModalAlert/modal'
import { useNavigate } from 'react-router';
import ModalIncluirNovo from '../../components/ModalIncluirNovo/modal'
import api from "../../Services/api";
import ExportExcel from '../../components/ExportToExcel';
import Select from 'react-select'
import * as XLSX from 'xlsx'; // Importa a biblioteca xlsx
import { parse } from 'json2csv';

function ExportarData() {

    const navigate = useNavigate();

    const [qualTitulo, setQualTitulo] = useState(<></>)
    const [qualGrid, setQualGrid] = useState(<></>)
    const [exibirGrid, setExibirGrid] = useState(<></>);
    const [dataCSV, setDataCSV] = useState<object[]>([]);
    const [nomeCSV, setNomeCSV] = useState([]);
    const [propriedadeCSV, setPropriedadeCSV] = useState<string[]>([]);
    const [gridImportacao, setGridImportacao] = useState(<></>)
    const [openModal, setOpenModal] = useState(false)
    const [qualAlert, setQualAlert] = useState(<></>)
    const [openModalIncluirNovo, setOpenModalIncluirNovo] = useState(false)
    const inputFileRef = useRef<HTMLInputElement>(null);

    const [optionsModelo, setOptionsModelo] = useState([])
    const [ipm_id, setIpm_id] = useState('')

    useEffect(() => {

        setQualAlert(<div className='div-alert'><Loading /></div>)
        setOpenModal(true)


        validatToken()

    }, []);




    async function validatToken() {


        let ret: AxiosResponse;
        let valor: any;

        const _user = secureLocalStorage.getItem('t')

        api.defaults.headers.common['Authorization'] = _user as ''

        try {

            ret = await api.get("/status/validar")

            if (ret.data.status === 200) {


                await GetModelo()

                setGridImportacao(<GridImportacao
                    th1='Nome do arquivo'
                    th2='Status'
                    th3='Origem'
                    th4='Quem'
                    th5='Agendamento em'
                    th6='Importado em'
                    th7='Log de execução'
                    endPoint='importregistration/listar'
                    col1='1'
                    col2='4'
                    col3='5'
                    col4='8'
                    col5='3'
                    col6='6'
                    fromAdd='/task/add'
                    fromEdit='/task/edit'
                    btnDelete='S'
                    VisualizarLog={visualizarLog}
                    Atualizar={Atualizar}
                />)

                setOpenModal(false)
            }

        } catch (e: any) {

            if (e.response.data.status === 403) {

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
                    <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)

            }
        }
    }

    async function GetModelo() {
        let ret: AxiosResponse;
        let valor: any;
        try {
            ret = await api.get("importmodels/listar")
            if (ret.data.status === 200) {

                valor = ret.data.data;
                const arr = [] as any;
                let result = valor;
                result.map((user: any) => {
                    return arr.push({ value: String(user.ipm_id), label: user.ipm_description });
                });
                setOptionsModelo(arr)


            }
        } catch (e) {
            setOptionsModelo([])
        }
    }

    function fecharModal() {
        secureLocalStorage.removeItem('t')
        secureLocalStorage.removeItem('acp')
        setOpenModal(false)
        navigate('/login');

    }


    function ChamarVisualizacao(obj: any) {
        setQualGrid(<><FormModeloImportacao Data={obj} /></>)
    }


    function AbrirModelo(form: any) {
        setQualGrid((<><GridSecundarioModelos Iconeadd={ChamarVisualizacao}
            th1='Ativo'
            th2='Descrição'
            th3='Prefixo do arquivo *'
            th4='Subcategoria'
            th5='Data da Inclusão'
            th6='Data Atualização'
            endPoint='importmodels/listar'
            consult='N'
            col1='1'
            col2='2'
            col3='3'
            ambiente='%'
        /></>)
        )

        setQualTitulo(<><h1 className='modal-tit'>Gerenciar Modelos</h1></>)
        setOpenModalIncluirNovo(true)

    }


    function visualizarLog(obj: any) {

        setQualGrid((<><FormLogExecucao label={obj.irg_executionrecord} /></>)
        )
        setQualTitulo(<><h1 className='modal-tit'>Log de Execução</h1></>)
        setOpenModalIncluirNovo(true)
    }

    function Atualizar() {
        window.location.reload();
    }

    function fecharOpenModalIncluirNovo() {
        setOpenModalIncluirNovo(false)
    }

    const customStyles = {
        control: (provided: any, state: any) => ({
            ...provided,
            background: '#fff',
            minHeight: '30px',
            height: '30px',
            borderColor: state.isFocused ? 'grey' : '#92989e',

        }),


        valueContainer: (provided: any, state: any) => ({
            ...provided,
            height: '30px',
            padding: '0 6px'
        }),

        input: (provided: any, state: any) => ({
            ...provided,
            margin: '0px',
            fontSize: 14,

        }),
        indicatorsContainer: (provided: any, state: any) => ({
            ...provided,
            height: '30px',
        }),
    };

    function gerarPlanilha() {

        if (ipm_id === '1') {
            listarAtivos()
        } else if (ipm_id === '2') {
            listarLocal()
        }
        else if (ipm_id === '3') {
            listarPecas()
        }
        else if (ipm_id === '4') {
            listarTerceiros()
        }
        else if (ipm_id === '5') {
            listarServico()
        }
        else if (ipm_id === '6') {
            listarItens()
        }else if (ipm_id === '7') {
            listarAgent() 
        }else if (ipm_id === '8') {
            listarPlanejador()
        }else if(ipm_id === '9'){
            listarProduto()
        }

    }

    async function listarAtivos() {
        let ret: AxiosResponse;
        let valor: any;
        try {
            ret = await api.get("asset/listaresset")
            if (ret.data.status === 200) {
                valor = ret.data.data;

                const worksheet = XLSX.utils.json_to_sheet(valor);
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, "Ativos");
                XLSX.writeFile(workbook, "Cadastro_Ativos.xlsx");

            }
        } catch (e) {

        }
    }

    async function listarLocal() {
        let ret: AxiosResponse;
        let valor: any;
        try {
            ret = await api.get("local/listarlocal")
            if (ret.data.status === 200) {
                valor = ret.data.data;

                const worksheet = XLSX.utils.json_to_sheet(valor);
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, "Locais");
                XLSX.writeFile(workbook, "Cadastro_Locais.xlsx");

            }
        } catch (e) {

        }
    }


    async function listarPecas() {
        let ret: AxiosResponse;
        let valor: any;
        try {
            ret = await api.get("partmaterial/verificacao")
            if (ret.data.status === 200) {
                valor = ret.data.data;

                const worksheet = XLSX.utils.json_to_sheet(valor);
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, "Peças_Materias");
                XLSX.writeFile(workbook, "Cadastro_Peças_Materias.xlsx");

            }
        } catch (e) {

        }
    }

    async function listarTerceiros() {
        let ret: AxiosResponse;
        let valor: any;
        try {
            ret = await api.get("serviceproviders/listar")
            if (ret.data.status === 200) {
                valor = ret.data.data;

                const worksheet = XLSX.utils.json_to_sheet(valor);
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, "Terceiros");
                XLSX.writeFile(workbook, "Cadastro_Terceiros.xlsx");

            }
        } catch (e) {

        }
    }

    async function listarItens() {
        let ret: AxiosResponse;
        let valor: any;
        try {
            ret = await api.get("item/verificacaoitem")
            if (ret.data.status === 200) {
                valor = ret.data.data;

                const worksheet = XLSX.utils.json_to_sheet(valor);
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, "Itens");
                XLSX.writeFile(workbook, "Cadastro_Itens.xlsx");

            }
        } catch (e) {

        }
    }

    async function listarServico() {
        let ret: AxiosResponse;
        let valor: any;
        try {
            ret = await api.get("services/verificacao")
            if (ret.data.status === 200) {
                valor = ret.data.data;

                const worksheet = XLSX.utils.json_to_sheet(valor);
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, "Serviços");
                XLSX.writeFile(workbook, "Cadastro_Serviços.xlsx");

            }
        } catch (e) {

        }
    }

    async function listarAgent() {
        let ret: AxiosResponse;
        let valor: any;
        try {
            ret = await api.get("user/verificacao")
            if (ret.data.status === 200) {
                valor = ret.data.data;

                const worksheet = XLSX.utils.json_to_sheet(valor);
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, "Usuarios");
                XLSX.writeFile(workbook, "Cadastro_Usuarios.xlsx");

            }
        } catch (e) {

        }
    }

    async function listarPlanejador() {
        let ret: AxiosResponse;
        let valor: any;
        try {
            ret = await api.get("planner/listar")
            if (ret.data.status === 200) {
                valor = ret.data.data;

                const worksheet = XLSX.utils.json_to_sheet(valor);
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, "Planejador");
                XLSX.writeFile(workbook, "Cadastro_Planejador.xlsx");

            }
        } catch (e) {

        }
    }

    async function listarProduto() {
        let ret: AxiosResponse;
        let valor: any;
        try {
            ret = await api.get("product/listar")
            if (ret.data.status === 200) {
                valor = ret.data.data;

                const worksheet = XLSX.utils.json_to_sheet(valor);
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, "Produtos");
                XLSX.writeFile(workbook, "Cadastro_Produtos.xlsx");

            }
        } catch (e) {

        }
    }

    return (
        <>
            <Nav />
            <div className="main-content">
                <Header />
                <main>
                    <div className='containerPrincipal'>

                        <div className="title-Page">
                            <div className="title-text">
                                <div className="size-x circle">1</div>
                                <h2>Exportar dados</h2>
                            </div>
                            <hr />
                        </div>

                        <div className='ContainerBodyComple'>

                            <div className='import-div2'>
                                <div className="select-box-ativos-select">
                                    <div className='details-flex'>
                                        <span className="details">Cadastro</span>
                                    </div>
                                    <Select
                                        placeholder={'Selecione...'}
                                        options={optionsModelo}
                                        styles={customStyles}
                                        value={optionsModelo.filter(function (option: any) {
                                            return option.value === ipm_id;
                                        })}
                                        onChange={(options: any) =>
                                            !options ? setIpm_id("") : setIpm_id(options.value)
                                        }
                                        isClearable
                                        noOptionsMessage={() => "Não encontrado..."}
                                        theme={(theme: any) => ({
                                            ...theme,
                                            borderRadius: 4,
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#ebebeb',
                                                primary: 'black',
                                            },
                                        })}
                                    />
                                </div>
                            </div>

                            <button className='dropOpcao-import' onClick={AbrirModelo}>
                                <a>Gerenciar Modelos</a>
                            </button>
                        </div>

                        <div className="Bt-button-cad-imp">


                            <button onClick={gerarPlanilha}>Exportar Planilha</button>

                        </div>

                        <div className="title-Page">
                            <div className="title-text">
                                <div className="size-x circle">2</div>
                                <h2>Lista de exportação</h2>
                            </div>
                            <hr />
                        </div>

                        {gridImportacao}


                    </div>
                </main>
            </div>
            <ModalIncluirNovo isOpen={openModalIncluirNovo} setModalOpen={() => setOpenModalIncluirNovo(!openModalIncluirNovo)}>
                <div className='title-modal-incluir'>
                    <h1 className="modal-IncluirNovo-title">Gerenciar Modelos</h1>
                    <div onClick={fecharOpenModalIncluirNovo}>
                        <button className="fa fa-times icone-modal-IncluirNovo" data-dismiss="modal" aria-label="Close"></button>
                    </div>
                </div>
                <div className='scrol-modal'>

                    {qualGrid}

                </div>
            </ModalIncluirNovo>




            <ModalPrincipal isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)}>
                <div className='container-modal-alert'>
                    {qualAlert}
                </div>
            </ModalPrincipal>




        </>
    );

}

export default ExportarData;