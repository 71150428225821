import React, { useState, useEffect } from 'react'
import './index.css'
import { AxiosResponse } from "axios";
import api from '../../Services/api'
import { MdOutlineAddCircle } from 'react-icons/md';
import Select from 'react-select'
import ModalAlert from '../../components/ModalAlert/modal'
import secureLocalStorage from "react-secure-storage";
import Loading from '../../components/Loading';
import { useNavigate } from 'react-router';

function FormUnidade(props: any) {

    const navigate = useNavigate();
    const dadosFab = (sessionStorage.getItem("dadosUni") || '{}');
    let userUni = JSON.parse(dadosFab);
    const [id, setId] = useState(userUni.unt_id || '')
    const [active, setActive] = useState(userUni.unt_active != undefined ? Boolean(userUni.unt_active) : true)
    const [unity, setUnity] = useState(userUni.unt_description || '')
    const [alternativeIdentifier, setAlternativeIdentifier] = useState(userUni.unt_alternativeidentifier || '')
    const [unityIni, setUnityIni] = useState(userUni.unt_description || '')
    const [alternativeIdentifierIni, setAlternativeIdentifierIni] = useState(userUni.unt_alternativeidentifier || '')
    const [contract, setContract] = useState(String(userUni.ctc_id || ''))
    const [country, setCountry] = useState(userUni.unt_country || '')
    const [state, setState] = useState(userUni.unt_state || '')
    const [county, setCounty] = useState(userUni.unt_city || '')
    const [district, setDistrict] = useState(userUni.unt_cityneighborhood || '')
    const [streetType, setStreetType] = useState(userUni.unt_streettype || '')
    const [street, setStreet] = useState(userUni.unt_street || '')
    const [number, setNumber] = useState(userUni.unt_streetnumber || '')
    const [streetComplement, setStreetComplement] = useState(userUni.unt_streetcomplement || '')
    const [zipCode, setZipCode] = useState(userUni.unt_zipcode || '')
    const [gps, setGps] = useState(userUni.unt_gps || '')
    const [dadosContrato, setDadosContrato] = useState([]);
    const [carregaContrato, setCarregaContrato] = useState();
    const [isDisabledctc_id, setIsDisabledctc_id] = useState(false)

    const [openModal, setOpenModal] = useState(false)
    const [qualAlert, setQualAlert] = useState(<></>)

    const [optionsUnidade, setOptionsUnidade] = useState([])

    useEffect(() => {

      

        validatToken();

    }, []);


    async function validatToken() {

        let ret: AxiosResponse;
        let valor: any;

        const _user = secureLocalStorage.getItem('t')

        api.defaults.headers.common['Authorization'] = _user as ''

        try {

            ret = await api.get("/status/validar")

            if (ret.data.status === 200) {

                secureLocalStorage.setItem('t', ret.data.data);
                secureLocalStorage.setItem('acp', ret.data.decodedToken.acp_integrationid);
                secureLocalStorage.setItem('n', ret.data.decodedToken.name);
                secureLocalStorage.setItem('iag', ret.data.decodedToken.id);
                secureLocalStorage.setItem('cy', ret.data.decodedToken.company);

                GetUnidade();

                if (userUni.unt_id !== undefined) {
                    GetContrato();
                    setContract(String(userUni.ctc_id || ''))

                 

                    if (props.manual === 'SIM') {
                        setIsDisabledctc_id(true)
                    }

                } else {

                    if (props.manual === 'SIM') {
                   
                        setIsDisabledctc_id(true)
                        setContract(String(props.ambiente))

                        GetContrato();

                    } else {
                    
                        GetContrato();

                        
                        if(props.contract !== undefined){

                            setContract(String(props.contract.split('_')[0] || ''))
                            
                        }else{
                           
                            setContract("")
                        }
                      

                    }

                }



                if (props.contract === null || props.contract === undefined) {
                   
                    setIsDisabledctc_id(false)
                  
                }else{
                    
                    setIsDisabledctc_id(true)
                }


            }
        } catch (e: any) {

            if (e.response.data.status === 403) {

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
                    <div className='btn-alert' onClick={fecharModalToken}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)

            }
        }

    }


    async function GetUnidade() {
        let ret: AxiosResponse;
        let valor: any;

        try {
            ret = await api.get("unity/verificacaounity")
            if (ret.data.status === 200) {
                valor = ret.data.data;

                setOptionsUnidade(valor)

            }
        } catch (e) {

            setOptionsUnidade([])

        }
    }

    function fecharModalToken() {

        secureLocalStorage.removeItem('t')
        secureLocalStorage.removeItem('acp')
        secureLocalStorage.removeItem('n')
        secureLocalStorage.removeItem('iag')
        navigate('/login');
        setOpenModal(false)

    }

    function ConfirmarUnidade() {

        if (id === undefined || id === '') {
            insertUnidade();
        } else {
            UpdateUnidade();
        }
    }

    async function UpdateUnidade() {


        const FiltrarDescricao = optionsUnidade.filter(
            (e: any) => {
                return e.unt_description.toLowerCase() === unity.toLowerCase()
            }
        );

        const FiltrarAlternativeIdentifier = optionsUnidade.filter(
            (e: any) => {
                return e.unt_alternativeidentifier.toLowerCase() === alternativeIdentifier.toLowerCase()
            }
        );

        if (unityIni.toLowerCase() !== unity.toLowerCase() && FiltrarDescricao.length > 0) {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Descrição já está em uso.</span></div>
                <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
            </div>)
            setOpenModal(true)
        } else
            if (unity === "") {
                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Digite a descrição.</span></div>
                    <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                </div>)
                setOpenModal(true)
            } else if (alternativeIdentifierIni.toLowerCase() !== alternativeIdentifier.toLowerCase() && FiltrarAlternativeIdentifier.length > 0) {

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Identificador alternativo já está em uso.</span></div>
                    <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                </div>)
                setOpenModal(true)
            }
            else
                if (alternativeIdentifier === "") {
                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Digite o identificador alternativo.</span></div>
                        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                    </div>)
                    setOpenModal(true)
                } else if (contract === "" || contract === "Selecione...") {

                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Selecione o contrato.</span></div>
                        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                    </div>)
                    setOpenModal(true)

                } else {

                    let ret: AxiosResponse;
                    const dados = {
                        unt_id: id,
                        unt_active: active,
                        unt_description: unity,
                        ctc_id: contract,
                        unt_alternativeidentifier: alternativeIdentifier,
                        unt_country: country,
                        unt_state: state,
                        unt_city: county,
                        unt_cityneighborhood: district,
                        unt_streettype: streetType,
                        unt_street: street,
                        unt_streetnumber: number,
                        unt_streetcomplement: streetComplement,
                        unt_zipcode: zipCode,
                        unt_gps: gps
                    }

                    try {
                        ret = await api.put("unity/update", {
                            data: dados,
                        });
                        if (ret.data.status === 200) {
                            props.voltar()
                        }
                    } catch (e: any) {
                        setQualAlert(<div className='div-alert'>
                            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                            <div className='div-info-alert'><span className="info-alert">Não foi possível concluir a alteração.</span></div>
                            <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                        </div>)
                        setOpenModal(true)

                    }
                }

    }

    async function insertUnidade() {
        let ret: AxiosResponse;


        const FiltrarDescricao = optionsUnidade.filter(
            (e: any) => {
                return e.unt_description.toLowerCase() === unity.toLowerCase()
            }
        );

        const FiltrarAlternativeIdentifier = optionsUnidade.filter(
            (e: any) => {
                return e.unt_alternativeidentifier.toLowerCase() === alternativeIdentifier.toLowerCase()
            }
        );

        if (FiltrarDescricao.length > 0) {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Descrição já está em uso.</span></div>
                <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
            </div>)
            setOpenModal(true)
        } else
            if (unity === "") {
                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Digite a descrição.</span></div>
                    <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                </div>)
                setOpenModal(true)
            } else if (FiltrarAlternativeIdentifier.length > 0) {

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Identificador alternativo já está em uso.</span></div>
                    <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                </div>)
                setOpenModal(true)
            }
            else
                if (alternativeIdentifier === "") {
                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Digite o identificador alternativo.</span></div>
                        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                    </div>)
                    setOpenModal(true)
                } else if (contract === "" || contract === "Selecione...") {

                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Selecione o contrato.</span></div>
                        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                    </div>)
                    setOpenModal(true)

                } else {

                    const dados = [{
                        unt_active: active,
                        unt_description: unity,
                        ctc_id: contract,
                        unt_alternativeidentifier: alternativeIdentifier,
                        unt_country: country,
                        unt_state: state,
                        unt_city: county,
                        unt_cityneighborhood: district,
                        unt_streettype: streetType,
                        unt_street: street,
                        unt_streetnumber: number,
                        unt_streetcomplement: streetComplement,
                        unt_zipcode: zipCode,
                        unt_gps: gps
                    }]

                    try {
                        ret = await api.post("unity/insert", {
                            data: dados,
                        });

                        if (ret.data.status === 200) {
                            props.voltar()
                        }
                    } catch (e: any) {
                        setQualAlert(<div className='div-alert'>
                            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                            <div className='div-info-alert'><span className="info-alert">Não foi possivel concluir o cadastramento.</span></div>
                            <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                        </div>)

                        setOpenModal(true)
                    }
                }
    }


    function fecharModal() {
        setOpenModal(false)
    }

    function ValitarActive() {
        let e = document.getElementById("checkbox-active-fab") as HTMLInputElement;

        if (e.checked) {

            setActive(true)
        } else {

            setActive(false)
        }
    }

    function voltar() {

        props.voltar();

    }

    async function GetContrato() {
       
        let ret: AxiosResponse;
        let valor: any;

        try {
            ret = await api.get("contract/listar")
            if (ret.data.status === 200) {
                valor = ret.data.data;


                const arr = [] as any;
                let result = valor;
                result.map((user: any) => {
                    return arr.push({ value: String(user.ctc_id), label: user.ctc_description });
                });
                setDadosContrato(arr)

            } else {
                setDadosContrato([])
            }
        } catch (e) {
       
            setDadosContrato([])
        }
    }

    const rendercontrato = dadosContrato.map((comp: any) => {
        return (
            <option key={comp.ctc_id} value={comp.ctc_id}>
                {" "}
                {comp.ctc_description}{" "}
            </option>
        );
    });


    const customStyles = {
        control: (provided: any, state: any) => ({
            ...provided,
            background: '#fff',
            minHeight: '30px',
            height: '30px',
            borderColor: state.isFocused ? 'grey' : '#92989e',

        }),


        valueContainer: (provided: any, state: any) => ({
            ...provided,
            height: '30px',
            padding: '0 6px'
        }),

        input: (provided: any, state: any) => ({
            ...provided,
            margin: '0px',
            fontSize: 14,

        }),
        indicatorsContainer: (provided: any, state: any) => ({
            ...provided,
            height: '30px',
        }),
    };


    return (
        <>
            <div className="container-modal-pric2">
                <div className="class-checkbox">
                    <input type="checkbox" id="checkbox-active-fab" checked={active} onChange={ValitarActive} />
                    <h4 className="lab-ativo">Ativo</h4>
                </div>

                <div className="container-3-cad">
                    <div id="content1">
                        <div className="input-box-ativos">
                            <span className="details">Descrição *</span>
                            <input
                             autoComplete="off"
                                type="text"
                                onChange={(e) => setUnity(e.target.value)}
                                value={unity} />
                        </div>
                    </div>
                </div>
                <div className="container-cad-prod4">

                    <div id="content1">
                        <div className="input-box-ativos">
                            <span className="details">Identificador Alternativo *</span>
                            <input
                             autoComplete="off"
                                type="text"
                                onChange={(e) => setAlternativeIdentifier(e.target.value)}
                                value={alternativeIdentifier} />
                        </div>
                    </div>
                    <div id="content2">
                        <div className="select-box-ativos-select">

                            {props.manual === 'SIM' ? (
                                <span className="details">Contrato *</span>
                            ) :

                                <div className='details-flex' onClick={props.ChamarCadastroContratoUni}>
                                    <span className="details">Contrato *</span>
                                    <MdOutlineAddCircle className='details-flex-icon-ativo' />
                                    <span className="details-esc-ativo">Incluir Novo</span>
                                </div>

                            }

                            <Select
                                maxMenuHeight={150}
                                placeholder={'Selecione...'}
                                options={dadosContrato}
                                styles={customStyles}
                                isDisabled={isDisabledctc_id}
                                value={dadosContrato.filter(function (option: any) {
                                    return option.value === contract;
                                })}
                                onChange={(options: any) =>
                                    !options ? setContract("") : setContract(options.value)
                                }

                                isClearable
                                noOptionsMessage={() => "Não encontrado..."}
                                theme={(theme: any) => ({
                                    ...theme,
                                    borderRadius: 4,
                                    colors: {
                                        ...theme.colors,
                                        primary25: '#ebebeb',
                                        primary: 'black',
                                    },
                                })}
                            />
                        </div>
                    </div>

                </div>

                <div className="container-cad-prod23">
                    <div id="content1">
                        <div className="input-box-ativos">
                            <span className="details">Coordenada GPS</span>
                            <input
                             autoComplete="off"
                                type="text"
                                onChange={(e) => setGps(e.target.value)}
                                value={gps} />
                        </div>
                    </div>
                    <div id="content2">
                        <div className="input-box-ativos">
                        <span className="details">País</span>
                            <input
                             autoComplete="off"
                                type="text"
                                onChange={(e) => setCountry(e.target.value)}
                                value={country} />
                        </div>
                    </div>
                </div>

                <div className="container-cad-prod2">

                    <div id="content1">
                        <div className="input-box-ativos">
                            <span className="details">Estado</span>
                            <input
                             autoComplete="off"
                                type="text"
                                onChange={(e) => setState(e.target.value)}
                                value={state} />
                        </div>
                    </div>
                    <div id="content2">
                    <div className="input-box-ativos">
                            <span className="details">Município</span>
                            <input
                             autoComplete="off"
                                type="text"
                                onChange={(e) => setCounty(e.target.value)}
                                value={county} />
                        </div>
                    </div>
                    <div id="content3">
                    <div className="input-box-ativos">
                            <span className="details">Bairro</span>
                            <input
                             autoComplete="off"
                                type="text"
                                onChange={(e) => setDistrict(e.target.value)}
                                value={district} />
                        </div>
                    </div>
                </div>

                <div className="container-cad-prod24">

                    <div id="content1">
                    <div className="input-box-ativos">
                            <span className="details">Tipo logradouro</span>
                            <input
                             autoComplete="off"
                                type="text"
                                onChange={(e) => setStreetType(e.target.value)}
                                value={streetType} />
                        </div>
                    </div>
                    <div id="content2">
                    <div className="input-box-ativos">
                            <span className="details">Logradouro</span>
                            <input
                             autoComplete="off"
                                type="text"
                                onChange={(e) => setStreet(e.target.value)}
                                value={street} />
                        </div>
                    </div>
                   
                </div>

                <div className="container-cad-prod2">

                    <div id="content1">
                        <div className="input-box-ativos">
                            <span className="details">Número</span>
                            <input
                             autoComplete="off"
                                type="text"
                                onChange={(e) => setNumber(e.target.value)}
                                value={number} />
                        </div>
                    </div>

                    <div id="content2">
                        <div className="input-box-ativos">
                            <span className="details">Complemento</span>
                            <input
                             autoComplete="off"
                                type="text"
                                onChange={(e) => setStreetComplement(e.target.value)}
                                value={streetComplement} />
                        </div>
                    </div>

                    <div id="content3">
                        <div className="input-box-ativos">
                            <span className="details">CEP</span>
                            <input
                             autoComplete="off"
                                type="text"
                                onChange={(e) => setZipCode(e.target.value)}
                                value={zipCode} />
                        </div>
                    </div>
                </div>

                <div className="Bt-button-cad">
                    <button type="submit" onClick={ConfirmarUnidade}>
                        <i className="fa fa-check-circle icon-ok-btn"></i> Salvar
                    </button>
                    <button type="submit" onClick={props.voltar}>
                        <i className="fa fa-times icon-cancelar-btn"></i> Cancelar
                    </button>
                </div>
            </div>

            <ModalAlert isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)}>
                <div className='container-modal-alert'>
                    {qualAlert}
                </div>
            </ModalAlert>
        </>
    );

}

export default FormUnidade;