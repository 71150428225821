import React, { useState, useEffect } from 'react'
import './index.css'
import { AxiosResponse } from "axios";
import api from '../../Services/api'
import ModalPrincipal from '../ModalAlert/modal'
import Loading from '../Loading';
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from 'react-router';

function FormTipoServico(props: any) {

    const navigate = useNavigate();

    const [openModal, setOpenModal] = useState(false)
    const [qualAlert, setQualAlert] = useState(<></>)

    const [id, setId] = useState(props.obj.tsp_id || '')
    const [active, setActive] = useState(props.obj.tsp_active != undefined ? Boolean(props.obj.tsp_active) : true)
    const [description, setDescription] = useState(props.obj.tsp_description || '')
    const [alternativeIdentifier, setAlternativeIdentifier] = useState(props.obj.tsp_alternativeidentifier || '')
    const [svp_id, setSvp_id] = useState(props.obj.svp_id || '')
    const [descriptionIni, setDescriptionIni] = useState(props.obj.tsp_description || '')
    const [alternativeIdentifierIni, setAlternativeIdentifierIni] = useState(props.obj.tsp_alternativeidentifier || '')


    const [optionsagentgroup, setOptionsagentgroup] = useState([])

    useEffect(() => {

        validatToken();

    }, []);

    async function validatToken() {

        let ret: AxiosResponse;
        let valor: any;


        const _user = secureLocalStorage.getItem('t')

        api.defaults.headers.common['Authorization'] = _user as ''

        try {

            ret = await api.get("/status/validar")

            if (ret.data.status === 200) {

                secureLocalStorage.setItem('t', ret.data.data);
                secureLocalStorage.setItem('acp', ret.data.decodedToken.acp_integrationid);
                secureLocalStorage.setItem('n', ret.data.decodedToken.name);
                secureLocalStorage.setItem('iag', ret.data.decodedToken.id);
                secureLocalStorage.setItem('cy', ret.data.decodedToken.company);
                Getagentgroup();
                setOpenModal(false)
            }

        } catch (e: any) {

            if (e.response.data.status === 403) {

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
                    <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)

            }
        }

    }

    function fecharModal() {

        secureLocalStorage.removeItem('t')
        secureLocalStorage.removeItem('acp')
        secureLocalStorage.removeItem('n')
        secureLocalStorage.removeItem('iag')
        navigate('/login');
        setOpenModal(false)

    }

    async function Getagentgroup() {
        let ret: AxiosResponse;
        let valor: any;
        try {
        ret = await api.get("typeserviceproviders/verificacao", {
          params: {
            id: props.id
          }
        })
            if (ret.data.status === 200) {
                valor = ret.data.data;

                setOptionsagentgroup(valor)

            }
        } catch (e) {
            setOptionsagentgroup([])
        }
    }

    function finalizarModal2() {
        setOpenModal(false)
    }

    async function ConfirmarTipoAgente() {

        let ret: AxiosResponse;
        let valor: any;

        const _user = secureLocalStorage.getItem('t')


        api.defaults.headers.common['Authorization'] = _user as ''

        try {

            ret = await api.get("/status/validar")

            if (ret.data.status === 200) {

                if (id === undefined || id === '') {
                    insertTipoAgente();

                } else {
                    UpdateTipoAgente();
                }

            }

        } catch (e: any) {

            if (e.response.data.status === 403) {

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
                    <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)

            }
        }
    }

    async function UpdateTipoAgente() {

        setQualAlert(<div className='div-alert'><Loading /></div>)
        setOpenModal(true)

        const Filtrardescription = optionsagentgroup.filter(
            (e: any) => {
                return e.tsp_description.toLowerCase() === description.toLowerCase()
            }
        );

        const FiltraralternativeIdentifier = optionsagentgroup.filter(
            (e: any) => {
                return e.tsp_alternativeidentifier.toLowerCase() === alternativeIdentifier.toLowerCase()
            }
        );

        if (descriptionIni.toLowerCase() !== description.toLowerCase() && Filtrardescription.length > 0) {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">A descrição já está em uso.</span></div>
                <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
            </div>)


        } else
            if (alternativeIdentifierIni.toLowerCase() !== alternativeIdentifier.toLowerCase() && FiltraralternativeIdentifier.length > 0) {

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">O identificador alternativo já está em uso.</span></div>
                    <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                </div>)

            } else
                if (description === "") {

                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Digite a descrição.</span></div>
                        <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                    </div>)

                } else
                    if (alternativeIdentifier === "") {

                        setQualAlert(<div className='div-alert'>
                            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                            <div className='div-info-alert'><span className="info-alert">Digite o identificador alternativo.</span></div>
                            <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                        </div>)

                    } else {

                        let ret: AxiosResponse;
                        
                        const dados = {
                            tsp_id: id,
                            tsp_active: active,
                            tsp_description: description,
                            tsp_alternativeidentifier: alternativeIdentifier,
                            svp_id: svp_id,
                            age_id_lastupdate: secureLocalStorage.getItem('iag'),
                        }

                        try {
                            ret = await api.put("typeserviceproviders/update", {
                                data: dados,
                            });
                            if (ret.data.status === 200) {
                                props.salvarTipo()
                            }
                        } catch (e: any) {

                            setQualAlert(<div className='div-alert'>
                                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                                <div className='div-info-alert'><span className="info-alert">Não foi possível concluir a alteração.</span></div>
                                <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                            </div>)

                        }
                    }

    }

    async function insertTipoAgente() {
        let ret: AxiosResponse;

        setQualAlert(<div className='div-alert'><Loading /></div>)
        setOpenModal(true)

 

        const Filtrardescription = optionsagentgroup.filter(
            (e: any) => {
                return e.tsp_description.toLowerCase() === description.toLowerCase()
            }
        );

        const FiltraralternativeIdentifier = optionsagentgroup.filter(
            (e: any) => {
                return e.tsp_alternativeidentifier.toLowerCase() === alternativeIdentifier.toLowerCase()
            }
        );

        if (Filtrardescription.length > 0) {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">A descrição já está em uso.</span></div>
                <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
            </div>)


        } else
            if (FiltraralternativeIdentifier.length > 0) {

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">O identificador alternativo já está em uso.</span></div>
                    <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                </div>)

            } else
                if (description === "") {

                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Digite a descrição.</span></div>
                        <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                    </div>)

                } else
                    if (alternativeIdentifier === "") {

                        setQualAlert(<div className='div-alert'>
                            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                            <div className='div-info-alert'><span className="info-alert">Digite o identificador alternativo.</span></div>
                            <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                        </div>)

                    } else {

                        const dados = [{
                            tsp_active: active,
                            tsp_description: description,
                            tsp_alternativeidentifier: alternativeIdentifier,
                            svp_id: props.id,
                            age_id_insert: secureLocalStorage.getItem('iag'),
                        }]

                        try {
                            ret = await api.post("typeserviceproviders/insert", {
                                data: dados,
                            });

                            if (ret.data.status === 200) {
                                props.salvarTipo()
                            }
                        } catch (e: any) {

                            setQualAlert(<div className='div-alert'>
                                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                                <div className='div-info-alert'><span className="info-alert">Não foi possível concluir o cadastramento.</span></div>
                                <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                            </div>)

                        }
                    }
    }

    function ValitarActive() {
        let e = document.getElementById("checkbox-active-fab") as HTMLInputElement;

        if (e.checked) {

            setActive(true)
        } else {

            setActive(false)
        }
    }

    function voltar() {

        props.voltar();

    }

    return (
        <>
            <div className="container-modal-pric">
                <div className="class-checkbox">
                    <input type="checkbox" id="checkbox-active-fab" checked={active} onChange={ValitarActive} />
                    <h4 className="lab-ativo">Ativo</h4>
                </div>
                <div className="container-cad-prod4">
                    <div id="content1">
                        <div className="input-box-ativos">
                            <span className="details">Descrição *</span>
                            <input
                                type="text"
                                onChange={(e) => setDescription(e.target.value)}
                                value={description}
                                autoComplete="off"
                            />
                        </div>
                    </div>
                    <div id="content2">
                        <div className="input-box-ativos">
                            <span className="details">Identificador Alternativo *</span>
                            <input
                                autoComplete="off"
                                type="text"
                                onChange={(e) => setAlternativeIdentifier(e.target.value)}
                                value={alternativeIdentifier} />
                        </div>
                    </div>
                </div>
                <div className="Bt-button-cad">
                    <button type="submit" onClick={ConfirmarTipoAgente}>
                        <i className="fa fa-check-circle icon-ok-btn"></i> Salvar
                    </button>
                    <button type="submit" onClick={props.voltar}>
                        <i className="fa fa-times icon-cancelar-btn"></i> Cancelar
                    </button>
                </div>
            </div>

            <ModalPrincipal isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)}>
                <div className='container-modal-alert'>
                    {qualAlert}
                </div>
            </ModalPrincipal>

        </>
    );

}

export default FormTipoServico;