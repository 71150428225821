import React from 'react';
import  secureLocalStorage  from  "react-secure-storage";
import {Navigate, Outlet} from 'react-router-dom'


const UseAuth=()=>{
 
  const user=secureLocalStorage.getItem('t')
 
  if(user){

    return true

  } else {

    return false

  }
}

const  PublicRoutes=(props:any) =>{

  const auth=UseAuth()

  if (auth === true){
    return <Navigate to="/dashboard"/>
  }else{
    return <Outlet/>
  }

  //return auth ? : 

}

export default PublicRoutes;
