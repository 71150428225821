import React, { PureComponent, useState } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
import "./index.css";
import api from "../../Services/api";
import { FcSearch, FcPlus, FcFilledFilter } from "react-icons/fc";
import { Link } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { FaEraser } from "react-icons/fa";
import Select from 'react-select'
import Loading from '../../components/Loading';
import ModalPrincipal from '../../components/ModalAlert/modal'

export class Child extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      token: false,
      Nacesso: false,
      isLoading: true, 
      offset: 0,
      tableData: [],
      orgtableData: [],
      perPage: 11,
      currentPage: 0,
      pagAtual: 1,
      length: 0,
      endpoint: this.props.endPoint,
      clicked: false,
      atualiza: 0,
      search: '',
      isChecked: [],
      consult: this.props.consult,
      forcePage: 0,
      ast_description: '',
      mov_destinyunit: '',
      mov_destinyloc: '',
      mov_type: '',
      ast_id: '',
      clickedmovement: false,
      optionsUnity: [],
      optionsTipo: [{
        value: 'estoque',
        label: 'Em estoque'
      },
      {
        value: 'contrato',
        label: 'Em contrato'
      }]
    };

    this.handlePageClick = this.handlePageClick.bind(this);
  }

  componentDidMount() {
    this.getData();
    this.GetUnity();
  }


  GetUnity() {

    let valor

    api.get(this.props.unity).then((res) => {
      var data = res.data.data;
      if (res.data.status === 200) {

        valor = res.data.data;

        const arr = [];
        let result = valor;
        result.map((user) => {
          return arr.push({ value: String(user.unt_id), label: user.unt_description });
        });

        this.setState({
          optionsUnity: arr
        });

      }

    })
      .catch(function (res) {
        this.setState({
          optionsUnity: []
        });

      });
  }

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
        pagAtual: selectedPage + 1,
        forcePage: selectedPage
      },
      () => {
        this.loadMoreData();
      }
    );


  };

  loadMoreData() {

    const data = this.state.dataT;

    const slice = data.slice(
      this.state.offset,
      this.state.offset + this.state.perPage,

    );


    //////////////////////aqui
    this.setState({
      pageCount: Math.ceil(data.length / this.state.perPage),
      tableData: slice,
      length: data.length,
    });

  }


  handlePageClick_clear = () => {
    const selectedPage = 0;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
        pagAtual: selectedPage + 1,
        forcePage: selectedPage,
      },
      () => {
        this.loadMoreData();
      }
    );


  };


  handleInputChange = (event) => {
    this.setState({ search: event.target.value });
  };

  do_search = () => {

    let dados = [];

    this.handlePageClick_clear()

    this.setState({ isLoading: true });

    if (this.props.pag === "movement") {

      dados.push({
        ast_description: this.state.ast_description,
        mov_destinyunit: this.state.mov_destinyunit,
        mov_type: this.state.mov_type,
        ast_id: this.state.ast_id,
        search: this.state.search,
      })


      api.get(this.props.buscarmovement, {
        params: {
          data: dados
        }
      }).then((res) => {

        var data = res.data.data;

        if (res.data.status === 200) {

          var slice = data.slice(
            this.state.offset,
            this.state.offset + this.state.perPage
          );

          this.setState({
            pageCount: Math.ceil(data.length / this.state.perPage),
            orgtableData: res.data.data,
            tableData: slice,
            dataT: res.data.data,
            length: data.length,
          });

        }

        this.setState({          
          isLoading: false,
        });

      })
        .catch(function (e) {

          const data = [];
          const slice = data.slice(
            this.state.offset,
            this.state.offset + this.state.perPage
          );
  
          this.setState({
            pageCount: Math.ceil(data.length / this.state.perPage),
            orgtableData: [],
            tableData: slice,
            dataT: [],
            length: data.length,
          });

          if (!e.response) {

            this.setState({ isLoading: false });
            this.setState({ Nacesso: true });

          } else if (e.response.status === 403) {
          
            this.setState({ isLoading: false });
            this.setState({ token: true });

          } else {
            
            this.setState({ isLoading: false });
            this.setState({ Nacesso: true });

          }
          
        });

    }



  };

  clear_search = () => {

    window.location.reload();

  };

  getData() {
    let dados = [];

    this.setState({ isLoading: true });

    if (this.props.pag === "movement") {

      dados.push({
        ast_description: '',
        mov_destinyunit: '',
        mov_type: '',
        search: '',
        ast_id: '',
      })

      const _user = secureLocalStorage.getItem('t')

      api.defaults.headers.common['Authorization'] = _user


      api.get(this.props.endPoint, {
        params: {
          data: dados
        }
      }).then((res) => {
        const data = res.data.data || [];

        if (res.data.status === 200) {
          const slice = data.slice(
            this.state.offset,
            this.state.offset + this.state.perPage
          );

          this.setState({
            pageCount: Math.ceil(data.length / this.state.perPage),
            orgtableData: data,
            tableData: slice,
            dataT: data,
            length: data.length,
          });
        } else {
          const slice = data.slice(
            this.state.offset,
            this.state.offset + this.state.perPage
          );

          this.setState({
            pageCount: Math.ceil(data.length / this.state.perPage),
            orgtableData: [],
            tableData: slice,
            dataT: [],
            length: data.length,
          });
        }

        this.setState({
                    
          isLoading: false,
        });

      }).catch((e) => {
    

        const data = [];
        const slice = data.slice(
          this.state.offset,
          this.state.offset + this.state.perPage
        );

        this.setState({
          pageCount: Math.ceil(data.length / this.state.perPage),
          orgtableData: [],
          tableData: slice,
          dataT: [],
          length: data.length,
        });

        if (!e.response) {

          this.setState({ isLoading: false });
          this.setState({ Nacesso: true });

        } else if (e.response.status === 403) {
        
          this.setState({ isLoading: false });
          this.setState({ token: true });

        } else {
          
          this.setState({ isLoading: false });
          this.setState({ Nacesso: true });

        }

        this.setState({
                    
          isLoading: false,
        });
        
      });

    }
  }

  toggle = (index) => {
    if (this.state.clicked === index) {
      //if clicked question is already active, then close it
      return this.setState({ clicked: null });
    }
    this.setState({ clicked: index });
  };

  handlecheckbox = (e) => {
    const { value, checked } = e.target;

    if (checked) {

      this.setState({
        isChecked: ([...this.state.isChecked, value])
      });


    } else {


      this.setState({
        isChecked: (this.state.isChecked.filter((e) => e !== value))
      });


    }

  }

  exibirFiltro = () => {

    if (this.props.pag === "movement") {
      if (this.state.clickedmovement === true) {
        this.setState({
          clickedmovement: false
        });
      } else {
        this.setState({
          clickedmovement: true
        });
      }
    }
  }

  funcNsolicitacao = () => {
    this.setState({ Nacesso: false });
    }

  render() {

    const customStyles = {
      control: (provided, state) => ({
        ...provided,
        background: '#fff',
        minHeight: '30px',
        height: '30px',
        borderColor: state.isFocused ? 'grey' : '#92989e',

      }),


      valueContainer: (provided, state) => ({
        ...provided,
        height: '30px',
        padding: '0 6px'
      }),

      input: (provided, state) => ({
        ...provided,
        margin: '0px',
        fontSize: 14,

      }),
      indicatorsContainer: (provided, state) => ({
        ...provided,
        height: '30px',
      }),
    };

    return (
      <div className="ContainerGrid">
        <div className="ContainerBody">
          <div className="InlineContainery">

            <div className="InputBusca">
              <input
                autoComplete="off"
                placeholder="Pesquisar..."
                onChange={this.handleInputChange}
                value={this.state.search}
              />

              <div className="icone-buscaT" onClick={this.do_search}>
                <FcSearch className="icone-add" />
              </div>

              <div className="icone-buscaT" onClick={this.clear_search}>
                <FaEraser className="icone-add" />
              </div>

              <div className="icone-buscaT" onClick={this.exibirFiltro}>
                <FcFilledFilter className="icone-add" />
              </div>

            </div>
          </div>
          {secureLocalStorage.getItem('acp') === 'DESENVOLVEDOR' || secureLocalStorage.getItem('acp') === 'ADMINISTRADOR' ? (
            <Link to={this.props.fromAdd} className="icone-buscaAdd" >
              <FcPlus className="icone-add" />
            </Link>
          ) : null}
        </div>

        <div className="ContainerDropdown">
          <>
            {this.state.clickedmovement === true ? (

              <div className="container-Dropdown-busca">

                <div className="container-cad-prod6">
                  <div id="content1">
                    <div className="input-box-ativos">
                      <span className="details">ID Ativo</span>
                      <input
                        type="text"
                        onChange={(e) => this.setState({ ast_id: e.target.value })}
                        value={this.state.ast_id}
                      />
                    </div>
                  </div>

                  <div id="content2">
                    <div className="input-box-ativos">
                      <span className="details">Descrição</span>
                      <input
                        type="text"
                        onChange={(e) => this.setState({ ast_description: e.target.value })}
                        value={this.state.ast_description}
                      />
                    </div>
                  </div>

                  <div id="content3">
                    <div className="select-box-ativos-select">
                      <span className="details">Unidade de Destino</span>
                      <Select
                        maxMenuHeight={150}
                        placeholder={'Selecione...'}
                        options={this.state.optionsUnity}
                        styles={customStyles}
                        onChange={(options) =>
                          !options ? this.setState({ mov_destinyunit: '' }) : this.setState({ mov_destinyunit: options.value })
                        }
                        isClearable
                        noOptionsMessage={() => "Não encontrado..."}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 4,
                          colors: {
                            ...theme.colors,
                            primary25: '#ebebeb',
                            primary: 'black',
                          },
                        })}
                      />
                    
                  </div>
                </div>
                <div id="content4">
                  <div className="select-box-ativos-select">
                    <span className="details">Tipo de Movimentação</span>
                    <Select
                      maxMenuHeight={150}
                      placeholder={'Selecione...'}
                      options={this.state.optionsTipo}
                      styles={customStyles}
                      onChange={(options) =>
                        !options ? this.setState({ mov_type: '' }) : this.setState({ mov_type: options.value })
                      }
                      isClearable
                      noOptionsMessage={() => "Não encontrado..."}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 4,
                        colors: {
                          ...theme.colors,
                          primary25: '#ebebeb',
                          primary: 'black',
                        },
                      })}
                    />
                  </div>
                </div>
              </div>

              </div>
            ) : null}
        </>
      </div>

        {
      this.state.pageCount > 0 ? (
        <div className="grid-tab-user">
          <table>
            <tbody>
              <tr id="grid-header">
                <th className="Title">{this.props.th1}</th>
                <th className="Title">{this.props.th2}</th>
                <th className="Title">{this.props.th3}</th>
                <th className="Title">{this.props.th4}</th>

                <th className="Title">{this.props.th6}</th>
              </tr>
            </tbody>
            <tbody>
              {this.state.tableData.map((tdata, i) => (
                <tr className="TR-Coluna" key={Object.values(tdata)[0]}>

                  <td className="Coluna-id">{Object.values(tdata)[this.props.col1]}</td>
                  <td className="Coluna-id">{Object.values(tdata)[this.props.col2]}</td>
                  <td className="Coluna-descricao">{Object.values(tdata)[this.props.col3]}</td>
                  <td className="Coluna">{Object.values(tdata)[this.props.col4]}</td>


                  <td className="Coluna-icone-btn-movi"><h1 className="btn-grid-incl" onClick={() => { this.props.VisualizarMovi(Object(tdata)) }}>Visualizar</h1></td>

                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : null
    }
    <div className="ContainerPag">
      <div className="InlineContainery">

        {this.state.pageCount === null || this.state.pageCount === 0 ? (
          <div className="SemResut">
            <h2>
              Nenhum resultado encontrado.
            </h2>
            <h2>
              Página 1 de 1
            </h2>
          </div>
        ) : null}


        <div className="InputBusca">
          {this.state.pageCount > 0 ? (
            <div className="Quant">
              <h2>
                Página {this.state.pagAtual} de {this.state.pageCount}
              </h2>
            </div>
          ) : null}

          {this.state.pageCount > 1 ? (
            <ReactPaginate
              previousLabel={<i className="fas fa-angle-left"></i>}
              nextLabel={<i className="fas fa-angle-right"></i>}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={this.state.pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={this.handlePageClick}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
              forcePage={this.state.forcePage}
            />
          ) : null}
        </div>
      </div>
      {this.state.pageCount > 0 ? (
        <div className="paginate">
          <h2>Total de registros: {this.state.length}</h2>
        </div>
      ) : null}
    </div>

    <ModalPrincipal isOpen={this.state.isLoading}>
        <div className='container-modal-alert'>
          <div className='div-alert'><Loading /></div>
        </div>
      </ModalPrincipal>
      
      <ModalPrincipal isOpen={this.state.token}>
          <div className='container-modal-alert'>
            <div className='div-alert'>
              <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
              <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
              <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
              <div className='btn-alert' onClick={this.props.fecharModal}><span className="btn-alert-lab">OK</span></div>
            </div>
          </div>
        </ModalPrincipal>

        <ModalPrincipal isOpen={this.state.Nacesso}>
          <div className='container-modal-alert'>
            <div className='div-alert'>
              <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
              <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
              <div className='div-info-alert'><span className="info-alert">Não foi possível concluir sua solicitação.</span></div>
              <div className='btn-alert' onClick={this.funcNsolicitacao}><span className="btn-alert-lab">OK</span></div>
            </div>
          </div>
        </ModalPrincipal>

      </div >
    );
  }
}

export default Child;
