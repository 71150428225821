import React, { useState, useEffect } from 'react'
import './index.css'
import { AxiosResponse } from "axios";
import api from '../../Services/api'
import Select from 'react-select'
import ModalAlert from '../../components/ModalAlert/modal'
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from 'react-router';
import Loading from '../../components/Loading';

function FormDepreciation(props: any) {
    const dadosDpc = (sessionStorage.getItem("dadosDpc") || '{}');
    let userDpc = JSON.parse(dadosDpc);

    const navigate = useNavigate();
    const [id, setId] = useState(userDpc.dpc_id || '')
    const [active, setActive] = useState(userDpc.dpc_active != undefined ? Boolean(userDpc.dpc_active) : true)
    const [description, setDescription] = useState(userDpc.dpc_description || '')
    const [alternativeIdentifier, setAlternativeIdentifier] = useState(userDpc.dpc_alternativeidentifier || '')
    const [descriptionIni, setDescriptionIni] = useState(userDpc.dpc_description || '')
    const [alternativeIdentifierIni, setAlternativeIdentifierIni] = useState(userDpc.dpc_alternativeidentifier || '')
    const [depreciationMethod, setDepreciationMethod] = useState(String(userDpc.dmt_id) || '')
    const [months, setMonths] = useState(userDpc.dpc_months || '')
    const [openModal, setOpenModal] = useState(false)
    const [qualAlert, setQualAlert] = useState(<></>)

    const [optionDepreciationMethod, setOptionDepreciationMethod] = useState([])
    const [optionsDepreciation, setOptionsDepreciation] = useState([])

    useEffect(() => {

        validatToken();

    }, []);


    function validarNumber(number: any) {
        var valor = number

        valor = valor + '';
        valor = parseInt(valor.replace(/[\D]+/g, ''));
        valor = valor + '';

        if (valor == 'NaN') {
            setMonths('')
        } else {
            setMonths(valor)

        }

    }


    async function validatToken() {

        let ret: AxiosResponse;
        let valor: any;

        const _user = secureLocalStorage.getItem('t')

        api.defaults.headers.common['Authorization'] = _user as ''

        try {

            ret = await api.get("/status/validar")

            if (ret.data.status === 200) {

                secureLocalStorage.setItem('t', ret.data.data);
                secureLocalStorage.setItem('acp', ret.data.decodedToken.acp_integrationid);
                secureLocalStorage.setItem('n', ret.data.decodedToken.name);
                secureLocalStorage.setItem('iag', ret.data.decodedToken.id);
                secureLocalStorage.setItem('cy', ret.data.decodedToken.company);

                GetDepreciationMethod();
                GetDepreciation();

            }
        } catch (e: any) {

            if (e.response.data.status === 403) {

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
                    <div className='btn-alert' onClick={fecharModalToken}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)

            }
        }

    }

    async function GetDepreciation() {
        let ret: AxiosResponse;
        let valor: any;

        try {
            ret = await api.get("depreciation/verificacaodepreciation")
            if (ret.data.status === 200) {
                valor = ret.data.data;

                setOptionsDepreciation(valor)

            }
        } catch (e) {
            setOptionsDepreciation([])

        }
    }

    function fecharModalToken() {

        secureLocalStorage.removeItem('t')
        secureLocalStorage.removeItem('acp')
        secureLocalStorage.removeItem('n')
        secureLocalStorage.removeItem('iag')
        navigate('/login');
        setOpenModal(false)

    }

    function fecharModal() {
        setOpenModal(false)
    }

    function ConfirmarDepreciation() {

        setOpenModal(true)
        setQualAlert(<div className='div-alert'><Loading /></div>)

        if (id === undefined || id === '') {
            insertDepreciation();

        } else {
            UpdateDepreciation();

        }
    }

    async function UpdateDepreciation() {
        let ret: AxiosResponse;

        const FiltrarDescricao = optionsDepreciation.filter(
            (e: any) => {
                return e.dpc_description.toLowerCase() === description.toLowerCase()
            }
        );

        const FiltrarAlternativeIdentifier = optionsDepreciation.filter(
            (e: any) => {
                return e.dpc_alternativeidentifier.toLowerCase() === alternativeIdentifier.toLowerCase()
            }
        );



        if (description === "") {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Digite a descrição.</span></div>
                <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
            </div>)
            setOpenModal(true)

        } else if (descriptionIni.toLowerCase() !== description.toLowerCase() && FiltrarDescricao.length > 0) {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Descrição já está em uso.</span></div>
                <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
            </div>)
            setOpenModal(true)
        } else
            if (alternativeIdentifier === "") {

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Digite o identificador alternativo.</span></div>
                    <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                </div>)
                setOpenModal(true)
            } else if (alternativeIdentifierIni.toLowerCase() !== alternativeIdentifier.toLowerCase() && FiltrarAlternativeIdentifier.length > 0) {

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Identificador alternativo já está em uso.</span></div>
                    <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                </div>)
                setOpenModal(true)
            } else
                if (depreciationMethod === "" || depreciationMethod === undefined || depreciationMethod === 'undefined') {

                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Selecione o método de depreciação.</span></div>
                        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                    </div>)
                    setOpenModal(true)
                } else
                    if (months === "") {

                        setQualAlert(<div className='div-alert'>
                            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                            <div className='div-info-alert'><span className="info-alert">Digite a quantidade de meses.</span></div>
                            <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                        </div>)

                        setOpenModal(true)
                    } else {
                        const dados = {
                            dpc_id: id,
                            dpc_active: active,
                            dpc_description: description,
                            dpc_alternativeidentifier: alternativeIdentifier,
                            dmt_id: depreciationMethod,
                            dpc_months: months
                        }

                        try {
                            ret = await api.put("depreciation/update", {
                                data: dados,
                            });
                            if (ret.data.status === 200) {
                                props.voltar()
                            }
                        } catch (e: any) {

                            setQualAlert(<div className='div-alert'>
                                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                                <div className='div-info-alert'><span className="info-alert">Não foi possível concluir a alteração.</span></div>
                                <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                            </div>)
                            setOpenModal(true)

                        }
                    }

    }

    async function insertDepreciation() {
        let ret: AxiosResponse;

        const FiltrarDescricao = optionsDepreciation.filter(
            (e: any) => {
                return e.dpc_description.toLowerCase() === description.toLowerCase()
            }
        );

        const FiltrarAlternativeIdentifier = optionsDepreciation.filter(
            (e: any) => {
                return e.dpc_alternativeidentifier.toLowerCase() === alternativeIdentifier.toLowerCase()
            }
        );


        if (description === "") {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Digite a descrição.</span></div>
                <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
            </div>)
            setOpenModal(true)

        } else if (FiltrarDescricao.length > 0) {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Descrição já está em uso.</span></div>
                <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
            </div>)
            setOpenModal(true)
        } else
            if (alternativeIdentifier === "") {

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Digite o identificador alternativo.</span></div>
                    <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                </div>)
                setOpenModal(true)
            } else if (FiltrarAlternativeIdentifier.length > 0) {

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Identificador alternativo já está em uso.</span></div>
                    <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                </div>)
                setOpenModal(true)
            } else
                if (depreciationMethod === "" || depreciationMethod === undefined || depreciationMethod === 'undefined') {

                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Selecione o método de depreciação.</span></div>
                        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                    </div>)
                    setOpenModal(true)
                } else
                    if (months === "") {

                        setQualAlert(<div className='div-alert'>
                            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                            <div className='div-info-alert'><span className="info-alert">Digite a quantidade de meses.</span></div>
                            <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                        </div>)
                        setOpenModal(true)

                    } else {
                        const dados = [{
                            dpc_active: active,
                            dpc_description: description,
                            dpc_alternativeidentifier: alternativeIdentifier,
                            dmt_id: depreciationMethod,
                            dpc_months: months
                        }]

                        try {
                            ret = await api.post("depreciation/insert", {
                                data: dados,
                            });
                            if (ret.data.status === 200) {
                                props.voltar();
                            }
                        } catch (e: any) {
                            setQualAlert(<div className='div-alert'>
                                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                                <div className='div-info-alert'><span className="info-alert">Não foi possivel concluir o cadastramento.</span></div>
                                <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                            </div>)

                            setOpenModal(true)
                        }
                    }
    }

    function ValitarActive() {
        let e = document.getElementById("checkbox-active-mod") as HTMLInputElement;

        if (e.checked) {

            setActive(true)
        } else {

            setActive(false)
        }
    }


    const customStyles = {
        control: (provided: any, state: any) => ({
            ...provided,
            background: '#fff',
            minHeight: '30px',
            height: '30px',
            borderColor: state.isFocused ? 'grey' : '#92989e',

        }),


        valueContainer: (provided: any, state: any) => ({
            ...provided,
            height: '30px',
            padding: '0 6px'
        }),

        input: (provided: any, state: any) => ({
            ...provided,
            margin: '0px',
            fontSize: 14,

        }),
        indicatorsContainer: (provided: any, state: any) => ({
            ...provided,
            height: '30px',
        }),
    };


    async function GetDepreciationMethod() {
        let ret: AxiosResponse;
        let valor: any;
        try {
            ret = await api.get("depreciationMethod/listar")
            if (ret.data.status === 200) {
                valor = ret.data.data;

                const arr = [] as any;
                let result = valor;
                result.map((user: any) => {
                    return arr.push({ value: String(user.dmt_id), label: user.dmt_description });
                });
                setOptionDepreciationMethod(arr)
            }
        } catch (e) {
            setOptionDepreciationMethod([])
        }
    }

    const maskOnlyNumbers = (value: any) => {
        return value.replace(/\D/g, "");
    };

    return (
        <>
            <div className="container-modal-pric">
                <div className="class-checkbox">
                    <input type="checkbox" id="checkbox-active-mod" checked={active} onChange={ValitarActive} />
                    <h4 className="lab-ativo">Ativo</h4>
                </div>
                <div className="container-cad-prod4">
                    <div id="content1">
                        <div className="input-box-ativos">
                            <span className="details">Descrição *</span>
                            <input
                                type="text"
                                autoComplete="off"
                                onChange={(e) => setDescription(e.target.value)}
                                value={description} />
                        </div>
                    </div>
                    <div id="content2">
                        <div className="input-box-ativos">
                            <span className="details">Identificador Alternativo *</span>
                            <input
                                type="text"
                                autoComplete="off"
                                onChange={(e) => setAlternativeIdentifier(e.target.value)}
                                value={alternativeIdentifier} />
                        </div>
                    </div>
                </div>

                <div className="container-cad-prod4">
                    <div id="content1">

                        <div className="select-box-ativos-select">
                            <span className="details">Metodo de Depreciação *</span>
                            <Select
                                maxMenuHeight={120}
                                placeholder={'Selecione...'}
                                options={optionDepreciationMethod}
                                styles={customStyles}
                                value={optionDepreciationMethod.filter(function (option: any) {
                                    return option.value === depreciationMethod;
                                })}
                                onChange={(options: any) =>
                                    !options ? setDepreciationMethod("") : setDepreciationMethod(maskOnlyNumbers(options.value))
                                }
                                isClearable
                                noOptionsMessage={() => "Não encontrado..."}
                                theme={(theme: any) => ({
                                    ...theme,
                                    borderRadius: 4,
                                    colors: {
                                        ...theme.colors,
                                        primary25: '#ebebeb',
                                        primary: 'black',
                                    },
                                })}
                            />
                        </div>

                    </div>
                    <div id="content2">
                        <div className="input-box-ativos">
                            <span className="details">Meses *</span>
                            <input
                                type="text"
                                autoComplete="off"
                                onChange={(e) => validarNumber(e.target.value)}
                                value={months} />
                        </div>
                    </div>
                </div>

                <div className="Bt-button-cad">
                    <button type="submit" onClick={ConfirmarDepreciation}>
                        <i className="fa fa-check-circle icon-ok-btn"></i> Salvar
                    </button>
                    <button type="submit" onClick={props.voltar}>
                        <i className="fa fa-times icon-cancelar-btn"></i> Cancelar
                    </button>
                </div>
            </div>


            <ModalAlert isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)}>
                <div className='container-modal-alert'>
                    {qualAlert}
                </div>
            </ModalAlert>
        </>
    );

}

export default FormDepreciation;