import React, { PureComponent, useState } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
import "./index.css";
import api from "../../Services/api";
import { FcSearch, FcPlus, FcFilledFilter } from "react-icons/fc";
import { AxiosResponse } from "axios";
import { Link } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import Select from 'react-select'
import { FaEraser, FaSearch, FaPen, FaTrash } from "react-icons/fa";
import Loading from '../../components/Loading';
import ModalPrincipal from '../../components/ModalAlert/modal'
import { FaCopy } from "react-icons/fa";
import { FaList } from "react-icons/fa6";

export class Child extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      offset: 0,
      tableData: [],
      orgtableData: [],
      perPage: 10,
      currentPage: 0,
      pagAtual: 1,
      length: 0,
      endpoint: this.props.endPoint,
      clicked: false,
      atualiza: 0,
      search: '',
      isChecked: [],
      consult: this.props.consult,
      forcePage: 0,
      clickedtask: false,
      tsk_active: '',
      tsk_id: '',
      tea_id: '',
      age_id: '',
      loc_id: '',
      ast_id: '',
      tsk_integrationid: '',
      stn_id: '',
      tsk_observation: '',
      tty_id: '',
      crt_id: '',
      pvt_id: '',
      pla_id: '',
      startdate: '',
      enddate: '',
      startdateUlt: '',
      enddateUlt: '',
      optionsTeam: [],
      optionsAgent: [],
      optionsLocal: [],
      optionsAsset: [],
      optionsTasksituation: [],
      optionsTasktype: [],
      optionsCorrectivetype: [],
      optionsPreventivetype: [],
      token: false,
      Nacesso: false,
    };


    this.handlePageClick = this.handlePageClick.bind(this);
  }


  componentDidMount() {
    this.getData();

    if (this.props.pag === "task") {
      this.setState({ tsk_active: '1' });
      this.GetTeam();
      this.GetAgent();
      this.GetLocal();
      this.GetAsset();
      this.GetTasksituation();
      this.GetTasktype();
      this.GetCorrectivetype();
      this.GetPreventivetype();
    }
  }

  GetPreventivetype() {

    let valor

    api.get(this.props.preventivetype).then((res) => {
      var data = res.data.data;
      if (res.data.status === 200) {

        valor = res.data.data;

        const arr = [];
        let result = valor;
        result.map((user) => {
          return arr.push({ value: String(user.pvt_id), label: user.pvt_description });
        });

        this.setState({
          optionsPreventivetype: arr
        });

      }

    })
      .catch(function (res) {
        this.setState({
          optionsPreventivetype: []
        });

      });
  }

  GetCorrectivetype() {

    let valor

    api.get(this.props.correctivetype).then((res) => {
      var data = res.data.data;
      if (res.data.status === 200) {

        valor = res.data.data;

        const arr = [];
        let result = valor;
        result.map((user) => {
          return arr.push({ value: String(user.crt_id), label: user.crt_description });
        });

        this.setState({
          optionsCorrectivetype: arr
        });

      }

    })
      .catch(function (res) {
        this.setState({
          optionsCorrectivetype: []
        });

      });
  }

  GetTasktype() {

    let valor

    api.get(this.props.tasktype).then((res) => {
      var data = res.data.data;
      if (res.data.status === 200) {

        valor = res.data.data;

        const arr = [];
        let result = valor;
        result.map((user) => {
          return arr.push({ value: String(user.tty_id), label: user.tty_description });
        });

        this.setState({
          optionsTasktype: arr
        });

      }

    })
      .catch(function (res) {
        this.setState({
          optionsTasktype: []
        });

      });
  }

  GetTasksituation() {

    let valor

    api.get(this.props.tasksituation).then((res) => {
      var data = res.data.data;
      if (res.data.status === 200) {

        valor = res.data.data;

        const arr = [];
        let result = valor;
        result.map((user) => {
          return arr.push({ value: String(user.stn_id), label: user.stn_description });
        });

        this.setState({
          optionsTasksituation: arr
        });

      }

    })
      .catch(function (res) {
        this.setState({
          optionsTasksituation: []
        });

      });
  }

  GetTeam() {

    let valor

    api.get(this.props.team).then((res) => {
      var data = res.data.data;
      if (res.data.status === 200) {

        valor = res.data.data;

        const arr = [];
        let result = valor;
        result.map((user) => {
          return arr.push({ value: String(user.tae_id), label: user.tae_description });
        });

        this.setState({
          optionsTeam: arr
        });

      }

    })
      .catch(function (res) {
        this.setState({
          optionsTeam: []
        });

      });
  }

  GetAgent() {

    let valor

    api.get(this.props.user).then((res) => {
      var data = res.data.data;
      if (res.data.status === 200) {

        valor = res.data.data;


        const arr = [];
        let result = valor;
        result.map((user) => {
          return arr.push({ value: String(user.age_id), label: user.age_name });
        });



        this.setState({
          optionsAgent: arr
        });

      }

    })
      .catch(function (res) {
        this.setState({
          optionsAgent: []
        });

      });
  }

  GetLocal() {

    let valor

    api.get(this.props.local).then((res) => {
      var data = res.data.data;
      if (res.data.status === 200) {

        valor = res.data.data;


        const arr = [];
        let result = valor;
        result.map((user) => {
          return arr.push({ value: String(user.loc_id), label: user.loc_description });
        });



        this.setState({
          optionsLocal: arr
        });

      }

    })
      .catch(function (res) {
        this.setState({
          optionsLocal: []
        });

      });
  }

  GetAsset() {

    let valor

    api.get(this.props.asset).then((res) => {
      var data = res.data.data;
      if (res.data.status === 200) {

        valor = res.data.data;

        const arr = [];
        let result = valor;
        result.map((user) => {
          return arr.push({ value: String(user.ast_id), label: user.ast_description });
        });

        this.setState({
          optionsAsset: arr
        });

      }

    })
      .catch(function (res) {
        this.setState({
          optionsAsset: []
        });

      });
  }

  getData() {
    let dados = [];

    this.setState({ isLoading: true });

    if (this.props.pag === "task") {

      dados.push({
        tsk_active: '1',
        tsk_id: '',
        tea_id: '',
        age_id: '',
        loc_id: '',
        ast_id: '',
        tsk_integrationid: '',
        stn_id: '',
        tsk_observation: '',
        tty_id: '',
        crt_id: '',
        pvt_id: '',
        pla_id: '',
        startdate: '',
        enddate: '',
        search: '',
        startdateUlt: '',
        enddateUlt: '',
      })

      const _user = secureLocalStorage.getItem('t')

      api.defaults.headers.common['Authorization'] = _user


      api.get(this.props.endPoint, {
        params: {
          data: dados
        }
      }).then((res) => {
        const data = res.data.data || [];

        if (res.data.status === 200) {
          const slice = data.slice(
            this.state.offset,
            this.state.offset + this.state.perPage
          );

          this.setState({
            pageCount: Math.ceil(data.length / this.state.perPage),
            orgtableData: data,
            tableData: slice,
            dataT: data,
            length: data.length,
          });
        } else {
          const slice = data.slice(
            this.state.offset,
            this.state.offset + this.state.perPage
          );

          this.setState({
            pageCount: Math.ceil(data.length / this.state.perPage),
            orgtableData: [],
            tableData: slice,
            dataT: [],
            length: data.length,
          });
        }

        this.setState({

          isLoading: false,
        });

      }).catch((e) => {

        const data = [];
        const slice = data.slice(
          this.state.offset,
          this.state.offset + this.state.perPage
        );

        this.setState({
          pageCount: Math.ceil(data.length / this.state.perPage),
          orgtableData: [],
          tableData: slice,
          dataT: [],
          length: data.length,
        });

        if (!e.response) {

          this.setState({ isLoading: false });
          this.setState({ Nacesso: true });

        } else if (e.response.status === 403) {

          this.setState({ isLoading: false });
          this.setState({ token: true });

        } else {

          this.setState({ isLoading: false });
          this.setState({ Nacesso: true });

        }

        this.setState({

          isLoading: false,
        });

      });

    }
  }

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
        pagAtual: selectedPage + 1,
        forcePage: selectedPage
      },
      () => {
        this.loadMoreData();
      }
    );


  };

  loadMoreData() {

    const data = this.state.dataT;

    const slice = data.slice(
      this.state.offset,
      this.state.offset + this.state.perPage,

    );


    //////////////////////aqui
    this.setState({
      pageCount: Math.ceil(data.length / this.state.perPage),
      tableData: slice,
      length: data.length,
    });

  }


  handlePageClick_clear = () => {
    const selectedPage = 0;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
        pagAtual: selectedPage + 1,
        forcePage: selectedPage,
      },
      () => {
        this.loadMoreData();
      }
    );


  };




  handleInputChange = (event) => {
    this.setState({ search: event.target.value });
  };

  do_search = () => {

    this.setState({ isLoading: true });

    let dados = [];

    this.handlePageClick_clear()

    if (this.props.pag === "task") {

      dados.push({
        tsk_active: this.state.tsk_active,
        tsk_id: this.state.tsk_id,
        tea_id: this.state.tea_id,
        age_id: this.state.age_id,
        loc_id: this.state.loc_id,
        ast_id: this.state.ast_id,
        tsk_integrationid: this.state.tsk_integrationid,
        stn_id: this.state.stn_id,
        tsk_observation: this.state.tsk_observation,
        tty_id: this.state.tty_id,
        crt_id: this.state.crt_id,
        pvt_id: this.state.pvt_id,
        pla_id: this.state.pla_id,
        startdate: this.state.startdate,
        enddate: this.state.enddate,
        search: this.state.search,
        startdateUlt: this.state.startdateUlt,
        enddateUlt: this.state.enddateUlt,
      })


      api.get(this.props.buscartask, {
        params: {
          data: dados
        }
      }).then((res) => {

        var data = res.data.data;

        if (res.data.status === 200) {

          var slice = data.slice(
            this.state.offset,
            this.state.offset + this.state.perPage
          );

          this.setState({
            pageCount: Math.ceil(data.length / this.state.perPage),
            orgtableData: res.data.data,
            tableData: slice,
            dataT: res.data.data,
            length: data.length,
          });

        }

        this.setState({
          isLoading: false,
        });

      })
        .catch(function (e) {

          const data = [];
          const slice = data.slice(
            this.state.offset,
            this.state.offset + this.state.perPage
          );

          this.setState({
            pageCount: Math.ceil(data.length / this.state.perPage),
            orgtableData: [],
            tableData: slice,
            dataT: [],
            length: data.length,
          });

          if (!e.response) {

            this.setState({ isLoading: false });
            this.setState({ Nacesso: true });

          } else if (e.response.status === 403) {

            this.setState({ isLoading: false });
            this.setState({ token: true });

          } else {

            this.setState({ isLoading: false });
            this.setState({ Nacesso: true });

          }
        });

    }


  };

  clear_search = () => {
    window.location.reload();
  };


  toggle = (index) => {
    if (this.state.clicked === index) {
      //if clicked question is already active, then close it
      return this.setState({ clicked: null });
    }
    this.setState({ clicked: index });
  };

  handlecheckbox = (e) => {
    const { value, checked } = e.target;

    if (checked) {

      this.setState({
        isChecked: ([...this.state.isChecked, value])
      });

    } else {


      this.setState({
        isChecked: (this.state.isChecked.filter((e) => e !== value))
      });


    }

  }

  chamarCust = (valor) => {
    this.props.funtoggle(valor, this.props.form);

  }

  exibirFiltro = () => {

    if (this.props.pag === "task") {
      if (this.state.clickedtask === true) {
        this.setState({
          clickedtask: false
        });
      } else {
        this.setState({
          clickedtask: true
        });
      }
    }

  }

  funcNsolicitacao = () => {
    this.setState({ Nacesso: false });
  }

  handleInputChangeAtivo = (event) => {

    if (this.props.pag === "task") {
      this.setState({ tsk_active: event.target.value });
    }
  }

  render() {

    const customStyles = {
      control: (provided, state) => ({
        ...provided,
        background: '#fff',
        minHeight: '30px',
        height: '30px',
        borderColor: state.isFocused ? 'grey' : '#92989e',

      }),


      valueContainer: (provided, state) => ({
        ...provided,
        height: '30px',
        padding: '0 6px'
      }),

      input: (provided, state) => ({
        ...provided,
        margin: '0px',
        fontSize: 14,

      }),
      indicatorsContainer: (provided, state) => ({
        ...provided,
        height: '30px',
      }),
    };

    return (
      <div className="ContainerGrid">
        <div className="ContainerBody">
          <div className="InputBusca">
            <input
              autoComplete="off"
              placeholder="Pesquisar..."
              onChange={this.handleInputChange}
              value={this.state.search}
            />

            <div className="icone-buscaT" onClick={this.do_search}>
              <FcSearch className="icone-add" />
            </div>

            <div className="icone-buscaT" onClick={this.clear_search}>
              <FaEraser className="icone-add" />
            </div>

            <div className="icone-buscaT" onClick={this.exibirFiltro}>
              <FcFilledFilter className="icone-add" />
            </div>

          </div>

          {secureLocalStorage.getItem('acp') === 'DESENVOLVEDOR' || secureLocalStorage.getItem('acp') === 'ADMINISTRADOR' || secureLocalStorage.getItem('acp') === 'DIRECIONADOR' ? (
            <Link to={this.props.fromAdd} className="icone-buscaAdd" >
              <FcPlus className="icone-add" />
            </Link>
          ) : null}


        </div>

        <div className="ContainerDropdown">

          {this.state.clickedtask === true ? (

            <div className="container-Dropdown-busca">
              <div className="container-cad-prod6">
                <div id="content1">
                  <div className="input-box-ativos">
                    <span className="details">Ativo</span>
                    <select id="SELCAT" name="SELCAT" key='CAT0'
                      onChange={this.handleInputChangeAtivo} value={this.state.tsk_active}>
                      <option value="">Todos</option>
                      <option value="1">Sim</option>
                      <option value="0">Não</option>
                    </select>
                  </div>
                </div>

                <div id="content2">
                  <div className="input-box-ativos">
                    <span className="details">Tarefa</span>
                    <input
                      type="text"
                      onChange={(e) => this.setState({ tsk_id: e.target.value })}
                      value={this.state.tsk_id}
                    />
                  </div>
                </div>
                <div id="content3">
                  <div className="select-box-ativos-select">
                    <span className="details">Usuário</span>
                    <Select
                      maxMenuHeight={150}
                      placeholder={'Selecione...'}
                      options={this.state.optionsAgent}
                      styles={customStyles}
                      onChange={(options) =>
                        !options ? this.setState({ age_id: '' }) : this.setState({ age_id: options.value })
                      }
                      isClearable
                      noOptionsMessage={() => "Não encontrado..."}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 4,
                        colors: {
                          ...theme.colors,
                          primary25: '#ebebeb',
                          primary: 'black',
                        },
                      })}
                    />
                  </div>


                </div>

                <div id="content4">
                  <div className="select-box-ativos-select">
                    <span className="details">Equipe</span>
                    <Select
                      maxMenuHeight={150}
                      placeholder={'Selecione...'}
                      options={this.state.optionsTeam}
                      styles={customStyles}
                      onChange={(options) =>
                        !options ? this.setState({ tea_id: '' }) : this.setState({ tea_id: options.value })
                      }
                      isClearable
                      noOptionsMessage={() => "Não encontrado..."}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 4,
                        colors: {
                          ...theme.colors,
                          primary25: '#ebebeb',
                          primary: 'black',
                        },
                      })}
                    />
                  </div>
                </div>

              </div>

              <div className="container-cad-prod6">

                <div id="content1">

                  <div className="select-box-ativos-select">
                    <span className="details">Local</span>
                    <Select
                      maxMenuHeight={150}
                      placeholder={'Selecione...'}
                      options={this.state.optionsLocal}
                      styles={customStyles}
                      onChange={(options) =>
                        !options ? this.setState({ loc_id: '' }) : this.setState({ loc_id: options.value })
                      }
                      isClearable
                      noOptionsMessage={() => "Não encontrado..."}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 4,
                        colors: {
                          ...theme.colors,
                          primary25: '#ebebeb',
                          primary: 'black',
                        },
                      })}
                    />
                  </div>
                </div>

                <div id="content2">

                  <div className="select-box-ativos-select">
                    <span className="details">Ativo</span>
                    <Select
                      maxMenuHeight={150}
                      placeholder={'Selecione...'}
                      options={this.state.optionsAsset}
                      styles={customStyles}
                      onChange={(options) =>
                        !options ? this.setState({ ast_id: '' }) : this.setState({ ast_id: options.value })
                      }
                      isClearable
                      noOptionsMessage={() => "Não encontrado..."}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 4,
                        colors: {
                          ...theme.colors,
                          primary25: '#ebebeb',
                          primary: 'black',
                        },
                      })}
                    />
                  </div>

                </div>

                <div id="content3">

                  <div className="input-box-ativos">
                    <span className="details">ID para Integração</span>
                    <input
                      type="text"
                      onChange={(e) => this.setState({ tsk_integrationid: e.target.value })}
                      value={this.state.tsk_integrationid}
                    />
                  </div>

                </div>

                <div id="content4">

                  <div className="input-box-ativos">
                    <span className="details">Observação</span>
                    <input
                      type="text"
                      onChange={(e) => this.setState({ tsk_observation: e.target.value })}
                      value={this.state.tsk_observation}
                    />
                  </div>

                </div>

              </div>

              <div className="container-cad-prod6">
                <div id="content1">
                  <div className="input-box-ativos">
                    <span className="details">Data Inicial</span>
                    <div>
                      <input
                        type="date"
                        onChange={(e) => this.setState({ startdate: e.target.value })}
                        value={this.state.startdate}
                      />
                    </div>
                  </div>
                </div>

                <div id="content2">
                  <div className="input-box-ativos">
                    <span className="details">Até</span>
                    <input
                      type="date"
                      onChange={(e) => this.setState({ enddate: e.target.value })}
                      value={this.state.enddate}
                    />
                  </div>
                </div>
                <div id="content3">
                  <div className="input-box-ativos">
                    <span className="details">Última Atividade</span>
                    <div>
                      <input
                        type="date"
                        onChange={(e) => this.setState({ startdateUlt: e.target.value })}
                        value={this.state.startdateUlt}
                      />
                    </div>
                  </div>
                </div>

                <div id="content4">
                  <div className="input-box-ativos">
                    <span className="details">Até</span>
                    <input
                      type="date"
                      onChange={(e) => this.setState({ enddateUlt: e.target.value })}
                      value={this.state.enddateUlt}
                    />
                  </div>
                </div>





              </div>

              <div className="container-cad-prod16">

                <div id="content1">

                  <div className="select-box-ativos-select">
                    <span className="details">Situação</span>
                    <Select
                      maxMenuHeight={150}
                      placeholder={'Selecione...'}
                      options={this.state.optionsTasksituation}
                      styles={customStyles}
                      onChange={(options) =>
                        !options ? this.setState({ stn_id: '' }) : this.setState({ stn_id: options.value })
                      }
                      isClearable
                      noOptionsMessage={() => "Não encontrado..."}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 4,
                        colors: {
                          ...theme.colors,
                          primary25: '#ebebeb',
                          primary: 'black',
                        },
                      })}
                    />
                  </div>
                </div>

                <div id="content2">
                  <div className="select-box-ativos-select">
                    <span className="details">Tipo de Tarefa</span>
                    <Select
                      maxMenuHeight={150}
                      placeholder={'Selecione...'}
                      options={this.state.optionsTasktype}
                      styles={customStyles}
                      onChange={(options) =>
                        !options ? this.setState({ tty_id: '' }) : this.setState({ tty_id: options.value })
                      }
                      isClearable
                      noOptionsMessage={() => "Não encontrado..."}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 4,
                        colors: {
                          ...theme.colors,
                          primary25: '#ebebeb',
                          primary: 'black',
                        },
                      })}
                    />
                  </div>

                </div>

                <div id="content3">

                  <div className="select-box-ativos-select">
                    <span className="details">Tipo Corretiva</span>
                    <Select
                      maxMenuHeight={150}
                      placeholder={'Selecione...'}
                      options={this.state.optionsCorrectivetype}
                      styles={customStyles}
                      onChange={(options) =>
                        !options ? this.setState({ crt_id: '' }) : this.setState({ crt_id: options.value })
                      }
                      isClearable
                      noOptionsMessage={() => "Não encontrado..."}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 4,
                        colors: {
                          ...theme.colors,
                          primary25: '#ebebeb',
                          primary: 'black',
                        },
                      })}
                    />
                  </div>

                </div>

                <div id="content4">

                  <div className="select-box-ativos-select">
                    <span className="details">Tipo Preventiva</span>
                    <Select
                      maxMenuHeight={150}
                      placeholder={'Selecione...'}
                      options={this.state.optionsPreventivetype}
                      styles={customStyles}
                      onChange={(options) =>
                        !options ? this.setState({ pvt_id: '' }) : this.setState({ pvt_id: options.value })
                      }
                      isClearable
                      noOptionsMessage={() => "Não encontrado..."}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 4,
                        colors: {
                          ...theme.colors,
                          primary25: '#ebebeb',
                          primary: 'black',
                        },
                      })}
                    />
                  </div>


                </div>

                <div id="content5">

                  <div className="input-box-ativos">
                    <span className="details">ID Planejador</span>
                    <input
                      type="text"
                      onChange={(e) => this.setState({ pla_id: e.target.value })}
                      value={this.state.pla_id}
                    />
                  </div>

                </div>

              </div>

            </div>
          ) : null}

        </div>

        {this.state.pageCount > 0 ? (
          <div className="grid-tab-user">
            <table>
              <tbody>
                <tr id="grid-header">
                  <th className="Title">{this.props.th1}</th>
                  <th className="Title">{this.props.th2}</th>
                  <th className="Title">{this.props.th3}</th>
                  <th className="Title">{this.props.th4}</th>
                  <th className="Title">{this.props.th5}</th>
                  <th className="Title">{this.props.th6}</th>
                  <th className="Title">{this.props.th8}</th>
                  <th className="Title">{this.props.th7}</th>

                  <th className="Title">Ações</th>
                </tr>
              </tbody>
              <tbody>
                {this.state.tableData.map((tdata, i) => (
                  <tr className="TR-Coluna" key={Object.values(tdata)[0]}>
                    <td className="ColunaCheck-user" align="center"><input type='checkbox' checked={Object.values(tdata)[this.props.col1]} onChange={() => { }} /></td>
                    <td className="Coluna-id">{Object.values(tdata)[this.props.col2]}</td>

                    {Object.values(tdata)[this.props.col3] !== '' ? (
                      <td className="Coluna-descricao">
                        {Object.values(tdata)[this.props.col3]}</td>
                    ) :
                      <td className="Coluna-descricao">
                        {Object.values(tdata)[this.props.col4]}</td>
                    }

                    <td className="Coluna-quem">{Object.values(tdata)[this.props.col7]}</td>

                    {Object.values(tdata)[this.props.col6] === '' ? (
                      <td className="Coluna-data-previsto">{Object.values(tdata)[this.props.col5]?.substring(8, 10)}/{Object.values(tdata)[this.props.col5]?.substring(5, 7)}/{Object.values(tdata)[this.props.col5]?.substring(0, 4)} {Object.values(tdata)[this.props.col5]?.substring(11, 16)}</td>
                    ) : <td className="Coluna-data-previsto">{Object.values(tdata)[this.props.col5]?.substring(8, 10)}/{Object.values(tdata)[this.props.col5]?.substring(5, 7)}/{Object.values(tdata)[this.props.col5]?.substring(0, 4)} {Object.values(tdata)[this.props.col5]?.substring(11, 16)} - {Object.values(tdata)[this.props.col6]?.substring(11, 16)}</td>}

                    {Object.values(tdata)[this.props.col9] !== '' ? (
                      Object.values(tdata)[this.props.col10] === '' ? (
                        <td className="Coluna-data-previsto">{Object.values(tdata)[this.props.col9]?.substring(8, 10)}/{Object.values(tdata)[this.props.col9]?.substring(5, 7)}/{Object.values(tdata)[this.props.col9]?.substring(0, 4)} {Object.values(tdata)[this.props.col9]?.substring(11, 16)}</td>
                      ) : <td className="Coluna-data-previsto">{Object.values(tdata)[this.props.col9]?.substring(8, 10)}/{Object.values(tdata)[this.props.col9]?.substring(5, 7)}/{Object.values(tdata)[this.props.col9]?.substring(0, 4)} {Object.values(tdata)[this.props.col9]?.substring(11, 16)} - {Object.values(tdata)[this.props.col10]?.substring(11, 16)}</td>
                    ) : <td className="Coluna-data-previsto"></td>}

                    <td className="Coluna-data-previsto">{Object.values(tdata)[this.props.col11]}</td>

                    <td className="Coluna-situacao">{Object.values(tdata)[this.props.col12]}</td>

                    <td className="Coluna-icone-btn3">

                      <Link to={this.props.fromEdit + '/' + Object.values(tdata)[0]}>
                        <button className="btn-icon edit-btn">
                          <FaPen />
                        </button>
                      </Link>

                      <span onClick={() => this.props.copiarTask(tdata)}>
                        <button className="btn-icon view-btn2">
                          <FaCopy />
                        </button>
                      </span>
                      
                      <span onClick={() => this.props.consultarSituacao(tdata)}>
                        <button className="btn-icon view-btn3">
                          <FaList />
                        </button>
                        </span>


                      <Link to={this.props.fromvisu + '/' + Object.values(tdata)[21] + '/' + Object.values(tdata)[0]}>
                        <button className="btn-icon view-btn">
                          <FaSearch />
                        </button>
                      </Link>
                    </td>

                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : null}
        <div className="ContainerPag">
          <div className="InlineContainery">

            {this.state.pageCount === null || this.state.pageCount === 0 ? (
              <div className="SemResut">
                <h2>
                  Nenhum resultado encontrado.
                </h2>
                <h2>
                  Página 1 de 1
                </h2>
              </div>
            ) : null}

            <div className="InputBusca">
              {this.state.pageCount > 0 ? (
                <div className="Quant">
                  <h2>
                    Página {this.state.pagAtual} de {this.state.pageCount}
                  </h2>
                </div>
              ) : null}

              {this.state.pageCount > 1 ? (
                <ReactPaginate
                  previousLabel={<i className="fas fa-angle-left"></i>}
                  nextLabel={<i className="fas fa-angle-right"></i>}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.state.pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                  forcePage={this.state.forcePage}
                />
              ) : null}
            </div>
          </div>
          {this.state.pageCount > 0 ? (
            <div className="paginate">
              <h2>Total de registros: {this.state.length}</h2>
            </div>
          ) : null}
        </div>

        <ModalPrincipal isOpen={this.state.isLoading}>
          <div className='container-modal-alert'>
            <div className='div-alert'><Loading /></div>
          </div>
        </ModalPrincipal>

        <ModalPrincipal isOpen={this.state.token}>
          <div className='container-modal-alert'>
            <div className='div-alert'>
              <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
              <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
              <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
              <div className='btn-alert' onClick={this.props.fecharModal}><span className="btn-alert-lab">OK</span></div>
            </div>
          </div>
        </ModalPrincipal>

        <ModalPrincipal isOpen={this.state.Nacesso}>
          <div className='container-modal-alert'>
            <div className='div-alert'>
              <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
              <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
              <div className='div-info-alert'><span className="info-alert">Não foi possível concluir sua solicitação.</span></div>
              <div className='btn-alert' onClick={this.funcNsolicitacao}><span className="btn-alert-lab">OK</span></div>
            </div>
          </div>
        </ModalPrincipal>

      </div>
    );
  }
}

export default Child;
