import React, { PureComponent, useState } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
import "./index.css";
import api from "../../Services/api";
import { useNavigate } from "react-router";
import { AxiosResponse } from "axios";
import { FaEraser } from "react-icons/fa";
import { FcSearch, FcPlus, FcFilledFilter } from "react-icons/fc";
import secureLocalStorage from "react-secure-storage";
import Select from 'react-select'
import { FaListAlt } from "react-icons/fa";
import Loading from '../Loading';
import ModalPrincipal from '../ModalAlert/modal'
import { Link } from "react-router-dom";
import { FaSearch, FaPen, FaTrash } from "react-icons/fa";
import { FaCopy } from "react-icons/fa";

export class Child extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      offset: 0,
      tableData: [],
      orgtableData: [],
      perPage: 11,
      currentPage: 0,
      pagAtual: 1,
      length: 0,
      endpoint: this.props.endPoint,
      clicked: false,
      atualiza: 0,
      search: '',
      isChecked: [],
      consult: this.props.consult,
      forcePage: 0,
      mrc_active: '',
      mrc_description: '',
      mrc_alternativeidentifier: '',
      clickedmyrecords: false,
      token: false,
      Nacesso: false,
    };

    this.handlePageClick = this.handlePageClick.bind(this);
  }


  componentDidMount() {

    this.getData();


  }


  getData() {

    this.setState({ isLoading: true });

      const _user = secureLocalStorage.getItem('t')

      api.defaults.headers.common['Authorization'] = _user

      api.get(this.props.endPoint).then((res) => {
        const data = res.data.data || [];


        if (res.data.status === 200) {
          const slice = data.slice(
            this.state.offset,
            this.state.offset + this.state.perPage
          );

          this.setState({
            pageCount: Math.ceil(data.length / this.state.perPage),
            orgtableData: data,
            tableData: slice,
            dataT: data,
            length: data.length,
            dataP: data,
          });
        } else {
          const slice = data.slice(
            this.state.offset,
            this.state.offset + this.state.perPage
          );

          this.setState({
            pageCount: Math.ceil(data.length / this.state.perPage),
            orgtableData: [],
            tableData: slice,
            dataT: [],
            length: data.length,
            dataP:  [],
          });
        }

        this.setState({
          isLoading: false,
        });

      }).catch((e) => {

        const data = [];
        const slice = data.slice(
          this.state.offset,
          this.state.offset + this.state.perPage
        );

        this.setState({
          pageCount: Math.ceil(data.length / this.state.perPage),
          orgtableData: [],
          tableData: slice,
          dataT: [],
          length: data.length,
          dataP:[],
        });

        if (!e.response) {

          this.setState({ isLoading: false });
          this.setState({ Nacesso: true });

        } else if (e.response.status === 403) {

          this.setState({ isLoading: false });
          this.setState({ token: true });

        } else {

          this.setState({ isLoading: false });
          this.setState({ Nacesso: true });

        }

      });
    

  }


  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
        pagAtual: selectedPage + 1,
        forcePage: selectedPage
      },
      () => {
        this.loadMoreData();
      }
    );


  };

  loadMoreData() {

    const data = this.state.dataT;

    const slice = data.slice(
      this.state.offset,
      this.state.offset + this.state.perPage,

    );


    //////////////////////aqui
    this.setState({
      pageCount: Math.ceil(data.length / this.state.perPage),
      tableData: slice,
      length: data.length,
    });

  }


  handleInputChange = (event) => {
    this.setState({ search: event.target.value });
  };

  do_search = () => {


 
      const temp_rows = this.state.dataT.filter(
        (e) => {
          return (
            JSON.stringify(e)
              .toLowerCase()
              .indexOf(this.state.search.toLowerCase()) >= 0
          );
        }
      );

      var slice = temp_rows.slice(
        this.state.offset,
        this.state.offset + this.state.perPage
      );

      this.setState({
        pageCount: Math.ceil(temp_rows.length / this.state.perPage),
        orgtableData: temp_rows,
        tableData: slice,
        dataT: temp_rows,
        length: temp_rows.length,
      });
  
   this.setState({
      isLoading: false,
    });

    
	

  };

  clear_search = () => {

    window.location.reload();

  };


  toggle = (index) => {
    if (this.state.clicked === index) {
      //if clicked question is already active, then close it
      return this.setState({ clicked: null });
    }
    this.setState({ clicked: index });
  };

  handlecheckbox = (e) => {
    const { value, checked } = e.target;

    if (checked) {

      this.setState({
        isChecked: ([...this.state.isChecked, value])
      });

    } else {


      this.setState({
        isChecked: (this.state.isChecked.filter((e) => e !== value))
      });


    }

  }




  funcNsolicitacao = () => {
    this.setState({ Nacesso: false });
  }

  render() {
    return (
      <div className="ContainerGrid">
        <div className="ContainerBody">


          <div className="InputBusca">
            <input
              autoComplete="off"
              placeholder="Pesquisar..."
              onChange={this.handleInputChange}
              value={this.state.search}
            />

            <div className="icone-buscaT" onClick={()=> this.do_search()}>
              <FcSearch className="icone-add" />
            </div>

            <div className="icone-buscaT" onClick={()=> this.clear_search()}>
              <FaEraser className="icone-add" />
            </div>

          

          </div>


          <Link to={'/accessprofile/add'} className="icone-buscaAdd" >
            <FcPlus className="icone-add" />
          </Link>

        </div>


        {this.state.pageCount > 0 ? (
          <div className="grid-tab-user">
            <table>
              <tbody>
                <tr id="header-user">
                  <th className="Title-user">{this.props.th1}</th>
                  <th className="Title-user">Ações</th>
                </tr>
              </tbody>
              <tbody>
                {this.state.tableData.map((tdata, i) => (
                  <tr className="TR-Coluna-user" key={Object.values(tdata)[this.props.col1]}>
                    <td className="Coluna-user-id">{Object.values(tdata)[this.props.col2]}</td>

             
                    <td className="Coluna-icone-btn2">


                      {Object.values(tdata)[4] === 1 ? (

                        <button className="btn-icon edit-btn" onClick={() => this.props.permitirEditar()}>
                          <FaPen />
                        </button>

                      ) : <Link to={'/accessprofile/edit' + '/' + Object.values(tdata)[0]}>
                        <button className="btn-icon edit-btn">
                          <FaPen />
                        </button>
                      </Link>}


                      <button className="btn-icon view-btn" onClick={() => this.props.visualizar(Object.values(tdata)[0], Object.values(tdata)[2])}>
                        <FaSearch />
                      </button>

                      <Link to={'/accessprofile/add/copy' + '/' + Object.values(tdata)[0]}>
                        <button className="btn-icon view-btn2">
                          <FaCopy />
                        </button>
                        </Link>


                    </td>



                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : null}
        <div className="ContainerPag">
          <div className="InlineContainery">

            {this.state.pageCount === null || this.state.pageCount === 0 ? (
              <div className="SemResut">
                <h2>
                  Nenhum resultado encontrado.
                </h2>
                <h2>
                  Página 1 de 1
                </h2>
              </div>
            ) : null}


            <div className="InputBusca">
              {this.state.pageCount > 0 ? (
                <div className="Quant">
                  <h2>
                    Página {this.state.pagAtual} de {this.state.pageCount}
                  </h2>
                </div>
              ) : null}

              {this.state.pageCount > 1 ? (
                <ReactPaginate
                  previousLabel={<i className="fas fa-angle-left"></i>}
                  nextLabel={<i className="fas fa-angle-right"></i>}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.state.pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                  forcePage={this.state.forcePage}
                />
              ) : null}
            </div>
          </div>
          {this.state.pageCount > 0 ? (
            <div className="paginate">
              <h2>Total de registros: {this.state.length}</h2>
            </div>
          ) : null}
        </div>

        <ModalPrincipal isOpen={this.state.isLoading}>
          <div className='container-modal-alert'>
            <div className='div-alert'><Loading /></div>
          </div>
        </ModalPrincipal>

        <ModalPrincipal isOpen={this.state.token}>
          <div className='container-modal-alert'>
            <div className='div-alert'>
              <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
              <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
              <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
              <div className='btn-alert' onClick={this.props.fecharModal}><span className="btn-alert-lab">OK</span></div>
            </div>
          </div>
        </ModalPrincipal>

        <ModalPrincipal isOpen={this.state.Nacesso}>
          <div className='container-modal-alert'>
            <div className='div-alert'>
              <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
              <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
              <div className='div-info-alert'><span className="info-alert">Não foi possível concluir sua solicitação.</span></div>
              <div className='btn-alert' onClick={this.funcNsolicitacao}><span className="btn-alert-lab">OK</span></div>
            </div>
          </div>
        </ModalPrincipal>

      </div>
    );
  }
}

export default Child;
