import React, { useState } from 'react';
import './index.css';
import { useNavigate } from 'react-router';
import { MdEmail } from "react-icons/md";
import Swal from 'sweetalert2';
import logo from '../../img/desk.png';
import logomenu from "../../img/LOGO.svg";
import { FaUserAlt, FaBuilding } from "react-icons/fa";
import { AxiosResponse } from "axios";
import api from '../../Services/api'
import ModalAlert from '../../components/ModalAlert/modal'
import Loading from '../../components/Loading/index'
import axios from 'axios';

function ForgotPassword() {

  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [login, setLogin] = useState('');
  const [company, setCompany] = useState("")
  const [openModal, setOpenModal] = useState(false)
  const [qualAlert, setQualAlert] = useState(<></>)


  function finalizarModal2() {
    setOpenModal(false)
  }

  async function recuperarSenha() {


    if (company === '' || login === '') {

      setQualAlert(<div className='div-alert'>
        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
        <div className='div-info-alert'><span className="info-alert">Por favor, preencha todos os campos.</span></div>
        <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
      </div>)

      setOpenModal(true)

    } else {


      setQualAlert(<div className='div-alert'><Loading /></div>)
      setOpenModal(true)

      axios.get('https://tudoapi.mobcode.com.br:3000/ambientes/validar', {
        params: {
          ambiente: company
        }
      })
        .then(async function (response) {

          let endPoint = response.data.data[0].amb_endpointweb

          axios.get(endPoint + 'user/reset', {
            params: {
              login: login,
              company: company
           }
          })
            .then(async function (response) {

              navigate('/login');

            })
            .catch(function (error) {

              if (error.response === undefined) {

                setQualAlert(<div className='div-alert'>
                  <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                  <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                  <div className='div-info-alert'><span className="info-alert">Desculpe, não conseguimos conectar ao servidor no momento.</span></div>
                  <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                </div>)


              } else if (error.response.status === 401) {

                setQualAlert(<div className='div-alert'>
                  <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                  <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                  <div className='div-info-alert'><span className="info-alert">Usuário não encontrado!</span></div>
                  <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                </div>)

              } else {

                setQualAlert(<div className='div-alert'>
                  <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                  <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                  <div className='div-info-alert'><span className="info-alert">Não foi possivel realizar o login!</span></div>
                  <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                </div>)

              }

            })












        })
        .catch(async function (error) {

          console.log(error)

          if (error.response === undefined) {

            setQualAlert(<div className='div-alert'>
              <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
              <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
              <div className='div-info-alert'><span className="info-alert">Desculpe, não conseguimos conectar ao servidor no momento.</span></div>
              <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
            </div>)


          } else if (error.response.status === 401) {

            setQualAlert(<div className='div-alert'>
              <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
              <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
              <div className='div-info-alert'><span className="info-alert">Ambiente não encontrado!</span></div>
              <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
            </div>)

          } else {

            setQualAlert(<div className='div-alert'>
              <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
              <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
              <div className='div-info-alert'><span className="info-alert">Não foi possivel realizar o login!</span></div>
              <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
            </div>)

          }
        });
    }











  }


  return (
    <div className="forgot-password">
      <div className="forgot-password-right">
        <div className='div-title1'>
          <span className='title-login2'>Esqueceu sua senha?</span>
        </div>
        <div className='div-title2'>
          <span className='title-login2'>Digite seu e-mail e o usuário para recuperar a senha.</span>
        </div>

        <div className='container-input2'>

          <div className="login-loginInputAmbiente">
            <FaBuilding className='icon-input' />
            <input
              className='mascInput'
              placeholder="AMBIENTE"
              value={company}
              onChange={e => setCompany(e.target.value.toUpperCase())}
            />
          </div>

          <div className="forgot-password-inputEmail">
            <FaUserAlt className='icon-input' />
            <input
              className='mascInput'
              placeholder="USUÁRIO"
              value={login}
              onChange={e => setLogin(e.target.value)}
            />
          </div>


          <div className='container-btn'>
            <button type="submit" onClick={recuperarSenha}>
              Enviar
            </button>
          </div>
        </div>
      </div>

      <div className="forgot-password-inf">
        <div>
          <img className='img-das3' src={logomenu} alt="Logo" />
        </div>
        <img className='img-dash' src={logo} alt="Logo" />
        <div className='dash-info-dash'>
          <span className='title-bord-icon-dash'>
            Controle ativos com registro de manutenções corretivas, preventivas, movimentações e inventários de forma eficiente.
          </span>
        </div>
      </div>

      <ModalAlert isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)}>
        <div className='container-modal-alert'>
          {qualAlert}
        </div>
      </ModalAlert>

    </div>
  );
}

export default ForgotPassword;
