import React, { PureComponent, useState } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
import "./index.css";
import { FcSearch, FcSynchronize } from "react-icons/fc";
import secureLocalStorage from "react-secure-storage";
import { FaEraser } from "react-icons/fa";
import api from "../../Services/api";

export class Child extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      tableData: [],
      orgtableData: [],
      perPage: 11,
      currentPage: 0,
      pagAtual: 1,
      length: 0,
      endpoint: this.props.endPoint,
      clicked: false,
      atualiza: 0,
      search: '',
      isChecked: [],
      consult: this.props.consult,
      forcePage: 0
    };

    this.handlePageClick = this.handlePageClick.bind(this);
  }



  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
        pagAtual: selectedPage + 1,
        forcePage: selectedPage
      },
      () => {
        this.loadMoreData();
      }
    );


  };

  loadMoreData() {

    const data = this.state.dataT;

    const slice = data.slice(
      this.state.offset,
      this.state.offset + this.state.perPage,

    );


    //////////////////////aqui
    this.setState({
      pageCount: Math.ceil(data.length / this.state.perPage),
      tableData: slice,
      length: data.length,
    });

  }


  handlePageClick_clear = () => {
    const selectedPage = 0;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
        pagAtual: selectedPage + 1,
        forcePage: selectedPage,
      },
      () => {
        this.loadMoreData();
      }
    );


  };




  handleInputChange = (event) => {
    this.setState({ search: event.target.value });
  };

  do_search = () => {

    this.setState({
      dataT: this.state.orgtableData,
    });
    //------------------------------BUSCAR
    if (this.state.search === '' || this.state.search === undefined || this.state.search === null) {

    } else {


      const temp_rows = this.state.orgtableData.filter(
        (e) => {
          return (
            JSON.stringify(e)
              .toLowerCase()
              .indexOf(this.state.search.toLowerCase()) >= 0
          );
        }
      );
      
      var slice = temp_rows.slice(
        this.state.offset,
        this.state.offset + this.state.perPage
      );

      this.setState({
        pageCount: Math.ceil(temp_rows.length / this.state.perPage),
        orgtableData: this.state.orgtableData,
        tableData: slice,
        dataT: temp_rows,
        length: temp_rows.length,
        pagAtual: 1,
      });

      this.handlePageClick_clear()
    }


  };

  clear_search = () => {
    //------------------------------LIMPAR
    this.setState({ search: '' });

    var slice = this.state.orgtableData.slice(
      this.state.offset,
      this.state.offset + this.state.perPage
    );

    this.setState({
      pageCount: Math.ceil(this.state.orgtableData.length / this.state.perPage),
      orgtableData: this.state.orgtableData,
      tableData: slice,
      dataT: this.state.orgtableData,
      length: this.state.orgtableData.length,
      pagAtual: 0,
      forcePage: 0,
    });

    this.handlePageClick_clear();

  };

  componentDidMount() {
    this.getData();
    this.clear_search();
  }

  getData() {
    const _user = secureLocalStorage.getItem('t')

    api.defaults.headers.common['Authorization'] = _user


    api.get(this.props.endPoint).then((res) => {
      const data = res.data.data || [];

      if (res.data.status === 200) {
        const slice = data.slice(
          this.state.offset,
          this.state.offset + this.state.perPage
        );

        this.setState({
          pageCount: Math.ceil(data.length / this.state.perPage),
          orgtableData: data,
          tableData: slice,
          dataT: data,
          length: data.length,
        });
      } else {
        const slice = data.slice(
          this.state.offset,
          this.state.offset + this.state.perPage
        );

        this.setState({
          pageCount: Math.ceil(data.length / this.state.perPage),
          orgtableData: [],
          tableData: slice,
          dataT: [],
          length: data.length,
        });
      }

      this.setState({
                  
        isLoading: false,
      });

    }).catch((e) => {

      const data = [];
      const slice = data.slice(
        this.state.offset,
        this.state.offset + this.state.perPage
      );

      this.setState({
        pageCount: Math.ceil(data.length / this.state.perPage),
        orgtableData: [],
        tableData: slice,
        dataT: [],
        length: data.length,
      });

      if (!e.response) {
       // console.log('Erro de rede ou outro erro não relacionado à resposta');
      } else if (e.response.status === 403) {
     //   console.log('Erro 403: Acesso negado');
      } else {
      //  console.log(`Erro ${e.response.status}: ${e.response.statusText}`);
      }

      this.setState({
                  
        isLoading: false,
      });
      
    });

  }


  toggle = (index) => {
    if (this.state.clicked === index) {
      //if clicked question is already active, then close it
      return this.setState({ clicked: null });
    }
    this.setState({ clicked: index });
  };

  handlecheckbox = (e) => {
    const { value, checked } = e.target;

    if (checked) {

      this.setState({
        isChecked: ([...this.state.isChecked, value])
      });


    } else {


      this.setState({
        isChecked: (this.state.isChecked.filter((e) => e !== value))
      });


    }

  }

  chamarCust = (valor) => {
    this.props.funtoggle(valor, this.props.form);

  }

  exibirFiltro = () => {
  
    if(this.state.clicked === true){
      this.setState({
        clicked: false
      });
    }else{
      this.setState({
        clicked: true
      });
    }
  }

  render() {
    return (
      <div className="ContainerGrid">
        <div className="ContainerBody">
      

            <div className="InputBusca">
              <input
              autoComplete="off"
                placeholder="Pesquisar..."
                onChange={this.handleInputChange}
                value={this.state.search}
              />

              <div className="icone-buscaT" onClick={this.do_search}>
                <FcSearch className="icone-add" />
              </div>

              <div className="icone-buscaT" onClick={this.clear_search}>
              <FaEraser className="icone-add"/>
              </div>
             
            </div>
        
            <div onClick={this.props.Atualizar} className="icone-buscaAdd" >
            <FcSynchronize className="icone-add" />
          </div>

        </div>

        <div className="ContainerDropdown">
          {this.state.clicked === true ? (
            <div className="dropdown">
              <div className="user-filtro">
                <div className="input-box-grid">
                  <span className="details">Ativo</span>
                  <select id="SELCAT" name="SELCAT" key='CAT0'>
                    <option disabled selected>
                      {" "}
                      -- Selecione --{" "}
                    </option>
                  </select>
                </div>

                <div className="input-box-grid">
                  <span className="details">Categoria</span>
                  <select id="SELCAT" name="SELCAT" key='CAT1'>
                    <option disabled selected>
                      {" "}
                      -- Selecione --{" "}
                    </option>
                  </select>
                </div>

                <div className="input-box-grid">
                  <span className="details">Categoria</span>
                  <select id="SELCAT" name="SELCAT" key='CAT2'>
                    <option disabled selected>
                      {" "}
                      -- Selecione --{" "}
                    </option>
                  </select>
                </div>

                <div className="input-box-grid">
                  <span className="details">Categoria</span>
                  <select id="SELCAT" name="SELCAT" key='CAT3'>
                    <option disabled selected>
                      {" "}
                      -- Selecione --{" "}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          ) : null}
        </div>

        {this.state.pageCount > 0 ? (
          <div className="grid-tab-user">
            <table>
              <tbody>
                <tr id="grid-header">
                  <th className="Title">{this.props.th1}</th>
                  <th className="Title">{this.props.th2}</th>
                  <th className="Title">{this.props.th3}</th>
                  <th className="Title">{this.props.th4}</th>
                  <th className="Title">{this.props.th5}</th>
                  <th className="Title">{this.props.th6}</th>
                  <th className="Title">{this.props.th7}</th>
                </tr>
              </tbody>
              <tbody>
                {this.state.tableData.map((tdata, i) => (
                  <tr className="TR-Coluna" key={Object.values(tdata)[0]}>
                   <td className="Coluna-padrao">{Object.values(tdata)[this.props.col1]}</td>
                    <td className="Coluna-padrao">{Object.values(tdata)[this.props.col2]}</td>
                    <td className="Coluna-padrao">{Object.values(tdata)[this.props.col3]}</td>
                    <td className="Coluna-padrao">{Object.values(tdata)[this.props.col4]}</td>

                  
                    {Object.values(tdata)[this.props.col5] !== null ? ( 
                    <td className="Coluna-data">{Object.values(tdata)[this.props.col5]?.substring(8, 10)}/{Object.values(tdata)[this.props.col5]?.substring(5, 7)}/{Object.values(tdata)[this.props.col5]?.substring(0, 4)} {Object.values(tdata)[this.props.col5]?.substring(11, 16)}</td>
                    ) :  <td className="Coluna-data"></td> }
                    
                    {Object.values(tdata)[this.props.col6] !== null ? ( 
                    <td className="Coluna-data">{Object.values(tdata)[this.props.col6]?.substring(8, 10)}/{Object.values(tdata)[this.props.col6]?.substring(5, 7)}/{Object.values(tdata)[this.props.col6]?.substring(0, 4)} {Object.values(tdata)[this.props.col6]?.substring(11, 16)}</td>
                    ) :  <td className="Coluna-data"></td> }
                       
                    <td className="Coluna-icone-btn">
                                      
                      <div onClick={() => { this.props.VisualizarLog(Object(tdata)) }} >
                      <i className="fa-solid fa-message icone-acao2-import"></i>
                      </div>

                    </td>

                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : null}
        <div className="ContainerPag">
          <div className="InlineContainery">

            {this.state.pageCount === null || this.state.pageCount === 0 ? (
              <div className="SemResut">
                <h2>
                  Nenhum resultado encontrado.
                </h2>
                <h2>
                  Página 1 de 1
                </h2>
              </div>
            ) : null}

            <div className="InputBusca">
              {this.state.pageCount > 0 ? (
                <div className="Quant">
                  <h2>
                    Página {this.state.pagAtual} de {this.state.pageCount}
                  </h2>
                </div>
              ) : null}

              {this.state.pageCount > 1 ? (
                <ReactPaginate
                  previousLabel={<i className="fas fa-angle-left"></i>}
                  nextLabel={<i className="fas fa-angle-right"></i>}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.state.pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                  forcePage={this.state.forcePage}
                />
              ) : null}
            </div>
          </div>
          {this.state.pageCount > 0 ? (
            <div className="paginate">
              <h2>Total de registros: {this.state.length}</h2>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default Child;
