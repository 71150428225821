import React, { PureComponent, useState } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
import "./index.css";
import api from "../../Services/api";
import { useNavigate } from "react-router";
import { AxiosResponse } from "axios";
import secureLocalStorage from "react-secure-storage";
import { FaEraser, FaSearch, FaPen, FaTrash } from "react-icons/fa";
import { FcSearch, FcPlus, FcFilledFilter } from "react-icons/fc";

export class Child extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      tableData: [],
      orgtableData: [],
      perPage: 10,
      currentPage: 0,
      pagAtual: 1,
      length: 0,
      endpoint: this.props.endPoint,
      atualiza: 0,
      search: '',
      isChecked: [],
      consult: this.props.consult,
      forcePage: 0,
      active: '',
      description: '',
      alternativeidentifier: '',
      clicked: false,
    };

    this.handlePageClick = this.handlePageClick.bind(this);
  }



  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
        pagAtual: selectedPage + 1,
        forcePage: selectedPage
      },
      () => {
        this.loadMoreData();
      }
    );


  };

  loadMoreData() {

    const data = this.state.dataT;

    const slice = data.slice(
      this.state.offset,
      this.state.offset + this.state.perPage,

    );


    //////////////////////aqui
    this.setState({
      pageCount: Math.ceil(data.length / this.state.perPage),
      tableData: slice,
      length: data.length,
    });

  }


  handlePageClick_clear = () => {
    const selectedPage = 0;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
        pagAtual: selectedPage + 1,
        forcePage: selectedPage,
      },
      () => {
        this.loadMoreData();
      }
    );


  };




  handleInputChange = (event) => {
    this.setState({ search: event.target.value });
  };

  do_search = () => {

    this.setState({
      dataT: this.state.orgtableData,
    });
    //------------------------------BUSCAR
    if (this.state.search === '' || this.state.search === undefined || this.state.search === null) {

    } else {

      const temp_rows = this.state.orgtableData.filter(
        (e) => {
          return (
            JSON.stringify(e)
              .toLowerCase()
              .indexOf(this.state.search.toLowerCase()) >= 0
          );
        }
      );

      var slice = temp_rows.slice(
        this.state.offset,
        this.state.offset + this.state.perPage
      );

      this.setState({
        pageCount: Math.ceil(temp_rows.length / this.state.perPage),
        orgtableData: this.state.orgtableData,
        tableData: slice,
        dataT: temp_rows,
        length: temp_rows.length,
        pagAtual: 1,
      });

      this.handlePageClick_clear()
    }


  };

  clear_search = () => {
    //------------------------------LIMPAR
    this.setState({ search: '' });

    var slice = this.state.orgtableData.slice(
      this.state.offset,
      this.state.offset + this.state.perPage
    );

    this.setState({
      pageCount: Math.ceil(this.state.orgtableData.length / this.state.perPage),
      orgtableData: this.state.orgtableData,
      tableData: slice,
      dataT: this.state.orgtableData,
      length: this.state.orgtableData.length,
      pagAtual: 0,
      forcePage: 0,
    });

    this.handlePageClick_clear();

  };

  componentDidMount() {
    this.getData();
    this.clear_search();
  }

  getData() {

    try {
      api.get(this.props.endPoint, {
        params: {
          id: this.props.ambiente
        }
      }).then((res) => {
        var data = res.data.data;
        if (res.data.status === 200) {
          var slice = data.slice(
            this.state.offset,
            this.state.offset + this.state.perPage
          );

          this.setState({
            pageCount: Math.ceil(data.length / this.state.perPage),
            orgtableData: res.data.data,
            tableData: slice,
            dataT: res.data.data,
            length: data.length,
          });

        }
      });


    }
    catch (e) { }

  }


  toggle = (index) => {
    if (this.state.clicked === index) {
      //if clicked question is already active, then close it
      return this.setState({ clicked: null });
    }
    this.setState({ clicked: index });
  };

  exibirFiltro = () => {

  }

  render() {
    return (
      <div className="ContainerGrid2">
        <div className="ContainerBody2">
          <div className="InlineContainery">

            <div className="InputBusca">
              <input
                autoComplete="off"
                placeholder="Pesquisar..."
                onChange={this.handleInputChange}
                value={this.state.search}
              />

              <div className="icone-busca" onClick={this.do_search}>
                <FcSearch className="icone-add" />
              </div>

              <div className="icone-busca" onClick={this.clear_search}>
                <FaEraser className="icone-add" />
              </div>



            </div>
          </div>

          {secureLocalStorage.getItem('acp') === 'DESENVOLVEDOR' || secureLocalStorage.getItem('acp') === 'ADMINISTRADOR' ? (

            <div className="icone-busca" onClick={() => this.props.Iconeadd()}>
              <FcPlus className="icone-add" />
            </div>

          ) : null}

        </div>

        <div className="ContainerDropdown">
          {this.state.clicked === 1 ? (
           
           <div className="container-Dropdown-busca">
           <div className="container-cad-prod33">
             <div id="content1">
               <div className="input-box-ativos">
                 <span className="details">Ativo</span>
                 <select id="SELCAT" name="SELCAT" key='CAT0'
                   onChange={this.handleInputChangeAtivo}
                   value={this.state.active}>
                   <option value="">Todos</option>
                   <option value="1">Sim</option>
                   <option value="0">Não</option>
                 </select>
               </div>
             </div>

             <div id="content2">
               <div className="input-box-ativos">
                 <span className="details">Descrição</span>
                 <input
                   type="text"
                   onChange={(e) => this.setState({ description: e.target.value })}
                   value={this.state.description}
                 />
               </div>
             </div>

             <div id="content3">
               <div className="input-box-ativos">
                 <span className="details">Identificador alternativo</span>
                 <input
                   type="text"
                   onChange={(e) => this.setState({ alternativeidentifier: e.target.value })}
                   value={this.state.alternativeidentifier}
                 />
               </div>
             </div>
           </div>
         </div>

          ) : null}
        </div>

        {this.state.pageCount > 0 ? (
          <div className="grid-tab-user">
            <table>
              <tbody>
                <tr id="grid-header">
                  <th className="Title">{this.props.th3}</th>
                  <th className="Title">{this.props.th1}</th>
                  <th className="Title">{this.props.th2}</th>
                  {this.props.acao === 'S' ? (
                    <th className="Title">Ações</th>
                  ) : null}
                </tr>
              </tbody>
              <tbody>
                {this.state.tableData.map((tdata, i) => (
                  <tr className="TR-Coluna" key={Object.values(tdata)[this.props.col1]}>
                    <td className="Coluna-id">{Object.values(tdata)[this.props.col4]}</td>

                    {this.props.conc === 'S' ? (
                      <td className="Coluna-descricao2">{Object.values(tdata)[this.props.col3]}</td>
                    ) : <td className="Coluna-descricao2">{Object.values(tdata)[this.props.col3]?.substring(0, 62)}...</td>}
                    <td className="Coluna-padrao">{Object.values(tdata)[this.props.col2]}</td>


                    {this.props.acao === 'S' ? (
                      <td className="Coluna-icone-btn2">
                

                          {this.state.consult === 'S' ? (

                   
                              <button className="btn-icon edit-btn" onClick={() => { this.props.Iconeditar(Object(tdata)) }}>
                                <FaPen />
                              </button>
                       

                          ) : null}

                          {this.props.del !== 'S' ? (

                              <button className="btn-icon delete-btn" onClick={() => { this.props.iconeExcluir(Object(tdata)) }}>
                              <FaTrash />
                              </button>

                            
                          ) : null}

                     
                      </td>
                    ) : null}




                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : null}
        <div className="ContainerPag">
          <div className="InlineContainery">

            {this.state.pageCount === null || this.state.pageCount === 0 ? (
              <div className="SemResut">
                <h2>
                  Nenhum resultado encontrado.
                </h2>
                <h2>
                  Página 1 de 1
                </h2>
              </div>
            ) : null}


            <div className="InputBusca">
              {this.state.pageCount > 0 ? (
                <div className="Quant">
                  <h2>
                    Página {this.state.pagAtual} de {this.state.pageCount}
                  </h2>
                </div>
              ) : null}

              {this.state.pageCount > 1 ? (
                <ReactPaginate
                  previousLabel={<i className="fas fa-angle-left"></i>}
                  nextLabel={<i className="fas fa-angle-right"></i>}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.state.pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                  forcePage={this.state.forcePage}
                />
              ) : null}
            </div>
          </div>
          {this.state.pageCount > 0 ? (
            <div className="paginate">
              <h2>Total de registros: {this.state.length}</h2>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default Child;
