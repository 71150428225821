import React, { useState, useEffect } from "react";
import "./index.css";
import Listagem from "../Listagem";
import Swal from 'sweetalert2';
import secureLocalStorage from "react-secure-storage";

function PersonalizadoData(props) {

  //  const dadosAgenda = (localStorage.getItem("dadosPersonalizado2") || '{}');
  // let ageObj = JSON.parse(dadosAgenda);

  let [lista, setLista] = useState(props.dados || []);
  let [dataItem, setDataItem] = useState("");
  let [horaItem, setHoraItem] = useState("");
  const [horaItemDisabled, setHoraItemDisabled] = useState(false);
  const [dataItemDisabled, setDataItemDisabled] = useState(false);
  
  useEffect(() => {

    props.personalizado2func(lista)

    if(secureLocalStorage.getItem('acp') === 'CONSULTOR'){
      setDataItemDisabled(true)
      setHoraItemDisabled(true)
    }

  }, []);


  function geraStringAleatoria() {
    var stringAleatoria = '';
    var caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (var i = 0; i < 8; i++) {
      stringAleatoria += caracteres.charAt(Math.floor(Math.random() * caracteres.length));
    }
    return stringAleatoria;
  }

  function adicionarNovoItem() {

    if(secureLocalStorage.getItem('acp') !== 'CONSULTOR'){
    let ddd = dataItem + ' ' + horaItem
    if (dataItem === '') {
      Swal.fire({
        icon: 'error',
        title: 'ATENÇÃO',
        text: 'Informe a data!',
        confirmButtonColor: "#222d32",
      })

    } else if (horaItem === '') {
      Swal.fire({
        icon: 'error',
        title: 'ATENÇÃO',
        text: 'Informe a hora!',
        confirmButtonColor: "#222d32",
      })
    } else if (dataItem < props.dataIncial) {
      Swal.fire({
        icon: 'error',
        title: 'ATENÇÃO',
        text: 'A data personalizada não pode ser menor que a data inicial.',
        confirmButtonColor: "#222d32",
      })
    }
    else {


      const dados = {
        id: geraStringAleatoria(),
        data: ddd
      }



      setLista([...lista, dados]);
      setDataItem("");
      setHoraItem("");

      let tmArray = [...lista, dados];

      props.personalizado2func(tmArray)

    }
  }

  }

  function deletarItem(index) {

    if(secureLocalStorage.getItem('acp') !== 'CONSULTOR'){
    const newArray = lista.filter((transaction) => transaction.id !== index);

    setLista(newArray);
    props.personalizado2func(newArray)
    }


  }

  return (
    <>
      <div className="Container-data">
        <div className="user-details">

          <div className="container-cad-prod26">

            <div id="content1">
              <div className="input-itens">
                <span className="details">Data</span>
                <input
                  value={dataItem} 
                  onChange={value => 
                    setDataItem(value.target.value)} 
                    type="date"
                    disabled={dataItemDisabled}
                />
              </div>
            </div>
            <div id="content2">
              <div className="input-itens">
                <span className="details">Hora</span>
                <input
                  value={horaItem}
                   onChange={value => setHoraItem(value.target.value)} 
                   type="time"
                   disabled={horaItemDisabled}
                />
              </div>
            </div>
            <div id="content3">
              <div className="input-itens">
                <button className="button_itens" onClick={() => adicionarNovoItem()}>
                  ADICIONAR
                </button>
              </div>
            </div>
          </div>



        </div>

        <ul className="listagem">

          {lista.map((item, index) => (
            <Listagem
              key={index}
              data={item.data}
              id={item.id}
              funcao={deletarItem}
            />
          ))}
        </ul>
      </div>

    </>
  );
};

export default PersonalizadoData;
