
import React, { useState, useEffect } from 'react'
import Nav from "../../components/Nav";
import Header from "../../components/Header";
import './index.css'
import { AxiosResponse } from "axios";
import api from '../../Services/api'
import { MdOutlineAddCircle } from 'react-icons/md';
import MobileForm from '../../components/MobileForm';
import NotebookForm from '../../components/NotebookForm';
import DesktopForm from '../../components/DesktopForm';
import MonitorForm from '../../components/MonitorForm';
import ServidorForm from '../../components/ServidorForm';
import { useNavigate } from 'react-router'
import moment from 'moment';
import Select from 'react-select'
import FormProduto from '../../components/FormProduto';
import GridSecundario from '../../components/GridSecundario'
import Loading from '../../components/Loading';
import FormContrato from '../../components/FormContrato';
import FormUnidade from '../../components/FormUnidade';
import { useParams } from 'react-router-dom';
import FormDepartment from '../../components/FormDepartment';
import FormDepreciation from '../../components/FormDepreciation';
import secureLocalStorage from "react-secure-storage";
import ModalIncluirNovo from '../../components/ModalIncluirNovo/modal'
import ModalAlert from '../../components/ModalAlert/modal'

function InventarioEdit() {

    const navigate = useNavigate();
    let { id } = useParams();
    const [active, setActive] = useState(true);
    const [idInventorio, setIdInventorio] = useState('');
    const [description, setDescription] = useState('');
    const [category, setCategory] = useState('')
    const [subcategory, setSubcategory] = useState('')
    const [alternativeIdentifier, setAlternativeIdentifier] = useState('');
    const [alternativeIdentifierIni, setAlternativeIdentifierIni] = useState('');
    const [status, setStatus] = useState('');
    const [statusInicial, setStatusInicial] = useState('');
    const [created_at, setCreated_at] = useState('');
    const [updated_at, setUpdated_at] = useState('');
    const [observation, setObservation] = useState('');
    const [carregaStatus, setCarregaStatus] = useState(GetStatus);
    const [product, setProduct] = useState('');
    const [manufacturer, setManufacturer] = useState('')
    const [brand, setBrand] = useState('')
    const [model, setModel] = useState('')
    const [invoice, setInvoice] = useState('')
    const [idinvoice, setIdinvoice] = useState('')
    const [cust, setCust] = useState('')
    const [custInicial, setCustInicial] = useState('')
    const [residualValue, setResidualValue] = useState('')
    const [issueDate, setIssueDate] = useState('')
    const [warrantyMonth, setWarrantyMonth] = useState('');
    const [expirationDate, setExpirationDate] = useState('');
    const [typeOfDepreciation, setTypeOfDepreciation] = useState('');
    const [mac, setMac] = useState('');
    const [serialNumber, setSerialNumber] = useState('');
    const [serialNumberIni, setSerialNumberIni] = useState('');
    const [tombamento, setTombamento] = useState('');
    const [tombamentoIni, setTombamentoIni] = useState('');
    const [contract, setContract] = useState('');
    const [unity, setUnity] = useState('');
    const [contractInicial, setContractInicial] = useState('');
    const [unityInicial, setUnityInicial] = useState('');
    const [department, setDepartment] = useState('');
    const [user, setUser] = useState('');
    const [qualGrid, setQualGrid] = useState(<></>)
    const [ast_costcenter, setAst_costcenter] = useState('');

    const [optionspavement, setOptionspavement] = useState([])
    const [pavement, setPavement] = useState('');

    const [dadosInvoice, setDadosInvoice] = useState([]);

    const [qualForm, setQualForm] = useState(<></>)
    const [dadosForm, setDadosForm] = useState([])

    const [qualInf, setQualInf] = useState(<></>)
    const [titulo, setTitulo] = useState('');
    const [observationOcorrencia, setObservationOcorrencia] = useState('');
    const [exibirOcorrido, setExibirOcorrido] = useState('');
    const [exibirDevolucao, setExibirDevolucao] = useState('');
    const [modalGrid, setModalGrid] = useState(<></>)

    const [isDisabledCategory, setIsDisabledCategory] = useState(false)
    const [isDisabledSubcategory, setIsDisabledSubcategory] = useState(false)
    const [isDisabledContract, setIsDisabledContract] = useState(false)
    const [isDisabledUnity, setIsDisabledUnity] = useState(false)
    const [isDisabledProduct, setIsDisabledProduct] = useState(false)
    const [isDisabledStatus, setIsDisabledStatus] = useState(false)
    const [isDisabledDepreciation, setIsDisabledDepreciation] = useState(false)
    const [isDisabledManufacturer, setIsDisabledManufacturer] = useState(true)


    const [optionsManufacturer, setOptionsManufacturer] = useState([])
    const [optionsCategory, setOptionsCategory] = useState([])
    const [optionSubcategory, setOptionSubcategory] = useState([])
    const [optionStatus, setOptionStatus] = useState([])
    const [optionProduct, setOptionProduct] = useState([])
    const [optionContract, setOptionContract] = useState([])
    const [optionUnity, setOptionUnity] = useState([])
    const [exibirModalGrid, setExibirModalGrid] = useState('')

    const [optionsBrand, setOptionsBrand] = useState([])
    const [isDisabledBrand, setIsDisabledBrand] = useState(true)

    const [optionsModel, setOptionsModel] = useState([])
    const [isDisabledModel, setIsDisabledModel] = useState(true)
    const [optionsDepartment, setOptionsDepartment] = useState([])
    const [isDisabledPavement, setIsDisabledPavement] = useState(true)
    const [isDisabledDepartment, setDisabledDepartment] = useState(true)
    const [optionDepreciationMethod, setOptionDepreciationMethod] = useState([])

    const [optionsAtivo, setOptionsAtivo] = useState([])
    const [openModalIncluirNovo, setOpenModalIncluirNovo] = useState(false)
    const [openModalIncluirNovoInf, setOpenModalIncluirNovoInf] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [qualAlert, setQualAlert] = useState(<></>)

    const [activeDisabled, setActiveDisabled] = useState(false)
    const [descriptionDisabled, setDescriptionDisabled] = useState(false)
    const [alternativeIdentifierDisabled, setAlternativeIdentifierDisabled] = useState(false)
    const [statusDisabled, setStatusDisabled] = useState(false)
    const [observationDisabled, setObservationDisabled] = useState(false)
    const [invoiceDisabled, setInvoiceDisabled] = useState(false)
    const [custDisabled, setCustDisabled] = useState(false)
    const [issueDateDisabled, setIssueDateDisabled] = useState(false)
    const [warrantyMonthDisabled, setWarrantyMonthDisabled] = useState(false)
    const [serialNumberDisabled, setSerialNumberDisabled] = useState(false)
    const [tombamentoDisabled, setTombamentoDisabled] = useState(false)
    const [macDisabled, setMacDisabled] = useState(false)
    const [userDisabled, setUserDisabled] = useState(false)
    const [confirmarDisabled, setConfirmarDisabled] = useState(false)

    const [optionsAtivoComp, setOptionsAtivoComp] = useState([])

    useEffect(() => {

        setQualAlert(<div className='div-alert'><Loading /></div>)
        setOpenModal(true)

        validatToken();

    }, []);

    async function validatToken() {

        let ret: AxiosResponse;
        let valor: any;

        const _user = secureLocalStorage.getItem('t')

        api.defaults.headers.common['Authorization'] = _user as ''

        try {

            ret = await api.get("/status/validar")

            if (ret.data.status === 200) {

                secureLocalStorage.setItem('t', ret.data.data);
                secureLocalStorage.setItem('acp', ret.data.decodedToken.acp_integrationid);
                secureLocalStorage.setItem('n', ret.data.decodedToken.name);
                secureLocalStorage.setItem('iag', ret.data.decodedToken.id);
                secureLocalStorage.setItem('cy', ret.data.decodedToken.company);

                if (ret.data.decodedToken.acp_integrationid === 'DESENVOLVEDOR' || ret.data.decodedToken.acp_integrationid === 'ADMINISTRADOR') {

                    setActiveDisabled(false)
                    setDescriptionDisabled(false)
                    setIsDisabledCategory(false)
                    setIsDisabledSubcategory(false)
                    setAlternativeIdentifierDisabled(false)
                    setStatusDisabled(false)
                    setObservationDisabled(false)
                    setIsDisabledProduct(false)
                    setInvoiceDisabled(false)
                    setCustDisabled(false)
                    setIsDisabledDepreciation(false)
                    setIssueDateDisabled(false)
                    setWarrantyMonthDisabled(false)
                    setSerialNumberDisabled(false)
                    setTombamentoDisabled(false)
                    setMacDisabled(false)
                    setIsDisabledContract(false)
                    setIsDisabledUnity(false)
                    setIsDisabledPavement(false)
                    setDisabledDepartment(false)
                    setUserDisabled(false)
                    setConfirmarDisabled(false)

                } else if (ret.data.decodedToken.acp_integrationid === 'CONSULTOR' || secureLocalStorage.getItem('acp') === 'DIRECIONADOR') {
                    setActiveDisabled(true)
                    setDescriptionDisabled(true)
                    setIsDisabledCategory(true)
                    setIsDisabledSubcategory(true)
                    setAlternativeIdentifierDisabled(true)
                    setStatusDisabled(true)
                    setObservationDisabled(true)
                    setIsDisabledProduct(true)
                    setInvoiceDisabled(true)
                    setCustDisabled(true)
                    setIsDisabledDepreciation(true)
                    setIssueDateDisabled(true)
                    setWarrantyMonthDisabled(true)
                    setSerialNumberDisabled(true)
                    setTombamentoDisabled(true)
                    setMacDisabled(true)
                    setIsDisabledContract(true)
                    setIsDisabledUnity(true)
                    setIsDisabledPavement(true)
                    setDisabledDepartment(true)
                    setUserDisabled(true)
                    setConfirmarDisabled(true)

                }

                await inventoryditar()

            }

        } catch (e: any) {

            if (e.response.data.status === 403) {

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
                    <div className='btn-alert' onClick={fecharModalToken}><span className="btn-alert-lab">OK</span></div>
                </div>)


            }
        }

    }

    function fecharModalToken() {
        secureLocalStorage.removeItem('t')
        secureLocalStorage.removeItem('acp')
        secureLocalStorage.removeItem('n')
        secureLocalStorage.removeItem('iag')
        navigate('/login');
        setOpenModal(false)
    }

    const [daysMonthsYears, setDaysMonthsYears] = useState({
        days: 0,
        months: 0,
        years: 0,
    });

    function showDate(dateString: any, daysMonthsYears: any) {
        const dateToShow = new Date(dateString);

        if (!dateString || !dateToShow.getTime()) return '';

        dateToShow.setUTCHours(3);

        const { days, months, years } = daysMonthsYears;

        if (days) dateToShow.setDate(dateToShow.getDate() + Number(days));
        if (months) dateToShow.setMonth(dateToShow.getMonth() + Number(months));
        if (years) dateToShow.setFullYear(dateToShow.getFullYear() + Number(years));
        return dateToShow.toISOString().split('T')[0];
    }


    function fecharOpenModalIncluirNovo() {
        setOpenModalIncluirNovo(false)
    }

    function fecharOpenModalIncluirNovoInf() {
        setOpenModalIncluirNovoInf(false)
    }

    async function GetAtivo() {

        let ret: AxiosResponse;
        let valor: any;
        try {
            ret = await api.get("asset/verificacaoasset")

            if (ret.data.status === 200) {
                valor = ret.data.data;

                setOptionsAtivo(valor)

            }
        } catch (e) {

            setOptionsAtivo([])
        }
    }

    async function inventoryditar() {
        let ret: AxiosResponse;
        let valor: any;

        if (/^\d+$/.test(id || '') === true) {
            try {
                ret = await api.get("asset/visualizarasset", {
                    params: {
                        id: id
                    }
                })
                if (ret.data.status === 200) {
                    valor = ret.data.data[0];


                    setAst_costcenter(valor.ast_costcenter || '')
                    setActive(valor.ast_active != undefined ? Boolean(valor.ast_active) : true)
                    setIdInventorio(valor.ast_id || '');
                    setDescription(valor.ast_description || '');
                    setCategory(String(valor.ctg_id || ''))
                    setSubcategory(String(valor.stg_id || ''))
                    setStatus(String(valor.ssa_id || ''));
                    setStatusInicial(String(valor.ssa_id) || '');
                    setAlternativeIdentifier(valor.ast_alternativeidentifier || '')
                    setAlternativeIdentifierIni(valor.ast_alternativeidentifier || '')
                    setCreated_at(valor.ast_datetimeinsert?.substring(0, 16) || '');
                    setUpdated_at(valor.updated_at?.substring(0, 16) || '');
                    setObservation(valor.ast_observation || '');
                    setProduct(String(valor.pdc_id || ''));
                    setManufacturer(String(valor.mfr_id || ''))
                    setBrand(String(valor.brn_id || ''))
                    setModel(String(valor.mde_id || ''))
                    setInvoice(valor.ast_invoicenumber || '')
                    setIdinvoice(valor.inc_id || '')
                    setCust(valor.ast_cust || '')
                    setCustInicial(valor.ast_cust || '')
                    setResidualValue(valor.ast_residualvalue || '')
                    setIssueDate(valor.ast_issuedate || '')
                    setWarrantyMonth(valor.ast_warrantymonth || '');
                    // setExpirationDate(valor.ast_expirationdate || '');
                    setTypeOfDepreciation(String(valor.dpc_id || ''));
                    setMac(valor.ast_mac || '');
                    setSerialNumberIni(valor.ast_serialnumber || '');
                    setSerialNumber(valor.ast_serialnumber || '');
                    setTombamentoIni(valor.ast_tombamento || '');
                    setTombamento(valor.ast_tombamento || '');
                    setContract(String(valor.ctc_id + '_' + valor.ctc_description || ''));
                    setUnity(String(valor.unt_id + '_' + valor.untdescricao || ''));
                    setContractInicial(String(valor.ctc_id + '_' + valor.ctc_description || ''));
                    setUnityInicial(String(valor.unt_id + '_' + valor.untdescricao || ''));
                    setUser(valor.ast_usedby || '');
                    //  setIsDisabledSubcategory(false)

                    
                    await  GetProduto(String(valor.stg_id || ''))
                    await  GetCategoria();
                    await  Getsubcategory(String(valor.ctg_id || ''));
                    await  GetFabricante()
                    await  GetMarca(String(valor.mfr_id || ''))
                    await  GetModelo(String(valor.brn_id || ''))
                    await  GetDepreciationMethod();
                    await  GetContrato();
                    await  GetAtivo()

                    if (valor.unt_id !== "" || valor.unt_id !== null) {
                        await  GetDepartment(String(valor.unt_id) || "")

                        if (valor.loc_pavement !== null) {
                            setPavement(valor.loc_pavement || '')
                            await GetDepartmentPavimentUp(valor.loc_pavement, String(valor.unt_id))
                            setDepartment(String(valor.loc_id || ''));
                        }

                    }

                    if (valor.inc_id !== null) {


                        if (valor.ast_warrantymonth === "") {

                        } else {


                            if (parseInt(valor.ast_warrantymonth) > 0) {

                                let mes = showDate(valor.ast_issuedate, { ...daysMonthsYears, days: Number(0), months: Number(valor.ast_warrantymonth), years: Number(0) })
                                setExpirationDate(showDate(mes, { ...daysMonthsYears, days: Number(0), months: Number(0), years: Number(0) }))

                            }
                        }



                        if (valor.ctc_id === null || valor.ctc_id === "") {

                        } else {
                            await GetUnidadeUp(String(valor.ctc_id));
                        }

                        setInvoiceDisabled(true)
                        setCustDisabled(true)

                        setIsDisabledProduct(true);
                        setIsDisabledCategory(true);
                        setIsDisabledSubcategory(true);
                        setIsDisabledStatus(true);
                        setIssueDateDisabled(true);

                        //if (valor.stg_id === 'Selecione...' || valor.stg_id === '') {
                          //  setQualForm(<></>)
                        //} else if (valor.stg_id === 1 || valor.stg_id === 3) {
                          //  setQualForm(<MobileForm data={valor} test={Inclurdados} disabled={'S'} />)
                        //} else if (valor.stg_id === 4) {
                         //   setQualForm(<NotebookForm data={valor} test={Inclurdados} disabled={'S'} />)
                       // }
                        //else if (valor.stg_id === 5) {
                          //  setQualForm(<DesktopForm data={valor} test={Inclurdados} disabled={'S'} />)
                       /// }
                        //else if (valor.stg_id === 2) {
                          //  setQualForm(<MonitorForm data={valor} test={Inclurdados} disabled={'S'} />)
                       /// } else if (valor.stg_id === 17) {
                       //     setQualForm(<ServidorForm data={valor} test={Inclurdados} disabled={'S'} />)
                     //   }

                    

                        if (valor.ssa_id === 1) {

                      
                            setIsDisabledContract(true);
                            setIsDisabledUnity(true);
                            setIsDisabledPavement(true)
                            setDisabledDepartment(true)
                            setUserDisabled(true)

                        } else {
                       
                            setIsDisabledContract(false);
                            setIsDisabledUnity(false);
                            setDisabledDepartment(false)
                            setIsDisabledPavement(false)
                            setUserDisabled(false)

                        }

                    } else {
                   
                        if (valor.ssa_id === 1) {
                       
                            setIsDisabledContract(true);
                            setIsDisabledUnity(true);
                            setIsDisabledPavement(true)
                            setDisabledDepartment(true)
                            setUserDisabled(true)

                        } else {
                      
                            setIsDisabledContract(false);
                            setIsDisabledUnity(false);
                            setDisabledDepartment(false)
                            setIsDisabledPavement(false)
                            setUserDisabled(false)

                        }


                        if (valor.ctc_id === null || valor.ctc_id === "") {

                        } else {

                            await  GetUnidadeUp(String(valor.ctc_id));
                        }

                        if (valor.ast_issuedate === '0000-00-00') {
                            setIssueDate("")
                        } else {
                            setIssueDate(valor.ast_issuedate || '')
                        }


                        setWarrantyMonth(valor.ast_warrantymonth || '')

                        if (valor.ast_warrantymonth !== "") {

                            let mes = showDate(valor.ast_issuedate, { ...daysMonthsYears, days: Number(0), months: Number(valor.ast_warrantymonth), years: Number(0) })
                            setExpirationDate(showDate(mes, { ...daysMonthsYears, days: Number(0), months: Number(0), years: Number(0) }))
                        } else {

                            setExpirationDate('')
                        }
                    }


                    if (secureLocalStorage.getItem('acp') === 'CONSULTOR' || secureLocalStorage.getItem('acp') === 'DIRECIONADOR') {
                        setActiveDisabled(true)
                        setDescriptionDisabled(true)
                        setIsDisabledCategory(true)
                        setIsDisabledSubcategory(true)
                        setAlternativeIdentifierDisabled(true)
                        setStatusDisabled(true)
                        setObservationDisabled(true)
                        setIsDisabledProduct(true)
                        setInvoiceDisabled(true)
                        setCustDisabled(true)
                        setIsDisabledDepreciation(true)
                        setIssueDateDisabled(true)
                        setWarrantyMonthDisabled(true)
                        setSerialNumberDisabled(true)
                        setTombamentoDisabled(true)
                        setMacDisabled(true)
                        setIsDisabledContract(true)
                        setIsDisabledUnity(true)
                        setIsDisabledPavement(true)
                        setDisabledDepartment(true)
                        setUserDisabled(true)
                        setConfirmarDisabled(true)

                    }
                    setOpenModal(false)
                } else {
                    setOpenModal(false)
                    navigate('/asset/edit/' + id + '/n');
                }
            } catch (e) {
                setOpenModal(false)
                navigate('/asset/edit/' + id + '/n');
            }

        } else {
            setOpenModal(false)
            navigate('/asset/edit/' + id + '/n');
        }

        
    }


    async function GetDepartmentPavimentUp(pavimento: any, unidade: any) {
        let ret: AxiosResponse;
        let valor: any;

        try {
            ret = await api.get("local/listarlocpaviment", {
                params: {
                    id: unidade,
                    paviment: pavimento
                }
            })
            if (ret.data.status === 200) {
                valor = ret.data.data;

                const arr = [] as any;
                let result = valor;
                result.map((user: any) => {
                    return arr.push({ value: String(user.loc_id), label: user.loc_description });
                });
                setOptionsDepartment(arr)
            }
        } catch (e) {
            setOptionsDepartment([])
        }
    }


    async function GetModelo(marca: any) {
        let ret: AxiosResponse;
        let valor: any;

        try {
            ret = await api.get("model/listar", {
                params: {
                    id: marca
                }
            })
            if (ret.data.status === 200) {
                valor = ret.data.data;
                const arr = [] as any;
                let result = valor;
                result.map((user: any) => {
                    return arr.push({ value: String(user.mde_id), label: user.mde_description });
                });
                setOptionsModel(arr)
            }
        } catch (e) {

            setOptionsModel([])
        }

    }

    async function GetMarca(fabricante: any) {
        let ret: AxiosResponse;
        let valor: any;

        try {
            ret = await api.get("brand/listar", {
                params: {
                    id: fabricante
                }
            })
            if (ret.data.status === 200) {
                valor = ret.data.data;

                const arr = [] as any;
                let result = valor;
                result.map((user: any) => {
                    return arr.push({ value: String(user.brn_id), label: user.brn_description });
                });
                setOptionsBrand(arr)
            }
        } catch (e) {
            setOptionsBrand([])
        }

    }

    async function GetFabricante() {
        let ret: AxiosResponse;
        let valor: any;
        try {
            ret = await api.get("manufacturer/listar")
            if (ret.data.status === 200) {
                valor = ret.data.data;

                const arr = [] as any;
                let result = valor;
                result.map((user: any) => {
                    return arr.push({ value: String(user.mfr_id), label: user.mfr_description });
                });
                setOptionsManufacturer(arr)
            }
        } catch (e) {
            setOptionsManufacturer([])
        }
    }

    async function GetUnidadeUp(id: any) {
        let ret: AxiosResponse;
        let valor: any;

        if (id === '' || id === 'Selecione...') {
            setUnity('')
            setOptionUnity([])
        } else {
            try {
                ret = await api.get("unity/listar", {
                    params: {
                        id: id
                    }
                })
                if (ret.data.status === 200) {
                    valor = ret.data.data;
                    const arr = [] as any;
                    let result = valor;
                    result.map((user: any) => {
                        return arr.push({ value: String(user.unt_id + "_" + user.unt_description), label: user.unt_description });
                    });
                    setOptionUnity(arr)
                }
            } catch (e) {
                setOptionUnity([]);
            }
        }
    }

    function ValitarActive() {

        let e = document.getElementById("checkbox-ativo") as HTMLInputElement;

        if (e.checked) {

            setActive(true)
        } else {

            setActive(false)
        }
    }

    async function GetStatus() {
        let ret: AxiosResponse;
        let valor: any;
        try {
            ret = await api.get("assetstatus/listar")
            if (ret.data.status === 200) {
                valor = ret.data.data;


                const arr = [] as any;
                let result = valor;
                result.map((user: any) => {
                    return arr.push({ value: String(user.ssa_id), label: user.ssa_description });
                });
                setOptionStatus(arr)
            }
        } catch (e) {
            setOptionStatus([])
        }
    }

    async function GetCategoria() {
        let ret: AxiosResponse;
        let valor: any;
        try {
            ret = await api.get("category/listar")
            if (ret.data.status === 200) {
                valor = ret.data.data;


                const arr = [] as any;
                let result = valor;
                result.map((user: any) => {
                    return arr.push({ value: String(user.ctg_id), label: user.ctg_description });
                });
                setOptionsCategory(arr)

            }
        } catch (e) {
            setOptionsCategory([])
        }
    }

    async function Getsubcategory(categoria: any) {
        let ret: AxiosResponse;
        let valor: any;

        if (categoria === '' || categoria === 'Selecione...') {
            setSubcategory('')
            setOptionSubcategory([])

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Selecione a categoria.</span></div>
                <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
            </div>)

            setOpenModal(true)

        } else {

            try {
                ret = await api.get("subcategory/listar", {
                    params: {
                        id: categoria
                    }
                })
                if (ret.data.status === 200) {
                    valor = ret.data.data;
                    const arr = [] as any;
                    let result = valor;
                    result.map((user: any) => {
                        return arr.push({ value: String(user.stg_id), label: user.stg_description });
                    });
                    setOptionSubcategory(arr)
                }
            } catch (e) {
                setOptionSubcategory([])
            }
        }
    }

    async function GetProduto(SubCategory: any) {

        if (SubCategory === '' || SubCategory === undefined) {

        } else {
            let ret: AxiosResponse;
            let valor: any;
            try {
                ret = await api.get("product/produtosub", {
                    params: {
                        id: SubCategory
                    }
                })
                if (ret.data.status === 200) {
                    valor = ret.data.data;

                    const arr = [] as any;
                    let result = valor;
                    result.map((user: any) => {
                        return arr.push({ value: String(user.pdc_id), label: user.pdc_description });
                    });
                    setOptionProduct(arr)

                } else {
                    setOptionProduct([])
                }
                setOpenModal(false)
            } catch (e) {
                setOpenModal(false)
                setOptionProduct([])
            }
        }

    }

    function Inclurdados(data: any) {

        setDadosForm(data)
    }

    async function GetCarregaInf(inf: any) {
        let ret: AxiosResponse;
        let valor: any;


        setQualForm(<></>)
        if (inf === "" || inf === "Selecione...") {
            setQualForm(<></>)
            setManufacturer('')
            setBrand('')
            setModel('')
            setProduct('')
        } else {

            setQualAlert(<div className='div-alert'><Loading /></div>)
            setOpenModal(true)


            try {
                ret = await api.get("product/exibir", {
                    params: {
                        id: inf
                    }
                })
                if (ret.data.status === 200) {
                    valor = ret.data.data;

                    setProduct(inf)

                    await GetMarca(String(valor[0].mfr_id) || '')
                    await GetModelo(String(valor[0].brn_id) || '')
                    await GetFabricante()

                    setManufacturer(String(valor[0].mfr_id))
                    setBrand(String(valor[0].brn_id))
                    setModel(String(valor[0].mde_id))

                    //if (valor[0].stg_id === 1 || valor[0].stg_id === 3) {

                      //  setQualForm(<MobileForm data={valor[0]} test={Inclurdados} disabled={'S'} />)
                    //} else if (valor[0].stg_id === 4) {
                     //   setQualForm(<NotebookForm data={valor[0]} test={Inclurdados} disabled={'S'} />)
                   // }
                   // else if (valor[0].stg_id === 5) {
                      //  setQualForm(<DesktopForm data={valor[0]} test={Inclurdados} disabled={'S'} />)
                    //}
                    //else if (valor[0].stg_id === 2) {
                      //  setQualForm(<MonitorForm data={valor[0]} test={Inclurdados} disabled={'S'} />)
                    //} else if (valor[0].stg_id === 17) {
                      //  setQualForm(<ServidorForm data={valor[0]} test={Inclurdados} disabled={'S'} />)
                    //} else {
                    //    setQualForm(<></>)
                  //  }
                }
                setOpenModal(false)
            } catch (e) {
                setOpenModal(false)
            }
        }

    }

    async function GetContrato() {
        let ret: AxiosResponse;
        let valor: any;
        try {
            ret = await api.get("contract/listar")
            if (ret.data.status === 200) {
                valor = ret.data.data;

                const arr = [] as any;
                let result = valor;
                result.map((user: any) => {
                    return arr.push({ value: String(user.ctc_id + '_' + user.ctc_description), label: user.ctc_description });
                });
                setOptionContract(arr)
            }
        } catch (e) {
            setOptionContract([])
        }
    }


    async function GetUnidadeSel(id: any) {
        let ret: AxiosResponse;
        let valor: any;


        setOptionUnity([]);

        if (id === '' || id === 'Selecione...' || id === 'undefined' || id === undefined) {
            setUnity('')
            setOptionUnity([])
            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Selecione o contrato.</span></div>
                <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
            </div>)

            setOpenModal(true)
        } else {
            try {
                ret = await api.get("unity/listar", {
                    params: {
                        id: id
                    }
                })
                if (ret.data.status === 200) {
                    valor = ret.data.data;

                    const arr = [] as any;
                    let result = valor;
                    result.map((user: any) => {
                        return arr.push({ value: String(user.unt_id + "_" + user.unt_description), label: user.unt_description });
                    });
                    setOptionUnity(arr)

                }
                setOpenModal(false)
            } catch (e) {

                setOptionUnity([]);
                setOpenModal(false)
            }
        }

    }

    function Cancelar() {
        navigate('/asset');
    }

    function salvarInventario() {


        setQualAlert(<div className='div-alert'><Loading /></div>)
        setOpenModal(true)

        const FiltrarAlternativeIdentifier = optionsAtivo.filter(
            (e: any) => {
                return String(e.ast_alternativeidentifier || "").toLowerCase() === alternativeIdentifier.toLowerCase()
            }
        );

        const FiltrarSerialNumber = optionsAtivo.filter(
            (e: any) => {
                return String(e.ast_serialnumber || "").toLowerCase() === serialNumber.toLowerCase()
            }
        );

        const FiltrarTombamento = optionsAtivo.filter(
            (e: any) => {
                return String(e.ast_tombamento || "").toLowerCase() === tombamento.toLowerCase()
            }
        );


        if (status === "") {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Selecione o status.</span></div>
                <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
            </div>)

            setOpenModal(true)



        } else {






            if (idInventorio !== '' && status === '1') {


                if (statusInicial === '2' && status === '1') {


                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Não é possível alterar o status sendo utilizado para estoque, você pode fazer a devolução do ativo.</span></div>
                        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                    </div>)

                    setOpenModal(true)

                } else if (statusInicial === '3' && status === '1') {

                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Não é possível devolver um ativo que está fora de uso.</span></div>
                        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                    </div>)

                    setOpenModal(true)

                } else {



                    if (description === '') {

                        setQualAlert(<div className='div-alert'>
                            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                            <div className='div-info-alert'><span className="info-alert">Digite a descrição.</span></div>
                            <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                        </div>)

                        setOpenModal(true)

                    } else if (category === "") {

                        setQualAlert(<div className='div-alert'>
                            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                            <div className='div-info-alert'><span className="info-alert">Selecione a categoria.</span></div>
                            <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                        </div>)

                        setOpenModal(true)

                    } else if (subcategory === "") {

                        setQualAlert(<div className='div-alert'>
                            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                            <div className='div-info-alert'><span className="info-alert">Selecione a subcategoria.</span></div>
                            <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                        </div>)

                        setOpenModal(true)

                    } else if (alternativeIdentifier === "") {

                        setQualAlert(<div className='div-alert'>
                            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                            <div className='div-info-alert'><span className="info-alert">Digite o Identificador Alternativo.</span></div>
                            <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                        </div>)

                        setOpenModal(true)

                    }


                    else if (product === "") {

                        setQualAlert(<div className='div-alert'>
                            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                            <div className='div-info-alert'><span className="info-alert">Selecione o produto.</span></div>
                            <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                        </div>)

                        setOpenModal(true)


                    } else if (typeOfDepreciation !== "" && cust === "") {


                        setQualAlert(<div className='div-alert'>
                            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                            <div className='div-info-alert'><span className="info-alert">Você indicou o tipo de depreciação para realizarmos o calculo precisamos saber o custo do ativo.</span></div>
                            <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                        </div>)

                        setOpenModal(true)

                    } else
                        if (alternativeIdentifier.toLowerCase() !== "" && alternativeIdentifierIni.toLowerCase() !== alternativeIdentifier.toLowerCase() && FiltrarAlternativeIdentifier.length > 0) {

                            setQualAlert(<div className='div-alert'>
                                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                                <div className='div-info-alert'><span className="info-alert">Identificador alternativo já está em uso.</span></div>
                                <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                            </div>)

                        } else if (serialNumber !== "" && serialNumberIni.toLowerCase() !== serialNumber.toLowerCase() && FiltrarSerialNumber.length > 0) {

                            setQualAlert(<div className='div-alert'>
                                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                                <div className='div-info-alert'><span className="info-alert">Número de série já está em uso.</span></div>
                                <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                            </div>)

                            setOpenModal(true)

                        } else if (tombamento !== "" && tombamentoIni.toLowerCase() !== tombamento.toLowerCase() && FiltrarTombamento.length > 0) {

                            setQualAlert(<div className='div-alert'>
                                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                                <div className='div-info-alert'><span className="info-alert">Tombamento já está em uso.</span></div>
                                <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                            </div>)

                            setOpenModal(true)
                        }

                        else {

                            let newArray: any;
                            let newArray2 = [];
                            let newArray3: any;
                            let newArray4: any;
                            let statusMovi = '1'


                            newArray = [];
                            newArray3 = [];
                            newArray4 = [];

                            newArray2.push({
                                ast_id: id,
                                age_id: secureLocalStorage.getItem('iag'),
                                atv_type: 'ativo',
                                atv_description: `Alterou o ativo ${description}.`,
                                atv_idcontrol: id
                            })


                            const dadosModificados = {
                                unt_id: '',
                                ctc_id: '',
                                loc_id: '',
                                ast_usedby: '',
                            }

                            let alterouCusto: any;

                            if (cust !== custInicial) {
                                alterouCusto = 'S'
                            } else {
                                alterouCusto = 'N'
                            }

                            UpdateInventarioNota(newArray, newArray2, newArray3, statusMovi, newArray4, dadosModificados, alterouCusto)
                        }
                }
            } if (idInventorio !== '' && status === '2') {


                if (description === '') {

                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Digite a descrição.</span></div>
                        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                    </div>)

                    setOpenModal(true)

                } else if (category === "") {

                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Selecione a categoria.</span></div>
                        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                    </div>)

                    setOpenModal(true)

                } else if (subcategory === "") {

                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Selecione a subcategoria.</span></div>
                        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                    </div>)

                    setOpenModal(true)

                } else if (alternativeIdentifier === "") {

                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Digite o Identificador Alternativo.</span></div>
                        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                    </div>)

                    setOpenModal(true)

                } else if (product === "") {

                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Selecione o produto.</span></div>
                        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                    </div>)

                    setOpenModal(true)

                } else if (typeOfDepreciation !== "" && cust === "") {

                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Você indicou o tipo de depreciação para realizarmos o calculo precisamos saber o custo do ativo.</span></div>
                        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                    </div>)

                    setOpenModal(true)

                } else
                    if (alternativeIdentifier !== "" && alternativeIdentifierIni !== alternativeIdentifier && FiltrarAlternativeIdentifier.length > 0) {

                        setQualAlert(<div className='div-alert'>
                            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                            <div className='div-info-alert'><span className="info-alert">Identificador alternativo já está em uso.</span></div>
                            <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                        </div>)

                    } else if (serialNumber !== "" && serialNumberIni !== serialNumber && FiltrarSerialNumber.length > 0) {

                        setQualAlert(<div className='div-alert'>
                            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                            <div className='div-info-alert'><span className="info-alert">Número de série já está em uso.</span></div>
                            <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                        </div>)

                        setOpenModal(true)

                    } else if (tombamento !== "" && tombamentoIni !== tombamento && FiltrarTombamento.length > 0) {

                        setQualAlert(<div className='div-alert'>
                            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                            <div className='div-info-alert'><span className="info-alert">Tombamento já está em uso.</span></div>
                            <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                        </div>)

                        setOpenModal(true)
                    }
                    else if (contract.split('_')[0] === '' || contract.split('_')[0] === 'null') {

                        setQualAlert(<div className='div-alert'>
                            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                            <div className='div-info-alert'><span className="info-alert">Selecione o contrato que o ativo está vinculado.</span></div>
                            <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                        </div>)

                        setOpenModal(true)

                    } else if (unity.split('_')[0] === '' || unity.split('_')[0] === 'null') {


                        setQualAlert(<div className='div-alert'>
                            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                            <div className='div-info-alert'><span className="info-alert">Selecione a unidade que o ativo está vinculado.</span></div>
                            <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                        </div>)

                        setOpenModal(true)

                    } else if (pavement === "") {

                        setQualAlert(<div className='div-alert'>
                            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                            <div className='div-info-alert'><span className="info-alert">Selecione o pavimento.</span></div>
                            <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                        </div>)

                        setOpenModal(true)

                    } else if (department === "") {

                        setQualAlert(<div className='div-alert'>
                            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                            <div className='div-info-alert'><span className="info-alert">Selecione o local.</span></div>
                            <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                        </div>)

                        setOpenModal(true)

                    } else {





                        let newArray = []
                        let newArray2 = []
                        let newArray3 = []
                        let newArray4: any
                        newArray4 = []


                        let statusMovi = status


                        if (String(unity.split('_')[0]) === String(unityInicial.split('_')[0])) {


                        } else {


                            let qualDescricao = ''

                            let stt = statusInicial

                            if (stt === '1') {

                                statusMovi = '2'
                                qualDescricao = `Realizou uma movimentação de estoque para o contrato ${contract.split('_')[1]} unidade ${unity.split('_')[1]}.`
                            } else if (stt === '2' || stt === '3') {

                                statusMovi = '2'
                                qualDescricao = `Realizou uma movimentação da unidade ${unityInicial.split('_')[1]} para o contrato ${contract.split('_')[1]} unidade ${unity.split('_')[1]}.`
                            } else {

                                statusMovi = stt
                            }

                            newArray.push({
                                ast_id: id,
                                age_id: secureLocalStorage.getItem('iag'),
                                atv_type: 'movimentacao',
                                atv_description: qualDescricao,
                                atv_idcontrol: id
                            })

                            let qualStatus = ''

                            if (stt === '1') {
                                qualStatus = "estoque"
                            } else if (stt === '2' || stt === '3') {
                                qualStatus = "contrato"
                            }

                            let unityInicialVazio = null

                            if (unityInicial.split('_')[0] === null || unityInicial.split('_')[0] === 'null') {
                                unityInicialVazio = null
                            } else {
                                unityInicialVazio = unityInicial.split('_')[0]
                            }



                            newArray3.push({
                                ast_id: id,
                                mov_sourceunit: unityInicialVazio,
                                mov_destinyunit: unity.split('_')[0] || null,
                                mov_destinyloc: department,
                                mov_usedby: user,
                                mov_observation: null,
                                age_id_insert: secureLocalStorage.getItem('iag'),
                                mov_type: qualStatus
                            })



                        }

                        const dadosModificados = {
                            unt_id: unity.split('_')[0] || null,
                            ctc_id: contract.split('_')[0] || null,
                            loc_id: department || null,
                            ast_usedby: user || null,
                        }

                        newArray2.push({
                            ast_id: id,
                            age_id: secureLocalStorage.getItem('iag'),
                            atv_type: 'ativo',
                            atv_description: `Alterou o ativo ${description}.`,
                            atv_idcontrol: id
                        })

                        let alterouCusto: any;

                        if (cust !== custInicial) {
                            alterouCusto = 'S'
                        } else {
                            alterouCusto = 'N'
                        }

                        UpdateInventarioNota(newArray, newArray2, newArray3, statusMovi, newArray4, dadosModificados, alterouCusto)





                    }


            }

            if (idInventorio !== '' && status === '3') {












                if (description === '') {

                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Digite a descrição.</span></div>
                        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                    </div>)

                    setOpenModal(true)

                } else if (category === "") {

                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Selecione a categoria.</span></div>
                        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                    </div>)

                    setOpenModal(true)

                } else if (subcategory === "") {

                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Selecione a subcategoria.</span></div>
                        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                    </div>)

                    setOpenModal(true)

                } else if (alternativeIdentifier === "") {

                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Digite o Identificador Alternativo.</span></div>
                        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                    </div>)

                    setOpenModal(true)

                } else if (product === "") {

                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Selecione o produto.</span></div>
                        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                    </div>)

                    setOpenModal(true)

                } else if (typeOfDepreciation !== "" && cust === "") {

                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Você indicou o tipo de depreciação para realizarmos o calculo precisamos saber o custo do ativo.</span></div>
                        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                    </div>)

                    setOpenModal(true)

                } else
                    if (alternativeIdentifier !== "" && alternativeIdentifierIni !== alternativeIdentifier && FiltrarAlternativeIdentifier.length > 0) {

                        setQualAlert(<div className='div-alert'>
                            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                            <div className='div-info-alert'><span className="info-alert">Identificador alternativo já está em uso.</span></div>
                            <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                        </div>)

                    } else if (serialNumber !== "" && serialNumberIni !== serialNumber && FiltrarSerialNumber.length > 0) {

                        setQualAlert(<div className='div-alert'>
                            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                            <div className='div-info-alert'><span className="info-alert">Número de série já está em uso.</span></div>
                            <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                        </div>)

                        setOpenModal(true)

                    } else if (tombamento !== "" && tombamentoIni !== tombamento && FiltrarTombamento.length > 0) {

                        setQualAlert(<div className='div-alert'>
                            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                            <div className='div-info-alert'><span className="info-alert">Tombamento já está em uso.</span></div>
                            <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                        </div>)

                        setOpenModal(true)
                    }
                    else if (contract.split('_')[0] === '' || contract.split('_')[0] === 'null') {

                        setQualAlert(<div className='div-alert'>
                            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                            <div className='div-info-alert'><span className="info-alert">Selecione o contrato que o ativo estava vinculado antes de se tornar fora de uso.</span></div>
                            <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                        </div>)

                        setOpenModal(true)


                    } else if (unity.split('_')[0] === '' || unity.split('_')[0] === 'null') {

                        setQualAlert(<div className='div-alert'>
                            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                            <div className='div-info-alert'><span className="info-alert">Selecione a unidade que o ativo estava vinculado antes de se tornar fora de uso.</span></div>
                            <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                        </div>)

                        setOpenModal(true)

                    } else if (pavement === "") {

                        setQualAlert(<div className='div-alert'>
                            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                            <div className='div-info-alert'><span className="info-alert">Selecione o pavimento.</span></div>
                            <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                        </div>)

                        setOpenModal(true)

                    } else if (department === "") {

                        setQualAlert(<div className='div-alert'>
                            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                            <div className='div-info-alert'><span className="info-alert">Selecione o local.</span></div>
                            <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                        </div>)

                        setOpenModal(true)

                    } else {

                        if (statusInicial === '3' && status === '3') {

                            let newArray = []
                            let newArray2 = []
                            let newArray3 = []
                            let newArray4: any
                            newArray4 = []

                            let statusMovi = status



                            if (String(unity.split('_')[0]) === String(unityInicial.split('_')[0])) {


                            } else {

                                let unityInicialVazio = null

                                if (unityInicial.split('_')[0] === null || unityInicial.split('_')[0] === 'null') {
                                    unityInicialVazio = null
                                } else {
                                    unityInicialVazio = unityInicial.split('_')[0]
                                }

                                let qualStatus = "contrato"


                                newArray3.push({
                                    ast_id: id,
                                    mov_sourceunit: unityInicialVazio,
                                    mov_destinyunit: unity.split('_')[0] || null,
                                    mov_destinyloc: department || null,
                                    mov_usedby: user || null,
                                    mov_observation: null,
                                    age_id_insert: secureLocalStorage.getItem('iag'),
                                    mov_type: qualStatus
                                })

                                let qualDescricao = `Realizou uma movimentação da unidade ${unityInicial.split('_')[1]} para o contrato ${contract.split('_')[1]} unidade ${unity.split('_')[1]}.`

                                newArray.push({
                                    ast_id: id,
                                    age_id: secureLocalStorage.getItem('iag'),
                                    atv_type: 'movimentacao',
                                    atv_description: qualDescricao,
                                    atv_idcontrol: id
                                })

                            }

                            newArray2.push({
                                ast_id: id,
                                age_id: secureLocalStorage.getItem('iag'),
                                atv_type: 'ativo',
                                atv_description: `Alterou o ativo ${description} em relação ao status para fora de uso indicando que: ${observationOcorrencia}`,
                                atv_idcontrol: id
                            })



                            //criar o correncia
                            const dadosModificados = {
                                unt_id: unity.split('_')[0] || null,
                                ctc_id: contract.split('_')[0] || null,
                                loc_id: department || null,
                                ast_usedby: user || null,
                            }

                            let alterouCusto: any;

                            if (cust !== custInicial) {
                                alterouCusto = 'S'
                            } else {
                                alterouCusto = 'N'
                            }

                            UpdateInventarioNota(newArray, newArray2, newArray3, statusMovi, newArray4, dadosModificados, alterouCusto)









                        } else {


                            setOpenModal(false)
                            setTitulo('Informações da ocorrencia')
                            setObservationOcorrencia("")
                            setExibirOcorrido("S")
                            setExibirDevolucao('N')
                            setOpenModalIncluirNovoInf(true)

                        }

                    }











            }

            if (idInventorio !== '' && status === '4') {



                if (statusInicial === '1' && status === '4') {

                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Não é possível devolver um ativo que está em estoque.</span></div>
                        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                    </div>)

                    setOpenModal(true)


                } else if (statusInicial === '3' && status === '4') {



                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Não é possível devolver um ativo que está fora de uso.</span></div>
                        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                    </div>)

                    setOpenModal(true)

                } else {



                    if (description === '') {

                        setQualAlert(<div className='div-alert'>
                            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                            <div className='div-info-alert'><span className="info-alert">Digite a descrição.</span></div>
                            <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                        </div>)

                        setOpenModal(true)

                    } else if (category === "") {

                        setQualAlert(<div className='div-alert'>
                            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                            <div className='div-info-alert'><span className="info-alert">Selecione a categoria.</span></div>
                            <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                        </div>)

                        setOpenModal(true)

                    } else if (subcategory === "") {

                        setQualAlert(<div className='div-alert'>
                            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                            <div className='div-info-alert'><span className="info-alert">Selecione a subcategoria.</span></div>
                            <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                        </div>)

                        setOpenModal(true)

                    } else if (alternativeIdentifier === "") {

                        setQualAlert(<div className='div-alert'>
                            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                            <div className='div-info-alert'><span className="info-alert">Digite o Identificador Alternativo.</span></div>
                            <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                        </div>)

                        setOpenModal(true)

                    } else if (product === "") {

                        setQualAlert(<div className='div-alert'>
                            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                            <div className='div-info-alert'><span className="info-alert">Selecione o produto.</span></div>
                            <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                        </div>)

                        setOpenModal(true)

                    } else if (typeOfDepreciation !== "" && cust === "") {

                        setQualAlert(<div className='div-alert'>
                            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                            <div className='div-info-alert'><span className="info-alert">Você indicou o tipo de depreciação para realizarmos o calculo precisamos saber o custo do ativo.</span></div>
                            <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                        </div>)

                        setOpenModal(true)

                    } else
                        if (alternativeIdentifier !== "" && alternativeIdentifierIni !== alternativeIdentifier && FiltrarAlternativeIdentifier.length > 0) {

                            setQualAlert(<div className='div-alert'>
                                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                                <div className='div-info-alert'><span className="info-alert">Identificador alternativo já está em uso.</span></div>
                                <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                            </div>)

                        } else if (serialNumber !== "" && serialNumberIni !== serialNumber && FiltrarSerialNumber.length > 0) {

                            setQualAlert(<div className='div-alert'>
                                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                                <div className='div-info-alert'><span className="info-alert">Número de série já está em uso.</span></div>
                                <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                            </div>)

                            setOpenModal(true)

                        } else if (tombamento !== "" && tombamentoIni !== tombamento && FiltrarTombamento.length > 0) {

                            setQualAlert(<div className='div-alert'>
                                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                                <div className='div-info-alert'><span className="info-alert">Tombamento já está em uso.</span></div>
                                <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                            </div>)

                            setOpenModal(true)
                        }

                        else {
                            setOpenModal(false)
                            setTitulo('Informações da ocorrencia')
                            setObservationOcorrencia("")
                            setExibirOcorrido("N")
                            setExibirDevolucao('S')
                            setOpenModalIncluirNovoInf(true)
                        }




                }




            }
        }







    }


    function salvarOcorrencia() {
        setQualAlert(<div className='div-alert'><Loading /></div>)
        setOpenModal(true)

        if (observationOcorrencia === '') {
            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Precisamos entender o que aconteceu para o ativo se tornar fora de uso.</span></div>
                <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
            </div>)

            setOpenModal(true)

        } else {



            let newArray = []
            let newArray2 = []
            let newArray3 = []
            let newArray4 = []
            let statusMovi = status



            if (String(unity.split('_')[0]) === String(unityInicial.split('_')[0])) {


            } else {

                let unityInicialVazio = null

                if (unityInicial.split('_')[0] === null || unityInicial.split('_')[0] === 'null') {
                    unityInicialVazio = null
                } else {
                    unityInicialVazio = unityInicial.split('_')[0]
                }

                let qualStatus = ''

                if (statusInicial === '1') {
                    qualStatus = "estoque"
                } else if (statusInicial === '2' || statusInicial === '3') {
                    qualStatus = "contrato"
                }

                newArray3.push({
                    ast_id: id,
                    mov_sourceunit: unityInicialVazio,
                    mov_destinyunit: unity.split('_')[0] || null,
                    mov_destinyloc: department || null,
                    mov_usedby: user || null,
                    mov_observation: null,
                    age_id_insert: secureLocalStorage.getItem('iag'),
                    mov_type: qualStatus
                })

                let qualDescricao = ''

                if (statusInicial === '1') {
                    qualDescricao = `Realizou uma movimentação de estoque para o contrato ${contract.split('_')[1]} unidade ${unity.split('_')[1]}.`
                } else if (statusInicial === '2' || statusInicial === '3') {
                    qualDescricao = `Realizou uma movimentação da unidade ${unityInicial.split('_')[1]} para o contrato ${contract.split('_')[1]} unidade ${unity.split('_')[1]}.`
                }

                newArray.push({
                    ast_id: id,
                    age_id: secureLocalStorage.getItem('iag'),
                    atv_type: 'movimentacao',
                    atv_description: qualDescricao,
                    atv_idcontrol: id
                })

            }

            newArray2.push({
                ast_id: id,
                age_id: secureLocalStorage.getItem('iag'),
                atv_type: 'ativo',
                atv_description: `Alterou o ativo ${description} em relação ao status para fora de uso indicando que: ${observationOcorrencia}`,
                atv_idcontrol: id
            })

            newArray4.push({
                ast_id: id,
                age_id_insert: secureLocalStorage.getItem('iag'),
                occ_occurrence: observationOcorrencia,
                occ_assetcondition: 'FORAUSO',
                occ_requester: null
            })

            //criar o correncia
            const dadosModificados = {
                unt_id: unity.split('_')[0] || null,
                ctc_id: contract.split('_')[0] || null,
                loc_id: department || null,
                ast_usedby: user || null,
            }

            let alterouCusto: any;

            if (cust !== custInicial) {
                alterouCusto = 'S'
            } else {
                alterouCusto = 'N'
            }

            UpdateInventarioNota(newArray, newArray2, newArray3, statusMovi, newArray4, dadosModificados, alterouCusto)



        }
    }

    function salvarDevolucao() {
        setQualAlert(<div className='div-alert'><Loading /></div>)
        setOpenModal(true)


        if (observationOcorrencia === '') {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">O ativo retornarar ao estoque precisamos entender o que aconteceu para a devolução.</span></div>
                <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
            </div>)

            setOpenModal(true)


        } else {


            let newArray = []
            let newArray2 = []
            let newArray3 = []
            let newArray4 = []
            let statusMovi = '1'

            newArray.push({
                ast_id: id,
                age_id: secureLocalStorage.getItem('iag'),
                atv_type: 'movimentacao',
                atv_description: `Realizou uma movimentação da unidade ${unityInicial.split('_')[1]} para o estoque.`,
                atv_idcontrol: id
            })

            newArray2.push({
                ast_id: id,
                age_id: secureLocalStorage.getItem('iag'),
                atv_type: 'ativo',
                atv_description: `Alterou o ativo ${description} em relação ao status para devolvido indicando que: ${observationOcorrencia}`,
                atv_idcontrol: id
            })

            newArray4.push({
                ast_id: id,
                age_id_insert: secureLocalStorage.getItem('iag'),
                occ_occurrence: observationOcorrencia,
                occ_assetcondition: 'DEVOLVIDO',
                occ_requester: null,
            })

            newArray3.push({
                ast_id: id,
                mov_sourceunit: unityInicial.split('_')[0] || null,
                mov_destinyunit: null,
                mov_destinyloc: null,
                mov_usedby: null,
                mov_observation: null,
                age_id_insert: secureLocalStorage.getItem('iag'),
                mov_type: 'contrato'
            })


            const dadosModificados = {
                unt_id: '',
                ctc_id: '',
                loc_id: '',
                ast_usedby: '',
            }

            let alterouCusto: any;

            if (cust !== custInicial) {
                alterouCusto = 'S'
            } else {
                alterouCusto = 'N'
            }

            UpdateInventarioNota(newArray, newArray2, newArray3, statusMovi, newArray4, dadosModificados, alterouCusto)
        }
    }


    async function UpdateInventarioNota(dataMovimentacao: any, dataInventario: any, moviDados: any, statusMovi: any, ocorrenciaDados: any, dadosModificados: any, alterouCusto: any) {

        let newArray = [] as any
        let ret: AxiosResponse;

        const dados = {
            ast_id: id,
            ast_active: active,
            ast_description: description,
            pdc_id: product || null,
            inc_id: idinvoice || null,
            ssa_id: statusMovi || null,
            unt_id: dadosModificados.unt_id || null,
            ast_observation: observation,
            loc_id: dadosModificados.loc_id || null,
            ast_usedby: dadosModificados.ast_usedby || null,
            ast_serialnumber: serialNumber,
            ast_mac: mac,
            ast_tombamento: tombamento,
            ast_residualvalue: residualValue,
            dpc_id: typeOfDepreciation || null,
            ast_expirationdate: expirationDate,
            ast_cust: cust,
            ctc_id: dadosModificados.ctc_id || null,
            ast_invoicenumber: invoice,
            age_id_lastupdate: secureLocalStorage.getItem('iag'),
            ast_issuedate: issueDate || null,
            ast_warrantymonth: warrantyMonth || '0',
            ast_alternativeidentifier: alternativeIdentifier,
            ast_costcenter: ast_costcenter|| null
        }

        try {
            ret = await api.put("asset/updateassetmanual", {
                data: dados,
                dataMovimentacao: dataMovimentacao,
                dataInventario: dataInventario,
                moviDados: moviDados,
                ocorrenciaDados: ocorrenciaDados,
                alterouCusto: alterouCusto
            });
            if (ret.data.status === 200) {

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-check ControleIconAlert"></span>
                    <div className='div-info-alert'><span className="info-alert">Alteração realizada com sucesso!</span></div>
                    <div className='btn-alert' onClick={fecharModalOk}><span className="btn-alert-lab">OK</span></div>
                </div>)


                setOpenModal(true)


            }
        } catch (e: any) {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Não foi possível concluir a alteração.</span></div>
                <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
            </div>)

            setOpenModal(true)


        }
    }

    function fecharModalOk() {
        navigate('/asset');
    }

    async function insertInventoryManual(movimentacao: any, ocorrencia: any) {
        let ret: AxiosResponse;

        const dados = [{
            ast_active: active,
            ast_description: description,
            pdc_id: product || null,
            inc_id: idinvoice || null,
            ssa_id: status || null,
            unt_id: unity.split('_')[0] || null,
            ast_observation: observation || null,
            loc_id: department || null,
            ast_usedby: user || null,
            ast_serialnumber: serialNumber || null,
            ast_mac: mac || null,
            ast_tombamento: tombamento || null,
            ast_residualvalue: residualValue || null,
            dpc_id: typeOfDepreciation || null,
            ast_expirationdate: expirationDate || null,
            ast_cust: cust,
            ctc_id: contract.split('_')[0] || null,
            ast_invoicenumber: invoice || null,
            age_id_lastupdate: secureLocalStorage.getItem('iag'),
            ast_issuedate: issueDate || null,
            ast_warrantymonth: warrantyMonth || null,
        }]

        try {
            ret = await api.post("asset/insertassetmanual", {
                data: dados,
                dataMovimentacao: movimentacao,
                dataOcorrencia: ocorrencia
            });
            if (ret.data.status === 200) {

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-check ControleIconAlert"></span>
                    <div className='div-info-alert'><span className="info-alert">Ativo incluido com sucesso.</span></div>
                    <div className='btn-alert' onClick={fecharModalOk}><span className="btn-alert-lab">OK</span></div>
                </div>)


                setOpenModal(true)

            }
        } catch (e: any) {
            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Não foi possivel concluir o cadastramento.</span></div>
                <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
            </div>)

            setOpenModal(true)
        }
    }


    function IncluirUnity(Unidade: any) {

        if (Unidade === '') {
            setUnity('')
            setOptionsDepartment([])
            setDepartment("")
            setOptionspavement([])
            setPavement("")
            setUser("")
        } else {
            setQualAlert(<div className='div-alert'><Loading /></div>)
            setOpenModal(true)
            setDepartment("")
            setPavement("")
            GetDepartment(Unidade.split('_')[0])
            setUnity(Unidade)
        }
    }

    function IncluirContrato(Contrato: any) {


        if (Contrato === '' || Contrato === 'Selecione...') {
            setContract('')
            setOptionUnity([])
            setUnity("")
            setOptionsDepartment([])
            setDepartment("")
            setOptionspavement([])
            setPavement("")
            setUser("")
        } else {

            setQualAlert(<div className='div-alert'><Loading /></div>)
            setOpenModal(true)

            setOptionspavement([])
            setOptionsDepartment([])
            setUser("")
            setUnity('')
            GetUnidadeSel(Contrato.split('_')[0])
            setContract(Contrato)
        }
    }

    function incluirCategory(Category: any) {
        setSubcategory('');
        setQualForm(<></>)
        setManufacturer('')
        setBrand('')
        setModel('')
        setProduct('')
        setOptionProduct([])
        if (Category === '' || Category === 'Selecione...') {

            setCategory('');
            setIsDisabledSubcategory(true)
        } else {
            Getsubcategory(Category);
            setIsDisabledSubcategory(false)
            setCategory(Category);
        }
    }

    function incluirSubCategory(SubCategory: any) {

        setQualForm(<></>)
        setManufacturer('')
        setBrand('')
        setModel('')
        setProduct('')
        setOptionProduct([])

        if (SubCategory === '' || SubCategory === 'Selecione...') {
            setSubcategory('');
        } else {
            setQualAlert(<div className='div-alert'><Loading /></div>)
            setOpenModal(true)
            GetProduto(SubCategory);
            setSubcategory(SubCategory);
        }
    }

    function incluirStatus(Status: any) {

        if (Status === '' || Status === 'Selecione...') {

            setStatus('');
            setSerialNumberDisabled(false)
            setTombamentoDisabled(false)
            setMacDisabled(false)

            setPavement("")
            setDepartment("")
            setContract("");
            setUnity("");
            setDepartment("");
            setUser("");
            setIsDisabledContract(true)
            setIsDisabledUnity(true)
            setDisabledDepartment(true)
            setIsDisabledPavement(true)
            setUserDisabled(true)
            setIsDisabledDepreciation(false)

        } else {
            setStatus(Status);


            if (idInventorio !== '' && Status === '3') {
            
                setSerialNumberDisabled(false)
                setTombamentoDisabled(false)
                setMacDisabled(false)
                setIsDisabledContract(false);
                setIsDisabledUnity(false)
                setDisabledDepartment(false)
                setIsDisabledDepreciation(false)
                setIsDisabledPavement(false)
                setUserDisabled(false)
            }
            if (idInventorio !== '' && Status === '4') {

                setPavement("")
                setContract("");
                setUnity("");
                setDepartment("");
                setUser("");
                setIsDisabledContract(true)
                setIsDisabledUnity(true)
                setDisabledDepartment(true)
                setIsDisabledPavement(true)
                setIsDisabledDepreciation(false)
                setUserDisabled(true)


            }
            if (idInventorio !== '' && Status === "2") {

                setSerialNumberDisabled(false)

                setIsDisabledContract(false)
                setIsDisabledUnity(false)
                setDisabledDepartment(false)
                setIsDisabledPavement(false)
                setIsDisabledDepreciation(false)

                setUserDisabled(false)

            }
            if (idInventorio !== '' && Status === '1') {

                setSerialNumberDisabled(false)
                setTombamentoDisabled(false)
                setMacDisabled(false)


                setPavement("")
                setContract("");
                setUnity("");
                setDepartment("");
                setUser("");
                setIsDisabledContract(true)
                setIsDisabledUnity(true)
                setDisabledDepartment(true)
                setIsDisabledPavement(true)
                setIsDisabledDepreciation(false)
                setUserDisabled(true)
            }

        }
    }

    function validarIssueDate(valor: any) {



        if (valor === '') {
            setIssueDate(valor)
            setWarrantyMonth('')
            setExpirationDate('')
        } else {

            let today = new Date();
            let date =
                today.getFullYear() +
                "-" +
                (today.getMonth() + 1).toString().padStart(2, "0") +
                "-" +
                today.getDate().toString().padStart(2, "0");
            let time =
                today.getHours().toString().padStart(2, "0") +
                ":" +
                today.getMinutes().toString().padStart(2, "0");

            if (moment(valor).isAfter(date)) {

                setWarrantyMonth('')
                setExpirationDate('')

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">A data de aquisição não pode ser maior que a data atual.</span></div>
                    <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)


            } else {
                setIssueDate(valor)
                if (warrantyMonth !== "") {
                    let mes = showDate(valor, { ...daysMonthsYears, days: Number(0), months: Number(warrantyMonth), years: Number(0) })
                    setExpirationDate(showDate(mes, { ...daysMonthsYears, days: Number(0), months: Number(0), years: Number(0) }))
                } else {
                    setExpirationDate('')
                }
            }
        }
    }


    function validarWarrantyMonth(valor: any) {

        setWarrantyMonth(valor)
        if (valor !== "") {

            let mes = showDate(issueDate, { ...daysMonthsYears, days: Number(0), months: Number(valor), years: Number(0) })
            setExpirationDate(showDate(mes, { ...daysMonthsYears, days: Number(0), months: Number(0), years: Number(0) }))
        }

    }

    const customStyles = {
        control: (provided: any, state: any) => ({
            ...provided,
            background: '#fff',
            minHeight: '30px',
            height: '30px',
            borderColor: state.isFocused ? 'grey' : '#92989e',

        }),


        valueContainer: (provided: any, state: any) => ({
            ...provided,
            height: '30px',
            padding: '0 6px'
        }),

        input: (provided: any, state: any) => ({
            ...provided,
            margin: '0px',
            fontSize: 14,

        }),
        indicatorsContainer: (provided: any, state: any) => ({
            ...provided,
            height: '30px',
        }),
    };

    function validarNumber(number: any) {
        var valor = number

        valor = valor + '';
        valor = parseInt(valor.replace(/[\D]+/g, ''));
        valor = valor + '';

        if (valor == 'NaN') {

        } else {

            if (valor === '') {

                if (warrantyMonth !== "") {
                    let mes = showDate(issueDate, { ...daysMonthsYears, days: Number(0), months: Number(warrantyMonth), years: Number(0) })
                    setExpirationDate(showDate(mes, { ...daysMonthsYears, days: Number(0), months: Number(0), years: Number(valor) }))
                } else {
                    setExpirationDate("")
                }
            } else {

                if (warrantyMonth !== "" || valor !== "") {

                    let mes = showDate(issueDate, { ...daysMonthsYears, days: Number(0), months: Number(warrantyMonth), years: Number(0) })
                    setExpirationDate(showDate(mes, { ...daysMonthsYears, days: Number(0), months: Number(0), years: Number(valor) }))
                }
            }
        }

    }


    function incluirValor(value: any) {


        var valor = value
        valor = valor + '';
        valor = parseInt(valor.replace(/[\D]+/g, ''));
        valor = valor + '';
        valor = valor.replace(/([0-9]{2})$/g, ".$1");
        if (valor.length > 6) {
            valor = valor.replace(/([0-9]{3}),([0-9]{2}$)/g, "$1.$2");
        }
        setCust(valor)
        if (valor == 'NaN') {
            setCust('0');
        }

    }

    //-------------------------------------------------ADD PRODUTO

    function incluirProduto() {
        if(secureLocalStorage.getItem('acp') === 'DESENVOLVEDOR' || secureLocalStorage.getItem('acp') === 'ADMINISTRADOR'){
        if (status !== "") {

            if (subcategory !== '') {

                setTitulo('Cadastrar Produto')
                setQualGrid(<><><GridSecundario
                    Iconeadd={ChamarCadastroProduto}
                    VoltarModalGrid={VoltarModalGrid}
                    iconeExcluir={ModalDeletar}
                    Iconeditar={EditarCustomizado}
                    PegarValorGrid={PegarValorGridProduct}
                    th1='Ativo'
                    th2='Descrição'
                    th3='Identificador Alternativo'
                    th4='Subcategoria'
                    th5='Data da Inclusão'
                    th6='Data Atualização'
                    endPoint='product/produtosub'
                    consult='N'
                    col1='1'
                    col2='2'
                    col3='3'
                    ambiente={subcategory}
                    dbtnDelete="N"
                /></>
                </>)
                setExibirModalGrid('S')
                setExibirOcorrido("N")
                setExibirDevolucao('N')
                setOpenModalIncluirNovo(true)
            } else {
                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Selecione a subcategoria.</span></div>
                    <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)
            }
        } else {
            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Selecione o status para continuar</span></div>
                <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
            </div>)

            setOpenModal(true)
        }
    }

    }

    function fecharModal() {
        setOpenModal(false)
    }

    function PegarValorGridProduct(valor: any) {

        GetCarregaInf(String(valor.pdc_id))
        setOpenModalIncluirNovo(false)

    }

    function VoltarModalGrid() {
        GetProduto(subcategory);
        setTitulo('Cadastrar Produto')
        setQualGrid(<><><GridSecundario
            Iconeadd={ChamarCadastroProduto}
            VoltarModalGrid={VoltarModalGrid}
            iconeExcluir={ModalDeletar}
            Iconeditar={EditarCustomizado}
            PegarValorGrid={PegarValorGridProduct}
            th1='Ativo'
            th2='Descrição'
            th3='Identificador Alternativo'
            th4='Subcategoria'
            th5='Data da Inclusão'
            th6='Data Atualização'
            endPoint='product/produtosub'
            consult='N'
            col1='1'
            col2='2'
            col3='3'
            ambiente={subcategory}
            dbtnDelete="N"
        /></>
        </>)

    }

    function ModalDeletar(obj: any) {

        setQualAlert(<div className='div-alert'>
            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
            <div className='div-info-alert-atencao'><span className="info-alert-atencao">Deseja deletar o registro?</span></div>
            <div className='div-info-alert'><span className="info-alert">Você não poderá reverter.</span></div>

            <div className='btn-alert-div'>
                <span className='btn-alert-esc' onClick={() => deletarProduto(obj.pdc_id)}>
                    <span className="btn-alert-lab">SIM</span>
                </span>
                <span className='btn-alert-dir' onClick={finalizarModal2}>
                    <span className="btn-alert-lab">NÃO</span>
                </span>
            </div>
        </div>)

        setOpenModal(true)

    }

    function finalizarModal2() {
        setOpenModal(false)
    }

    async function deletarProduto(id: any) {
        setQualGrid(<><Loading /></>)
        let ret: AxiosResponse;

        try {
            ret = await api.get("product/encontrarprodinv", {
                params: {
                    id: id
                }
            })
            if (ret.data.status === 200) {
                if (ret.data.data.length > 0) {

                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Não é possível excluir um produto vinculado ao cadastro.</span></div>
                        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                    </div>)

                    setOpenModal(true)

                    setQualGrid(<><><GridSecundario
                        Iconeadd={ChamarCadastroProduto}
                        VoltarModalGrid={VoltarModalGrid}
                        iconeExcluir={ModalDeletar}
                        Iconeditar={EditarCustomizado}
                        PegarValorGrid={PegarValorGridProduct}
                        th1='Ativo'
                        th2='Descrição'
                        th3='Identificador Alternativo'
                        th4='Subcategoria'
                        th5='Data da Inclusão'
                        th6='Data Atualização'
                        endPoint='product/produtosub'
                        consult='N'
                        col1='1'
                        col2='2'
                        col3='3'
                        ambiente={subcategory}
                        dbtnDelete="N"
                    /></>
                    </>)
                } else {
                    let ret: AxiosResponse;
                    const dados = [{
                        id: id,
                    }]

                    try {
                        ret = await api.delete("product/delete", {
                            data: { id: id }
                        });
                        if (ret.data.status === 200) {

                            setQualAlert(<div className='div-alert'>
                                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                                <div className='div-info-alert'><span className="info-alert">O produto foi excluída com sucesso!</span></div>
                                <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                            </div>)

                            setOpenModal(true)

                            VoltarModalGrid()
                        }
                    } catch (e: any) {
                        setQualGrid(<><><GridSecundario
                            Iconeadd={ChamarCadastroProduto}
                            VoltarModalGrid={VoltarModalGrid}
                            iconeExcluir={ModalDeletar}
                            Iconeditar={EditarCustomizado}
                            PegarValorGrid={PegarValorGridProduct}
                            th1='Ativo'
                            th2='Descrição'
                            th3='Identificador Alternativo'
                            th4='Subcategoria'
                            th5='Data da Inclusão'
                            th6='Data Atualização'
                            endPoint='product/produtosub'
                            consult='N'
                            col1='1'
                            col2='2'
                            col3='3'
                            ambiente={subcategory}
                            dbtnDelete="N"
                        /></>
                        </>)
                    }
                }
            }
        } catch (e) {
            setQualGrid(<><><GridSecundario
                Iconeadd={ChamarCadastroProduto}
                VoltarModalGrid={VoltarModalGrid}
                iconeExcluir={ModalDeletar}
                Iconeditar={EditarCustomizado}
                PegarValorGrid={PegarValorGridProduct}
                th1='Ativo'
                th2='Descrição'
                th3='Identificador Alternativo'
                th4='Subcategoria'
                th5='Data da Inclusão'
                th6='Data Atualização'
                endPoint='product/produtosub'
                consult='N'
                col1='1'
                col2='2'
                col3='3'
                ambiente={subcategory}
                dbtnDelete="N"
            /></>
            </>)
        }
    }

    async function EditarCustomizado(obj: any) {
        sessionStorage.setItem("dadosProd", JSON.stringify(obj));
        setTitulo('Cadastrar Produto')
        setQualGrid(<><FormProduto VoltarModalGrid={VoltarModalGrid} /></>)
    }

    function ChamarCadastroProduto() {
        setTitulo('Cadastrar Produto')
        sessionStorage.removeItem("dadosProd");
        setQualGrid(<><FormProduto VoltarModalGrid={VoltarModalGrid} /></>)
    }

    //-------------------------------------------------ADD PRODUTO
    //-------------------------------------------------ADD CONTRATO

    function incluirContrato() {
        if(secureLocalStorage.getItem('acp') === 'DESENVOLVEDOR' || secureLocalStorage.getItem('acp') === 'ADMINISTRADOR'){
            if (status === "2" || status === "3" || status === "4") {

                setOptionUnity([])
                setOptionspavement([])
                setOptionsDepartment([])
                setUser("")
                setContract("")

                setTitulo('Cadastrar Contrato')
                setQualGrid((<><GridSecundario
                    Iconeadd={ChamarCadastroContrato}
                    Iconeditar={EditarContrato}
                    iconeExcluir={ContratoDeletar}
                    PegarValorGrid={PegarValorGridContrato}
                    th1='Ativo'
                    th2='ID'
                    th3='Descrição'
                    th4='Identificador Alternativo'
                    th5=''
                    th6=''
                    endPoint='contract/buscarcontract'
                    consult='N'
                    col1='1'
                    col2='2'
                    col3='3'
                    ambiente={'%'}
                    dbtnDelete="N"
                    pag="ok"
                /></>))
                setExibirModalGrid('S')
                setExibirOcorrido("N")
                setExibirDevolucao('N')
                setOpenModalIncluirNovo(true)
            }
        }
    }


    function ChamarCadastroContrato() {
        sessionStorage.removeItem("dadosCon");
        setQualGrid(<><FormContrato voltar={BtnVoltarContrato} /></>)
    }

    async function EditarContrato(obj: any) {
        sessionStorage.setItem("dadosCon", JSON.stringify(obj));
        setQualGrid(<><FormContrato voltar={BtnVoltarContrato} /></>)
    }

    function ContratoDeletar(obj: any) {


        setQualAlert(<div className='div-alert'>
            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
            <div className='div-info-alert-atencao'><span className="info-alert-atencao">Deseja deletar o registro?</span></div>
            <div className='div-info-alert'><span className="info-alert">Você não poderá reverter.</span></div>

            <div className='btn-alert-div'>
                <span className='btn-alert-esc' onClick={() => deletarDeletar(obj.ctc_id)}>
                    <span className="btn-alert-lab">SIM</span>
                </span>
                <span className='btn-alert-dir' onClick={finalizarModal2}>
                    <span className="btn-alert-lab">NÃO</span>
                </span>
            </div>
        </div>)

        setOpenModal(true)

    }


    async function deletarDeletar(id: any) {

        setOpenModal(false)
        setQualGrid(<><Loading /></>)
        let ret: AxiosResponse;
        try {
            ret = await api.delete("contract/delete", {
                data: { id: id }
            });
            if (ret.data.status === 200) {

                setQualGrid((<><GridSecundario
                    Iconeadd={ChamarCadastroContrato}
                    Iconeditar={EditarContrato}
                    iconeExcluir={ContratoDeletar}
                    PegarValorGrid={PegarValorGridContrato}
                    th1='Ativo'
                    th2='ID'
                    th3='Descrição'
                    th4='Identificador Alternativo'
                    th5=''
                    th6=''
                    endPoint='contract/buscarcontract'
                    consult='N'
                    col1='1'
                    col2='2'
                    col3='3'
                    ambiente={'%'}
                    dbtnDelete="N"
                    pag="ok"
                /></>))
                GetContrato();

            }
        } catch (e: any) {
            setQualGrid((<><GridSecundario
                Iconeadd={ChamarCadastroContrato}
                Iconeditar={EditarContrato}
                iconeExcluir={ContratoDeletar}
                PegarValorGrid={PegarValorGridContrato}
                th1='Ativo'
                th2='ID'
                th3='Descrição'
                th4='Identificador Alternativo'
                th5=''
                th6=''
                endPoint='contract/buscarcontract'
                consult='N'
                col1='1'
                col2='2'
                col3='3'
                ambiente={'%'}
                dbtnDelete="N"
                pag="ok"
            /></>))
        }
    }

    function BtnVoltarContrato() {

        setQualGrid((<><GridSecundario
            Iconeadd={ChamarCadastroContrato}
            Iconeditar={EditarContrato}
            iconeExcluir={ContratoDeletar}
            PegarValorGrid={PegarValorGridContrato}
            th1='Ativo'
            th2='ID'
            th3='Descrição'
            th4='Identificador Alternativo'
            th5=''
            th6=''
            endPoint='contract/buscarcontract'
            consult='N'
            col1='1'
            col2='2'
            col3='3'
            ambiente={'%'}
            dbtnDelete="N"
            pag="ok"
        /></>))
        GetContrato()
    }

    //-------------------------------------------------ADD CONTRATO
    //=================================ADD UNIDADE=============================================  
    function incluirUnidade() {
        if(secureLocalStorage.getItem('acp') === 'DESENVOLVEDOR' || secureLocalStorage.getItem('acp') === 'ADMINISTRADOR'){
            if (status === "2" || status === "3" || status === "4") {

                if (contract === '' || contract === '_null') {

                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Selecione o contrato.</span></div>
                        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                    </div>)

                    setOpenModal(true)

                } else {
                    setUnity("")
                    setOptionspavement([])
                    setOptionsDepartment([])
                    setUser("")

                    setTitulo('Cadastrar Unidade')
                    setQualGrid((<><GridSecundario
                        Iconeadd={ChamarCadastroUnidade}
                        Iconeditar={EditarUnidade}
                        iconeExcluir={UnidadeDeletar}
                        PegarValorGrid={PegarValorGridUnidade}
                        th1='Ativo'
                        th2='ID'
                        th3='Descrição'
                        th4='Identificador Alternativo'
                        th5=''
                        th6=''
                        endPoint='unity/buscarunity'
                        consult='N'
                        col1='1'
                        col2='2'
                        col3='3'
                        params={contract.split('_')[0]}
                        dbtnDelete="N"
                        pag="ok"
                    /></>))
                    setExibirModalGrid('S')
                    setExibirOcorrido("N")
                    setExibirDevolucao('N')
                    setOpenModalIncluirNovo(true)
                }
            }
        }
    }

    function ChamarCadastroUnidade() {
        sessionStorage.removeItem("dadosUni");
        setQualGrid(<><FormUnidade contract={contract} voltar={BtnVoltarUnidade} ChamarCadastroContratoUni={ChamarCadastroContratoUni} /></>)
    }

    async function EditarUnidade(obj: any) {
        sessionStorage.setItem("dadosUni", JSON.stringify(obj));
        setQualGrid(<><FormUnidade contract={contract} voltar={BtnVoltarUnidade} ChamarCadastroContratoUni={ChamarCadastroContratoUni} /></>)
    }

    function ChamarCadastroContratoUni() {
        sessionStorage.removeItem("dadosCon");
        setQualGrid(<><FormContrato voltar={ChamarCadastroUnidadeform} /></>)
    }


    function ChamarCadastroUnidadeform() {
        setQualGrid(<><FormUnidade voltar={BtnVoltarUnidade} ChamarCadastroContratoUni={ChamarCadastroContratoUni} /></>)
    }

    function BtnVoltarUnidade() {
        setQualGrid((<><GridSecundario
            Iconeadd={ChamarCadastroUnidade}
            Iconeditar={EditarUnidade}
            iconeExcluir={UnidadeDeletar}
            PegarValorGrid={PegarValorGridUnidade}
            th1='Ativo'
            th2='ID'
            th3='Descrição'
            th4='Identificador Alternativo'
            th5=''
            th6=''
            endPoint='unity/buscarunity'
            consult='N'
            col1='1'
            col2='2'
            col3='3'
            params={contract.split('_')[0]}
            dbtnDelete="N"
            pag="ok"
        /></>))
        GetUnidadeSel(contract.split('_')[0]);
    }

    function UnidadeDeletar(obj: any) {

        setQualAlert(<div className='div-alert'>
            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
            <div className='div-info-alert-atencao'><span className="info-alert-atencao">Deseja deletar o registro?</span></div>
            <div className='div-info-alert'><span className="info-alert">Você não poderá reverter.</span></div>

            <div className='btn-alert-div'>
                <span className='btn-alert-esc' onClick={() => DeletarUnidade(obj.unt_id)}>
                    <span className="btn-alert-lab">SIM</span>
                </span>
                <span className='btn-alert-dir' onClick={finalizarModal2}>
                    <span className="btn-alert-lab">NÃO</span>
                </span>
            </div>
        </div>)

        setOpenModal(true)

    }

    async function DeletarUnidade(id: any) {

        GetUnidadeSel(contract.split('_')[0]);
        setOpenModal(false)
        setQualGrid(<><Loading /></>)

        let ret: AxiosResponse;
        try {
            ret = await api.delete("unity/delete", {
                data: { id: id }
            });
            if (ret.data.status === 200) {
                GetUnidadeSel(contract.split('_')[0]);
                setQualGrid((<><GridSecundario
                    Iconeadd={ChamarCadastroUnidade}
                    Iconeditar={EditarUnidade}
                    iconeExcluir={UnidadeDeletar}
                    PegarValorGrid={PegarValorGridUnidade}
                    th1='Ativo'
                    th2='ID'
                    th3='Descrição'
                    th4='Identificador Alternativo'
                    th5=''
                    th6=''
                    endPoint='unity/buscarunity'
                    consult='N'
                    col1='1'
                    col2='2'
                    col3='3'
                    params={contract.split('_')[0]}
                    dbtnDelete="N"
                    pag="ok"
                /></>))
                GetUnidadeSel(contract.split('_')[0]);
            }
        } catch (e: any) {
            setQualGrid((<><GridSecundario
                Iconeadd={ChamarCadastroUnidade}
                Iconeditar={EditarUnidade}
                iconeExcluir={UnidadeDeletar}
                PegarValorGrid={PegarValorGridUnidade}
                th1='Ativo'
                th2='ID'
                th3='Descrição'
                th4='Identificador Alternativo'
                th5=''
                th6=''
                endPoint='unity/buscarunity'
                consult='N'
                col1='1'
                col2='2'
                col3='3'
                params={contract.split('_')[0]}
                dbtnDelete="N"
                pag="ok"
            /></>))
            GetUnidadeSel(contract.split('_')[0]);
        }
    }

    //=================================ADD UNIDADE=============================================  
    async function GetDepartment(unidade: any) {
        let ret: AxiosResponse;
        let valor: any;

        setOptionspavement([])
        setOptionsDepartment([])
        try {
            ret = await api.get("local/listar", {
                params: {
                    id: unidade
                }
            })
            if (ret.data.status === 200) {
                valor = ret.data.data;

                const arr = [] as any;
                let result = valor;
                result.map((user: any) => {
                    return arr.push({ value: String(user.loc_pavement), label: user.loc_pavement });
                });

                setOptionspavement(arr)

            }
            setOpenModal(false)
        } catch (e) {
            setOpenModal(false)
            setOptionspavement([])
        }
    }


    function incluirDepartamento() {
        if(secureLocalStorage.getItem('acp') === 'DESENVOLVEDOR' || secureLocalStorage.getItem('acp') === 'ADMINISTRADOR'){
            if (status === "2" || status === "3" || status === "4") {

                if (unity === "") {

                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Selecione a unidade.</span></div>
                        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                    </div>)

                    setOpenModal(true)

                }
                else {
                    setPavement("")
                    setUser("")
                    setDepartment("")
                    setOptionsDepartment([])
                    setTitulo('Cadastrar Local')
                    setQualGrid((<><GridSecundario
                        Iconeadd={ChamarCadastroDepartamento}
                        Iconeditar={EditarDepartment}
                        iconeExcluir={DepartamentoDeletar}
                        PegarValorGrid={PegarValorGridDepartamento}
                        th1='Ativo'
                        th2='ID'
                        th3='Descrição'
                        th4='Identificador Alternativo'
                        th5=''
                        th6=''
                        endPoint='local/buscarloc'
                        consult='N'
                        col1='1'
                        col2='2'
                        col3='3'
                        params={unity.split('_')[0]}
                        dbtnDelete="N"
                        pag="ok"
                    /></>))
                    setExibirModalGrid('S')
                    setExibirOcorrido("N")
                    setExibirDevolucao('N')
                    setOpenModalIncluirNovo(true)


                }
            }

        }
    }


    function ChamarCadastroDepartamento() {
        sessionStorage.removeItem("dadosDep");
        setQualGrid(<><FormDepartment voltar={BtnVoltarDepartamento} contrato={contract.split('_')[0]} unidade={unity.split('_')[0]} /></>)
    }

    async function EditarDepartment(obj: any) {
        sessionStorage.setItem("dadosDep", JSON.stringify(obj));
        setQualGrid(<><FormDepartment voltar={BtnVoltarDepartamento} /></>)
    }

    function BtnVoltarDepartamento() {
        setTitulo('Cadastrar Local')
        setQualGrid((<><GridSecundario
            Iconeadd={ChamarCadastroDepartamento}
            Iconeditar={EditarDepartment}
            iconeExcluir={DepartamentoDeletar}
            PegarValorGrid={PegarValorGridDepartamento}
            th1='Ativo'
            th2='ID'
            th3='Descrição'
            th4='Identificador Alternativo'
            th5=''
            th6=''
            endPoint='local/buscarloc'
            consult='N'
            col1='1'
            col2='2'
            col3='3'
            params={unity.split('_')[0]}
            dbtnDelete="N"
            pag="ok"
        /></>))
        GetDepartment(unity.split('_')[0])
    }


    function DepartamentoDeletar(obj: any) {

        setQualAlert(<div className='div-alert'>
            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
            <div className='div-info-alert-atencao'><span className="info-alert-atencao">Deseja deletar o registro?</span></div>
            <div className='div-info-alert'><span className="info-alert">Você não poderá reverter.</span></div>

            <div className='btn-alert-div'>
                <span className='btn-alert-esc' onClick={() => DeletarDepartamento(obj.loc_id)}>
                    <span className="btn-alert-lab">SIM</span>
                </span>
                <span className='btn-alert-dir' onClick={finalizarModal2}>
                    <span className="btn-alert-lab">NÃO</span>
                </span>
            </div>
        </div>)

        setOpenModal(true)



    }

    async function DeletarDepartamento(id: any) {
        setQualGrid(<><Loading /></>)
        let ret: AxiosResponse;
        setOpenModal(false)
        try {
            ret = await api.delete("local/delete", {
                data: { id: id }
            });
            if (ret.data.status === 200) {

                setTitulo('Cadastrar Local')
                setQualGrid((<><GridSecundario
                    Iconeadd={ChamarCadastroDepartamento}
                    Iconeditar={EditarDepartment}
                    iconeExcluir={DepartamentoDeletar}
                    PegarValorGrid={PegarValorGridDepartamento}
                    th1='Ativo'
                    th2='ID'
                    th3='Descrição'
                    th4='Identificador Alternativo'
                    th5=''
                    th6=''
                    endPoint='local/buscarloc'
                    consult='N'
                    col1='1'
                    col2='2'
                    col3='3'
                    params={unity.split('_')[0]}
                    dbtnDelete="N"
                    pag="ok"
                /></>))
                GetDepartment(unity.split('_')[0])
            }
        } catch (e: any) {
            setTitulo('Cadastrar Local')
            setQualGrid((<><GridSecundario
                Iconeadd={ChamarCadastroDepartamento}
                Iconeditar={EditarDepartment}
                iconeExcluir={DepartamentoDeletar}
                PegarValorGrid={PegarValorGridDepartamento}
                th1='Ativo'
                th2='ID'
                th3='Descrição'
                th4='Identificador Alternativo'
                th5=''
                th6=''
                endPoint='local/buscarloc'
                consult='N'
                col1='1'
                col2='2'
                col3='3'
                params={unity.split('_')[0]}
                dbtnDelete="N"
                pag="ok"
            /></>))
            GetDepartment(unity.split('_')[0])
        }
    }

    //=================================Depreciation============================================= 

    function incluirDepreciation() {
        if(secureLocalStorage.getItem('acp') === 'DESENVOLVEDOR' || secureLocalStorage.getItem('acp') === 'ADMINISTRADOR'){
            if (status !== "") {
                setTitulo('Cadastrar Depreciação')
                setQualGrid((<><GridSecundario
                    Iconeadd={ChamarCadastroDepreciation}
                    Iconeditar={EditarDepreciation}
                    iconeExcluir={DepreciationDeletar}
                    PegarValorGrid={PegarValorGridDepreciationMethod}
                    th1='Ativo'
                    th2='ID'
                    th3='Descrição'
                    th4='Identificador Alternativo'
                    th5=''
                    th6=''
                    endPoint='depreciation/buscardepreciation'
                    consult='N'
                    col1='1'
                    col2='2'
                    col3='3'
                    ambiente={'%'}
                    pag="ok"
                /></>))

                setExibirModalGrid('S')
                setExibirOcorrido("N")
                setExibirDevolucao('N')
                setOpenModalIncluirNovo(true)
            } else {
                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Selecione o status para continuar</span></div>
                    <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)
            }
        }
    }

    function ChamarCadastroDepreciation() {
        sessionStorage.removeItem("dadosDpc");
        setQualGrid(<><FormDepreciation voltar={BtnVoltarDepreciation} /></>)

    }


    function BtnVoltarDepreciation() {
        setTitulo('Cadastrar Depreciação')
        setQualGrid((<><GridSecundario
            Iconeadd={ChamarCadastroDepreciation}
            Iconeditar={EditarDepreciation}
            iconeExcluir={DepreciationDeletar}
            PegarValorGrid={PegarValorGridDepreciationMethod}
            th1='Ativo'
            th2='ID'
            th3='Descrição'
            th4='Identificador Alternativo'
            th5=''
            th6=''
            endPoint='depreciation/buscardepreciation'
            consult='N'
            col1='1'
            col2='2'
            col3='3'
            ambiente={'%'}
            pag="ok"
        /></>))

        GetDepreciationMethod()
    }

    async function EditarDepreciation(obj: any) {
        sessionStorage.setItem("dadosDpc", JSON.stringify(obj));
        setQualGrid(<><FormDepreciation voltar={BtnVoltarDepreciation} /></>)

    }

    function DepreciationDeletar(obj: any) {

        setQualAlert(<div className='div-alert'>
            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
            <div className='div-info-alert-atencao'><span className="info-alert-atencao">Deseja deletar o registro?</span></div>
            <div className='div-info-alert'><span className="info-alert">Você não poderá reverter.</span></div>

            <div className='btn-alert-div'>
                <span className='btn-alert-esc' onClick={() => DeletarDepreciation(obj.dpc_id)}>
                    <span className="btn-alert-lab">SIM</span>
                </span>
                <span className='btn-alert-dir' onClick={finalizarModal2}>
                    <span className="btn-alert-lab">NÃO</span>
                </span>
            </div>
        </div>)

        setOpenModal(true)

    }

    async function DeletarDepreciation(id: any) {

        setOpenModal(false)
        setQualGrid(<><Loading /></>)
        let ret: AxiosResponse;
        try {
            ret = await api.delete("depreciation/delete", {
                data: { id: id }
            });
            if (ret.data.status === 200) {

                setTitulo('Cadastrar Depreciação')
                setQualGrid((<><GridSecundario
                    Iconeadd={ChamarCadastroDepreciation}
                    Iconeditar={EditarDepreciation}
                    iconeExcluir={DepreciationDeletar}
                    PegarValorGrid={PegarValorGridDepreciationMethod}
                    th1='Ativo'
                    th2='ID'
                    th3='Descrição'
                    th4='Identificador Alternativo'
                    th5=''
                    th6=''
                    endPoint='depreciation/buscardepreciation'
                    consult='N'
                    col1='1'
                    col2='2'
                    col3='3'
                    ambiente={'%'}
                    pag="ok"
                /></>))

                GetDepreciationMethod()
            }
        } catch (e: any) {
            setTitulo('Cadastrar Depreciação')
            setQualGrid((<><GridSecundario
                Iconeadd={ChamarCadastroDepreciation}
                Iconeditar={EditarDepreciation}
                iconeExcluir={DepreciationDeletar}
                PegarValorGrid={PegarValorGridDepreciationMethod}
                th1='Ativo'
                th2='ID'
                th3='Descrição'
                th4='Identificador Alternativo'
                th5=''
                th6=''
                endPoint='depreciation/buscardepreciation'
                consult='N'
                col1='1'
                col2='2'
                col3='3'
                ambiente={'%'}
                pag="ok"
            /></>))

            GetDepreciationMethod()
        }
    }

    async function GetDepreciationMethod() {
        let ret: AxiosResponse;
        let valor: any;
        try {
            ret = await api.get("depreciation/listar")
            if (ret.data.status === 200) {
                valor = ret.data.data;

                const arr = [] as any;
                let result = valor;
                result.map((user: any) => {
                    return arr.push({ value: String(user.dpc_id), label: user.dpc_description });
                });
                setOptionDepreciationMethod(arr)
            }
        } catch (e) {
            setOptionDepreciationMethod([])
        }
    }

    function IncluirLocais(locais: any) {

        if (locais === '') {
            setPavement("")
            setDepartment("")
            setOptionsDepartment([])
            setDepartment("")
            setUser("")
        } else {
            setQualAlert(<div className='div-alert'><Loading /></div>)
            setOpenModal(true)
            setDepartment("")
            setPavement(locais)
            GetDepartmentPaviment(locais)
        }

    }

    function PegarValorGridDepreciationMethod(valor: any) {
        setTypeOfDepreciation(String(valor.dpc_id))
        setOpenModalIncluirNovo(false)
    }

    function PegarValorGridContrato(valor: any) {

        IncluirContrato(String(valor.ctc_id + '_' + valor.ctc_description))
        setOpenModalIncluirNovo(false)

    }

    function PegarValorGridUnidade(valor: any) {

        IncluirUnity(String(valor.unt_id + "_" + valor.unt_description))
        setOpenModalIncluirNovo(false)

    }

    function PegarValorGridDepartamento(valor: any) {

        IncluirLocais(String(valor.loc_pavement))
        setDepartment(String(valor.loc_id))
        setOpenModalIncluirNovo(false)

    }


    async function GetDepartmentPaviment(pavimento: any) {
        let ret: AxiosResponse;
        let valor: any;

        try {
            ret = await api.get("local/listarlocpaviment", {
                params: {
                    id: unity.split('_')[0],
                    paviment: pavimento
                }
            })
            if (ret.data.status === 200) {
                valor = ret.data.data;

                const arr = [] as any;
                let result = valor;
                result.map((user: any) => {
                    return arr.push({ value: String(user.loc_id), label: user.loc_description });
                });
                setOptionsDepartment(arr)
            }
            setOpenModal(false)
        } catch (e) {
            setOpenModal(false)
            setOptionsDepartment([])
        }
    }

    function incluirDepartment(valor: any) {

        if (valor === "") {
            setUser("")
            setDepartment("")
        } else {
            setDepartment(valor)
        }
    }

    return (
        <>
            <Nav />
            <div className="main-content">
                <Header />
                <main>
                    <div className='containerPrincipal'>
                        <div className="title-Page">
                            <div className="title-text">
                                <div className="size-x circle">1</div>
                                <h2>Defina informações gerais</h2>
                            </div>
                            <hr />
                        </div>
                        <div className="class-checkbox">
                            <input type="checkbox" id="checkbox-ativo" checked={active} onChange={ValitarActive} disabled={activeDisabled} />
                            <h4 className="lab-ativo">Ativo</h4>
                        </div>

                        <div className="container-cad-prod1">
                            <div id="content1">
                                <div className="input-box-ativos">
                                    <span className="details">Descrição *</span>
                                    <input
                                        autoComplete="off"
                                        id="description"
                                        type="text"
                                        disabled={descriptionDisabled}
                                        onChange={(e) => setDescription(e.target.value)}
                                        value={description}
                                    />
                                </div>
                            </div>
                            <div id="content2">
                                <div className="select-box-ativos-select">
                                    <span className="details">Categoria *</span>
                                    <Select
                                        placeholder={'Selecione...'}
                                        options={optionsCategory}
                                        styles={customStyles}
                                        isDisabled={isDisabledCategory}
                                        value={optionsCategory.filter(function (option: any) {
                                            return option.value === category;
                                        })}
                                        onChange={(options: any) =>
                                            !options ? incluirCategory("") : incluirCategory(options.value)
                                        }
                                        isClearable
                                        noOptionsMessage={() => "Não encontrado..."}
                                        theme={(theme: any) => ({
                                            ...theme,
                                            borderRadius: 4,
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#ebebeb',
                                                primary: 'black',
                                            },
                                        })}
                                    />
                                </div>
                            </div>

                            <div id="content3">
                                <div className="select-box-ativos-select">
                                    <span className="details">Subcategoria *</span>
                                    <Select
                                        placeholder={'Selecione...'}
                                        options={optionSubcategory}
                                        styles={customStyles}
                                        isDisabled={isDisabledSubcategory}
                                        value={optionSubcategory.filter(function (option: any) {
                                            return option.value === subcategory;
                                        })}
                                        onChange={(options: any) =>
                                            !options ? incluirSubCategory("") : incluirSubCategory(options.value)
                                        }
                                        isClearable
                                        noOptionsMessage={() => "Não encontrado..."}
                                        theme={(theme: any) => ({
                                            ...theme,
                                            borderRadius: 4,
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#ebebeb',
                                                primary: 'black',
                                            },
                                        })}
                                    />
                                </div>
                            </div>
                        </div>



                        <div className="container-cad-prod16">
                            <div id="content1">
                                <div className="input-box-ativos">
                                    <span className="details">Identificador Alternativo *</span>
                                    <input
                                        autoComplete="off"
                                        id="description"
                                        type="text"
                                        disabled={alternativeIdentifierDisabled}
                                        onChange={(e) => setAlternativeIdentifier(e.target.value)}
                                        value={alternativeIdentifier}
                                    />
                                </div>
                            </div>
                            <div id="content2">
                                <div className="select-box-ativos-select">
                                    <span className="details">Status *</span>
                                    <Select
                                        placeholder={'Selecione...'}
                                        options={optionStatus}
                                        styles={customStyles}
                                        value={optionStatus.filter(function (option: any) {
                                            return option.value === status;
                                        })}
                                        onChange={(options: any) =>
                                            !options ? incluirStatus("") : incluirStatus(options.value)
                                        }
                                        isDisabled={statusDisabled}
                                        isClearable
                                        noOptionsMessage={() => "Não encontrado..."}
                                        theme={(theme: any) => ({
                                            ...theme,
                                            borderRadius: 4,
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#ebebeb',
                                                primary: 'black',
                                            },
                                        })}
                                    />
                                </div>
                            </div>

                            <div id="content3">
                                <div className="input-box-ativos">
                                    <span className="details">Centro de Custos</span>
                                    <input
                                        type="text"
                                        onChange={(e) => setAst_costcenter(e.target.value)}
                                        value={ast_costcenter}
                                    />
                                </div>
                            </div>

                            <div id="content4">
                                <div className="input-box-ativos">
                                    <span className="details">Data da inclusão</span>
                                    <input
                                        type="datetime-local"
                                        id="DATA-TIME"
                                        name="DATE-TIME"
                                        disabled
                                        onChange={(e) => setCreated_at(e.target.value)}
                                        value={created_at}
                                    />
                                </div>
                            </div>
                            <div id="content5">
                                <div className="input-box-ativos">
                                    <span className="details">Data da última atualização</span>
                                    <input
                                        type="datetime-local"
                                        id="DATA-TIME"
                                        name="DATE-TIME"
                                        disabled
                                        onChange={(e) => setUpdated_at(e.target.value)}
                                        value={updated_at}
                                    />
                                </div>
                            </div>
                        </div>




                        <div className="container-3-ativo">
                            <div className="textareaBox-ativo">
                                <span className="details">Observação</span>
                                <textarea
                                    onChange={(e) => setObservation(e.target.value)}
                                    value={observation}
                                    disabled={observationDisabled}
                                />
                            </div>
                        </div>

                        <div className="title-Page">
                            <div className="title-text">
                                <div className="size-x circle">2</div>
                                <h2>Sobre o ativo</h2>
                            </div>
                            <hr />
                        </div>
                        <div className="container-cad-prod1">
                            <div id="content1">
                                <div className="select-box-ativos-select" >
                                    <div className='details-flex' >
                                        <span className="details">Produto *</span>
                                    </div>

                                    <Select
                                        maxMenuHeight={150}
                                        placeholder={'Selecione...'}
                                        options={optionProduct}
                                        styles={customStyles}
                                        isDisabled={isDisabledProduct}
                                        value={optionProduct.filter(function (option: any) {
                                            return option.value === product;
                                        })}
                                        onChange={(options: any) =>
                                            !options ? GetCarregaInf("") : GetCarregaInf(options.value)
                                        }

                                        isClearable
                                        noOptionsMessage={() => "Não encontrado..."}
                                        theme={(theme: any) => ({
                                            ...theme,
                                            borderRadius: 4,
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#ebebeb',
                                                primary: 'black',
                                            },
                                        })}
                                    />
                                </div>
                            </div>
                            <div id="content2">
                                <div className="select-box-ativos-select">
                                    <div className='details-flex'>
                                        <span className="details">Fabricante</span>
                                    </div>
                                    <Select
                                        placeholder={'Selecione...'}
                                        options={optionsManufacturer}
                                        styles={customStyles}
                                        value={optionsManufacturer.filter(function (option: any) {
                                            return option.value === manufacturer;
                                        })}
                                        isClearable
                                        isDisabled={isDisabledManufacturer}
                                        noOptionsMessage={() => "Não encontrado..."}
                                        theme={(theme: any) => ({
                                            ...theme,
                                            borderRadius: 4,
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#ebebeb',
                                                primary: 'black',
                                            },
                                        })}
                                    />
                                </div>
                            </div>
                            <div id="content3">
                                <div className="select-box-ativos-select">
                                    <div className='details-flex'>
                                        <span className="details">Marca</span>
                                    </div>

                                    <Select
                                        placeholder={'Selecione...'}
                                        options={optionsBrand}
                                        styles={customStyles}
                                        isDisabled={isDisabledBrand}
                                        value={optionsBrand.filter(function (option: any) {
                                            return option.value === brand;
                                        })}
                                        isClearable
                                        noOptionsMessage={() => "Não encontrado..."}
                                        theme={(theme: any) => ({
                                            ...theme,
                                            borderRadius: 4,
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#ebebeb',
                                                primary: 'black',
                                            },
                                        })}
                                    />
                                </div>
                            </div>

                        </div>
                        <div className="container-cad-prod14">
                            <div id="content1">
                                <div className="select-box-ativos-select">
                                    <div className='details-flex'>
                                        <span className="details">Modelo</span>
                                    </div>
                                    <Select
                                        placeholder={'Selecione...'}
                                        options={optionsModel}
                                        styles={customStyles}
                                        value={optionsModel.filter(function (option: any) {
                                            return option.value === model;
                                        })}
                                        isDisabled={isDisabledModel}
                                        isClearable
                                        noOptionsMessage={() => "Não encontrado..."}
                                        theme={(theme: any) => ({
                                            ...theme,
                                            borderRadius: 4,
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#ebebeb',
                                                primary: 'black',
                                            },
                                        })}
                                    />
                                </div>
                            </div>
                            <div id="content2">
                                <div className="input-box-ativos">
                                    <div className='details-flex'>
                                        <span className="details">Número Nota Fiscal</span>
                                    </div>
                                    <input
                                        autoComplete="off"
                                        id="invoice"
                                        type="text"
                                        onChange={(e) => setInvoice(e.target.value)}
                                        value={invoice}
                                        disabled={invoiceDisabled}
                                    />

                                </div>
                            </div>
                            <div id="content3">
                                <div className="input-box-ativos">
                                    <span className="details">Valor</span>
                                    <input
                                        id="cust"
                                        type="text"
                                        onChange={(e) => incluirValor(e.target.value)}
                                        value={cust}
                                        placeholder='R$'
                                        disabled={custDisabled}
                                    />
                                </div>
                            </div>
                            <div id="content4">

                                <div className="select-box-ativos-select">
                                    <div className='details-flex'>
                                        <span className="details">Depreciação </span>
                                        <MdOutlineAddCircle className='details-flex-icon-ativo' />
                                        <span className="details-esc-ativo" onClick={incluirDepreciation}>Incluir Novo</span>
                                    </div>
                                    <Select
                                        placeholder={'Selecione...'}
                                        options={optionDepreciationMethod}
                                        styles={customStyles}
                                        isDisabled={isDisabledDepreciation}
                                        value={optionDepreciationMethod.filter(function (option: any) {
                                            return option.value === typeOfDepreciation;
                                        })}
                                        onChange={(options: any) =>
                                            !options ? setTypeOfDepreciation("") : setTypeOfDepreciation(options.value)
                                        }
                                        isClearable
                                        noOptionsMessage={() => "Não encontrado..."}
                                        theme={(theme: any) => ({
                                            ...theme,
                                            borderRadius: 4,
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#ebebeb',
                                                primary: 'black',
                                            },
                                        })}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="container-cad-prod6 ">
                            <div id="content1">
                                <div className="input-box-ativos">

                                    <span className="details">Valor Residual</span>
                                    <input
                                        disabled
                                        id="residualValue"
                                        type="text"
                                        onChange={(e) => setResidualValue(e.target.value)}
                                        value={residualValue}
                                    />
                                </div>
                            </div>
                            <div id="content2">
                                <div className="input-box-ativos">
                                    <div className='details-flex'>
                                        <span className="details">Data de Aquisição</span>
                                    </div>
                                    <input
                                        id="issueDate"
                                        type="date"
                                        onChange={(e) => validarIssueDate(e.target.value)}
                                        value={issueDate}
                                        disabled={issueDateDisabled}
                                    />
                                </div>
                            </div>
                            <div id="content3">
                                <div className="input-box-ativos">
                                    <span className="details">Garantia (Mês)</span>
                                    <div className='input-flex-ativos'>

                                        <input
                                            placeholder='Quan. Mês'
                                            type="number"
                                            value={warrantyMonth}
                                            onChange={(e) =>
                                                validarWarrantyMonth(e.target.value)}
                                            disabled={warrantyMonthDisabled}
                                        />

                                    </div>
                                </div>
                            </div>
                            <div id="content4">
                                <div className="input-box-ativos">
                                    <div className='details-flex'>
                                        <span className="details">Data de Expiração</span>
                                    </div>
                                    <input
                                        type="date"
                                        onChange={(e) => setExpirationDate(e.target.value)}
                                        id="expirationDate"
                                        value={expirationDate}
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="container-cad-prod6">

                            <div id="content1">
                                <div className="input-box-ativos">
                                    <div className='details-flex'>
                                        <span className="details">Número de Série</span>
                                    </div>
                                    <input
                                        autoComplete="off"
                                        id="serialNumber"
                                        type="text"
                                        onChange={(e) => setSerialNumber(e.target.value)}
                                        value={serialNumber}
                                        disabled={serialNumberDisabled}
                                    />

                                </div>
                            </div>
                            <div id="content2">
                                <div className="input-box-ativos">
                                    <div className='details-flex'>
                                        <span className="details">Tombamento</span>
                                    </div>
                                    <input
                                        autoComplete="off"
                                        id="tombamento"
                                        type="text"
                                        onChange={(e) => setTombamento(e.target.value)}
                                        value={tombamento}
                                        disabled={tombamentoDisabled}
                                    />
                                </div>
                            </div>



                            {subcategory === '1' || subcategory === '3' || subcategory === '4' || subcategory === '5' || subcategory === '17' || subcategory === '18' || subcategory === '19' || subcategory === '8' || subcategory === '9' ? (
                                <div id="content3">
                                    <div className="input-box-ativos">
                                        <span className="details">MAC</span>
                                        <input
                                            autoComplete="off"
                                            id="mac"
                                            type="text"
                                            onChange={(e) => setMac(e.target.value)}
                                            value={mac}
                                            disabled={macDisabled}
                                        />
                                    </div>
                                </div>
                            ) : null}

                        </div>

                        {qualForm}

                        <div className="title-Page">
                            <div className="title-text">
                                <div className="size-x circle">4</div>
                                <h2>Localização</h2>
                            </div>
                            <hr />
                        </div>

                        <div className="container-cad-prod4">
                            <div id="content1">
                                <div className="select-box-ativos-select">
                                    <div className='details-flex'>
                                        <span className="details">Contrato</span>
                                        <MdOutlineAddCircle className='details-flex-icon-ativo' />
                                        <span className="details-esc-ativo" onClick={incluirContrato}>Incluir Novo</span>
                                    </div>
                                    <Select
                                        maxMenuHeight={120}
                                        placeholder={'Selecione...'}
                                        options={optionContract}
                                        styles={customStyles}
                                        value={optionContract.filter(function (option: any) {
                                            return option.value === contract;
                                        })}
                                        onChange={(options: any) =>
                                            !options ? IncluirContrato("") : IncluirContrato(options.value)
                                        }
                                        isClearable
                                        isDisabled={isDisabledContract}
                                        noOptionsMessage={() => "Não encontrado..."}
                                        theme={(theme: any) => ({
                                            ...theme,
                                            borderRadius: 4,
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#ebebeb',
                                                primary: 'black',
                                            },
                                        })}
                                    />
                                </div>
                            </div>

                            <div id="content2">

                                <div className="select-box-ativos-select">
                                    <div className='details-flex'>
                                        <span className="details">Unidade</span>
                                        <MdOutlineAddCircle className='details-flex-icon-ativo' />
                                        <span className="details-esc-ativo" onClick={incluirUnidade}>Incluir Novo</span>
                                    </div>

                                    <Select
                                        maxMenuHeight={120}
                                        placeholder={'Selecione...'}
                                        options={optionUnity}
                                        styles={customStyles}
                                        value={optionUnity.filter(function (option: any) {
                                            return option.value === unity;
                                        })}
                                        onChange={(options: any) =>
                                            !options ? IncluirUnity("") : IncluirUnity(options.value)
                                        }
                                        isClearable
                                        isDisabled={isDisabledUnity}
                                        noOptionsMessage={() => "Não encontrado..."}
                                        theme={(theme: any) => ({
                                            ...theme,
                                            borderRadius: 4,
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#ebebeb',
                                                primary: 'black',
                                            },
                                        })}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="container-cad-prod2">
                            <div id="content1">
                                <div className="select-box-ativos-select">
                                    <span className="details">Pavimento</span>
                                    <Select
                                        maxMenuHeight={100}
                                        placeholder={'Selecione...'}
                                        options={optionspavement}
                                        styles={customStyles}
                                        value={optionspavement.filter(function (option: any) {
                                            return option.value === pavement;
                                        })}
                                        onChange={(options: any) =>
                                            !options ? IncluirLocais("") : IncluirLocais(options.value)
                                        }
                                        isClearable
                                        isDisabled={isDisabledPavement}
                                        noOptionsMessage={() => "Não encontrado..."}
                                        theme={(theme: any) => ({
                                            ...theme,
                                            borderRadius: 4,
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#ebebeb',
                                                primary: 'black',
                                            },
                                        })}
                                    />
                                </div>
                            </div>

                            <div id="content2">


                                <div className="select-box-ativos-select">
                                    <div className='details-flex'>
                                        <span className="details">Local</span>
                                        <MdOutlineAddCircle className='details-flex-icon-ativo' />
                                        <span className="details-esc-ativo" onClick={incluirDepartamento}>Incluir Novo</span>
                                    </div>

                                    <Select
                                        maxMenuHeight={100}
                                        placeholder={'Selecione...'}
                                        options={optionsDepartment}
                                        styles={customStyles}
                                        value={optionsDepartment.filter(function (option: any) {
                                            return option.value === department;
                                        })}
                                        onChange={(options: any) =>
                                            !options ? incluirDepartment("") : incluirDepartment(options.value)
                                        }
                                        isClearable
                                        isDisabled={isDisabledDepartment}
                                        noOptionsMessage={() => "Não encontrado..."}
                                        theme={(theme: any) => ({
                                            ...theme,
                                            borderRadius: 4,
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#ebebeb',
                                                primary: 'black',
                                            },
                                        })}
                                    />
                                </div>


                            </div>

                            <div id="content3">
                                <div className="input-box-ativos">
                                    <span className="details">Nome Usuário</span>
                                    <input
                                        autoComplete="off"
                                        id="user"
                                        onChange={(e) => setUser(e.target.value)}
                                        value={user}
                                        disabled={userDisabled}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="Bt-button-cad">
                            <button type="submit" onClick={salvarInventario} disabled={confirmarDisabled}>
                                <i className="fa fa-check-circle icon-ok-btn"></i> Salvar
                            </button>
                            <button type="submit" onClick={Cancelar}>
                                <i className="fa fa-times icon-cancelar-btn"></i> Cancelar
                            </button>
                        </div>

                    </div>
                </main>
            </div>

            <ModalIncluirNovo isOpen={openModalIncluirNovoInf} setModalOpen={() => setOpenModalIncluirNovo(!openModalIncluirNovoInf)}>
                <>
                    <div className='title-modal-incluir'>
                        <h1 className="modal-IncluirNovo-title">{titulo}</h1>
                        <button className="fa fa-times icone-modal-IncluirNovo" data-dismiss="modal" aria-label="Close" onClick={fecharOpenModalIncluirNovoInf}></button>
                    </div>
                    <div className='container-modal-pag'>

                        {exibirOcorrido === 'S' ? (
                            <>
                                <h1 className="spa-aten">
                                    <b> Atenção:</b> Precisamos entender o que aconteceu para o ativo se tornar fora de uso.
                                </h1>
                                <div className="container-cad-prod3">
                                    <div className="textareaBox-ativo">
                                        <span className="details">Relate o ocorrido *</span>
                                        <textarea
                                            onChange={(e) => setObservationOcorrencia(e.target.value)} value={observationOcorrencia}
                                        />
                                    </div>
                                </div>
                                <div className="Bt-button-cad">
                                    <button type="submit" onClick={salvarOcorrencia}>
                                        <i className="fa fa-check-circle  icon-ok-btn"></i>  Salvar
                                    </button>
                                    <button type="submit" onClick={fecharOpenModalIncluirNovoInf}>
                                        <i className="fa fa-times icon-cancelar-btn"></i> Cancelar
                                    </button>
                                </div>
                            </>
                        ) : null}
                        {exibirDevolucao === 'S' ? (
                            <>
                                <h1 className="spa-aten">
                                    <b> Atenção:</b> O ativo retornarar ao estoque precisamos entender o que aconteceu para a devolução.
                                </h1>
                                <div className="container-cad-prod3">
                                    <div className="textareaBox-ativo">
                                        <span className="details">Relate o ocorrido *</span>
                                        <textarea
                                            onChange={(e) => setObservationOcorrencia(e.target.value)} value={observationOcorrencia}
                                        />
                                    </div>
                                </div>
                                <div className="Bt-button-cad">
                                    <button type="submit" onClick={salvarDevolucao}>
                                        <i className="fa fa-check-circle  icon-ok-btn"></i> Salvar
                                    </button>
                                    <button type="submit" onClick={fecharOpenModalIncluirNovoInf}>
                                        <i className="fa fa-times icon-cancelar-btn"></i> Cancelar
                                    </button>
                                </div>
                            </>
                        ) : null}
                    </div>
                </>
            </ModalIncluirNovo>

            <ModalIncluirNovo isOpen={openModalIncluirNovo} setModalOpen={() => setOpenModalIncluirNovo(!openModalIncluirNovo)}>
                <div className='title-modal-incluir'>
                    <h1 className="modal-IncluirNovo-title">{titulo}</h1>
                    <div  onClick={fecharOpenModalIncluirNovo} >
                    <button className="fa fa-times icone-modal-IncluirNovo" data-dismiss="modal" aria-label="Close"/>
                    </div>
                </div>
                <div className='modal-scrol2'>

                    {qualGrid}

                </div>
            </ModalIncluirNovo>

            <ModalAlert isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)}>
                <div className='container-modal-alert'>
                    {qualAlert}
                </div>
            </ModalAlert>
        </>
    );

}

export default InventarioEdit;