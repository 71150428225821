import React, { useState, useEffect } from 'react'
import './index.css'
import { AxiosResponse } from "axios";
import api from '../../Services/api'
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router';

function MonitorForm(props:any) {
   
    const navigate = useNavigate();
  
    const [screenSize, setScreenSize] = useState(props.data.pdc_screensize || '')
    const [maximumResolution,setMaximumResolution] = useState(props.data.pdc_maximumresolution || '')
    const [voltage,setVoltage] = useState(props.data.pdc_voltage || '')
    const [updateRate, setUpdateRate] = useState(props.data.pdc_updaterate || '')


    useEffect(() => {

        if(props.data.pdc_id !== undefined || props.data.pdc_id !== ''){
            props.test({
                pdc_screensize: props.data.pdc_screensize,
                pdc_maximumresolution: props.data.pdc_maximumresolution,
                pdc_voltage: props.data.pdc_voltage,
                pdc_updaterate: props.data.pdc_updaterate
                })
        }

        let disabledscreenSize = document.getElementById("screenSize") as HTMLInputElement;
        let disabledmaximumResolution = document.getElementById("maximumResolution") as HTMLInputElement;
        let disabledupdateRate = document.getElementById("updateRate") as HTMLInputElement;
        let disabledvoltage = document.getElementById("voltage") as HTMLInputElement;

        if (props.disabled === 'S'){
            disabledscreenSize.disabled = true;
            disabledmaximumResolution.disabled = true;
            disabledupdateRate.disabled = true;
            disabledvoltage.disabled = true;
            }

        }, []);
        
        function incluirScreenSize(data:any){
            setScreenSize(data)
            props.test({
                pdc_maximumresolution: maximumResolution,
                pdc_voltage: voltage,
                pdc_updaterate: updateRate,
                pdc_screensize: data,      
            })
        }

        function incluirUpdateRate(data:any){
            setUpdateRate(data);
            props.test({
                pdc_maximumresolution: maximumResolution,
                pdc_voltage: voltage,
                pdc_updaterate: data,
                pdc_screensize: screenSize,      
            })
        }

        function incluirVoltage(data:any){
            setVoltage(data);
            props.test({
                pdc_maximumresolution: maximumResolution,
                pdc_voltage: data,
                pdc_updaterate: updateRate,
                pdc_screensize: screenSize,      
            })
        }

    function incluirMaximumResolution(data:any){
        setMaximumResolution(data);
        props.test({
            pdc_maximumresolution: data,
            pdc_voltage: voltage,
            pdc_updaterate: updateRate,
            pdc_screensize: screenSize,      
        })
    }

    return (
        <>
            
            <div className="title-Page">
                            <div className="title-text">
                                <div className="size-x circle">2</div>
                                <h2>Detalhes técnicos</h2>
                            </div>
                            <hr />
                        </div>

                        <div className="container-cad-prodMobi1">
                        <div id="content1">
                                <div className="input-box-ativos">
                                    <span className="details">Tamanho da Tela</span>
                                    <input
                                     id="screenSize"
                                        type="text"
                                        autoComplete="off"
                                        onChange={(e) => incluirScreenSize(e.target.value)}
                                        value={screenSize}
                                        disabled={props.acpDisabled}
                                    />
                                </div>
                            </div>
                            <div id="content2">
                                <div className="input-box-ativos">
                                    <span className="details">Resolução Máxima</span>
                                    <input
                                     id="maximumResolution"
                                        type="text"
                                        autoComplete="off"
                                        onChange={(e) => incluirMaximumResolution(e.target.value)}
                                        value={maximumResolution}
                                        disabled={props.acpDisabled}
                                    />
                                </div>
                            </div>
                            <div id="content3">
                                <div className="input-box-ativos">
                                    <span className="details">Taxa de Atualização</span>
                                    <input
                                    id="updateRate"
                                        type="text"
                                        autoComplete="off"
                                        onChange={(e) => incluirUpdateRate(e.target.value)}
                                        value={updateRate}
                                        disabled={props.acpDisabled}
                                    />
                                </div>
                            </div>
                            <div id="content4">
                                <div className="input-box-ativos">
                                    <span className="details">Voltagem</span>
                                    <input
                                    id="voltage"
                                        type="text"
                                        autoComplete="off"
                                        onChange={(e) => incluirVoltage(e.target.value)}
                                        value={voltage}
                                        disabled={props.acpDisabled}
                                    />
                                </div>
                            </div>   
                        </div>
        </>
    );

}

export default MonitorForm;