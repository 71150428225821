import React from "react";
import GaugeChart from "react-gauge-chart";
import './ClassificacaoChart.css'; // Adicione o arquivo de estilo
import Aviso01 from '../Aviso01/index';

const ClassificacaoChart = (props) => {
  return (
    <div className="featured-ProgressBar">




      <div className="top">
        <h1 className="title">Indicador de Qualidade dos Atendimentos</h1>
      </div>

      {props.obj > 0 ? (
        <>
          <div className="gauge-container">
            <GaugeChart
              id="gauge-chart"
              nrOfLevels={5}  // Número de seções no gráfico
              colors={["#ff0000", "#ff6600", "#ffff00", "#66ff66", "#00cc00"]} // Cores para cada seção
              arcWidth={0.3}  // Largura do arco
              percent={0.75}  // Posição do ponteiro (75% = "Bom", por exemplo)
              textColor="#000000"  // Cor do texto
            />
          </div>
          <div className="gauge-labels">
            <span>Muito Ruim</span>
            <span>Ruim</span>
            <span>Regular</span>
            <span>Bom</span>
            <span>Ótimo</span>
          </div>
        </>
      ) : (
        <Aviso01 />
      )}

    </div>
  );
};

export default ClassificacaoChart;
