import React, { PureComponent, useState } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
import "./index.css";
import api from "../../Services/api";
import { useNavigate } from "react-router";
import { AxiosResponse } from "axios";
import { FaEraser } from "react-icons/fa";
import { FcSearch, FcPlus, FcFilledFilter } from "react-icons/fc";
import secureLocalStorage from "react-secure-storage";
import Select from 'react-select'
import { FaListAlt } from "react-icons/fa";
import Loading from '../../components/Loading';
import ModalPrincipal from '../../components/ModalAlert/modal'

export class Child extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true, 
      offset: 0,
      tableData: [],
      orgtableData: [],
      perPage: 11,
      currentPage: 0,
      pagAtual: 1,
      length: 0,
      endpoint: this.props.endPoint,
      clicked: false,
      atualiza: 0,
      search: '',
      isChecked: [],
      consult: this.props.consult,
      forcePage: 0,
      mrc_active: '',
      mrc_description: '',
      mrc_alternativeidentifier: '',
      clickedmyrecords: false,
      token: false,
      Nacesso: false,
    };

    this.handlePageClick = this.handlePageClick.bind(this);
  }


  componentDidMount() {

    this.getData();

    if (this.props.pag === "myrecords") {
      this.setState({ mrc_active: '1' });
    }

  }


  getData() {

    this.setState({ isLoading: true });

    let dados = [];

    if (this.props.pag === "myrecords") {

      dados.push({
        mrc_active: '1',
        mrc_description: '',
        mrc_alternativeidentifier: '',
        search: '',
      })

      const _user = secureLocalStorage.getItem('t')

      api.defaults.headers.common['Authorization'] = _user

      api.get(this.props.endPoint, {
        params: {
          data: dados
        }
      }).then((res) => {
        const data = res.data.data || [];

        if (res.data.status === 200) {
          const slice = data.slice(
            this.state.offset,
            this.state.offset + this.state.perPage
          );

          this.setState({
            pageCount: Math.ceil(data.length / this.state.perPage),
            orgtableData: data,
            tableData: slice,
            dataT: data,
            length: data.length,
          });
        } else {
          const slice = data.slice(
            this.state.offset,
            this.state.offset + this.state.perPage
          );

          this.setState({
            pageCount: Math.ceil(data.length / this.state.perPage),
            orgtableData: [],
            tableData: slice,
            dataT: [],
            length: data.length,
          });
        }

        this.setState({          
          isLoading: false,
        });

      }).catch((e) => {

        const data = [];
        const slice = data.slice(
          this.state.offset,
          this.state.offset + this.state.perPage
        );

        this.setState({
          pageCount: Math.ceil(data.length / this.state.perPage),
          orgtableData: [],
          tableData: slice,
          dataT: [],
          length: data.length,
        });

        if (!e.response) {

          this.setState({ isLoading: false });
          this.setState({ Nacesso: true });

        } else if (e.response.status === 403) {
        
          this.setState({ isLoading: false });
          this.setState({ token: true });

        } else {
          
          this.setState({ isLoading: false });
          this.setState({ Nacesso: true });

        }

      });
    }

  }


  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
        pagAtual: selectedPage + 1,
        forcePage: selectedPage
      },
      () => {
        this.loadMoreData();
      }
    );


  };

  loadMoreData() {

    const data = this.state.dataT;

    const slice = data.slice(
      this.state.offset,
      this.state.offset + this.state.perPage,

    );


    //////////////////////aqui
    this.setState({
      pageCount: Math.ceil(data.length / this.state.perPage),
      tableData: slice,
      length: data.length,
    });

  }


  handlePageClick_clear = () => {
    const selectedPage = 0;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
        pagAtual: selectedPage + 1,
        forcePage: selectedPage,
      },
      () => {
        this.loadMoreData();
      }
    );


  };


  handleInputChange = (event) => {
    this.setState({ search: event.target.value });
  };

  do_search = () => {

    this.setState({ isLoading: true });
    
    let dados = [];

    this.handlePageClick_clear()

    if (this.props.pag === "myrecords") {

      dados.push({
        mrc_active: this.state.mrc_active,
        mrc_description: this.state.mrc_description,
        mrc_alternativeidentifier: this.state.mrc_alternativeidentifier,
        search: this.state.search,
      })


      api.get(this.props.buscarmyrecords, {
        params: {
          data: dados
        }
      }).then((res) => {

        var data = res.data.data;

        if (res.data.status === 200) {

          var slice = data.slice(
            this.state.offset,
            this.state.offset + this.state.perPage
          );

          this.setState({
            pageCount: Math.ceil(data.length / this.state.perPage),
            orgtableData: res.data.data,
            tableData: slice,
            dataT: res.data.data,
            length: data.length,
          });

        }

        this.setState({          
          isLoading: false,
        });

      })
        .catch(function (e) {
 
          const data = [];
          const slice = data.slice(
            this.state.offset,
            this.state.offset + this.state.perPage
          );
  
          this.setState({
            pageCount: Math.ceil(data.length / this.state.perPage),
            orgtableData: [],
            tableData: slice,
            dataT: [],
            length: data.length,
          });

          if (!e.response) {

            this.setState({ isLoading: false });
            this.setState({ Nacesso: true });

          } else if (e.response.status === 403) {
          
            this.setState({ isLoading: false });
            this.setState({ token: true });

          } else {
            
            this.setState({ isLoading: false });
            this.setState({ Nacesso: true });

          }
          
        });

    }

  };

  clear_search = () => {

    window.location.reload();

  };


  toggle = (index) => {
    if (this.state.clicked === index) {
      //if clicked question is already active, then close it
      return this.setState({ clicked: null });
    }
    this.setState({ clicked: index });
  };

  handlecheckbox = (e) => {
    const { value, checked } = e.target;

    if (checked) {

      this.setState({
        isChecked: ([...this.state.isChecked, value])
      });
  
    } else {


      this.setState({
        isChecked: (this.state.isChecked.filter((e) => e !== value))
      });
      

    }

  }


  exibirFiltro = () => {

    if (this.props.pag === "myrecords") {
      if (this.state.clickedmyrecords === true) {
        this.setState({
          clickedmyrecords: false
        });
      } else {
        this.setState({
          clickedmyrecords: true
        });
      }
    }
  }

  handleInputChangeAtivo = (event) => {

    if (this.props.pag === "myrecords") {
      this.setState({ mrc_active: event.target.value });
    }
  }

  funcNsolicitacao = () => {
    this.setState({ Nacesso: false });
  }

  render() {
    return (
      <div className="ContainerGrid">
        <div className="ContainerBody">
          <div className="InlineContainery">

            <div className="InputBusca">
              <input
                autoComplete="off"
                placeholder="Pesquisar..."
                onChange={this.handleInputChange}
                value={this.state.search}
              />

              <div className="icone-busca" onClick={this.do_search}>
                <FcSearch className="icone-add" />
              </div>

              <div className="icone-busca" onClick={this.clear_search}>
                <FaEraser className="icone-add" />
              </div>

              <div className="icone-buscaT" onClick={this.exibirFiltro}>
                <FcFilledFilter className="icone-add" />
              </div>

            </div>
          </div>

        </div>

        <div className="ContainerDropdown">
          <>
            {this.state.clickedmyrecords === true ? (

              <div className="container-Dropdown-busca">

                <div className="container-cad-prod6">
                  <div id="content1">
                    <div className="input-box-ativos">
                      <span className="details">Ativo</span>
                      <select id="SELCAT" name="SELCAT" key='CAT0' onChange={this.handleInputChangeAtivo}
                        value={this.state.mrc_active}>
                        <option value="">Todos</option>
                        <option value="1">Sim</option>
                        <option value="0">Não</option>
                      </select>
                    </div>
                  </div>

                  <div id="content2">
                    <div className="input-box-ativos">
                      <span className="details">Descrição</span>
                      <input
                        type="text"
                        onChange={(e) => this.setState({ mrc_description: e.target.value })}
                        value={this.state.mrc_description}
                      />
                    </div>
                  </div>

                  <div id="content3">
                    <div className="input-box-ativos">
                      <span className="details">Identificador Alternativo</span>
                      <input
                        type="text"
                        onChange={(e) => this.setState({ mrc_alternativeidentifier: e.target.value })}
                        value={this.state.mrc_alternativeidentifier}
                      />
                    </div>
                  </div>
                </div>

              </div>
            ) : null}

          </>
        </div>

        {this.state.pageCount > 0 ? (
          <div className="grid-tab-user">
            <table>
              <tbody>
                <tr id="header-user">
                  <th className="Title-user">{this.props.th1}</th>
                  <th className="Title-user">{this.props.th2}</th>
                  <th className="Title-user">Ações</th>
                </tr>
              </tbody>
              <tbody>
                {this.state.tableData.map((tdata, i) => (
                  <tr className="TR-Coluna-user" key={Object.values(tdata)[this.props.col1]}>
                    <td className="Coluna-user-id">{Object.values(tdata)[this.props.col2]}</td>

                    {this.props.conc === 'S' ? (
                      <td className="Coluna-user-id">{Object.values(tdata)[this.props.col3]}</td>
                    ) : <td className="Coluna-user-id">{Object.values(tdata)[this.props.col3]?.substring(0, 62)}</td>}


                    <td className="Coluna-icone-btn2">


                      <button className="btn-icon view-btn" onClick={() => { this.props.ChamarModalCad(Object(tdata)) }}>
                        <FaListAlt />
                      </button>

                    </td>



                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : null}
        <div className="ContainerPag">
          <div className="InlineContainery">

            {this.state.pageCount === null || this.state.pageCount === 0 ? (
              <div className="SemResut">
                <h2>
                  Nenhum resultado encontrado.
                </h2>
                <h2>
                  Página 1 de 1
                </h2>
              </div>
            ) : null}


            <div className="InputBusca">
              {this.state.pageCount > 0 ? (
                <div className="Quant">
                  <h2>
                    Página {this.state.pagAtual} de {this.state.pageCount}
                  </h2>
                </div>
              ) : null}

              {this.state.pageCount > 1 ? (
                <ReactPaginate
                  previousLabel={<i className="fas fa-angle-left"></i>}
                  nextLabel={<i className="fas fa-angle-right"></i>}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.state.pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                  forcePage={this.state.forcePage}
                />
              ) : null}
            </div>
          </div>
          {this.state.pageCount > 0 ? (
            <div className="paginate">
              <h2>Total de registros: {this.state.length}</h2>
            </div>
          ) : null}
        </div>

        <ModalPrincipal isOpen={this.state.isLoading}>
          <div className='container-modal-alert'>
            <div className='div-alert'><Loading /></div>
          </div>
        </ModalPrincipal>
        
        <ModalPrincipal isOpen={this.state.token}>
          <div className='container-modal-alert'>
            <div className='div-alert'>
              <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
              <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
              <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
              <div className='btn-alert' onClick={this.props.fecharModal}><span className="btn-alert-lab">OK</span></div>
            </div>
          </div>
        </ModalPrincipal>

        <ModalPrincipal isOpen={this.state.Nacesso}>
          <div className='container-modal-alert'>
            <div className='div-alert'>
              <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
              <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
              <div className='div-info-alert'><span className="info-alert">Não foi possível concluir sua solicitação.</span></div>
              <div className='btn-alert' onClick={this.funcNsolicitacao}><span className="btn-alert-lab">OK</span></div>
            </div>
          </div>
        </ModalPrincipal>

      </div>
    );
  }
}

export default Child;
