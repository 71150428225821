import React, { useEffect, useState, useRef } from 'react'
import Nav from "../../components/Nav";
import Header from "../../components/Header";
import api from "../../Services/api";
import { AxiosResponse } from "axios";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import './index.css'
import { columnsFromBackend } from './KanbanData';
import TaskCard from './TaskCard';
import Select from 'react-select'
import { FcFilledFilter, FcClearFilters } from "react-icons/fc";
import ModalPrincipal from '../../components/ModalAlert/modal'
import Loading from '../../components/Loading'
import { useNavigate } from 'react-router';
import secureLocalStorage from "react-secure-storage";


const Kanban = () => {
  let menuRef = useRef() as any;
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false)
  const [qualAlert, setQualAlert] = useState(<></>)

  const [columns, setColumns] = useState(columnsFromBackend);
  const [optionsAgent, setOptionsAgent] = useState([])
  const [optionsEquipe, setOptionsEquipe] = useState([])
  const [optionsForma, setOptionsForma] = useState([])
  const [optionsSituacao, setOptionsSituacao] = useState([])
  const [optionsTasktype, setOptionsTasktype] = useState([])

  const [optionsPreparacao, setOptionsPreparacao] = useState([])

  const [optionsAguardando, setOptionsAguardando] = useState([])

  const [optionsCampo, setOptionsCampo] = useState([])

  const [optionsTaskComp, setOptionsTaskComp] = useState([])

  const [age_id, setAge_id] = useState('');
  const [tea_id, setTea_id] = useState('');
  const [tsf_id, setTsf_id] = useState('');
  const [stn_id, setStn_id] = useState('');

  const [filterPreparacao, setFilterPreparacao] = useState('');
  const [descricaoPreparacao, setDescricaoPreparacao] = useState('');
  const [tty_idPreparacao, setTty_idPreparacao] = useState('');
  const [dataIniPreparacao, setDataIniPreparacao] = useState('');
  const [dataFimPreparacao, setDataFimPreparacao] = useState('');

  const [filterAguardando, setFilterAguardando] = useState('');
  const [descricaoAguardando, setDescricaoAguardando] = useState('');
  const [tty_idAguardando, setTty_idAguardando] = useState('');
  const [dataIniAguardando, setDataIniAguardando] = useState('');
  const [dataFimAguardando, setDataFimAguardando] = useState('');


  const [filterCampo, setFilterCampo] = useState('');
  const [descricaoCampo, setDescricaoCampo] = useState('');
  const [tty_idCampo, setTty_idCampo] = useState('');
  const [dataIniCampo, setDataIniCampo] = useState('');
  const [dataFimCampo, setDataFimCampo] = useState('');

  const [isDisabledForma, setIsDisabledForma] = useState(true)
  const [openModalIncluirNovo, setOpenModalIncluirNovo] = useState(false)

  useEffect(() => {

    setQualAlert(<div className='div-alert'><Loading /></div>)
    setOpenModal(true)

    validatToken();

}, []);


  async function validatToken() {

    let ret: AxiosResponse;
    let valor: any;


    const _user = secureLocalStorage.getItem('t')

    api.defaults.headers.common['Authorization'] = _user as ''

    try {

        ret = await api.get("/status/validar")

        if (ret.data.status === 200) {

            secureLocalStorage.setItem('t', ret.data.data);
            secureLocalStorage.setItem('acp', ret.data.decodedToken.acp_integrationid);
            secureLocalStorage.setItem('n', ret.data.decodedToken.name);
            secureLocalStorage.setItem('iag', ret.data.decodedToken.id);
            secureLocalStorage.setItem('cy', ret.data.decodedToken.company);



            setOpenModal(false)
        }

    } catch (e: any) {

        if (e.response.data.status === 403) {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
                <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
            </div>)

            setOpenModal(true)

        }
    }

}


function fecharModal() {

  secureLocalStorage.removeItem('t')
  secureLocalStorage.removeItem('acp')
  secureLocalStorage.removeItem('n')
  secureLocalStorage.removeItem('iag')
  navigate('/login');
  setOpenModal(false)

}

  async function GetTask() {
    let ret: AxiosResponse;
    let valor: any;
    try {

      ret = await api.get("task/taskdirection")
      if (ret.data.status === 200) {
        valor = ret.data.data;

        setOptionsTaskComp(valor)


        const FiltrarPreparacao = valor.filter(
          (e: any) => {
            return e.stn_id === 20
          }
        );

        setOptionsPreparacao(FiltrarPreparacao)
   

        const FilterAguardando = valor.filter(
          (e: any) => {
            return e.stn_id === 30
          }
        );

        setOptionsAguardando(FilterAguardando)
 

        const FilterCampo = valor.filter(
          (e: any) => {
            return e.stn_id === 40
          }
        );

        setOptionsCampo(FilterCampo)
  

        const columnsFrom = {
          preparacao: {
            title: 'Em Preparação',
            items: FiltrarPreparacao,
          },
          aguardando: {
            title: 'Aguardando Envio',
            items: FilterAguardando,
          },
          campo: {
            title: 'Em Campo',
            items: FilterCampo,
          },
          alteracao: {
            title: 'Alteração',
            items: [] as any,
          }

        }

        setColumns(columnsFrom)

      }
    } catch (e) {

    }
  }

  const onDragEnd = (result: any, columns: any, setColumns: any) => {

    //aqui

    if (!result.destination) return;
    const { source, destination } = result;

    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      });
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      });
    }

 
  };

  function carregar() {

    setAge_id('')
setTea_id('')
setTsf_id('')
setStn_id('')

  GetTask();
    GetAgent();
GetEquipe();
    GetForma();
    GetSituacao();
  GetTasktype();

    filtrarAguardandoLimpar();
    filtrarPreparacaoLimpar();
    filtrarCampoLimpar();
    setOpenModal(false)
  }

  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      background: '#fff',
      minHeight: '30px',
      height: '30px',
      borderColor: state.isFocused ? 'grey' : '#92989e',

    }),


    valueContainer: (provided: any, state: any) => ({
      ...provided,
      height: '30px',
      padding: '0 6px'
    }),

    input: (provided: any, state: any) => ({
      ...provided,
      margin: '0px',
      fontSize: 14,

    }),
    indicatorsContainer: (provided: any, state: any) => ({
      ...provided,
      height: '30px',
    }),
  };

  function SelecionarAgent(id: any) {

    if (id === '') {
      setAge_id("")
      setTea_id("")
      setTsf_id("")
      setIsDisabledForma(true)
    } else {
      setTea_id("")
      setTsf_id("")
      setAge_id(id)
      setIsDisabledForma(true)
    }
  }

  async function GetAgent() {
    let ret: AxiosResponse;
    let valor: any;
    try {
      ret = await api.get("user/listar")
      if (ret.data.status === 200) {
        valor = ret.data.data;
        const arr = [] as any;
        let result = valor;
        result.map((user: any) => {
          return arr.push({ value: String(user.age_id), label: user.age_name });
        });

        setOptionsAgent(arr)

      }
    } catch (e) {
      setOptionsAgent([])
    }
  }

  async function GetForma() {
    let ret: AxiosResponse;
    let valor: any;
    try {
      ret = await api.get("taskform/listar")
      if (ret.data.status === 200) {
        valor = ret.data.data;
        const arr = [] as any;
        let result = valor;
        result.map((user: any) => {
          return arr.push({ value: String(user.tsf_id), label: user.tsf_description });
        });

        setOptionsForma(arr)

      }
    } catch (e) {
      setOptionsForma([])
    }
  }

  function SelecionarEquipe(id: any) {

    if (id === '') {
      setTea_id("")
      setAge_id("")
      setTsf_id("")
      setIsDisabledForma(true)
    } else {
      setAge_id("")
      setTea_id(id)
      setIsDisabledForma(false)
    }
  }

  async function GetEquipe() {
    let ret: AxiosResponse;
    let valor: any;
    try {
      ret = await api.get("team/listar")
      if (ret.data.status === 200) {
        valor = ret.data.data;
        const arr = [] as any;
        let result = valor;
        result.map((user: any) => {
          return arr.push({ value: String(user.tae_id), label: user.tae_description });
        });

        setOptionsEquipe(arr)

      }
    } catch (e) {
      setOptionsEquipe([])
    }
  }

  async function GetSituacao() {
    let ret: AxiosResponse;
    let valor: any;
    try {
      ret = await api.get("tasksituation/listar")
      if (ret.data.status === 200) {
        valor = ret.data.data;
        const arr = [] as any;
        let result = valor;

        const situacao = valor.filter(
          (e: any) => {
            return e.stn_id === 20 || e.stn_id === 30 || e.stn_id === 40
          }
        );

        situacao.map((user: any) => {
          return arr.push({ value: String(user.stn_id), label: user.stn_description });
        });



        setOptionsSituacao(arr)

      }
    } catch (e) {
      setOptionsSituacao([])
    }
  }

  async function GetTasktype() {
    let ret: AxiosResponse;
    let valor: any;
    try {
      ret = await api.get("tasktype/listar")
      if (ret.data.status === 200) {
        valor = ret.data.data;
        const arr = [] as any;


        const tasktype = valor.filter(
          (e: any) => {
            return e.tty_id !== 1
          }
        );

        tasktype.map((user: any) => {
          return arr.push({ value: String(user.tty_id), label: user.tty_description });
        });

        setOptionsTasktype(arr)

      }
    } catch (e) {
      setOptionsTasktype([])
    }
  }





  function filtrarPreparacao() {

    let FiltrarPreparacao = columns.preparacao.items

    if (columns.preparacao.items.length > 0) {

      const FiltrarPreparacaoFilter = columns.preparacao.items.filter(function (objeto: never) {
        return columns.alteracao.items.indexOf(objeto) == -1
      });


      if (dataIniPreparacao === "" && dataFimPreparacao !== "") {

        setQualAlert(<div className='div-alert'>
        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
        <div className='div-info-alert'><span className="info-alert">Selecione a data inicial para realizar a busca por período.</span></div>
        <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
    </div>)

    setOpenModal(true)

      }else
      if (dataIniPreparacao !== "" && dataFimPreparacao === "") {

        
        setQualAlert(<div className='div-alert'>
        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
        <div className='div-info-alert'><span className="info-alert">Selecione a data final para realizar a busca por período.</span></div>
        <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
    </div>)

    setOpenModal(true)


      }else
      if (descricaoPreparacao !== "" && tty_idPreparacao === '' && dataIniPreparacao === '' && dataFimPreparacao === '') {

        FiltrarPreparacao = FiltrarPreparacaoFilter.filter(
          (e: any) => {
            return (
              JSON.stringify(e)
                .toLowerCase()
                .indexOf(descricaoPreparacao.toLowerCase()) >= 0
            );
          }
        );

        setFilterPreparacao('SIM')
      }else
      if (tty_idPreparacao !== "" && dataIniPreparacao === '' && dataFimPreparacao === '' && descricaoPreparacao === "" ) {

        FiltrarPreparacao = FiltrarPreparacaoFilter.filter(
          (e: any) => {
            return e.tty_id === Number(tty_idPreparacao)
          }
        );

        setFilterPreparacao('SIM')
      }else
      
      if (dataIniPreparacao !== "" && dataFimPreparacao !== "" && descricaoPreparacao === "" && tty_idPreparacao === '') {

        if (dataFimPreparacao < dataIniPreparacao) {

          setQualAlert(<div className='div-alert'>
          <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Data fim não pode ser menor que data inicial.</span></div>
          <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
      </div>)
  
      setOpenModal(true)

        } else if (dataIniPreparacao === dataFimPreparacao) {
          
          FiltrarPreparacao = FiltrarPreparacaoFilter.filter(
            (e: any) => {
              return e.tsk_scheduleinitialdatehour.substring(0, 10) === dataIniPreparacao
            }
          );


          setFilterPreparacao('SIM')

        } else {

          FiltrarPreparacao = FiltrarPreparacaoFilter.filter(
            (e: any) => {
              return e.tsk_scheduleinitialdatehour.substring(0, 10) >= dataIniPreparacao &&
                e.tsk_scheduleinitialdatehour.substring(0, 10) <= dataFimPreparacao
            }
          );

          setFilterPreparacao('SIM')

        }
      }else
      if (descricaoPreparacao !== "" && dataIniPreparacao !== "" && dataFimPreparacao !== "" && tty_idPreparacao !== '') {

        if (dataIniPreparacao === "") {

          
          setQualAlert(<div className='div-alert'>
          <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Selecione a data inicial.</span></div>
          <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
      </div>)
  
      setOpenModal(true)

        } else if (dataFimPreparacao === "") {

          setQualAlert(<div className='div-alert'>
          <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Selecione a data final.</span></div>
          <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
      </div>)
  
      setOpenModal(true)

        }
        else if (dataFimPreparacao < dataIniPreparacao) {

          setQualAlert(<div className='div-alert'>
          <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Data fim não pode ser menor que data inicial.</span></div>
          <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
      </div>)
  
      setOpenModal(true)

        } else if (dataIniPreparacao === dataFimPreparacao) {

          FiltrarPreparacao = FiltrarPreparacaoFilter.filter(
            (e: any) => {
              return (
                JSON.stringify(e)
                  .toLowerCase()
                  .indexOf(descricaoPreparacao.toLowerCase()) >= 0 && e.tsk_scheduleinitialdatehour.substring(0, 10) === dataIniPreparacao && e.tty_id === Number(tty_idPreparacao)
              );
            }
          );

          setFilterPreparacao('SIM')

        } else {

          FiltrarPreparacao = FiltrarPreparacaoFilter.filter(
            (e: any) => {
              return (
                JSON.stringify(e)
                  .toLowerCase()
                  .indexOf(descricaoPreparacao.toLowerCase()) >= 0 && 
                  e.tsk_scheduleinitialdatehour.substring(0, 10) >= dataIniPreparacao &&
                  e.tsk_scheduleinitialdatehour.substring(0, 10) <= dataFimPreparacao &&
                  e.tty_id === Number(tty_idPreparacao)
              );
            }
          );

     

          setFilterPreparacao('SIM')

        }

      }else if (descricaoPreparacao !== "" && tty_idPreparacao !== '' && dataIniPreparacao === "" && dataFimPreparacao === "" ) {

        FiltrarPreparacao = FiltrarPreparacaoFilter.filter(
          (e: any) => {
            return (
              JSON.stringify(e)
                .toLowerCase()
                .indexOf(descricaoPreparacao.toLowerCase()) >= 0 && e.tty_id === Number(tty_idPreparacao)
            );
          }
        );

        setFilterPreparacao('SIM')

      } else if (descricaoPreparacao !== "" && dataIniPreparacao !== "" && dataFimPreparacao !== "" && tty_idPreparacao === '') {

        if (dataIniPreparacao === "") {

          setQualAlert(<div className='div-alert'>
          <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Selecione a data inicial.</span></div>
          <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
      </div>)
  
      setOpenModal(true)

        } else if (dataFimPreparacao === "") {


          setQualAlert(<div className='div-alert'>
          <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Selecione a data final.</span></div>
          <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
      </div>)
  
      setOpenModal(true)

        }
        else if (dataFimPreparacao < dataIniPreparacao) {

          setQualAlert(<div className='div-alert'>
          <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Data fim não pode ser menor que data inicial.</span></div>
          <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
      </div>)
  
      setOpenModal(true)

        } else if (dataIniPreparacao === dataFimPreparacao) {

          FiltrarPreparacao = FiltrarPreparacaoFilter.filter(
            (e: any) => {
              return (
                JSON.stringify(e)
                  .toLowerCase()
                  .indexOf(descricaoPreparacao.toLowerCase()) >= 0 && e.tsk_scheduleinitialdatehour.substring(0, 10) === dataIniPreparacao
              );
            }
          );

          setFilterPreparacao('SIM')

        } else {

          FiltrarPreparacao = FiltrarPreparacaoFilter.filter(
            (e: any) => {
              return (
                JSON.stringify(e)
                  .toLowerCase()
                  .indexOf(descricaoPreparacao.toLowerCase()) >= 0 && 
                  e.tsk_scheduleinitialdatehour.substring(0, 10) >= dataIniPreparacao &&
                  e.tsk_scheduleinitialdatehour.substring(0, 10) <= dataFimPreparacao
              );
            }
          );

          setFilterPreparacao('SIM')

        }

      }else


      if (tty_idPreparacao !== '' && dataIniPreparacao !== "" || dataFimPreparacao !== "" && descricaoPreparacao === "") {

        if (dataIniPreparacao === "") {

          
          setQualAlert(<div className='div-alert'>
          <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Selecione a data inicial.</span></div>
          <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
      </div>)
  
      setOpenModal(true)

        } else if (dataFimPreparacao === "") {


          setQualAlert(<div className='div-alert'>
          <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Selecione a data final.</span></div>
          <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
      </div>)
  
      setOpenModal(true)

        }
        else if (dataFimPreparacao < dataIniPreparacao) {

          setQualAlert(<div className='div-alert'>
          <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Data fim não pode ser menor que data inicial.</span></div>
          <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
      </div>)
  
      setOpenModal(true)

        } else if (dataIniPreparacao === dataFimPreparacao) {

          FiltrarPreparacao = FiltrarPreparacaoFilter.filter(
            (e: any) => {
              return (
                JSON.stringify(e)
                  .toLowerCase()
                  .indexOf(descricaoPreparacao.toLowerCase()) >= 0 && e.tsk_scheduleinitialdatehour.substring(0, 10) === dataIniPreparacao&& e.tty_id === Number(tty_idPreparacao)
              );
            }
          );

          setFilterPreparacao('SIM')

        } else {

          FiltrarPreparacao = FiltrarPreparacaoFilter.filter(
            (e: any) => {
              return e.tsk_scheduleinitialdatehour.substring(0, 10) >= dataIniPreparacao &&
                e.tsk_scheduleinitialdatehour.substring(0, 10) <= dataFimPreparacao && 
                e.tty_id === Number(tty_idPreparacao)
            }
          );


       
          setFilterPreparacao('SIM')

        }

      }
    
      

    
   
    


      const columnsFrom = {
        preparacao: {
          title: 'Em Preparação',
          items: FiltrarPreparacao,
        },
        aguardando: {
          title: 'Aguardando Envio',
          items: columns.aguardando.items,
        },
        campo: {
          title: 'Em Campo',
          items: columns.campo.items,
        },
        alteracao: {
          title: 'Alteração',
          items: columns.alteracao.items,
        }

      }

      setColumns(columnsFrom)

    } else {

      setQualAlert(<div className='div-alert'>
      <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
      <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
      <div className='div-info-alert'><span className="info-alert">Não existe tarefas a serem filtradas.</span></div>
      <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
  </div>)

  setOpenModal(true)

    }
  }








  function filtrarPreparacaoLimpar() {

    //filtrarPreparacaoLimpar
    var listaA = [];

    listaA = optionsPreparacao.filter(function (objeto: never) {
      return columns.campo.items.indexOf(objeto) == -1
    });

    listaA = optionsPreparacao.filter(function (objeto: never) {
      return columns.preparacao.items.indexOf(objeto) == -1
    });


    listaA = optionsPreparacao.filter(function (objeto: never) {
      return columns.alteracao.items.indexOf(objeto) == -1
    });

  

    const columnsFrom = {
      preparacao: {
        title: 'Em Preparação',
        items: listaA,
      },
      aguardando: {
        title: 'Aguardando Envio',
        items: columns.aguardando.items,
      },
      campo: {
        title: 'Em Campo',
        items: columns.campo.items,
      },
      alteracao: {
        title: 'Alteração',
        items: columns.alteracao.items,
      }

    }

    setFilterPreparacao('')
    setColumns(columnsFrom)

    setDescricaoPreparacao("")
    setTty_idPreparacao("")
    setDataIniPreparacao("")
    setDataFimPreparacao("")
  }

  function incluirIdPreparacao(id: any) {
    if (id === "") {
    //  filtrarPreparacaoLimpar();
      setTty_idPreparacao("")
    } else {
      setTty_idPreparacao(id)
    }
  }



  function filtrarAguardandoLimpar() {

    //filtrarAguardandoLimpar
    var listaA = [];

    listaA = optionsAguardando.filter(function (objeto: never) {
      return columns.campo.items.indexOf(objeto) == -1
    });

    listaA = optionsAguardando.filter(function (objeto: never) {
      return columns.preparacao.items.indexOf(objeto) == -1
    });


    listaA = optionsAguardando.filter(function (objeto: never) {
      return columns.alteracao.items.indexOf(objeto) == -1
    });

  

    const columnsFrom = {
      preparacao: {
        title: 'Em Preparação',
        items: columns.preparacao.items,
      },
      aguardando: {
        title: 'Aguardando Envio',
        items: listaA,
      },
      campo: {
        title: 'Em Campo',
        items: columns.campo.items,
      },
      alteracao: {
        title: 'Alteração',
        items: columns.alteracao.items,
      }

    }

    setFilterAguardando('')
    setColumns(columnsFrom)

    setDescricaoAguardando("")
    setTty_idAguardando("")
    setDataIniAguardando("")
    setDataFimAguardando("")
  }


  function incluirIdAguardando(id: any) {
    if (id === "") {
    //  filtrarPreparacaoLimpar();
      setTty_idAguardando("")
    } else {
      setTty_idAguardando(id)
    }
  }



  function filtrarAguardando (id: any) {

    let FiltrarAguardando = columns.aguardando.items

    if (columns.aguardando.items.length > 0) {

      const FiltrarAguardandoFilter = columns.aguardando.items.filter(function (objeto: never) {
        return columns.alteracao.items.indexOf(objeto) == -1
      });


      if (dataIniAguardando === "" && dataFimAguardando !== "") {

        setQualAlert(<div className='div-alert'>
        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
        <div className='div-info-alert'><span className="info-alert">Selecione a data inicial para realizar a busca por período.</span></div>
        <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
    </div>)
  
    setOpenModal(true)

      }else
      if (dataIniAguardando !== "" && dataFimAguardando === "") {

        setQualAlert(<div className='div-alert'>
        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
        <div className='div-info-alert'><span className="info-alert">Selecione a data final para realizar a busca por período.</span></div>
        <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
    </div>)
  
    setOpenModal(true)
    
      }else
      if (descricaoAguardando !== "" && tty_idAguardando === '' && dataIniAguardando === '' && dataFimAguardando === '') {

        FiltrarAguardando = FiltrarAguardandoFilter.filter(
          (e: any) => {
            return (
              JSON.stringify(e)
                .toLowerCase()
                .indexOf(descricaoAguardando.toLowerCase()) >= 0
            );
          }
        );

        setFilterAguardando('SIM')
      }else
      if (tty_idAguardando !== "" && dataIniAguardando === '' && dataFimAguardando === '' && descricaoAguardando === "" ) {

        FiltrarAguardando = FiltrarAguardandoFilter.filter(
          (e: any) => {
            return e.tty_id === Number(tty_idAguardando)
          }
        );

        setFilterAguardando('SIM')
      }else
      
      if (dataIniAguardando !== "" && dataFimAguardando !== "" && descricaoAguardando === "" && tty_idAguardando === '') {

        if (dataFimAguardando < dataIniAguardando) {


          setQualAlert(<div className='div-alert'>
          <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Data fim não pode ser menor que data inicial.</span></div>
          <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
      </div>)
    
      setOpenModal(true)
    
        } else if (dataIniAguardando === dataFimAguardando) {

          FiltrarAguardando = FiltrarAguardandoFilter.filter(
            (e: any) => {
              return e.tsk_scheduleinitialdatehour.substring(0, 10) === dataIniAguardando
            }
          );


          setFilterAguardando('SIM')

        } else {

          FiltrarAguardando = FiltrarAguardandoFilter.filter(
            (e: any) => {
              return e.tsk_scheduleinitialdatehour.substring(0, 10) >= dataIniAguardando &&
                e.tsk_scheduleinitialdatehour.substring(0, 10) <= dataFimAguardando
            }
          );

          setFilterAguardando('SIM')

        }
      }else
      if (descricaoAguardando !== "" && dataIniAguardando !== "" && dataFimAguardando !== "" && tty_idAguardando !== '') {

        if (dataIniAguardando === "") {

          setQualAlert(<div className='div-alert'>
          <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Selecione a data inicial.</span></div>
          <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
      </div>)
    
      setOpenModal(true)


        } else if (dataFimAguardando === "") {

          
          setQualAlert(<div className='div-alert'>
          <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Selecione a data final.</span></div>
          <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
      </div>)
    
      setOpenModal(true)

        }
        else if (dataFimAguardando < dataIniAguardando) {

          setQualAlert(<div className='div-alert'>
          <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Data fim não pode ser menor que data inicial.</span></div>
          <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
      </div>)
    
      setOpenModal(true)

        } else if (dataIniAguardando === dataFimAguardando) {

          FiltrarAguardando = FiltrarAguardandoFilter.filter(
            (e: any) => {
              return (
                JSON.stringify(e)
                  .toLowerCase()
                  .indexOf(descricaoAguardando.toLowerCase()) >= 0 && e.tsk_scheduleinitialdatehour.substring(0, 10) === dataIniAguardando && e.tty_id === Number(tty_idAguardando)
              );
            }
          );

          setFilterAguardando('SIM')

        } else {

          FiltrarAguardando = FiltrarAguardandoFilter.filter(
            (e: any) => {
              return (
                JSON.stringify(e)
                  .toLowerCase()
                  .indexOf(descricaoAguardando.toLowerCase()) >= 0 && 
                  e.tsk_scheduleinitialdatehour.substring(0, 10) >= dataIniAguardando &&
                  e.tsk_scheduleinitialdatehour.substring(0, 10) <= dataFimAguardando &&
                  e.tty_id === Number(tty_idAguardando)
              );
            }
          );

     

          setFilterAguardando('SIM')

        }

      }else if (descricaoAguardando !== "" && tty_idAguardando !== '' && dataIniAguardando === "" && dataFimAguardando === "" ) {

        FiltrarAguardando = FiltrarAguardandoFilter.filter(
          (e: any) => {
            return (
              JSON.stringify(e)
                .toLowerCase()
                .indexOf(descricaoAguardando.toLowerCase()) >= 0 && e.tty_id === Number(tty_idAguardando)
            );
          }
        );

        setFilterAguardando('SIM')

      } else if (descricaoAguardando !== "" && dataIniAguardando !== "" && dataFimAguardando !== "" && tty_idAguardando === '') {

        if (dataIniAguardando === "") {

          setQualAlert(<div className='div-alert'>
          <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Selecione a data inicial.</span></div>
          <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
      </div>)
    
      setOpenModal(true)
      
        } else if (dataFimAguardando === "") {

          setQualAlert(<div className='div-alert'>
          <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Selecione a data final.</span></div>
          <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
      </div>)
    
      setOpenModal(true)

        }
        else if (dataFimAguardando < dataIniAguardando) {

          setQualAlert(<div className='div-alert'>
          <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Data fim não pode ser menor que data inicial.</span></div>
          <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
      </div>)
    
      setOpenModal(true)

        } else if (dataIniAguardando === dataFimAguardando) {

          FiltrarAguardando = FiltrarAguardandoFilter.filter(
            (e: any) => {
              return (
                JSON.stringify(e)
                  .toLowerCase()
                  .indexOf(descricaoAguardando.toLowerCase()) >= 0 && e.tsk_scheduleinitialdatehour.substring(0, 10) === dataIniAguardando
              );
            }
          );

          setFilterAguardando('SIM')

        } else {

          FiltrarAguardando = FiltrarAguardandoFilter.filter(
            (e: any) => {
              return (
                JSON.stringify(e)
                  .toLowerCase()
                  .indexOf(descricaoAguardando.toLowerCase()) >= 0 && 
                  e.tsk_scheduleinitialdatehour.substring(0, 10) >= dataIniAguardando &&
                  e.tsk_scheduleinitialdatehour.substring(0, 10) <= dataFimAguardando
              );
            }
          );

          setFilterAguardando('SIM')

        }

      }else


      if (tty_idAguardando !== '' && dataIniAguardando !== "" || dataFimAguardando !== "" && descricaoAguardando === "") {

        if (dataIniAguardando === "") {

          setQualAlert(<div className='div-alert'>
          <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Selecione a data inicial.</span></div>
          <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
      </div>)
    
      setOpenModal(true)

        } else if (dataFimAguardando === "") {

          setQualAlert(<div className='div-alert'>
          <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Selecione a data final.</span></div>
          <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
      </div>)
    
      setOpenModal(true)

        }
        else if (dataFimAguardando < dataIniAguardando) {

          setQualAlert(<div className='div-alert'>
          <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Data fim não pode ser menor que data inicial.</span></div>
          <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
      </div>)
    
      setOpenModal(true)

        } else if (dataIniAguardando === dataFimAguardando) {

          FiltrarAguardando = FiltrarAguardandoFilter.filter(
            (e: any) => {
              return (
                JSON.stringify(e)
                  .toLowerCase()
                  .indexOf(descricaoAguardando.toLowerCase()) >= 0 && e.tsk_scheduleinitialdatehour.substring(0, 10) === dataIniAguardando&& e.tty_id === Number(tty_idAguardando)
              );
            }
          );

          setFilterAguardando('SIM')

        } else {

          FiltrarAguardando = FiltrarAguardandoFilter.filter(
            (e: any) => {
              return e.tsk_scheduleinitialdatehour.substring(0, 10) >= dataIniAguardando &&
                e.tsk_scheduleinitialdatehour.substring(0, 10) <= dataFimAguardando && 
                e.tty_id === Number(tty_idAguardando)
            }
          );


       
          setFilterAguardando('SIM')

        }

      }

     
      const columnsFrom = {
        preparacao: {
          title: 'Em Preparação',
          items: columns.preparacao.items,
        },
        aguardando: {
          title: 'Aguardando Envio',
          items: FiltrarAguardando,
        },
        campo: {
          title: 'Em Campo',
          items: columns.campo.items,
        },
        alteracao: {
          title: 'Alteração',
          items: columns.alteracao.items,
        }

      }

      setColumns(columnsFrom)

    } else {

      setQualAlert(<div className='div-alert'>
      <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
      <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
      <div className='div-info-alert'><span className="info-alert">Não existe tarefas a serem filtradas.</span></div>
      <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
  </div>)

  setOpenModal(true)

    }
  }
  
  ///aqui campo

  function incluirIdCampo(id: any) {
    if (id === "") {
    //  filtrarPreparacaoLimpar();
      setTty_idCampo("")
    } else {
      setTty_idCampo(id)
    }
  }

  function filtrarCampoLimpar() {

    //filtrarCampoLimpar
    var listaA = [];

    listaA = optionsCampo.filter(function (objeto: never) {
      return columns.campo.items.indexOf(objeto) == -1
    });

    listaA = optionsCampo.filter(function (objeto: never) {
      return columns.preparacao.items.indexOf(objeto) == -1
    });


    listaA = optionsCampo.filter(function (objeto: never) {
      return columns.alteracao.items.indexOf(objeto) == -1
    });

    const columnsFrom = {
      preparacao: {
        title: 'Em Preparação',
        items: columns.preparacao.items,
      },
      aguardando: {
        title: 'Aguardando Envio',
        items: columns.aguardando.items,
      },
      campo: {
        title: 'Em Campo',
        items: listaA,
      },
      alteracao: {
        title: 'Alteração',
        items: columns.alteracao.items,
      }

    }

    setFilterCampo('')
    setColumns(columnsFrom)

    setDescricaoCampo("")
    setTty_idCampo("")
    setDataIniCampo("")
    setDataFimCampo("")
  }


  function filtrarCampo (id: any) {

   
    
    let FiltrarCampo = columns.campo.items

    if (columns.campo.items.length > 0) {

      const FiltrarCampoFilter = columns.campo.items.filter(function (objeto: never) {
        return columns.alteracao.items.indexOf(objeto) == -1
      });


      if (dataIniCampo === "" && dataFimCampo !== "") {

        setQualAlert(<div className='div-alert'>
        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
        <div className='div-info-alert'><span className="info-alert">Selecione a data inicial para realizar a busca por período.</span></div>
        <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
    </div>)
  
    setOpenModal(true)

      }else
      if (dataIniCampo !== "" && dataFimCampo === "") {

        setQualAlert(<div className='div-alert'>
        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
        <div className='div-info-alert'><span className="info-alert">Selecione a data final para realizar a busca por período.</span></div>
        <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
    </div>)
  
    setOpenModal(true)

      }else
      if (descricaoCampo !== "" && tty_idCampo === '' && dataIniCampo === '' && dataFimCampo === '') {

        FiltrarCampo = FiltrarCampoFilter.filter(
          (e: any) => {
            return (
              JSON.stringify(e)
                .toLowerCase()
                .indexOf(descricaoCampo.toLowerCase()) >= 0
            );
          }
        );

        setFilterCampo('SIM')
      }else
      if (tty_idCampo !== "" && dataIniCampo === '' && dataFimCampo === '' && descricaoCampo === "" ) {

        FiltrarCampo = FiltrarCampoFilter.filter(
          (e: any) => {
            return e.tty_id === Number(tty_idCampo)
          }
        );

        setFilterCampo('SIM')
      }else
      
      if (dataIniCampo !== "" && dataFimCampo !== "" && descricaoCampo === "" && tty_idCampo === '') {

        if (dataFimCampo < dataIniCampo) {

          setQualAlert(<div className='div-alert'>
          <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Data fim não pode ser menor que data inicial.</span></div>
          <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
      </div>)
    
      setOpenModal(true)

        } else if (dataIniCampo === dataFimCampo) {

          FiltrarCampo = FiltrarCampoFilter.filter(
            (e: any) => {
              return e.tsk_scheduleinitialdatehour.substring(0, 10) === dataIniCampo
            }
          );


          setFilterCampo('SIM')

        } else {

          FiltrarCampo = FiltrarCampoFilter.filter(
            (e: any) => {
              return e.tsk_scheduleinitialdatehour.substring(0, 10) >= dataIniCampo &&
                e.tsk_scheduleinitialdatehour.substring(0, 10) <= dataFimCampo
            }
          );

          setFilterCampo('SIM')

        }
      }else
      if (descricaoCampo !== "" && dataIniCampo !== "" && dataFimCampo !== "" && tty_idCampo !== '') {

        if (dataIniCampo === "") {


          setQualAlert(<div className='div-alert'>
          <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Selecione a data inicial.</span></div>
          <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
      </div>)
    
      setOpenModal(true)


        } else if (dataFimCampo === "") {

          setQualAlert(<div className='div-alert'>
          <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Selecione a data final.</span></div>
          <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
      </div>)
    
      setOpenModal(true)

        }
        else if (dataFimCampo < dataIniCampo) {

          setQualAlert(<div className='div-alert'>
          <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Data fim não pode ser menor que data inicial.</span></div>
          <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
      </div>)
    
      setOpenModal(true)

        } else if (dataIniCampo === dataFimCampo) {

          FiltrarCampo = FiltrarCampoFilter.filter(
            (e: any) => {
              return (
                JSON.stringify(e)
                  .toLowerCase()
                  .indexOf(descricaoCampo.toLowerCase()) >= 0 && e.tsk_scheduleinitialdatehour.substring(0, 10) === dataIniCampo && e.tty_id === Number(tty_idCampo)
              );
            }
          );

          setFilterCampo('SIM')

        } else {

          FiltrarCampo = FiltrarCampoFilter.filter(
            (e: any) => {
              return (
                JSON.stringify(e)
                  .toLowerCase()
                  .indexOf(descricaoCampo.toLowerCase()) >= 0 && 
                  e.tsk_scheduleinitialdatehour.substring(0, 10) >= dataIniCampo &&
                  e.tsk_scheduleinitialdatehour.substring(0, 10) <= dataFimCampo &&
                  e.tty_id === Number(tty_idCampo)
              );
            }
          );

     

          setFilterCampo('SIM')

        }

      }else if (descricaoCampo !== "" && tty_idCampo !== '' && dataIniCampo === "" && dataFimCampo === "" ) {

        FiltrarCampo = FiltrarCampoFilter.filter(
          (e: any) => {
            return (
              JSON.stringify(e)
                .toLowerCase()
                .indexOf(descricaoCampo.toLowerCase()) >= 0 && e.tty_id === Number(tty_idCampo)
            );
          }
        );

        setFilterCampo('SIM')

      } else if (descricaoCampo !== "" && dataIniCampo !== "" && dataFimCampo !== "" && tty_idCampo === '') {

        if (dataIniCampo === "") {

          setQualAlert(<div className='div-alert'>
          <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Selecione a data inicial.</span></div>
          <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
      </div>)
    
      setOpenModal(true)

        } else if (dataFimCampo === "") {

          setQualAlert(<div className='div-alert'>
          <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Selecione a data final.</span></div>
          <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
      </div>)
    
      setOpenModal(true)

        }
        else if (dataFimCampo < dataIniCampo) {

          setQualAlert(<div className='div-alert'>
          <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Data fim não pode ser menor que data inicial.</span></div>
          <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
      </div>)
    
      setOpenModal(true)

        } else if (dataIniCampo === dataFimCampo) {

          FiltrarCampo = FiltrarCampoFilter.filter(
            (e: any) => {
              return (
                JSON.stringify(e)
                  .toLowerCase()
                  .indexOf(descricaoCampo.toLowerCase()) >= 0 && e.tsk_scheduleinitialdatehour.substring(0, 10) === dataIniCampo
              );
            }
          );

          setFilterCampo('SIM')

        } else {

          FiltrarCampo = FiltrarCampoFilter.filter(
            (e: any) => {
              return (
                JSON.stringify(e)
                  .toLowerCase()
                  .indexOf(descricaoCampo.toLowerCase()) >= 0 && 
                  e.tsk_scheduleinitialdatehour.substring(0, 10) >= dataIniCampo &&
                  e.tsk_scheduleinitialdatehour.substring(0, 10) <= dataFimCampo
              );
            }
          );

          setFilterCampo('SIM')

        }

      }else


      if (tty_idCampo !== '' && dataIniCampo !== "" || dataFimCampo !== "" && descricaoCampo === "") {

        if (dataIniCampo === "") {

          setQualAlert(<div className='div-alert'>
          <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Selecione a data inicial.</span></div>
          <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
      </div>)
    
      setOpenModal(true)

        } else if (dataFimCampo === "") {

          setQualAlert(<div className='div-alert'>
          <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Selecione a data final.</span></div>
          <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
      </div>)
    
      setOpenModal(true)

        }
        else if (dataFimCampo < dataIniCampo) {

          setQualAlert(<div className='div-alert'>
          <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Data fim não pode ser menor que data inicial.</span></div>
          <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
      </div>)
    
      setOpenModal(true)

        } else if (dataIniCampo === dataFimCampo) {

          FiltrarCampo = FiltrarCampoFilter.filter(
            (e: any) => {
              return (
                JSON.stringify(e)
                  .toLowerCase()
                  .indexOf(descricaoCampo.toLowerCase()) >= 0 && e.tsk_scheduleinitialdatehour.substring(0, 10) === dataIniCampo&& e.tty_id === Number(tty_idCampo)
              );
            }
          );

          setFilterCampo('SIM')

        } else {

          FiltrarCampo = FiltrarCampoFilter.filter(
            (e: any) => {
              return e.tsk_scheduleinitialdatehour.substring(0, 10) >= dataIniCampo &&
                e.tsk_scheduleinitialdatehour.substring(0, 10) <= dataFimCampo && 
                e.tty_id === Number(tty_idCampo)
            }
          );


       
          setFilterCampo('SIM')

        }

      }

     
      const columnsFrom = {
        preparacao: {
          title: 'Em Preparação',
          items: columns.preparacao.items,
        },
        aguardando: {
          title: 'Aguardando Envio',
          items: columns.aguardando.items,
        },
        campo: {
          title: 'Em Campo',
          items: FiltrarCampo,
        },
        alteracao: {
          title: 'Alteração',
          items: columns.alteracao.items,
        }

      }

      setColumns(columnsFrom)

    } else {

      setQualAlert(<div className='div-alert'>
      <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
      <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
      <div className='div-info-alert'><span className="info-alert">Não existe tarefas a serem filtradas.</span></div>
      <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
  </div>)

  setOpenModal(true)

    }
  }

  async function confirmaUpdate(){

    let ret: AxiosResponse;
    setQualAlert(<div className='div-alert'><Loading /></div>)
    setOpenModal(true)


    if(age_id === "" && tea_id === ""){

     setQualAlert(<div className='div-alert'>
     <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
     <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
     <div className='div-info-alert'><span className="info-alert">Selecione quem vai executar a tarefa (Usuário ou Equipe).</span></div>
     <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
 </div>)

 setOpenModal(true)
     
    }else if(tea_id !== "" && tsf_id === ""){
      setQualAlert(<div className='div-alert'>
      <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
      <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
      <div className='div-info-alert'><span className="info-alert">Selecione a forma de execução da equipe.</span></div>
      <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
  </div>)
 
  setOpenModal(true)
     
    
    }else if(stn_id === ""){

      setQualAlert(<div className='div-alert'>
      <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
      <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
      <div className='div-info-alert'><span className="info-alert">Selecione a situação.</span></div>
      <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
  </div>)
 
  setOpenModal(true)

    }else{
      try {
				ret = await api.put("task/taskdirectionupdate", {
					dataTask: columns.alteracao.items,
          age_id:  age_id || null,
          tea_id: tea_id || null,
          tsf_id: tsf_id || null,
          stn_id: stn_id || null
				});

				if (ret.data.status === 200) {
					setQualAlert(<div className='div-alert'>
						<span className="fa-regular fa-circle-check ControleIconAlert"></span>
						<div className='div-info-alert'><span className="info-alert">Tarefas alteradas com sucesso.</span></div>
						<div className='btn-alert' onClick={finalizarModal1}><span className="btn-alert-lab">OK</span></div>
					</div>)

				}
			} catch (e: any) {
				setQualAlert(<div className='div-alert'>
					<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
					<div className='div-info-alert'><span className="info-alert">Não é possível realizar a alteração.</span></div>
					<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
				</div>)
			}
    }

  }

  
	function finalizarModal1() {
		carregar();
	}

	function finalizarModal2() {
		setOpenModal(false)
	}

  return (

    <>
      <Nav />
      <div className="main-content">
        <Header />
        <main>

          <div className='containerPrincipal'>
            <div className='ContainerBody'>
              <div className='div-titulo-pages'>
                <span className='fa fa-arrows-h' />
                <h1 className='div-titulo-pages-text'>Direcionamento Rápido</h1>
              </div>
              <div className='menu-trigger-op' onClick={carregar}>
                <div className='dropOpcao-op'><span className='fa fa-spinner ControleIco'></span> <h1>Carregar</h1></div>
              </div>
            </div>

            <div className='DragDropContext-ini-exibir'>
              <div className='Container-DragDrop-exibir'>

                <div className='TaskList-exibir'>


                  <div className='div-icon-op'>
                    <span className='Title-DragDrop'>Filtrar (Em Preparação)</span>

                    {filterPreparacao === '' ? (
                      <FcFilledFilter className='icon-filter-op' />
                    ) : null}

                    {filterPreparacao !== '' ? (
                      <FcClearFilters className='icon-filter-op' onClick={filtrarPreparacaoLimpar} />
                    ) : null}

                  </div>

                  <div className="select-box-ativos-select-op">
                    <div className="input-box-ativos">
                      <input
                        type="text"
                        autoComplete="off"
                        placeholder="Pesquisar..."
                        onChange={(e) => setDescricaoPreparacao(e.target.value)}
                        value={descricaoPreparacao}
                      />
                    </div>
                  </div>
                  <div className="select-box-ativos-select-op">
                    <Select
                      placeholder={'Tipo de Tarefa...'}
                      options={optionsTasktype}
                      styles={customStyles}
                      maxMenuHeight={100}
                      value={optionsTasktype.filter(function (option: any) {
                        return option.value === tty_idPreparacao;
                      })}
                      onChange={(options: any) =>
                        !options ? incluirIdPreparacao("") : incluirIdPreparacao(options.value)
                      }
                      isClearable
                      noOptionsMessage={() => "Não encontrado..."}
                      theme={(theme: any) => ({
                        ...theme,
                        borderRadius: 4,
                        colors: {
                          ...theme.colors,
                          primary25: '#ebebeb',
                          primary: 'black',
                        },
                      })}
                    />
                  </div>



                  <span className="details-op">Período: </span>

                  <div className="select-box-ativos-select-op">
                    <div className="input-box-ativos">
                      <input
                        type="date"
                        autoComplete="off"
                        placeholder="Descrição..."
                        onChange={(e) => setDataIniPreparacao(e.target.value)}
                        value={dataIniPreparacao}
                      />
                    </div>
                  </div>

                  <div className="select-box-ativos-select-op">
                    <div className="input-box-ativos">
                      <input
                        type="date"
                        autoComplete="off"
                        placeholder="Descrição..."
                        onChange={(e) => setDataFimPreparacao(e.target.value)}
                        value={dataFimPreparacao}
                      />
                    </div>
                  </div>

                  <div className='btn-direcionamento-op' onClick={filtrarPreparacao}>
                    <h1 className='text-direcionamento-op'>Confirmar</h1>
                  </div>


                </div>
                <div className='TaskList-exibir'>
      
                  <div className='div-icon-op'>
                   <span className='Title-DragDrop'>Filtrar (Aguardando Envio)</span>
                    {filterAguardando === '' ? (
                      <FcFilledFilter className='icon-filter-op' />
                    ) : null}

                    {filterAguardando !== '' ? (
                      <FcClearFilters className='icon-filter-op' onClick={filtrarAguardandoLimpar} />
                    ) : null}

                  </div>

                  <div className="select-box-ativos-select-op">
                    <div className="input-box-ativos">
                      <input
                        type="text"
                        autoComplete="off"
                        placeholder="Pesquisar..."
                        onChange={(e) => setDescricaoAguardando(e.target.value)}
                        value={descricaoAguardando}
                      />
                    </div>
                  </div>
                  <div className="select-box-ativos-select-op">
                    <Select
                      placeholder={'Tipo de Tarefa...'}
                      options={optionsTasktype}
                      styles={customStyles}
                      maxMenuHeight={100}
                      value={optionsTasktype.filter(function (option: any) {
                        return option.value === tty_idAguardando;
                      })}
                      onChange={(options: any) =>
                        !options ? incluirIdAguardando("") : incluirIdAguardando(options.value)
                      }
                      isClearable
                      noOptionsMessage={() => "Não encontrado..."}
                      theme={(theme: any) => ({
                        ...theme,
                        borderRadius: 4,
                        colors: {
                          ...theme.colors,
                          primary25: '#ebebeb',
                          primary: 'black',
                        },
                      })}
                    />
                  </div>



                  <span className="details-op">Período: </span>

                  <div className="select-box-ativos-select-op">
                    <div className="input-box-ativos">
                      <input
                        type="date"
                        autoComplete="off"
                        placeholder="Descrição..."
                        onChange={(e) => setDataIniAguardando(e.target.value)}
                        value={dataIniAguardando}
                      />
                    </div>
                  </div>

                  <div className="select-box-ativos-select-op">
                    <div className="input-box-ativos">
                      <input
                        type="date"
                        autoComplete="off"
                        placeholder="Descrição..."
                        onChange={(e) => setDataFimAguardando(e.target.value)}
                        value={dataFimAguardando}
                      />
                    </div>
                  </div>

                  <div className='btn-direcionamento-op' onClick={filtrarAguardando}>
                    <h1 className='text-direcionamento-op'>Confirmar</h1>
                  </div>
                </div>

                <div className='TaskList-exibir'>
                  <div className='div-icon-op'>
                   <span className='Title-DragDrop'>Filtrar (Em Campo)</span>
                    {filterCampo === '' ? (
                      <FcFilledFilter className='icon-filter-op' />
                    ) : null}

                    {filterCampo !== '' ? (
                      <FcClearFilters className='icon-filter-op' onClick={filtrarCampoLimpar} />
                    ) : null}

                  </div>

                  <div className="select-box-ativos-select-op">
                    <div className="input-box-ativos">
                      <input
                        type="text"
                        autoComplete="off"
                        placeholder="Pesquisar..."
                        onChange={(e) => setDescricaoCampo(e.target.value)}
                        value={descricaoCampo}
                      />
                    </div>
                  </div>
                  <div className="select-box-ativos-select-op">
                    <Select
                      placeholder={'Tipo de Tarefa...'}
                      options={optionsTasktype}
                      styles={customStyles}
                      maxMenuHeight={100}
                      value={optionsTasktype.filter(function (option: any) {
                        return option.value === tty_idCampo;
                      })}
                      onChange={(options: any) =>
                        !options ? incluirIdCampo("") : incluirIdCampo(options.value)
                      }
                      isClearable
                      noOptionsMessage={() => "Não encontrado..."}
                      theme={(theme: any) => ({
                        ...theme,
                        borderRadius: 4,
                        colors: {
                          ...theme.colors,
                          primary25: '#ebebeb',
                          primary: 'black',
                        },
                      })}
                    />
                  </div>



                  <span className="details-op">Período: </span>

                  <div className="select-box-ativos-select-op">
                    <div className="input-box-ativos">
                      <input
                        type="date"
                        autoComplete="off"
                        placeholder="Descrição..."
                        onChange={(e) => setDataIniCampo(e.target.value)}
                        value={dataIniCampo}
                      />
                    </div>
                  </div>

                  <div className="select-box-ativos-select-op">
                    <div className="input-box-ativos">
                      <input
                        type="date"
                        autoComplete="off"
                        placeholder="Descrição..."
                        onChange={(e) => setDataFimCampo(e.target.value)}
                        value={dataFimCampo}
                      />
                    </div>
                  </div>

                  <div className='btn-direcionamento-op' onClick={filtrarCampo}>
                    <h1 className='text-direcionamento-op'>Confirmar</h1>
                  </div>

                </div>

                <div className='TaskList-exibir'>
                <div className='div-icon-op'>
                   <span className='Title-DragDrop'>Confirmar Alterações</span>
                 </div>
                 
          


                 <div className="select-box-ativos-select-op-alt">
                    <Select
                      placeholder={'Usuários...'}
                      options={optionsAgent}
                      styles={customStyles}
                      maxMenuHeight={100}
                      value={optionsAgent.filter(function (option: any) {
                        return option.value === age_id;
                      })}
                      onChange={(options: any) =>
                        !options ? SelecionarAgent("") : SelecionarAgent(options.value)
                      }
                      isClearable
                      noOptionsMessage={() => "Não encontrado..."}
                      theme={(theme: any) => ({
                        ...theme,
                        borderRadius: 4,
                        colors: {
                          ...theme.colors,
                          primary25: '#ebebeb',
                          primary: 'black',
                        },
                      })}
                    />
                  </div>
                  <div className="select-box-ativos-select-op-alt">
                    <Select
                      placeholder={'Equipe...'}
                      options={optionsEquipe}
                      styles={customStyles}
                      maxMenuHeight={100}
                      value={optionsEquipe.filter(function (option: any) {
                        return option.value === tea_id;
                      })}
                      onChange={(options: any) =>
                        !options ? SelecionarEquipe("") : SelecionarEquipe(options.value)
                      }
                      isClearable
                      noOptionsMessage={() => "Não encontrado..."}
                      theme={(theme: any) => ({
                        ...theme,
                        borderRadius: 4,
                        colors: {
                          ...theme.colors,
                          primary25: '#ebebeb',
                          primary: 'black',
                        },
                      })}
                    />
                  </div>
                  
                  <div className="select-box-ativos-select-op-alt">
                    <Select
                      placeholder={'Forma...'}
                      options={optionsForma}
                      styles={customStyles}
                      isDisabled={isDisabledForma}
                      maxMenuHeight={100}
                      value={optionsForma.filter(function (option: any) {
                        return option.value === tsf_id;
                      })}
                      onChange={(options: any) =>
                        !options ? setTsf_id("") : setTsf_id(options.value)
                      }
                      isClearable
                      noOptionsMessage={() => "Não encontrado..."}
                      theme={(theme: any) => ({
                        ...theme,
                        borderRadius: 4,
                        colors: {
                          ...theme.colors,
                          primary25: '#ebebeb',
                          primary: 'black',
                        },
                      })}
                    />
                  </div>
                  <div className="select-box-ativos-select-op-alt">
                    <Select
                      placeholder={'Situação...'}
                      options={optionsSituacao}
                      styles={customStyles}
                      maxMenuHeight={80}
                      value={optionsSituacao.filter(function (option: any) {
                        return option.value === stn_id;
                      })}
                      onChange={(options: any) =>
                        !options ? setStn_id("") : setStn_id(options.value)
                      }
                      isClearable
                      noOptionsMessage={() => "Não encontrado..."}
                      theme={(theme: any) => ({
                        ...theme,
                        borderRadius: 4,
                        colors: {
                          ...theme.colors,
                          primary25: '#ebebeb',
                          primary: 'black',
                        },
                      })}
                    />
                  </div>


                  <div className='btn-direcionamento-op-alt'  onClick={confirmaUpdate}>
                    <h1 className='text-direcionamento-op-alt'>Confirmar</h1>
                  </div>


                


                </div>
              </div>
            </div>



            <div className='DragDropContext-ini'>
              <DragDropContext
                onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
              >
                <div className='Container-DragDrop'>
                  <div className='TaskColumnStyles'>

                    {Object.entries(columns).map(([columnId, column], index) => {
                      return (

                        <Droppable key={index} droppableId={columnId}>
                          {(provided, snapshot) => (
                            <div className='TaskList'
                              ref={provided.innerRef}
                              {...provided.droppableProps}
                            >
                              <span className='Title-DragDrop'>{column.title}</span>
                              {column.items.map((item: any, index: any) => (
                                <TaskCard key={String(item.tsk_id)} item={item} index={index}/>
                              ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>



                      );
                    })}
                  </div>
                </div>
              </DragDropContext>

            </div>
          </div>
        </main>
      </div>


    

      <ModalPrincipal isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)}>
                <div className='container-modal-alert'>
                    {qualAlert}
                </div>
            </ModalPrincipal>

    </>
  );
};

export default Kanban;
