import { useState, useEffect } from 'react'
import './index.css'
import { AxiosResponse } from "axios";
import api from '../../Services/api'
import Swal from 'sweetalert2';
import Select from 'react-select'
import { FcSearch, FcPlus, FcFilledFilter } from "react-icons/fc";
import { FaEraser } from "react-icons/fa";

interface IenumServiceGetOrderBy { ok: any, campoId: string; name: string; departamento: string; usuario: string; numeroSerie: string; mac: string; tombamento: string; };

interface EnumServiceItems extends Array<IenumServiceGetOrderBy> { }

const EditableTable = (props: any) => {

  const [employeeData, setEmployeeData] = useState([])
  const [employeeDataFiltrar, setEmployeeDataFiltrar] = useState([])
  const [employeeDataCompleto, setEmployeeDataCompleto] = useState([])

  const [descriptionCat, setDescriptionCat] = useState('')
  const [optionsDepartment, setOptionsDepartment] = useState([])


  const [inputBusca, setInputBusca] = useState('');
  const [optionsAtivo, setOptionsAtivo] = useState([])

  useEffect(() => {
    carregaFiltroprodutoEstoque();
    GetDepartment();
    GetAtivo()
  }, []);

  async function GetAtivo() {
    let ret: AxiosResponse;
    let valor: any;
    let array: any;


    try {
      ret = await api.get("asset/exibir")
      if (ret.data.status === 200) {

        array = ret.data.data;


        var data = array.map((element: any) => { return { ok: false, ...element } });
        const valor: EnumServiceItems =
          data.map((item: any) => {
            return {
              ok: item.ok, campoId: item.ast_id || "", name: item.ast_description || "",
              alternativeIdentifier: item.ast_alternativeidentifier || "",
              departamento: "", usuario: "", numeroSerie: item.ast_serialnumber || "",
              mac: item.ast_mac || "",
              tombamento: item.ast_tombamento || ""
            }
          })

        setOptionsAtivo(valor as any)

      }
    } catch (e) {
      setOptionsAtivo([])
    }
  }

  async function carregaFiltroprodutoEstoque() {
    let ret: AxiosResponse;
    let array: any;


    if (props.tipoMov === 'estoque') {

      try {
        ret = await api.get("asset/filtroprodutoestoque", {
          params: {
            idproduto: props.params1,
            idstatus: '1'
          }
        })
        if (ret.data.status === 200) {
          if (ret.data.data[0].ctg_description) {
            setDescriptionCat('S')
          }
          array = ret.data.data;

          var data = array.map((element: any) => { return { ok: false, ...element } });

          const valor: EnumServiceItems =
            data.map((item: any) => {
              return {
                ok: item.ok, campoId: item.ast_id || "", name: item.ast_description || "",
                alternativeIdentifier: item.ast_alternativeidentifier || "",
                departamento: "", usuario: "", numeroSerie: item.ast_serialnumber || "",
                mac: item.ast_mac || "", tombamento: item.ast_tombamento || ""
              }
            })

          setEmployeeDataCompleto(valor as any)
          setEmployeeDataFiltrar(valor as any)
          setEmployeeData(valor as any)
        }
      } catch (e) {

      }
    } else if (props.tipoMov === 'contrato') {

      try {
        ret = await api.get("asset/filtroprodcontrato", {
          params: {
            unity: props.params2,
            product: props.params1
          }
        })
        if (ret.data.status === 200) {

          if (ret.data.data[0].ctg_description) {
            setDescriptionCat('S')
          }

          array = (ret.data.data);

    

          var data = array.map((element: any) => { return { ok: false, ...element } });
          const valor: EnumServiceItems =
            data.map((item: any) => {
              return {
                ok: item.ok, campoId: item.ast_id || "", name: item.ast_description || "",
                alternativeIdentifier: item.ast_alternativeidentifier || "",
                departamento: "", usuario: "", numeroSerie: item.ast_serialnumber || "",
                mac: item.ast_mac || "",
                tombamento: item.ast_tombamento || ""
              }
            })

          setEmployeeDataCompleto(valor as any)
          setEmployeeData(valor as any)
          setEmployeeDataFiltrar(valor as any)
        }
      } catch (e) {

      }
    }


  }


  const onChangeInput = (e: any, campoId: any) => {

    const { name, value } = e.target

    const editData = employeeData.map((item: any) =>
      item.campoId === campoId && name ? { ...item, [name]: value } : item
    )

    const editData2 = employeeDataCompleto.map((item: any) =>
      item.campoId === campoId && name ? { ...item, [name]: value } : item
    )

    const editData3 = optionsAtivo.map((item: any) =>
      item.campoId === campoId && name ? { ...item, [name]: value } : item
    )

    setEmployeeData(editData as [])

    setEmployeeDataCompleto(editData2 as [])

    setOptionsAtivo(editData3 as [])

    props.dadosMov(editData2, editData3)

  }

  const onChangeSelect = (e: any, campoId: any, name: any) => {

    if (e !== '') {

      const editData = employeeData.map((item: any) =>
        item.campoId === campoId && name ? { ...item, [name]: e.value } : item
      )

      const editData2 = employeeDataCompleto.map((item: any) =>
        item.campoId === campoId && name ? { ...item, [name]: e.value } : item
      )

      const editData3 = optionsAtivo.map((item: any) =>
        item.campoId === campoId && name ? { ...item, [name]: e.value } : item
      )

      setEmployeeData(editData as [])

      setEmployeeDataCompleto(editData2 as [])

      setOptionsAtivo(editData3 as [])

      props.dadosMov(editData2, editData3)

    } else {


      const editData = employeeData.map((item: any) =>
        item.campoId === campoId && name ? { ...item, [name]: '' } : item
      )

      const editData2 = employeeDataCompleto.map((item: any) =>
        item.campoId === campoId && name ? { ...item, [name]: e.value } : item
      )

      const editData3 = optionsAtivo.map((item: any) =>
        item.campoId === campoId && name ? { ...item, [name]: e.value } : item
      )

      setEmployeeData(editData as [])

      setEmployeeDataCompleto(editData2 as [])

      setOptionsAtivo(editData3 as [])

      props.dadosMov(editData2, editData3)

    }

  }

  const handlecheckbox = (e: any, campoId: any) => {
    const { name, value, checked } = e.target


    if (checked) {
      const editData = employeeData.map((item: any) =>
        item.campoId === campoId && name ? { ...item, [name]: true } : item
      )

      const editData2 = employeeDataCompleto.map((item: any) =>
        item.campoId === campoId && name ? { ...item, [name]: true } : item
      )


      const editData3 = optionsAtivo.map((item: any) =>
        item.campoId === campoId && name ? { ...item, [name]: true } : item
      )

      setOptionsAtivo(editData3 as [])

      setEmployeeData(editData as [])

      setEmployeeDataCompleto(editData2 as [])

      setOptionsAtivo(editData3 as [])

      props.dadosMov(editData2, editData3)


    } else {

      const editData = employeeData.map((item: any) =>
        item.campoId === campoId && name ? { ...item, [name]: false } : item
      )

      const editData2 = employeeDataCompleto.map((item: any) =>
        item.campoId === campoId && name ? { ...item, [name]: false } : item
      )

      const editData3 = optionsAtivo.map((item: any) =>
        item.campoId === campoId && name ? { ...item, [name]: false } : item
      )

      setEmployeeData(editData as [])

      setEmployeeDataCompleto(editData2 as [])

      setOptionsAtivo(editData3 as [])

      props.dadosMov(editData2, editData3)

    
    }
  }

  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      background: '#fff',
      minHeight: '30px',
      height: '30px',
      borderColor: state.isFocused ? 'grey' : '#92989e',

    }),


    valueContainer: (provided: any, state: any) => ({
      ...provided,
      height: '30px',
      padding: '0 6px'
    }),

    input: (provided: any, state: any) => ({
      ...provided,
      margin: '0px',
      fontSize: 14,

    }),
    indicatorsContainer: (provided: any, state: any) => ({
      ...provided,
      height: '30px',
    }),
  };

  async function GetDepartment() {
    let ret: AxiosResponse;
    let valor: any;

    try {
      ret = await api.get("local/listarloc", {
        params: {
          id: props.ambiente,
        }
      })
      if (ret.data.status === 200) {

        console.log('o que eu tenho???oi?', props.ambiente)
        valor = ret.data.data;

        const arr = [] as any;
        let result = valor;
        result.map((user: any) => {
          return arr.push({ value: String(user.loc_id), label: user.loc_description });
        });
        setOptionsDepartment(arr)
      }
    } catch (e) {
      setOptionsDepartment([])
    }
  }

  function Buscar() {
    if (inputBusca === "") {
      setEmployeeData(employeeDataCompleto)
    } else {
      const temp_rows = employeeData.filter(
        (e: any) => {
          return (
            JSON.stringify(e)
              .toLowerCase()
              .indexOf(inputBusca.toLowerCase()) >= 0
          );
        }
      );

      setEmployeeData(temp_rows)
    }

  }


  function limparBusca() {
    setInputBusca("")
    setEmployeeData(employeeDataCompleto)
  }

  return (
    <div className="ContainerGrid">

      <h1 className="spa-aten">
        <b> Atenção:</b> Selecione os itens que deseja realizar a movimentação.
      </h1>

      <div className="ContainerBody">
        <div className="InputBusca">
          <input
          autoComplete="off"
            placeholder="Pesquisar..."
            onChange={(e) => setInputBusca(e.target.value)}
            value={inputBusca}
          />

          <div className="icone-buscaT" onClick={Buscar}>
            <FcSearch className="icone-add" />
          </div>

          <div className="icone-buscaT" onClick={limparBusca}>
          <FaEraser className="icone-add"/>
          </div>

        </div>

      </div>


      {employeeData.length !== 0 ? (
        <div className="grid-tab">
          <table>
            <thead>
              <tr id="grid-header">
                <th className="Title">Adicionar</th>
                <th className="Title">{props.title1}</th>
                <th className="Title">{props.title2}</th>
                <th className="Title">{props.title8}</th>
                <th className="Title">{props.title5}</th>
                <th className="Title">{props.title7}</th>
                {descriptionCat === 'S' ? (
                  <th className="Title">{props.title6}</th>
                ) : null}
                <th className="Title">{props.title3}</th>
                <th className="Title">{props.title4}</th>
              </tr>
            </thead>

            <tbody>
              {employeeData.map(({ ok, campoId, name, alternativeIdentifier, departamento, usuario, numeroSerie, mac, tombamento }) => (
                <tr className="TR-Coluna-user" key={campoId}>
                  <td className="ColunaCheck-input">
                    <input
                      type='checkbox'
                      name="ok"
                      checked={ok}
                      onChange={(e) => handlecheckbox(e, campoId)}
                    />

                  </td>

                  <td className="Coluna-GridInputid">
                    {campoId}
                  </td>

                  <td className="Coluna-GridInputdesc">
                    {name}
                  </td>

                  <td className="Coluna-GridInput">
                    <input className="input-grid-input"
                      autoComplete="off"
                      name="alternativeIdentifier"
                      type="text"
                      value={alternativeIdentifier}
                      onChange={(e) => onChangeInput(e, campoId)}
                      placeholder="Identificador Alternativo"
                    />
                  </td>

                  <td className="Coluna-GridInput">
                    <input className="input-grid-input"
                      autoComplete="off"
                      name="numeroSerie"
                      type="text"
                      value={numeroSerie}
                      onChange={(e) => onChangeInput(e, campoId)}
                      placeholder="Número de Série"
                    />
                  </td>

                  <td className="Coluna-GridInput">
                    <input className="input-grid-input"
                      autoComplete="off"
                      name="tombamento"
                      type="text"
                      value={tombamento}
                      onChange={(e) => onChangeInput(e, campoId)}
                      placeholder="Tombamento"
                    />
                  </td>

                  <td className="Coluna-GridInput">
                    <input className="input-grid-input"
                      autoComplete="off"
                      name="mac"
                      type="text"
                      value={mac}
                      onChange={(e) => onChangeInput(e, campoId)}
                      placeholder="MAC"
                    />
                  </td>


                  <td className="Coluna-GridInput">
                    <Select
                      maxMenuHeight={160}
                      className="select-grid-s"
                      name="departamento"
                      placeholder={'Selecione...'}
                      options={optionsDepartment}
                      styles={customStyles}
                      value={optionsDepartment.filter(function (option: any) {
                        return option.value === departamento;
                      })}

                      onChange={(options: any) =>
                        !options ? onChangeSelect("", campoId, "departamento") : onChangeSelect(options, campoId, "departamento")
                      }
                      isClearable
                      noOptionsMessage={() => "Não encontrado..."}
                      theme={(theme: any) => ({
                        ...theme,
                        borderRadius: 4,
                        colors: {
                          ...theme.colors,
                          primary25: '#ebebeb',
                          primary: 'black',
                        },
                      })}
                    />
                  </td>
                  <td className="Coluna-GridInput">
                    <input className="input-grid-input"
                      autoComplete="off"
                      name="usuario"
                      value={usuario}
                      type="text"
                      onChange={(e) => onChangeInput(e, campoId)}
                      placeholder="Usuário"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>


      ) :
        <div className="SemResut">
          <h2>
            Nenhum resultado encontrado.
          </h2>
          <h2>
            Página 1 de 1
          </h2>
        </div>}

    </div>
  )
}

export default EditableTable