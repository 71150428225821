
import React, { useState, useEffect } from 'react'
import Nav from "../../components/Nav";
import Header from "../../components/Header";
import './index.css'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import AtividadesAtivo from '../../components/AtividadesAtivo'
import { AxiosResponse } from "axios";
import api from '../../Services/api'
import GridExibirAlt from '../../components/GridExibirAlt'
import Loading from '../../components/Loading/index'
import QRCode from 'react-qr-code'
import QRCodeLink from 'qrcode'
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router'
import Select from 'react-select'
import ModalIncluirNovo from '../../components/ModalIncluirNovo/modal'
import ModalAlert from '../../components/ModalAlert/modal'
import secureLocalStorage from "react-secure-storage";
import FormTipoServico from '../../components/FormTipoServico'

function PrestadoresVisualizacao() {
  const navigate = useNavigate();
  let { id } = useParams();
  const [openModalIncluirNovo, setOpenModalIncluirNovo] = useState(false)
  const [tabIndex, setTabIndex] = useState(0);
  const [titulo, setTitulo] = useState('');

  const [openModal, setOpenModal] = useState(false)
  const [qualAlert, setQualAlert] = useState(<></>)

  const [svp_corporatenameIni, setSvp_corporatenameIni] = useState('');
	const [svp_cnpjIni, setSvp_cnpjIni] = useState('');
	const [svp_corporatename, setSvp_corporatename] = useState('')
	const [svp_cnpj, setSvp_cnpj] = useState('')
	const [svp_country, setSvp_country] = useState('')
	const [svp_state, setSvp_state] = useState('')
	const [svp_city, setSvp_city] = useState('')
	const [svp_cityneighborhood, setSvp_cityneighborhood] = useState('')
	const [svp_streettype, setSvp_streettype] = useState('')
	const [svp_street, setSvp_street] = useState('')
	const [svp_streetnumber, setSvp_streetnumber] = useState('')
	const [svp_streetcomplement, setSvp_streetcomplement] = useState('')
	const [svp_zipcode, setSvp_zipcode] = useState('')
	const [svp_phoneddd, setSvp_phoneddd] = useState('')
	const [svp_phoneddi, setSvp_phoneddi] = useState('')
	const [svp_phone, setSvp_phone] = useState('')
	const [svp_mobilephoneddd, setSvp_mobilephoneddd] = useState('')
	const [svp_mobilephoneddi, setSvp_mobilephoneddi] = useState('')
	const [svp_mobilephone, setSvp_mobilephone] = useState('')
	const [svp_email, setSvp_email] = useState('')
	const [svp_observation, setSvp_observation] = useState('')
	const [svp_contracttime, setSvp_contracttime] = useState('')
	const [datetimeinsert, setDatetimeinsert] = useState('')
	const [datetimeupdate, setDatetimeupdate] = useState('')
  const [qualGridTipoServico, setQualGridTipoServico] = useState(<></>)
  const [qForm, setQForm] = useState(<></>)

  useEffect(() => {

    setQualAlert(<div className='div-alert'><Loading /></div>)
    setOpenModal(true)

    validatToken();

  }, []);

  async function validatToken() {

    let ret: AxiosResponse;
    let valor: any;

    const _user = secureLocalStorage.getItem('t')

    api.defaults.headers.common['Authorization'] = _user as ''

    try {

      ret = await api.get("/status/validar")

      if (ret.data.status === 200) {

        secureLocalStorage.setItem('t', ret.data.data);
        secureLocalStorage.setItem('acp', ret.data.decodedToken.acp_integrationid);
        secureLocalStorage.setItem('n', ret.data.decodedToken.name);
        secureLocalStorage.setItem('iag', ret.data.decodedToken.id);
        secureLocalStorage.setItem('cy', ret.data.decodedToken.company);



        if (ret.data.decodedToken.acp_integrationid === 'DESENVOLVEDOR' || ret.data.decodedToken.acp_integrationid === 'ADMINISTRADOR') {



        } else if (ret.data.decodedToken.acp_integrationid === 'CONSULTOR') {


        }

        await inventoryditar('')

      }

    } catch (e: any) {

      if (e.response.data.status === 403) {

        setQualAlert(<div className='div-alert'>
          <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
          <div className='btn-alert' onClick={fecharModalToken}><span className="btn-alert-lab">OK</span></div>
        </div>)


      }
    }

  }

  function fecharModalToken() {
    secureLocalStorage.removeItem('t')
    secureLocalStorage.removeItem('acp')
    secureLocalStorage.removeItem('n')
    secureLocalStorage.removeItem('iag')
    navigate('/login');
    setOpenModal(false)
  }

  async function inventoryditar(tab: any) {


    let ret: AxiosResponse;
    let valor: any;

    if (/^\d+$/.test(id || '') === true) {

      try {
        ret = await api.get("serviceproviders/serviceproviderseditar", {
          params: {
            id: id
          }
        })
        if (ret.data.status === 200) {

          valor = ret.data.data[0];

          setSvp_corporatenameIni(ret.data.data[0].svp_corporatename || '');
					setSvp_cnpjIni(ret.data.data[0].svp_cnpj || '');
					setSvp_corporatename(ret.data.data[0].svp_corporatename || '')
					setSvp_cnpj(ret.data.data[0].svp_cnpj || '')
					setSvp_country(ret.data.data[0].svp_country || '')
					setSvp_state(ret.data.data[0].svp_state || '')
					setSvp_city(ret.data.data[0].svp_city || '')
					setSvp_cityneighborhood(ret.data.data[0].svp_cityneighborhood || '')
					setSvp_streettype(ret.data.data[0].svp_streettype || '')
					setSvp_street(ret.data.data[0].svp_street || '')
					setSvp_streetnumber(ret.data.data[0].svp_streetnumber || '')
					setSvp_streetcomplement(ret.data.data[0].svp_streetcomplement || '')
					setSvp_zipcode(ret.data.data[0].svp_zipcode || '')
					setSvp_phoneddd(ret.data.data[0].svp_phoneddd || '')
					setSvp_phoneddi(ret.data.data[0].svp_phoneddi || '')
					setSvp_phone(ret.data.data[0].svp_phone || '')
					setSvp_mobilephoneddd(ret.data.data[0].svp_mobilephoneddd || '')
					setSvp_mobilephoneddi(ret.data.data[0].svp_mobilephoneddi || '')
					setSvp_mobilephone(ret.data.data[0].svp_mobilephone || '')
					setSvp_email(ret.data.data[0].svp_email || '')
					setSvp_observation(ret.data.data[0].svp_observation || '')
					setDatetimeinsert(ret.data.data[0].svp_datetimeinsert?.substring(0, 16) || '')
					setDatetimeupdate(ret.data.data[0].svp_datetimeinsert?.substring(0, 16) || '')
         
          if (valor.svp_phoneddd !== null) {
            if (valor.svp_phone !== null) {
              setSvp_phone("(" + valor.svp_phoneddd + ") " + valor.svp_phone)
            } 
          }else{
            if (valor.svp_phone !== null) {
              setSvp_phone(valor.svp_phone)
            } 
          }

          if (valor.svp_mobilephoneddd !== null) {
            if (valor.svp_mobilephone !== null) {
              setSvp_mobilephone("(" + valor.svp_mobilephoneddd + ") " + valor.svp_mobilephone)
            }
          }else{
            if (valor.svp_mobilephone !== null) {
              setSvp_mobilephone(valor.svp_mobilephone)
            }
          }

          if (sessionStorage.getItem("QUALTAB") === '0' || sessionStorage.getItem("QUALTAB") === null || sessionStorage.getItem("QUALTAB") === undefined || sessionStorage.getItem("QUALTAB") === '') {
            setTabIndex(0)
          } else
            if (sessionStorage.getItem("QUALTAB") === '1') {
              setTabIndex(1)
              ChamaGridTipoServico()
            }

        } else {
          navigate('/serviceproviders/visualizar/' + id + '/n');
        }
      } catch (e) {
        navigate('/serviceproviders/visualizar/' + id + '/n');
      }

    } else {
      navigate('/serviceproviders/visualizar/' + id + '/n');
    }

    setOpenModal(false)

  }


  function ChamaResumo() {
    setTabIndex(0)
  }

  function addtipo(){
    if(secureLocalStorage.getItem('acp') !== 'CONSULTOR'){
      setTitulo('Adicionar Tipo de Serviço');   
      setQForm(<FormTipoServico voltar={fecharOpenModalIncluirNovo} id={id} salvarTipo={salvarTipo} obj={[]}/>)
      setOpenModalIncluirNovo(true)
      }
  }

  async  function ChamaGridTipoServico() {

    setQualGridTipoServico(<div className='div-alert'><Loading /></div>)
    
    setTabIndex(1)
  
      let ret: AxiosResponse;
  
      try {
        ret = await api.get("typeserviceproviders/listar", {
          params: {
            id: id
          }
        })
        if (ret.data.status === 200) {
          setQualGridTipoServico(<><GridExibirAlt
            Iconeadd={addtipo}
            Iconeditar={Editartipo} 
            th1='Descrição'
            th2='Identificador Alternativo'
            th3='ID'
            endPoint='typeserviceproviders/listar'
            consult='S'
            col1={0}
            col2={3}
            col3={2}
            col4={0}
            ambiente={id}
            conc='S'
            acao='S'
            del='S'
          /></>)
  
        } else {
  
          setQualGridTipoServico(<div className='panel-content-visu-aviso'>
          <h1 className='panel-content-aviso'>Não há tipo de serviços disponíveis.</h1>
          <h1 className='panel-content-aviso-add' onClick={addtipo}>Adicionar Serviço</h1>
        </div>)
  
        }
      } catch (e) {
        
        setQualGridTipoServico(<div className='panel-content-visu-aviso'>
        <h1 className='panel-content-aviso'>Não há tipo de serviços disponíveis.</h1>
        <h1 className='panel-content-aviso-add' onClick={addtipo}>Adicionar Serviço</h1>
      </div>)
  
      }
    }

    function salvarTipo(){
      sessionStorage.setItem("QUALTAB", '1');
      window.location.reload();
      setOpenModal(false)
    }

    function fecharOpenModalIncluirNovo() {
      setOpenModalIncluirNovo(false)
    }

    function Editartipo(obj: any){
  
      setTitulo('Editar Tipo de Serviço');   
      setQForm(<FormTipoServico 
        voltar={fecharOpenModalIncluirNovo} 
        id={id} 
        salvarTipo={salvarTipo}
        obj={obj}
        />)
      setOpenModalIncluirNovo(true)
    }

  return (
    <>
      <Nav />
      <div className="main-content">
        <Header />
        <main>
          <div className='containerPrincipal'>

            <h1 className='titulo-card-visualizacao'>{svp_corporatename}</h1>



            <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>

              <div className="container-cad-prod28">
                <div id="content1">
                  <TabList>
                    <Tab onClick={ChamaResumo} >
                      <p>Resumo</p>
                    </Tab>
                    <Tab onClick={ChamaGridTipoServico}>
                      <p>Tipo de Serviços</p>
                    </Tab>
                   
                  </TabList>
                </div>



                <div id="content2">
                  <TabPanel>
                    <div className="panel-content">

                      <div className='panel-content-visu'>
                        <h2>ID: </h2>
                        <h2 className='resumo-bd'>{id}</h2>
                      </div>
                      <div className='panel-content-visu'>
                        <h2>CNPJ: </h2>
                        <h2 className='resumo-bd'>{svp_cnpj}</h2>
                      </div>
                      <div className='panel-content-visu'>
                        <h2>E-mail: </h2>
                        <h2 className='resumo-bd'>{svp_email}</h2>
                      </div>
                      <div className='panel-content-visu'>
                        <h2>Celular: </h2>
                        <h2 className='resumo-bd'>{svp_mobilephone}</h2>
                      </div>
                      <div className='panel-content-visu'>
                        <h2>Telefone: </h2>
                        <h2 className='resumo-bd'>{svp_phone}</h2>
                      </div>
                      <div className='panel-content-visu'>
                        <h2>País: </h2>
                        <h2 className='resumo-bd'>{svp_country}</h2>
                      </div>
                      <div className='panel-content-visu'>
                        <h2>Estado: </h2>
                        <h2 className='resumo-bd'>{svp_state}</h2>
                      </div>
                      <div className='panel-content-visu'>
                        <h2>Município: </h2>
                        <h2 className='resumo-bd'>{svp_city}</h2>
                      </div>
                      <div className='panel-content-visu'>
                        <h2>Bairro: </h2>
                        <h2 className='resumo-bd'>{svp_cityneighborhood}</h2>
                      </div>
                      <div className='panel-content-visu'>
                        <h2>Tipo logradouro: </h2>
                        <h2 className='resumo-bd'>{svp_streettype}</h2>
                      </div>
                      <div className='panel-content-visu'>
                        <h2>Logradouro: </h2>
                        <h2 className='resumo-bd'>{svp_street}</h2>
                      </div>
                      <div className='panel-content-visu'>
                        <h2>Número: </h2>
                        <h2 className='resumo-bd'>{svp_streetnumber}</h2>
                      </div>
                      <div className='panel-content-visu'>
                        <h2>Complemento: </h2>
                        <h2 className='resumo-bd'>{svp_streetcomplement}</h2>
                      </div>
                      <div className='panel-content-visu'>
                        <h2>CEP: </h2>
                        <h2 className='resumo-bd'>{svp_zipcode}</h2>
                      </div>
                      
                    </div>
                  </TabPanel>
                  <TabPanel>

                    {qualGridTipoServico}

                  </TabPanel>
                  
                </div>
              </div>
            </Tabs>



          </div>
        </main>
      </div>

      <ModalIncluirNovo isOpen={openModalIncluirNovo} setModalOpen={() => setOpenModalIncluirNovo(!openModalIncluirNovo)}>
        <div className='title-modal-incluir'>
          <h1 className="modal-IncluirNovo-title">{titulo}</h1>
          <div onClick={fecharOpenModalIncluirNovo}>
          <button className="fa fa-times icone-modal-IncluirNovo" data-dismiss="modal" aria-label="Close"></button>
          </div>
        </div>
        <div>
        <div className='scrol-modal'>
          {qForm}
          </div>
            
          </div>
      </ModalIncluirNovo>



      <ModalAlert isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)}>
        <div className='container-modal-alert'>
          {qualAlert}
        </div>
      </ModalAlert>

    </>
  );

}

export default PrestadoresVisualizacao;