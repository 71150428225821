import React, { useRef, useState, useEffect } from 'react'
import Nav from "../../components/Nav";
import Header from "../../components/Header";
import { AxiosResponse } from "axios";
import api from '../../Services/api'
import './index.css'
import Select from 'react-select'
import ListagemChamado from '../../components/ListagemChamados';
import Loading from '../../components/Loading'
import DetalhamentoTicket from '../../components/detalhamentoTicket'
import "./index.css";
import { FcSearch } from "react-icons/fc";
import { FcPlus } from "react-icons/fc";
import ModalPrincipal from '../../components/ModalAlert/modal'
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from 'react-router';
import ModalIncluirNovo from '../../components/ModalIncluirNovo/modal'

const optionTipo = [{
    value: '2',
    label: 'Chamado',
}, {
    value: '3',
    label: 'Movimentação',
}]

function Tickets() {

    const navigate = useNavigate();
    const [toggleState, setToggleState] = useState(1);

    const [unity, setUnity] = useState('');
    const [unityDest, setUnityDest] = useState('');
    const [contract, setContract] = useState('');
    const [contractDest, setContractDest] = useState('');
    const [responsavel, setResponsavel] = useState('');
    const [telefone, setTelefone] = useState('');
    const [product, setProduct] = useState('');
    const [mensagem, setMensagem] = useState('');
    const [tipo, setTipo] = useState('');
    const [user, setUser] = useState('');

    const [optionContract, setOptionContract] = useState([])
    const [optionUnityDest, setOptionUnityDest] = useState([])
    const [optionspavementMov, setOptionspavementMov] = useState([])
    const [optionsDepartmentMov, setOptionsDepartmentMov] = useState([])

    const [pavementMov, setPavementMov] = useState('');
    const [departmentMov, setDepartmentMov] = useState('');

    const [optionUnity, setOptionUnity] = useState([])
    const [optionProduct, setOptionProduct] = useState([])

    const [chamadosAbertos, setChamadosAbertos] = useState(<></>)
    const [chamadosFinalizados, setChamadosFinalizados] = useState(<></>)

    const [exibirNovoChamado, setExibirNovoChamado] = useState('');
    const [exibirDetalhamento, setExibirDetalhamento] = useState('');
    const [formDetalhamento, setFormDetalhamento] = useState(<></>)
    const [titulo, setTitulo] = useState('')

    const [optionsDepartment, setOptionsDepartment] = useState([])
    const [optionspavement, setOptionspavement] = useState([])
    const [pavement, setPavement] = useState('');
    const [department, setDepartment] = useState('');

    const [dadosAtivos, setDadosAtivos] = useState([])

    const [openModal, setOpenModal] = useState(false)
    const [qualAlert, setQualAlert] = useState(<></>)
    const [openModalIncluirNovo, setOpenModalIncluirNovo] = useState(false)


    const [optionChamadosAbertos, setOptionChamadosAbertos] = useState([])
    const [optionChamadosFinalizados, setOptionChamadosFinalizados] = useState([])
    const [inputBusca, setInputBusca] = useState('');


    const [entidade, setEntidade] = useState('');

    useEffect(() => {

        setQualAlert(<div className='div-alert'><Loading /></div>)
        setOpenModal(true)

        validatToken();
    }, []);


    async function validatToken() {

        let ret: AxiosResponse;
        let valor: any;

        const _user = secureLocalStorage.getItem('t')

        api.defaults.headers.common['Authorization'] = _user as ''

        try {

            ret = await api.get("/status/validar")

            if (ret.data.status === 200) {

                secureLocalStorage.setItem('t', ret.data.data);
                secureLocalStorage.setItem('acp', ret.data.decodedToken.acp_integrationid);
                secureLocalStorage.setItem('n', ret.data.decodedToken.name);
                secureLocalStorage.setItem('iag', ret.data.decodedToken.id);
                secureLocalStorage.setItem('cy', ret.data.decodedToken.company);

                GetContrato();
                listarTicketAbertos();
                setInputBusca("")
                setOpenModal(false)
            }

        } catch (e: any) {

            if (e.response.data.status === 403) {

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
                    <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)

            }
        }

    }

    function fecharModal2() {
        setOpenModal(false)
    }

    function fecharModal() {

        secureLocalStorage.removeItem('t')
        secureLocalStorage.removeItem('acp')
        secureLocalStorage.removeItem('n')
        secureLocalStorage.removeItem('iag')
        navigate('/login');
        setOpenModal(false)

    }

    function fecharOpenModalIncluirNovo() {
        setOpenModalIncluirNovo(false)
    }

    function detalhamentoTicket(data: any) {
        setFormDetalhamento(<DetalhamentoTicket data={data} listarTicketAbertos={listarTicketAbertos} fecharOpenModalIncluirNovo={fecharOpenModalIncluirNovo} />)
        setExibirNovoChamado('N')
        setTitulo('Resumo')
        setExibirDetalhamento('S')
        setOpenModalIncluirNovo(true)
    }

    async function listarTicketAbertos() {
        setChamadosAbertos(<Loading />)
        let ret: AxiosResponse;
        let valor: any;

        try {
            ret = await api.get("ticket/listarticket", {
                params: {
                    age_id: secureLocalStorage.getItem('iag'),
                }
            })
            if (ret.data.status === 200) {
                valor = ret.data.data;

                if (valor.length !== 0) {

                    setOptionChamadosAbertos(valor)

                    setChamadosAbertos(<ListagemChamado
                        data={valor}
                        cor={'#3CB371'}
                        func={detalhamentoTicket}
                    />)

                } else {
                    setChamadosAbertos(<h1 className='ticket-exibir'>Sem chamados a serem exibidos...</h1>)
                }
                setToggleState(1)
            } else {
                setChamadosAbertos(<h1 className='ticket-exibir'>Sem chamados a serem exibidos...</h1>)
                setToggleState(1)
            }
        } catch (e) {
            setChamadosAbertos(<h1 className='ticket-exibir'>Sem chamados a serem exibidos...</h1>)
            setToggleState(1)
        }
    }

    function buscarChamadosAbertos() {

        if (toggleState === 1) {

            if (inputBusca === "") {

                setInputBusca("")

            } else {

                const temp_rows = optionChamadosAbertos.filter(
                    (e: any) => {
                        return (
                            JSON.stringify(e)
                                .toLowerCase()
                                .indexOf(inputBusca.toLowerCase()) >= 0
                        );
                    }
                );

                if (temp_rows.length > 0) {

                    setChamadosAbertos(<ListagemChamado
                        data={temp_rows}
                        cor={'#3CB371'}
                        func={detalhamentoTicket}
                    />)

                } else {

                    setChamadosAbertos(<h1 className='ticket-exibir'>Sem chamados a serem exibidos...</h1>)
                
                }

            }

        } else if (toggleState === 2) {

            if (inputBusca === "") {

                setInputBusca("")

            } else {

                const temp_rows = optionChamadosFinalizados.filter(

                    (e: any) => {
                        return (
                            JSON.stringify(e)
                                .toLowerCase()
                                .indexOf(inputBusca.toLowerCase()) >= 0
                        );
                    }
                    
                );

                if (temp_rows.length > 0) {

                    setChamadosFinalizados(<ListagemChamado
                        data={temp_rows}
                        cor={'#3CB371'}
                        func={detalhamentoTicket}
                    />)

                } else {

                    setChamadosFinalizados(<h1 className='ticket-exibir'>Sem chamados a serem exibidos...</h1>)
               
                }

            }
        }
    }

    function limparchamados() {

        if (toggleState === 1) {
            setInputBusca("")

            setChamadosAbertos(<ListagemChamado
                data={optionChamadosAbertos}
                cor={'#3CB371'}
                func={detalhamentoTicket}
            />)
        } else if (toggleState === 2) {
            setInputBusca("")

            setChamadosFinalizados(<ListagemChamado
                data={optionChamadosFinalizados}
                cor={'#3CB371'}
                func={detalhamentoTicket}
            />)
        }
    }

    async function listarTicketFechado() {
        setChamadosAbertos(<Loading />)
        let ret: AxiosResponse;
        let valor: any;

        try {
            ret = await api.get("ticket/listarticketfechados", {
                params: {
                    age_id: secureLocalStorage.getItem('iag'),
                }
            })
            if (ret.data.status === 200) {
                valor = ret.data.data;

                if (valor.length !== 0) {

                    setOptionChamadosFinalizados(valor)

                    setChamadosFinalizados(<ListagemChamado
                        data={valor}
                        cor={'#676666'}
                        func={detalhamentoTicket}
                    />)
                } else {
                    setChamadosFinalizados(<h1 className='ticket-exibir'>Sem chamados finalizados a serem exibidos...</h1>)
                }
                setToggleState(2)
            } else {
                setChamadosFinalizados(<h1 className='ticket-exibir'>Sem chamados finalizados a serem exibidos...</h1>)
                setToggleState(2)
            }
        } catch (e) {
            setChamadosFinalizados(<h1 className='ticket-exibir'>Sem chamados finalizados a serem exibidos...</h1>)
            setToggleState(2)
        }
    }

    function novoChamado() {
     


        setQualAlert(<div className='div-alert'>
        <span className="far fa-question-circle ControleIconAlertquestion"></span>
        <div className='div-info-alert-atencao'>
            <span className="info-alert-atencao">ATENÇÃO</span></div>
        <div className='div-info-alert'>
            <span className="info-alert">
                Para qual tipo você deseja criar uma tarefa?</span></div>
        <div className='btn-flex'>
            <div className='btn-alert-f' onClick={chamarLocal}>
                <span className="btn-alert-lab-flex">LOCAL</span>
            </div>

            <div className='btn-alert-f' onClick={chamarAtivo}>
                <span className="btn-alert-lab-flex">ATIVO</span>
            </div>
        </div>
    </div>)

    setOpenModal(true)
    }

    function chamarAtivo() {
        setEntidade('2')
        setOpenModal(false)
       setPavement("")
        setDepartment("")
        setUnityDest("")
        setContractDest("")
        setTipo("")
        setProduct("")
        setTelefone("")
        setResponsavel("")
        setUnity("")
        setContract("")
        setUnityDest("")
        setContractDest('')
        setDepartmentMov('');
        setOptionUnityDest([])
        setOptionspavementMov([])
        setOptionsDepartmentMov([])
        setOptionUnity([])
        setOptionProduct([])
        setDadosAtivos([])
        setOptionspavement([])
        setTitulo('Novo Ticket')
        setExibirNovoChamado('S')
        setExibirDetalhamento('N')
        setOpenModalIncluirNovo(true)
        
    }

  async function chamarLocal() {
    setOpenModal(false)
    setEntidade('1')
    setPavement("")
    setDepartment("")
    setUnityDest("")
    setContractDest("")
    setTipo("2")
    setProduct("")
    setTelefone("")
    setResponsavel("")
    setUnity("")
    setContract("")
    setUnityDest("")
    setContractDest('')
    setDepartmentMov('');
    setOptionUnityDest([])
    setOptionspavementMov([])
    setOptionsDepartmentMov([])
    setOptionUnity([])
    setOptionProduct([])
    setDadosAtivos([])
    setOptionspavement([])
    setTitulo('Novo Ticket')
    setExibirNovoChamado('S')
    setExibirDetalhamento('N')
    setOpenModalIncluirNovo(true)
   
    
    }

    function Cancelar() {
        setUnity('');
        setContract('');
        setResponsavel('');
        setTelefone('');
        setProduct('');
        setMensagem('');
        setMensagem('');
        setOpenModalIncluirNovo(false)

    }
    async function GetContrato() {
        let ret: AxiosResponse;
        let valor: any;
        try {
            ret = await api.get("contract/listar")
            if (ret.data.status === 200) {
                valor = ret.data.data;

                const arr = [] as any;
                let result = valor;
                result.map((user: any) => {
                    return arr.push({ value: String(user.ctc_id + '_' + user.ctc_description), label: user.ctc_description });
                });
                setOptionContract(arr)
            }
        } catch (e) {
            setOptionContract([])
        }
    }

    const customStyles = {
        control: (provided: any, state: any) => ({
            ...provided,
            background: '#fff',
            minHeight: '30px',
            height: '30px',
            borderColor: state.isFocused ? 'grey' : '#92989e',

        }),


        valueContainer: (provided: any, state: any) => ({
            ...provided,
            height: '30px',
            padding: '0 6px'
        }),

        input: (provided: any, state: any) => ({
            ...provided,
            margin: '0px',
            fontSize: 14,

        }),
        indicatorsContainer: (provided: any, state: any) => ({
            ...provided,
            height: '30px',
        }),
    };

    function confirmar() {

        setQualAlert(<div className='div-alert'><Loading /></div>)
        setOpenModal(true)

        if (entidade === '2' && product === "") {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Selecione o ativo que deseja abrir um ticket.</span></div>
                <div className='btn-alert' onClick={fecharModal2}><span className="btn-alert-lab">OK</span></div>
            </div>)

        } else if (entidade === '2' && tipo === "") {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Selecione o tipo de ticket.</span></div>
                <div className='btn-alert' onClick={fecharModal2}><span className="btn-alert-lab">OK</span></div>
            </div>)

        } else if (tipo === "3" && departmentMov === "") {


            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Selecione o destino da movimentação.</span></div>
                <div className='btn-alert' onClick={fecharModal2}><span className="btn-alert-lab">OK</span></div>
            </div>)

        } else if (entidade === "1" && departmentMov === "") {


            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Selecione o local que deseja abrir um ticket.</span></div>
                <div className='btn-alert' onClick={fecharModal2}><span className="btn-alert-lab">OK</span></div>
            </div>)

        }
        else if (tipo === "2" && mensagem === '') {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Digite o problema.</span></div>
                <div className='btn-alert' onClick={fecharModal2}><span className="btn-alert-lab">OK</span></div>
            </div>)


        } else {
            abrirChamado()
        }
    }

    async function abrirChamado() {
        let ret: AxiosResponse;

        let destino: any

        if (departmentMov === "") {
            destino = undefined
        } else {
            destino = departmentMov
        }

        const dados = {
            ctc_id: contractDest || null,
            unt_id: unityDest || null,
            tck_message: mensagem,
            age_id: secureLocalStorage.getItem('iag'),
            tck_requester: responsavel,
            tck_responsible: secureLocalStorage.getItem('n'),
            tck_telephone: telefone,
            stn_id: 20,
            ast_id: parseInt(product.split('_')[0]),
            tty_id: tipo,
            loc_id: departmentMov || null,
            tsk_userdestination: user || null,
            ett_id: entidade
        }

        try {
            ret = await api.post("ticket/insert", {
                data: dados,
            });

            if (ret.data.status === 200) {



                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-check ControleIconAlert"></span>
                    <div className='div-info-alert'><span className="info-alert">Chamado aberto com sucesso.</span></div>
                    <div className='btn-alert' onClick={fecharModal3}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setUnity('');
                setContract('');
                setResponsavel('');
                setTelefone('');
                setProduct('');
                setMensagem('');
                setUser('');
                setPavement("");
                setPavementMov("");
                setOpenModalIncluirNovo(true)
                listarTicketAbertos();


            }
        } catch (e: any) {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Não foi possível concluir o cadastramento.</span></div>
                <div className='btn-alert' onClick={fecharModal2}><span className="btn-alert-lab">OK</span></div>
            </div>)

            setOpenModal(true)

        }

    }

    function fecharModal3() {
        setOpenModal(false)
        setOpenModalIncluirNovo(false)
    }

    function IncluirContrato(Contrato: any) {

        setUnity('')
        if (Contrato === '' || Contrato === 'Selecione...') {
            setContract("")
            setDepartment("")
            setOptionsDepartment([])
            setPavement("")
            setOptionspavement([])
            setUnity("")
            setOptionUnity([])
            setProduct("")
            setOptionProduct([])
        } else {
            setDepartment("")
            setOptionsDepartment([])
            setPavement("")
            setOptionspavement([])
            setUnity("")
            setOptionUnity([])
            setProduct("")
            setOptionProduct([])
            GetUnidadeSel(Contrato.split('_')[0])
            setContract(Contrato)
        }
    }

    async function GetUnidadeSel(id: any) {
        let ret: AxiosResponse;
        let valor: any;

        if (id === '' || id === 'Selecione...' || id === 'undefined' || id === undefined) {
            setUnity('')
            setOptionUnity([])

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Selecione o contrato.</span></div>
                <div className='btn-alert' onClick={fecharModal2}><span className="btn-alert-lab">OK</span></div>
            </div>)

            setOpenModal(true)

        } else {
            try {
                ret = await api.get("unity/listar", {
                    params: {
                        id: id
                    }
                })
                if (ret.data.status === 200) {
                    valor = ret.data.data;


                    const arr = [] as any;
                    let result = valor;
                    result.map((user: any) => {
                        return arr.push({ value: String(user.unt_id + "_" + user.unt_description), label: user.unt_description });
                    });
                    setOptionUnity(arr)

                }
            } catch (e) {
                setOptionUnity([]);
            }
        }

    }

    function

        IncluirUnity(Unidade: any) {

        if (Unidade === '' || Unidade === 'Selecione...') {
            setDepartment("")
            setOptionsDepartment([])
            setPavement("")
            setOptionspavement([])
            setUnity("")
            setProduct("")
            setOptionProduct([])
        } else {
            setDepartment("")
            setOptionsDepartment([])
            setPavement("")
            setOptionspavement([])
            setProduct("")
            setOptionProduct([])
            listarAtivosUnidade(Unidade.split('_')[0])
            GetDepartment(Unidade.split('_')[0])
            setUnity(Unidade)
        }
    }

    async function GetDepartment(unidade: any) {
        let ret: AxiosResponse;
        let valor: any;

        try {
            ret = await api.get("local/listar", {
                params: {
                    id: unidade
                }
            })
            if (ret.data.status === 200) {
                valor = ret.data.data;

                const arr = [] as any;
                let result = valor;
                result.map((user: any) => {
                    return arr.push({ value: String(user.loc_pavement), label: user.loc_pavement });
                });

                setOptionspavement(arr)

            }
        } catch (e) {
            setOptionsDepartment([])
        }
    }

    async function listarAtivosUnidade(id: any) {
        let ret: AxiosResponse;
        let valor: any;

        if (id === '' || id === 'Selecione...' || id === 'undefined' || id === undefined) {
            setUnity('')
            setOptionUnity([])

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Selecione o contrato.</span></div>
                <div className='btn-alert' onClick={fecharModal2}><span className="btn-alert-lab">OK</span></div>
            </div>)

            setOpenModal(true)


        } else {
            try {
                ret = await api.get("ticket/listarativosunidade", {
                    params: {
                        id: id
                    }
                })
                if (ret.data.status === 200) {
                    valor = ret.data.data;

                    setDadosAtivos(valor)
                    const arr = [] as any;
                    let result = valor;
                    result.map((user: any) => {
                        return arr.push({ value: String(user.ast_id + "_" + user.ast_description), label: user.ast_alternativeidentifier ? user.ast_description + " (" + user.ast_alternativeidentifier + ")" : user.ast_description });
                    });
                    setOptionProduct(arr)

                }
            } catch (e) {
                setOptionUnity([]);
            }
        }

    }

    function incluirTipo(tipo: any) {
        if (tipo === "") {
            setTipo("")
            setUnityDest("")
            setOptionUnityDest([])
            setContractDest('')
            setOptionspavementMov([])
            setPavementMov('');
            setOptionsDepartmentMov([])
            setDepartmentMov('');
        } else {
            setTipo(tipo)

        }
    }

    function IncluirLocais(locais: any) {

        if (locais === '') {
            setDepartment("")
            setOptionsDepartment([])
            setPavement("")
            setProduct("")
            setOptionProduct([])
            listarAtivosUnidade(unity.split('_')[0])

        } else {

            setProduct("")
            setOptionProduct([])
            setDepartment("")
            setPavement(locais)
            GetDepartmentPaviment(locais)

        }

    }

    async function GetDepartmentPaviment(pavimento: any) {
        let ret: AxiosResponse;
        let valor: any;

        try {
            ret = await api.get("local/listarlocpaviment", {
                params: {
                    id: unity.split('_')[0],
                    paviment: pavimento
                }
            })
            if (ret.data.status === 200) {
                valor = ret.data.data;

                const arr = [] as any;
                let result = valor;
                result.map((user: any) => {
                    return arr.push({ value: String(user.loc_id), label: user.loc_description });
                });
                setOptionsDepartment(arr)
            }
        } catch (e) {
            setOptionsDepartment([])
        }
    }

    function incluirDepartamento(id: any) {

        if (id === "") {
            setDepartment("")
            setProduct("")
            setOptionProduct([])
            listarAtivosUnidade(unity.split('_')[0])

        } else {

            setProduct("")
            setOptionProduct([])
            setDepartment(id)
            listarListarativodepart(id)
        }
    }

    async function listarListarativodepart(id: any) {
        let ret: AxiosResponse;
        let valor: any;

        try {
            ret = await api.get("local/listarativoloc", {
                params: {
                    id: id
                }
            })
            if (ret.data.status === 200) {
                valor = ret.data.data;


                const arr = [] as any;
                let result = valor;
                result.map((user: any) => {
                    return arr.push({ value: String(user.ast_id + "_" + user.ast_description), label: user.ast_alternativeidentifier ? user.ast_description + " (" + user.ast_alternativeidentifier + ")" : user.ast_description });
                });
                setOptionProduct(arr)

            }
        } catch (e) {
            setOptionUnity([]);
        }

    }


    function IncluirContratoDest(Contrato: any) {


        if (Contrato === '') {
            setUnityDest("")
            setPavementMov('');
            setDepartmentMov('');
            setContractDest('')
            setUser("");

            setOptionspavementMov([])
            setOptionUnityDest([])
            setOptionsDepartmentMov([])

        } else {
            setUnityDest("")
            setPavementMov('');
            setDepartmentMov('');
            setUser("");
            setOptionspavementMov([])
            setOptionsDepartmentMov([])
            GetUnidadeSelDest(Contrato.split('_')[0])
            setContractDest(Contrato)

        }
    }

    async function GetUnidadeSelDest(id: any) {
        let ret: AxiosResponse;
        let valor: any;


        if (id === '' || id === 'Selecione...' || id === 'undefined' || id === undefined) {
            setUnityDest('')
            setOptionUnityDest([])

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Selecione o contrato de destino.</span></div>
                <div className='btn-alert' onClick={fecharModal2}><span className="btn-alert-lab">OK</span></div>
            </div>)

            setOpenModal(true)

        } else {
            try {
                ret = await api.get("unity/listar", {
                    params: {
                        id: id
                    }
                })
                if (ret.data.status === 200) {
                    valor = ret.data.data;


                    const arr = [] as any;
                    let result = valor;
                    result.map((user: any) => {
                        return arr.push({ value: String(user.unt_id + "_" + user.unt_description), label: user.unt_description });
                    });
                    setOptionUnityDest(arr)

                }
            } catch (e) {
                setOptionUnityDest([]);
            }
        }

    }

    function incluirAtivo(id: any) {
        let valor: any;

        if (id === '') {
            setProduct("")
            setPavement('')
            setDepartment('')


            listarAtivosUnidade(unity.split('_')[0])


        } else {

            setProduct(id)



            const filtrarAtivo = dadosAtivos.filter(
                (e: any) => {
                    return e.ast_id === parseInt(id.split('_')[0])
                });

            valor = filtrarAtivo[0];

            if (valor.loc_id !== "") {
                listarlistardepartid(valor.loc_id)
            } else {
                setPavement('')
                setDepartment('')
            }





        }
    }

    async function listarlistardepartid(id: any) {
        let ret: AxiosResponse;
        let valor: any;

        try {
            ret = await api.get("local/listarlocalid", {
                params: {
                    id: id
                }
            })
            if (ret.data.status === 200) {
                valor = ret.data.data;


                setPavement(valor[0].loc_pavement)
                setDepartment(String(valor[0].loc_id))
                GetDepartmentPaviment(valor[0].loc_pavement)



            }
        } catch (e) {
            setPavement('')
            setDepartment('')
        }

    }

    //---------------------------------------------

    function IncluirUnityMOV(Unidade: any) {

        if (Unidade === '') {
            setUnityDest('')
            setOptionspavementMov([])
            setPavementMov('');
            setOptionsDepartmentMov([])
            setDepartmentMov('');
            setUser("");
        } else {
            setUnityDest(Unidade)
            GetDepartmentMOV(Unidade.split('_')[0])

        }
    }

    async function GetDepartmentMOV(unidade: any) {
        let ret: AxiosResponse;
        let valor: any;

        try {
            ret = await api.get("local/listar", {
                params: {
                    id: unidade
                }
            })
            if (ret.data.status === 200) {
                valor = ret.data.data;

                const arr = [] as any;
                let result = valor;
                result.map((user: any) => {
                    return arr.push({ value: String(user.loc_pavement), label: user.loc_pavement });
                });

                setOptionspavementMov(arr)

            }
        } catch (e) {
            setOptionspavementMov([])
        }
    }

    function IncluirLocaisMov(id: any) {
        if (id === '') {
            setUser("");
            setPavementMov("")
            setOptionsDepartmentMov([])
            setDepartmentMov('');

        } else {

            setPavementMov(id)
            GetDepartmentPavimentMOV(id)
        }
    }


    async function GetDepartmentPavimentMOV(pavimento: any) {
        let ret: AxiosResponse;
        let valor: any;

        try {
            ret = await api.get("local/listarlocpaviment", {
                params: {
                    id: unityDest.split('_')[0],
                    paviment: pavimento
                }
            })
            if (ret.data.status === 200) {
                valor = ret.data.data;

                const arr = [] as any;
                let result = valor;
                result.map((user: any) => {
                    return arr.push({ value: String(user.loc_id), label: user.loc_description });
                });
                setOptionsDepartmentMov(arr)
            }
        } catch (e) {
            setOptionsDepartmentMov([])
        }
    }

    return (
        <>
            <Nav />
            <div className="main-content">
                <Header />
                <main>
                    <div className='containerPrincipal'>
                        <div className="ContainerBody">
                        <div className="container-cad-prod29">
                            <div className='div-titulo-pages'>
                                <span className='fa-solid fa-ticket' />
                                <h1 className='div-titulo-pages-text'>Ticket</h1>
                            </div>

                          
                                <div className="InputBusca">
                                    <input
                                    autoComplete="off"
                                        placeholder="Pesquisar..."
                                        onChange={(e) => setInputBusca(e.target.value)}
                                        value={inputBusca}
                                    />
                                    <div className="icone-buscaT" onClick={buscarChamadosAbertos}>
                                        <FcSearch className="icone-add" />
                                    </div>

                                    <div className="icone-buscaT" onClick={limparchamados}>
                                        <i className="fa-solid fa-eraser icone-add"
                                        ></i>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <button className='btn-add' type="submit" onClick={novoChamado}><i className="fa fa-plus"></i> Novo Chamado</button>

                        <div className='btn-status'>
                            <div className='btn-sel-dir' onClick={listarTicketAbertos}>
                                <h1 className='titulo-status'>Abertos</h1>
                            </div>
                            <div className='btn-sel-esc' onClick={listarTicketFechado}>
                                <h1 className='titulo-status'>Finalizados</h1>
                            </div>
                        </div>

                        <div className="container-tab">
                            <div className="content-tabs">
                                <div
                                    className={
                                        toggleState === 1 ? "content  active-content" : "content"
                                    }
                                >
                                    <div className='list-tichet'>
                                        {chamadosAbertos}
                                    </div>
                                </div>
                                <div
                                    className={
                                        toggleState === 2 ? "content  active-content" : "content"
                                    }
                                >
                                    <div className='list-tichet'>
                                        {chamadosFinalizados}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </main>
            </div>


            <ModalIncluirNovo isOpen={openModalIncluirNovo} setModalOpen={() => setOpenModalIncluirNovo(!openModalIncluirNovo)}>
                <>
                    <div className='title-modal-incluir'>
                        <h1 className="modal-IncluirNovo-title"> {titulo}</h1>
                        <button className="fa fa-times icone-modal-IncluirNovo" data-dismiss="modal" aria-label="Close" onClick={fecharOpenModalIncluirNovo}></button>
                    </div>

                    <div className='container-modal-incluir2'>
                        {exibirNovoChamado === 'S' ? (
                            <div className='container-modal-incluir'>
                                <div className='novo-chamado'>

                                {entidade === '1' ? (<>
                                    <div className="container-cad-prod4">
                                            <div id="content1">
                                                <div className="select-box-ativos-select">
                                                    <span className="details">Contrato *</span>
                                                    <Select
                                                        maxMenuHeight={200}
                                                        placeholder={'Selecione...'}
                                                        options={optionContract}
                                                        styles={customStyles}
                                                        value={optionContract.filter(function (option: any) {
                                                            return option.value === contractDest;
                                                        })}
                                                        onChange={(options: any) =>
                                                            !options ? IncluirContratoDest("") : IncluirContratoDest(options.value)
                                                        }
                                                        isClearable
                                                        noOptionsMessage={() => "Não encontrado..."}
                                                        theme={(theme: any) => ({
                                                            ...theme,
                                                            borderRadius: 4,
                                                            colors: {
                                                                ...theme.colors,
                                                                primary25: '#ebebeb',
                                                                primary: 'black',
                                                            },
                                                        })}
                                                    />
                                                </div>
                                            </div>
                                            <div id="content2">
                                                <div className="select-box-ativos-select">
                                                    <span className="details">Unidade *</span>
                                                    <Select
                                                        maxMenuHeight={200}
                                                        placeholder={'Selecione...'}
                                                        options={optionUnityDest}
                                                        styles={customStyles}
                                                        value={optionUnityDest.filter(function (option: any) {
                                                            return option.value === unityDest;
                                                        })}
                                                        onChange={(options: any) =>
                                                            !options ? IncluirUnityMOV("") : IncluirUnityMOV(options.value)
                                                        }
                                                        isClearable

                                                        noOptionsMessage={() => "Não encontrado..."}
                                                        theme={(theme: any) => ({
                                                            ...theme,
                                                            borderRadius: 4,
                                                            colors: {
                                                                ...theme.colors,
                                                                primary25: '#ebebeb',
                                                                primary: 'black',
                                                            },
                                                        })}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="container-cad-prod4">
                                            <div id="content1">
                                                <div className="select-box-ativos-select">
                                                    <span className="details">Pavimento *</span>
                                                    <Select
                                                        maxMenuHeight={100}
                                                        placeholder={'Selecione...'}
                                                        options={optionspavementMov}
                                                        styles={customStyles}
                                                        value={optionspavementMov.filter(function (option: any) {
                                                            return option.value === pavementMov;
                                                        })}
                                                        onChange={(options: any) =>
                                                            !options ? IncluirLocaisMov("") : IncluirLocaisMov(options.value)
                                                        }
                                                        isClearable
                                                        noOptionsMessage={() => "Não encontrado..."}
                                                        theme={(theme: any) => ({
                                                            ...theme,
                                                            borderRadius: 4,
                                                            colors: {
                                                                ...theme.colors,
                                                                primary25: '#ebebeb',
                                                                primary: 'black',
                                                            },
                                                        })}
                                                    />
                                                </div>
                                            </div>
                                            <div id="content2">
                                                <div className="select-box-ativos-select">
                                                    <span className="details">Local *</span>
                                                    <Select
                                                        maxMenuHeight={100}
                                                        placeholder={'Selecione...'}
                                                        options={optionsDepartmentMov}
                                                        styles={customStyles}
                                                        value={optionsDepartmentMov.filter(function (option: any) {
                                                            return option.value === departmentMov;
                                                        })}
                                                        onChange={(options: any) =>
                                                            !options ? setDepartmentMov("") : setDepartmentMov(options.value)
                                                        }
                                                        isClearable
                                                        noOptionsMessage={() => "Não encontrado..."}
                                                        theme={(theme: any) => ({
                                                            ...theme,
                                                            borderRadius: 4,
                                                            colors: {
                                                                ...theme.colors,
                                                                primary25: '#ebebeb',
                                                                primary: 'black',
                                                            },
                                                        })}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                    </>) : null}
                                    
                                {entidade === '2' ? (<>
                                    <div className="container-cad-prod4">
                                        <div id="content1">
                                            <div className="container-cad-prod3">
                                                <div className="select-box-ativos-select">
                                                    <span className="details">Contrato *</span>
                                                    <Select
                                                        maxMenuHeight={200}
                                                        placeholder={'Selecione...'}
                                                        options={optionContract}
                                                        styles={customStyles}
                                                        value={optionContract.filter(function (option: any) {
                                                            return option.value === contract;
                                                        })}
                                                        onChange={(options: any) =>
                                                            !options ? IncluirContrato("") : IncluirContrato(options.value)
                                                        }
                                                        isClearable
                                                        noOptionsMessage={() => "Não encontrado..."}
                                                        theme={(theme: any) => ({
                                                            ...theme,
                                                            borderRadius: 4,
                                                            colors: {
                                                                ...theme.colors,
                                                                primary25: '#ebebeb',
                                                                primary: 'black',
                                                            },
                                                        })}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div id="content2">
                                            <div className="container-cad-prod3">
                                                <div className="select-box-ativos-select">
                                                    <span className="details">Unidade *</span>
                                                    <Select
                                                        maxMenuHeight={200}
                                                        placeholder={'Selecione...'}
                                                        options={optionUnity}
                                                        styles={customStyles}
                                                        value={optionUnity.filter(function (option: any) {
                                                            return option.value === unity;
                                                        })}
                                                        onChange={(options: any) =>
                                                            !options ? IncluirUnity("") : IncluirUnity(options.value)
                                                        }
                                                        isClearable
                                                        noOptionsMessage={() => "Não encontrado..."}
                                                        theme={(theme: any) => ({
                                                            ...theme,
                                                            borderRadius: 4,
                                                            colors: {
                                                                ...theme.colors,
                                                                primary25: '#ebebeb',
                                                                primary: 'black',
                                                            },
                                                        })}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="container-cad-prod4">
                                        <div id="content1">
                                            <div className="select-box-ativos-select">
                                                <span className="details">Pavimento *</span>
                                                <Select
                                                    maxMenuHeight={100}
                                                    placeholder={'Selecione...'}
                                                    options={optionspavement}
                                                    styles={customStyles}
                                                    value={optionspavement.filter(function (option: any) {
                                                        return option.value === pavement;
                                                    })}
                                                    onChange={(options: any) =>
                                                        !options ? IncluirLocais("") : IncluirLocais(options.value)
                                                    }
                                                    isClearable
                                                    noOptionsMessage={() => "Não encontrado..."}
                                                    theme={(theme: any) => ({
                                                        ...theme,
                                                        borderRadius: 4,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#ebebeb',
                                                            primary: 'black',
                                                        },
                                                    })}
                                                />
                                            </div>
                                        </div>
                                        <div id="content2">
                                            <div className="select-box-ativos-select">
                                                <span className="details">Local *</span>
                                                <Select
                                                    maxMenuHeight={100}
                                                    placeholder={'Selecione...'}
                                                    options={optionsDepartment}
                                                    styles={customStyles}
                                                    value={optionsDepartment.filter(function (option: any) {
                                                        return option.value === department;
                                                    })}
                                                    onChange={(options: any) =>
                                                        !options ? incluirDepartamento("") : incluirDepartamento(options.value)
                                                    }
                                                    isClearable
                                                    noOptionsMessage={() => "Não encontrado..."}
                                                    theme={(theme: any) => ({
                                                        ...theme,
                                                        borderRadius: 4,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#ebebeb',
                                                            primary: 'black',
                                                        },
                                                    })}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="container-cad-prod4">
                                        <div id="content1">
                                            <div className="select-box-ativos-select">
                                                <span className="details">Ativo *</span>
                                                <Select
                                                    maxMenuHeight={200}
                                                    placeholder={'Selecione...'}
                                                    options={optionProduct}
                                                    styles={customStyles}
                                                    value={optionProduct.filter(function (option: any) {
                                                        return option.value === product;
                                                    })}
                                                    onChange={(options: any) =>
                                                        !options ? incluirAtivo("") : incluirAtivo(options.value)
                                                    }
                                                    isClearable
                                                    noOptionsMessage={() => "Não encontrado..."}
                                                    theme={(theme: any) => ({
                                                        ...theme,
                                                        borderRadius: 4,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#ebebeb',
                                                            primary: 'black',
                                                        },
                                                    })}
                                                />
                                            </div>
                                        </div>

                                        <div id="content2">
                                            <div className="select-box-ativos-select">
                                                <span className="details">Tipo *</span>
                                                <Select
                                                    maxMenuHeight={200}
                                                    placeholder={'Selecione...'}
                                                    options={optionTipo}
                                                    styles={customStyles}
                                                    value={optionTipo.filter(function (option: any) {
                                                        return option.value === tipo;
                                                    })}
                                                    onChange={(options: any) =>
                                                        !options ? incluirTipo("") : incluirTipo(options.value)
                                                    }
                                                    isClearable
                                                    noOptionsMessage={() => "Não encontrado..."}
                                                    theme={(theme: any) => ({
                                                        ...theme,
                                                        borderRadius: 4,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#ebebeb',
                                                            primary: 'black',
                                                        },
                                                    })}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                </>) : null}















                                    <div className="container-cad-prod4">
                                        <div id="content1">
                                            <div className="input-box-ativos">
                                                <span className="details">Requisitante</span>
                                                <input
                                                autoComplete="off"
                                                    id="user"
                                                    onChange={(e) => setResponsavel(e.target.value)}
                                                    value={responsavel}
                                                />
                                            </div>
                                        </div>
                                        <div id="content2">
                                            <div className="input-box-ativos">
                                                <span className="details">Telefone</span>
                                                <input
                                                autoComplete="off"
                                                    id="user"
                                                    onChange={(e) => setTelefone(e.target.value)}
                                                    value={telefone}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    {tipo === '3' ? (<>
                                        <h1 className="spa-aten-ticket">
                                            <b> Atenção:</b> Selecione o destino da movimentação.
                                        </h1>


                                        <div className="container-cad-prod4">
                                            <div id="content1">
                                                <div className="select-box-ativos-select">
                                                    <span className="details">Contrato *</span>
                                                    <Select
                                                        maxMenuHeight={200}
                                                        placeholder={'Selecione...'}
                                                        options={optionContract}
                                                        styles={customStyles}
                                                        value={optionContract.filter(function (option: any) {
                                                            return option.value === contractDest;
                                                        })}
                                                        onChange={(options: any) =>
                                                            !options ? IncluirContratoDest("") : IncluirContratoDest(options.value)
                                                        }
                                                        isClearable
                                                        noOptionsMessage={() => "Não encontrado..."}
                                                        theme={(theme: any) => ({
                                                            ...theme,
                                                            borderRadius: 4,
                                                            colors: {
                                                                ...theme.colors,
                                                                primary25: '#ebebeb',
                                                                primary: 'black',
                                                            },
                                                        })}
                                                    />
                                                </div>
                                            </div>
                                            <div id="content2">
                                                <div className="select-box-ativos-select">
                                                    <span className="details">Unidade *</span>
                                                    <Select
                                                        maxMenuHeight={200}
                                                        placeholder={'Selecione...'}
                                                        options={optionUnityDest}
                                                        styles={customStyles}
                                                        value={optionUnityDest.filter(function (option: any) {
                                                            return option.value === unityDest;
                                                        })}
                                                        onChange={(options: any) =>
                                                            !options ? IncluirUnityMOV("") : IncluirUnityMOV(options.value)
                                                        }
                                                        isClearable

                                                        noOptionsMessage={() => "Não encontrado..."}
                                                        theme={(theme: any) => ({
                                                            ...theme,
                                                            borderRadius: 4,
                                                            colors: {
                                                                ...theme.colors,
                                                                primary25: '#ebebeb',
                                                                primary: 'black',
                                                            },
                                                        })}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="container-cad-prod19">
                                            <div id="content1">
                                                <div className="select-box-ativos-select">
                                                    <span className="details">Pavimento *</span>
                                                    <Select
                                                        maxMenuHeight={100}
                                                        placeholder={'Selecione...'}
                                                        options={optionspavementMov}
                                                        styles={customStyles}
                                                        value={optionspavementMov.filter(function (option: any) {
                                                            return option.value === pavementMov;
                                                        })}
                                                        onChange={(options: any) =>
                                                            !options ? IncluirLocaisMov("") : IncluirLocaisMov(options.value)
                                                        }
                                                        isClearable
                                                        noOptionsMessage={() => "Não encontrado..."}
                                                        theme={(theme: any) => ({
                                                            ...theme,
                                                            borderRadius: 4,
                                                            colors: {
                                                                ...theme.colors,
                                                                primary25: '#ebebeb',
                                                                primary: 'black',
                                                            },
                                                        })}
                                                    />
                                                </div>
                                            </div>
                                            <div id="content2">
                                                <div className="select-box-ativos-select">
                                                    <span className="details">Local *</span>
                                                    <Select
                                                        maxMenuHeight={100}
                                                        placeholder={'Selecione...'}
                                                        options={optionsDepartmentMov}
                                                        styles={customStyles}
                                                        value={optionsDepartmentMov.filter(function (option: any) {
                                                            return option.value === departmentMov;
                                                        })}
                                                        onChange={(options: any) =>
                                                            !options ? setDepartmentMov("") : setDepartmentMov(options.value)
                                                        }
                                                        isClearable
                                                        noOptionsMessage={() => "Não encontrado..."}
                                                        theme={(theme: any) => ({
                                                            ...theme,
                                                            borderRadius: 4,
                                                            colors: {
                                                                ...theme.colors,
                                                                primary25: '#ebebeb',
                                                                primary: 'black',
                                                            },
                                                        })}
                                                    />
                                                </div>
                                            </div>
                                            <div id="content3">
                                                <div className="input-box-ativos">
                                                    <span className="details">Nome Usuário</span>
                                                    <input
                                                    autoComplete="off"
                                                        id="user"
                                                        onChange={(e) => setUser(e.target.value)}
                                                        value={user}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </>) : null}

                                    <div className="container-cad-prod3">
                                        <div className="textareaBox-ativo">
                                            <span className="details">Mensagem *</span>
                                            <textarea className="input-field-Chamados-novo" maxLength={3000} onChange={(e) => setMensagem(e.target.value)}
                                                value={mensagem}
                                            />
                                        </div>
                                    </div>

                                    <div className="Bt-button-cad">
                                        <button type="submit" onClick={confirmar}>
                                            <i className="fa fa-check-circle icon-ok-btn"></i> Salvar
                                        </button>
                                        <button type="submit" onClick={Cancelar}>
                                            <i className="fa fa-times icon-cancelar-btn"></i> Cancelar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ) : null}

                        {exibirDetalhamento === 'S' ? (
                            formDetalhamento
                        ) : null}

                    </div>
                </>
            </ModalIncluirNovo>


            <ModalPrincipal isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)}>
                <div className='container-modal-alert'>
                    {qualAlert}
                </div>
            </ModalPrincipal>

        </>
    );

}

export default Tickets;