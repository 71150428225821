
import React, { useState, useEffect } from 'react'
import Nav from "../../components/Nav";
import Header from "../../components/Header";
import './index.css'
import { AxiosResponse } from "axios";
import api from '../../Services/api'
import Form from "../../components/eFormItem";
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import Loading from '../../components/Loading'
import ModalAlert from '../../components/ModalAlert/modal'
import secureLocalStorage from "react-secure-storage";

function NotaFiscalAdd() {
    const navigate = useNavigate();
    let { id } = useParams();
    const [idInvoice, setIdInvoice] = useState('')
    const [active, setActive] = useState(true)
    const [provider, setProvider] = useState('');
    const [alternativeIdentifier, setAlternativeIdentifier] = useState('');
    const [alternativeIdentifierIni, setAlternativeIdentifierIni] = useState('');
    const [cnpj, setCnpj] = useState('');
    const [invoiceNumber, setInvoiceNumber] = useState('');
    const [series, setSeries] = useState('');
    const [accesskey, setAccesskey] = useState('');
    const [issueDate, setIssueDate] = useState('');
    const [noteValue, setNoteValue] = useState('');
    const [observation, setObservation] = useState('');
    const [created_at, setCreated_at] = useState('');
    const [updated_at, setUpdated_at] = useState('');
    const [warrantyMonth, setWarrantyMonth] = useState('');
    const [inventory, SetInventory] = useState('')
    const [deleteItem, SetDeleteItem] = useState('')
    const [transactionsList, setTransactionsList] = useState([]);

    const [optionsNotafiscal, setOptionsNotafiscal] = useState([]);
    const [openModal, setOpenModal] = useState(false)
    const [qualAlert, setQualAlert] = useState(<></>)
    
    const [activeDisabled, setActiveDisabled] = useState(false)
    const [providerDisabled, setProviderDisabled] = useState(false)
    const [cnpjDisabled, setCnpjDisabled] = useState(false)
    const [alternativeIdentifierDisabled, setAlternativeIdentifierDisabled] = useState(false)
    const [invoiceNumberDisabled, setInvoiceNumberDisabled] = useState(false)
    const [seriesDisabled, setSeriesDisabled] = useState(false)
    const [issueDateDisabled, setIssueDateDisabled] = useState(false)
    const [accesskeyDisabled, setAccesskeyDisabled] = useState(false)
    const [noteValueDisabled, setNoteValueDisabled] = useState(false)
    const [warrantyMonthDisabled, setWarrantyMonthDisabled] = useState(false)
    const [observationDisabled, setObservationDisabled] = useState(false)
    const [confirmarDisabled, setConfirmarDisabled] = useState(false)

    useEffect(() => {

        setQualAlert(<div className='div-alert'><Loading /></div>)
        setOpenModal(true)

        validatToken();
    }, []);


    async function validatToken() {

        let ret: AxiosResponse;
        let valor: any;

        const _user = secureLocalStorage.getItem('t')

        api.defaults.headers.common['Authorization'] = _user as ''

        try {

            ret = await api.get("/status/validar")

            if (ret.data.status === 200) {

                secureLocalStorage.setItem('t', ret.data.data);
                secureLocalStorage.setItem('acp', ret.data.decodedToken.acp_integrationid);
                secureLocalStorage.setItem('n', ret.data.decodedToken.name);
                secureLocalStorage.setItem('iag', ret.data.decodedToken.id);
                secureLocalStorage.setItem('cy', ret.data.decodedToken.company);

                if(ret.data.decodedToken.acp_integrationid === 'CONSULTOR'){
                    setActiveDisabled(true)
                    setProviderDisabled(true)
                    setCnpjDisabled(true)
                    setAlternativeIdentifierDisabled(true)
                    setInvoiceNumberDisabled(true)
                    setSeriesDisabled(true)
                    setIssueDateDisabled(true)
                    setAccesskeyDisabled(true)
                    setNoteValueDisabled(true)
                    setWarrantyMonthDisabled(true)
                    setObservationDisabled(true)
                    setConfirmarDisabled(true)
                }

                await  invoiceeditar();
                await  GetItensNota(id)
                SetDeleteItem('N')
                 await GetNotafiscal()
                setOpenModal(false)

            }

        } catch (e: any) {

            if (e.response.data.status === 403) {

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
                    <div className='btn-alert' onClick={fecharModalToken}><span className="btn-alert-lab">OK</span></div>
                </div>)
            }
        }
    }

    async function GetNotafiscal() {
        let ret: AxiosResponse;
        let valor: any;
        try {
            ret = await api.get("invoice/listar")
            if (ret.data.status === 200) {
                valor = ret.data.data;

                setOptionsNotafiscal(valor);

            }
        } catch (e) {

            setOptionsNotafiscal([]);

        }
    }

    
    function fecharModalToken() {

        secureLocalStorage.removeItem('t')
        secureLocalStorage.removeItem('acp')
        secureLocalStorage.removeItem('n')
        secureLocalStorage.removeItem('iag')
        navigate('/login');
        setOpenModal(false)

    }
    
    async function GetItensNota(id: any) {
        let ret: AxiosResponse;
        let valor: any;
        try {
            ret = await api.get("invoiceitems/listar", {
                params: {
                    id: id
                }
            })
            if (ret.data.status === 200) {
                valor = ret.data.data;
                setTransactionsList(valor)
                sessionStorage.setItem("dadosItens", JSON.stringify(valor));

            }
        } catch (e) {
            setTransactionsList([])
        }
    }

    async function invoiceeditar() {
      
        let ret: AxiosResponse;
        let valor: any;
   
     if(/^\d+$/.test(id || '') === true){
        try {
            ret = await api.get("invoice/invoiceeditar", {
                params: {
                    id: parseInt(id || '')
                }
            })
            if (ret.data.status === 200) {
          
                setIdInvoice(ret.data.data[0].inc_id || '')
                setActive(ret.data.data[0].inc_active != undefined ? Boolean(ret.data.data[0].inc_active) : true)
                setProvider(ret.data.data[0].inc_provider || '');
                setAlternativeIdentifier(ret.data.data[0].inc_alternativeidentifier || '');
                setAlternativeIdentifierIni(ret.data.data[0].inc_alternativeidentifier || '');
                setCnpj(ret.data.data[0].inc_cnpj || '');
                setInvoiceNumber(maskOnlyNumbers(ret.data.data[0].inc_invoicenumber || ''));
                setSeries(ret.data.data[0].inc_series || '');
                setAccesskey(ret.data.data[0].inc_accesskey || '');
                setIssueDate(ret.data.data[0].inc_issuedate || '');
                incluirValor(ret.data.data[0].inc_notevalue || '');
                setObservation(ret.data.data[0].inc_observation || '');
                setCreated_at(ret.data.data[0].inc_datetimeinsert?.substring(0, 16) || '');
                setUpdated_at(ret.data.data[0].inc_datetimeupdate?.substring(0, 16) || '');
                setWarrantyMonth(ret.data.data[0].inc_warrantymonth || '');
                SetInventory(ret.data.data[0].inc_inclusion || '')

            }else{
                navigate('/invoice/edit/'+ id + '/n');
            }
        } catch (e) {
            navigate('/invoice/edit/'+ id + '/n');
        }
    }else{
        navigate('/invoice/edit/'+ id + '/n');
    }
    }

    function finalizarModal2() {
        setOpenModal(false)
    }

    function ConfirmareNota() {

        setOpenModal(true)
        setQualAlert(<div className='div-alert'><Loading /></div>)

        const FiltrarAlternativeIdentifier = optionsNotafiscal.filter(
            (e: any) => {
                return e.inc_alternativeidentifier.toLowerCase() === alternativeIdentifier.toLowerCase()
            }
        );


        if (provider === "") {

            setQualAlert(<div className='div-alert'>
            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
            <div className='div-info-alert'><span className="info-alert">Digite o fornecedor.</span></div>
            <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
        </div>)
        setOpenModal(true)

        } else
            if (cnpj === "") {

                setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Digite o CNPJ.</span></div>
                <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
            </div>)
            setOpenModal(true)

            } else if (alternativeIdentifier.toLowerCase() !== "" && alternativeIdentifierIni.toLowerCase() !== alternativeIdentifier.toLowerCase() && FiltrarAlternativeIdentifier.length > 0) {

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Identificador Alternativo já está em uso.</span></div>
                    <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)
            } else
                    if (invoiceNumber === "") {

                        setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Digite o numero da nota.</span></div>
                        <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                    </div>)

                    setOpenModal(true)

                    } else
                                if (issueDate === "") {

                                    setQualAlert(<div className='div-alert'>
                                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                                    <div className='div-info-alert'><span className="info-alert">Digite a data de emissão.</span></div>
                                    <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                                </div>)
                                
                                setOpenModal(true)


                                } else
                                    if (noteValue === "") {

                                        setQualAlert(<div className='div-alert'>
                                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                                        <div className='div-info-alert'><span className="info-alert">Digite o valor da nota.</span></div>
                                        <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                                    </div>)
                                    
                                    setOpenModal(true)


                                    } else if (transactionsList.length <= 0) {

                                        
                                        setQualAlert(<div className='div-alert'>
                                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                                        <div className='div-info-alert'><span className="info-alert">Informe os itens da nota.</span></div>
                                        <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                                    </div>)
                                    
                                    setOpenModal(true)

                                    } else {
                                        UpdateNota()
                                    }
    }

    function showDate(dateString: any, daysMonthsYears: any) {
        const dateToShow = new Date(dateString);

        if (!dateString || !dateToShow.getTime()) return '';

        dateToShow.setUTCHours(3);

        const { days, months, years } = daysMonthsYears;

        if (days) dateToShow.setDate(dateToShow.getDate() + Number(days));
        if (months) dateToShow.setMonth(dateToShow.getMonth() + Number(months));
        if (years) dateToShow.setFullYear(dateToShow.getFullYear() + Number(years));
        return dateToShow.toISOString().split('T')[0];
    }


    const [daysMonthsYears, setDaysMonthsYears] = useState({
        days: 0,
        months: 0,
        years: 0,
    });

    function finalizarModal1() {
        navigate('/invoice');
    }

    async function UpdateNota() {
        let newArray = [] as any
        let ret: AxiosResponse;

        let dataexp = ''

        const dados = {
            inc_id: idInvoice,
            inc_active: active,
            inc_provider: provider,
            inc_cnpj: cnpj,
            inc_alternativeidentifier: alternativeIdentifier,
            inc_invoicenumber: invoiceNumber,
            inc_series: series,
            inc_accesskey: accesskey,
            inc_issuedate: issueDate,
            inc_notevalue: noteValue,
            inc_warrantymonth: warrantyMonth,
            inc_observation: observation,
            age_id_lastupdate: secureLocalStorage.getItem('iag'),
            inc_expirationdate: dataexp
        }
        if (inventory === 'N') {
            try {
                ret = await api.put("invoice/update", {
                    data: dados,
                    datalist: transactionsList
                });
                if (ret.data.status === 200) {

                    setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-check ControleIconAlert"></span>
                    <div className='div-info-alert'><span className="info-alert">Nota fiscal alterada com sucesso.</span></div>
                    <div className='btn-alert' onClick={finalizarModal1}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)
                
 
                }
            } catch (e: any) {

                setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Não foi possível concluir a alteração.</span></div>
                <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
            </div>)
            
            setOpenModal(true)

            }
        } else if (inventory === 'S') {

            setQualAlert(<div className='div-alert'>
            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
            <div className='div-info-alert'><span className="info-alert">Não é possivel alterar a nota vinculada ao cadastro de ativos.</span></div>
            <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
        </div>)
        
        setOpenModal(true)

        }
    }

    const handleAdd = (transaction: any) => {
        const newArrayTransactions = [...transactionsList, transaction];
        let transfor = JSON.stringify(newArrayTransactions)
        setTransactionsList(JSON.parse(transfor));
    };

    function ValitarActive() {
        let e = document.getElementById("checkbox-active-nota") as HTMLInputElement;
        if (e.checked) {
            setActive(true)
        } else {
            setActive(false)
        }
    }

    function Cancelar() {
        navigate('/invoice');
    }

    function incluirValor(value: any) {
        var valor = value
        valor = valor + '';
        valor = parseInt(valor.replace(/[\D]+/g, ''));
        valor = valor + '';
        valor = valor.replace(/([0-9]{2})$/g, ".$1");
        if (valor.length > 6) {
            valor = valor.replace(/([0-9]{3}),([0-9]{2}$)/g, "$1.$2");
        }
        setNoteValue(valor)
        if (valor == 'NaN') {
            setNoteValue('');
        }
    }


    function validarcnpj(cnpj: any) {
        var valor = cnpj

        valor = valor + '';
        valor = valor.replace(/\D/g, "");
        valor = valor + '';

        if (valor == 'NaN') {
            setCnpj('');
        } else {
            if (valor.length > 14) {

            } else {
                setCnpj(valor.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5"))
            }

        }
    }

    const maskOnlyNumbers = (value: any) => {
        return value.replace(/\D/g, "");
    };

    return (
        <>
            <Nav />
            <div className="main-content">
                <Header />
                <main>

                    <div className='container-principal'>
                        <div className="title-Page">
                            <div className="title-text">
                                <div className="size-x circle">1</div>
                                <h2>Defina as informações gerais sobre a nota fiscal</h2>
                            </div>
                            <hr />
                        </div>

                        <div className="class-checkbox">
                            <input type="checkbox" disabled={activeDisabled} id="checkbox-active-nota" checked={active} onChange={ValitarActive} />
                            <h4 className="lab-ativo">Ativo</h4>
                        </div>
                        <div className="container-cad-prod5">
                            <div className="input-box-ativos">
                                <span className="details">Fornecedor *</span>
                                <input
                                    autoComplete="off"
                                    type="text"
                                    onChange={(e) => setProvider(e.target.value)} 
                                    value={provider}
                                    disabled={providerDisabled}
                                />
                            </div>
                            <div className="input-box-ativos">
                                <span className="details">CNPJ *</span>
                                <input
                                autoComplete="off"
                                    type="text"
                                    onChange={(e) => validarcnpj(e.target.value)} 
                                    value={cnpj}
                                    disabled={cnpjDisabled}
                                />
                            </div>
                            <div className="input-box-ativos">
                                <span className="details">Identificador Alternativo</span>
                                <input
                                autoComplete="off"
                                    type="text"
                                    onChange={(e) => setAlternativeIdentifier(e.target.value)} 
                                    value={alternativeIdentifier}
                                    disabled={alternativeIdentifierDisabled}
                                />
                            </div>
                        </div>
                        <div className="container-cad-prod13">


                            <div className="input-box-ativos">
                                <span className="details">Numero da Nota *</span>
                                <input
                                autoComplete="off"
                                    type="text"
                                    onChange={(e) => setInvoiceNumber(maskOnlyNumbers(e.target.value))} 
                                    value={invoiceNumber}
                                    disabled={invoiceNumberDisabled}
                                />
                            </div>

                            <div className="input-box-ativos">
                                <span className="details">Série</span>
                                <input
                                autoComplete="off"
                                    type="text"
                                    onChange={(e) => setSeries(e.target.value)}
                                    value={series}
                                    disabled={seriesDisabled}
                                />
                            </div>
                            <div className="input-box-ativos">
                                <span className="details">Data de Emissão *</span>
                                <input
                                    type="date"
                                    onChange={(e) => setIssueDate(e.target.value)} 
                                    value={issueDate}
                                    disabled={issueDateDisabled}
                                />
                            </div>
                            <div className="input-box-ativos">
                                <span className="details">Chave de Acesso </span>
                                <input
                                autoComplete="off"
                                    type="text"
                                    onChange={(e) => setAccesskey(e.target.value)} 
                                    value={accesskey}
                                    disabled={accesskeyDisabled}
                                />
                            </div>

                        </div>
                        <div className="container-cad-prod6">

                            <div className="input-box-ativos">
                                <span className="details">Valor da Nota *</span>
                                <input
                                autoComplete="off"
                                    className='input-nota'
                                    type="text"
                                    placeholder='R$'
                                    onChange={(e) => incluirValor(e.target.value)} 
                                    value={noteValue}
                                    disabled={noteValueDisabled}
                                />
                            </div>

                            <div className="input-box-ativos">
                                <span className="details">Garantia</span>
                                <div className='input-flex-ativos'>


                                    <input
                                     placeholder='Quan. Mês'
                                        autoComplete="off"
                                        type="number"
                                        value={warrantyMonth}
                                        maxLength={2}
                                        onChange={(e) => setWarrantyMonth(e.target.value)}
                                        disabled={warrantyMonthDisabled}
                                    />
                               
                               
                                </div>
                            </div>

                            <div className="input-box-ativos">
                                <span className="details">Data da inclusão</span>
                                <input
                                    type="datetime-local"
                                    id="DATA-TIME"
                                    name="DATE-TIME"
                                    disabled
                                    value={created_at}
                                />
                            </div>

                            <div className="input-box-ativos">
                                <span className="details">Data da última atualização</span>
                                <input
                                    type="datetime-local"
                                    id="DATA-TIME"
                                    name="DATE-TIME"
                                    disabled
                                    value={updated_at}
                                />
                            </div>
                        </div>

                        <div className="container-cad-prod3">
                            <div className="textareaBox-ativo">
                                <span className="details">Observação</span>
                                <textarea
                                    onChange={(e) => setObservation(e.target.value)} 
                                    value={observation}
                                    disabled={observationDisabled}
                                />
                            </div>
                        </div>

                        <div className="title-Page">
                            <div className="title-text">
                                <div className="size-x circle">2</div>
                                <h2>Itens Notas Fiscais</h2>
                            </div>
                            <hr />
                        </div>

                        <div className="itens">

                            <Form
                                handleAdd={handleAdd}
                                transactionsList={transactionsList}
                                setTransactionsList={setTransactionsList}
                                inventory={inventory}
                            />

                        </div>

                        <div className="Bt-button-cad">
                            <button type="submit" onClick={ConfirmareNota} disabled={confirmarDisabled}>
                                <i className="fa fa-check-circle icon-ok-btn"></i> Salvar
                            </button>
                            <button type="submit" onClick={Cancelar}>
                                <i className="fa fa-times icon-cancelar-btn"></i> Cancelar
                            </button>
                        </div>

                    </div>
                </main>
            </div>

            
            <ModalAlert isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)}>
                <div className='container-modal-alert'>
                    {qualAlert}
                </div>
            </ModalAlert>

        </>
    );

}

export default NotaFiscalAdd;