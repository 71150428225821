import styled from "styled-components";


interface ITagProps {
  color: string;
}

interface IButtonProps {
  color: string;
}

export const IButton = styled.div<IButtonProps>`
    background-color: red;
    width: 60px;
    height: 10px;
`;

interface IRespondido {
  color: string;
}

export const ContainerRespondido = styled.li<IRespondido>`
    margin-top: 3px;
    color:#222d32;
    background-color: ${props => props.color};
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 6px;
    padding-right: 6px;
    border-radius: 5px;
`;

export const Container = styled.li<ITagProps>`
justify-content: space-between;
list-style: none;
padding: 8px 8px 8px;
display: flex;
align-items: center;
transition: all .3s;
position: relative;
margin-bottom: 10px;
border-top: 5px solid ${props => props.color};
border-left: 1px solid #ccc;
border-bottom: 1px solid #ccc;
border-left: 1px solid #ccc;
border-right: 1px solid #ccc;
border-top-left-radius: 5px;
border-top-right-radius: 5px;
border-bottom-left-radius: 5px;
border-bottom-right-radius: 5px;
background-color: #fff;
margin-right: 5px;
cursor: pointer;

.ticket-data{
  display: flex;
}

.ticket-hora{
  font-size: 14px;
    font-weight: normal;
    color:#222d32;
    margin-left: 10px;
}

&:hover{
 opacity: 0.8;
}

.container-list-cham{
  display: flex;
}

  .title-list{
    font-size: 14px;
   font-weight: normal;
   color:#222d32;
   font-weight: bold;
  }

  .assunt-list{
    font-size: 14px;
    font-weight: normal;
    color:#222d32;
  }

  .assunt-list-status{
    margin-top: 3px;
    color:#222d32;
    background-color:#BAD76D;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 5px;
  }

  .container-agend2 {
    display: grid;
    width: 100%;
    grid-template-columns: 0.9fr 1.5fr 0.8fr 0.2fr ;
    grid-template-areas: "content1 content2 content3 content4";
    grid-gap: 0.5rem;
  }

  .container-list1 {
    display: grid;
    width: 100%;
    grid-template-columns: 7fr 1.5fr 2fr;
    grid-template-areas: "content1 content1 content2";
  }

  .wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}
.box {
    box-sizing: border-box;
    background-color: silver;
    width: calc((100% / 2) - 2rem); /* calco para descontar a margem da largura do box se aumenta a marge tem que ajustar aqui tb */
}

  #content-list1 {
    grid-area: content1;
    border-radius: var(--main-radius);
    padding-top: var(--main-padding);
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 14px;
  }
  
  #content-list2 {
    grid-area: content2;
    border-radius: var(--main-radius);
    padding-top: var(--main-padding);
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 14px;
    float:right;
    
 
  }
  
  .status-chamados{
    background-color: #3CB371;
    padding: 1px;
  }

  #content-list3 {
    grid-area: content3;
    border-radius: var(--main-radius);
    padding-top: var(--main-padding);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 14px;
    margin-left: 10px;
  }

> div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 10px;
}

.icone-resumo{
  color: #4B0C46;
  text-align: right;

}

.nome-list{
  margin-left: 5px;
  text-align: right;
}

.span-nome{
font-size: 14px;
}

.span-result{
  font-size: 12px;
  font-weight: normal;
 
}

.span-inf{
font-size: 12px;
color:#222d32;
}

.span-inf2{
  font-size: 12px;
  color:#222d32;
}


@media only screen and (max-width: 860px) {

  .icone-resumo{
    margin-top: 2px;
  text-align: left;

}

@media (max-width: 500px) {
  .title-list{
    font-size: 14px;
  }
  }

 

  .container-list1{
    grid-template-columns: 1fr;
    grid-template-areas: 
    "content1" 
    "content2" 
  }

  #content-list2{
   margin-top: 2px;
   }

 #content-list2{
	 margin-left: 0px;
   margin-top: 2px;
   }

  #content-list3{
	 margin-left: 0px;
   margin-top: 2px;
   }

}

`;

export const Tag = styled.div<ITagProps>`
    height: 13px;
    width: 13px;
    border-radius: 50%;
    background-color: ${props => props.color};
    position: absolute;
    left: 5px;
`;

export const Title = styled.div`
 text-align: left;
 font-weight: bold;
 word-break: break-all;

 span{
  font-size: 11px;
 }
 
 .title-senha{
   margin-left: 10px;
   margin-right: 10px;
 }


`
export const ContResumo = styled.div`
margin: 0 0 0 10px;

  .text{
    margin-top: 1px
  }
  span{
    text-transform: uppercase;
    font-size: 13px; 
  }
  b{
    font-size: 13px; 
  }
`
export const TitleClassif = styled.div`
  margin-top: 25px;
  text-align: center;

  h2{
    font-size: 13px; 
    margin-bottom: 5px;
    text-transform: uppercase;
  }
 
  input{
    margin: 5px;
    font-weight: bold;
  }
`

export const Tmoda = styled.div`
margin: 10px;
  .text{
    margin-top: 7px
  }
`

export const ButtonContainer = styled.div`
   width: 80%;
   margin-top: 30px;
   align-items: center;
`

