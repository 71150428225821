import React from 'react'; 
import styled from 'styled-components';
import { FaLayerGroup, FaBoxes, FaBuilding, FaExclamationTriangle, FaTools } from "react-icons/fa";
import { BsFillGearFill } from 'react-icons/bs';

const Container = styled.div`
  display: flex;
  justify-content: space-between;  /* Isso faz com que as divs sejam posicionadas nas extremidades */
  align-items: center;
  background-color: #fff;
  border-radius: 20px;
  width: 100%;
  margin-top: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adiciona a sombra aqui */
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  background-color: #fff;
  border-radius: 20px;
`;

const Label = styled.span`
margin-left: 10px;
  font-size: 16px;
  color: #333333;
  background-color: #fff;
  font-weight: bold;
`;

const Value = styled.span`
margin-left: 10px;
  font-size: 24px;
  font-weight: bold;
  color: #333333;
  background-color: #fff;
  margin-top: 5px;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #3962AC;
  height: 100%;
  padding-left: 40px;
  padding-right: 20px;
  color: white;
  border-bottom-left-radius: 50px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
`;

const Badge = styled.span`
margin-left: 10px;
  font-size: 14px;
  margin-top: 5px;
  color: #333333;
  transition: color 0.3s ease; /* Adiciona uma transição suave */
  cursor: pointer;
  &:hover {
    color: #3962ac; /* Cor alterada ao passar o mouse */
  }
`;


const WalletBox = (props: any) => {
  return (
    <Container>
      <Info>
        <Label>{props.title}</Label>
        <Value>{props.valor}</Value>
        <Badge onClick={props.chamarModalTotal}>Ver todos</Badge>

      </Info>
      <IconWrapper>

                 {props.icone === "fa fa-loc" ? (  
                    <FaBuilding size={30}/>
                ):null}

                 {props.icone === "fa fa-layer-group" ? (  
                    <FaLayerGroup size={30}/>
                ):null}

                {props.icone === "fa fa-boxes-stacked" ? (  
                    <FaBoxes size={30}/>
                ):null}

                {props.icone === "fa fa-building" ? (  
                    <BsFillGearFill size={30}/>
                ):null}

                {props.icone === "fa fa-exclamation-triangle" ? (  
                    <FaExclamationTriangle size={30}/>
                ):null}

      </IconWrapper>
    </Container>
  );
};

export default WalletBox;
