import "./featured.scss";
import React from "react";
import Aviso01 from '../Aviso01/index';
import ProgressBar from "@ramonak/react-progress-bar";

const Progressbar = (props) => {
  return (
    <div className="featured-ProgressBar2">
      <div className="top">
        <h1 className="title">Ativos por Categoria</h1>
      </div>

      {props.obj.length > 0 ? (
        <div className="container-progressbar">
          {props.obj.map((row) => {
            const totalAtivos = parseInt(props.totalativos);
            const totalRow = parseInt(row.total);

            // Verifica se props.totalativos é 0 para evitar divisão por zero
            const percentage = totalAtivos === 0 ? 0 : ((totalRow / totalAtivos) * 100).toFixed(2);

            return (
              <div className="ProgressBar" key={row.ctg_id}>
                <div className="ProgressBar-header">
                  <h1 className="title-desc">{row.ctg_description}</h1>
                  <h1 className="porcentagem-text">{percentage}%</h1>
                </div>
                <ProgressBar
                  completed={percentage}
                  bgColor="linear-gradient(90deg, #3962AC, #3962AC)"
                  animateOnRender={true}
                  customLabel=" "
                  height="16px"
                  baseBgColor="#e0e0e0"
                  borderRadius="50px"
                  isLabelVisible={false}
                />
              </div>
            );
          })}
        </div>
      ) : (
        <Aviso01 />
      )}
    </div>
  );
};

export default Progressbar;
