import React, { useState, useEffect } from 'react'
import Nav from "../../components/Nav";
import Header from "../../components/Header";
import './index.css'
import Select from 'react-select'
import api from '../../Services/api'
import { AxiosResponse } from "axios";
import { MdOutlineAddCircle } from 'react-icons/md';
import GridSecundario from '../../components/GridSecundario'
import FormTipoEquipe from '../../components/FormTipoEquipe';
import Loading from '../../components/Loading';
import { useNavigate } from 'react-router';
import DualListBox from "react-dual-listbox";
import ModalPrincipal from '../../components/ModalAlert/modal';
import secureLocalStorage from "react-secure-storage";
import 'react-dual-listbox/lib/react-dual-listbox.css';
import ModalIncluirNovo from '../../components/ModalIncluirNovo/modal'

// Definindo a interface para as permissões dos usuários
interface UserPermission {
  fea_description: string;
  fea_id: number;
  prm_create: number; // 1 ou 0 em vez de boolean
  prm_view: number;
  prm_edit: number;
  prm_delete: number;
}

// Dados de exemplo para as permissões
const initialPermissionsData: UserPermission[] = [

  {
    fea_description: 'Dashboard',
    fea_id: 1,
    prm_create: 0,
    prm_view: 0,
    prm_edit: 0,
    prm_delete: 0
  },
  {
    fea_description: 'Usuários',
    fea_id: 2,
    prm_create: 0,
    prm_view: 0,
    prm_edit: 0,
    prm_delete: 0
  },
  {
    fea_description: 'Mobile',
    fea_id: 3,
    prm_create: 0,
    prm_view: 0,
    prm_edit: 0,
    prm_delete: 0
  },
  {
    fea_description: 'Equipes',
    fea_id: 4,
    prm_create: 0,
    prm_view: 0,
    prm_edit: 0,
    prm_delete: 0
  },
  {
    fea_description: 'Atividades',
    fea_id: 5,
    prm_create: 0,
    prm_view: 0,
    prm_edit: 0,
    prm_delete: 0
  },
  {
    fea_description: 'Itens da Atividade',

    fea_id: 6,
    prm_create: 0,
    prm_view: 0,
    prm_edit: 0,
    prm_delete: 0
  },
  {
    fea_description: 'Planos Automáticos',
    fea_id: 7,
    prm_create: 0,
    prm_view: 0,
    prm_edit: 0,
    prm_delete: 0
  },
  {
    fea_description: 'Calendário Atividades',
    fea_id: 8,
    prm_create: 0,
    prm_view: 0,
    prm_edit: 0,
    prm_delete: 0
  },
  {
    fea_description: 'Cadastros Customizados',
    fea_id: 9,
    prm_create: 0,
    prm_view: 0,
    prm_edit: 0,
    prm_delete: 0
  },
  {
    fea_description: 'Produto',
    fea_id: 10,
    prm_create: 0,
    prm_view: 0,
    prm_edit: 0,
    prm_delete: 0
  },
  {
    fea_description: 'Ativos',
    fea_id: 11,
    prm_create: 0,
    prm_view: 0,
    prm_edit: 0,
    prm_delete: 0
  },
  {
    fea_description: 'Cadastros Ativo',
    fea_id: 12,
    prm_create: 0,
    prm_view: 0,
    prm_edit: 0,
    prm_delete: 0
  },
  {
    fea_description: 'Inventário Mobile',
    fea_id: 13,
    prm_create: 0,
    prm_view: 0,
    prm_edit: 0,
    prm_delete: 0
  },
  {
    fea_description: 'Locais',
    fea_id: 14,
    prm_create: 0,
    prm_view: 0,
    prm_edit: 0,
    prm_delete: 0
  },
  {
    fea_description: 'Terceiros',
    fea_id: 15,
    prm_create: 0,
    prm_view: 0,
    prm_edit: 0,
    prm_delete: 0
  },
  {
    fea_description: 'Peças e Materiais',
    fea_id: 16,
    prm_create: 0,
    prm_view: 0,
    prm_edit: 0,
    prm_delete: 0
  },
  {
    fea_description: 'Serviços',
    fea_id: 17,
    prm_create: 0,
    prm_view: 0,
    prm_edit: 0,
    prm_delete: 0
  },
  {
    fea_description: 'Notas Fiscais',
    fea_id: 18,
    prm_create: 0,
    prm_view: 0,
    prm_edit: 0,
    prm_delete: 0
  },
  {
    fea_description: 'Meus Cadastros',
    fea_id: 19,
    prm_create: 0,
    prm_view: 0,
    prm_edit: 0,
    prm_delete: 0
  },
  {
    fea_description: 'Movimentações',
    fea_id: 20,
    prm_create: 0,
    prm_view: 0,
    prm_edit: 0,
    prm_delete: 0
  },
  {
    fea_description: 'Tarefas',
    fea_id: 21,
    prm_create: 0,
    prm_view: 0,
    prm_edit: 0,
    prm_delete: 0
  },
  {
    fea_description: 'Relatórios',
    fea_id: 22,
    prm_create: 0,
    prm_view: 0,
    prm_edit: 0,
    prm_delete: 0
  },
  {
    fea_description: 'Importar dados',
    fea_id: 23,
    prm_create: 0,
    prm_view: 0,
    prm_edit: 0,
    prm_delete: 0
  },
  {
    fea_description: 'Exportar dados',
    fea_id: 24,
    prm_create: 0,
    prm_view: 0,
    prm_edit: 0,
    prm_delete: 0
  },
  {
    fea_description: 'Configurações',
    fea_id: 25,
    prm_create: 0,
    prm_view: 0,
    prm_edit: 0,
    prm_delete: 0
  },
  {
    fea_description: 'Perfil de Acesso',
    fea_id: 26,
    prm_create: 0,
    prm_view: 0,
    prm_edit: 0,
    prm_delete: 0
  }
  // Adicione mais permissões conforme necessário
];

// Componente que exibe uma categoria de permissões
const PermissionCategory: React.FC<{
  title: string;
  permissions: UserPermission[];
  onPermissionChange: (id: number, field: keyof UserPermission, value: number) => void;
}> = ({ title, permissions, onPermissionChange }) => {
  return (
    <fieldset className="div-info-fieldset">
      <legend className="div-info-legend">{title}</legend>
      <div className="div-info-grid-container">
        {permissions.map((permission) => (
          <div key={permission.fea_id} className="div-info-permission-item">
            <span className="div-info-permission-title">{permission.fea_description}</span>
            <label className="div-info-label">
              <input
                type="checkbox"
                checked={permission.prm_create === 1}
                onChange={(e) =>
                  onPermissionChange(permission.fea_id, 'prm_create', e.target.checked ? 1 : 0)
                }
                className="div-info-checkbox"
              />
              Incluir
            </label>
            <label className="div-info-label">
              <input
                type="checkbox"
                checked={permission.prm_view === 1}
                onChange={(e) =>
                  onPermissionChange(permission.fea_id, 'prm_view', e.target.checked ? 1 : 0)
                }
                className="div-info-checkbox"
              />
              Visualizar
            </label>
            <label className="div-info-label">
              <input
                type="checkbox"
                checked={permission.prm_edit === 1}
                onChange={(e) =>
                  onPermissionChange(permission.fea_id, 'prm_edit', e.target.checked ? 1 : 0)
                }
                className="div-info-checkbox"
              />
              Editar
            </label>
            <label className="div-info-label">
              <input
                type="checkbox"
                checked={permission.prm_delete === 1}
                onChange={(e) =>
                  onPermissionChange(permission.fea_id, 'prm_delete', e.target.checked ? 1 : 0)
                }
                className="div-info-checkbox"
              />
              Excluir
            </label>
          </div>
        ))}
      </div>
    </fieldset>
  );
};

// Componente principal do aplicativo
const PerfilacessoAdd: React.FC = () => {
  const [permissionsData, setPermissionsData] = useState<UserPermission[]>(initialPermissionsData);

  // Função para lidar com mudanças nas permissões
  const handlePermissionChange = (id: number, field: keyof UserPermission, value: number) => {
    setPermissionsData((prevPermissions) =>
      prevPermissions.map((permission) =>
        permission.fea_id === id ? { ...permission, [field]: value } : permission
      )
    );
  };

  const savePermissions = async () => {

    console.log(permissionsData)

  };

  const navigate = useNavigate();

  const [qualTitulo, setQualTitulo] = useState('')
  const [qualGrid, setQualGrid] = useState(<></>)
  const [openModalIncluirNovo, setOpenModalIncluirNovo] = useState(false)

  const [openModal, setOpenModal] = useState(false)
  const [qualAlert, setQualAlert] = useState(<></>)

  const [active, setActive] = useState(true)
  const [integrationid, setIntegrationId] = useState('')
  const [description, setDescription] = useState('')

  const [descriptionDisabled, setDescriptionDisabled] = useState(false)
  const [integrationidDisabled, setIntegrationidDisabled] = useState(false)

  const [confirmarDisabled, setConfirmarDisabled] = useState(false)
  const [cancelarDisabled, setCancelarDisabled] = useState(false)
  const [activeDisabled, setActiveDisabled] = useState(false)
  const [optionsPermissions, setOptionsPermissions] = useState([])

  useEffect(() => {


    setQualAlert(<div className='div-alert'><Loading /></div>)
    setOpenModal(true)

    validatToken();

  }, []);


  async function validatToken() {

    let ret: AxiosResponse;
    let valor: any;

    const _user = secureLocalStorage.getItem('t')

    api.defaults.headers.common['Authorization'] = _user as ''

    try {

      ret = await api.get("/status/validar")

      if (ret.data.status === 200) {


        secureLocalStorage.setItem('t', ret.data.data);
        secureLocalStorage.setItem('acp', ret.data.decodedToken.acp_integrationid);
        secureLocalStorage.setItem('n', ret.data.decodedToken.name);
        secureLocalStorage.setItem('iag', ret.data.decodedToken.id);
        secureLocalStorage.setItem('cy', ret.data.decodedToken.company);


        if (ret.data.decodedToken.acp_integrationid === 'DESENVOLVEDOR' || ret.data.decodedToken.acp_integrationid === 'ADMINISTRADOR') {



        } else if (ret.data.decodedToken.acp_integrationid === 'CONSULTOR') {



        }

        setOpenModal(false)

      await GetPermissions()

      }

    } catch (e: any) {

      if (e.response === undefined) {
        setQualAlert(<div className='div-alert'>
          <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Sem comunicação com o servidor.</span></div>
          <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
        </div>)

        setOpenModal(true)
      } else
        if (e.response.status === 403) {

          setQualAlert(<div className='div-alert'>
            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
            <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado.</span></div>
            <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
          </div>)

          setOpenModal(true)

        } else {
          setQualAlert(<div className='div-alert'>
            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
            <div className='div-info-alert'><span className="info-alert">Não foi possível concluir sua solicitação.</span></div>
            <div className='btn-alert' onClick={fecharModal2}><span className="btn-alert-lab">OK</span></div>
          </div>)

          setOpenModal(true)
        }
    }

  }


  async function GetPermissions() {
		let ret: AxiosResponse;
		let valor: any;
		try {
			ret = await api.get("permissions/verificacao")
			if (ret.data.status === 200) {
				valor = ret.data.data;

				setOptionsPermissions(valor)

			}
		} catch (e) {
			setOptionsPermissions([])
		}
	}

  function fecharModal2() {
    setOpenModal(false)
  }

  function fecharModal() {

    secureLocalStorage.removeItem('t')
    secureLocalStorage.removeItem('acp')
    secureLocalStorage.removeItem('n')
    secureLocalStorage.removeItem('iag')
    navigate('/login');
    setOpenModal(false)

  }

  function ValitarActive() {
    let e = document.getElementById("checkbox-active") as HTMLInputElement;

    if (e.checked) {

      setActive(true)
    } else {

      setActive(false)
    }
  }


  function finalizarModal1() {
    navigate('/accessprofile');
  }

  function finalizarModal2() {
    setOpenModal(false)
  }


  function cancelar() {
    navigate('/accessprofile');
  }

  function fecharOpenModalIncluirNovo() {
    setOpenModalIncluirNovo(false)
  }

  async function confirmar() {
    console.log(permissionsData)

    setQualAlert(<div className='div-alert'><Loading /></div>)
    setOpenModal(true)

    const Filtrardescription = optionsPermissions.filter(
      (e: any) => {
        return e.acp_description.toLowerCase() === description .toLowerCase()
      }
    );

    const FiltrarIntegrationid = optionsPermissions.filter(
      (e: any) => {
        return e.acp_integrationid.toLowerCase() === integrationid.toLowerCase()
      }
    );

    if (description === '') {

      setQualAlert(<div className='div-alert'>
        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
        <div className='div-info-alert'><span className="info-alert">Digite a descrição.</span></div>
        <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
      </div>)


    } else
      if (integrationid === '') {

        setQualAlert(<div className='div-alert'>
          <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Digite o ID para Integração.</span></div>
          <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
        </div>)


      } else if (Filtrardescription.length > 0) {

        setQualAlert(<div className='div-alert'>
          <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Descrição já está em uso.</span></div>
          <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
        </div>)


      } else if (FiltrarIntegrationid.length > 0) {

        setQualAlert(<div className='div-alert'>
          <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">ID para Integração já está em uso.</span></div>
          <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
        </div>)


      }else {

        const dados = [{
          acp_active: active,
          acp_description: description,
          acp_integrationid: integrationid,
        }]

        let ret: AxiosResponse;

        try {
          ret = await api.post("permissions/insert", {
            dadosagenda: dados,
            permissionsData: permissionsData
          });
          if (ret.data.status === 200) {

            setQualAlert(<div className='div-alert'>
              <span className="fa-regular fa-circle-check ControleIconAlert"></span>
              <div className='div-info-alert'><span className="info-alert">Cadastro realizado com sucesso.</span></div>
              <div className='btn-alert' onClick={finalizarModal1}><span className="btn-alert-lab">OK</span></div>
            </div>)
          }
        } catch (e: any) {


          setQualAlert(<div className='div-alert'>
            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
            <div className='div-info-alert'><span className="info-alert">Não foi possivel concluir o cadastramento.</span></div>
            <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
          </div>)
        }

      }
  }

  return (
    <>
      <Nav />
      <div className="main-content">
        <Header />
        <main>
          <div className='containerPrincipal'>
            <div className="title-Page">
              <div className="title-text">
                <div className="size-x circle">1</div>
                <h2>Informações Gerais</h2>
              </div>
              <hr />
            </div>

            <div className="class-checkbox">
              <input type="checkbox" id="checkbox-active" disabled={activeDisabled} checked={active} onChange={ValitarActive} />
              <h4 className="lab-ativo">Ativo</h4>
            </div>
            <div className="container-cad-prod4">

              <div id="content1">
                <div className="input-box-ativos">
                  <span className="details">Descrição *</span>
                  <input
                    type="text"
                    onChange={(e) => setDescription(e.target.value)}
                    value={description}
                    autoComplete="nope"
                    disabled={descriptionDisabled}
                  />
                </div>
              </div>
              <div id="content2">
                <div className="input-box-ativos">
                  <span className="details">ID para Integração *</span>
                  <input
                    type="text"
                    onChange={(e) => setIntegrationId(e.target.value)}
                    value={integrationid}
                    autoComplete="nope"
                    disabled={integrationidDisabled}
                  />
                </div>
              </div>

            </div>


            <PermissionCategory
              title="Permissões"
              permissions={permissionsData}
              onPermissionChange={handlePermissionChange}
            />


            <div className="Bt-button-cad">
              <button type="submit" onClick={confirmar} disabled={confirmarDisabled}>
                <i className="fa fa-check-circle icon-ok-btn"></i> Salvar
              </button>
              <button type="submit" onClick={cancelar} disabled={cancelarDisabled}>
                <i className="fa fa-times icon-cancelar-btn"></i> Cancelar
              </button>
            </div>

          </div>
        </main>
      </div>


      <ModalIncluirNovo isOpen={openModalIncluirNovo} setModalOpen={() => setOpenModalIncluirNovo(!openModalIncluirNovo)}>
        <>
          <div className='title-modal-incluir'>
            <h1 className="modal-IncluirNovo-title">{qualTitulo}</h1>

            <div onClick={fecharOpenModalIncluirNovo}>
              <button className="fa fa-times icone-modal-IncluirNovo" />
            </div>
          </div>

          <div className='scrol-modal'>
            {qualGrid}
          </div>
        </>
      </ModalIncluirNovo>

      <ModalPrincipal isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)}>

        <div className='container-modal-alert'>
          {qualAlert}
        </div>
      </ModalPrincipal>

    </>
  );
};

export default PerfilacessoAdd;
