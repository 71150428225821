import React, { useEffect, useState } from 'react'
import Nav from "../../components/Nav";
import Header from "../../components/Header";
import { AxiosResponse } from "axios";
import api from "../../Services/api";
import Loading from '../../components/Loading';
import VisualizacaoMovimentacao from '../../components/VisualizacaoMovimentacao'
import GridMovimentacao from '../../components/GridMovimentacao'
import secureLocalStorage from "react-secure-storage";
import ModalPrincipal from '../../components/ModalAlert/modal'
import { useNavigate } from 'react-router';
import ModalIncluirNovo from '../../components/ModalIncluirNovo/modal'

function Movimentacoes() {

    const navigate = useNavigate();

    const [qualInf, setQualInf] = useState(<></>)
    const [titulo, setTitulo] = useState('');
    const [exibirGrid, setExibirGrid] = useState(<></>);

    const [openModal, setOpenModal] = useState(false)
    const [qualAlert, setQualAlert] = useState(<></>)
    const [openModalIncluirNovo, setOpenModalIncluirNovo] = useState(false)

    useEffect(() => {

       // setQualAlert(<div className='div-alert'><Loading /></div>)
      //  setOpenModal(true)

        validatToken();
    }, []);

    async function validatToken() {

        let ret: AxiosResponse;
        let valor: any;

        const _user = secureLocalStorage.getItem('t')

        api.defaults.headers.common['Authorization'] = _user as ''

        try {

            ret = await api.get("/status/validar")

            if (ret.data.status === 200) {

                secureLocalStorage.setItem('t', ret.data.data);
                secureLocalStorage.setItem('acp', ret.data.decodedToken.acp_integrationid);
                secureLocalStorage.setItem('n', ret.data.decodedToken.name);
                secureLocalStorage.setItem('iag', ret.data.decodedToken.id);
                secureLocalStorage.setItem('cy', ret.data.decodedToken.company);

                setExibirGrid(<GridMovimentacao 
                    Iconeadd={ChamarCadastro}
                    Iconeditar={Editar} 
                    iconeExcluir={ModalDeletar}
                    th1='ID'
                    th2='ID Ativo'
                    th3='Descrição'
                    th4='Tipo'
                    th5='Subcategoria'
                    th6='Ações'
                    endPoint='movement/buscarmovement'
                    consult='N'
                    col1='0'
                    col2='1'
                    col3='2'//2
                    col4='3'
                    col5='5'
                    col6='6'
                    ambiente='%'
                    converter=""
                    VisualizarMovi={VisualizarMovi}
                    form="movimentacao"
                    fromAdd='/movement/add'
                    pag="movement"
                    buscarmovement='movement/buscarmovement'
                    unity='unity/exibir'
                    fecharModal={fecharModal}
                />)
                
                setOpenModal(false)
            }

        } catch (e: any) {

            if (e.response.data.status === 403) {

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
                    <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)

            }
        }

    }

    function fecharModal() {

        secureLocalStorage.removeItem('t')
        secureLocalStorage.removeItem('acp')
        secureLocalStorage.removeItem('n')
        secureLocalStorage.removeItem('iag')
        navigate('/login');
        setOpenModal(false)

    }


    async function VisualizarMovi(obj: any) {
        let ret: AxiosResponse;
        let valor: any;

        setOpenModal(true)
        setQualAlert(<div className='div-alert'><Loading /></div>)

        try {
            ret = await api.get("movement/listarmovementproduto", {
                params: {
                    id: obj.ast_id
                }
            })
            if (ret.data.status === 200) {

                valor = ret.data.data;
                setOpenModal(false)
                setTitulo('Histórico de Movimentação')
                setOpenModalIncluirNovo(true)
                setQualInf(<VisualizacaoMovimentacao
                    obj={valor}

                />)

            } else {

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Ocorreu um erro ao visualizar.</span></div>
                    <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                </div>)
                setOpenModal(true)

            }
        } catch (e) {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Ocorreu um erro ao visualizar.</span></div>
                <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
            </div>)
            setOpenModal(true)

        }

    }

    function finalizarModal2() {
        setOpenModal(false)
    }



    function ChamarCadastro() {

    }
    async function Editar(obj: any) {

    }


    function ModalDeletar(obj: any) {

    }

    function fecharOpenModalIncluirNovo() {
        setOpenModalIncluirNovo(false)
    }

    return (
        <>
            <Nav />
            <div className="main-content">
                <Header />
                <main>
                    <div className='containerPrincipal'>
                        <div className='ContainerBody'>
                            <div className='div-titulo-pages'>
                                <span className='fa fa-exchange-alt' />
                                <h1 className='div-titulo-pages-text'>Movimentações</h1>
                            </div>
                        </div>
                        <div className='grid-container-principal'>
                            {exibirGrid}
                        </div>
                    </div>
                </main>
            </div>


            <ModalIncluirNovo isOpen={openModalIncluirNovo} setModalOpen={() => setOpenModalIncluirNovo(!openModalIncluirNovo)}>
                <>
                    <div className='title-modal-incluir'>
                        <h1 className="modal-IncluirNovo-title">{titulo}</h1>
                        <div onClick={fecharOpenModalIncluirNovo}>
                            <button className="fa fa-times icone-modal-IncluirNovo" data-dismiss="modal" aria-label="Close" onClick={fecharOpenModalIncluirNovo}></button>
                        </div>
                    </div>

                    <div className='scrol-modal'>
                        {qualInf}
                    </div>
                </>
            </ModalIncluirNovo>

            <ModalPrincipal isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)}>
                <div className='container-modal-alert'>
                    {qualAlert}
                </div>
            </ModalPrincipal>

        </>
    );

}

export default Movimentacoes;