import React from "react";
import './index.css';
import {
  FaTrash,
} from "react-icons/fa";
import { isTemplateMiddle } from "typescript";

const GridItem = ({ item, onDelete }) => {
  return (
    <tbody>
      <tr className="TR-Coluna-itens">
        <td className="Coluna-itens">{item.int_productcode}</td>
        <td className="Coluna-itens">{item.int_productdescription}</td>
        <td className="Coluna-itens">{item.pdc_id}</td>
        <td className="Coluna-itens">{item.int_theamount}</td>
        <td className="Coluna-itens">{item.int_unitvalue}</td>
        <td className="Coluna-icone-itens">
          <div onClick={() => onDelete(item.int_id || item.id)}>
            <span className="fa fa-trash icon-delete-itens"></span>
            </div>
        </td>
      </tr>
    </tbody>
  );
};

export default GridItem;
