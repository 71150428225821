
import React, { useState, useEffect } from 'react'
import Nav from "../../components/Nav";
import Header from "../../components/Header";
import './index.css'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import AtividadesAtivo from '../../components/AtividadesAtivo'
import { AxiosResponse } from "axios";
import api from '../../Services/api'
import GridEditInventario from '../../components/GridEditInventario'
import GridRelacionamento from '../../components/GridRelacionamento'
import GridExibirAlt from '../../components/GridExibirAlt'
import Loading from '../../components/Loading/index'
import { MdOutlineAddCircle } from 'react-icons/md';
import QRCode from 'react-qr-code'
import QRCodeLink from 'qrcode'
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router'
import Select from 'react-select'
import ModalIncluirNovo from '../../components/ModalIncluirNovo/modal'
import ModalAlert from '../../components/ModalAlert/modal'
import secureLocalStorage from "react-secure-storage";

function InventarioVisualizacao() {
  const navigate = useNavigate();
  let { id } = useParams();
  const [openModalIncluirNovo, setOpenModalIncluirNovo] = useState(false)
  const [userInv, setUserInv] = useState([] as any);
  const [tabIndex, setTabIndex] = useState(-1);
  const [titulo, setTitulo] = useState('');
  const [tipoEntidade, setTipoEntidade] = useState('Ativos');
  const [dadosCategoria, setDadosCategoria] = useState([]);
  const [exibirCatAtivo, setExibirCatAtivo] = useState('S');
  const [exibirAnos, setExibirAnos] = useState('');
  const [exibirMeses, setExibirMeses] = useState('');
  const [categoriaAtivo, setCategoriaAtivo] = useState('');
  const [qualGrid, setQualGrid] = useState(<></>)
  const [qualGridRelacionamento, setQualGridRelacionamento] = useState(<></>)
  const [dadosRelacionamento, setDadosRelacionamento] = useState([]);
  const [qModalRelacionamento, setQmodalRelacionamento] = useState('')
  const [qModalOcorrencia, setQmodalOcorrencia] = useState('')
  const [qModalDespesas, setQmodalDespesas] = useState('')
  const [qModalMovimentacao, setQmodalMovimentacao] = useState('')
  const [openModal, setOpenModal] = useState(false)
  const [qualAlert, setQualAlert] = useState(<></>)
  //--------------dados visualização--------------------
  const [idInventorio, setIdInventorio] = useState('');
  const [descricao, setDescricao] = useState('');
  const [categoria, setCategoria] = useState('');
  const [subcategoria, setSubcategoria] = useState('');
  const [status, setStatus] = useState('');
  const [produto, setProduto] = useState('');
  const [fabricante, setFabricante] = useState('');
  const [marca, setMarca] = useState('');
  const [modelo, setModelo] = useState('');
  const [numeroSerie, setNumeroSerie] = useState('');
  const [alternativeidentifier, setAlternativeidentifier] = useState('');
  const [notaFiscal, setNotaFiscal] = useState('');
  const [idnotaFiscal, setIdnotaFiscal] = useState('');
  const [custo, setCusto] = useState('');
  const [garantiaAno, setGarantiaAno] = useState('');
  const [garantiaMes, setGarantiaMes] = useState('');
  const [dataAquisicao, setDataAquisicao] = useState('');
  const [unt_id, setUnt_id] = useState('');
  const [localizacaoContrato, setLocalizacaoContrato] = useState('')
  const [localizacaoUnidade, setLocalizacaoUnidade] = useState('')
  const [localizacaoAndar, setLocalizacaoAndar] = useState('')
  const [localizacaoLocal, setLocalizacaoLocal] = useState('')
  //--------------dados visualização--------------------

  //--------------dados ocorrencia--------------------
  const [idOcorrencia, setIdOcorrencia] = useState('');
  const [solicitanteOcorrencia, setSolicitanteOcorrencia] = useState('');
  const [condicaoAtivoOcorrencia, setCondicaoAtivoOcorrencia] = useState('');
  const [observationOcorrencia, setObservationOcorrencia] = useState('');
  //--------------dados ocorrencia--------------------

  //--------------dados Custos--------------------
  const [idCustos, setIdCustos] = useState('');
  const [tipoManutencao, setTipoManutencao] = useState('');
  const [valor, setValor] = useState('');
  const [observationCustos, setObservationCustos] = useState('');
  //--------------dados Custos--------------------

  //--------------dados Movimentacao--------------------
  const [statusMovimentacao, setStatusMovimentacao] = useState('');
  const [contractMovimentacao, setContractMovimentacao] = useState('');
  const [unityMovimentacao, setUnityMovimentacao] = useState('');
  const [pavementMovimentacao, setPavementMovimentacao] = useState('');
  const [optionspavement, setOptionspavement] = useState([])
  const [optionsDepartment, setOptionsDepartment] = useState([])
  const [departmentMovimentacao, setDepartmentMovimentacao] = useState('');
  const [userMovimentacao, setUserMovimentacao] = useState('');
  const[loc_id, setLoc_id] = useState('')
  const [contractdest, setContractdest] = useState('');
  const [destiny, setDestiny] = useState('');
  const [dadosUnidadedest, setDadosUnidadedest] = useState([]);
  const [dadosPavementdest, setDadosPavementdest] = useState([]);
  const [dadosDepartmendest, setDadosDepartmendest] = useState([]);
  const [destinationPavement, setDestinationPavement] = useState('');
  const [destinationDepartmen, setDestinationDepartmen] = useState('');
  const [targetUser, setTargetUser] = useState('');
  const [observationMovi, setObservationMovi] = useState('');
  const [mac, setMac] = useState('');
  const [serialNumber, setSerialNumber] = useState('');
  const [tombamento, setTombamento] = useState('');
  const [serialNumberIni, setSerialNumberIni] = useState('');
  const [tombamentoIni, setTombamentoIni] = useState('');
  const [subCat, setSubCat] = useState('');
  const [statusIni, setStatusIni] = useState('');
  const [alternativeIdentifierIni, setAlternativeIdentifierIni] = useState('');
  //--------------dados Movimentacao--------------------
  //--------------dados atividade--------------------
  const [exibiAtividade, setExibiAtividade] = useState(<></>);
  //--------------dados atividade--------------------
  const [qrcodeLink, setQrcodeLink] = useState('')
  const [gerarQrcode, setGerarQrcode] = useState('')
  const [link, setLink] = useState('')

  const [isDisabledContract, setIsDisabledContract] = useState(true)
  const [optionContract, setOptionContract] = useState([])
  const [isDisabledUnity, setIsDisabledUnity] = useState(true)
  const [isDisabledTipoMan, setIsDisabledTipoMan] = useState(true)
  const [optionUnity, setOptionUnity] = useState([])
  const [optionUnitydest, setOptionUnitydest] = useState([])
  const [optionsAtivo, setOptionsAtivo] = useState([])

  const [optionTipoMan, setOptionTipoMan] = useState([
    {
      value: "estoque",
      label: "Em estoque"
    },
    {
      value: "contrato",
      label: "Em contrato"
    },
  ])

  const [optiontipoEntidade, setOptiontipoEntidade] = useState([
    {
      value: "Ativos",
      label: "Ativos"
    }
  ])

  const [optiontipo, setOptiontipo] = useState([
    {
      value: "Manutenção",
      label: "Manutenção"
    },
    {
      value: "Conserto",
      label: "Conserto"
    }
  ])

  const [solicitanteOcorrenciaDisabled, setsolicitanteOcorrenciaDisabled] = useState(false)
  const [observationOcorrenciaDisabled, setSobservationOcorrenciaDisabled] = useState(false)
  const [insertOcorrenciaDisabled, setInsertOcorrenciaDisabled] = useState(false)
  const [tipoManutencaoDisabled, setTipoManutencaoDisabled] = useState(false)
  const [valorDisabled, setValorDisabled] = useState(false)
  const [observationCustosDisabled, setbservationCustosDisabled] = useState(false)
   const [insertCustosDisabled, setInsertCustosDisabled] = useState(false)

  useEffect(() => {

    setQualAlert(<div className='div-alert'><Loading /></div>)
    setOpenModal(true)

    validatToken();

  }, []);

  async function validatToken() {

    let ret: AxiosResponse;
    let valor: any;

    const _user = secureLocalStorage.getItem('t')

    api.defaults.headers.common['Authorization'] = _user as ''

    try {

      ret = await api.get("/status/validar")

      if (ret.data.status === 200) {

        secureLocalStorage.setItem('t', ret.data.data);
        secureLocalStorage.setItem('acp', ret.data.decodedToken.acp_integrationid);
        secureLocalStorage.setItem('n', ret.data.decodedToken.name);
        secureLocalStorage.setItem('iag', ret.data.decodedToken.id);
        secureLocalStorage.setItem('cy', ret.data.decodedToken.company);



        if (ret.data.decodedToken.acp_integrationid === 'DESENVOLVEDOR' || ret.data.decodedToken.acp_integrationid === 'ADMINISTRADOR') {



        } else if (ret.data.decodedToken.acp_integrationid === 'CONSULTOR') {
          setsolicitanteOcorrenciaDisabled(true)
          setSobservationOcorrenciaDisabled(true)
          setInsertOcorrenciaDisabled(true)
          setTipoManutencaoDisabled(true)
          setValorDisabled(true)
          setbservationCustosDisabled(true)
          setInsertCustosDisabled(true)

        }

        await inventoryditar('')

      }

    } catch (e: any) {

      if (e.response.data.status === 403) {

        setQualAlert(<div className='div-alert'>
          <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
          <div className='btn-alert' onClick={fecharModalToken}><span className="btn-alert-lab">OK</span></div>
        </div>)


      }
    }

  }

  function fecharModalToken() {
    secureLocalStorage.removeItem('t')
    secureLocalStorage.removeItem('acp')
    secureLocalStorage.removeItem('n')
    secureLocalStorage.removeItem('iag')
    navigate('/login');
    setOpenModal(false)
  }

  async function inventoryditar(tab: any) {

    setQualGridRelacionamento(<div className='div-alert'><Loading /></div>)

    let ret: AxiosResponse;
    let valor: any;

    if (/^\d+$/.test(id || '') === true) {

      try {
        ret = await api.get("asset/visaogeralasset", {
          params: {
            id: id
          }
        })
        if (ret.data.status === 200) {



          valor = ret.data.data[0];


          setLoc_id(valor.loc_id)
          setUnt_id(ret.data.data[0].unt_id)
          setUserInv(ret.data.data[0])
          setIdInventorio(valor.ast_id || '');
          setDescricao(valor.ast_description || '');
          setCategoria(valor.ctg_description || '');
          setSubcategoria(valor.stg_description || '');
          setStatus(valor.ssa_description || '');
          setProduto(valor.pdc_description || '');
          setFabricante(valor.mfr_description || '');
          setMarca(valor.brn_description || '');
          setModelo(valor.mde_description || '');
          setNumeroSerie(valor.ast_serialnumber || '');
          setNotaFiscal(valor.ast_invoicenumber || '');
          setIdnotaFiscal(String(valor.inc_id || ''));
          setCusto(valor.ast_cust || '');
          setSubCat(String(valor.stg_id || ''))
          setStatusIni(String(valor.ssa_id || ''))

          setLocalizacaoContrato(valor.ctc_description || '')
          setLocalizacaoUnidade(valor.untdescricao || '')
          setLocalizacaoAndar(valor.loc_pavement || '')
          setLocalizacaoLocal(valor.loc_description || '')

          if (valor.ast_warrantymonth !== '0') {
            setGarantiaMes(valor.ast_warrantymonth || '');
          } else {
            setGarantiaMes('');
          }

          setMac(valor.ast_mac || '');
          setSerialNumber(valor.ast_serialnumber || '');
          setTombamento(valor.ast_tombamento || '');
          setSerialNumberIni(valor.ast_serialnumber || '');
          setTombamentoIni(valor.ast_tombamento || '');
          setAlternativeidentifier(valor.ast_alternativeidentifier || '');
          setAlternativeIdentifierIni(valor.ast_alternativeidentifier || '');
          setGerarQrcode('N')

          if (sessionStorage.getItem("QUALTAB") === '0' || sessionStorage.getItem("QUALTAB") === null || sessionStorage.getItem("QUALTAB") === undefined || sessionStorage.getItem("QUALTAB") === '') {
            setTabIndex(0)
          } else
            if (sessionStorage.getItem("QUALTAB") === '1') {
              setTabIndex(1)
              ChamaGridRelacionamento();
            } else
              if (sessionStorage.getItem("QUALTAB") === '2') {
                setTabIndex(2)
                ChamaGridOcorrencia();
              } else
                if (sessionStorage.getItem("QUALTAB") === '3') {
                  setTabIndex(3)
                  ChamaGridDespesas();
                }
          if (tab === '5' || sessionStorage.getItem("QUALTAB") === '5') {

            GetAtivo();
            setTabIndex(5)

            ChamaGridMovimentacao(String(valor.ctc_id || ''),
              String(valor.ctc_description || ''),
              String(valor.unt_id || ''),
              String(valor.untdescricao || ''),
              String(valor.loc_pavement || ''),
              String(valor.loc_id || ''),
              String(valor.ast_usedby || '')
            );
          }

          if (sessionStorage.getItem("QUALTAB") === '4') {
            chamarGridMovimentacao('4')
          }

          if (valor.ast_warrantymonth !== null) {

            if (valor.ast_warrantymonth === '1') {
              setExibirMeses('Mês')
            } else if (Number(valor.ast_warrantymonth) > 1) {
              setExibirMeses('Meses')
            }

          }

          if (valor.ast_issuedate === '' || valor.ast_issuedate === null) {
            setDataAquisicao('')
          } else {
            //2023-02-07
            setDataAquisicao(valor.ast_issuedate.split("-").reverse().join("/"))
          }

        } else {
          navigate('/asset/visualizar/' + id + '/n');
        }
      } catch (e) {
        navigate('/asset/visualizar/' + id + '/n');
      }

    } else {
      navigate('/asset/visualizar/' + id + '/n');
    }

    setOpenModal(false)

  }



  async function chamarGridMovimentacao(tab: any) {

    setQualGridRelacionamento(<div className='div-alert'><Loading /></div>)

    let ret: AxiosResponse;
    let valor: any;

    try {
      ret = await api.get("asset/visaogeralasset", {
        params: {
          id: id
        }
      })
      if (ret.data.status === 200) {
        valor = ret.data.data[0];

        GetAtivo();
        setTabIndex(4)

        ChamaGridMovimentacao(String(valor.ctc_id || ''),
          String(valor.ctc_description || ''),
          String(valor.unt_id || ''),
          String(valor.untdescricao || ''),
          String(valor.loc_pavement || ''),
          String(valor.loc_id || ''),
          String(valor.ast_usedby || '')
        );






      }
    } catch (e) {

    }

  }

  async function GetAtivo() {

    let ret: AxiosResponse;
    let valor: any;
    try {
      ret = await api.get("asset/verificacaoasset")

      if (ret.data.status === 200) {
        valor = ret.data.data;

        setOptionsAtivo(valor)

      }
    } catch (e) {

      setOptionsAtivo([])
    }
  }


  function fecharModal() {
    setOpenModal(false)
  }

  function BuscarGrid() {



    if (categoriaAtivo === '') {

      setQualAlert(<div className='div-alert'>
        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
        <div className='div-info-alert'><span className="info-alert">Selecione a categoria do ativo.</span></div>
        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
      </div>)

      setOpenModal(true)

    } else {
      setQualGrid(<><GridRelacionamento
        th1='Adicionar'
        th2='ID'
        th3='Descrição'
        th4='Numero de Série'
        th5='Tombamento'
        th6=''
        endPoint='asset/listarassetrelacionamentocategoria'
        consult='N'
        col1='1'
        col2='2'
        col3='3'
        id={id}
        outParams='S'
        params={categoriaAtivo}
        DadosRelacionamento={DadosRelacionamento}
        InsertRelacionamento={InsertRelacionamento}
        cancelar={cancelar}
      /></>)
    }

  }


  function ValidarTipoManutencao(tipo: any) {

    if (tipo === 'Selecione...') {
      setTipoManutencao('');
    } else {
      setTipoManutencao(tipo);
    }
  }

  function qCategoria(categoria: any) {

    setQualGrid((<></>))
    if (categoria === '') {
      setCategoriaAtivo('')

    } else {
      setCategoriaAtivo(categoria)
    }

  }

  function handleQrcode() {
    setLink(String(alternativeidentifier))
    handleGenerate('teste123')
    setTitulo('Gerar QR Code');
    setTipoEntidade('Ativos');
    setCategoriaAtivo("");
    setQmodalRelacionamento('N')
    setExibirCatAtivo('N')
    setQmodalOcorrencia('N')
    setQmodalMovimentacao('N');
    setQmodalDespesas('N');
    setGerarQrcode('S')

    setOpenModalIncluirNovo(true)
  }


  function handleGenerate(link_url: any) {
    QRCodeLink.toDataURL(link_url, {
      width: 600,
      margin: 3
    }, function (err, url) {
      setQrcodeLink(url)
    })
  }

  function TipoEnt(entidade: any) {

    if (entidade === '') {
      setTipoEntidade('');
      setExibirCatAtivo('N')
    } else {

      setTipoEntidade(entidade);
      if (entidade == 'Ativos') {
        setExibirCatAtivo('S')
      } else if (entidade == 'Agentes') {
        setExibirCatAtivo('N')
      } else if (entidade == 'Departamentos') {
        setExibirCatAtivo('N')
      } else if (entidade == 'Software') {
        setExibirCatAtivo('N')
      }
    }
  }

  //---------------------------------------------------
  async function ChamaGridRelacionamento() {
    sessionStorage.setItem("QUALTAB", '1');
    setQualGridRelacionamento(<div className='div-alert'><Loading /></div>)

    let ret: AxiosResponse;
    let valor: any;
    try {
      ret = await api.get("relationship/listar", {
        params: {
          id: id
        }
      })
      if (ret.data.status === 200) {

        setQualGridRelacionamento(<><GridExibirAlt
          iconeExcluir={ModalDeletar}
          Iconeadd={Relacionamento}
          th1='Descrição'
          th2='Categoria'
          th3='ID Ativo'
          endPoint='relationship/listar'
          consult='N'
          col1={0}
          col2={2}
          col3={1}
          col4={3}
          ambiente={id}
          conc='S'
          acao='S'
        /></>)

      } else {
        setQualGridRelacionamento(<div className='panel-content-visu-aviso'>
          <h1 className='panel-content-aviso'>Não há Relacionamentos Disponíveis.</h1>
          <h1 className='panel-content-aviso-add' onClick={Relacionamento}>Adicionar Novo</h1>
        </div>)
      }
    } catch (e) {
      setQualGridRelacionamento(<div className='panel-content-visu-aviso'>
        <h1 className='panel-content-aviso'>Não há Relacionamentos Disponíveis.</h1>
        <h1 className='panel-content-aviso-add' onClick={Relacionamento}>Adicionar Novo</h1>
      </div>)
    }

  }

  function ModalDeletar(obj: any) {

    if(secureLocalStorage.getItem('acp') !== 'CONSULTOR'){
 
    setQualAlert(<div className='div-alert'>
      <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
      <div className='div-info-alert-atencao'><span className="info-alert-atencao">Deseja deletar o registro?</span></div>
      <div className='div-info-alert'><span className="info-alert">Você não poderá reverter.</span></div>

      <div className='btn-alert-div'>
        <span className='btn-alert-esc' onClick={() => deletarRelacionamento(obj.rls_id, obj.rls_assetsecondary, obj.rls_mainasset)}>
          <span className="btn-alert-lab">SIM</span>
        </span>
        <span className='btn-alert-dir' onClick={finalizarModal2}>
          <span className="btn-alert-lab">NÃO</span>
        </span>
      </div>
    </div>)

    setOpenModal(true)
    }
  }

  function finalizarModal2() {
    setOpenModal(false)
  }

  async function deletarRelacionamento(id: any, idsecundario: any, idInventoryMain: any) {

    let ret: AxiosResponse;

  //  setQualAlert(<div className='div-alert'><Loading /></div>)
  //  setOpenModal(true)

    try {
      ret = await api.delete("relationship/delete", {
        data: {
          id: id,
          idsecundario: idsecundario,
          idInventoryMain: idInventoryMain,
          age_id_insert: secureLocalStorage.getItem('iag'),
        }
      });

      if (ret.data.status === 200) {

        sessionStorage.setItem("QUALTAB", '1');
        // window.location.reload();
        ChamaGridRelacionamento();
        setOpenModal(false);



      }
    } catch (e: any) {

      setQualAlert(<div className='div-alert'>
        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
        <div className='div-info-alert'><span className="info-alert">Não foi possivel Excluir.</span></div>
        <div className='btn-alert' onClick={fecharModal2}><span className="btn-alert-lab">OK</span></div>
      </div>)

      setOpenModal(true)
    }
  }

  function fecharModal3() {

    sessionStorage.setItem("QUALTAB", '1');
    // window.location.reload();
    ChamaGridRelacionamento();
    setOpenModal(false);
  }

  function fecharModal2() {

    sessionStorage.setItem("QUALTAB", '1');
    // window.location.reload();
    ChamaGridRelacionamento();
    setOpenModal(false)
  }


  function Relacionamento() {

    if(secureLocalStorage.getItem('acp') !== 'CONSULTOR'){
    if (statusIni === '1' || statusIni === '3') {

      setQualAlert(<div className='div-alert'>
        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
        <div className='div-info-alert'><span className="info-alert">Só é possível adicionar relacionamento quando o ativo está estiver sendo utilizado.</span></div>
        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
      </div>)

      setOpenModal(true)
    }
    else {
      setTitulo('Adicionar Relacionamento');
      setTipoEntidade('Ativos');
      setCategoriaAtivo("");
      setQmodalRelacionamento('S')
      setExibirCatAtivo('S')
      setQmodalOcorrencia('N')
      setQmodalMovimentacao('N');
      setGerarQrcode('N')
      setOpenModalIncluirNovo(true)
      GetCategoria();
      setQualGrid((<><GridRelacionamento
        th1='Adicionar'
        th2='ID'
        th3='Descrição'
        th4='Numero de Série'
        th5='Tombamento'
        th6=''
        endPoint='asset/listarassetrelacionamento'
        consult='N'
        col1='1'
        col2='2'
        col3='3'
        col4='0'
        id={id}
        outParams='N'
        DadosRelacionamento={DadosRelacionamento}
        InsertRelacionamento={InsertRelacionamento}
        cancelar={cancelar}
      /></>))
    }
  }

  }

  function cancelar() {
    sessionStorage.setItem("QUALTAB", '1');
    window.location.reload();
  }


  async function GetCategoria() {
    let ret: AxiosResponse;
    let valor: any;
    try {
      ret = await api.get("category/listar")
      if (ret.data.status === 200) {
        valor = ret.data.data;

        const arr = [] as any;
        let result = valor;
        result.map((user: any) => {
          return arr.push({ value: String(user.ctg_id), label: user.ctg_description });
        });

        setDadosCategoria(arr);
      }
    } catch (e) {
      setDadosCategoria([])
    }
  }



  function DadosRelacionamento(obj: any) {

    setDadosRelacionamento(obj)
  }


  async function InsertRelacionamento(obj: any) {

    setQualAlert(<div className='div-alert'><Loading /></div>)
    setOpenModal(true)

    let ret: AxiosResponse;
    let i = 0
    let newArray1 = []
    let newArray2 = []

 

    for (i = 0; i < obj.length; i++) {
      newArray1.push({
        age_id_insert: secureLocalStorage.getItem('iag'),
        rls_mainasset: id,
        rls_assetsecondary: obj[i].ast_id || null,
        ast_description: descricao,
      })

      newArray2.push({
        age_id_insert: secureLocalStorage.getItem('iag'),
        rls_mainasset: obj[i].ast_id || null,
        rls_assetsecondary: id,
        ast_description: obj[i].ast_description,
      })
    }

    var juntarArray = newArray1.concat(newArray2);

   

    if (juntarArray.length > 0) {
      try {
        ret = await api.post("relationship/insert", {
          data: juntarArray,
          id: id
        });
        if (ret.data.status === 200) {

          setQualAlert(<div className='div-alert'>
            <span className="fa-regular fa-circle-check ControleIconAlert"></span>
            <div className='div-info-alert'><span className="info-alert">O relacionamento foi incluido com sucesso.</span></div>
            <div className='btn-alert' onClick={fecharModal4}><span className="btn-alert-lab">OK</span></div>
          </div>)


          setOpenModal(true)



        }

      } catch (e: any) {

        setQualAlert(<div className='div-alert'>
          <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Não foi possivel realizar a alteração.</span></div>
          <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
        </div>)

        setOpenModal(true)

      }
    } else {

      setQualAlert(<div className='div-alert'>
        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
        <div className='div-info-alert'><span className="info-alert">Selecione ao menos um relacionamento.</span></div>
        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
      </div>)

      setOpenModal(true)

    }
  }

  function fecharModal4() {

    sessionStorage.setItem("QUALTAB", '1');
    window.location.reload();

  }
  //---------------------------------------------------------------------------------------

  function ValidarCondicaoAtivoOcorrencia(condicao: any) {

    if (condicao === 'Selecione...') {
      setCondicaoAtivoOcorrencia('');
    } else {
      setCondicaoAtivoOcorrencia(condicao);
    }
  }

  async function ChamaGridOcorrencia() {

    setQualGridRelacionamento(<div className='div-alert'><Loading /></div>)
    sessionStorage.setItem("QUALTAB", '2');
    let ret: AxiosResponse;
    let valor: any;
    try {
      ret = await api.get("occurrence/listar", {
        params: {
          id: id
        }
      })
      if (ret.data.status === 200) {
        setQualGridRelacionamento(<><GridExibirAlt 
          iconeExcluir={ModalDeletarOcorrencia} 
          Iconeditar={EditarOcorrencia} 
          Iconeadd={Ocorrencia}
          th1='Assunto'
          th2='Requisitante'
          th3='ID'
          endPoint='occurrence/listar'
          consult='S'
          col1={0}
          col2={5}
          col3={3}
          col4={0}
          ambiente={id}
          acao='S'
        /></>)

      } else {
        setQualGridRelacionamento(<div className='panel-content-visu-aviso'>
          <h1 className='panel-content-aviso'>Não há Ocorrências Disponíveis.</h1>
          <h1 className='panel-content-aviso-add' onClick={Ocorrencia}>Adicionar Ocorrências</h1>
        </div>)
      }
    } catch (e) {
      setQualGridRelacionamento(<div className='panel-content-visu-aviso'>
        <h1 className='panel-content-aviso'>Não há Ocorrências Disponíveis.</h1>
        <h1 className='panel-content-aviso-add' onClick={Ocorrencia}>Adicionar Ocorrências</h1>
      </div>)
    }
  
  }

  function ModalDeletarOcorrencia(obj: any) {

 if(secureLocalStorage.getItem('acp') !== 'CONSULTOR'){
    setQualAlert(<div className='div-alert'>
      <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
      <div className='div-info-alert-atencao'><span className="info-alert-atencao">Deseja deletar o registro?</span></div>
      <div className='div-info-alert'><span className="info-alert">Você não poderá reverter.</span></div>

      <div className='btn-alert-div'>
        <span className='btn-alert-esc' onClick={() => deletarRelacionamentoOcorrencia(obj.occ_id, obj.ast_id)}>
          <span className="btn-alert-lab">SIM</span>
        </span>
        <span className='btn-alert-dir' onClick={finalizarModal2}>
          <span className="btn-alert-lab">NÃO</span>
        </span>
      </div>
    </div>)

    setOpenModal(true)
 }

  }

  async function deletarRelacionamentoOcorrencia(id: any, idInventory: any) {

    let ret: AxiosResponse;

   
    try {
      ret = await api.delete("occurrence/delete", {
        data: {
          id: id,
          ast_id: idInventory || null,
          age_id: secureLocalStorage.getItem('iag'),
        }
      });
      if (ret.data.status === 200) {


        sessionStorage.setItem("QUALTAB", '2');
        window.location.reload();
        setOpenModal(false)


      }
    } catch (e: any) {

      setQualAlert(<div className='div-alert'>
        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
        <div className='div-info-alert'><span className="info-alert">Não foi possivel Exluir.</span></div>
        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
      </div>)

      setOpenModal(true)


    }
  }

  function fecharModal5() {

    sessionStorage.setItem("QUALTAB", '2');
    window.location.reload();
    setOpenModal(false)
  }

  function EditarOcorrencia(obj: any) {

    if (obj !== null) {
      setTitulo('Editar Ocorrência');
      setIdOcorrencia(obj.occ_id)
      setSolicitanteOcorrencia(obj.occ_requester);
      setCondicaoAtivoOcorrencia(obj.occ_assetcondition);
      setObservationOcorrencia(obj.occ_occurrence);
      setQmodalRelacionamento('N')
      setQmodalOcorrencia('S')
      setQmodalMovimentacao('N');
      setQmodalDespesas('N');
      setGerarQrcode('N')
      setOpenModalIncluirNovo(true)
    }

  }

  function Ocorrencia() {
    if(secureLocalStorage.getItem('acp') !== 'CONSULTOR'){
    setTitulo('Adicionar Ocorrência');
    setIdOcorrencia('')
    setSolicitanteOcorrencia('');
    setCondicaoAtivoOcorrencia('');
    setObservationOcorrencia('');
    setQmodalDespesas('N')
    setQmodalRelacionamento('N')
    setQmodalOcorrencia('S')
    setQmodalMovimentacao('N');
    setGerarQrcode('N')
    setOpenModalIncluirNovo(true)
    }
  }

  async function insertOcorrencia() {
    let ret: AxiosResponse;

    setQualAlert(<div className='div-alert'><Loading /></div>)
    setOpenModal(true)

    if (solicitanteOcorrencia === '') {

      setQualAlert(<div className='div-alert'>
        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
        <div className='div-info-alert'><span className="info-alert">Digite o nome do solicitante.</span></div>
        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
      </div>)

      setOpenModal(true)

    }
    else if (observationOcorrencia === "") {

      setQualAlert(<div className='div-alert'>
        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
        <div className='div-info-alert'><span className="info-alert">Digite o assunto.</span></div>
        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
      </div>)

      setOpenModal(true)


    } else
      if (idOcorrencia === '') {
        const dados = [{
          ast_id: id,
          age_id_insert: secureLocalStorage.getItem('iag'),
          occ_occurrence: observationOcorrencia,
          occ_assetcondition: 'EMUSO',
          occ_requester: solicitanteOcorrencia
        }]

        try {
          ret = await api.post("occurrence/insert", {
            data: dados,
          });
          if (ret.data.status === 200) {

            setQualAlert(<div className='div-alert'>
              <span className="fa-regular fa-circle-check ControleIconAlert"></span>
              <div className='div-info-alert'><span className="info-alert">Ocorrência Incluida com sucesso.</span></div>
              <div className='btn-alert' onClick={fecharModal6}><span className="btn-alert-lab">OK</span></div>
            </div>)


            setOpenModal(true)



          }
        } catch (e: any) {

          setQualAlert(<div className='div-alert'>
            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
            <div className='div-info-alert'><span className="info-alert">Não foi possível concluir o cadastramento.</span></div>
            <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
          </div>)

          setOpenModal(true)


        }
      } else {
        const dados = {
          ast_id: id,
          age_id_insert: secureLocalStorage.getItem('iag'),
          occ_occurrence: observationOcorrencia,
          occ_assetcondition: 'EMUSO',
          occ_requester: solicitanteOcorrencia,
          occ_id: idOcorrencia
        }

        try {
          ret = await api.put("occurrence/update", {
            data: dados,
          });
          if (ret.data.status === 200) {


            setQualAlert(<div className='div-alert'>
              <span className="fa-regular fa-circle-check ControleIconAlert"></span>
              <div className='div-info-alert'><span className="info-alert">A ocorrência foi alterada com sucesso.</span></div>
              <div className='btn-alert' onClick={fecharModal7}><span className="btn-alert-lab">OK</span></div>
            </div>)


            setOpenModal(true)



          }
        } catch (e: any) {

          setQualAlert(<div className='div-alert'>
            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
            <div className='div-info-alert'><span className="info-alert">Não foi possível concluir a alteração.</span></div>
            <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
          </div>)

          setOpenModal(true)


        }
      }
  }

  function fecharModal6() {

    sessionStorage.setItem("QUALTAB", '2');
    window.location.reload();
    setOpenModal(false)
  }

  function fecharModal7() {

    sessionStorage.setItem("QUALTAB", '2');
    window.location.reload();
    setOpenModal(false)
  }
  //----------------------------------------------------------------------------

  async function ChamaGridDespesas() {

    setQualGridRelacionamento(<div className='div-alert'><Loading /></div>)
    sessionStorage.setItem("QUALTAB", '3');
    let ret: AxiosResponse;

    try {
      ret = await api.get("costs/listar", {
        params: {
          id: id
        }
      })
      if (ret.data.status === 200) {
        setQualGridRelacionamento(<><GridExibirAlt 
          iconeExcluir={ModalDeletarDespesas}
          Iconeditar={editarDespesas} 
          Iconeadd={Despesas}
          th1='Tipo'
          th2='Valor'
          th3='ID'
          endPoint='costs/listar'
          consult='S'
          col1={0}
          col2={4}
          col3={3}
          col4={0}
          ambiente={id}
          conc='S'
          acao='S'
        /></>)

      } else {
        setQualGridRelacionamento(<div className='panel-content-visu-aviso'>
          <h1 className='panel-content-aviso'>Não há manutenções disponíveis.</h1>
          <h1 className='panel-content-aviso-add' onClick={Despesas}>Adicionar Manutenções</h1>
        </div>)
      }
    } catch (e) {
      setQualGridRelacionamento(<div className='panel-content-visu-aviso'>
        <h1 className='panel-content-aviso'>Não há manutenções disponíveis.</h1>
        <h1 className='panel-content-aviso-add' onClick={Despesas}>Adicionar Manutenções</h1>
      </div>)
    }
  }

  function ModalDeletarDespesas(obj: any) {
    if(secureLocalStorage.getItem('acp') !== 'CONSULTOR'){
    if (obj.cst_typecost === 'Compra') {

      setQualAlert(<div className='div-alert'>
        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
        <div className='div-info-alert'><span className="info-alert">Não é possível deletar valor de compra.</span></div>
        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
      </div>)

      setOpenModal(true)



    } else {

      setQualAlert(<div className='div-alert'>
        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
        <div className='div-info-alert-atencao'><span className="info-alert-atencao">Deseja deletar o registro?</span></div>
        <div className='div-info-alert'><span className="info-alert">Você não poderá reverter.</span></div>

        <div className='btn-alert-div'>
          <span className='btn-alert-esc' onClick={() => deletarRelacionamentoDespesas(obj.cst_id, obj.ast_id)}>
            <span className="btn-alert-lab">SIM</span>
          </span>
          <span className='btn-alert-dir' onClick={finalizarModal2}>
            <span className="btn-alert-lab">NÃO</span>
          </span>
        </div>
      </div>)

      setOpenModal(true)


    }
  }
  }

  async function deletarRelacionamentoDespesas(id: any, ast_id: any) {

    let ret: AxiosResponse;


    try {
      ret = await api.delete("costs/delete", {
        data: {
          id: id,
          ast_id: ast_id,
          age_id: secureLocalStorage.getItem('iag'),
        }
      });
      if (ret.data.status === 200) {


        sessionStorage.setItem("QUALTAB", '3');
        window.location.reload();
        setOpenModal(false)



      }
    } catch (e: any) {

      setQualAlert(<div className='div-alert'>
        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
        <div className='div-info-alert'><span className="info-alert">Não foi possivel excluir.</span></div>
        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
      </div>)

      setOpenModal(true)

    }
  }

  function fecharModal8() {

    sessionStorage.setItem("QUALTAB", '3');
    window.location.reload();
    setOpenModal(false)
  }

  function editarDespesas(obj: any) {

    if (obj !== null) {
      if (obj.cst_typecost === 'Compra') {

        setQualAlert(<div className='div-alert'>
          <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Não é possível editar valor de compra.</span></div>
          <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
        </div>)

        setOpenModal(true)


      } else {
        setTitulo('Editar Manutenções');
        setIdCustos(obj.cst_id);
        setTipoManutencao(obj.cst_typecost);
        setValor(obj.cst_value);
        setObservationCustos(obj.cst_observation);
        setQmodalRelacionamento('N');
        setQmodalOcorrencia('N');
        setQmodalDespesas('S');
        setQmodalMovimentacao('N');
        setGerarQrcode('N')
        setOpenModalIncluirNovo(true)
      }
    }

  }

  function Despesas() {
    if(secureLocalStorage.getItem('acp') !== 'CONSULTOR'){
    setTitulo('Adicionar Manutenções');
    setQmodalRelacionamento('N')
    setQmodalOcorrencia('N')
    setQmodalDespesas('S')
    setQmodalMovimentacao('N');
    setGerarQrcode('N')
    setIdCustos('');
    setTipoManutencao('');
    setValor('');
    setObservationCustos('');
    setOpenModalIncluirNovo(true)
    }
  }
  //------------------------------------------------------------
  async function ChamaGridMovimentacao(idContrato: any, descContrato: any, idUnidade: any, descUnidade: any, pavimento: any, departamento: any, user: any) {
    setQualGridRelacionamento(<></>)
    sessionStorage.setItem("QUALTAB", '4');
    let ret: AxiosResponse;

    try {
      ret = await api.get("movement/listarmovementproduto", {
        params: {
          id: id
        }
      })
      if (ret.data.status === 200) {
        setQualGridRelacionamento(<><GridExibirAlt Iconeadd={() => Movimentacao(idContrato, descContrato, idUnidade, descUnidade, pavimento, departamento, user)}
          th1='Destino'
          th2='Tipo de Movimentação'
          th3='ID'
          endPoint='movement/listarmovementproduto'
          consult='S'
          col1={0}
          col2={8}
          col3={12}
          col4={0}
          ambiente={id}
          conc='S'
          acao='N'
        /></>)
        // GetContrato();
        // GetUnidadeSel('');
      } else {
        setQualGridRelacionamento(<div className='panel-content-visu-aviso'>
          <h1 className='panel-content-aviso'>Não há Movimentação Disponíveis.</h1>
          <h1 className='panel-content-aviso-add' onClick={() => Movimentacao(idContrato, descContrato, idUnidade, descUnidade, pavimento, departamento, user)}>Adicionar Movimentação</h1>
        </div>)
      }
    } catch (e) {
      setQualGridRelacionamento(<div className='panel-content-visu-aviso'>
        <h1 className='panel-content-aviso'>Não há Movimentação Disponíveis.</h1>
        <h1 className='panel-content-aviso-add' onClick={() => Movimentacao(idContrato, descContrato, idUnidade, descUnidade, pavimento, departamento, user)}>Adicionar Movimentação</h1>
      </div>)
    }
  }

  function Movimentacao(idContrato: any, descContrato: any, idUnidade: any, descUnidade: any, pavimento: any, departamento: any, user: any) {
   
    if(secureLocalStorage.getItem('acp') !== 'CONSULTOR'){
    setTitulo('Adicionar Movimentação');
    setQmodalRelacionamento('N')
    setQmodalOcorrencia('N')
    setQmodalDespesas('N')
    setQmodalMovimentacao('S')
    setGerarQrcode('N')
    GetContrato();
    //setContractdest('');
    //setDestiny('');

    setContractdest('');
    setDestiny('');
    setDestinationPavement('');
    setDestinationDepartmen('');
    setTargetUser('');


    setDadosUnidadedest([])
    setOptionUnitydest([])
    setDadosPavementdest([])
    setDadosDepartmendest([])
    setTargetUser("")

    if (idContrato === null || idContrato === '') {
      setStatusMovimentacao('estoque')
    } else {

      setStatusMovimentacao('contrato')
      GetContrato();
      GetUnidade(idContrato)
      setContractMovimentacao(idContrato + '_' + descContrato);
      setUnityMovimentacao(idUnidade + '_' + descUnidade)
      setPavementMovimentacao(pavimento)
      GetDepartment(idUnidade)
      setDepartmentMovimentacao(departamento)
      GetDepartmentPavimentUp(pavimento, idUnidade)
      setUserMovimentacao(user)

    }
    setOpenModalIncluirNovo(true)
  }

  }

  async function GetDepartment(unidade: any) {
    let ret: AxiosResponse;
    let valor: any;

    try {
      ret = await api.get("local/listar", {
        params: {
          id: unidade
        }
      })
      if (ret.data.status === 200) {

        valor = ret.data.data;

        const arr = [] as any;
        let result = valor;
        result.map((user: any) => {
          return arr.push({ value: String(user.loc_pavement), label: user.loc_pavement });
        });

        setOptionspavement(arr)



      }
    } catch (e) {
      setOptionspavement([])
    }
  }

  async function IncluirUnidade(unidade: any) {
    let ret: AxiosResponse;
    let valor: any;

    if (unidade === "") {

      setDestiny("")
      setDadosPavementdest([])
      setDestinationPavement("")
      setDadosDepartmendest([])
      setDestinationDepartmen("")
      setTargetUser("")

    } else {
      setDestinationPavement("")
      setDestinationDepartmen("")
      setTargetUser("")
      setDadosDepartmendest([])
      setDestiny(unidade)

      try {
        ret = await api.get("local/listar", {
          params: {
            id: unidade
          }
        })
        if (ret.data.status === 200) {

          valor = ret.data.data;

          const arr = [] as any;
          let result = valor;
          result.map((user: any) => {
            return arr.push({ value: String(user.loc_pavement), label: user.loc_pavement });
          });

          setDadosPavementdest(arr)



        }
      } catch (e) {
        setDadosPavementdest([])
      }
    }
  }


  async function IncluirPavimento(pavimento: any) {
    let ret: AxiosResponse;
    let valor: any;

    if (pavimento === "") {
      setDestinationPavement("")
      setDadosDepartmendest([])
      setDestinationDepartmen("")
      setTargetUser("")
    } else {
      setDestinationPavement(pavimento)
      setDestinationDepartmen("")
      setTargetUser("")
      try {
        ret = await api.get("local/listarlocpaviment", {
          params: {
            id: destiny.split('_')[0],
            paviment: pavimento
          }
        })
        if (ret.data.status === 200) {
          valor = ret.data.data;

          const arr = [] as any;
          let result = valor;
          result.map((user: any) => {
            return arr.push({ value: String(user.loc_id), label: user.loc_description });
          });
          setDadosDepartmendest(arr)
        }
      } catch (e) {

        setDadosDepartmendest([])
      }
    }
  }


  async function GetDepartmentPavimentUp(pavimento: any, unidade: any) {
    let ret: AxiosResponse;
    let valor: any;

    try {
      ret = await api.get("local/listarlocpaviment", {
        params: {
          id: unidade,
          paviment: pavimento
        }
      })
      if (ret.data.status === 200) {
        valor = ret.data.data;

        const arr = [] as any;
        let result = valor;
        result.map((user: any) => {
          return arr.push({ value: String(user.loc_id), label: user.loc_description });
        });
        setOptionsDepartment(arr)
      }
    } catch (e) {
      setOptionsDepartment([])
    }
  }


  async function GetContrato() {
    let ret: AxiosResponse;
    let valor: any;
    try {
      ret = await api.get("contract/listar")
      if (ret.data.status === 200) {
        valor = ret.data.data;


        const arr = [] as any;
        let result = valor;
        result.map((user: any) => {
          return arr.push({ value: String(user.ctc_id + '_' + user.ctc_description), label: user.ctc_description });
        });
        setOptionContract(arr)

      }
    } catch (e) { }
  }



  //----------------------------------------------------------------
  async function ChamaAtividades() {

    let ret: AxiosResponse;
    let valor: any;
    try {
      ret = await api.get("activity/listar", {
        params: {
          id: id
        }
      })
      if (ret.data.status === 200) {
        valor = ret.data.data as [];

        setExibiAtividade(<><AtividadesAtivo data={valor} /></>)
      } else {
        setExibiAtividade(<h1 className='panel-content-aviso-atv'>Não há atividades vinculadas a esse ativos.</h1>)
      }
    } catch (e) {
      setOptionUnity([]);
      setExibiAtividade(<h1 className='panel-content-aviso-atv'>Não há atividades vinculadas a esse ativos.</h1>)
    }
  }


  async function insertCustos() {
    let ret: AxiosResponse;

    setQualAlert(<div className='div-alert'><Loading /></div>)
    setOpenModal(true)

    if (tipoManutencao === '') {

      setQualAlert(<div className='div-alert'>
        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
        <div className='div-info-alert'><span className="info-alert">Selecione o tipo de manutenção.</span></div>
        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
      </div>)

      setOpenModal(true)


    } else if (valor === '') {

      setQualAlert(<div className='div-alert'>
        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
        <div className='div-info-alert'><span className="info-alert">Digite o valor.</span></div>
        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
      </div>)

      setOpenModal(true)

    } else if (observationCustos === '') {

      setQualAlert(<div className='div-alert'>
        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
        <div className='div-info-alert'><span className="info-alert">Digite uma descrição para a manutenção.</span></div>
        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
      </div>)

      setOpenModal(true)

    }

    else
      if (idCustos === '') {
        const dados = [{
          ast_id: id,
          age_id: secureLocalStorage.getItem('iag'),
          cst_typecost: tipoManutencao,
          cst_value: valor,
          cst_observation: observationCustos,
          loc_id: loc_id
        }]

        try {
          ret = await api.post("costs/insert", {
            data: dados,
          });
          if (ret.data.status === 200) {


            setQualAlert(<div className='div-alert'>
              <span className="fa-regular fa-circle-check ControleIconAlert"></span>
              <div className='div-info-alert'><span className="info-alert">Alteração realizada com sucesso.</span></div>
              <div className='btn-alert' onClick={fecharModal9}><span className="btn-alert-lab">OK</span></div>
            </div>)


            setOpenModal(true)



            //inclurAtividadeDespesas(ret.data.data)
          }
        } catch (e: any) {

          setQualAlert(<div className='div-alert'>
            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
            <div className='div-info-alert'><span className="info-alert">Não foi possível concluir o cadastramento.</span></div>
            <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
          </div>)

          setOpenModal(true)


        }
      } else {
        const dados = {
          ast_id: id,
          age_id: secureLocalStorage.getItem('iag'),
          cst_observation: observationCustos,
          cst_value: valor,
          cst_typecost: tipoManutencao,
          cst_id: idCustos,
          loc_id: loc_id
        }

        try {
          ret = await api.put("costs/update", {
            data: dados,
          });
          if (ret.data.status === 200) {

            setQualAlert(<div className='div-alert'>
              <span className="fa-regular fa-circle-check ControleIconAlert"></span>
              <div className='div-info-alert'><span className="info-alert">Alteração realizada com sucesso.</span></div>
              <div className='btn-alert' onClick={fecharModal10}><span className="btn-alert-lab">OK</span></div>
            </div>)


            setOpenModal(true)


          }
        } catch (e: any) {

          setQualAlert(<div className='div-alert'>
            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
            <div className='div-info-alert'><span className="info-alert">Não foi possível concluir a alteração.</span></div>
            <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
          </div>)

          setOpenModal(true)



        }
      }
  }

  function fecharModal10() {
    sessionStorage.setItem("QUALTAB", '3');
    window.location.reload();
    setOpenModal(false)
  }

  function fecharModal9() {

    sessionStorage.setItem("QUALTAB", '3');
    window.location.reload()
    setOpenModal(false)
  }

  async function inclurAtividadeDespesas(obj: any) {
    let ret: AxiosResponse;
    let newArray = []
    let i = 0

    newArray.push({
      ast_id: obj[i].ast_id,
      age_id: obj[i].age_id,
      atv_type: 'Despesas',
      atv_description: `Incluiu o valor do tipo ${obj[i].cst_typecost} no valor de R$ ${obj[i].cst_value}.`,
      atv_idcontrol: obj[i].cst_id
    })

    try {
      ret = await api.post("activity/insert", {
        data: newArray,
      });
      if (ret.data.status === 200) {

        setQualAlert(<div className='div-alert'>
          <span className="fa-regular fa-circle-check ControleIconAlert"></span>
          <div className='div-info-alert'><span className="info-alert">Alteração realizada com sucesso.</span></div>
          <div className='btn-alert' onClick={fecharModal11}><span className="btn-alert-lab">OK</span></div>
        </div>)


        setOpenModal(true)


      }
    } catch (e: any) {

      setQualAlert(<div className='div-alert'>
        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
        <div className='div-info-alert'><span className="info-alert">Não foi possível concluir o cadastramento.</span></div>
        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
      </div>)

      setOpenModal(true)


    }
  }

  function fecharModal11() {


    sessionStorage.setItem("QUALTAB", '3');
    window.location.reload();
    setOpenModal(false)
  }

  function cancelarOcorrencia() {
    sessionStorage.setItem("QUALTAB", '2');
    window.location.reload();
  }

  function cancelarDespesas() {
    setTitulo('Adicionar Manutenções');
    setQmodalRelacionamento('N')
    setQmodalOcorrencia('N')
    setQmodalDespesas('S')

    sessionStorage.setItem("QUALTAB", '3');
    window.location.reload();
  }

  async function GetUnidadeDest(id: any) {
    let ret: AxiosResponse;
    let valor: any;

    if (id === '' || id === 'Selecione...') {

      setContractdest('')
      setDestiny('')
      setOptionUnitydest([]);
      setDadosDepartmendest([])
      setDadosPavementdest([])
      setTargetUser("")
    } else {
      setDestiny('')
      setDestinationPavement("")
      setDestinationDepartmen("")
      setTargetUser("")
      setContractdest(id)
      setDadosPavementdest([])
      setDadosDepartmendest([])

      try {
        ret = await api.get("unity/listar", {
          params: {
            id: id.split('_')[0]
          }
        })
        if (ret.data.status === 200) {
          valor = ret.data.data;
          const arr = [] as any;
          let result = valor;

          result.map((user: any) => {
            return arr.push({ value: String(user.unt_id + "_" + user.unt_description), label: user.unt_description });
          });
          setOptionUnitydest(arr)


        }
      } catch (e) {
        setOptionUnitydest([]);
      }
    }
  }

  const renderUnidadedest = dadosUnidadedest.map((comp: any) => {
    return (
      <option key={comp.unt_id} value={comp.unt_id + '_' + comp.unt_description}>
        {" "}
        {comp.unt_description}{" "}
      </option>
    );
  });


  async function GetUnidade(id: any) {
    let ret: AxiosResponse;
    let valor: any;

    if (id === '' || id === 'Selecione...') {
      setContractdest('')
      setDestiny('')
      setDadosUnidadedest([]);
    } else {
      setDestiny('')
      setContractdest(id)

      try {
        ret = await api.get("unity/listar", {
          params: {
            id: id.split('_')[0]
          }
        })
        if (ret.data.status === 200) {
          valor = ret.data.data;

          const arr = [] as any;
          let result = valor;
          result.map((user: any) => {
            return arr.push({ value: String(user.unt_id + "_" + user.unt_description), label: user.unt_description });
          });
          setOptionUnity(arr)

        }
      } catch (e) {
        setOptionUnity([]);
      }
    }
  }



  function validarStatusMovimentacao(status: any) {

    if (status === 'Selecione...') {
      setStatusMovimentacao('')
    } else {
      setStatusMovimentacao(status)
    }

  }

  async function insertMovimentacao() {
    let ret: AxiosResponse;
    let newArray2 = [];


    const FiltrarAlternativeIdentifier = optionsAtivo.filter(
      (e: any) => {
          return String(e.ast_alternativeidentifier || "").toLowerCase() === alternativeidentifier.toLowerCase()
      }
  );

    const FiltrarSerialNumber = optionsAtivo.filter(
      (e: any) => {
        return String(e.ast_serialnumber || "").toLowerCase() === serialNumber.toLowerCase()
      }
    );

    const FiltrarTombamento = optionsAtivo.filter(
      (e: any) => {
        return String(e.ast_tombamento || "").toLowerCase() === tombamento.toLowerCase()
      }
    );

  

    setQualAlert(<div className='div-alert'><Loading /></div>)
    setOpenModal(true)

    if (statusMovimentacao === '') {

      setQualAlert(<div className='div-alert'>
        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
        <div className='div-info-alert'><span className="info-alert">Selecione o tipo de movimentação.</span></div>
        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
      </div>)

      setOpenModal(true)


    } else if (contractdest === '') {

      setQualAlert(<div className='div-alert'>
        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
        <div className='div-info-alert'><span className="info-alert">Selecione o contrato de destino.</span></div>
        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
      </div>)

      setOpenModal(true)

    } else if (destiny === '') {

      setQualAlert(<div className='div-alert'>
        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
        <div className='div-info-alert'><span className="info-alert">Selecione a unidade de destino.</span></div>
        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
      </div>)

      setOpenModal(true)

    } else if (destinationPavement === '') {

      setQualAlert(<div className='div-alert'>
        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
        <div className='div-info-alert'><span className="info-alert">Selecione o pavimento.</span></div>
        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
      </div>)

      setOpenModal(true)

    } else if (destinationDepartmen === '') {

      setQualAlert(<div className='div-alert'>
        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
        <div className='div-info-alert'><span className="info-alert">Selecione o departamento.</span></div>
        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
      </div>)

      setOpenModal(true)

    } else if (alternativeidentifier === "") {


      setQualAlert(<div className='div-alert'>
        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
        <div className='div-info-alert'><span className="info-alert">Digite o Identificador Alternativo.</span></div>
        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
      </div>)

      setOpenModal(true)

    }  else if (alternativeidentifier !== "" && alternativeIdentifierIni !==
     alternativeidentifier && FiltrarAlternativeIdentifier.length > 0) {

      setQualAlert(<div className='div-alert'>
        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
        <div className='div-info-alert'><span className="info-alert">Identificador alternativo já está em uso.</span></div>
        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
      </div>)

      setOpenModal(true)

    }
    else if (serialNumber !== "" && serialNumberIni !== serialNumber && FiltrarSerialNumber.length > 0) {

      setQualAlert(<div className='div-alert'>
        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
        <div className='div-info-alert'><span className="info-alert">Número de série já está em uso.</span></div>
        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
      </div>)

      setOpenModal(true)

    } else if (tombamento !== "" && tombamentoIni !== tombamento && FiltrarTombamento.length > 0) {

      setQualAlert(<div className='div-alert'>
        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
        <div className='div-info-alert'><span className="info-alert">Tombamento já está em uso.</span></div>
        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
      </div>)

      setOpenModal(true)
    }
    else {
      newArray2.push({
        ast_id: id,
        mov_sourceunit: unityMovimentacao.split('_')[0] || null,
        mov_destinyunit: destiny.split('_')[0] || null,
        mov_destinyloc: destinationDepartmen || null,
        mov_usedby: targetUser,
        mov_observation: observationMovi,
        age_id_insert: secureLocalStorage.getItem('iag'),
        mov_type: statusMovimentacao
      })

      let newArray = []

      newArray.push({
        age_id_lastupdate: secureLocalStorage.getItem('iag'),
        ast_mac: mac,
        ast_usedby: targetUser,
        ast_serialnumber: serialNumber,
        loc_id: destinationDepartmen || null,
        ast_tombamento: tombamento,
        ast_id: id || null,
        ssa_id: '2',
        unt_id: destiny.split('_')[0] || null,
        ctc_id: contractdest.split('_')[0] || null,
        ast_alternativeidentifier: alternativeidentifier
      })

      let newArray3 = []


      if (statusMovimentacao === 'estoque') {
        newArray3.push({
          ast_id: id || null,
          age_id: secureLocalStorage.getItem('iag'),
          atv_type: 'movimentacao',
          atv_description: `Realizou uma movimentação de estoque para o contrato ${contractdest.split('_')[1]} unidade ${destiny.split('_')[1]}.`,
          atv_idcontrol: id
        })
      } else {
        newArray3.push({
          ast_id: id,
          age_id: secureLocalStorage.getItem('iag'),
          atv_type: 'movimentacao',
          atv_description: `Realizou uma movimentação da unidade ${userInv.untdescricao} para o contrato ${contractdest.split('_')[1]} unidade ${destiny.split('_')[1]}.`,
          atv_idcontrol: id
        })
      }


      try {
        ret = await api.post("movement/insert", {
          data: newArray2,
          dataUp: newArray,
          dataActivy: newArray3
        });
        if (ret.data.status === 200) {

          setQualAlert(<div className='div-alert'>
            <span className="fa-regular fa-circle-check ControleIconAlert"></span>
            <div className='div-info-alert'><span className="info-alert">Movimentação realizada com sucesso.</span></div>
            <div className='btn-alert' onClick={fecharModal12}><span className="btn-alert-lab">OK</span></div>
          </div>)


          setOpenModal(true)




        }
      } catch (e: any) {

        setQualAlert(<div className='div-alert'>
          <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Não foi possível concluir o cadastramento.</span></div>
          <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
        </div>)

        setOpenModal(true)


      }

    }
  }

  function fecharModal12() {

    sessionStorage.setItem("QUALTAB", '4');
    window.location.reload();
    setOpenModal(false)
  }

  async function inclurAtividade() {
    let ret: AxiosResponse;
    let newArray = []

    if (statusMovimentacao === 'estoque') {
      newArray.push({ ast_id: id, age_id: secureLocalStorage.getItem('iag'), atv_type: 'movimentacao', atv_description: `Realizou uma movimentação de estoque para o contrato ${contractdest.split('_')[1]} unidade ${destiny.split('_')[1]}.`, atv_idcontrol: id })
    } else if (statusMovimentacao === 'contrato') {
      newArray.push({ ast_id: id, age_id: secureLocalStorage.getItem('iag'), atv_type: 'movimentacao', atv_description: `Realizou uma movimentação da unidade ${userInv.untdescricao} para o contrato ${contractdest.split('_')[1]} unidade ${destiny.split('_')[1]}.`, atv_idcontrol: id })
    }

    try {
      ret = await api.post("activity/insert", {
        data: newArray,
      });
      if (ret.data.status === 200) {


        setQualAlert(<div className='div-alert'>
          <span className="fa-regular fa-circle-check ControleIconAlert"></span>
          <div className='div-info-alert'><span className="info-alert">Alteração realizada com sucesso.</span></div>
          <div className='btn-alert' onClick={fecharModal13}><span className="btn-alert-lab">OK</span></div>
        </div>)


        setOpenModal(true)



      }
    } catch (e: any) {


      setQualAlert(<div className='div-alert'>
        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
        <div className='div-info-alert'><span className="info-alert">Não foi possível concluir o cadastramento.</span></div>
        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
      </div>)

      setOpenModal(true)

    }
  }

  function fecharModal13() {

    window.location.reload();
    setOpenModal(false)
  }

  function cancelarMovimentacao() {
    setTitulo('Adicionar Movimentação');
    setQmodalRelacionamento('N')
    setQmodalOcorrencia('N')
    setQmodalDespesas('N')
    sessionStorage.setItem("QUALTAB", '4');
    window.location.reload();
  }

  function ChamaResumo() {
    sessionStorage.setItem("QUALTAB", '0');
  }

  function incluirValor(value: any) {
    var valor = value
    valor = valor + '';
    valor = parseInt(valor.replace(/[\D]+/g, ''));
    valor = valor + '';
    valor = valor.replace(/([0-9]{2})$/g, ".$1");
    if (valor.length > 6) {
      valor = valor.replace(/([0-9]{3}),([0-9]{2}$)/g, "$1.$2");
    }
    setValor(valor)
    if (valor == 'NaN') {
      setValor('');
    }
  }

  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      background: '#fff',
      minHeight: '30px',
      height: '30px',
      borderColor: state.isFocused ? 'grey' : '#92989e',

    }),


    valueContainer: (provided: any, state: any) => ({
      ...provided,
      height: '30px',
      padding: '0 6px'
    }),

    input: (provided: any, state: any) => ({
      ...provided,
      margin: '0px',
      fontSize: 14,

    }),
    indicatorsContainer: (provided: any, state: any) => ({
      ...provided,
      height: '30px',
    }),
  };

  function fecharOpenModalIncluirNovo() {
    setOpenModalIncluirNovo(false)
  }


  function IncluirDepartmen(id: any) {

    if (id === "") {

      setDestinationDepartmen("")
      setTargetUser("")

    } else {

      setDestinationDepartmen(id)

    }

  }

  return (
    <>
      <Nav />
      <div className="main-content">
        <Header />
        <main>
          <div className='containerPrincipal'>

            <h1 className='titulo-card-visualizacao'>{descricao}</h1>
            <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>

              <div className="container-cad-prod28">
                <div id="content1">
                  <TabList>
                    <Tab onClick={ChamaResumo} >
                      <p>Resumo</p>
                    </Tab>
                    <Tab onClick={ChamaGridRelacionamento}>
                      <p>Relacionamento</p>
                    </Tab>
                    <Tab onClick={ChamaGridOcorrencia}>
                      <p>Ocorrência</p>
                    </Tab>
                    <Tab onClick={ChamaGridDespesas}>
                      <p>Manutenções</p>
                    </Tab>

                    <Tab onClick={() => chamarGridMovimentacao('4')}>
                      <p>Movimentações</p>
                    </Tab>

                    <Tab onClick={ChamaAtividades}>
                      <p>Atividade</p>
                    </Tab>

                  </TabList>
                </div>



                <div id="content2">
                  <TabPanel>
                    <div className="panel-content">

                      <div className='panel-content-visu'>
                        <h2>ID: </h2>
                        <h2 className='resumo-bd'>{id}</h2>
                      </div>
                      <div className='panel-content-visu-qrcode'>
                        <h2>QR Code: </h2>
                        <div className='btn-qrcode' onClick={handleQrcode}> <span className='fa fa-qrcode icon-qrcode'></span></div>
                      </div>
                      <div className='panel-content-visu'>
                        <h2>Categoria: </h2>
                        <h2 className='resumo-bd'>{categoria}</h2>
                      </div>
                      <div className='panel-content-visu'>
                        <h2>Subcategoria: </h2>
                        <h2 className='resumo-bd'>{subcategoria}</h2>
                      </div>
                      <div className='panel-content-visu'>
                        <h2>Status: </h2>
                        <h2 className='resumo-bd'>{status}</h2>
                      </div>

                      <div className='panel-content-visu'>
                        <h2>Produto: </h2>
                        <h2 className='resumo-bd'>{produto}</h2>
                      </div>
                      <div className='panel-content-visu'>
                        <h2>Fabricante: </h2>
                        <h2 className='resumo-bd'>{fabricante}</h2>
                      </div>
                      <div className='panel-content-visu'>
                        <h2>Marca: </h2>
                        <h2 className='resumo-bd'>{marca}</h2>
                      </div>
                      <div className='panel-content-visu'>
                        <h2>Modelo: </h2>
                        <h2 className='resumo-bd'>{modelo}</h2>
                      </div>
                      <div className='panel-content-visu'>
                        <h2>Identificador Alternativo: </h2>
                        <h2 className='resumo-bd'>{alternativeidentifier}</h2>
                      </div>
                      <div className='panel-content-visu'>
                        <h2>Numero de Serie: </h2>
                        <h2 className='resumo-bd'>{numeroSerie}</h2>
                      </div>
                      <div className='panel-content-visu'>
                        <h2>Contrato: </h2>
                        <h2 className='resumo-bd'>{localizacaoContrato}</h2>
                      </div>
                      <div className='panel-content-visu'>
                        <h2>Unidade: </h2>
                        <h2 className='resumo-bd'>{localizacaoUnidade}</h2>
                      </div>
                      <div className='panel-content-visu'>
                        <h2>Andar: </h2>
                        <h2 className='resumo-bd'>{localizacaoAndar}</h2>
                      </div>
                      <div className='panel-content-visu'>
                        <h2>Local: </h2>
                        <h2 className='resumo-bd'>{localizacaoLocal}</h2>
                      </div>
                      <div className='panel-content-visu'>
                        <h2>Nota Fiscal: </h2>
                        <h2 className='resumo-bd'>{notaFiscal}</h2>
                      </div>
                      <div className='panel-content-visu'>
                        <h2>Valor: </h2>
                        <h2 className='resumo-bd'>{custo}</h2>
                      </div>
                      <div className='panel-content-visu'>
                        <h2>Garantia: </h2>
                        <h2 className='resumo-bd'>{garantiaAno} {exibirAnos} {garantiaMes} {exibirMeses} </h2>
                      </div>
                      <div className='panel-content-visu'>
                        <h2>Data de Aquisição: </h2>
                        <h2 className='resumo-bd'>{dataAquisicao}</h2>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>

                    {qualGridRelacionamento}

                  </TabPanel>
                  <TabPanel>

                    {qualGridRelacionamento}

                  </TabPanel>
                
                  <TabPanel>

                    {qualGridRelacionamento}

                  </TabPanel>
                  <TabPanel>

                    {qualGridRelacionamento}

                  </TabPanel>
                  <TabPanel>
                    <div className="panel-content">
                      {exibiAtividade}
                    </div>
                  </TabPanel>
                </div>
              </div>
            </Tabs>
          </div>
        </main>
      </div>


      <ModalIncluirNovo isOpen={openModalIncluirNovo} setModalOpen={() => setOpenModalIncluirNovo(!openModalIncluirNovo)}>
        <div className='title-modal-incluir'>
          <h1 className="modal-IncluirNovo-title">{titulo}</h1>
          <div onClick={fecharOpenModalIncluirNovo}>
            <button className="fa fa-times icone-modal-IncluirNovo" data-dismiss="modal" aria-label="Close"></button>
          </div>
        </div>
        <div>
          <div className='container-modal-incluir'>
            {qModalRelacionamento
              === 'S' ? (
              <>
                <div className='modal-visu-inventatio'>
                  <div className="container-InventarioEdit">
                    <div id="content1">
                    <div className="select-box-ativos-select">
                        <div className='details-flex'>
                          <span className="details">Tipo de Entidade *</span>
                        </div>

                        <Select
                          maxMenuHeight={120}
                          placeholder={'Selecione...'}
                          options={optiontipoEntidade}
                          styles={customStyles}
                          value={optiontipoEntidade.filter(function (option: any) {
                            return option.value === tipoEntidade;
                          })}
                          onChange={(options: any) =>
                            !options ? TipoEnt("") : TipoEnt(options.value)
                          }
                          isClearable
                          noOptionsMessage={() => "Não encontrado..."}
                          theme={(theme: any) => ({
                            ...theme,
                            borderRadius: 4,
                            colors: {
                              ...theme.colors,
                              primary25: '#ebebeb',
                              primary: 'black',
                            },
                          })}
                        />

                      </div>
                    </div>
                    {exibirCatAtivo === 'S' ? (
                      <>
                        <div id="content2">


                        <div className="select-box-ativos-select">
                        <div className='details-flex'>
                          <span className="details">Categoria *</span>
                        </div>

                        <Select
                          maxMenuHeight={120}
                          placeholder={'Selecione...'}
                          options={dadosCategoria}
                          styles={customStyles}
                          value={dadosCategoria.filter(function (option: any) {
                            return option.value === categoriaAtivo;
                          })}
                          onChange={(options: any) =>
                            !options ? qCategoria("") : qCategoria(options.value)
                          }
                          isClearable
                          noOptionsMessage={() => "Não encontrado..."}
                          theme={(theme: any) => ({
                            ...theme,
                            borderRadius: 4,
                            colors: {
                              ...theme.colors,
                              primary25: '#ebebeb',
                              primary: 'black',
                            },
                          })}
                        />
                      </div>



                        </div>

                        <div className="input-box-ativos">
                          <div className='btn-busca-edt' onClick={BuscarGrid}>
                            <h1 className='lab-movimentacao'>Buscar</h1>
                          </div>
                        </div>
                      </>
                    ) : null}
                  </div>
                  <div className='qual-grid'>

                    {qualGrid}

                  </div>
                </div>
              </>
            ) : null}





            {qModalOcorrencia
              === 'S' ? (
              <>
                <div className='modal-visu-inventatio'>
                  <div className="textareaBox-ativo">

                    <div className="input-box-ativos">
                      <div className='details-flex'>
                        <span className="details">Requisitante *</span>
                      </div>
                      <input
                        autoComplete="off"
                        type="text"
                        onChange={(e) => setSolicitanteOcorrencia(e.target.value)} 
                        value={solicitanteOcorrencia}
                        disabled={solicitanteOcorrenciaDisabled}
                      />

                    </div>


                  </div>
                  <div className="container-cad-prod3">
                    <div className="textareaBox-ativo">
                      <span className="details">Assunto *</span>
                      <textarea
                        onChange={(e) => setObservationOcorrencia(e.target.value)} 
                        value={observationOcorrencia}
                        disabled={observationOcorrenciaDisabled}
                      />
                    </div>
                  </div>
                  <div className="Bt-button-cad">
                    <button type="submit" onClick={insertOcorrencia} disabled={insertOcorrenciaDisabled}>
                      <i className="fa fa-check-circle icon-ok-btn"></i> Salvar
                    </button>
                    <button type="submit" onClick={cancelarOcorrencia}>
                      <i className="fa fa-times icon-cancelar-btn" ></i> Cancelar
                    </button>
                  </div>

                </div>
              </>
            ) : null}

            {qModalDespesas
              === 'S' ? (
              <>

                <div className='modal-visu-inventatio'>
                  <div className="container-InventarioEdit2">
                    <div id="content1">
                    <div className="select-box-ativos-select">
                        <div className='details-flex'>
                          <span className="details">Tipo *</span>
                        </div>

                        <Select
                          maxMenuHeight={80}
                          placeholder={'Selecione...'}
                          options={optiontipo}
                          styles={customStyles}
                          value={optiontipo.filter(function (option: any) {
                            return option.value === tipoManutencao;
                          })}
                          onChange={(options: any) =>
                            !options ? ValidarTipoManutencao("") : ValidarTipoManutencao(options.value)
                          }
                          isClearable
                          noOptionsMessage={() => "Não encontrado..."}
                          theme={(theme: any) => ({
                            ...theme,
                            borderRadius: 4,
                            colors: {
                              ...theme.colors,
                              primary25: '#ebebeb',
                              primary: 'black',
                            },
                          })}
                        />
                      </div>
                    </div>

                    <div id="content2">
                      <div className="input-box-ativos">
                        <div className='details-flex'>
                          <span className="details">Valor *</span>
                        </div>
                        <input
                          autoComplete="off"
                          type="text"
                          onChange={(e) => incluirValor(e.target.value)}
                          value={valor}
                          placeholder='R$'
                          disabled={valorDisabled}
                        />

                      </div>
                    </div>
                  </div>
                  <div className="container-cad-prod3">
                    <div className="textareaBox-ativo">
                      <span className="details">Informação sobre a manutenção *</span>
                      <textarea
                        onChange={(e) => setObservationCustos(e.target.value)}
                        value={observationCustos}
                        disabled={observationCustosDisabled}
                      />
                    </div>
                  </div>
                  <div className="Bt-button-cad">
                    <button type="submit" onClick={insertCustos} disabled={insertCustosDisabled}>
                      <i className="fa fa-check-circle icon-ok-btn"></i> Salvar
                    </button>
                    <button type="submit" onClick={cancelarDespesas}>
                      <i className="fa fa-times icon-cancelar-btn" ></i> Cancelar
                    </button>
                  </div>

                </div>


              </>
            ) : null}

            {qModalMovimentacao === 'S' ? (
              <>
                <div className='modal-visu-inventatio'>

                  <div className="container-cad-prod8">

                    <div id="conten1">
                      <div className="select-box-ativos-select">
                        <span className="details">Contrato</span>

                        <Select
                          maxMenuHeight={150}
                          placeholder={'Selecione...'}
                          options={optionContract}
                          styles={customStyles}
                          value={optionContract.filter(function (option: any) {
                            return option.value === contractMovimentacao;
                          })}
                          onChange={(options: any) =>
                            !options ? setContractMovimentacao("") : setContractMovimentacao(options.value)
                          }
                          isClearable
                          isDisabled={isDisabledContract}
                          noOptionsMessage={() => "Não encontrado..."}
                          theme={(theme: any) => ({
                            ...theme,
                            borderRadius: 4,
                            colors: {
                              ...theme.colors,
                              primary25: '#ebebeb',
                              primary: 'black',
                            },
                          })}
                        />
                      </div>
                    </div>
                    <div id="conten2">
                      <div className="select-box-ativos-select">
                        <span className="details">Origem</span>
                        <Select
                          maxMenuHeight={150}
                          placeholder={'Selecione...'}
                          options={optionUnity}
                          styles={customStyles}
                          value={optionUnity.filter(function (option: any) {
                            return option.value === unityMovimentacao;
                          })}
                          onChange={(options: any) =>
                            !options ? setUnityMovimentacao("") : setUnityMovimentacao(options.value)
                          }
                          isClearable
                          isDisabled={isDisabledUnity}
                          noOptionsMessage={() => "Não encontrado..."}
                          theme={(theme: any) => ({
                            ...theme,
                            borderRadius: 4,
                            colors: {
                              ...theme.colors,
                              primary25: '#ebebeb',
                              primary: 'black',
                            },
                          })}
                        />

                      </div>
                    </div>
                  </div>

                  <div className="container-cad-prod2">
                    <div id="content1">
                      <div className="select-box-ativos-select">
                        <span className="details">Pavimento</span>
                        <Select
                          maxMenuHeight={100}
                          placeholder={'Selecione...'}
                          options={optionspavement}
                          styles={customStyles}
                          value={optionspavement.filter(function (option: any) {
                            return option.value === pavementMovimentacao;
                          })}
                          onChange={(options: any) =>
                            !options ? setPavementMovimentacao("") : setPavementMovimentacao(options.value)
                          }
                          isClearable
                          isDisabled={true}
                          noOptionsMessage={() => "Não encontrado..."}
                          theme={(theme: any) => ({
                            ...theme,
                            borderRadius: 4,
                            colors: {
                              ...theme.colors,
                              primary25: '#ebebeb',
                              primary: 'black',
                            },
                          })}
                        />
                      </div>
                    </div>

                    <div id="content2">
                      <div className="select-box-ativos-select">
                        <div className='details-flex'>
                          <span className="details">Departamento</span>
                        </div>
                        <Select
                          maxMenuHeight={100}
                          placeholder={'Selecione...'}
                          options={optionsDepartment}
                          styles={customStyles}
                          value={optionsDepartment.filter(function (option: any) {
                            return option.value === departmentMovimentacao;
                          })}
                          onChange={(options: any) =>
                            !options ? setDepartmentMovimentacao("") : setDepartmentMovimentacao(options.value)
                          }
                          //   isClearable
                          isDisabled={true}
                          noOptionsMessage={() => "Não encontrado..."}
                          theme={(theme: any) => ({
                            ...theme,
                            borderRadius: 4,
                            colors: {
                              ...theme.colors,
                              primary25: '#ebebeb',
                              primary: 'black',
                            },
                          })}
                        />
                      </div>
                    </div>


                    <div id="content3">
                      <div className="input-box-ativos">
                        <div className='details-flex'>
                          <span className="details">Usuário</span>
                        </div>
                        <input
                          autoComplete="off"
                          type="text"
                          disabled={true}
                          onChange={(e) => setUserMovimentacao(e.target.value)}
                          value={userMovimentacao}
                        />

                      </div>
                    </div>
                  </div>

                  <div className="title-Page">
                    <div className="title-text">
                      <div className="size-x circle">1</div>
                      <h2>Defina informações do destino</h2>
                    </div>
                    <hr />
                  </div>

                  <div className="container-cad-prod2">
                    <div className="select-box-ativos-select">
                      <span className="details">Tipo de Movimentação *</span>
                      <Select
                        maxMenuHeight={150}
                        placeholder={'Selecione...'}
                        options={optionTipoMan}
                        styles={customStyles}
                        value={optionTipoMan.filter(function (option: any) {
                          return option.value === statusMovimentacao;
                        })}
                        onChange={(options: any) =>
                          !options ? validarStatusMovimentacao("") : validarStatusMovimentacao(options.value)
                        }
                        isClearable
                        isDisabled={isDisabledUnity}
                        noOptionsMessage={() => "Não encontrado..."}
                        theme={(theme: any) => ({
                          ...theme,
                          borderRadius: 4,
                          colors: {
                            ...theme.colors,
                            primary25: '#ebebeb',
                            primary: 'black',
                          },
                        })}
                      />
                    </div>

                    <div className="select-box-ativos-select">
                      <div className='details-flex'>
                        <span className="details">Contrato *</span>
                      </div>
                      <Select
                        maxMenuHeight={150}
                        placeholder={'Selecione...'}
                        options={optionContract}
                        styles={customStyles}
                        value={optionContract.filter(function (option: any) {
                          return option.value === contractdest;
                        })}
                        onChange={(options: any) =>
                          !options ? GetUnidadeDest("") : GetUnidadeDest(options.value)
                        }
                        isClearable
                        noOptionsMessage={() => "Não encontrado..."}
                        theme={(theme: any) => ({
                          ...theme,
                          borderRadius: 4,
                          colors: {
                            ...theme.colors,
                            primary25: '#ebebeb',
                            primary: 'black',
                          },
                        })}
                      />
                    </div>
                    <div className="select-box-ativos-select">
                      <span className="details">Destino *</span>
                      <Select
                        maxMenuHeight={150}
                        placeholder={'Selecione...'}
                        options={optionUnitydest}
                        styles={customStyles}
                        value={optionUnitydest.filter(function (option: any) {
                          return option.value === destiny;
                        })}
                        onChange={(options: any) =>
                          !options ? IncluirUnidade("") : IncluirUnidade(options.value)
                        }
                        isClearable
                        noOptionsMessage={() => "Não encontrado..."}
                        theme={(theme: any) => ({
                          ...theme,
                          borderRadius: 4,
                          colors: {
                            ...theme.colors,
                            primary25: '#ebebeb',
                            primary: 'black',
                          },
                        })}
                      />


                    </div>
                  </div>

                  <div className="container-cad-prod2">
                    <div id="content1">
                      <div className="select-box-ativos-select">
                        <span className="details">Pavimento *</span>
                        <Select
                          maxMenuHeight={100}
                          placeholder={'Selecione...'}
                          options={dadosPavementdest}
                          styles={customStyles}
                          value={dadosPavementdest.filter(function (option: any) {
                            return option.value === destinationPavement;
                          })}
                          onChange={(options: any) =>
                            !options ? IncluirPavimento("") : IncluirPavimento(options.value)
                          }
                          isClearable
                          noOptionsMessage={() => "Não encontrado..."}
                          theme={(theme: any) => ({
                            ...theme,
                            borderRadius: 4,
                            colors: {
                              ...theme.colors,
                              primary25: '#ebebeb',
                              primary: 'black',
                            },
                          })}
                        />
                      </div>
                    </div>

                    <div id="content2">

                      <div className="select-box-ativos-select">
                        <div className='details-flex'>
                          <span className="details">Departamento *</span>
                        </div>

                        <Select
                          maxMenuHeight={100}
                          placeholder={'Selecione...'}
                          options={dadosDepartmendest}
                          styles={customStyles}
                          value={dadosDepartmendest.filter(function (option: any) {
                            return option.value === destinationDepartmen;
                          })}
                          onChange={(options: any) =>
                            !options ? IncluirDepartmen("") : IncluirDepartmen(options.value)
                          }
                          isClearable
                          noOptionsMessage={() => "Não encontrado..."}
                          theme={(theme: any) => ({
                            ...theme,
                            borderRadius: 4,
                            colors: {
                              ...theme.colors,
                              primary25: '#ebebeb',
                              primary: 'black',
                            },
                          })}
                        />
                      </div>

                    </div>

                    <div id="content3">
                      <div className="input-box-ativos">
                        <div className='details-flex'>
                          <span className="details">Usuário</span>
                        </div>
                        <input
                          autoComplete="off"
                          type="text"
                          onChange={(e) => setTargetUser(e.target.value)}
                          value={targetUser}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="container-cad-prod2">

                    <div id="content1">
                      <div className="input-box-ativos">
                        <span className="details">Identificador Alternativo *</span>
                        <input
                          autoComplete="off"
                          type="text"
                          onChange={(e) => setAlternativeidentifier(e.target.value)}
                          value={alternativeidentifier}
                        />
                      </div>
                    </div>

                    <div id="content2">
                      <div className="input-box-ativos">
                        <span className="details">Número de Serie</span>
                        <input
                          autoComplete="off"
                          type="text"
                          onChange={(e) => setSerialNumber(e.target.value)}
                          value={serialNumber}
                        />
                      </div>
                    </div>

                    <div id="content3">
                      <div className="input-box-ativos">
                        <div className="input-box-ativos">
                          <div className='details-flex'>
                            <span className="details">Tombamento</span>
                          </div>
                          <input
                            autoComplete="off"
                            type="text"
                            onChange={(e) => setTombamento(e.target.value)}
                            value={tombamento}
                          />
                        </div>
                      </div>
                    </div>

                  </div>

                  {subCat === '1' || subCat === '3' || subCat === '4' || subCat === '5' || subCat === '17' || subCat === '18' || subCat === '19' || subCat === '8' || subCat === '9' ? (
                    <div className="container-cad-prod27">
                      <div id="content1">
                        <div className="input-box-ativos">
                          <span className="details">MAC</span>
                          <input
                            autoComplete="off"
                            type="text"
                            onChange={(e) => setMac(e.target.value)}
                            value={mac}
                          />
                        </div>
                      </div>
                    </div>
                  ) : null}

                  <div className="container-cad-prod3">
                    <div className="textareaBox-ativo">
                      <span className="details">Observação</span>
                      <textarea
                        onChange={(e) => setObservationMovi(e.target.value)}
                        value={observationMovi}
                      />
                    </div>
                  </div>
                  <div className="Bt-button-cad">
                    <button type="submit" onClick={insertMovimentacao}>
                      <i className="fa fa-check-circle icon-ok-btn"></i> Salvar
                    </button>
                    <button type="submit" onClick={cancelarMovimentacao}>
                      <i className="fa fa-times icon-cancelar-btn" ></i> Cancelar
                    </button>
                  </div>
                </div>
              </>
            ) : null}

            {gerarQrcode === 'S' ? (
              <div className='modal-visu-qrcode'>
                <QRCode
                  value={link}
                />
                <a href={qrcodeLink} download={`${alternativeidentifier}.png`} className='gerarQr'>Baixar QR Code</a>
              </div>
            ) : null}
          </div>
        </div>
      </ModalIncluirNovo>

      <ModalAlert isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)}>
        <div className='container-modal-alert'>
          {qualAlert}
        </div>
      </ModalAlert>

    </>
  );

}

export default InventarioVisualizacao;