import React, { useState } from "react";
import api from "../../Services/api";
import "./index.css";

export class InputCust extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tableData: []
        };
    }

    componentDidMount() {
        this.getData();
     
   
      }

    getData() {

        if(this.props.id === ""){


            try {
                api.get("userproductcustomfieid/listar").then((res) => {
                  var data = res.data.data;

                  if (res.data.status === 200) {
                  var data2 = data.map((element) => { return {...element,
                  pcf_id: null,
                  pcf_value: null,
                  pdc_id: null} });

                
                     this.setState({
                            tableData: data
                        });
                        this.props.incluirDadosCamposCust(data)
                  }
                });
        
        
              }
              catch (e) { }









        }else{
        try {
            api.get("userproductcustomfieid/listarvalor", {
              params: {
               id: this.props.id
              }
            }).then((res) => {
              var data = res.data.data;

              if (res.data.status === 200) {
                 this.setState({
                        tableData: data
                    });
                    this.props.incluirDadosCamposCust(data)
              }
            });
    
    
          }
          catch (e) { }

        }

    }

    renderRow(row, i) {
        return <div key={i}>{row}</div>
      }


    render() {
  
        let rows = []
        let I = 0;
        let J = 0;
        let k = 0;

        for (I = 0; I < this.state.tableData.length; I = I + 4) {

            for (J = I, k = 1; J < this.state.tableData.length; J++, k++) {
                if (J < I + 4) {
                    let groupDescription;
                    if (!this.state.tableData[J].pcf_value) {
                        groupDescription = ""
                    } else {
                        groupDescription = this.state.tableData[J].pcf_value
                    }
                    rows.push(
                        <div id={`content${k}`}>
                            <div id="input-box-ativos-cust1">
                                <span className="details">{this.state.tableData[J].pdt_description}</span>
                                <input
                                    autoComplete="off"
                                    id='${this.state.tableData[J].pdt_alternativeidentifier}'
                                    name={this.state.tableData[J].pdt_alternativeidentifier}
                                    onChange={(e) => onChangeInput(e)}
                                    value={groupDescription}
                                    disabled={this.props.camposCustDisabled}
                                />
                            </div>
                        </div>
                    )

                }
            }

        }


        const onChangeInput = (e, campoId) => {

            const { name, value } = e.target

            const editData = this.state.tableData.map((item) =>
                item.pdt_alternativeidentifier === name && name ? { ...item, pcf_value: value } : item
            )

            this.setState({
                tableData: editData
            });

          this.props.incluirDadosCamposCust(editData)
        }

        return (
            <div id="container-cad-cust1">
               {rows.map((tdata, i) => (
                this.renderRow(tdata, i)
             ))}
            </div>
        )
    }
}

export default InputCust;