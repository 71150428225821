import React, { useState, useEffect } from 'react'
import './index.css'
import { AxiosResponse } from "axios";
import api from '../../Services/api'
import Select from 'react-select'
import Loading from '../../components/Loading';
import secureLocalStorage from "react-secure-storage";
import ModalAlert from '../../components/ModalAlert/modal'
import { useNavigate } from 'react-router';

function FormSubcategoria(props: any) {
    const dadosSub = (sessionStorage.getItem("dadosSub") || '{}');
    let userSub = JSON.parse(dadosSub);


    const navigate = useNavigate();
    const [id, setId] = useState(userSub.stg_id || '')
    const [active, setActive] = useState(userSub.stg_active != undefined ? Boolean(userSub.stg_active) : true)
    const [description, setDescription] = useState(userSub.stg_description || '')
    const [alternativeIdentifier, setAlternativeIdentifier] = useState(userSub.stg_alternativeidentifier || '')
    const [descriptionIni, setDescriptionIni] = useState(userSub.stg_description || '')
    const [alternativeIdentifierIni, setAlternativeIdentifierIni] = useState(userSub.stg_alternativeidentifier || '')
    const [category, setCategory] = useState(String(userSub.ctg_id) || '')
    const [dadosFabricante, setDadosFabricante] = useState([]);
    const [carregaFabricante, setCarregaFabricante] = useState(GetCategory);
    const [openModal, setOpenModal] = useState(false)
    const [qualAlert, setQualAlert] = useState(<></>)
    const [optionCategory, setOptionCategory] = useState([])
    const [optionSubcategory, setOptionSubcategory] = useState([])

    useEffect(() => {

        if (userSub.stg_active !== undefined) {

        } else
            if (props.category !== undefined || props.category !== "") {
                setCategory(props.category)
            }
       
            validatToken()
    }, []);

    async function validatToken() {

		let ret: AxiosResponse;
		let valor: any;

		const _user = secureLocalStorage.getItem('t')

		api.defaults.headers.common['Authorization'] = _user as ''

		try {

			ret = await api.get("/status/validar")

			if (ret.data.status === 200) {

				secureLocalStorage.setItem('t', ret.data.data);
				secureLocalStorage.setItem('acp', ret.data.decodedToken.acp_integrationid);
				secureLocalStorage.setItem('n', ret.data.decodedToken.name);
				secureLocalStorage.setItem('iag', ret.data.decodedToken.id);
				secureLocalStorage.setItem('cy', ret.data.decodedToken.company);

                subcategoria()

            }
		} catch (e: any) {

			if (e.response.data.status === 403) {

				setQualAlert(<div className='div-alert'>
					<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
					<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
					<div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
					<div className='btn-alert' onClick={fecharModalToken}><span className="btn-alert-lab">OK</span></div>
				</div>)

				setOpenModal(true)

			}
		}

	}

    async function subcategoria() {

        let ret: AxiosResponse;
        let valor: any;

        try {
            ret = await api.get("subcategory/verificacaosubcategory", {
                params: {
                    id: props.category
                }
            })
            if (ret.data.status === 200) {
                valor = ret.data.data;

                setOptionSubcategory(valor)

            }
        } catch (e) {
            setOptionSubcategory([])
        }
    }


    async function ConfirmarSubCategoria() {

        let ret: AxiosResponse;
        let valor: any;

        setOpenModal(true)
        setQualAlert(<div className='div-alert'><Loading /></div>)

        const _user = secureLocalStorage.getItem('t')

        api.defaults.headers.common['Authorization'] = _user as ''

        try {

            ret = await api.get("/status/validar")

            if (ret.data.status === 200) {

                if (id === undefined || id === '') {
                    insertMarca()
                } else {
                    UpdateMarca();
                }
            }

        } catch (e: any) {

            if (e.response.data.status === 403) {

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
                    <div className='btn-alert' onClick={fecharModalToken}><span className="btn-alert-lab">OK</span></div>
                </div>)


            }
        }

    }

    function fecharModalToken() {

        secureLocalStorage.removeItem('t')
        secureLocalStorage.removeItem('acp')
        secureLocalStorage.removeItem('n')
        secureLocalStorage.removeItem('iag')
        navigate('/login');
        setOpenModal(false)

    }

    async function UpdateMarca() {

        const FiltrarDescricao = optionSubcategory.filter(
            (e: any) => {
                return e.stg_description.toLowerCase() === description.toLowerCase()
            }
        );

        const FiltrarAlternativeIdentifier = optionSubcategory.filter(
            (e: any) => {
                return e.stg_alternativeidentifier.toLowerCase() === alternativeIdentifier.toLowerCase()
            }
        );

        if (descriptionIni.toLowerCase() !== description.toLowerCase() && FiltrarDescricao.length > 0) {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Descrição já está em uso.</span></div>
                <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
            </div>)

        } else if (alternativeIdentifierIni.toLowerCase() !== alternativeIdentifier.toLowerCase() && FiltrarAlternativeIdentifier.length > 0) {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Identificador alternativo já está em uso.</span></div>
                <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
            </div>)

        } else

            if (description === "") {


                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Digite a descrição.</span></div>
                    <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                </div>)


            } else
                if (alternativeIdentifier === "") {

                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Digite o identificador alternativo.</span></div>
                        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                    </div>)

                } else
                    if (category === "" || category === "Selecione...") {

                        setQualAlert(<div className='div-alert'>
                            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                            <div className='div-info-alert'><span className="info-alert">Selecione a Categoria.</span></div>
                            <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                        </div>)


                    } else {

                        let ret: AxiosResponse;

                        const dados = {
                            stg_id: id,
                            stg_active: active,
                            stg_description: description,
                            stg_alternativeidentifier: alternativeIdentifier,
                            ctg_id: category
                        }

                        try {
                            ret = await api.put("subcategory/update", {
                                data: dados,
                            });
                            if (ret.data.status === 200) {
                                props.voltar()
                            }
                        } catch (e: any) {

                            setQualAlert(<div className='div-alert'>
                            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                            <div className='div-info-alert'><span className="info-alert">Não foi possível concluir a alteração.</span></div>
                            <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                        </div>)

                        }
                    }

    }

    async function insertMarca() {
        let ret: AxiosResponse;


        const FiltrarDescricao = optionSubcategory.filter(
            (e: any) => {
                return e.stg_description.toLowerCase() === description.toLowerCase()
            }
        );

        const FiltrarAlternativeIdentifier = optionSubcategory.filter(
            (e: any) => {
                return e.stg_alternativeidentifier.toLowerCase() === alternativeIdentifier.toLowerCase()
            }
        );

        if (FiltrarDescricao.length > 0) {
    
            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Descrição já está em uso.</span></div>
                <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
            </div>)

        } else if (FiltrarAlternativeIdentifier.length > 0) {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Identificador alternativo já está em uso.</span></div>
                <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
            </div>)

        } else

            if (description === "") {


                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Digite a descrição.</span></div>
                    <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                </div>)


            } else
                if (alternativeIdentifier === "") {

                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Digite o identificador alternativo.</span></div>
                        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                    </div>)

                } else
                    if (category === "" || category === "Selecione...") {

                        setQualAlert(<div className='div-alert'>
                            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                            <div className='div-info-alert'><span className="info-alert">Selecione a Categoria.</span></div>
                            <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                        </div>)


                    } else {

                        const dados = [{
                            stg_active: active,
                            stg_description: description,
                            stg_alternativeidentifier: alternativeIdentifier,
                            ctg_id: category
                        }]

                        try {
                            ret = await api.post("subcategory/insert", {
                                data: dados,
                            });
                            if (ret.data.status === 200) {
                                props.voltar()
                            }
                        } catch (e: any) {

                            setQualAlert(<div className='div-alert'>
                            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                            <div className='div-info-alert'><span className="info-alert">Não foi possível concluir o cadastramento.</span></div>
                            <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                        </div>)

                        }
                    }
    }

    function fecharModal() {
        setOpenModal(false)
    }

    async function GetCategory() {
        let ret: AxiosResponse;
        let valor: any;
        try {
            ret = await api.get("category/listar")
            if (ret.data.status === 200) {
                valor = ret.data.data;


                const arr = [] as any;
                let result = valor;
                result.map((user: any) => {
                    return arr.push({ value: String(user.ctg_id), label: user.ctg_description });
                });
                setOptionCategory(arr)


            }
        } catch (e: any) {
           
        }
    }

    function ValitarActive() {
        let e = document.getElementById("checkbox-active-marc") as HTMLInputElement;

        if (e.checked) {

            setActive(true)
        } else {

            setActive(false)
        }
    }

    const customStyles = {
        control: (provided: any, state: any) => ({
            ...provided,
            background: '#fff',
            minHeight: '30px',
            height: '30px',
            borderColor: state.isFocused ? 'grey' : '#92989e',

        }),


        valueContainer: (provided: any, state: any) => ({
            ...provided,
            height: '30px',
            padding: '0 6px'
        }),

        input: (provided: any, state: any) => ({
            ...provided,
            margin: '0px',
            fontSize: 14,

        }),
        indicatorsContainer: (provided: any, state: any) => ({
            ...provided,
            height: '30px',
        }),
    };


    return (
        <>
            <div className="container-modal-pric">
                <div className="class-checkbox">
                    <input type="checkbox" id="checkbox-active-marc" checked={active} onChange={ValitarActive} />
                    <h4 className="lab-ativo">Ativo</h4>
                </div>
                <div className="container-cad-prod2">
                    <div id="content1">
                        <div className="input-box-ativos">
                            <span className="details">Descrição *</span>
                            <input
                                type="text"
                                autoComplete="off"
                                onChange={(e) => setDescription(e.target.value)}
                                value={description} />
                        </div>
                    </div>
                    <div id="content2">
                        <div className="input-box-ativos">
                            <span className="details">Identificador Alternativo *</span>
                            <input
                                autoComplete="off"
                                type="text"
                                onChange={(e) => setAlternativeIdentifier(e.target.value)}
                                value={alternativeIdentifier} />
                        </div>
                    </div>
                    <div id="content3">
                        <div className="select-box-ativos-select">
                            <div className='details-flex'>
                                <span className="details">Categoria *</span>
                            </div>
                            <Select
                                maxMenuHeight={90}
                                placeholder={'Selecione...'}
                                options={optionCategory}
                                styles={customStyles}
                                value={optionCategory.filter(function (option: any) {
                                    return option.value === category;
                                })}
                                onChange={(options: any) =>
                                    !options ? setCategory("") : setCategory(options.value)
                                }

                                isClearable
                                noOptionsMessage={() => "Não encontrado..."}
                                theme={(theme: any) => ({
                                    ...theme,
                                    borderRadius: 4,
                                    colors: {
                                        ...theme.colors,
                                        primary25: '#ebebeb',
                                        primary: 'black',
                                    },
                                })}
                                isDisabled={true}
                            />
                        </div>
                    </div>
                </div>
                <div className="Bt-button-cad">
                    <button type="submit" onClick={ConfirmarSubCategoria}>
                        <i className="fa fa-check-circle icon-ok-btn"></i> Salvar
                    </button>
                    <button type="submit" onClick={props.voltar}>
                        <i className="fa fa-times icon-cancelar-btn"></i> Cancelar
                    </button>
                </div>
            </div>

            <ModalAlert isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)}>
                <div className='container-modal-alert'>
                    {qualAlert}
                </div>
            </ModalAlert>
        </>
    );

}

export default FormSubcategoria;