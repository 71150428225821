import React, { useState } from 'react';
import './DualListBox.css';
import ListItem from '../ListItem';

// Definindo a interface para os itens
interface Item {
  id: string;
  label: string;
  ite_alternativeidentifier: string;
  its_id: string;
  ite_tag: string;
}

interface DualListBoxProps {
  items: Item[];
  itemsSel: Item[];
  pegarItens: any
}

const DualListBoxPesonalizado: React.FC<DualListBoxProps> = ({ items, pegarItens, itemsSel }) => {
  const [availableItems, setAvailableItems] = useState<Item[]>(items);
  const [selectedItems, setSelectedItems] = useState<Item[]>(itemsSel);
  const [searchAvailable, setSearchAvailable] = useState<string>('');
  const [searchSelected, setSearchSelected] = useState<string>('');

  const [selectedAvailable, setSelectedAvailable] = useState<string[]>([]);
  const [selectedSelected, setSelectedSelected] = useState<string[]>([]);

  const filterItems = (items: Item[], query: string) => {
    return items.filter(item =>
      item.label.toLowerCase().includes(query.toLowerCase())
    );
  };

  const handleSelectItem = () => {
    const newSelectedItems = [...selectedItems, ...availableItems.filter(i => selectedAvailable.includes(i.id))];
    pegarItens(newSelectedItems);
    setSelectedItems(newSelectedItems);
    setAvailableItems(prevAvailable => prevAvailable.filter(i => !selectedAvailable.includes(i.id)));
    setSelectedAvailable([]);
  };


  const handleDeselectItem = () => {

    if (selectedSelected.length > 0) {
      const currentSelectedItems = selectedItems;
      const currentSelectedSelected = selectedSelected;

      const newAvailableItems = [
        ...availableItems,
        ...currentSelectedItems.filter(i => currentSelectedSelected.includes(i.id))
      ]


      pegarItens(currentSelectedItems.filter(i => !currentSelectedSelected.includes(i.id)));

      // Atualiza os estados
      setAvailableItems(newAvailableItems);
      setSelectedItems(currentSelectedItems.filter(i => !currentSelectedSelected.includes(i.id)));
      setSelectedSelected([]);
    }
  };


  const handleSelectAll = () => {

    const combined = { ...availableItems, ...selectedItems };
    
 
    pegarItens([...availableItems, ...selectedItems]);
    setSelectedItems(prevSelected => [...availableItems, ...selectedItems]);
    setAvailableItems([]);
  };

  const handleDeselectAll = () => {

    setAvailableItems(prevAvailable => [...prevAvailable, ...selectedItems]);
    setSelectedItems([]);
    pegarItens([]);
  };

  return (
    <div className="dual-listbox">
      <div className="listbox">
        <span className="details-list-lab">Itens Disponíveis:</span>
        <div className="input-box-ativos">
          <input
            type="text"
            placeholder="Pesquisar..."
            value={searchAvailable}
            onChange={(e) => setSearchAvailable(e.target.value)}
            className="search-box"
          />
        </div>

        <select
          multiple
          className="list"
          value={selectedAvailable}
          onChange={(e) => {
            const newSelectedAvailable = [...e.target.selectedOptions].map(option => option.value);
            setSelectedAvailable(newSelectedAvailable);
          }}
        >
          {filterItems(availableItems, searchAvailable).map(item => (
            <option key={item.id} value={item.id} className='option-list'>
                  {item.label.length > 30 ? item.label.slice(0, 30) + '...' : item.label}
            </option>
          ))}
        </select>
      </div>
      <div className="buttons">
        <button onClick={handleSelectAll} className="btn-move">&gt;&gt;</button>
        <button onClick={handleSelectItem} className="btn-move">&gt;</button>
        <button onClick={handleDeselectItem} className="btn-move">&lt;</button>
        <button onClick={handleDeselectAll} className="btn-move">&lt;&lt;</button>
      </div>
      <div className="listbox">
        <span className="details-list-lab">Itens Selecionados:</span>
        <div className="input-box-ativos">
          <input
            type="text"
            placeholder="Pesquisar..."
            value={searchSelected}
            onChange={(e) => setSearchSelected(e.target.value)}
            className="search-box"
          />
        </div>
        <select
          multiple
          className="list"
          value={selectedSelected}
          onChange={(e) => {
            const newSelectedSelected = [...e.target.selectedOptions].map(option => option.value);
            setSelectedSelected(newSelectedSelected);
          }}
        >
          {filterItems(selectedItems, searchSelected).map(item => (
            <option key={item.id} value={item.id} className='option-list'>
                  {item.label.length > 30 ? item.label.slice(0, 30) + '...' : item.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default DualListBoxPesonalizado;
