import React, { useState, useEffect } from 'react'
import './index.css'
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { AiFillFileText } from "react-icons/ai";

function AtividadesAtivo(props: any) {


    return (
        <div className='card-visualizacao'>
            <div className='card-overflow-visu'>
                <div className='card-overflow-visu2'>

                    <VerticalTimeline className='Vertical'>
                        {props.data.map((tdata: any, index: any) => (
                            <div className="vertical-tim" key={index}>
                                <VerticalTimelineElement
                                    className="vertical-timeline-element--work"
                                    contentStyle={{ background: '#13293D', color: '#ffffff' }}
                                    contentArrowStyle={{ borderRight: '7px solid  #13293D' }}
                                    dateClassName={"data-teste"}
                                    date={tdata.atv_datetimeinsert.substring(8, 10)+ '/' + tdata.atv_datetimeinsert.substring(5, 7) +'/'+ tdata.atv_datetimeinsert.substring(0, 4) +' ' + tdata.atv_datetimeinsert.substring(11, 16)}
                                    iconStyle={{ background: '#13293D', color: '#fff' }}
                                    icon={<AiFillFileText />}
                                >
                                    <h4 className="vertical-timeline-element-subtitle">{tdata.age_name},</h4>
                                    <p> {tdata.atv_description}</p>
                                </VerticalTimelineElement>
                            </div>
                        ))}
                    </VerticalTimeline>
                </div>
            </div>
        </div>

    );

}

export default AtividadesAtivo;