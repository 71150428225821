
import React, { useState, useEffect } from 'react'
import Nav from "../../components/Nav";
import Header from "../../components/Header";
import './index.css'
import { MdOutlineAddCircle } from 'react-icons/md';
import { AxiosResponse } from "axios";
import api from '../../Services/api'
import { useNavigate } from 'react-router';
import GridSecundario from '../../components/GridSecundario'
import FormContrato from '../../components/FormContrato';
import FormUnidade from '../../components/FormUnidade';
import GridT from '../../components/GridInput';
import Select from 'react-select'
import Loading from '../../components/Loading'
import ModalIncluirNovo from '../../components/ModalIncluirNovo/modal'
import ModalAlert from '../../components/ModalAlert/modal'
import secureLocalStorage from "react-secure-storage";
import { v4 as uuidv4 } from 'uuid';

function MovimentacoesAdd() {

    const [openModal, setOpenModal] = useState(false)
    const [qualAlert, setQualAlert] = useState(<></>)
    const [qualBtn, setQualBtn] = useState(<></>)
    const [qualBtn2, setQualBtn2] = useState(<></>)

    const navigate = useNavigate();

    const [contract, setContract] = useState('');
    const [contractdest, setContractdest] = useState('');

    const [unity, setUnity] = useState('');
    const [destiny, setDestiny] = useState('');
    const [status, setStatus] = useState('');
    const [disponivel, setDisponivel] = useState('');
    const [quantidade, setQuantidade] = useState('');
    const [product, setProduct] = useState("");
    const [dadosProduto, setDadosProduto] = useState([]);
    const [filtrodadosProduto, setFiltrodadosProduto] = useState([]);
    const [modalGrid, setModalGrid] = useState(<></>);
    const [exibirEst, setExibirEst] = useState('');
    const [exibirCont, setExibirCont] = useState('');
    const [observation, setObservation] = useState('');
    const [dadosMovimentacao, setDadosMovimentacao] = useState([]);
    const [dadosMovimentacao2, setDadosMovimentacao2] = useState([]);
    const [qualTitulo, setQualTitulo] = useState('')
    const [qualTitulo2, setQualTitulo2] = useState('')
    const [qualGrid, setQualGrid] = useState(<></>)
    const [openModalIncluirNovo, setOpenModalIncluirNovo] = useState(false)
    const [openModalIncluirNovo2, setOpenModalIncluirNovo2] = useState(false)
    const [optionsContrato, setOptionsContrato] = useState([])
    const [optionsUnidade, setOptionsUnidade] = useState([])
    const [isDisabledContrato, setIsDisabledContrato] = useState(true)
    const [isDisabledUnity, setIsDisabledUnity] = useState(true)
    const [optionsContratodest, setOptionsContratodest] = useState([])
    const [optionsUnidadedest, setOptionsUnidadedest] = useState([])
    const [optionsProduto, setOptionsProduto] = useState([])
    const [optionsAtivo, setOptionsAtivo] = useState([])
    const [optionsMovi, setOptionsMovi] = useState([
        {
            value: 'estoque',
            label: 'Em estoque'
        },
        {
            value: 'contrato',
            label: 'Em contrato'
        }
    ])

    const [optionsAgent, setOptionsAgent] = useState([])
    const [age_id, setAge_id] = useState('');
    const [tea_id, setTea_id] = useState('');
    const [tsf_id, setTsf_id] = useState('');
    const [isDisabledForma, setIsDisabledForma] = useState(true)
    const [optionsEquipe, setOptionsEquipe] = useState([])
    const [optionsForma, setOptionsForma] = useState([])
    const [taskMobile, setTaskMobile] = useState('');

    useEffect(() => {
        validatToken()
    }, []);


    async function validatToken() {

        let ret: AxiosResponse;
        let valor: any;

        const _user = secureLocalStorage.getItem('t')

        api.defaults.headers.common['Authorization'] = _user as ''

        try {

            ret = await api.get("/status/validar")

            if (ret.data.status === 200) {

                secureLocalStorage.setItem('t', ret.data.data);
                secureLocalStorage.setItem('acp', ret.data.decodedToken.acp_integrationid);
                secureLocalStorage.setItem('n', ret.data.decodedToken.name);
                secureLocalStorage.setItem('iag', ret.data.decodedToken.id);
                secureLocalStorage.setItem('cy', ret.data.decodedToken.company);


                await GetContrato();
                await GetAtivo()
                await GetForma()
                await GetEquipe()
                await GetAgent()
            }

        } catch (e: any) {

            if (e.response.data.status === 403) {

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
                    <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)

            }
        }

    }


    async function GetAtivo() {
        let ret: AxiosResponse;
        let valor: any;
        try {
            ret = await api.get("asset/exibir")
            if (ret.data.status === 200) {
                valor = ret.data.data;

                setOptionsAtivo(valor)

            }
        } catch (e) {
            setOptionsAtivo([])
        }
    }

    function fecharModal() {

        secureLocalStorage.removeItem('t')
        secureLocalStorage.removeItem('acp')
        secureLocalStorage.removeItem('n')
        secureLocalStorage.removeItem('iag')
        navigate('/login');
        setOpenModal(false)

    }

    function dadosMov(obj: any, obj2: any) {

        setDadosMovimentacao(obj)
        setDadosMovimentacao2(obj2)

    }

    function voltarMovimentacao() {

        navigate('/movement');
    }

    async function GetUnidadeSel(id: any) {
        let ret: AxiosResponse;
        let valor: any;

        if (id === '' || id === 'Selecione...') {
            setContract('');
            setModalGrid(<></>)
            setOptionsUnidade([])
            setUnity('')
            setContractdest('')
            setDestiny('')
            setOptionsUnidadedest([])
        } else {
            setOptionsUnidadedest([])
            setContractdest('')
            setDestiny('')
            setModalGrid(<></>)
            //  let disabledunity = document.getElementById("unity") as HTMLInputElement;
            //disabledunity.disabled = false;
            setContract(id);
            setUnity('')
            try {
                ret = await api.get("unity/listar", {
                    params: {
                        id: id.split('_')[0]
                    }
                })
                if (ret.data.status === 200) {
                    valor = ret.data.data;
                    const arr = [] as any;
                    let result = valor;
                    result.map((user: any) => {
                        return arr.push({ value: String(user.unt_id) + '_' + user.unt_description, label: user.unt_description });
                    });
                    setOptionsUnidade(arr)

                }
            } catch (e) {
                setOptionsUnidade([]);
            }
        }

    }

    async function GetContrato() {
        let ret: AxiosResponse;
        let valor: any;
        try {
            ret = await api.get("contract/listar")
            if (ret.data.status === 200) {
                valor = ret.data.data;

                const arr = [] as any;
                let result = valor;
                result.map((user: any) => {
                    return arr.push({ value: String(user.ctc_id) + '_' + user.ctc_description, label: user.ctc_description });
                });
                setOptionsContrato(arr)

            }
        } catch (e) {
            setOptionsContrato([])
        }
    }


    function Qstatus(status: any) {
        setModalGrid(<></>)
        setProduct('')
        setDisponivel('');
        setQuantidade('');
        setContractdest('');
        setDestiny('');
        setContract('');
        sessionStorage.removeItem("produto");
        if (status === "") {
            setStatus('')
            setUnity("");
            setContract("");
            setDadosProduto([]);
            // let disabledcontract = document.getElementById("contract") as HTMLInputElement;
            // let disabledunity = document.getElementById("unity") as HTMLInputElement;
            // disabledcontract.disabled = true;
            // disabledunity.disabled = true;
            setIsDisabledContrato(true)
            setIsDisabledUnity(true)

            setExibirEst('N')
            setExibirCont('N')
        } else if (status === 'contrato') {
            //  let disabledcontract = document.getElementById("contract") as HTMLInputElement;
            // disabledcontract.disabled = false;
            //GetContrato();
            setStatus(status);
            setDadosProduto([]);
            setExibirEst('N')
            setExibirCont('S')
            GetContratodest();
            setIsDisabledContrato(false)
            setIsDisabledUnity(false)
        } else if (status === 'estoque') {
            setStatus(status);
            setUnity("");
            setContract("");
            GetProduto();
            GetContratodest();
            setIsDisabledContrato(true)
            setIsDisabledUnity(true)

            setExibirCont('N')
        }

    }

    function getDestiny(unity: any) {
        setModalGrid(<></>)
        if (unity === "") {
            setModalGrid(<></>)
            setUnity('')
            setContractdest('')
            setDestiny('')
            setOptionsUnidadedest([])
        } else {
            setOptionsUnidadedest([])
            setContractdest('')
            setDestiny('')
            setUnity(unity)

        }

    }

    async function GetUnidadeDest(id: any) {
        let ret: AxiosResponse;
        let valor: any;
        setModalGrid(<></>)

        setOptionsUnidadedest([])

        if (id === '' || id === 'Selecione...') {
            setContractdest('')
            setDestiny('')
            setOptionsUnidadedest([]);
        } else {
            setQualAlert(<div className='div-alert'><Loading /></div>)
            setOpenModal(true)
            setDestiny('')
            setContractdest(id)

            try {
                ret = await api.get("unity/listar", {
                    params: {
                        id: id.split('_')[0]
                    }
                })
                if (ret.data.status === 200) {
                    valor = ret.data.data;
                    const arr = [] as any;
                    let result = valor;
                    result.map((user: any) => {
                        return arr.push({ value: String(user.unt_id) + '_' + user.unt_description, label: user.unt_description });
                    });
                    setOptionsUnidadedest(arr)
                }
                setOpenModal(false)
            } catch (e) {
                setOpenModal(false)
                setOptionsUnidadedest([]);
            }
        }
    }

    async function GetUnidadeAtualizar(id: any) {
        let ret: AxiosResponse;
        let valor: any;


        if (id === '' || id === 'Selecione...') {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Selecione o contrato.</span></div>
                <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
            </div>)
            setOpenModal(true)

        } else {

            try {
                ret = await api.get("unity/listar", {
                    params: {
                        id: id.split('_')[0]
                    }
                })
                if (ret.data.status === 200) {
                    valor = ret.data.data;
                    setOptionsUnidadedest(valor);

                    const arr = [] as any;
                    let result = valor;
                    result.map((user: any) => {
                        return arr.push({ value: String(user.unt_id) + '_' + user.unt_description, label: user.unt_description });
                    });
                    setOptionsUnidadedest(arr)

                }
            } catch (e) {
                setOptionsUnidadedest([]);
            }
        }
    }

    async function GetContratodest() {
        let ret: AxiosResponse;
        let valor: any;

        try {
            ret = await api.get("contract/listar")
            if (ret.data.status === 200) {
                valor = ret.data.data;

                const arr = [] as any;
                let result = valor;
                result.map((user: any) => {
                    return arr.push({ value: String(user.ctc_id) + '_' + user.ctc_description, label: user.ctc_description });
                });

                setOptionsContratodest(arr)

            }
        } catch (e) {

            setOptionsContratodest([])
        }
    }



    async function GetProduto() {
        let ret: AxiosResponse;
        let valor: any;

        try {
            ret = await api.get("asset/filtroprodutodisponivel", {
                params: {
                    idstatus: 1,
                }
            })

            if (ret.data.status === 200) {
                valor = ret.data.data;
                setDadosProduto(valor);

                const arr = [] as any;
                let result = valor;
                result.map((user: any) => {
                    return arr.push({ value: String(user.pdc_id), label: user.ast_description });
                });
                setOptionsProduto(arr)
                setExibirEst('S')
            } else {
                setOptionsProduto([])

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Não a ativos disponíveis no estoque.</span></div>
                    <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                </div>)
                setOpenModal(true)

                setExibirEst('N')
            }
        } catch (e) {
            setOptionsProduto([])
        }
    }

    const renderProduto = dadosProduto.map((comp: any) => {
        return (
            <option key={comp.pdc_id} value={comp.pdc_id}>
                {" "}
                {comp.ast_description}{" "}
            </option>
        );
    });

    async function GetProdutoQuant(produto: any) {
        let ret: AxiosResponse;
        let valor: any;
        setModalGrid(<></>)

        setProduct(produto)

        if (produto === "") {
            setProduct("")
            setDisponivel("")
            setQuantidade("")
        } else {
            try {
                ret = await api.get("asset/filtroprodutoestoque", {
                    params: {
                        idproduto: produto,
                        idstatus: 1,
                    }
                })

                if (ret.data.status === 200) {
                    valor = ret.data.data;
                    setDisponivel(valor.length)
                    setFiltrodadosProduto(valor)
                    setQuantidade("")
                } else {
                    setDisponivel('0')
                }
            } catch (e) { }
        }
    }

    function validarQuantidade() {

        setModalGrid(<></>)
        sessionStorage.removeItem("produto");

        if (contractdest === "") {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Informe o contrato de destino.</span></div>
                <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
            </div>)
            setOpenModal(true)

        } else if (destiny === "") {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Informe a unidade de destino.</span></div>
                <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
            </div>)
            setOpenModal(true)

        } else
            if (product === '') {


                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Selecione o produto desejado.</span></div>
                    <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                </div>)
                setOpenModal(true)


            }
            else {

                setModalGrid(<div className='Grid-div'><GridT
                    ambiente={destiny.split('_')[0]}
                    params1={product.split('_')[0]}
                    params2={destiny.split('_')[0]}
                    title1='ID'
                    title2='Descrição'
                    title3='Local'
                    title4='Usuario'
                    title5='Número de serie'
                    title6='MAC'
                    title7='Tombamento'
                    title8='Identificador Alternativo'
                    tombamento='S'
                    dadosMov={dadosMov}
                    salvarMovimentacao={salvarMovimentacao}
                    voltarMovimentacao={voltarMovimentacao}
                    tipoMov={'estoque'}
                /></div>)


            }
    }

    function GetDestiny(destino: any) {
        setModalGrid(<></>)
        if (destino === "Selecione...") {
            setDestiny('')
        } else {
            setDestiny(destino)
        }
    }



    function ListarProdCont() {

        setModalGrid(<></>)

        if (contract === '') {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Selecione o contrato de origem.</span></div>
                <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
            </div>)
            setOpenModal(true)

        } else
            if (unity === '') {

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Selecione a unidade de origem.</span></div>
                    <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                </div>)
                setOpenModal(true)

            } else
                if (contractdest === '') {

                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Selecione o contrato de destino.</span></div>
                        <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                    </div>)
                    setOpenModal(true)

                } else if (destiny === '') {

                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Selecione a unidade de destino.</span></div>
                        <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                    </div>)
                    setOpenModal(true)

                } else {

                    setModalGrid(<div className='Grid-div'><GridT
                        ambiente={destiny.split('_')[0]}
                        params1={product.split('_')[0]}
                        params2={unity.split('_')[0]}
                        title1='ID'
                        title2='Descrição'
                        title3='Local'
                        title4='Usuario'
                        title5='Número de serie'
                        title6='MAC'
                        title7='Tombamento'
                        title8='Identificador Alternativo'
                        tombamento='S'
                        dadosMov={dadosMov}
                        salvarMovimentacao={salvarMovimentacao}
                        voltarMovimentacao={voltarMovimentacao}
                        tipoMov={'contrato'}
                    /></div>)
                }
    }

    async function salvarMovimentacao() {
        setQualAlert(<div className='div-alert'><Loading /></div>)
        setOpenModal(true)

        let temp_rows: any;
        let temp_rows_alternativeIdentifier: any;
        let temp_rows_local: any;
        let validar_alternativeIdentifier: any;
        let validar_numeroSerie: any;
        let validar_tombamento: any;


        temp_rows = dadosMovimentacao.filter(
            (e: any) => {
                return e.ok === true
            }
        );

        temp_rows_alternativeIdentifier = dadosMovimentacao.filter(
            (e: any) => {
                return e.ok === true && e.alternativeIdentifier === "" || e.alternativeIdentifier === null
            }
        );

        temp_rows_local = dadosMovimentacao.filter(
            (e: any) => {
                return e.ok === true && e.departamento === "" || e.departamento === null
            }
        );

        let newArray_validar_alternativeIdentifier = [] as any;

        if (temp_rows.length > 0) {

            temp_rows.map((item: any, index: any) => (

                validar_alternativeIdentifier = dadosMovimentacao2.filter(
                    (e: any) => {

                        if (e.alternativeIdentifier === item.alternativeIdentifier 
                            && e.campoId !== item.campoId && item.alternativeIdentifier !=="") {
                            newArray_validar_alternativeIdentifier.push({
                                alternativeIdentifier: e.alternativeIdentifier
                            })
                        }

                    }
                )
            ))
        }

        let newArray_validar_numeroSerie = [] as any;

        if (temp_rows.length > 0) {
            temp_rows.map((item: any, index: any) => (

                validar_numeroSerie = dadosMovimentacao2.filter(
                    (e: any) => {

                        if (e.numeroSerie === item.numeroSerie && e.campoId 
                            !== item.campoId && item.numeroSerie !== ""
                            ) {
                            newArray_validar_numeroSerie.push({
                                numeroSerie: e.numeroSerie
                            })
                        }


                    }
                )
            ))
        }

        let newArray_validar_tombamento = [] as any;

        if (temp_rows.length > 0) {
            temp_rows.map((item: any, index: any) => (

                validar_tombamento = dadosMovimentacao2.filter(
                    (e: any) => {

                        if (e.tombamento === item.tombamento && 
                            e.campoId !== item.campoId && item.tombamento !== ""
                            ) {
                            newArray_validar_tombamento.push({
                                tombamento: e.tombamento
                            })
                        }


                    }
                )

            ))
        }

        if (temp_rows.length === 0) {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Selecione ao menos um ativo.</span></div>
                <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
            </div>)
            setOpenModal(true)

        } else if (temp_rows_alternativeIdentifier.length > 0) {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Digite o identificador alternativo dos ativos que deseja movimentar.</span></div>
                <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
            </div>)
            setOpenModal(true)

        } else if (newArray_validar_alternativeIdentifier.length > 0) {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">{`O identificador alternativo  '${newArray_validar_alternativeIdentifier[0].alternativeIdentifier}' já está em uso.`}</span></div>
                <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
            </div>)
            setOpenModal(true)
        } else if (newArray_validar_numeroSerie.length > 0) {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">{`O Número de serie '${newArray_validar_numeroSerie[0].numeroSerie}' já está em uso.`}</span></div>
                <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
            </div>)
            setOpenModal(true)
        } else if (newArray_validar_tombamento.length > 0) {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">{`O tombamento '${newArray_validar_tombamento[0].tombamento}' já está em uso.`}</span></div>
                <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
            </div>)
            setOpenModal(true)
        }
        else if (temp_rows_local.length > 0) {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Selecione o local onde o ativo vai ser vinculado.</span></div>
                <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
            </div>)
            setOpenModal(true)

        } else {

            let ret: AxiosResponse;
            let i = 0
            let newArray2 = []

            for (i = 0; i < temp_rows.length; i++) {
                newArray2.push({
                    ast_id: temp_rows[i].campoId,
                    mov_sourceunit: unity.split('_')[0] || null,
                    mov_destinyunit: destiny.split('_')[0],
                    mov_destinyloc: temp_rows[i].departamento,
                    mov_usedby: temp_rows[i].usuario,
                    mov_observation: observation,
                    age_id_insert: secureLocalStorage.getItem('iag'),
                    mov_type: status
                })
                //OBSERVAÇÃO: ALTERAR O USUÁRIO QUE REALIZOU A MOVIMENTAÇÃO
            }

            let newArray = []

            for (i = 0; i < temp_rows.length; i++) {
                newArray.push({ age_id_lastupdate: secureLocalStorage.getItem('iag'), ast_mac: temp_rows[i].mac, ast_usedby: temp_rows[i].usuario, ast_serialnumber: temp_rows[i].numeroSerie, loc_id: temp_rows[i].departamento, ast_tombamento: temp_rows[i].tombamento, ast_id: temp_rows[i].campoId, ssa_id: '2', unt_id: destiny.split('_')[0], ctc_id: contractdest.split('_')[0], ast_alternativeidentifier: temp_rows[i].alternativeIdentifier })
            }

            let newArray3 = []

            for (i = 0; i < temp_rows.length; i++) {
                newArray3.push({ ast_id: temp_rows[i].campoId, age_id: secureLocalStorage.getItem('iag'), atv_type: 'movimentacao', atv_description: `Realizou uma movimentação de estoque para o contrato ${contractdest.split('_')[1]} unidade ${destiny.split('_')[1]}.`, atv_idcontrol: temp_rows[i].campoId })
            }

            try {
                ret = await api.post("movement/insert", {
                    data: newArray2,
                    dataUp: newArray,
                    dataActivy: newArray3
                });
                if (ret.data.status === 200) {
                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-check ControleIconAlert"></span>
                        <div className='div-info-alert'><span className="info-alert">Movimentação realizada com sucesso.</span></div>
                        <div className='btn-alert' onClick={finalizarModal1}><span className="btn-alert-lab">OK</span></div>
                    </div>)


                }

            } catch (e: any) {
                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert'><span className="info-alert">Não é possível realizar a movimentação</span></div>
                    <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                </div>)
            }

        }
    }

    function finalizarModal1() {
        navigate('/movement');
    }

    function finalizarModal2() {
        setOpenModal(false)
    }

    function direcionamentoSalvarMovimentacao() {

        setQualTitulo2('Tipo de Inclusão')
        setQualBtn(<div className="Bt-button-cad">
            <button type="submit" onClick={() => salvarMovimentacao()}>
                <i className="fa fa-check-circle icon-ok-btn" ></i> Salvar
            </button>
            <button type="submit" onClick={() => fecharOpenModalIncluirNovo2()}>
                <i className="fa fa-times icon-cancelar-btn"></i> Cancelar
            </button>
        </div>)

        setQualBtn2(<div className="Bt-button-cad">
            <button type="submit" onClick={() => inserttaskmovement()}>
                <i className="fa fa-check-circle icon-ok-btn"></i> Salvar
            </button>
            <button type="submit" onClick={() => fecharOpenModalIncluirNovo2()}>
                <i className="fa fa-times icon-cancelar-btn"></i> Cancelar
            </button>
        </div>)

        setOpenModalIncluirNovo2(true)

    }

    function direcionamentoSalvarMovimentacaoCont() {

        setQualTitulo2('Tipo de Inclusão')
        setQualBtn(<div className="Bt-button-cad">
            <button type="submit" onClick={() => salvarMovimentacaoCont()}>
                <i className="fa fa-check-circle icon-ok-btn" ></i> Salvar
            </button>
            <button type="submit" onClick={() => fecharOpenModalIncluirNovo2()}>
                <i className="fa fa-times icon-cancelar-btn"></i> Cancelar
            </button>
        </div>)

        setQualBtn2(<div className="Bt-button-cad">
            <button type="submit" onClick={() => inserttaskmovement()}>
                <i className="fa fa-check-circle icon-ok-btn"></i> Salvar
            </button>
            <button type="submit" onClick={() => fecharOpenModalIncluirNovo2()}>
                <i className="fa fa-times icon-cancelar-btn"></i> Cancelar
            </button>
        </div>)

        setOpenModalIncluirNovo2(true)

    }

    async function GetAgent() {
        let ret: AxiosResponse;
        let valor: any;
        try {
            ret = await api.get("user/listar")
            if (ret.data.status === 200) {
                valor = ret.data.data;
                const arr = [] as any;
                let result = valor;
                result.map((user: any) => {
                    return arr.push({ value: String(user.age_id), label: user.age_name });
                });

                setOptionsAgent(arr)

            }
        } catch (e) {
            setOptionsAgent([])
        }
    }

    function SelecionarAgent(id: any) {



        if (id === '') {
            setAge_id("")
            setTea_id("")
            setTsf_id("")
            setIsDisabledForma(true)
        } else {
            setTea_id("")
            setTsf_id("")
            setAge_id(id)
            setIsDisabledForma(true)
        }
    }


    async function GetEquipe() {
        let ret: AxiosResponse;
        let valor: any;
        try {
            ret = await api.get("team/listar")
            if (ret.data.status === 200) {
                valor = ret.data.data;
                const arr = [] as any;
                let result = valor;
                result.map((user: any) => {
                    return arr.push({ value: String(user.tae_id), label: user.tae_description });
                });

                setOptionsEquipe(arr)

            }
        } catch (e) {
            setOptionsEquipe([])
        }
    }

    function SelecionarEquipe(id: any) {

        if (id === '') {
            setTea_id("")
            setAge_id("")
            setTsf_id("")
            setIsDisabledForma(true)
        } else {
            setAge_id("")
            setTea_id(id)
            setIsDisabledForma(false)
        }
    }

    async function GetForma() {
        let ret: AxiosResponse;
        let valor: any;
        try {
            ret = await api.get("taskform/listar")
            if (ret.data.status === 200) {
                valor = ret.data.data;
                const arr = [] as any;
                let result = valor;
                result.map((user: any) => {
                    return arr.push({ value: String(user.tsf_id), label: user.tsf_description });
                });

                setOptionsForma(arr)

            }
        } catch (e) {
            setOptionsForma([])
        }
    }

    async function inserttaskmovement() {
        let temp_rows: any;
        let temp_rows_alternativeIdentifier: any;
        let temp_rows_local: any;
        let validar_alternativeIdentifier: any;
        let validar_numeroSerie: any;
        let validar_tombamento: any;

        setQualAlert(<div className='div-alert'><Loading /></div>)
        setOpenModal(true)

        temp_rows = dadosMovimentacao.filter(
            (e: any) => {
                return e.ok === true
            }
        );

        temp_rows_alternativeIdentifier = dadosMovimentacao.filter(
            (e: any) => {
                return e.ok === true && e.alternativeIdentifier === "" || e.alternativeIdentifier === null
            }
        );

        temp_rows_local = dadosMovimentacao.filter(
            (e: any) => {
                return e.ok === true && e.departamento === "" || e.departamento === null
            }
        );

        let newArray_validar_alternativeIdentifier = [] as any;

        if (temp_rows.length > 0) {

            temp_rows.map((item: any, index: any) => (

                validar_alternativeIdentifier = dadosMovimentacao2.filter(
                    (e: any) => {

                        if (e.alternativeIdentifier === item.alternativeIdentifier 
                            && e.campoId !== item.campoId && item.alternativeIdentifier !== '') {
                            newArray_validar_alternativeIdentifier.push({
                                alternativeIdentifier: e.alternativeIdentifier
                            })
                        }

                    }
                )
            ))
        }

        let newArray_validar_numeroSerie = [] as any;

        if (temp_rows.length > 0) {
            temp_rows.map((item: any, index: any) => (

                validar_numeroSerie = dadosMovimentacao2.filter(
                    (e: any) => {

                        if (e.numeroSerie === item.numeroSerie 
                            && e.campoId !== item.campoId && item.numeroSerie !== "") {
                            newArray_validar_numeroSerie.push({
                                numeroSerie: e.numeroSerie
                            })
                        }


                    }
                )
            ))
        }

        let newArray_validar_tombamento = [] as any;

        if (temp_rows.length > 0) {
            temp_rows.map((item: any, index: any) => (

                validar_tombamento = dadosMovimentacao2.filter(
                    (e: any) => {

                        if (e.tombamento === item.tombamento 
                            && e.campoId !== item.campoId &&  item.tombamento !== '') {
                            newArray_validar_tombamento.push({
                                tombamento: e.tombamento
                            })
                        }


                    }
                )

            ))
        }

        if (temp_rows.length === 0) {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Selecione ao menos um ativo.</span></div>
                <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
            </div>)
            setOpenModal(true)

        } else if (temp_rows_alternativeIdentifier.length > 0) {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Digite o identificador alternativo dos ativos que deseja movimentar.</span></div>
                <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
            </div>)
            setOpenModal(true)

        } else if (newArray_validar_alternativeIdentifier.length > 0) {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">{`O identificador alternativo  '${newArray_validar_alternativeIdentifier[0].alternativeIdentifier}' já está em uso.`}</span></div>
                <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
            </div>)
            setOpenModal(true)
        } else if (newArray_validar_numeroSerie.length > 0) {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">{`O Número de serie '${newArray_validar_numeroSerie[0].numeroSerie}' já está em uso.`}</span></div>
                <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
            </div>)
            setOpenModal(true)
        } else if (newArray_validar_tombamento.length > 0) {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">{`O tombamento '${newArray_validar_tombamento[0].tombamento}' já está em uso.`}</span></div>
                <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
            </div>)
            setOpenModal(true)
        }
        else if (temp_rows_local.length > 0) {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Selecione o local onde o ativo vai ser vinculado.</span></div>
                <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
            </div>)
            setOpenModal(true)

        } else {

            setAge_id("")
            setTea_id("")
            setTsf_id("")
            setOpenModal(false)
            setQualTitulo('Movimentação')
            setTaskMobile('S')
            setOpenModalIncluirNovo(true)

        }
    }

    function finalizarModal4() {
        setAge_id("")
        setTea_id("")
        setTsf_id("")
        setOpenModal(false)
        setTaskMobile('N')
        setOpenModalIncluirNovo(false)
    }

    async function confirmarinserttaskmovement() {


        if (age_id === "" && tea_id === "") {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Selecione quem vai executar a tarefa (Usuário ou Equipe).</span></div>
                <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
            </div>)

            setOpenModal(true)


        } else if (tea_id !== "" && tsf_id === "") {


            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Selecione a forma de execução da equipe.</span></div>
                <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
            </div>)

            setOpenModal(true)

        } else {

            setQualAlert(<div className='div-alert'><Loading /></div>)
            setOpenModal(true)


            let temp_rows: any;

            temp_rows = dadosMovimentacao.filter(
                (e: any) => {
                    return e.ok === true
                }
            );

            let ret: AxiosResponse;
            let i = 0
            let newArray2 = []
            let newArray3 = []
           

            for (i = 0; i < temp_rows.length; i++) {


                const newUuid = uuidv4()

                newArray2.push({
                    ast_id: temp_rows[i].campoId,
                    mov_sourceunit: unity.split('_')[0] || null,
                    mov_destinyunit: destiny.split('_')[0],
                    mov_destinyloc: temp_rows[i].departamento,
                    mov_usedby: temp_rows[i].usuario,
                    mov_observation: observation,
                    age_id_insert: secureLocalStorage.getItem('iag'),
                    mov_type: status,
                    tsk_accesstoken: newUuid
                })
                //OBSERVAÇÃO: ALTERAR O USUÁRIO QUE REALIZOU A MOVIMENTAÇÃO
            }

            let newArray = []
            
            for (i = 0; i < temp_rows.length; i++) {

                
                newArray.push({
                    age_id_lastupdate: secureLocalStorage.getItem('iag'),
                    ast_mac: temp_rows[i].mac,
                    ast_usedby: temp_rows[i].usuario,
                    ast_serialnumber: temp_rows[i].numeroSerie,
                    loc_id: temp_rows[i].departamento,
                    ast_tombamento: temp_rows[i].tombamento,
                    ast_id: temp_rows[i].campoId,
                    ssa_id: '2',
                    unt_id: destiny.split('_')[0],
                    ctc_id: contractdest.split('_')[0],
                    ast_alternativeidentifier: temp_rows[i].alternativeIdentifier,
                   
                })


            }

  

            for (i = 0; i < temp_rows.length; i++) {

                newArray3.push({
                    ast_id: temp_rows[i].campoId,
                    atv_type: 'movimentacao',
                    atv_description: `Criou uma tarefa do tipo movimentação da unidade ${unity.split('_')[1]} para o contrato ${contractdest.split('_')[1]} unidade ${destiny.split('_')[1]}.`,
                    atv_idcontrol: temp_rows[i].campoId,
                    age_id: age_id || null,
                    tea_id: tea_id || null,
                    tsf_id: tsf_id || null,
                    age_id_insert: secureLocalStorage.getItem('iag'),
                })
            }


            try {
                ret = await api.post("movement/inserttaskmovement", {
                    data: newArray2,
                    dataUp: newArray,
                    dataActivy: newArray3
                });
                if (ret.data.status === 200) {

                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-check ControleIconAlert"></span>
                        <div className='div-info-alert'><span className="info-alert">Tarefas criadas com sucesso.</span></div>
                        <div className='btn-alert' onClick={finalizarModal1}><span className="btn-alert-lab">OK</span></div>
                    </div>)


                }

            } catch (e: any) {
                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert'><span className="info-alert">Não é possível realizar a movimentação</span></div>
                    <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                </div>)
            }

        }


    }


    async function salvarMovimentacaoCont() {

        let temp_rows: any;
        let temp_rows_alternativeIdentifier: any;
        let temp_rows_local: any;
        let validar_alternativeIdentifier: any;
        let validar_numeroSerie: any;
        let validar_tombamento: any;

        setQualAlert(<div className='div-alert'><Loading /></div>)
        setOpenModal(true)

        temp_rows = dadosMovimentacao.filter(
            (e: any) => {
                return e.ok === true
            }
        );

        temp_rows_alternativeIdentifier = dadosMovimentacao.filter(
            (e: any) => {
                return e.ok === true && e.alternativeIdentifier === "" || e.alternativeIdentifier === null
            }
        );

        temp_rows_local = dadosMovimentacao.filter(
            (e: any) => {
                return e.ok === true && e.departamento === "" || e.departamento === null
            }
        );

        let newArray_validar_alternativeIdentifier = [] as any;

        if (temp_rows.length > 0) {

            temp_rows.map((item: any, index: any) => (

                validar_alternativeIdentifier = dadosMovimentacao2.filter(
                    (e: any) => {

                        if (e.alternativeIdentifier === item.alternativeIdentifier 
                            && e.campoId !== item.campoId && item.alternativeIdentifier !== '') {
                            newArray_validar_alternativeIdentifier.push({
                                alternativeIdentifier: e.alternativeIdentifier
                            })
                        }

                    }
                )
            ))
        }

        let newArray_validar_numeroSerie = [] as any;

        if (temp_rows.length > 0) {
            temp_rows.map((item: any, index: any) => (

                validar_numeroSerie = dadosMovimentacao2.filter(
                    (e: any) => {

                        if (e.numeroSerie === item.numeroSerie 
                            && e.campoId !== item.campoId && item.numeroSerie !== '') {
                            newArray_validar_numeroSerie.push({
                                numeroSerie: e.numeroSerie
                            })
                        }


                    }
                )
            ))
        }

        let newArray_validar_tombamento = [] as any;

        if (temp_rows.length > 0) {
            temp_rows.map((item: any, index: any) => (

                validar_tombamento = dadosMovimentacao2.filter(
                    (e: any) => {

                        if (e.tombamento === item.tombamento && e.campoId !== item.campoId 
                            && item.tombamento !== "") {
                            newArray_validar_tombamento.push({
                                tombamento: e.tombamento
                            })
                        }


                    }
                )

            ))
        }

        if (temp_rows.length === 0) {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Selecione ao menos um ativo.</span></div>
                <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
            </div>)
            setOpenModal(true)

        } else if (temp_rows_alternativeIdentifier.length > 0) {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Digite o identificador alternativo dos ativos que deseja movimentar.</span></div>
                <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
            </div>)
            setOpenModal(true)

        } else if (newArray_validar_alternativeIdentifier.length > 0) {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">{`O identificador alternativo  '${newArray_validar_alternativeIdentifier[0].alternativeIdentifier}' já está em uso.`}</span></div>
                <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
            </div>)
            setOpenModal(true)
        } else if (newArray_validar_numeroSerie.length > 0) {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">{`O Número de serie '${newArray_validar_numeroSerie[0].numeroSerie}' já está em uso.`}</span></div>
                <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
            </div>)
            setOpenModal(true)
        } else if (newArray_validar_tombamento.length > 0) {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">{`O tombamento '${newArray_validar_tombamento[0].tombamento}' já está em uso.`}</span></div>
                <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
            </div>)
            setOpenModal(true)
        } else if (temp_rows_local.length > 0) {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Selecione o local onde o ativo vai ser vinculado.</span></div>
                <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
            </div>)
            setOpenModal(true)

        } else {
            let ret: AxiosResponse;
            let i = 0
            let newArray2 = []


            for (i = 0; i < temp_rows.length; i++) {

                newArray2.push({
                    ast_id: temp_rows[i].campoId,
                    mov_sourceunit: unity.split('_')[0] || null,
                    mov_destinyunit: destiny.split('_')[0],
                    mov_destinyloc: temp_rows[i].departamento,
                    mov_usedby: temp_rows[i].usuario,
                    mov_observation: observation,
                    age_id_insert: secureLocalStorage.getItem('iag'),
                    mov_type: status,
                })
                //OBSERVAÇÃO: ALTERAR O USUÁRIO QUE REALIZOU A MOVIMENTAÇÃO
            }

            let newArray = []

            for (i = 0; i < temp_rows.length; i++) {
                newArray.push({
                    age_id_lastupdate: secureLocalStorage.getItem('iag'),
                    ast_mac: temp_rows[i].mac,
                    ast_usedby: temp_rows[i].usuario,
                    ast_serialnumber: temp_rows[i].numeroSerie,
                    loc_id: temp_rows[i].departamento,
                    ast_tombamento: temp_rows[i].tombamento,
                    ast_id: temp_rows[i].campoId,
                    ssa_id: '2',
                    unt_id: destiny.split('_')[0],
                    ctc_id: contractdest.split('_')[0],
                    ast_alternativeidentifier: temp_rows[i].alternativeIdentifier
                })
            }

            let newArray3 = []


            for (i = 0; i < temp_rows.length; i++) {

                newArray3.push({
                    ast_id: temp_rows[i].campoId,
                    age_id: secureLocalStorage.getItem('iag'), atv_type: 'movimentacao',
                    atv_description: `Realizou uma movimentação da unidade ${unity.split('_')[1]} para o contrato ${contractdest.split('_')[1]} unidade ${destiny.split('_')[1]}.`,
                    atv_idcontrol: temp_rows[i].campoId
                })
            }


            try {
                ret = await api.post("movement/insert", {
                    data: newArray2,
                    dataUp: newArray,
                    dataActivy: newArray3
                });
                if (ret.data.status === 200) {

                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-check ControleIconAlert"></span>
                        <div className='div-info-alert'><span className="info-alert">Movimentação realizada com sucesso.</span></div>
                        <div className='btn-alert' onClick={finalizarModal1}><span className="btn-alert-lab">OK</span></div>
                    </div>)


                }

            } catch (e: any) {
                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert'><span className="info-alert">Não é possível realizar a movimentação</span></div>
                    <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                </div>)
            }


        }
    }

    //=================================Contrato=============================================
    function incluirContrato() {

        setQualTitulo('Cadastrar Contrato')
        setQualGrid((<><GridSecundario
            PegarValorGrid={PegarValorGridContrato}
            Iconeadd={ChamarCadastroContrato}
            Iconeditar={EditarContrato}
            iconeExcluir={ContratoDeletar}
            th1='Ativo'
            th2='ID'
            th3='Descrição'
            th4='Identificador Alternativo'
            th5=''
            th6=''
            endPoint='contract/buscarcontract'
            consult='N'
            col1='1'
            col2='2'
            col3='3'
            ambiente={'%'}
            dbtnDelete="N"
            pag="ok"
        /></>))

        setOpenModalIncluirNovo(true)
    }

    function PegarValorGridContrato(valor: any) {
        setOpenModalIncluirNovo(false)
        GetUnidadeDest(String(valor.ctc_id) + '_' + valor.ctc_description)

    }



    function ChamarCadastroContrato() {
        sessionStorage.removeItem("dadosCon");
        setQualGrid(<><FormContrato voltar={BtnVoltarContrato} /></>)
    }

    async function EditarContrato(obj: any) {
        sessionStorage.setItem("dadosCon", JSON.stringify(obj));
        setQualGrid(<><FormContrato voltar={BtnVoltarContrato} /></>)
    }

    function ContratoDeletar(obj: any) {

        setQualAlert(<div className='div-alert'>
            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
            <div className='div-info-alert-atencao'><span className="info-alert-atencao">Deseja deletar o registro?</span></div>
            <div className='div-info-alert'><span className="info-alert">Você não poderá reverter.</span></div>

            <div className='btn-alert-div'>
                <span className='btn-alert-esc' onClick={() => deletarDeletar(obj.ctc_id)}>
                    <span className="btn-alert-lab">SIM</span>
                </span>
                <span className='btn-alert-dir' onClick={finalizarModal2}>
                    <span className="btn-alert-lab">NÃO</span>
                </span>
            </div>
        </div>)

        setOpenModal(true)

    }


    async function deletarDeletar(id: any) {
        setOpenModal(false)
        setQualGrid(<><Loading /></>)
        let ret: AxiosResponse;
        try {
            ret = await api.delete("contract/delete", {
                data: { id: id }
            });
            if (ret.data.status === 200) {

                setQualGrid((<><GridSecundario
                    PegarValorGrid={PegarValorGridContrato}
                    Iconeadd={ChamarCadastroContrato}
                    Iconeditar={EditarContrato}
                    iconeExcluir={ContratoDeletar}
                    th1='Ativo'
                    th2='ID'
                    th3='Descrição'
                    th4='Identificador Alternativo'
                    th5=''
                    th6=''
                    endPoint='contract/buscarcontract'
                    consult='N'
                    col1='1'
                    col2='2'
                    col3='3'
                    ambiente={'%'}
                    dbtnDelete="N"
                    pag="ok"
                /></>))
                GetContratodest()

            }
        } catch (e: any) {
            setQualGrid((<><GridSecundario
                PegarValorGrid={PegarValorGridContrato}
                Iconeadd={ChamarCadastroContrato}
                Iconeditar={EditarContrato}
                iconeExcluir={ContratoDeletar}
                th1='Ativo'
                th2='ID'
                th3='Descrição'
                th4='Identificador Alternativo'
                th5=''
                th6=''
                endPoint='contract/buscarcontract'
                consult='N'
                col1='1'
                col2='2'
                col3='3'
                ambiente={'%'}
                dbtnDelete="N"
                pag="ok"
            /></>))
            GetContratodest()
        }
    }

    function BtnVoltarContrato() {
        setQualGrid((<><GridSecundario
            PegarValorGrid={PegarValorGridContrato}
            Iconeadd={ChamarCadastroContrato}
            Iconeditar={EditarContrato}
            iconeExcluir={ContratoDeletar}
            th1='Ativo'
            th2='ID'
            th3='Descrição'
            th4='Identificador Alternativo'
            th5=''
            th6=''
            endPoint='contract/buscarcontract'
            consult='N'
            col1='1'
            col2='2'
            col3='3'
            ambiente={'%'}
            dbtnDelete="N"
            pag="ok"
        /></>))
        GetContrato()
        GetContratodest()
    }
    //=================================Contrato=============================================
    //=================================Unidade=============================================  
    function incluirUnidade() {

        if (contractdest === '') {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Selecione o contrato de destino para adicionar uma nova unidade.</span></div>
                <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
            </div>)
            setOpenModal(true)

        } else {

            setDestiny("")
            setQualTitulo('Cadastrar Unidade')
            setQualGrid((<><GridSecundario
                PegarValorGrid={PegarValorGridUnidade}
                Iconeadd={ChamarCadastroUnidade}
                Iconeditar={EditarUnidade}
                iconeExcluir={UnidadeDeletar}
                th1='Ativo'
                th2='ID'
                th3='Descrição'
                th4='Identificador Alternativo'
                th5=''
                th6=''
                endPoint='unity/buscarunity'
                consult='N'
                col1='1'
                col2='2'
                col3='3'
                params={contractdest.split('_')[0]}
                dbtnDelete="N"
                pag="ok"
            /></>))
            GetUnidadeDest(contractdest)
            setOpenModalIncluirNovo(true)
        }


    }

    function PegarValorGridUnidade(valor: any) {
        setOpenModalIncluirNovo(false)
        GetDestiny(String(valor.unt_id) + '_' + valor.unt_description)

    }

    function ChamarCadastroUnidade() {
        sessionStorage.removeItem("dadosUni");
        setQualGrid(<><FormUnidade ambiente={contractdest.split('_')[0]} manual={'SIM'} voltar={BtnVoltarUnidade} ChamarCadastroContratoUni={ChamarCadastroContratoUni} /></>)
        GetUnidadeDest(contractdest)
    }

    async function EditarUnidade(obj: any) {
        sessionStorage.setItem("dadosUni", JSON.stringify(obj));
        setQualGrid(<><FormUnidade ambiente={contractdest.split('_')[0]} manual={'SIM'} voltar={BtnVoltarUnidade} ChamarCadastroContratoUni={ChamarCadastroContratoUni} /></>)
        GetUnidadeDest(contractdest)
    }

    function ChamarCadastroContratoUni() {
        sessionStorage.removeItem("dadosCon");
        setQualGrid(<><FormContrato voltar={ChamarCadastroUnidadeform} /></>)
        GetUnidadeDest(contractdest)
    }


    function ChamarCadastroUnidadeform() {
        setQualGrid(<><FormUnidade voltar={BtnVoltarUnidade} ChamarCadastroContratoUni={ChamarCadastroContratoUni} /></>)
    }

    function BtnVoltarUnidade() {
        GetUnidadeDest(contractdest)
        setQualGrid((<><GridSecundario
            PegarValorGrid={PegarValorGridUnidade}
            Iconeadd={ChamarCadastroUnidade}
            Iconeditar={EditarUnidade}
            iconeExcluir={UnidadeDeletar}
            th1='Ativo'
            th2='ID'
            th3='Descrição'
            th4='Identificador Alternativo'
            th5=''
            th6=''
            endPoint='unity/buscarunity'
            consult='N'
            col1='1'
            col2='2'
            col3='3'
            params={contractdest.split('_')[0]}
            dbtnDelete="N"
            pag="ok"
        /></>))
    }

    function UnidadeDeletar(obj: any) {

        setQualAlert(<div className='div-alert'>
            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
            <div className='div-info-alert-atencao'><span className="info-alert-atencao">Deseja deletar o registro?</span></div>
            <div className='div-info-alert'><span className="info-alert">Você não poderá reverter.</span></div>

            <div className='btn-alert-div'>
                <span className='btn-alert-esc' onClick={() => DeletarUnidade(obj.unt_id)}>
                    <span className="btn-alert-lab">SIM</span>
                </span>
                <span className='btn-alert-dir' onClick={finalizarModal2}>
                    <span className="btn-alert-lab">NÃO</span>
                </span>
            </div>
        </div>)

        setOpenModal(true)


    }

    async function DeletarUnidade(id: any) {
        setOpenModal(false)
        setQualGrid(<><Loading /></>)
        let ret: AxiosResponse;
        try {
            ret = await api.delete("unity/delete", {
                data: { id: id }
            });
            if (ret.data.status === 200) {

                setQualGrid((<><GridSecundario
                    PegarValorGrid={PegarValorGridUnidade}
                    Iconeadd={ChamarCadastroUnidade}
                    Iconeditar={EditarUnidade}
                    iconeExcluir={UnidadeDeletar}
                    th1='Ativo'
                    th2='ID'
                    th3='Descrição'
                    th4='Identificador Alternativo'
                    th5=''
                    th6=''
                    endPoint='unity/buscarunity'
                    consult='N'
                    col1='1'
                    col2='2'
                    col3='3'
                    params={contractdest.split('_')[0]}
                    dbtnDelete="N"
                    pag="ok"
                /></>))
                GetUnidadeDest(contractdest)
            }
        } catch (e: any) {
            setQualGrid((<><GridSecundario
                PegarValorGrid={PegarValorGridUnidade}
                Iconeadd={ChamarCadastroUnidade}
                Iconeditar={EditarUnidade}
                iconeExcluir={UnidadeDeletar}
                th1='Ativo'
                th2='ID'
                th3='Descrição'
                th4='Identificador Alternativo'
                th5=''
                th6=''
                endPoint='unity/buscarunity'
                consult='N'
                col1='1'
                col2='2'
                col3='3'
                params={contractdest.split('_')[0]}
                dbtnDelete="N"
                pag="ok"
            /></>))
            GetUnidadeDest(contractdest)
        }
    }

    //=================================Unidade=============================================  

    const customStyles = {
        control: (provided: any, state: any) => ({
            ...provided,
            background: '#fff',
            minHeight: '30px',
            height: '30px',
            borderColor: state.isFocused ? 'grey' : '#92989e',

        }),


        valueContainer: (provided: any, state: any) => ({
            ...provided,
            height: '30px',
            padding: '0 6px'
        }),

        input: (provided: any, state: any) => ({
            ...provided,
            margin: '0px',
            fontSize: 14,

        }),
        indicatorsContainer: (provided: any, state: any) => ({
            ...provided,
            height: '30px',
        }),
    };

    function fecharOpenModalIncluirNovo() {

        setOpenModalIncluirNovo(false)
        setOpenModalIncluirNovo2(false)
        setTaskMobile('')
    }

    function fecharOpenModalIncluirNovo2() {
        setOpenModalIncluirNovo(false)
        setOpenModalIncluirNovo2(false)

    }


    return (
        <>
            <Nav />
            <div className="main-content">
                <Header />
                <main>

                    <div className='container-principal'>
                        <div className="title-Page">
                            <div className="title-text">
                                <div className="size-x circle">1</div>
                                <h2>Defina informações da origem do ativo</h2>
                            </div>
                            <hr />
                        </div>
                        <div className="container-cad-prod2">

                            <div id="conten1">
                                <div className="select-box-ativos-select">
                                    <span className="details">Tipo de Movimentação *</span>
                                    <Select
                                        placeholder={'Selecione...'}
                                        options={optionsMovi}
                                        styles={customStyles}
                                        value={optionsMovi.filter(function (option: any) {
                                            return option.value === status;
                                        })}
                                        onChange={(options: any) =>
                                            !options ? Qstatus("") : Qstatus(options.value)
                                        }
                                        noOptionsMessage={() => "Não encontrado..."}
                                        theme={(theme: any) => ({
                                            ...theme,
                                            borderRadius: 4,
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#ebebeb',
                                                primary: 'black',
                                            },
                                        })}
                                    />
                                </div>
                            </div>
                            <div id="conten2">
                                <div className="select-box-ativos-select">
                                    <span className="details">Contrato *</span>

                                    <Select
                                        placeholder={'Selecione...'}
                                        options={optionsContrato}
                                        styles={customStyles}
                                        value={optionsContrato.filter(function (option: any) {
                                            return option.value === contract;
                                        })}
                                        onChange={(options: any) =>
                                            !options ? GetUnidadeSel("") : GetUnidadeSel(options.value)
                                        }
                                        isClearable
                                        isDisabled={isDisabledContrato}
                                        noOptionsMessage={() => "Não encontrado..."}
                                        theme={(theme: any) => ({
                                            ...theme,
                                            borderRadius: 4,
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#ebebeb',
                                                primary: 'black',
                                            },
                                        })}
                                    />
                                </div>
                            </div>
                            <div id="conten2">
                                <div className="select-box-ativos-select">
                                    <span className="details">Origem *</span>
                                    <Select
                                        placeholder={'Selecione...'}
                                        options={optionsUnidade}
                                        styles={customStyles}
                                        value={optionsUnidade.filter(function (option: any) {
                                            return option.value === unity;
                                        })}
                                        isDisabled={isDisabledUnity}
                                        onChange={(options: any) =>
                                            !options ? getDestiny("") : getDestiny(options.value)
                                        }
                                        isClearable
                                        noOptionsMessage={() => "Não encontrado..."}
                                        theme={(theme: any) => ({
                                            ...theme,
                                            borderRadius: 4,
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#ebebeb',
                                                primary: 'black',
                                            },
                                        })}
                                    />
                                </div>
                            </div>
                        </div>

                        {exibirEst === 'S' ? (
                            <div>
                                <div className="title-Page">
                                    <div className="title-text">
                                        <div className="size-x circle">2</div>
                                        <h2>Defina informações do destino</h2>
                                    </div>
                                    <hr />
                                </div>


                                <div className="container-cad-prod8">
                                    <div id="conten1">
                                        <div className="select-box-ativos-select">
                                            <div className='details-flex'>
                                                <span className="details">Contrato *</span>
                                                <MdOutlineAddCircle className='details-flex-icon-ativo' onClick={incluirContrato} />
                                                <span className="details-esc-ativo" onClick={incluirContrato}>Incluir Novo</span>
                                            </div>


                                            <Select
                                                placeholder={'Selecione...'}
                                                options={optionsContratodest}
                                                styles={customStyles}
                                                value={optionsContratodest.filter(function (option: any) {
                                                    return option.value === contractdest;
                                                })}
                                                onChange={(options: any) =>
                                                    !options ? GetUnidadeDest("") : GetUnidadeDest(options.value)
                                                }
                                                isClearable
                                                noOptionsMessage={() => "Não encontrado..."}
                                                theme={(theme: any) => ({
                                                    ...theme,
                                                    borderRadius: 4,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: '#ebebeb',
                                                        primary: 'black',
                                                    },
                                                })}
                                            />
                                        </div>
                                    </div>
                                    <div id="conten2">
                                        <div className="select-box-ativos-select">
                                            <div className='details-flex'>
                                                <span className="details">Destino *</span>
                                                <MdOutlineAddCircle className='details-flex-icon-ativo' onClick={incluirUnidade} />
                                                <span className="details-esc-ativo" onClick={incluirUnidade}>Incluir Novo</span>
                                            </div>
                                            <Select
                                                placeholder={'Selecione...'}
                                                options={optionsUnidadedest}
                                                styles={customStyles}
                                                value={optionsUnidadedest.filter(function (option: any) {
                                                    return option.value === destiny;
                                                })}
                                                onChange={(options: any) =>
                                                    !options ? GetDestiny("") : GetDestiny(options.value)
                                                }
                                                isClearable
                                                noOptionsMessage={() => "Não encontrado..."}
                                                theme={(theme: any) => ({
                                                    ...theme,
                                                    borderRadius: 4,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: '#ebebeb',
                                                        primary: 'black',
                                                    },
                                                })}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="container-3-ativo">
                                    <div className="textareaBox-ativo">
                                        <span className="details">Observação</span>
                                        <textarea
                                            onChange={(e) => setObservation(e.target.value)}
                                            value={observation}
                                        />
                                    </div>
                                </div>

                                <div className="title-Page">
                                    <div className="title-text">
                                        <div className="size-x circle">3</div>
                                        <h2>Defina informações do ativo</h2>
                                    </div>
                                    <hr />
                                </div>
                                <div className="container-cad-prod2">
                                    <div id="conten1">
                                        <div className="select-box-ativos-select">

                                            <span className="details">Produto *</span>

                                            <Select
                                                maxMenuHeight={100}
                                                placeholder={'Selecione...'}
                                                options={optionsProduto}
                                                styles={customStyles}
                                                value={optionsProduto.filter(function (option: any) {
                                                    return option.value === product;
                                                })}
                                                onChange={(options: any) =>
                                                    !options ? GetProdutoQuant("") : GetProdutoQuant(options.value)
                                                }
                                                isClearable
                                                noOptionsMessage={() => "Não encontrado..."}
                                                theme={(theme: any) => ({
                                                    ...theme,
                                                    borderRadius: 4,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: '#ebebeb',
                                                        primary: 'black',
                                                    },
                                                })}
                                            />

                                        </div>
                                    </div>
                                    <div id="conten2">
                                        <div className="input-box-ativos">
                                            <span className="details">Disponível</span>
                                            <input
                                                autoComplete="off"
                                                id="disponivel"
                                                type="number"
                                                disabled
                                                onChange={(e) => setDisponivel(e.target.value)}
                                                value={disponivel} />
                                        </div>
                                    </div>
                                    <div id="conten3">
                                        <div className="input-box-ativos">
                                            <div className='btn-busca-movimentacoes2' onClick={validarQuantidade}>
                                                <h1 className='lab-movimentacao'>Buscar</h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {modalGrid}

                                <div className="Bt-button-cad">
                                    <button type="submit" onClick={direcionamentoSalvarMovimentacao}>
                                        <i className="fa fa-check-circle icon-ok-btn"></i> Salvar
                                    </button>
                                    <button type="submit" onClick={voltarMovimentacao}>
                                        <i className="fa fa-times icon-cancelar-btn"></i> Cancelar
                                    </button>
                                </div>

                            </div>
                        ) : null}




                        {exibirCont === 'S' ? (
                            <div>
                                <div className="title-Page">
                                    <div className="title-text">
                                        <div className="size-x circle">2</div>
                                        <h2>Defina informações do destino</h2>
                                    </div>
                                    <hr />
                                </div>


                                <div className="container-cad-prod9">
                                    <div id="conten1">
                                        <div className="select-box-ativos-select">
                                            <div className='details-flex'>
                                                <span className="details">Contrato *</span>
                                                <MdOutlineAddCircle className='details-flex-icon-ativo' onClick={incluirContrato} />
                                                <span className="details-esc-ativo" onClick={incluirContrato}>Incluir Novo</span>
                                            </div>
                                            <Select
                                                placeholder={'Selecione...'}
                                                options={optionsContratodest}
                                                styles={customStyles}
                                                value={optionsContratodest.filter(function (option: any) {
                                                    return option.value === contractdest;
                                                })}
                                                onChange={(options: any) =>
                                                    !options ? GetUnidadeDest("") : GetUnidadeDest(options.value)
                                                }
                                                isClearable
                                                noOptionsMessage={() => "Não encontrado..."}
                                                theme={(theme: any) => ({
                                                    ...theme,
                                                    borderRadius: 4,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: '#ebebeb',
                                                        primary: 'black',
                                                    },
                                                })}
                                            />
                                        </div>
                                    </div>
                                    <div id="conten2">
                                        <div className="select-box-ativos-select">
                                            <div className='details-flex'>
                                                <span className="details">Destino *</span>
                                                <MdOutlineAddCircle className='details-flex-icon-ativo' onClick={incluirUnidade} />
                                                <span className="details-esc-ativo" onClick={incluirUnidade}>Incluir Novo</span>
                                            </div>
                                            <Select
                                                placeholder={'Selecione...'}
                                                options={optionsUnidadedest}
                                                styles={customStyles}
                                                value={optionsUnidadedest.filter(function (option: any) {
                                                    return option.value === destiny;
                                                })}
                                                onChange={(options: any) =>
                                                    !options ? GetDestiny("") : GetDestiny(options.value)
                                                }
                                                isClearable
                                                noOptionsMessage={() => "Não encontrado..."}
                                                theme={(theme: any) => ({
                                                    ...theme,
                                                    borderRadius: 4,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: '#ebebeb',
                                                        primary: 'black',
                                                    },
                                                })}
                                            />
                                        </div>
                                    </div>
                                    <div id="conten3">
                                        <div className="input-box-ativos">
                                            <div className='btn-busca-movimentacoes' onClick={ListarProdCont}>
                                                <h1 className='lab-movimentacao'>Buscar</h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="textareaBox-ativo">
                                    <span className="details">Observação</span>
                                    <textarea
                                        onChange={(e) => setObservation(e.target.value)}
                                        value={observation}
                                    />
                                </div>

                                <div className='divGridModal'>

                                    {modalGrid}



                                    <div className="Bt-button-cad">
                                        <button type="submit" onClick={direcionamentoSalvarMovimentacaoCont}>
                                            <i className="fa fa-check-circle icon-ok-btn"></i> Salvar
                                        </button>
                                        <button type="submit" onClick={voltarMovimentacao}>
                                            <i className="fa fa-times icon-cancelar-btn"></i> Cancelar
                                        </button>
                                    </div>

                                </div>
                            </div>
                        ) : null}

                    </div>
                </main>
            </div>


            <ModalIncluirNovo isOpen={openModalIncluirNovo2} setModalOpen={() => setOpenModalIncluirNovo2(!openModalIncluirNovo2)}>
                <div className='title-modal-incluir'>
                    <h1 className="modal-IncluirNovo-title">{qualTitulo2}</h1>
                    <div onClick={fecharOpenModalIncluirNovo}>
                        <button className="fa fa-times icone-modal-IncluirNovo" data-dismiss="modal" aria-label="Close" onClick={fecharOpenModalIncluirNovo2}></button>
                    </div>
                </div>
                <div className='modal-scrol'>


                    <div className="title-Page">
                        <div className="title-text">
                            <div className="size-x circle">1</div>
                            <h2>Modo 1</h2>
                        </div>
                        <hr />
                    </div>

                    <h1 className="spa-aten">
                        <b> Atenção:</b> Nesse modo você pode realizar a movimentação do ativo diretamente pelo center sem a necessidade do técnico registrar no aplicativo.
                    </h1>


                    {qualBtn}

                    <div className="title-Page">
                        <div className="title-text">
                            <div className="size-x circle">2</div>
                            <h2>Modo 2</h2>
                        </div>
                        <hr />
                    </div>

                    <h1 className="spa-aten">
                        <b> Atenção:</b> Nesse modo são geradas tarefas para o aplicativo que devem ser executadas pelo técnico para finalizar o processo de movimentação.
                    </h1>


                    {qualBtn2}

                </div>

            </ModalIncluirNovo>

            <ModalIncluirNovo isOpen={openModalIncluirNovo} setModalOpen={() => setOpenModalIncluirNovo(!openModalIncluirNovo)}>
                <>
                    <div className='title-modal-incluir'>
                        <h1 className="modal-IncluirNovo-title">{qualTitulo}</h1>
                        <div onClick={fecharOpenModalIncluirNovo}>
                            <div onClick={fecharOpenModalIncluirNovo}>
                                <button className="fa fa-times icone-modal-IncluirNovo" data-dismiss="modal" aria-label="Close"></button>
                            </div>
                        </div>
                    </div>

                    <div className='container-modal-incluir'>


                        {taskMobile === 'S' ? (
                            <><h1 className="spa-aten">
                                <b>Atenção:</b> Selecione quem vai executar a movimentação.</h1>

                                <div className="container-cad-prod26">
                                    <div id="content1">
                                        <div className="select-box-ativos-select">
                                            <span className="details">Usuário</span>
                                            <Select
                                                maxMenuHeight={80}
                                                placeholder={'Selecione...'}
                                                options={optionsAgent}
                                                styles={customStyles}
                                                value={optionsAgent.filter(function (option: any) {
                                                    return option.value === age_id;
                                                })}
                                                onChange={(options: any) =>
                                                    !options ? SelecionarAgent("") : SelecionarAgent(options.value)
                                                }
                                                isClearable
                                                noOptionsMessage={() => "Não encontrado..."}
                                                theme={(theme: any) => ({
                                                    ...theme,
                                                    borderRadius: 4,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: '#ebebeb',
                                                        primary: 'black',
                                                    },
                                                })}
                                            />
                                        </div>
                                    </div>
                                    <div id="content2">
                                        <div className="select-box-ativos-select">
                                            <span className="details">Equipe</span>
                                            <Select
                                                maxMenuHeight={80}
                                                placeholder={'Selecione...'}
                                                options={optionsEquipe}
                                                styles={customStyles}
                                                value={optionsEquipe.filter(function (option: any) {
                                                    return option.value === tea_id;
                                                })}
                                                onChange={(options: any) =>
                                                    !options ? SelecionarEquipe("") : SelecionarEquipe(options.value)
                                                }
                                                isClearable
                                                noOptionsMessage={() => "Não encontrado..."}
                                                theme={(theme: any) => ({
                                                    ...theme,
                                                    borderRadius: 4,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: '#ebebeb',
                                                        primary: 'black',
                                                    },
                                                })}
                                            />
                                        </div>
                                    </div>

                                    <div id="content3">
                                        <div className="select-box-ativos-select">
                                            <span className="details">Forma</span>
                                            <Select
                                                maxMenuHeight={80}
                                                placeholder={'Selecione...'}
                                                options={optionsForma}
                                                styles={customStyles}
                                                isDisabled={isDisabledForma}
                                                value={optionsForma.filter(function (option: any) {
                                                    return option.value === tsf_id;
                                                })}
                                                onChange={(options: any) =>
                                                    !options ? setTsf_id("") : setTsf_id(options.value)
                                                }
                                                isClearable
                                                noOptionsMessage={() => "Não encontrado..."}
                                                theme={(theme: any) => ({
                                                    ...theme,
                                                    borderRadius: 4,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: '#ebebeb',
                                                        primary: 'black',
                                                    },
                                                })}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="Bt-button-cad">
                                    <button type="submit" onClick={confirmarinserttaskmovement}>
                                        <i className="fa fa-check-circle icon-ok-btn"></i> Salvar
                                    </button>
                                    <button type="submit" onClick={finalizarModal4}>
                                        <i className="fa fa-times icon-cancelar-btn"></i> Cancelar
                                    </button>
                                </div>

                            </>

                        ) : qualGrid}


                    </div>
                </>
            </ModalIncluirNovo>

            <ModalAlert isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)}>
                <div className='container-modal-alert'>
                    {qualAlert}
                </div>
            </ModalAlert>
        </>
    );

}

export default MovimentacoesAdd;