import React from 'react';
import styled from 'styled-components';
import { FaCog, FaInfoCircle, FaRegStar, FaRegUser, FaCheckCircle, FaTools, FaCalendarAlt } from "react-icons/fa";
import { FaHelmetSafety } from "react-icons/fa6";

const NewContainer = styled.div`

  margin-bottom: 10px;
  width: 100%;
`;

const NewBox = styled.div`
  
  background: #3962AC;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding-left: 10px;
  padding-right: 10px;
  color: #fff;
  display: flex;
  align-items: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  padding: 8px;
  min-height: 100px;
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }

`;

const IconWrapper = styled.div`
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
  border-radius: 50%;
  padding: 20px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
`;

const Content = styled.div`
  flex: 1;
`;

const Title = styled.h3`
  font-size: 16px;
  color: #fff;
  font-weight: bold;



`;

const Description = styled.p`
  margin: 5px 0;
  font-size: 10px;
  color: #ddd;

 
`;

const DescriptionValor = styled.p`
   font-size: 24px;
   font-weight: bold;
  color: #fff;
  margin-top: 5px;

 
`;

const NewWalletBox = (props: any) => {
  return (
    <NewContainer>


      <NewBox>
        <IconWrapper>

                {props.icone === "chamados" ? (  
                    
                    <FaTools size={24} />
                ):null}

                 {props.icone === "tecnico" ? (  
                    <FaHelmetSafety size={24} />
                ):null}

                {props.icone === "concluidos" ? (  
                    <FaCheckCircle size={24} />
                ):null}

                {props.icone === "execucao" ? (  
                     <FaCog size={24} />
                ):null}

                {props.icone === "programadas" ? (  
                     <FaCalendarAlt size={24} />
                ):null}

          

        </IconWrapper>
        <Content>
          <Title>{props.title}</Title>
          <DescriptionValor>{props.valor}</DescriptionValor>
          <Description>{props.frase}</Description>
        </Content>
      </NewBox>



    </NewContainer>
  );
};

export default NewWalletBox;
