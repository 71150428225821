import React, { useState, useEffect } from 'react'
import Nav from "../../components/Nav";
import Header from "../../components/Header";
import './index.css'
import Select from 'react-select'
import api from '../../Services/api'
import { AxiosResponse } from "axios";
import { MdOutlineAddCircle } from 'react-icons/md';
import { useNavigate } from 'react-router';
import ModalAlert from '../../components/ModalAlert/modal'
import Loading from '../../components/Loading'
import secureLocalStorage from "react-secure-storage";
import ModalIncluirNovo from '../../components/ModalIncluirNovo/modal'
import GridSecundario from '../../components/GridSecundario'
import FormUnitmeasure from '../../components/FormUnitmeasure';
import { useParams } from 'react-router-dom';


function PartmaterialEdit() {
	const navigate = useNavigate();

	let { id } = useParams();
	const [qualTitulo, setQualTitulo] = useState('')
	const [qualGrid, setQualGrid] = useState(<></>)
	const [openModal, setOpenModal] = useState(false)
	const [qualAlert, setQualAlert] = useState(<></>)
	const [titulo, setTitulo] = useState('');

	const [pml_id, setPml_id] = useState('')
	const [pml_active, setPml_active] = useState(true)
	const [pml_description, setPml_description] = useState('')
	const [pml_alternativeidentifier, setPml_alternativeidentifier] = useState('')
	const [pml_descriptionIni, setPml_descriptionIni] = useState('')
	const [pml_alternativeidentifierIni, setPml_alternativeidentifierIni] = useState('')
	const [uts_id, setUts_id] = useState('')
	const [pml_price, setPml_price] = useState('')
	const [pml_cost, setPml_cost] = useState('')
	const [pml_gtin, setPml_gtin] = useState('')
	const [pml_ncm, setPml_ncm] = useState('')
	const [pml_cest, setPml_cest] = useState('')
	const [pml_observation, setPml_observation] = useState('')


	const [openModalIncluirNovo, setOpenModalIncluirNovo] = useState(false)
	const [confirmarUserDisabled, setConfirmarUserDisabled] = useState(false)
	const [cancelarDisabled, setCancelarDisabled] = useState(false)

	//----------------------------------------------------
	const [pml_activeDisabled, setPml_activeDisabled] = useState(false)
	const [pml_descriptionDisabled, setPml_descriptionDisabled] = useState(false)
	const [pml_alternativeidentifierDisabled, setPml_alternativeidentifierDisabled] = useState(false)
	const [uts_idDisabled, setUts_idDisabled] = useState(false)
	const [pml_priceDisabled, setPml_priceDisabled] = useState(false)
	const [pml_costDisabled, setPml_costDisabled] = useState(false)
	const [pml_gtinDisabled, setPml_gtinDisabled] = useState(false)
	const [pml_ncmDisabled, setPml_ncmDisabled] = useState(false)
	const [pml_cestDisabled, setPml_cestDisabled] = useState(false)
	const [pml_observationDisabled, setPml_observationDisabled] = useState(false)
	const [optionsUnitmeasure, setOptionsUnitmeasure] = useState([])
	const [optionsPartmaterial, setOptionsPartmaterial] = useState([])

	useEffect(() => {

		setQualAlert(<div className='div-alert'><Loading /></div>)
		setOpenModal(true)

		validatToken();

	}, []);

	function fecharOpenModalIncluirNovo() {
		setOpenModalIncluirNovo(false)
	}

	async function validatToken() {

		let ret: AxiosResponse;
		let valor: any;

		const _user = secureLocalStorage.getItem('t')

		api.defaults.headers.common['Authorization'] = _user as ''

		try {

			ret = await api.get("/status/validar")

			if (ret.data.status === 200) {

				secureLocalStorage.setItem('t', ret.data.data);
				secureLocalStorage.setItem('acp', ret.data.decodedToken.acp_integrationid);
				secureLocalStorage.setItem('n', ret.data.decodedToken.name);
				secureLocalStorage.setItem('iag', ret.data.decodedToken.id);
				secureLocalStorage.setItem('cy', ret.data.decodedToken.company);

				await partmaterialeditar()

			

				if (ret.data.decodedToken.acp_integrationid === 'DESENVOLVEDOR' || ret.data.decodedToken.acp_integrationid === 'ADMINISTRADOR') {


				} else if (ret.data.decodedToken.acp_integrationid === 'CONSULTOR') {

					setPml_activeDisabled(true)
					setPml_descriptionDisabled(true)
					setPml_alternativeidentifierDisabled(true)
					setUts_idDisabled(true)
					setPml_priceDisabled(true)
					setPml_costDisabled(true)
					setPml_gtinDisabled(true)
					setPml_ncmDisabled(true)
					setPml_cestDisabled(true)
					setPml_observationDisabled(true)

				}

				

			}

		} catch (e: any) {

			if (e.response.data.status === 403) {

				setQualAlert(<div className='div-alert'>
					<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
					<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
					<div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
					<div className='btn-alert' onClick={fecharModalToken}><span className="btn-alert-lab">OK</span></div>
				</div>)


			}
		}

	}


	async function partmaterialeditar() {
		let ret: AxiosResponse;
		let valor: any;


		if (/^\d+$/.test(id || '') === true) {
			try {
				ret = await api.get("partmaterial/partmaterialeditar", {
					params: {
						id: id
					}
				})
				if (ret.data.status === 200) {

					await GetPartmaterial()
					await GetUnitmeasure() 
			
					setPml_id(ret.data.data[0].pml_id || '');
					setPml_active(ret.data.data[0].pml_active != undefined ? Boolean(ret.data.data[0].pml_active) : true)
					setPml_description(ret.data.data[0].pml_description || '');
					setPml_descriptionIni(ret.data.data[0].pml_description || '');
					setPml_alternativeidentifier(ret.data.data[0].pml_alternativeidentifier || '');
					setPml_alternativeidentifierIni(ret.data.data[0].pml_alternativeidentifier || '');
					setUts_id(String(ret.data.data[0].uts_id || ''));
					setPml_price(ret.data.data[0].pml_price || '');
					setPml_cost(ret.data.data[0].pml_cost || '');
					setPml_gtin(ret.data.data[0].pml_gtin || '');
					setPml_ncm(ret.data.data[0].pml_ncm|| '');
					setPml_cest(ret.data.data[0].pml_cest || '');
					setPml_observation(ret.data.data[0].pml_observation || '');




					setOpenModal(false)
				} else {
					setOpenModal(false)
					navigate('/partmaterial/edit/' + id + '/n');
				}
			} catch (e) {
				setOpenModal(false)
				navigate('/partmaterial/edit/' + id + '/n');
			}

		} else {
			setOpenModal(false)
			navigate('/partmaterial/edit/' + id + '/n');
		}
	}


	async function GetUnitmeasure() {
		let ret: AxiosResponse;
		let valor: any;
		try {
			ret = await api.get("unitmeasure/listar")
			if (ret.data.status === 200) {
				valor = ret.data.data;

				const arr = [] as any;
				let result = valor;
				result.map((user: any) => {
					return arr.push({ value: String(user.uts_id), label: user.uts_description });
				});

				setOptionsUnitmeasure(arr)

			}
		} catch (e) {
			setOptionsUnitmeasure([])
		}
	}

	async function GetPartmaterial() {
		let ret: AxiosResponse;
		let valor: any;
		try {
			ret = await api.get("partmaterial/verificacao")
			if (ret.data.status === 200) {
				valor = ret.data.data;


				setOptionsPartmaterial(valor)

			}
		} catch (e) {
			setOptionsPartmaterial([])
		}
	}


	function fecharModalToken() {

		secureLocalStorage.removeItem('t')
		secureLocalStorage.removeItem('acp')
		secureLocalStorage.removeItem('n')
		secureLocalStorage.removeItem('iag')
		navigate('/login');
		setOpenModal(false)

	}



	const customStyles = {
		control: (provided: any, state: any) => ({
			...provided,
			background: '#fff',
			minHeight: '30px',
			height: '30px',
			borderColor: state.isFocused ? 'grey' : '#92989e',

		}),


		valueContainer: (provided: any, state: any) => ({
			...provided,
			height: '30px',
			padding: '0 6px'
		}),

		input: (provided: any, state: any) => ({
			...provided,
			margin: '0px',
			fontSize: 14,

		}),
		indicatorsContainer: (provided: any, state: any) => ({
			...provided,
			height: '30px',
		}),
	};

	function ValitarActive() {
		let e = document.getElementById("checkbox-active") as HTMLInputElement;

		if (e.checked) {

			setPml_active(true)
		} else {

			setPml_active(false)
		}
	}


	function cancelar() {
		navigate('/partmaterial');
	}



	function fecharModal() {
		setOpenModal(false)
	}




	const maskOnlyNumbers = (value: any) => {
		return value.replace(/\D/g, "");
	};



	function finalizarModal2() {
		setOpenModal(false)
	}

	function incluirValor(value: any) {

        var valor = value
        valor = valor + '';
        valor = parseInt(valor.replace(/[\D]+/g, ''));
        valor = valor + '';
        valor = valor.replace(/([0-9]{2})$/g, ".$1");
        if (valor.length > 6) {
            valor = valor.replace(/([0-9]{3}),([0-9]{2}$)/g, "$1.$2");
        }
        setPml_price(valor)
        if (valor == 'NaN') {
            setPml_price('');
        }
    }


	function incluirCutos(value: any) {

        var valor = value
        valor = valor + '';
        valor = parseInt(valor.replace(/[\D]+/g, ''));
        valor = valor + '';
        valor = valor.replace(/([0-9]{2})$/g, ".$1");
        if (valor.length > 6) {
            valor = valor.replace(/([0-9]{3}),([0-9]{2}$)/g, "$1.$2");
        }
        setPml_cost(valor)
        if (valor == 'NaN') {
            setPml_cost('');
        }
    }

	//=================================ADD UNIDADE=============================================  

	async function confirmarLocal() {

		let ret: AxiosResponse;
		let valor: any;

		setQualAlert(<div className='div-alert'><Loading /></div>)
		setOpenModal(true)


		const _user = secureLocalStorage.getItem('t')

		api.defaults.headers.common['Authorization'] = _user as ''

		try {

			ret = await api.get("/status/validar")

			if (ret.data.status === 200) {

				const FiltrarNome = optionsPartmaterial.filter(
					(e: any) => {
						return e.pml_description.toLowerCase() === pml_description.toLowerCase()
					}
				);

				const FiltrarCnpj = optionsPartmaterial.filter(
					(e: any) => {
						return e.pml_alternativeidentifier.toLowerCase() === pml_alternativeidentifier.toLowerCase()
					}
				);


				if (pml_description.toLowerCase() !== "" && pml_descriptionIni.toLowerCase() 
				!== pml_description.toLowerCase() && FiltrarNome.length > 0) {

					
					setQualAlert(<div className='div-alert'>
						<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
						<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
						<div className='div-info-alert'><span className="info-alert">Descrição já está em uso.</span></div>
						<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
					</div>)

				} else if (pml_description === '') {

					setQualAlert(<div className='div-alert'>
						<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
						<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
						<div className='div-info-alert'><span className="info-alert">Digite a descrição.</span></div>
						<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
					</div>)


				}

				else if (pml_alternativeidentifier === '') {

					setQualAlert(<div className='div-alert'>
						<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
						<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
						<div className='div-info-alert'><span className="info-alert">Digite o identificador alternativo.</span></div>
						<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
					</div>)
				}

				else if (pml_alternativeidentifier.toLowerCase() !== "" && pml_alternativeidentifierIni.toLowerCase() 
				!== pml_alternativeidentifier.toLowerCase() && FiltrarCnpj.length > 0) {

					setQualAlert(<div className='div-alert'>
						<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
						<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
						<div className='div-info-alert'><span className="info-alert">Identificador alternativo já está em uso.</span></div>
						<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
					</div>)
				} else if (uts_id === "") {

					setQualAlert(<div className='div-alert'>
						<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
						<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
						<div className='div-info-alert'><span className="info-alert">Selecione a unidade de medida.</span></div>
						<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
					</div>)

				}
				else {
					let ret: AxiosResponse;

					const dados = {
						pml_id: id,
						pml_active,
						pml_description,
						pml_alternativeidentifier,
						uts_id,
						pml_price,
						pml_cost,
						pml_gtin,
						pml_ncm,
						pml_cest,
						pml_observation,
						age_id_lastupdate: secureLocalStorage.getItem('iag'),
					};

					try {
						ret = await api.put("partmaterial/update", {
							data: dados,
						});
						if (ret.data.status === 200) {

							setQualAlert(<div className='div-alert'>
								<span className="fa-regular fa-circle-check ControleIconAlert"></span>
								<div className='div-info-alert'><span className="info-alert">Alteração realizada com sucesso!</span></div>
								<div className='btn-alert' onClick={finalizarModal1}><span className="btn-alert-lab">OK</span></div>
							</div>)
						}
					} catch (e: any) {

						setQualAlert(<div className='div-alert'>
							<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
							<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
							<div className='div-info-alert'><span className="info-alert">Não foi possivel concluir o cadastramento.</span></div>
							<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
						</div>)


					}
				}

			}

		} catch (e: any) {

			if (e.response.data.status === 403) {

				setQualAlert(<div className='div-alert'>
					<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
					<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
					<div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
					<div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
				</div>)


			}
		}


	}

	function finalizarModal1() {
		navigate('/partmaterial');
	}


	//--------------------unidade de medida---------------------------------

	function incluirSubgrupo() {

		setTitulo('Cadastrar unidade de medida')
		setQualGrid((<><GridSecundario
			Iconeadd={ChamarCadastroSubgrupo}
			Iconeditar={EditarSubgrupo}
			iconeExcluir={SubgrupoDeletar}
			PegarValorGrid={PegarValorGridSubgrupo}
			th1='Ativo'
			th2='ID'
			th3='Descrição'
			th4='Identificador Alternativo'
			th5=''
			th6=''
			endPoint='unitmeasure/buscarunitmeasure'
			consult='N'
			col1='1'
			col2='2'
			col3='3'
			ambiente={'%'}
			pag="ok"
		/></>))

		setOpenModalIncluirNovo(true)
}

function ChamarCadastroSubgrupo(){
	sessionStorage.removeItem("dadosTipoEquipe");
	setQualGrid(<><FormUnitmeasure voltar={BtnVoltarSubgrupo}/></>)
}

async function EditarSubgrupo(obj: any) {
	sessionStorage.setItem("dadosTipoEquipe", JSON.stringify(obj));
	setQualGrid(<><FormUnitmeasure voltar={BtnVoltarSubgrupo}/></>)
}

function BtnVoltarSubgrupo() {

	setQualGrid((<><GridSecundario
		Iconeadd={ChamarCadastroSubgrupo}
		Iconeditar={EditarSubgrupo}
		iconeExcluir={SubgrupoDeletar}
		PegarValorGrid={PegarValorGridSubgrupo}
		th1='Ativo'
		th2='ID'
		th3='Descrição'
		th4='Identificador Alternativo'
		th5=''
		th6=''
		endPoint='unitmeasure/buscarunitmeasure'
		consult='N'
		col1='1'
		col2='2'
		col3='3'
		ambiente={'%'}
		pag="ok"
	/></>))

	GetUnitmeasure() 

}

function SubgrupoDeletar(){

}

function PegarValorGridSubgrupo(valor: any){
	setUts_id(String(valor.uts_id))
	setOpenModalIncluirNovo(false)

}

	return (
		<>
			<Nav />
			<div className="main-content">
				<Header />
				<main>
					<div className='containerPrincipal'>



						<div className="title-Page">
							<div className="title-text">
								<div className="size-x circle">1</div>
								<h2>Informações Gerais</h2>
							</div>
							<hr />
						</div>


						<div className="container-cad-prod44">
							<div id="content1">
								<div className="class-checkbox-user2">
									<input type="checkbox" id="checkbox-active" checked={pml_active} onChange={ValitarActive} disabled={pml_activeDisabled} />
									<h4 className="lab-ativo">Ativo</h4>
								</div>
							</div>

						</div>


						<div className="container-cad-prod6">
							<div id="content1">
								<div className="input-box-ativos">
									<span className="details">Descrição *</span>
									<input
										autoComplete="off"
										type="text"
										onChange={(e) => setPml_description(e.target.value)}
										value={pml_description}
										disabled={pml_descriptionDisabled}
									/>

								</div>
							</div>
							<div id="content2">
								<div className="input-box-ativos">
									<span className="details">Identificador Alternativo *</span>
									<input
										type="text"
										onChange={(e) => setPml_alternativeidentifier(e.target.value)}
										value={pml_alternativeidentifier}
										autoComplete="off"
										disabled={pml_alternativeidentifierDisabled}
									/>

								</div>
							</div>
							<div id="content3">

								<div className="select-box-ativos-select">
									<div className='details-flex'>
										<span className="details">Unidade de medida *</span>
										<MdOutlineAddCircle className='details-flex-icon-ativo' />
										<span className="details-esc-ativo" onClick={incluirSubgrupo}>Incluir Novo</span>
									</div>

									<Select
										maxMenuHeight={140}
										placeholder={'Selecione...'}
										options={optionsUnitmeasure}
										styles={customStyles}
										isDisabled={uts_idDisabled}
										value={optionsUnitmeasure.filter(function (option: any) {
											return option.value === uts_id;
										})}
										onChange={(options: any) =>
											!options ? setUts_id("") : setUts_id(options.value)
										}
										isClearable
										noOptionsMessage={() => "Não encontrado..."}
										theme={(theme: any) => ({
											...theme,
											borderRadius: 4,
											colors: {
												...theme.colors,
												primary25: '#ebebeb',
												primary: 'black',
											},
										})}
									/>
								</div>

							</div>
							<div id="content4">
								<div className="input-box-ativos">
									<span className="details">Preço</span>
									<input
										type="text"
										onChange={(e) => incluirValor(e.target.value)}
										value={pml_price}
										autoComplete="off"
										disabled={pml_priceDisabled}
									/>

								</div>
							</div>
						</div>

						<div className="container-cad-prod6">
							<div id="content1">
								<div className="input-box-ativos">
									<span className="details">Custo</span>
									<input
										autoComplete="off"
										type="text"
										onChange={(e) => incluirCutos(e.target.value)}
										value={pml_cost}
										disabled={pml_costDisabled}
									/>

								</div>
							</div>
							<div id="content2">
								<div className="input-box-ativos">
									<span className="details">GTIN</span>
									<input
										type="text"
										onChange={(e) => setPml_gtin(e.target.value)}
										value={pml_gtin}
										autoComplete="off"
										disabled={pml_gtinDisabled}
									/>

								</div>
							</div>
							<div id="content3">
								<div className="input-box-ativos">
									<span className="details">NCM</span>
									<input
										type="text"
										onChange={(e) => setPml_ncm(e.target.value)}
										value={pml_ncm}
										autoComplete="off"
										disabled={pml_ncmDisabled}
									/>
								</div>
							</div>
							<div id="content4">
								<div className="input-box-ativos">
									<span className="details">CEST</span>
									<input
										type="text"
										onChange={(e) => setPml_cest(e.target.value)}
										value={pml_cest}
										autoComplete="off"
										disabled={pml_cestDisabled}
									/>
								</div>
							</div>

						</div>

						<div className="container-cad-prod3">
								<div className="textareaBox-ativo">
									<span className="details">Observação</span>
									<textarea
										onChange={(e) => setPml_observation(e.target.value)}
										value={pml_observation}
										disabled={pml_observationDisabled}
									/>
								</div>
							</div>

						<div className="Bt-button-cad">
							<button type="submit" disabled={confirmarUserDisabled} onClick={confirmarLocal}>
								<i className="fa fa-check-circle icon-ok-btn"></i> Salvar
							</button>
							<button type="submit" onClick={cancelar} disabled={cancelarDisabled}>
								<i className="fa fa-times icon-cancelar-btn"></i> Cancelar
							</button>
						</div>
					</div>




				</main>
			</div>


			<ModalIncluirNovo isOpen={openModalIncluirNovo} setModalOpen={() => setOpenModalIncluirNovo(!openModalIncluirNovo)}>
				<div className='title-modal-incluir'>
					<h1 className="modal-IncluirNovo-title">{titulo}</h1>
					<div onClick={fecharOpenModalIncluirNovo}>
						<button className="fa fa-times icone-modal-IncluirNovo" data-dismiss="modal" aria-label="Close"></button>
					</div>
				</div>
				<div className='scrol-modal'>

					{qualGrid}

				</div>
			</ModalIncluirNovo>


			<ModalAlert isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)}>
				<div className='container-modal-alert'>
					{qualAlert}
				</div>
			</ModalAlert>



		</>
	);

}

export default PartmaterialEdit;