import React, { useState, useEffect } from 'react'
import Nav from "../../components/Nav";
import Header from "../../components/Header";
import './index.css'
import Select from 'react-select'
import api from '../../Services/api'
import { AxiosResponse } from "axios";
import { MdOutlineAddCircle } from 'react-icons/md';
import { useNavigate } from 'react-router';
import ModalAlert from '../../components/ModalAlert/modal'
import Loading from '../../components/Loading'
import secureLocalStorage from "react-secure-storage";
import ModalIncluirNovo from '../../components/ModalIncluirNovo/modal'
import GridSecundario from '../../components/GridSecundario'
import FormUnitmeasure from '../../components/FormUnitmeasure';

function ServicosAdd() {
	const navigate = useNavigate();

	const [qualTitulo, setQualTitulo] = useState('')
	const [qualGrid, setQualGrid] = useState(<></>)
	const [openModal, setOpenModal] = useState(false)
	const [qualAlert, setQualAlert] = useState(<></>)
	const [titulo, setTitulo] = useState('');

	const [svc_active, setSvc_active] = useState(true)
	const [svc_description, setSvc_description] = useState('')
	const [svc_alternativeidentifier, setSvc_alternativeidentifier] = useState('')
	const [svc_price, setSvc_price] = useState('')
	const [svc_cost, setSvc_cost] = useState('')
	const [svc_observation, setSvc_observation] = useState('')

	const [openModalIncluirNovo, setOpenModalIncluirNovo] = useState(false)
	const [confirmarUserDisabled, setConfirmarUserDisabled] = useState(false)
	const [cancelarDisabled, setCancelarDisabled] = useState(false)

	//----------------------------------------------------
	const [svc_activeDisabled, setSvc_activeDisabled] = useState(false)
	const [svc_descriptionDisabled, setSvc_descriptionDisabled] = useState(false)
	const [svc_alternativeidentifierDisabled, setSvc_alternativeidentifierDisabled] = useState(false)
	const [svc_priceDisabled, setSvc_priceDisabled] = useState(false)
	const [svc_costDisabled, setSvc_costDisabled] = useState(false)
	const [svc_observationDisabled, setSvc_observationDisabled] = useState(false)

	const [optionsServices, setOptionsServices] = useState([])



	useEffect(() => {

		setQualAlert(<div className='div-alert'><Loading /></div>)
		setOpenModal(true)

		validatToken();

	}, []);

	function fecharOpenModalIncluirNovo() {
		setOpenModalIncluirNovo(false)
	}

	async function validatToken() {

		let ret: AxiosResponse;
		let valor: any;

		const _user = secureLocalStorage.getItem('t')

		api.defaults.headers.common['Authorization'] = _user as ''

		try {

			ret = await api.get("/status/validar")

			if (ret.data.status === 200) {

				secureLocalStorage.setItem('t', ret.data.data);
				secureLocalStorage.setItem('acp', ret.data.decodedToken.acp_integrationid);
				secureLocalStorage.setItem('n', ret.data.decodedToken.name);
				secureLocalStorage.setItem('iag', ret.data.decodedToken.id);
				secureLocalStorage.setItem('cy', ret.data.decodedToken.company);

				GetServices()


				if (ret.data.decodedToken.acp_integrationid === 'DESENVOLVEDOR' || ret.data.decodedToken.acp_integrationid === 'ADMINISTRADOR') {


				} else if (ret.data.decodedToken.acp_integrationid === 'CONSULTOR') {

					setSvc_activeDisabled(true)
					setSvc_descriptionDisabled(true)
					setSvc_alternativeidentifierDisabled(true)
					setSvc_priceDisabled(true)
					setSvc_costDisabled(true)
					setSvc_observationDisabled(true)

				}

				setOpenModal(false)

			}

		} catch (e: any) {

			if (e.response.data.status === 403) {

				setQualAlert(<div className='div-alert'>
					<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
					<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
					<div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
					<div className='btn-alert' onClick={fecharModalToken}><span className="btn-alert-lab">OK</span></div>
				</div>)


			}
		}

	}




	async function GetServices() {
		let ret: AxiosResponse;
		let valor: any;
		try {
			ret = await api.get("services/verificacao")
			if (ret.data.status === 200) {
				valor = ret.data.data;


				setOptionsServices(valor)

			}
		} catch (e) {
			setOptionsServices([])
		}
	}


	function fecharModalToken() {

		secureLocalStorage.removeItem('t')
		secureLocalStorage.removeItem('acp')
		secureLocalStorage.removeItem('n')
		secureLocalStorage.removeItem('iag')
		navigate('/login');
		setOpenModal(false)

	}



	const customStyles = {
		control: (provided: any, state: any) => ({
			...provided,
			background: '#fff',
			minHeight: '30px',
			height: '30px',
			borderColor: state.isFocused ? 'grey' : '#92989e',

		}),


		valueContainer: (provided: any, state: any) => ({
			...provided,
			height: '30px',
			padding: '0 6px'
		}),

		input: (provided: any, state: any) => ({
			...provided,
			margin: '0px',
			fontSize: 14,

		}),
		indicatorsContainer: (provided: any, state: any) => ({
			...provided,
			height: '30px',
		}),
	};

	function ValitarActive() {
		let e = document.getElementById("checkbox-active") as HTMLInputElement;

		if (e.checked) {

			setSvc_active(true)
		} else {

			setSvc_active(false)
		}
	}


	function cancelar() {
		navigate('/services');
	}



	function fecharModal() {
		setOpenModal(false)
	}




	const maskOnlyNumbers = (value: any) => {
		return value.replace(/\D/g, "");
	};



	function finalizarModal2() {
		setOpenModal(false)
	}

	function incluirValor(value: any) {

        var valor = value
        valor = valor + '';
        valor = parseInt(valor.replace(/[\D]+/g, ''));
        valor = valor + '';
        valor = valor.replace(/([0-9]{2})$/g, ".$1");
        if (valor.length > 6) {
            valor = valor.replace(/([0-9]{3}),([0-9]{2}$)/g, "$1.$2");
        }
        	setSvc_price(valor)
        if (valor == 'NaN') {
            setSvc_price('');
        }
    }


	function incluirCutos(value: any) {

        var valor = value
        valor = valor + '';
        valor = parseInt(valor.replace(/[\D]+/g, ''));
        valor = valor + '';
        valor = valor.replace(/([0-9]{2})$/g, ".$1");
        if (valor.length > 6) {
            valor = valor.replace(/([0-9]{3}),([0-9]{2}$)/g, "$1.$2");
        }
        setSvc_cost(valor)
        if (valor == 'NaN') {
            setSvc_cost('');
        }
    }

	//=================================ADD UNIDADE=============================================  

	async function confirmarLocal() {

		let ret: AxiosResponse;
		let valor: any;

		setQualAlert(<div className='div-alert'><Loading /></div>)
		setOpenModal(true)


		const _user = secureLocalStorage.getItem('t')

		api.defaults.headers.common['Authorization'] = _user as ''

		try {

			ret = await api.get("/status/validar")

			if (ret.data.status === 200) {

				const FiltrarNome = optionsServices.filter(
					(e: any) => {
						return e.svc_description.toLowerCase() === svc_description.toLowerCase()
					}
				);

				const FiltrarCnpj = optionsServices.filter(
					(e: any) => {
						return e.svc_alternativeidentifier.toLowerCase() === svc_alternativeidentifier.toLowerCase()
					}
				);

				if (svc_description === "") {

					setQualAlert(<div className='div-alert'>
						<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
						<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
						<div className='div-info-alert'><span className="info-alert">Digite a descrição.</span></div>
						<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
					</div>)

				} else if (FiltrarNome.length > 0) {

					setQualAlert(<div className='div-alert'>
						<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
						<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
						<div className='div-info-alert'><span className="info-alert">Descrição já está em uso.</span></div>
						<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
					</div>)


				}

				else if (FiltrarCnpj.length > 0) {

					setQualAlert(<div className='div-alert'>
						<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
						<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
						<div className='div-info-alert'><span className="info-alert">Identificador alternativo já está em uso.</span></div>
						<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
					</div>)
				}

				else if (svc_alternativeidentifier === "") {

					setQualAlert(<div className='div-alert'>
						<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
						<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
						<div className='div-info-alert'><span className="info-alert">Digite o identificador alternativo.</span></div>
						<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
					</div>)
				}
				else {

			
					let ret: AxiosResponse;

					const dados = [{
						svc_active,
						svc_description,
						svc_alternativeidentifier,
						svc_price,
						svc_cost,
						svc_observation,
						age_id_insert: secureLocalStorage.getItem('iag'),
					}];

					try {
						ret = await api.post("services/insert", {
							data: dados,
						});
						if (ret.data.status === 200) {

							setQualAlert(<div className='div-alert'>
								<span className="fa-regular fa-circle-check ControleIconAlert"></span>
								<div className='div-info-alert'><span className="info-alert">Cadastro realizado com sucesso!</span></div>
								<div className='btn-alert' onClick={finalizarModal1}><span className="btn-alert-lab">OK</span></div>
							</div>)
						}
					} catch (e: any) {

						setQualAlert(<div className='div-alert'>
							<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
							<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
							<div className='div-info-alert'><span className="info-alert">Não foi possivel concluir o cadastramento.</span></div>
							<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
						</div>)


					}
				}

			}

		} catch (e: any) {

			if (e.response.data.status === 403) {

				setQualAlert(<div className='div-alert'>
					<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
					<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
					<div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
					<div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
				</div>)


			}
		}


	}

	function finalizarModal1() {
		navigate('/services');
	}



	return (
		<>
			<Nav />
			<div className="main-content">
				<Header />
				<main>
					<div className='containerPrincipal'>



						<div className="title-Page">
							<div className="title-text">
								<div className="size-x circle">1</div>
								<h2>Informações Gerais</h2>
							</div>
							<hr />
						</div>


						<div className="container-cad-prod44">
							<div id="content1">
								<div className="class-checkbox-user2">
									<input type="checkbox" id="checkbox-active"
									 checked={svc_active} onChange={ValitarActive} disabled={svc_activeDisabled} />
									<h4 className="lab-ativo">Ativo</h4>
								</div>
							</div>

						</div>


						<div className="container-cad-prod6">
							<div id="content1">
								<div className="input-box-ativos">
									<span className="details">Descrição *</span>
									<input
										autoComplete="off"
										type="text"
										onChange={(e) => setSvc_description(e.target.value)}
										value={svc_description}
										disabled={svc_descriptionDisabled}
									/>

								</div>
							</div>
							<div id="content2">
								<div className="input-box-ativos">
									<span className="details">Identificador Alternativo *</span>
									<input
										type="text"
										onChange={(e) => setSvc_alternativeidentifier(e.target.value)}
										value={svc_alternativeidentifier}
										autoComplete="off"
										disabled={svc_alternativeidentifierDisabled}
									/>

								</div>
							</div>
							<div id="content3">

						
								<div className="input-box-ativos">
									<span className="details">Custo</span>
									<input
										autoComplete="off"
										type="text"
										onChange={(e) => incluirCutos(e.target.value)}
										value={svc_cost}
										disabled={svc_costDisabled}
									/>

								</div>
						

							</div>
							<div id="content4">
								<div className="input-box-ativos">
									<span className="details">Preço</span>
									<input
										type="text"
										onChange={(e) => incluirValor(e.target.value)}
										value={svc_price}
										autoComplete="off"
										disabled={svc_priceDisabled}
									/>

								</div>
							</div>
						</div>

						
						<div className="container-cad-prod3">
								<div className="textareaBox-ativo">
									<span className="details">Observação</span>
									<textarea
										onChange={(e) => setSvc_observation(e.target.value)}
										value={svc_observation}
										disabled={svc_observationDisabled}
									/>
								</div>
							</div>

						<div className="Bt-button-cad">
							<button type="submit" disabled={confirmarUserDisabled} onClick={confirmarLocal}>
								<i className="fa fa-check-circle icon-ok-btn"></i> Salvar
							</button>
							<button type="submit" onClick={cancelar} disabled={cancelarDisabled}>
								<i className="fa fa-times icon-cancelar-btn"></i> Cancelar
							</button>
						</div>

					</div>
				</main>
			</div>


			<ModalIncluirNovo isOpen={openModalIncluirNovo} setModalOpen={() => setOpenModalIncluirNovo(!openModalIncluirNovo)}>
				<div className='title-modal-incluir'>
					<h1 className="modal-IncluirNovo-title">{titulo}</h1>
					<div onClick={fecharOpenModalIncluirNovo}>
						<button className="fa fa-times icone-modal-IncluirNovo" data-dismiss="modal" aria-label="Close"></button>
					</div>
				</div>
				<div className='scrol-modal'>

					{qualGrid}

				</div>
			</ModalIncluirNovo>


			<ModalAlert isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)}>
				<div className='container-modal-alert'>
					{qualAlert}
				</div>
			</ModalAlert>
		</>
	);

}

export default ServicosAdd;