import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import GlobalStyles from "./styles/GlobalStyles";
import Router from './routes';
import { AuthProvider } from './context/index'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <GlobalStyles />
    <AuthProvider>
      <Router />  
    </AuthProvider>
  </React.StrictMode>
);
