import React, { useState, useEffect } from 'react'
import './index.css'
import { AxiosResponse } from "axios";
import api from '../../Services/api'
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router';

function ServidorForm(props: any) {

    const navigate = useNavigate();
    const [processor, setProcessor] = useState(props.data.pdc_processor || '')
    const [processorSpeed, setProcessorSpeed] = useState(props.data.pdc_processorspeed || '')
    const [ram, setRam] = useState(props.data.pdc_ram || '')
    const [storage, setStorage] = useState(props.data.pdc_storage || '')

    useEffect(() => {

        if (props.data.pdc_id !== undefined || props.data.pdc_id !== '') {
            props.test({
                pdc_processor: props.data.pdc_processor,
                pdc_processorspeed: props.data.pdc_processorspeed,
                pdc_ram: props.data.pdc_ram,
                pdc_storage: props.data.pdc_storage,
            })
        }

        let disabledprocessor = document.getElementById("processor") as HTMLInputElement;
        let disabledprocessorSpeed = document.getElementById("processorSpeed") as HTMLInputElement;
        let disabledram = document.getElementById("ram") as HTMLInputElement;
        let disabledstorage = document.getElementById("storage") as HTMLInputElement;

        if (props.disabled === 'S') {
            disabledprocessor.disabled = true;
            disabledprocessorSpeed.disabled = true;
            disabledram.disabled = true;
            disabledstorage.disabled = true;
        }

    }, []);


    function incluirProcessor(data: any) {
        setProcessor(data)
        props.test({
            pdc_ram: ram,
            pdc_storage: storage,
            pdc_processor: data,
            pdc_processorspeed: processorSpeed,
        })
    }

    function incluirProcessorSpeed(data: any) {
        setProcessorSpeed(data)
        props.test({
            pdc_ram: ram,
            pdc_storage: storage,
            pdc_processor: processor,
            pdc_processorspeed: data,
        })
    }


    function incluirRam(data: any) {
        setRam(data)
        props.test({
            pdc_ram: data,
            pdc_storage: storage,
            pdc_processor: processor,
            pdc_processorspeed: processorSpeed,
        })
    }

    function incluirStorage(data: any) {
        setStorage(data)
        props.test({
            pdc_ram: ram,
            pdc_storage: data,
            pdc_processor: processor,
            pdc_processorspeed: processorSpeed,
        })
    }

    return (
        <>

            <div className="title-Page">
                <div className="title-text">
                    <div className="size-x circle">2</div>
                    <h2>Detalhes técnicos</h2>
                </div>
                <hr />
            </div>

            <div className="container-cad-prodMobi1">

                <div id="content1">
                    <div className="input-box-ativos">
                        <span className="details">Processador</span>
                        <input
                            id="processor"
                            type="text"
                            onChange={(e) => incluirProcessor(e.target.value)}
                            value={processor}
                            autoComplete="off"
                            disabled={props.acpDisabled}
                        />
                    </div>
                </div>

                <div id="content2">
                    <div className="input-box-ativos">
                        <span className="details">Velocidade do Processador</span>
                        <input
                            id="processorSpeed"
                            type="text"
                            onChange={(e) => incluirProcessorSpeed(e.target.value)}
                            value={processorSpeed}
                            autoComplete="off"
                            disabled={props.acpDisabled}
                        />
                    </div>
                </div>
                <div id="content3">
                    <div className="input-box-ativos">
                        <span className="details">RAM</span>
                        <input
                            id="ram"
                            type="text"
                            onChange={(e) => incluirRam(e.target.value)}
                            value={ram}
                            autoComplete="off"
                            disabled={props.acpDisabled}
                        />
                    </div>
                </div>
                <div id="content4">
                    <div className="input-box-ativos">
                        <span className="details">Armazenamento</span>
                        <input
                            id="storage"
                            type="text"
                            onChange={(e) => incluirStorage(e.target.value)}
                            value={storage}
                            autoComplete="off"
                            disabled={props.acpDisabled}
                        />
                    </div>
                </div>

            </div>
        </>
    );

}

export default ServidorForm;