import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';


function BarChartComponent(props:any) {
return (
  <ResponsiveContainer width="95%" height={300}>
  <BarChart data={props.data}>
     <CartesianGrid strokeDasharray="3 3" />
     <XAxis dataKey="name" />
     <YAxis />
     <Tooltip />
     <Bar dataKey="Ativos" fill="#13293D" />
 </BarChart>
 </ResponsiveContainer>

);
}


export default BarChartComponent;