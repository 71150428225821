import React, { useState, useEffect } from 'react'
import './index.css'
import { AxiosResponse } from "axios";
import DualListBox from "react-dual-listbox";
import ModalPrincipal from '../../components/ModalAlert/modal'
import Loading from '../../components/Loading'
import axios from 'axios';
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from 'react-router';
import api from '../../Services/api'
import ModalIncluirNovo from '../../components/ModalIncluirNovo/modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // FontAwesome5 no painel
import Select from 'react-select'
import { MdOutlineAddCircle } from 'react-icons/md';
import {
    faClipboardList,
    faList,
    faTasks,
    faFileAlt,
    faFileInvoice,
    faFileInvoiceDollar,
    faCar,
    faTruck,
    faBus,
    faMotorcycle,
    faBicycle,
    faShippingFast,
    faTractor,
    faAmbulance,
    faMobileAlt,
    faTabletAlt,
    faLaptop,
    faDesktop,
    faServer,
    faNetworkWired,
    faSatellite,
    faSimCard,
    faTools,
    faWrench,
    faScrewdriver,
    faHammer,
    faHardHat,
    faToolbox,
    faCogs,
    faOilCan,
    faWarehouse,
    faArchive,
    faBox,
    faBoxes,
    faPallet,
    faTruckLoading,
    faStore,
    faLock,
    faUnlock,
    faShieldAlt,
    faUserShield,
    faKey,
    faFingerprint,
    faCamera,
    faBriefcase,
    faChartLine,
    faClipboardCheck,
    faTachometerAlt,
    faBatteryFull,
    faPlug,
    faArrowsAltH,
    faCalendarDay,
    faArrowsAlt,
    faArrowsAltV,
    faCompressArrowsAlt,
    faExpandArrowsAlt,
    faPeopleArrows,
    faCalendarAlt,
    faCalendarCheck,
    faCalendarPlus,
    faCalendarTimes,
    faLocationArrow,
    faSearchLocation,
    faMapMarkerAlt,
    faMapMarked,
    faUserAlt,
    faUserCheck,
    faUserCog,
    faUsers,
    faClock,
    faHandsHelping,
    faTag,
    faQuestionCircle,
    faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons';
import FormTaskGroup from '../../components/FormTaskGroup';
import GridSecundario from '../../components/GridSecundario'

const ICONS_PER_PAGE = 20; // Número de ícones por página

const icons = [
    { name: 'clipboard-list', icon: faClipboardList, descricao: 'faClipboardList' },
    { name: 'list', icon: faList, descricao: 'faList' },
    { name: 'tasks', icon: faTasks, descricao: 'faTasks' },
    { name: 'file-alt', icon: faFileAlt, descricao: 'faFileAlt' },
    { name: 'file-invoice', icon: faFileInvoice, descricao: 'faFileInvoice' },
    { name: 'file-invoice-dollar', icon: faFileInvoiceDollar, descricao: 'faFileInvoiceDollar' },
    { name: 'car', icon: faCar, descricao: 'faCar' },
    { name: 'truck', icon: faTruck, descricao: 'faTruck' },
    { name: 'bus', icon: faBus, descricao: 'faBus' },
    { name: 'motorcycle', icon: faMotorcycle, descricao: 'faMotorcycle' },
    { name: 'bicycle', icon: faBicycle, descricao: 'faBicycle' },
    { name: 'shipping-fast', icon: faShippingFast, descricao: 'faShippingFast' },
    { name: 'tractor', icon: faTractor, descricao: 'faTractor' },
    { name: 'ambulance', icon: faAmbulance, descricao: 'faAmbulance' },
    { name: 'mobile-alt', icon: faMobileAlt, descricao: 'faMobileAlt' },
    { name: 'tablet-alt', icon: faTabletAlt, descricao: 'faTabletAlt' },
    { name: 'laptop', icon: faLaptop, descricao: 'faLaptop' },
    { name: 'desktop', icon: faDesktop, descricao: 'faDesktop' },
    { name: 'server', icon: faServer, descricao: 'faServer' },
    { name: 'network-wired', icon: faNetworkWired, descricao: 'faNetworkWired' },
    { name: 'satellite', icon: faSatellite, descricao: 'faSatellite' },
    { name: 'sim-card', icon: faSimCard, descricao: 'faSimCard' },
    { name: 'tools', icon: faTools, descricao: 'faTools' },
    { name: 'wrench', icon: faWrench, descricao: 'faWrench' },
    { name: 'screwdriver', icon: faScrewdriver, descricao: 'faScrewdriver' },
    { name: 'hammer', icon: faHammer, descricao: 'faHammer' },
    { name: 'hard-hat', icon: faHardHat, descricao: 'faHardHat' },
    { name: 'toolbox', icon: faToolbox, descricao: 'faToolbox' },
    { name: 'cogs', icon: faCogs, descricao: 'faCogs' },
    { name: 'oil-can', icon: faOilCan, descricao: 'faOilCan' },
    { name: 'warehouse', icon: faWarehouse, descricao: 'faWarehouse' },
    { name: 'archive', icon: faArchive, descricao: 'faArchive' },
    { name: 'box', icon: faBox, descricao: 'faBox' },
    { name: 'boxes', icon: faBoxes, descricao: 'faBoxes' },
    { name: 'pallet', icon: faPallet, descricao: 'faPallet' },
    { name: 'truck-loading', icon: faTruckLoading, descricao: 'faTruckLoading' },
    { name: 'store', icon: faStore, descricao: 'faStore' },
    { name: 'lock', icon: faLock, descricao: 'faLock' },
    { name: 'unlock', icon: faUnlock, descricao: 'faUnlock' },
    { name: 'shield-alt', icon: faShieldAlt, descricao: 'faShieldAlt' },
    { name: 'user-shield', icon: faUserShield, descricao: 'faUserShield' },
    { name: 'key', icon: faKey, descricao: 'faKey' },
    { name: 'fingerprint', icon: faFingerprint, descricao: 'faFingerprint' },
    { name: 'camera', icon: faCamera, descricao: 'faCamera' },
    { name: 'briefcase', icon: faBriefcase, descricao: 'faBriefcase' },
    { name: 'chart-line', icon: faChartLine, descricao: 'faChartLine' },
    { name: 'clipboard-check', icon: faClipboardCheck, descricao: 'faClipboardCheck' },
    { name: 'tachometer-alt', icon: faTachometerAlt, descricao: 'faTachometerAlt' },
    { name: 'battery-full', icon: faBatteryFull, descricao: 'faBatteryFull' },
    { name: 'plug', icon: faPlug, descricao: 'faPlug' },
    { name: 'arrows-alt-h', icon: faArrowsAltH, descricao: 'faArrowsAltH' },
    { name: 'calendar-day', icon: faCalendarDay, descricao: 'faCalendarDay' },
    { name: 'arrows-alt', icon: faArrowsAlt, descricao: 'faArrowsAlt' },
    { name: 'arrows-alt-v', icon: faArrowsAltV, descricao: 'faArrowsAltV' },
    { name: 'compress-arrows-alt', icon: faCompressArrowsAlt, descricao: 'faCompressArrowsAlt' },
    { name: 'expand-arrows-alt', icon: faExpandArrowsAlt, descricao: 'faExpandArrowsAlt' },
    { name: 'people-arrows', icon: faPeopleArrows, descricao: 'faPeopleArrows' },
    { name: 'calendar-alt', icon: faCalendarAlt, descricao: 'faCalendarAlt' },
    { name: 'calendar-check', icon: faCalendarCheck, descricao: 'faCalendarCheck' },
    { name: 'calendar-plus', icon: faCalendarPlus, descricao: 'faCalendarPlus' },
    { name: 'calendar-times', icon: faCalendarTimes, descricao: 'faCalendarTimes' },
    { name: 'location-arrow', icon: faLocationArrow, descricao: 'faLocationArrow' },
    { name: 'search-location', icon: faSearchLocation, descricao: 'faSearchLocation' },
    { name: 'map-marker-alt', icon: faMapMarkerAlt, descricao: 'faMapMarkerAlt' },
    { name: 'map-marked', icon: faMapMarked, descricao: 'faMapMarked' },
    { name: 'user-alt', icon: faUserAlt, descricao: 'faUserAlt' },
    { name: 'user-check', icon: faUserCheck, descricao: 'faUserCheck' },
    { name: 'user-cog', icon: faUserCog, descricao: 'faUserCog' },
    { name: 'hands-helping', icon: faHandsHelping, descricao: 'faHandsHelping' },
    { name: 'tag', icon: faTag, descricao: 'faTag' },
    { name: 'question-circle', icon: faQuestionCircle, descricao: 'faQuestionCircle' },
    { name: 'exclamation-circle', icon: faExclamationCircle, descricao: 'faExclamationCircle' },
    { name: 'users', icon: faUsers, descricao: 'faUsers' },
    { name: 'clock', icon: faClock, descricao: 'faClock' },
];


function FormTipoTarefa(props: any) {

    const navigate = useNavigate();

    const dadosEquipe = (sessionStorage.getItem("dadosTipoTarefa") || '{}');
    let usertlc = JSON.parse(dadosEquipe);

    const [openModalIncluirNovo, setOpenModalIncluirNovo] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [qualAlert, setQualAlert] = useState(<></>)

    const [id, setId] = useState(usertlc.tty_id || '')
    const [active, setActive] = useState(usertlc.tty_active != undefined ? Boolean(usertlc.tty_active) : true)
    const [description, setDescription] = useState(usertlc.tty_description || '')
    const [descriptionIni, setDescriptionIni] = useState(usertlc.tty_description || '')
    const [alternativeIdentifier, setAlternativeIdentifier] = useState(usertlc.tty_integrationid || '')
    const [alternativeIdentifierIni, setAlternativeIdentifierIni] = useState(usertlc.tty_integrationid || '')
    const [tty_displayorder, setTty_displayorder] = useState(usertlc.tty_displayorder || '')
    const [tgp_id, setTgp_id] = useState(usertlc.tgp_id || '')

    const [dadosActivity, setDadosActivity] = useState([]);
    const [activitySelecionada, setActivitySelecionada] = useState<string[]>([]);

    const [optionstaskgroup, setOptionstaskgroup] = useState([])
    const [optionsTasktype, setOptionsTasktype] = useState([])

    const [activeDisabled, setActiveDisabled] = useState(false)
    const [descriptionDisabled, setDescriptionDisabled] = useState(false)
    const [alternativeIdentifierDisabled, setAlternativeIdentifierDisabled] = useState(false)


    const [selectedIcon, setSelectedIcon] = useState<string | null>(null);
    const [currentPage, setCurrentPage] = useState(1);

    const [qualTitulo, setQualTitulo] = useState('')
	const [qualGrid, setQualGrid] = useState(<></>)

    const [openModalIncluirNovo2, setOpenModalIncluirNovo2] = useState(false)
    const [exibirGrid, setExibirGrid] = useState(<></>);

    // Calcular o número total de páginas
    const totalPages = Math.ceil(icons.length / ICONS_PER_PAGE);

    // Calcular quais ícones exibir na página atual
    const startIndex = (currentPage - 1) * ICONS_PER_PAGE;
    const endIndex = startIndex + ICONS_PER_PAGE;
    const iconsToDisplay = icons.slice(startIndex, endIndex);

    const handleIconClick = (iconName: string) => {
        setSelectedIcon(iconName);
        setOpenModalIncluirNovo(false)
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    useEffect(() => {


        if (usertlc.tty_id == 1 || usertlc.tty_id == 2 || usertlc.tty_id == 3 || usertlc.tty_id == 4) {
            setActiveDisabled(true)
            setDescriptionDisabled(true)
            setAlternativeIdentifierDisabled(true)
        }


        if (usertlc.tty_icon !== undefined) {
            setSelectedIcon(usertlc.tty_icon)
        } else {

            setSelectedIcon('clipboard-list')
        }

        setQualAlert(<div className='div-alert'><Loading /></div>)
        setOpenModal(true)

        validatToken();

    }, []);


    async function validatToken() {

        let ret: AxiosResponse;
        let valor: any;


        const _user = secureLocalStorage.getItem('t')

        api.defaults.headers.common['Authorization'] = _user as ''

        try {

            ret = await api.get("/status/validar")

            if (ret.data.status === 200) {

                secureLocalStorage.setItem('t', ret.data.data);
                secureLocalStorage.setItem('acp', ret.data.decodedToken.acp_integrationid);
                secureLocalStorage.setItem('n', ret.data.decodedToken.name);
                secureLocalStorage.setItem('iag', ret.data.decodedToken.id);
                secureLocalStorage.setItem('cy', ret.data.decodedToken.company);

              await GetTasktype();
              await GetTaskGrupo();

                if (usertlc.tty_id !== undefined) {

                    await GetActivity();
                    await GetTasktypeactivityEditar(usertlc.tty_id)
                } else {
                    await GetActivity();
                }

                setOpenModal(false)
            }

        } catch (e: any) {

            if (e.response.data.status === 403) {

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
                    <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)

            }
        }

    }


    function fecharModal() {
        secureLocalStorage.removeItem('t')
        secureLocalStorage.removeItem('acp')
        setOpenModal(false)
        navigate('/login');

    }



    async function GetTasktype() {

        let ret: AxiosResponse;
        let valor: any;
        try {
            ret = await api.get("tasktype/listar")
            if (ret.data.status === 200) {
                valor = ret.data.data;


                setOptionsTasktype(valor)

            }
        } catch (e) {

            setOptionsTasktype([])
        }
    }

    async function GetTasktypeactivityEditar(id: any) {
        let ret: AxiosResponse;
        let valor: any;

        try {
            ret = await api.get("tasktypeactivity/listar", {
                params: {
                    id: id
                }
            })
            if (ret.data.status === 200) {

                if (ret.data.data.length > 0) {



                    const newarray = ret.data.data.map((element: any) => {
                        return String(element.act_id)
                    })



                    setActivitySelecionada(newarray)
                    setOpenModal(false)
                }

            }
        } catch (e) {
            setActivitySelecionada([])
            setOpenModal(false)
        }
    }

    async function GetActivity() {
        let ret: AxiosResponse;
        let valor: any;

        try {
            ret = await api.get("mobileactivity/listartasktypeactivity")
            if (ret.data.status === 200) {
                valor = ret.data.data;

                console.log('---------', valor)
                setDadosActivity(valor)

            }
        } catch (e) {

            setDadosActivity([])
        }
    }

    function ConfirmarTeamtype() {



        if (id === undefined || id === '') {
            insertTipoAgente();

        } else {
            UpdateTipoAgente();
        }
    }

    async function UpdateTipoAgente() {
        let ret: AxiosResponse;

        setQualAlert(<div className='div-alert'><Loading /></div>)
        setOpenModal(true)

        const FiltrarLogin = optionsTasktype.filter(
            (e: any) => {
                return e.tty_description.toLowerCase() === description.toLowerCase()
            }
        );

        const FiltraralternativeIdentifier = optionsTasktype.filter(
            (e: any) => {
                return e.tty_integrationid.toLowerCase() === alternativeIdentifier.toLowerCase()
            }
        );

        if (descriptionIni.toLowerCase() !== description.toLowerCase() && FiltrarLogin.length > 0) {


            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Descrição já está em uso.</span></div>
                <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
            </div>)

            setOpenModal(true)
        } else
            if (alternativeIdentifierIni.toLowerCase() !== alternativeIdentifier.toLowerCase() && FiltraralternativeIdentifier.length > 0) {


                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Identificador alternativo já está em uso.</span></div>
                    <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)
            } else
                if (description === "") {

                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Digite a descrição.</span></div>
                        <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                    </div>)

                    setOpenModal(true)
                } else
                    if (alternativeIdentifier === "") {

                        setQualAlert(<div className='div-alert'>
                            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                            <div className='div-info-alert'><span className="info-alert">Digite o identificador alternativo.</span></div>
                            <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                        </div>)
                        setOpenModal(true)
                    }else
                    if (tgp_id === "") {

                        setQualAlert(<div className='div-alert'>
                            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                            <div className='div-info-alert'><span className="info-alert">Selecione o grupo da tarefa.</span></div>
                            <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                        </div>)

                        setOpenModal(true)
                    } else
                        if (tty_displayorder === "") {

                            setQualAlert(<div className='div-alert'>
                                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                                <div className='div-info-alert'><span className="info-alert">Digite a ordem de exibição.</span></div>
                                <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                            </div>)

                            setOpenModal(true)
                        }  else {

                            const dados = {
                                tty_id: id,
                                tty_active: active,
                                tty_description: description,
                                tty_integrationid: alternativeIdentifier,
                                tty_displayorder: tty_displayorder,
                                tty_icon: selectedIcon,
                                tgp_id
                            }

                            const _user = secureLocalStorage.getItem('t') as ''
                            const endpoint = secureLocalStorage.getItem('ep') as ''


                            try {
                                ret = await api.put("tasktype/update", {
                                    data: dados,
                                    dadosactivitySelecionada: activitySelecionada
                                });
                                if (ret.data.status === 200) {

                                    setOpenModal(false)
                                    props.voltar()
                                }

                            } catch (e: any) {
                                setQualAlert(<div className='div-alert'>
                                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                                    <div className='div-info-alert'><span className="info-alert">Não foi possível concluir a alteração.</span></div>
                                    <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                                </div>)
                                setOpenModal(true)
                            }
                        }
    }

    async function insertTipoAgente() {
        let ret: AxiosResponse;


        const FiltrarLogin = optionsTasktype.filter(
            (e: any) => {
                return e.tty_description.toLowerCase() === description.toLowerCase()
            }
        );

        const FiltraralternativeIdentifier = optionsTasktype.filter(
            (e: any) => {
                return e.tty_integrationid.toLowerCase() === alternativeIdentifier.toLowerCase()
            }
        );

        if (FiltrarLogin.length > 0) {


            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Descrição já está em uso.</span></div>
                <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
            </div>)

            setOpenModal(true)

        } else
            if (FiltraralternativeIdentifier.length > 0) {


                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Identificador alternativo já está em uso.</span></div>
                    <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                </div>)
                setOpenModal(true)

            } else
                if (description === "") {

                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Digite a descrição.</span></div>
                        <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                    </div>)
                    setOpenModal(true)

                } else
                    if (alternativeIdentifier === "") {

                        setQualAlert(<div className='div-alert'>
                            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                            <div className='div-info-alert'><span className="info-alert">Digite o identificador alternativo.</span></div>
                            <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                        </div>)
                        setOpenModal(true)
                    }  else
                    if (tgp_id === "") {

                        setQualAlert(<div className='div-alert'>
                            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                            <div className='div-info-alert'><span className="info-alert">Selecione o grupo da tarefa.</span></div>
                            <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                        </div>)

                        setOpenModal(true)
                    } else
                        if (tty_displayorder === "") {

                            setQualAlert(<div className='div-alert'>
                                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                                <div className='div-info-alert'><span className="info-alert">Digite a ordem de exibição.</span></div>
                                <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                            </div>)

                            setOpenModal(true)
                        }
                        else {
                            const dados = [{
                                tty_active: active,
                                tty_description: description,
                                tty_integrationid: alternativeIdentifier,
                                tty_displayorder: tty_displayorder,
                                tty_icon: selectedIcon,
                                tgp_id
                            }]

                            const _user = secureLocalStorage.getItem('t') as ''
                            const endpoint = secureLocalStorage.getItem('ep') as ''

                            try {
                                ret = await api.post("tasktype/insert", {
                                    data: dados,
                                    dadosactivitySelecionada: activitySelecionada
                                });
                                if (ret.data.status === 200) {

                                    setOpenModal(false)
                                    props.voltar()



                                }

                            } catch (e: any) {

                                setQualAlert(<div className='div-alert'>
                                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                                    <div className='div-info-alert'><span className="info-alert">Não foi possível concluir o cadastramento.</span></div>
                                    <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                                </div>)
                                setOpenModal(true)
                            }
                        }
    }

    function finalizarModal2() {
        setOpenModal(false)
    }

    function ValitarActive() {
        let e = document.getElementById("checkbox-active-fab") as HTMLInputElement;

        if (e.checked) {

            setActive(true)
        } else {

            setActive(false)
        }
    }

    function voltar() {

        props.voltar();

    }

    let options = dadosActivity.map((element: any) => {
        return { value: String(element.act_id), label: element.act_description }
    });

    function valor(selected: any) {
        setActivitySelecionada(selected)
    }

    const maskOnlyNumbers = (value: any) => {
        return value.replace(/\D/g, "");
    };

    function fecharOpenModalIncluirNovo() {
        setOpenModalIncluirNovo(false)
    }

    function abrirModal() {
        setOpenModalIncluirNovo(true)
        setCurrentPage(1)
    }

    // Retorna o ícone se encontrado, caso contrário retorna null

    const customStyles = {
        control: (provided: any, state: any) => ({
            ...provided,
            background: '#fff',
            minHeight: '30px',
            height: '30px',
            borderColor: state.isFocused ? 'grey' : '#92989e',

        }),


        valueContainer: (provided: any, state: any) => ({
            ...provided,
            height: '30px',
            padding: '0 6px'
        }),

        input: (provided: any, state: any) => ({
            ...provided,
            margin: '0px',
            fontSize: 14,

        }),
        indicatorsContainer: (provided: any, state: any) => ({
            ...provided,
            height: '30px',
        }),
    };


    async function GetTaskGrupo() {
        let ret: AxiosResponse;
        let valor: any;
        try {
            ret = await api.get("taskgroup/listar")
            if (ret.data.status === 200) {
                valor = ret.data.data;

                const arr = [] as any;
                let result = valor;

                result.map((user: any) => {
                    return arr.push({ value: user.tgp_id, label: user.tgp_description});
                });

                setOptionstaskgroup(arr)
            }
        } catch (e) {
            setOptionstaskgroup([])
        }
    }

    function fecharOpenModalIncluirNovo2() {
		setOpenModalIncluirNovo2(false)
	}

    function incluirGrupoTask(){
        setQualTitulo('Grupo da Tarefa')
        setQualGrid(<><GridSecundario PegarValorGrid={PegarValorGrid}
          Iconeadd={ChamarCadastrogrupotarefa}
          Iconeditar={Editargrupotarefa}
          iconeExcluir={grupotarefaDeletar}
          th1='Ativo'
          th2='ID'
          th3='Descrição'
          th4='Identificador Alternativo'
          th5=''
          th6=''
          endPoint='taskgroup/buscar'
          consult='N'
          col1='1'
          col2='2'
          col3='3'
          ambiente='%'
          pag="ok"
        /></>)
  
        setOpenModalIncluirNovo2(true)
        sessionStorage.removeItem("dadosFab");
       GetTaskGrupo();

    }

//=================================grupo de tarefas=============================================
function ChamarCadastrogrupotarefa() {
    sessionStorage.removeItem("dadosTipoEquipe");
    setQualTitulo('Grupo da tarefa')
    setQualGrid(<><FormTaskGroup voltar={BtnVoltargrupotarefa} /></>)
  }

  async function Editargrupotarefa(obj: any) {
    if (secureLocalStorage.getItem('acp') !== 'CONSULTOR') {
      sessionStorage.setItem("dadosTipoEquipe", JSON.stringify(obj));
      setQualTitulo('Grupo da tarefa')
      setQualGrid(<><FormTaskGroup voltar={BtnVoltargrupotarefa} /></>)
    }
  }

  function grupotarefaDeletar(obj: any) {

    if (secureLocalStorage.getItem('acp') !== 'CONSULTOR') {
      setQualAlert(<div className='div-alert'>
        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
        <div className='div-info-alert-atencao'><span className="info-alert-atencao">Deseja deletar o registro?</span></div>
        <div className='div-info-alert'><span className="info-alert">Você não poderá reverter.</span></div>

        <div className='btn-alert-div'>
          <span className='btn-alert-esc' onClick={() => deletargrupotarefa(obj.tgp_id)}>
            <span className="btn-alert-lab">SIM</span>
          </span>
          <span className='btn-alert-dir' onClick={finalizarModal2}>
            <span className="btn-alert-lab">NÃO</span>
          </span>
        </div>
      </div>)

      setOpenModal(true)
    }
  }


  async function deletargrupotarefa(id: any) {
    setOpenModal(false)
    setQualGrid(<><Loading /></>)
    let ret: AxiosResponse;
    try {
      ret = await api.delete("correctivesubgroup/delete", {
        data: { id: id }
      });
      if (ret.data.status === 200) {
        setQualTitulo('Grupo da tarefa')
        setQualGrid(<><GridSecundario PegarValorGrid={PegarValorGrid}
          Iconeadd={ChamarCadastrogrupotarefa}
          Iconeditar={Editargrupotarefa}
          iconeExcluir={grupotarefaDeletar}
          th1='Ativo'
          th2='ID'
          th3='Descrição'
          th4='Identificador Alternativo'
          th5=''
          th6=''
          endPoint='taskgroup/buscar'
          consult='N'
          col1='1'
          col2='2'
          col3='3'
          ambiente='%'
          pag="ok"
        /></>)

      }
    } catch (e: any) {
      setQualTitulo('Grupo da tarefa')
      setQualGrid(<><GridSecundario PegarValorGrid={PegarValorGrid}
        Iconeadd={ChamarCadastrogrupotarefa}
        Iconeditar={Editargrupotarefa}
        iconeExcluir={grupotarefaDeletar}
        th1='Ativo'
        th2='ID'
        th3='Descrição'
        th4='Identificador Alternativo'
        th5=''
        th6=''
        endPoint='taskgroup/buscar'
        consult='N'
        col1='1'
        col2='2'
        col3='3'
        ambiente='%'
        pag="ok"
      /></>)

    }
  }

  function BtnVoltargrupotarefa() {
    setQualTitulo('Grupo da tarefa')
    setQualGrid(<><GridSecundario PegarValorGrid={PegarValorGrid}
      Iconeadd={ChamarCadastrogrupotarefa}
      Iconeditar={Editargrupotarefa}
      iconeExcluir={grupotarefaDeletar}
      th1='Ativo'
      th2='ID'
      th3='Descrição'
      th4='Identificador Alternativo'
      th5=''
      th6=''
      endPoint='taskgroup/buscar'
      consult='N'
      col1='1'
      col2='2'
      col3='3'
      ambiente='%'
      pag="ok"
    /></>)
    GetTaskGrupo();
  }

  //==============================================================================

  function PegarValorGrid(valor: any){
    GetTaskGrupo();
    setTgp_id(valor.tgp_id)
    setOpenModalIncluirNovo2(false)
    }

    return (
        <>
            <div className="container-modal-pric">

                <div className="title-Page">
                    <div className="title-text">
                        <div className="size-x circle">1</div>
                        <h2>Informações Gerais</h2>
                    </div>
                    <hr />
                </div>

                <div className="class-checkbox">
                    <input type="checkbox" id="checkbox-active-fab"
                        checked={active}
                        onChange={ValitarActive}
                        disabled={activeDisabled} />
                    <h4 className="lab-ativo">Ativo</h4>
                </div>

                <h4 className="lab-icon">Ícone</h4>
                <div className='setar-icon' onClick={abrirModal}>

                    {selectedIcon ? (
                        <FontAwesomeIcon
                            icon={icons.find((i) => i.name === selectedIcon)?.icon ?? faClipboardList}
                            size="3x"
                        />
                    ) : (
                        <FontAwesomeIcon icon={faClipboardList} size="3x" color="#92989e" />
                    )}


                </div>

    
                <div className="container-cad-prod4">
                    <div id="content1">
                        <div className="input-box-ativos">
                            <span className="details">Descrição *</span>
                            <input
                                type="text"
                                onChange={(e) => setDescription(e.target.value)}
                                value={description}
                                autoComplete="off"
                                disabled={descriptionDisabled}
                            />
                        </div>
                    </div>
                    <div id="content2">
                        <div className="input-box-ativos">
                            <span className="details">Identificador Alternativo *</span>
                            <input
                                autoComplete="off"
                                type="text"
                                onChange={(e) => setAlternativeIdentifier(e.target.value)}
                                value={alternativeIdentifier}
                                disabled={alternativeIdentifierDisabled}
                            />
                        </div>
                    </div>
                </div>


                <div className="container-cad-prod4">
                    <div id="content1">
                        <div className="select-box-ativos-select">
                        <div className='details-flex'>
                                        <span className="details">Grupo de tarefa *</span>
                                        <MdOutlineAddCircle className='details-flex-icon-ativo' />
                                        <span className="details-esc-ativo" onClick={incluirGrupoTask}>Incluir Novo</span>
                                    </div>
                         
                            <Select
                                maxMenuHeight={100}
                                placeholder={'Selecione...'}
                                options={optionstaskgroup}
                                styles={customStyles}
                                value={optionstaskgroup.filter(function (option: any) {
                                    return option.value === tgp_id;
                                })}
                                onChange={(options: any) =>
                                    !options ? setTgp_id("") : setTgp_id(options.value)
                                }
                                isClearable
                                noOptionsMessage={() => "Não encontrado..."}
                                theme={(theme: any) => ({
                                    ...theme,
                                    borderRadius: 4,
                                    colors: {
                                        ...theme.colors,
                                        primary25: '#ebebeb',
                                        primary: 'black',
                                    },
                                })}
                            />
                        </div>
                    </div>
                    <div id="content2">
                        <div className="input-box-ativos">
                            <span className="details">Ordem de exibição *</span>
                            <input
                                autoComplete="off"
                                type="text"
                                onChange={(e) => setTty_displayorder(maskOnlyNumbers(e.target.value))}
                                value={tty_displayorder} />
                        </div>
                    </div>
                </div>

                <div className="title-Page">
                    <div className="title-text">
                        <div className="size-x circle">2</div>
                        <h2>Atividades</h2>
                    </div>
                    <hr />
                </div>

                <div className='DualListBox'>
                    <div className="input-box">
                        <span className="details-list">ATIVIDADES DISPONÍVEIS:</span>
                    </div>
                    <DualListBox
                        options={options}
                        selected={activitySelecionada}
                        onChange={valor}
                        icons={{
                            moveLeft: "<",
                            moveAllLeft: "<<",
                            moveRight: ">",
                            moveAllRight: ">>"
                        }}
                        preserveSelectOrder={true}
                    />
                </div>





                <div className="Bt-button-cad">
                    <button type="submit" onClick={ConfirmarTeamtype}>
                        <i className="fa fa-check-circle icon-ok-btn"></i> Salvar
                    </button>
                    <button type="submit" onClick={props.voltar}>
                        <i className="fa fa-times icon-cancelar-btn"></i> Cancelar
                    </button>
                </div>
            </div>


            <ModalIncluirNovo isOpen={openModalIncluirNovo} setModalOpen={() => setOpenModalIncluirNovo(!openModalIncluirNovo)}>
                <>
                    <div className='title-modal-incluir'>
                        <h1 className="modal-IncluirNovo-title">Ícones</h1>
                        <div onClick={fecharOpenModalIncluirNovo}>
                            <button className="fa fa-times icone-modal-IncluirNovo" data-dismiss="modal" aria-label="Close"></button>
                        </div>
                    </div>

                    <div className='scrol-modal'>
                        <div>

                            <div className="icon-grid">
                                {iconsToDisplay.map((icon) => (
                                    <div
                                        key={icon.name}
                                        className={`icon-item ${selectedIcon === icon.name ? 'selected' : ''}`}
                                        onClick={() => handleIconClick(icon.name)}
                                    >
                                        <FontAwesomeIcon icon={icon.icon} size="2x" />
                                    </div>
                                ))}
                            </div>

                            <div className="pagination">
                                <button onClick={handlePrevPage} disabled={currentPage === 1}>
                                    Anterior
                                </button>
                                <span>Página {currentPage} de {totalPages}</span>
                                <button onClick={handleNextPage} disabled={currentPage === totalPages}>
                                    Próximo
                                </button>
                            </div>
                        </div>
                    </div>
                </>
            </ModalIncluirNovo>


            <ModalIncluirNovo isOpen={openModalIncluirNovo2} setModalOpen={() => setOpenModalIncluirNovo2(!openModalIncluirNovo)}>
				<>
					<div className='title-modal-incluir'>
						<h1 className="modal-IncluirNovo-title">{qualTitulo}</h1>
                        <div  onClick={fecharOpenModalIncluirNovo2} >
						<button className="fa fa-times icone-modal-IncluirNovo" data-dismiss="modal" aria-label="Close" onClick={fecharOpenModalIncluirNovo2}></button>
                        </div>
                    </div>

                    <div className='scrol-modal'>
						{qualGrid}
					</div>
				</>
			</ModalIncluirNovo>

            <ModalPrincipal isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)}>
                <div className='container-modal-alert'>
                    {qualAlert}
                </div>
            </ModalPrincipal>

        </>
    );

}

export default FormTipoTarefa;