import React, { useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/pt-br';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import ModalIncluirNovo from '../../components/ModalIncluirNovo/modal';
import './WeeklyCalendar.css'
import { FaEdit } from "react-icons/fa";
import { useNavigate } from 'react-router';

moment.locale('pt-br');
const localizer = momentLocalizer(moment);

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const customFormats = {
  monthHeaderFormat: (date, culture, localizer) => {
    return capitalizeFirstLetter(moment(date).format('MMMM YYYY'));
  },
  dayRangeHeaderFormat: ({ start, end }, culture, localizer) => {
    const startDate = capitalizeFirstLetter(moment(start).format('MMMM DD'));
    const endDate = moment(end).format('DD');
    return `${startDate} – ${endDate}`;
  },
  dayHeaderFormat: (date, culture, localizer) => {
    return capitalizeFirstLetter(moment(date).format('dddd, MMM DD')); // Personaliza o formato do cabeçalho do dia
  }
};

// Função para aplicar estilos personalizados aos eventos
const eventStyleGetter = (event, start, end, isSelected) => {
  const now = new Date();
  let backgroundColor = '#3174ad'; // Cor padrão

  if (end < now) {
    backgroundColor = '#ff4d4d'; // Vermelho para eventos passados
  }

  const style = {
    backgroundColor,
    color: 'white',
    borderRadius: '0px',
    opacity: 0.8,
    border: '0px',
    display: 'block',
  };

  return { style };
};

// Componente de Evento Personalizado
const CustomEvent = ({ event }) => {
  return (
    <div>
      <span>{moment(event.start).format('HH:mm')} {event.title}</span> {/* Exibe o título primeiro */}
    </div>
  );
};

const MeuCalendario = (props) => {

  const navigate = useNavigate();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [view, setView] = useState('month');
  const [date, setDate] = useState(new Date());

  const openModal = (event) => {
    setSelectedEvent(event);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedEvent(null);
  };

  function fecharOpenModalIncluirNovo() {
    setModalIsOpen(false);
  }

  const adjustToTimezone = (dateStr) => {
    const date = new Date(dateStr);
    date.setTime(date.getTime() + 3 * 60 * 60 * 1000);
    return date;
  };

  const formattedEvents = props.data.map(event => ({
    ...event,
    start: adjustToTimezone(event.start),
    end: event.end ? adjustToTimezone(event.end) : adjustToTimezone(event.start),
  }));

  function editar(id){

    let teste = `/planner/edit/` + id

    navigate(teste);

  }

  return (
    <div style={{ height: '700px' }}>
      <Calendar
        localizer={localizer}
        events={formattedEvents}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
        views={['month', 'week', 'day']}
        defaultView="month"
        formats={customFormats}
        eventPropGetter={eventStyleGetter}
        components={{
          event: CustomEvent // Usa o componente customizado para renderizar os eventos
        }}
        messages={{
          next: "Próximo",
          previous: "Anterior",
          today: "Hoje",
          month: "Mês",
          week: "Semana",
          day: "Dia",
        }}
        defaultDate={date} // Define a data inicial como o dia atual
        view={view}
        onView={(view) => {
          setView(view);
          if (view === 'week') {
            // Força a data para o início da semana atual (segunda-feira)
            const startOfWeek = moment().startOf('isoWeek').toDate();
            setDate(startOfWeek);
          } else if (view === 'day') {
            // Mantém a data no dia atual
            setDate(new Date());
          }
        }}
        date={date}
        onNavigate={(newDate) => setDate(newDate)}
        onSelectEvent={openModal}
      />

      <ModalIncluirNovo isOpen={modalIsOpen} setModalOpen={() => setModalIsOpen(!modalIsOpen)}>
        <>
          <div className='title-modal-incluir'>
            <h1 className="modal-IncluirNovo-title">Resumo da tarefa</h1>
            <div onClick={fecharOpenModalIncluirNovo}>
              <button className="fa fa-times icone-modal-IncluirNovo" data-dismiss="modal" aria-label="Close"></button>
            </div>
          </div>

          <div className='scrol-modal'>
            {selectedEvent && (
              
               <div className="container-cad-prod49">
                
                <div id="content1">
                  <div>
                <h2 style={{ marginBottom: '12px', color: '#000' }}>#{selectedEvent.pla_id}  {selectedEvent.title}</h2>
                <p style={{ marginBottom: '10px' }}><strong>Tipo de Tarefa:</strong> {selectedEvent.tty_description}</p>
                <p style={{ marginBottom: '10px' }}><strong>Data/Hora:</strong> {moment(selectedEvent.start).format('LLLL')}</p>
                
                </div>
                </div>
                <div id="content2">
                  <div className="btn-edt-cal" onClick={()=> editar(selectedEvent.pla_id)}>
                    <FaEdit style={{ fontSize: '20px' }}/>
                    <h1  style={{ fontSize: '14px', marginTop: '5px' }}>Editar</h1>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      </ModalIncluirNovo>
    </div>
  );
};

export default MeuCalendario;
