import React, { useState, useEffect } from "react";

export default function Step(props) {

      
    return (
        <div className={"stepBlock" + (props.selected ? " selected" : "")}>
            <div className="circleWrapper">

                <div className="circle-ticket">{props.index + 1}</div>

            </div>
            <span className="lab-step">{props.label}</span>
        </div>
    )
}