import React, { useEffect, useState, useRef } from 'react'
import Nav from "../../components/Nav";
import Header from "../../components/Header";
import GridTask from '../../components/GridTask'
import Swal from 'sweetalert2';
import api from "../../Services/api";
import { AxiosResponse } from "axios";
import DropdownItem from '../../components/DropdownItem'
import { Link } from "react-router-dom";
import './index.css';
import ModalPrincipal from '../../components/ModalAlert/modal'
import Loading from '../../components/Loading';
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import ModalIncluirNovo from '../../components/ModalIncluirNovo/modal'
import GridSituacaoTask from '../../components/GridSituacaoTask'

function Task() {

    const navigate = useNavigate();
    const [exibirGrid, setExibirGrid] = useState(<></>);
    const [open, setOpen] = useState(false);
    const [openModal, setOpenModal] = useState(false)
    const [qualAlert, setQualAlert] = useState(<></>)
    const [qualTitulo, setQualTitulo] = useState('')
    const [qualGrid, setQualGrid] = useState(<></>)

    const [openModalIncluirNovo, setOpenModalIncluirNovo] = useState(false)

    let menuRef = useRef() as any;

    useEffect(() => {

        let handler = (e: any) => {
            if (!menuRef.current.contains(e.target)) {
                setOpen(false);

            }
        };
        document.addEventListener("mousedown", handler);
        return () => {
            document.removeEventListener("mousedown", handler);
        }


    });



    function fecharModal() {

        secureLocalStorage.removeItem('t')
        secureLocalStorage.removeItem('acp')
        secureLocalStorage.removeItem('n')
        secureLocalStorage.removeItem('iag')
        navigate('/login');
        setOpenModal(false)

    }

    function selecionar() {
        let handler = (e: any) => {
            if (!menuRef.current.contains(e.target)) {
                setOpen(false);

            }
        };
        document.addEventListener("mousedown", handler);
        return () => {
            document.removeEventListener("mousedown", handler);
        }
    }

    useEffect(() => {
        validatToken()
    }, []);



    async function validatToken() {

        let ret: AxiosResponse;
        let valor: any;

        const _user = secureLocalStorage.getItem('t')


        api.defaults.headers.common['Authorization'] = _user as ''

        try {

            ret = await api.get("/status/validar")

            if (ret.data.status === 200) {

                secureLocalStorage.setItem('t', ret.data.data);
                secureLocalStorage.setItem('acp', ret.data.decodedToken.acp_integrationid);
                secureLocalStorage.setItem('n', ret.data.decodedToken.name);
                secureLocalStorage.setItem('iag', ret.data.decodedToken.id);
                secureLocalStorage.setItem('cy', ret.data.decodedToken.company);

                let ambiente = String(secureLocalStorage.getItem('tp')).toLowerCase()

                setExibirGrid(<GridTask
                    iconeExcluir={ModalDeletar}
                    th1='Ativo'
                    th2='ID'
                    th3='Onde'
                    th4='Quem'
                    th5='Previsto'
                    th6='Executado'
                    th7='Situação'
                    th8='Tipo de tarefa'
                    endPoint='task/buscartask'
                    col1='1'
                    col2='0'
                    col3='2'
                    col4='3'
                    col5='5'
                    col6='6'
                    col7='4'
                    col8=''
                    col9='7'
                    col10='8'
                    col11='9'
                    col12='10'
                    col13=''
                    fromAdd='/task/add'
                    fromEdit='/task/edit'
                    fromvisu={`/task/report/${ambiente}`}
                    btnDelete='S'
                    pag='task'
                    user='user/listar'
                    team='team/listar'
                    local='local/listarlocal'
                    asset='asset/listaresset'
                    tasksituation='tasksituation/listar'
                    tasktype='tasktype/listar'
                    correctivetype='correctivetype/listar'
                    preventivetype='preventivetype/listar'
                    buscartask='task/buscartask'
                    fecharModal={fecharModal}
                    copiarTask={copiarTask}
                    consultarSituacao={consultarSituacao}
                />)

                setOpenModal(false)
            }

        } catch (e: any) {

            if (e.response === undefined) {
                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Sem comunicação com o servidor.</span></div>
                    <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)
            } else
                if (e.response.status === 403) {

                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado.</span></div>
                        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                    </div>)

                    setOpenModal(true)

                } else {
                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Não foi possível concluir sua solicitação.</span></div>
                        <div className='btn-alert' onClick={fecharModal2}><span className="btn-alert-lab">OK</span></div>
                    </div>)

                    setOpenModal(true)
                }
        }

    }

    function consultarSituacao(data: any){
        console.log(data.tsk_id)

        setQualTitulo('Situação')
        
        setQualGrid((<><GridSituacaoTask
            th1='Adicionar'
            th2='ID'
            th3='Descrição'
            th4='Numero de Série'
            th5='Tombamento'
            th6=''
            endPoint='situationtask/listar'
            consult='N'
            col1='1'
            col2='2'
            col3='3'
            col4='0'
            id={data.tsk_id}
            outParams='N'
          /></>))

        setOpenModalIncluirNovo(true)

    }

  async  function copiarTask(data: any){
        let ret: AxiosResponse;

        setQualAlert(<div className='div-alert'><Loading /></div>)
        setOpenModal(true)

        let today = new Date();
        let date =
            today.getFullYear() +
            "-" +
            (today.getMonth() + 1).toString().padStart(2, "0") +
            "-" +
            today.getDate().toString().padStart(2, "0");
        let time =
            today.getHours().toString().padStart(2, "0") +
            ":" +
            today.getMinutes().toString().padStart(2, "0");
        let datatimeAtual = date + "T" + time;

        const newUuid = uuidv4()

        console.log(data.tea_id)

        let userAtual: null

        if(data.tea_id === '' || data.tea_id === null){
    
            userAtual = data.age_id
            
        }else{

            userAtual = null

        }

        console.log('tsk_notification', data.tsk_notification)

        const dados = [{
        tsk_active: data.tsk_active || null,    
        tsk_scheduleinitialdatehour: datatimeAtual,
        tsk_observation: data.tsk_observation || null,
        tsk_integrationid: data.tsk_integrationid || null,
        age_id: userAtual,
        ast_id: data.ast_id || null,
        stn_id:  30,
        tsk_waitingtime: data.tsk_waitingtime || null,
        tsk_priority: data.tsk_priority || null,
        src_id:  1,
        tea_id:  data.tea_id || null,
        tsf_id:  data.tsf_id || null,
        tsk_mobilesenddate:  data.tsk_mobilesenddate || null,
        tsk_accesstoken: newUuid,
        tty_id:  data.tty_id || null,
        ivb_id:  data.ivb_id || null,
        tsk_notification:  data.tsk_notification,
        crt_id:  data.crt_id || null,
        pvt_id:  data.pvt_id || null,
        tsk_requester: data.tsk_requester || null,
        tsk_responsible: data.tsk_responsible || null,
        agd_id:  data.agd_id || null,
        tsk_technicalinstruction: data.tsk_technicalinstruction || null,
        tsk_frequency: data.tsk_frequency || null,
        tsk_callback: data.tsk_callback || null,
        tsk_telephone: data.tsk_telephone || null,
        tsk_agendadescription: data.tsk_agendadescription || null,
        tsk_image1: data.tsk_image1 || null,
        tsk_image2:  data.tsk_image2 || null,
        ctc_id:  data.ctc_id || null,
        unt_id:  data.unt_id || null,
        loc_id:  data.loc_id || null,
        tsk_userdestination: data.tsk_userdestination || null,
        tsk_origemid:  data.tsk_origemid || null,
        ett_id:  data.ett_id || null,
        age_id_insert: secureLocalStorage.getItem('iag'),
        tsk_recreate:  data.tsk_recreate,
        pla_id: data.pla_id || null,
         }]

         

         try {
            ret = await api.post("task/insert", {
                data: dados,
                dadosComp: []
            });
            if (ret.data.status === 200) {


                const id = ret.data.data[0].tsk_id;
                const path = `/task/edit/${id}`;
                navigate(path);

             
               
            }

        } catch (e: any) {
            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert'><span className="info-alert">Não é possível criar a Tarefa.</span></div>
                <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
            </div>)
        }

    }


    function fecharModal2() {
        setOpenModal(false)
    }

    function ModalDeletar(obj: any) {
        setQualAlert(<div className='div-alert'>
            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
            <div className='div-info-alert-atencao'><span className="info-alert-atencao">Deseja deletar o registro?</span></div>
            <div className='div-info-alert'><span className="info-alert">Você não poderá reverter.</span></div>

            <div className='btn-alert-div'>
                <span className='btn-alert-esc' onClick={() => deletarProduto(obj.age_id)}>
                    <span className="btn-alert-lab">SIM</span>
                </span>
                <span className='btn-alert-dir' onClick={finalizarModal2}>
                    <span className="btn-alert-lab">NÃO</span>
                </span>
            </div>
        </div>)

        setOpenModal(true)
    }

    function finalizarModal2() {
        setOpenModal(false)
    }

    async function deletarProduto(id: any) {
        let ret: AxiosResponse;


        try {
            ret = await api.delete("user/delete", {
                data: { id: id }
            });
            if (ret.data.status === 200) {
                Swal.fire({
                    icon: 'success',
                    text: 'O produto foi excluída com sucesso!',
                    confirmButtonColor: "#222d32",
                })

                window.location.reload();
            }
        } catch (e: any) {
          
        }
    }


    function funtoggle(form: any) {


    }

    function fecharOpenModalIncluirNovo() {
        setOpenModalIncluirNovo(false)
    }

    return (
        <>
            <Nav />
            <div className="main-content">
                <Header />
                <main>
                    <div className='containerPrincipal'>
                        <div className='ContainerBody'>
                            <div className='div-titulo-pages'>
                                <span className='fa-sharp fa-solid fa-table-list' />
                                <h1 className='div-titulo-pages-text'>Tarefas</h1>
                            </div>



                            <div className="App">
                                <div className='menu-container' ref={menuRef}>
                                    <div className='menu-trigger' onClick={() => { setOpen(!open) }}>
                                        <div className='dropOpcao'><h1>Opções</h1> <span className='fa-sharp fa-solid fa-chevron-down ControleIco'></span> </div>
                                    </div>


                                    {secureLocalStorage.getItem('acp') === 'DESENVOLVEDOR' || secureLocalStorage.getItem('acp') === 'ADMINISTRADOR' || secureLocalStorage.getItem('acp') === 'DIRECIONADOR' ? (

                                        <div className={`dropdown-menu ${open ? 'active' : 'inactive'}`} >
                                            <ul>
                                                <Link to="/taskdirection" className='dropdownItem'>
                                                    <span className='fa fa-cog ControleIco'></span>
                                                    <h1 className='dropdownItem-text'>Direcionamento Rápido</h1>
                                                </Link>
                                            </ul>
                                        </div>
                                    ) : null}

                                </div>
                            </div>

                        </div>
                        <div className='grid-container-principal'>
                            {exibirGrid}
                        </div>
                    </div>
                </main>
            </div>

            <ModalPrincipal isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)}>
                <div className='container-modal-alert'>
                    {qualAlert}
                </div>
            </ModalPrincipal>

            <ModalIncluirNovo isOpen={openModalIncluirNovo} setModalOpen={() => setOpenModalIncluirNovo(!openModalIncluirNovo)}>
                <>
                    <div className='title-modal-incluir'>
                        <h1 className="modal-IncluirNovo-title">{qualTitulo}</h1>
                        <div onClick={fecharOpenModalIncluirNovo}>
                            <button className="fa fa-times icone-modal-IncluirNovo" data-dismiss="modal" aria-label="Close"></button>
                        </div>
                    </div>

                    <div className='scrol-modal'>
                        {qualGrid}
                    </div>
                </>
            </ModalIncluirNovo>

        </>
    );

}

export default Task;