import React, { useState, useEffect } from 'react'
import Nav from "../../components/Nav";
import Header from "../../components/Header";
import './index.css'
import Select from 'react-select'
import api from '../../Services/api'
import { AxiosResponse } from "axios";
import { MdOutlineAddCircle } from 'react-icons/md';
import { useNavigate } from 'react-router';
import ModalAlert from '../../components/ModalAlert/modal'
import Loading from '../../components/Loading'
import secureLocalStorage from "react-secure-storage";
import ModalIncluirNovo from '../../components/ModalIncluirNovo/modal'
import GridSecundario from '../../components/GridSecundario'
import FormItemsubgroup from '../../components/FormItemsubgroup';

function ItensAdd() {
	const navigate = useNavigate();

	const [qualTitulo, setQualTitulo] = useState('')
	const [qualGrid, setQualGrid] = useState(<></>)
	const [openModal, setOpenModal] = useState(false)
	const [qualAlert, setQualAlert] = useState(<></>)
	const [titulo, setTitulo] = useState('');


	const [ite_id, setIte_id] = useState('');
	const [ite_active, setIte_active] = useState(true)
	const [ite_description, setIte_description] = useState('');
	const [ite_alternativeidentifier, setIte_alternativeidentifier] = useState('');
	const [its_id, setIts_id] = useState('');
	const [ite_tag, setIte_tag] = useState('');
	const [ite_observation, setIte_observation] = useState('');
	const [age_id_insert, setAge_id_insert] = useState('');
	const [age_id_lastupdate, setAge_id_lastupdate] = useState('');


	const [ite_activeDisabled, setIte_activeDisabled] = useState(false)
	const [ite_descriptionDisabled, setIte_descriptionDisabled] = useState(false)
	const [ite_alternativeidentifierDisabled, setIte_alternativeidentifierDisabled] = useState(false)
	const [its_idDisabled, setIts_idDisabled] = useState(false)
	const [ite_tagDisabled, setIte_tagDisabled] = useState(false)
	const [ite_observationDisabled, setIte_observationDisabled] = useState(false)
	const [age_id_insertDisabled, setAge_id_insertDisabled] = useState(false)
	const [age_id_lastupdateDisabled, setAge_id_lastupdateDisabled] = useState(false)

	const [datetimeinsert, setDatetimeinsert] = useState('')
	const [datetimeupdate, setDatetimeupdate] = useState('')


	const [openModalIncluirNovo, setOpenModalIncluirNovo] = useState(false)
	const [confirmarUserDisabled, setConfirmarUserDisabled] = useState(false)
	const [cancelarDisabled, setCancelarDisabled] = useState(false)

	const [optionsItens, setOptionsItens] = useState([])
	const [optionsItemsubgroup, setOptionsItemsubgroup] = useState([])




	useEffect(() => {

		setQualAlert(<div className='div-alert'><Loading /></div>)
		setOpenModal(true)


		let today = new Date();
		let date =
			today.getFullYear() +
			"-" +
			(today.getMonth() + 1).toString().padStart(2, "0") +
			"-" +
			today.getDate().toString().padStart(2, "0");
		let time =
			today.getHours().toString().padStart(2, "0") +
			":" +
			today.getMinutes().toString().padStart(2, "0");
		let JuntarDate = date + "T" + time;
		setDatetimeinsert(JuntarDate)

		validatToken();

	}, []);




	function fecharOpenModalIncluirNovo() {
		setOpenModalIncluirNovo(false)
	}

	async function validatToken() {

		let ret: AxiosResponse;
		let valor: any;

		const _user = secureLocalStorage.getItem('t')

		api.defaults.headers.common['Authorization'] = _user as ''

		try {

			ret = await api.get("/status/validar")

			if (ret.data.status === 200) {

				secureLocalStorage.setItem('t', ret.data.data);
				secureLocalStorage.setItem('acp', ret.data.decodedToken.acp_integrationid);
				secureLocalStorage.setItem('n', ret.data.decodedToken.name);
				secureLocalStorage.setItem('iag', ret.data.decodedToken.id);
				secureLocalStorage.setItem('cy', ret.data.decodedToken.company);

				GetItens();
				GetSubgrupo();

				if (ret.data.decodedToken.acp_integrationid === 'DESENVOLVEDOR' || ret.data.decodedToken.acp_integrationid === 'ADMINISTRADOR') {


				} else if (ret.data.decodedToken.acp_integrationid === 'CONSULTOR') {


					setIte_activeDisabled(true)
					setIte_descriptionDisabled(true)
					setIte_alternativeidentifierDisabled(true)
					setIts_idDisabled(true)
					setIte_tagDisabled(true)
					setIte_observationDisabled(true)
					setAge_id_insertDisabled(true)
					setAge_id_lastupdateDisabled(true)

				}

				setOpenModal(false)

			}

		} catch (e: any) {

			if (e.response === undefined) {
				setQualAlert(<div className='div-alert'>
					<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
					<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
					<div className='div-info-alert'><span className="info-alert">Sem comunicação com o servidor.</span></div>
					<div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
				</div>)

				setOpenModal(true)
			} else
				if (e.response.status === 403) {

					setQualAlert(<div className='div-alert'>
						<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
						<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
						<div className='div-info-alert'><span className="info-alert">Token inválido ou expirado.</span></div>
						<div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
					</div>)

					setOpenModal(true)

				} else {
					setQualAlert(<div className='div-alert'>
						<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
						<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
						<div className='div-info-alert'><span className="info-alert">Não foi possível concluir sua solicitação.</span></div>
						<div className='btn-alert' onClick={fecharModal2}><span className="btn-alert-lab">OK</span></div>
					</div>)

					setOpenModal(true)
				}
		}

	}

	async function GetSubgrupo() {
		let ret: AxiosResponse;
		let valor: any;
		try {
			ret = await api.get("itemsubgroup/listar")
			if (ret.data.status === 200) {
				valor = ret.data.data;

				const arr = [] as any;
				let result = valor;
				result.map((user: any) => {
					return arr.push({ value: String(user.its_id), label: user.its_description });
				});

				setOptionsItemsubgroup(arr)

			}
		} catch (e) {
			setOptionsItemsubgroup([])
		}
	}


	
	async function GetItens() {
		let ret: AxiosResponse;
		let valor: any;
		try {
			ret = await api.get("item/verificacaoitem")
			if (ret.data.status === 200) {
				valor = ret.data.data;

				setOptionsItens(valor)

			}
		} catch (e) {
			setOptionsItens([])
		}
	}


	function fecharModalToken() {

		secureLocalStorage.removeItem('t')
		secureLocalStorage.removeItem('acp')
		secureLocalStorage.removeItem('n')
		secureLocalStorage.removeItem('iag')
		navigate('/login');
		setOpenModal(false)

	}



	const customStyles = {
		control: (provided: any, state: any) => ({
			...provided,
			background: '#fff',
			minHeight: '30px',
			height: '30px',
			borderColor: state.isFocused ? 'grey' : '#92989e',

		}),


		valueContainer: (provided: any, state: any) => ({
			...provided,
			height: '30px',
			padding: '0 6px'
		}),

		input: (provided: any, state: any) => ({
			...provided,
			margin: '0px',
			fontSize: 14,

		}),
		indicatorsContainer: (provided: any, state: any) => ({
			...provided,
			height: '30px',
		}),
	};

	function ValitarActive() {
		let e = document.getElementById("checkbox-active") as HTMLInputElement;

		if (e.checked) {

			setIte_active(true)
		} else {

			setIte_active(false)
		}
	}


	function cancelar() {
		navigate('/item');
	}



	function fecharModal() {
		setOpenModal(false)
	}




	const maskOnlyNumbers = (value: any) => {
		return value.replace(/\D/g, "");
	};



	function finalizarModal2() {
		setOpenModal(false)
	}

	//=================================ADD UNIDADE=============================================  

	async function confirmarLocal() {

		let ret: AxiosResponse;
		let valor: any;

		setQualAlert(<div className='div-alert'><Loading /></div>)
		setOpenModal(true)


		const _user = secureLocalStorage.getItem('t')

		api.defaults.headers.common['Authorization'] = _user as ''

		try {

			ret = await api.get("/status/validar")

			if (ret.data.status === 200) {

	

				const FiltrarNome = optionsItens.filter(
					(e: any) => {
						return e.ite_description.toLowerCase() === ite_description.toLowerCase()
					}
				);

				const FiltrarCnpj = optionsItens.filter(
					(e: any) => {
						return e.ite_alternativeidentifier.toLowerCase() === ite_alternativeidentifier.toLowerCase()
					}
				);

				if (ite_description === "") {

					setQualAlert(<div className='div-alert'>
						<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
						<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
						<div className='div-info-alert'><span className="info-alert">Digite a descrição.</span></div>
						<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
					</div>)

				} else if (FiltrarNome.length > 0) {

					setQualAlert(<div className='div-alert'>
						<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
						<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
						<div className='div-info-alert'><span className="info-alert">Descrição já está em uso.</span></div>
						<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
					</div>)
				}
				else if (FiltrarCnpj.length > 0) {

					setQualAlert(<div className='div-alert'>
						<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
						<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
						<div className='div-info-alert'><span className="info-alert">Identificador alternativo já está em uso.</span></div>
						<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
					</div>)
				}
				else if (ite_alternativeidentifier === "") {

					setQualAlert(<div className='div-alert'>
						<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
						<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
						<div className='div-info-alert'><span className="info-alert">Digite o identificador alternativo.</span></div>
						<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
					</div>)
				} else if (its_id === "") {

					setQualAlert(<div className='div-alert'>
						<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
						<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
						<div className='div-info-alert'><span className="info-alert">Selecione o subgrupo do item.</span></div>
						<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
					</div>)
				}
				else {
					let ret: AxiosResponse;

					const dados = [{
						ite_active,
						ite_description,
						ite_alternativeidentifier,
						its_id: its_id || null,
						ite_tag: ite_tag || null,
						ite_observation: ite_observation || null,
						age_id_insert: secureLocalStorage.getItem('iag'),
					}];

					try {
						ret = await api.post("item/insert", {
							data: dados,
						});
						if (ret.data.status === 200) {

							setQualAlert(<div className='div-alert'>
								<span className="fa-regular fa-circle-check ControleIconAlert"></span>
								<div className='div-info-alert'><span className="info-alert">Cadastro realizado com sucesso!</span></div>
								<div className='btn-alert' onClick={finalizarModal1}><span className="btn-alert-lab">OK</span></div>
							</div>)
						}
					} catch (e: any) {

						if (e.response === undefined) {
							setQualAlert(<div className='div-alert'>
								<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
								<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
								<div className='div-info-alert'><span className="info-alert">Sem comunicação com o servidor.</span></div>
								<div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
							</div>)

							setOpenModal(true)
						} else
							if (e.response.status === 403) {

								setQualAlert(<div className='div-alert'>
									<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
									<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
									<div className='div-info-alert'><span className="info-alert">Token inválido ou expirado.</span></div>
									<div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
								</div>)

								setOpenModal(true)

							} else {
								setQualAlert(<div className='div-alert'>
									<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
									<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
									<div className='div-info-alert'><span className="info-alert">Não foi possível concluir sua solicitação.</span></div>
									<div className='btn-alert' onClick={fecharModal2}><span className="btn-alert-lab">OK</span></div>
								</div>)

								setOpenModal(true)
							}

					}
				}

			}

		} catch (e: any) {
			if (e.response === undefined) {
				setQualAlert(<div className='div-alert'>
					<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
					<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
					<div className='div-info-alert'><span className="info-alert">Sem comunicação com o servidor.</span></div>
					<div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
				</div>)

				setOpenModal(true)
			} else
				if (e.response.status === 403) {

					setQualAlert(<div className='div-alert'>
						<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
						<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
						<div className='div-info-alert'><span className="info-alert">Token inválido ou expirado.</span></div>
						<div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
					</div>)

					setOpenModal(true)

				} else {
					setQualAlert(<div className='div-alert'>
						<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
						<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
						<div className='div-info-alert'><span className="info-alert">Não foi possível concluir sua solicitação.</span></div>
						<div className='btn-alert' onClick={fecharModal2}><span className="btn-alert-lab">OK</span></div>
					</div>)

					setOpenModal(true)
				}
		}


	}


	function fecharModal2() {
		setOpenModal(false)
	}

	function finalizarModal1() {
		navigate('/item');
	}

	//--------------------Subgrupo do Item---------------------------------

	function incluirSubgrupo() {

            setTitulo('Cadastrar Subgrupo do Item')
            setQualGrid((<><GridSecundario
                Iconeadd={ChamarCadastroSubgrupo}
                Iconeditar={EditarSubgrupo}
                iconeExcluir={SubgrupoDeletar}
                PegarValorGrid={PegarValorGridSubgrupo}
				th1='Ativo'
				th2='ID'
				th3='Descrição'
				th4='Identificador Alternativo'
				th5=''
				th6=''
                endPoint='itemsubgroup/buscaritemsubgroup'
                consult='N'
                col1='1'
                col2='2'
                col3='3'
				ambiente={'%'}
				pag="ok"
            /></>))

            setOpenModalIncluirNovo(true)
    }

	function ChamarCadastroSubgrupo(){
		sessionStorage.removeItem("dadosTipoEquipe");
		setQualGrid(<><FormItemsubgroup voltar={BtnVoltarSubgrupo}/></>)
	}

	async function EditarSubgrupo(obj: any) {
		sessionStorage.setItem("dadosTipoEquipe", JSON.stringify(obj));
		setQualGrid(<><FormItemsubgroup voltar={BtnVoltarSubgrupo}/></>)
	}

	function BtnVoltarSubgrupo() {

	    setQualGrid((<><GridSecundario
			Iconeadd={ChamarCadastroSubgrupo}
			Iconeditar={EditarSubgrupo}
			iconeExcluir={SubgrupoDeletar}
			PegarValorGrid={PegarValorGridSubgrupo}
			th1='Ativo'
			th2='ID'
			th3='Descrição'
			th4='Identificador Alternativo'
			th5=''
			th6=''
			endPoint='itemsubgroup/buscaritemsubgroup'
			consult='N'
			col1='1'
			col2='2'
			col3='3'
			ambiente={'%'}
			pag="ok"
		/></>))

		GetSubgrupo();

	}

	function SubgrupoDeletar(){

	}

	function PegarValorGridSubgrupo(valor: any){
		setIts_id(String(valor.its_id))
        setOpenModalIncluirNovo(false)

	}

	return (
		<>
			<Nav />
			<div className="main-content">
				<Header />
				<main>
					<div className='containerPrincipal'>



						<div className="title-Page">
							<div className="title-text">
								<div className="size-x circle">1</div>
								<h2>Informações Gerais</h2>
							</div>
							<hr />
						</div>

						<div className="class-checkbox">
							<input type="checkbox" id="checkbox-active"
								checked={ite_active} onChange={ValitarActive} disabled={ite_activeDisabled} />
							<h4 className="lab-ativo">Ativo</h4>
						</div>

						<div className="container-cad-prod37">
							<div id="content1">
								<div className="input-box-ativos">
									<span className="details">Descrição *</span>
									<input
										type="text"
										onChange={(e) => setIte_description(e.target.value)}
										value={ite_description}
										autoComplete="nope"
										disabled={ite_descriptionDisabled}
									/>

								</div>
							</div>

							<div id="content2">
								<div className="input-box-ativos">
									<span className="details">Identificador alternativo *</span>
									<input
										type="text"
										onChange={(e) => setIte_alternativeidentifier(e.target.value)}
										value={ite_alternativeidentifier}
										autoComplete="nope"
										disabled={ite_alternativeidentifierDisabled}
									/>
								</div>
							</div>

							<div id="content3">
								<div className="select-box-ativos-select">
									<div className='details-flex'>
										<span className="details">Subgrupo * </span>
										<MdOutlineAddCircle className='details-flex-icon-ativoincluirSubgrupo' />
										<span className="details-esc-ativo" onClick={incluirSubgrupo}>Incluir Novo</span>
									</div>

									<Select
										maxMenuHeight={140}
										placeholder={'Selecione...'}
										options={optionsItemsubgroup}
										styles={customStyles}
										isDisabled={its_idDisabled}
										value={optionsItemsubgroup.filter(function (option: any) {
											return option.value === its_id;
										})}
										onChange={(options: any) =>
											!options ? setIts_id("") : setIts_id(options.value)
										}
										isClearable
										noOptionsMessage={() => "Não encontrado..."}
										theme={(theme: any) => ({
											...theme,
											borderRadius: 4,
											colors: {
												...theme.colors,
												primary25: '#ebebeb',
												primary: 'black',
											},
										})}
									/>
								</div>
							</div>

						</div>




						<div className="container-cad-prod43">

							<div id="content1">
								<div className="input-box-ativos">
									<span className="details">Data da inclusão</span>
									<input
										type="datetime-local"
										id="DATA-TIME"
										name="DATE-TIME"
										disabled
										value={datetimeinsert}
									/>
								</div>
							</div>
							<div id="content2">
								<div className="input-box-ativos">
									<span className="details">Data da última atualização</span>
									<input
										type="datetime-local"
										id="DATA-TIME"
										name="DATE-TIME"
										disabled
										value={datetimeupdate}
									/>
								</div>
							</div>

						</div>


						<div className="container-cad-prod3">
							<div className="textareaBox-ativo">
								<span className="details">Tags</span>
								<textarea
									onChange={(e) => setIte_tag(e.target.value)}
									value={ite_tag}
									disabled={ite_tagDisabled}
								/>
							</div>
						</div>

						<div className="container-cad-prod3">
							<div className="textareaBox-ativo">
								<span className="details">Observação</span>
								<textarea
									onChange={(e) => setIte_observation(e.target.value)}
									value={ite_observation}
									disabled={ite_observationDisabled}
								/>
							</div>
						</div>




						<div className="Bt-button-cad">
							<button type="submit" disabled={confirmarUserDisabled} onClick={confirmarLocal}>
								<i className="fa fa-check-circle icon-ok-btn"></i> Salvar
							</button>
							<button type="submit" onClick={cancelar} disabled={cancelarDisabled}>
								<i className="fa fa-times icon-cancelar-btn"></i> Cancelar
							</button>
						</div>
					</div>




				</main>
			</div>


			<ModalIncluirNovo isOpen={openModalIncluirNovo} setModalOpen={() => setOpenModalIncluirNovo(!openModalIncluirNovo)}>
				<div className='title-modal-incluir'>
					<h1 className="modal-IncluirNovo-title">{titulo}</h1>
					<div onClick={fecharOpenModalIncluirNovo}>
						<button className="fa fa-times icone-modal-IncluirNovo" data-dismiss="modal" aria-label="Close"></button>
					</div>
				</div>
				<div className='scrol-modal'>

					{qualGrid}

				</div>
			</ModalIncluirNovo>


			<ModalAlert isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)}>
				<div className='container-modal-alert'>
					{qualAlert}
				</div>
			</ModalAlert>



		</>
	);

}

export default ItensAdd;