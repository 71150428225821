import React, { useState, useEffect } from 'react'
import Nav from "../../components/Nav";
import Header from "../../components/Header";
import './index.css'
import Select from 'react-select'
import api from '../../Services/api'
import { AxiosResponse } from "axios";
import Loading from '../../components/Loading';
import { useNavigate } from 'react-router';
import ModalIncluirNovo from '../../components/ModalIncluirNovo/modal'
import ModalAlert from '../../components/ModalAlert/modal'
import secureLocalStorage from "react-secure-storage";

function UsuariosAdd() {
	const navigate = useNavigate();

	const [qualTitulo, setQualTitulo] = useState(<></>)
	const [qualGrid, setQualGrid] = useState(<></>)
	const [active, setActive] = useState(true)
	const [description, setDescription] = useState('')
	const [initialdata, setInitialdata] = useState('')
	const [initialtime, setInitialtime] = useState('')
	const [finaldata, setFinaldata] = useState('')
	const [finaltime, setFinaltime] = useState('')
	
	const [contract, setContract] = useState('');
	const [unity, setUnity] = useState('');
    const [openModal, setOpenModal] = useState(false)
    const [qualAlert, setQualAlert] = useState(<></>)

	const [optionsUsuario, setOptionsUsuario] = useState([])
	const [optionContract, setOptionContract] = useState([])
	const [optionUnity, setOptionUnity] = useState([])
	const [optionsMobileinventory ,setOptionsMobileinventory] = useState([])

	const [openModalIncluirNovo, setOpenModalIncluirNovo] = useState(false)

	const [activeDisabled, setActiveDisabled] = useState(false)
	const [descriptionDisabled, setD4escriptionDisabled] = useState(false)
	const [initialdataDisabled, setInitialdataDisabled] = useState(false)
	const [initialtimeDisabled, setInitialtimeDisabled] = useState(false)
	const [finaldataDisabled, setFinaldataDisabled] = useState(false)
	const [finaltimeDisabled, setFinaltimeDisabled] = useState(false)
	const [usuarioDisabled, setUsuarioDisabled] = useState(false)
	const [contractDisabled, setContractDisabled] = useState(false)
	const [unityDisabled, setUnityDisabled] = useState(false)
	const [optionsEquipe, setOptionsEquipe] = useState([])
    const [optionsForma, setOptionsForma] = useState([])
	const [tea_id, setTea_id] = useState('');
    const [tsf_id, setTsf_id] = useState('');
	const [isDisabledForma, setIsDisabledForma] = useState(true)
	const [age_id, setAge_id] = useState('');

	useEffect(() => {

		setQualAlert(<div className='div-alert'><Loading /></div>)
		setOpenModal(true)

		validatToken();
		
		
	}, []);

	async function validatToken() {

		let ret: AxiosResponse;
		let valor: any;

		const _user = secureLocalStorage.getItem('t')

		api.defaults.headers.common['Authorization'] = _user as ''

		try {

			ret = await api.get("/status/validar")

			if (ret.data.status === 200) {

				secureLocalStorage.setItem('t', ret.data.data);
				secureLocalStorage.setItem('acp', ret.data.decodedToken.acp_integrationid);
				secureLocalStorage.setItem('n', ret.data.decodedToken.name);
				secureLocalStorage.setItem('iag', ret.data.decodedToken.id);
				secureLocalStorage.setItem('cy', ret.data.decodedToken.company);


				await GetUsuario()
				await GetContrato()
				await GetForma()
				await GetEquipe()
				await GetMobileinventory() 
				setOpenModal(false)

			}

		} catch (e: any) {

			if (e.response.data.status === 403) {

				setQualAlert(<div className='div-alert'>
					<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
					<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
					<div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
					<div className='btn-alert' onClick={fecharModalToken}><span className="btn-alert-lab">OK</span></div>
				</div>)

				setOpenModal(true)
			}
		}

	}

	async function GetMobileinventory() {
		let ret: AxiosResponse;
		let valor: any;
		try {
			ret = await api.get("mobileinventory/exibicaomobileinventory")
			if (ret.data.status === 200) {
				valor = ret.data.data;

				setOptionsMobileinventory(valor)

			}
		} catch (e) {

			setOptionsMobileinventory([])
		}
	}

	
    function fecharModalToken() {
		secureLocalStorage.removeItem('t')
		secureLocalStorage.removeItem('acp')
		secureLocalStorage.removeItem('n')
		secureLocalStorage.removeItem('iag')
		navigate('/login');
		setOpenModal(false)
	}

	function ValitarActive() {
		let e = document.getElementById("checkbox-active") as HTMLInputElement;

		if (e.checked) {

			setActive(true)
		} else {

			setActive(false)
		}
	}

	const customStyles = {
		control: (provided: any, state: any) => ({
			...provided,
			background: '#fff',
			minHeight: '30px',
			height: '30px',
			borderColor: state.isFocused ? 'grey' : '#92989e',

		}),


		valueContainer: (provided: any, state: any) => ({
			...provided,
			height: '30px',
			padding: '0 6px'
		}),

		input: (provided: any, state: any) => ({
			...provided,
			margin: '0px',
			fontSize: 14,

		}),
		indicatorsContainer: (provided: any, state: any) => ({
			...provided,
			height: '30px',
		}),
	};


	//=================================ADD UNIDADE=============================================  
	async function GetUsuario() {
		let ret: AxiosResponse;
		let valor: any;

		try {
			ret = await api.get("user/listar")
			if (ret.data.status === 200) {
				valor = ret.data.data;

				const arr = [] as any;
				let result = valor;
				result.map((user: any) => {
					return arr.push({ value: String(user.age_id), label: user.age_name });
				});
				setOptionsUsuario(arr)
			}
		} catch (e) {
			setOptionsUsuario([])
		}
	}

	async function GetContrato() {
		let ret: AxiosResponse;
		let valor: any;
		try {
			ret = await api.get("contract/listar")
			if (ret.data.status === 200) {
				valor = ret.data.data;

				const arr = [] as any;
				let result = valor;
				result.map((user: any) => {
					return arr.push({ value: String(user.ctc_id), label: user.ctc_description });
				});
				setOptionContract(arr)

			}
		} catch (e) {
			setOptionContract([])
		}
	}

	function fecharModal() {
		setOpenModal(false)
	}

	async function GetUnidadeSel(id: any) {
		let ret: AxiosResponse;
		let valor: any;


		if (id === '' || id === 'Selecione...' || id === 'undefined' || id === undefined) {
			setUnity('')
			setOptionUnity([])

			setQualAlert(<div className='div-alert'>
			<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
			<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
			<div className='div-info-alert'><span className="info-alert">Selecione o contrato.</span></div>
			<div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
		</div>)

		setOpenModal(true)

		} else {
			try {
				ret = await api.get("unity/listar", {
					params: {
						id: id
					}
				})
				if (ret.data.status === 200) {
					valor = ret.data.data;


					const arr = [] as any;
					let result = valor;
					result.map((user: any) => {
						return arr.push({ value: String(user.unt_id), label: user.unt_description });
					});
					setOptionUnity(arr)

				}
			} catch (e) {
				setOptionUnity([]);
			}
		}

	}


	function IncluirContrato(Contrato: any) {

		setUnity('')
		if (Contrato === '' || Contrato === 'Selecione...') {
			setContract('')
			setOptionUnity([])
		} else {

			GetUnidadeSel(Contrato)
			setContract(Contrato)
		}
	}

	async function salvarInventarioMovile() {
    
		setQualAlert(<div className='div-alert'><Loading /></div>)
		setOpenModal(true)

		let ret: AxiosResponse;
		let ret2: AxiosResponse;
		let valor: any;


		const FiltrarDescricao = optionsMobileinventory.filter(
			(e: any) => {
				return e.ivb_description.toLowerCase() === description.toLowerCase()
			}
		);

		if (description === '') {

			setQualAlert(<div className='div-alert'>
			<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
			<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
			<div className='div-info-alert'><span className="info-alert">Digite a descrição.</span></div>
			<div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
		</div>)

		setOpenModal(true)


		} else if (FiltrarDescricao.length > 0) {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Descrição já está em uso.</span></div>
                <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
            </div>)

			setOpenModal(true)

        } else if (initialdata === '') {

			
			setQualAlert(<div className='div-alert'>
			<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
			<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
			<div className='div-info-alert'><span className="info-alert">Selecione a data inicial.</span></div>
			<div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
		</div>)

		setOpenModal(true)

		}else if (initialtime === '') {

			setQualAlert(<div className='div-alert'>
			<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
			<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
			<div className='div-info-alert'><span className="info-alert">Selecione a hora inicial.</span></div>
			<div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
		</div>)

		setOpenModal(true)


		} else if (finaldata === '') {

			setQualAlert(<div className='div-alert'>
			<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
			<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
			<div className='div-info-alert'><span className="info-alert">Selecione a data final.</span></div>
			<div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
		</div>)

		setOpenModal(true)

		} else if (finaltime === '') {

			setQualAlert(<div className='div-alert'>
			<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
			<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
			<div className='div-info-alert'><span className="info-alert">Selecione a hora final.</span></div>
			<div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
		</div>)

		setOpenModal(true)

		} else if (finaldata < initialdata) {

			setQualAlert(<div className='div-alert'>
			<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
			<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
			<div className='div-info-alert'><span className="info-alert">A data final não pode ser menor que a data inicial.</span></div>
			<div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
		</div>)

		setOpenModal(true)

		} else if (contract === '') {

			setQualAlert(<div className='div-alert'>
			<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
			<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
			<div className='div-info-alert'><span className="info-alert">Selecione o contrato.</span></div>
			<div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
		</div>)

		setOpenModal(true)

		} else if (unity === '') {

			setQualAlert(<div className='div-alert'>
			<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
			<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
			<div className='div-info-alert'><span className="info-alert">Selecione a unidade.</span></div>
			<div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
		</div>)

		setOpenModal(true)

		}else if (age_id === "" && tea_id === "") {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Selecione quem vai executar a tarefa (Usuário ou Equipe).</span></div>
                <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
            </div>)

            setOpenModal(true)


        } else if (tea_id !== "" && tsf_id === "") {


            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Selecione a forma de execução da equipe.</span></div>
                <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
            </div>)

            setOpenModal(true)

        }
		else {

			try {
				ret = await api.get("mobileinventory/listarativosunidade", {
					params: {
						dados: unity
					}
				})

				if (ret.data.status === 200) {
					valor = ret.data.data;

					if (valor.length > 0) {
					
						const dados = [{
							ivb_active: active,
							ivb_description: description,
							ivb_initialdata: initialdata,
							ivb_initialtime: initialtime,
							ivb_finaldata: finaldata,
							ivb_finaltime: finaltime,
							age_id: age_id || null,
							ctc_id: contract,
							unt_id: unity,
							age_id_insert: secureLocalStorage.getItem('iag'),
							ivb_generated: 'N',
							stn_id: 30, 
							tea_id: tea_id || null, 
							tsf_id: tsf_id || null
						}]

						try {
							ret2 = await api.post("mobileinventory/insert", {
								data: dados,
							});
							if (ret2.data.status === 200) {

								setQualAlert(<div className='div-alert'>
								<span className="fa-regular fa-circle-check ControleIconAlert"></span>
								<div className='div-info-alert'><span className="info-alert">Cadastro realizado com sucesso!</span></div>
								<div className='btn-alert' onClick={finalizarModal1}><span className="btn-alert-lab">OK</span></div>
							</div>)
								
								setOpenModal(true)
							}
						}
						catch (e: any) {
							
						}

					} else {

						setQualAlert(<div className='div-alert'>
						<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
						<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
						<div className='div-info-alert'><span className="info-alert">Não é possível realizar o inventário na unidade selecionada, pois não possui ativo em uso.</span></div>
						<div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
					</div>)
			
					setOpenModal(true)

					}

				} else {


					setQualAlert(<div className='div-alert'>
					<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
					<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
					<div className='div-info-alert'><span className="info-alert">Não é possível realizar o inventário na unidade selecionada, pois não possui ativo em uso.</span></div>
					<div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
				</div>)
		
				setOpenModal(true)


				}
			} catch (e) {

				setQualAlert(<div className='div-alert'>
				<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
				<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
				<div className='div-info-alert'><span className="info-alert">Não é possível realizar o inventário na unidade selecionada, pois não possui ativo em uso.</span></div>
				<div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
			</div>)
	
			setOpenModal(true)

			}
		}
	}
	
	function finalizarModal1() {
		navigate('/mobileinventory');
	}


	function cancelar() {
		navigate('/mobileinventory');
	}

	function validarDataFim(valor: any) {

		if (initialdata === '') {

			setQualAlert(<div className='div-alert'>
			<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
			<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
			<div className='div-info-alert'><span className="info-alert">Selecione a data inicial.</span></div>
			<div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
		</div>)

		setOpenModal(true)

		} if (initialdata > valor) {

			setQualAlert(<div className='div-alert'>
			<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
			<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
			<div className='div-info-alert'><span className="info-alert">Data final não pode se menor que data inicial.</span></div>
			<div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
		</div>)

		setOpenModal(true)


		} else {
			setFinaldata(valor)
		}
	}

	function validarDataIni(valor: any) {

	
			setInitialdata(valor)
	
			setFinaldata('')
	
	}

	function incluirFinaltime(valor: any) {

		if (initialdata === finaldata) {
			if (valor < initialtime) {


				setQualAlert(<div className='div-alert'>
				<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
				<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
				<div className='div-info-alert'><span className="info-alert">Hora final não pode ser menor que hora inicial.</span></div>
				<div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
			</div>)
	
			setOpenModal(true)


			} else if(valor === initialtime){

				setQualAlert(<div className='div-alert'>
				<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
				<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
				<div className='div-info-alert'><span className="info-alert">Hora final não pode ser igual a hora inicial.</span></div>
				<div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
			</div>)
	
			setOpenModal(true)

			}
			else{
				setFinaltime(valor)
			}
		} else {
			setFinaltime(valor)
		}
	}

	function incluirInitialtime(valor: any) {

		setInitialtime(valor)
		setFinaltime("")
	}


	function fecharOpenModalIncluirNovo() {
		setOpenModalIncluirNovo(false)
	}


	async function GetEquipe() {
        let ret: AxiosResponse;
        let valor: any;
        try {
            ret = await api.get("team/listar")
            if (ret.data.status === 200) {
                valor = ret.data.data;
                const arr = [] as any;
                let result = valor;
                result.map((user: any) => {
                    return arr.push({ value: String(user.tae_id), label: user.tae_description });
                });

                setOptionsEquipe(arr)

            }
        } catch (e) {
            setOptionsEquipe([])
        }
    }

    function SelecionarEquipe(id: any) {

        if (id === '') {
            setTea_id("")
            setAge_id("")
            setTsf_id("")
            setIsDisabledForma(true)
        } else {
            setAge_id("")
            setTea_id(id)
            setIsDisabledForma(false)
        }
    }

    async function GetForma() {
        let ret: AxiosResponse;
        let valor: any;
        try {
            ret = await api.get("taskform/listar")
            if (ret.data.status === 200) {
                valor = ret.data.data;
                const arr = [] as any;
                let result = valor;

                const forma = result.filter(
                    (e: any) => {
                        return e.tsf_id === 1
                    }
                );

                forma.map((user: any) => {
                    return arr.push({ value: String(user.tsf_id), label: user.tsf_description });
                });

                setOptionsForma(arr)

            }
        } catch (e) {
            setOptionsForma([])
        }
    }


	function SelecionarAgent(id: any) {



        if (id === '') {
            setAge_id("")
            setTea_id("")
            setTsf_id("")
            setIsDisabledForma(true)
        } else {
            setTea_id("")
            setTsf_id("")
            setAge_id(id)
            setIsDisabledForma(true)
        }
    }


	return (
		<>
			<Nav />
			<div className="main-content">
				<Header />
				<main>
					<div className='containerPrincipal'>
						<div className="title-Page">
							<div className="title-text">
								<div className="size-x circle">1</div>
								<h2>Informações Gerais</h2>
							</div>
							<hr />
						</div>

						<div className="class-checkbox">
							<input type="checkbox" id="checkbox-active"  disabled={activeDisabled} checked={active} onChange={ValitarActive} />
							<h4 className="lab-ativo">Ativo</h4>
						</div>

						<div className="container-cad-prod15">
							<div id="content1">
								<div className="input-box-ativos">
									<span className="details">Descrição *</span>
									<input
									autoComplete="off"
										type="text"
										onChange={(e) => setDescription(e.target.value)}
										value={description}
										disabled={descriptionDisabled}
									/>
								</div>
							</div>
							<div id="content2">
								<div className="input-box-ativos">
									<span className="details">Data Inicial *</span>
									<input
									autoComplete="off"
										type="date"
										onChange={(e) => validarDataIni(e.target.value)}
										value={initialdata}
										disabled={initialdataDisabled}
									/>
								</div>
							</div>
							<div id="content3">
								<div className="input-box-ativos">
									<span className="details">Hora Inicial *</span>
									<input
									autoComplete="off"
										type="time"
										onChange={(e) => incluirInitialtime(e.target.value)}
										value={initialtime}
										disabled={initialtimeDisabled}
									/>
								</div>
							</div>
							<div id="content4">
								<div className="input-box-ativos">
									<span className="details">Data Final *</span>
									<input
									autoComplete="off"
										type="date"
										onChange={(e) => validarDataFim(e.target.value)}
										value={finaldata}
										disabled={finaldataDisabled}
									/>
								</div>
							</div>
							<div id="content5">
								<div className="input-box-ativos">
									<span className="details">Hora Final *</span>
									<input
									autoComplete="off"
										type="time"
										onChange={(e) => incluirFinaltime(e.target.value)}
										value={finaltime}
										disabled={finaltimeDisabled}
									/>
								</div>
							</div>
						</div>

						<div className="container-cad-prod2">
							<div id="content1">
								<div className="select-box-ativos-select">
									<div className='details-flex'>
										<span className="details">Contrato *</span>
									</div>
									<Select
										maxMenuHeight={200}
										placeholder={'Selecione...'}
										options={optionContract}
										styles={customStyles}
										value={optionContract.filter(function (option: any) {
											return option.value === contract;
										})}
										onChange={(options: any) =>
											!options ? IncluirContrato("") : IncluirContrato(options.value)
										}
										isClearable
										noOptionsMessage={() => "Não encontrado..."}
										theme={(theme: any) => ({
											...theme,
											borderRadius: 4,
											colors: {
												...theme.colors,
												primary25: '#ebebeb',
												primary: 'black',
											},
										})}
										isDisabled={contractDisabled}
									/>
								</div>
							</div>
							<div id="content2">
								<div className="select-box-ativos-select">
									<div className='details-flex'>
										<span className="details">Unidade *</span>
									</div>
									<Select
										maxMenuHeight={200}
										placeholder={'Selecione...'}
										options={optionUnity}
										styles={customStyles}
										value={optionUnity.filter(function (option: any) {
											return option.value === unity;
										})}
										onChange={(options: any) =>
											!options ? setUnity("") : setUnity(options.value)
										}
										isClearable
										noOptionsMessage={() => "Não encontrado..."}
										theme={(theme: any) => ({
											...theme,
											borderRadius: 4,
											colors: {
												...theme.colors,
												primary25: '#ebebeb',
												primary: 'black',
											},
										})}
										isDisabled={unityDisabled}
									/>
								</div>
							</div>
						</div>

						<div className="title-Page">
							<div className="title-text">
								<div className="size-x circle">2</div>
								<h2>Quem vai executar?</h2>
							</div>
							<hr />
						</div>

                                <div className="container-cad-prod26">
                                    <div id="content1">
                                        <div className="select-box-ativos-select">
                                            <span className="details">Usuário</span>
                                            <Select
                                               maxMenuHeight={200}
										placeholder={'Selecione...'}
										options={optionsUsuario}
										styles={customStyles}
										value={optionsUsuario.filter(function (option: any) {
											return option.value === age_id;
										})}
										isDisabled={usuarioDisabled}
										onChange={(options: any) =>
											!options ? SelecionarAgent("") : SelecionarAgent(options.value)
										}
										isClearable
										noOptionsMessage={() => "Não encontrado..."}
										theme={(theme: any) => ({
											...theme,
											borderRadius: 4,
											colors: {
												...theme.colors,
												primary25: '#ebebeb',
												primary: 'black',
											},
										})}
                                            />
                                        </div>
                                    </div>
                                    <div id="content2">
                                        <div className="select-box-ativos-select">
                                            <span className="details">Equipe</span>
                                            <Select
                                                maxMenuHeight={80}
                                                placeholder={'Selecione...'}
                                                options={optionsEquipe}
                                                styles={customStyles}
                                                value={optionsEquipe.filter(function (option: any) {
                                                    return option.value === tea_id;
                                                })}
                                                onChange={(options: any) =>
                                                    !options ? SelecionarEquipe("") : SelecionarEquipe(options.value)
                                                }
                                                isClearable
                                                noOptionsMessage={() => "Não encontrado..."}
                                                theme={(theme: any) => ({
                                                    ...theme,
                                                    borderRadius: 4,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: '#ebebeb',
                                                        primary: 'black',
                                                    },
                                                })}
                                            />
                                        </div>
                                    </div>

                                    <div id="content3">
                                        <div className="select-box-ativos-select">
                                            <span className="details">Forma</span>
                                            <Select
                                                maxMenuHeight={80}
                                                placeholder={'Selecione...'}
                                                options={optionsForma}
                                                styles={customStyles}
                                                isDisabled={isDisabledForma}
                                                value={optionsForma.filter(function (option: any) {
                                                    return option.value === tsf_id;
                                                })}
                                                onChange={(options: any) =>
                                                    !options ? setTsf_id("") : setTsf_id(options.value)
                                                }
                                                isClearable
                                                noOptionsMessage={() => "Não encontrado..."}
                                                theme={(theme: any) => ({
                                                    ...theme,
                                                    borderRadius: 4,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: '#ebebeb',
                                                        primary: 'black',
                                                    },
                                                })}
                                            />
                                        </div>
                                    </div>
                                </div>
				


						<div className="Bt-button-cad">
							<button type="submit" onClick={salvarInventarioMovile}>
								<i className="fa fa-check-circle icon-ok-btn"></i> Salvar
							</button>
							<button type="submit" onClick={cancelar}>
								<i className="fa fa-times icon-cancelar-btn"></i> Cancelar
							</button>
						</div>

					</div>
				</main>
			</div>



			<ModalIncluirNovo isOpen={openModalIncluirNovo} setModalOpen={() => setOpenModalIncluirNovo(!openModalIncluirNovo)}>
				<>
					<div className='title-modal-incluir'>
						<h1 className="modal-IncluirNovo-title">{qualTitulo}</h1>
						<button className="fa fa-times icone-modal-IncluirNovo" data-dismiss="modal" aria-label="Close" onClick={fecharOpenModalIncluirNovo}></button>
					</div>

					<div className='scrol-modal'>
						{qualGrid}
					</div>
				</>
			</ModalIncluirNovo>

			<ModalAlert isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)}>
				<div className='container-modal-alert'>
					{qualAlert}
				</div>
			</ModalAlert>

		</>
	);

}

export default UsuariosAdd;