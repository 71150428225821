import "./featured.scss";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const Featured = (props) => {
  return (
    <div className="featured modern-featured2">

      <div className="top">
        <h1 className="title2">Garantia</h1>
      </div>

  

        <h1 className="Badge">Situação da garantia dos ativos</h1>
  
      <div className="bottom">
        <div className="featuredChart">
          <CircularProgressbar
            value={parseInt(props.CircularProgressbarPorcentagem)}
            text={`${props.CircularProgressbarPorcentagem}%`}
            strokeWidth={8}
            styles={buildStyles({
              textColor: "#666",
              pathColor: "#3962AC", // Mantendo a cor original
              trailColor: "#d9d9d9", // Fundo um pouco mais claro
              textSize: '18px',
              pathTransitionDuration: 0.75, // Animação de preenchimento mais suave
            })}
          />
        </div>

        <div className="summary">
          <div className="item">
            <div className="itemTitle">Fora da Garantia</div>
            <div className="itemResult negative">
              <div className="resultAmount">{props.foraGarantia}</div>
            </div>
          </div>
          <div className="item">
            <div className="itemTitle">Na Garantia</div>
            <div className="itemResult positive">
              <div className="resultAmount">{props.naGarantia}</div>
            </div>
          </div>
          <div className="item">
            <div className="itemTitle">Próximo a vencer</div>
            <div className="itemResult warning">
              <div className="resultAmount">{props.proximoVencer}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Featured;
