import React, { useState, useEffect } from 'react'
import './index.css'
import { AxiosResponse } from "axios";
import api from '../../Services/api'
import Loading from '../../components/Loading';
import secureLocalStorage from "react-secure-storage";
import ModalPrincipal from '../../components/ModalAlert/modal'
import { useNavigate } from 'react-router';

function FormMeusCadastros(props: any) {

    const navigate = useNavigate();

    const [openModal, setOpenModal] = useState(false)
    const [qualAlert, setQualAlert] = useState(<></>)
    const [openModalIncluirNovo, setOpenModalIncluirNovo] = useState(false)
    const [exibirGrid, setExibirGrid] = useState(<></>);

    const [qualTitulo, setQualTitulo] = useState('')
	const [qualGrid, setQualGrid] = useState(<></>)

    const dadosEquipe = (sessionStorage.getItem("dadosCadastro") || '{}');
    const dadosid = (sessionStorage.getItem("idcadastro") || '{}');

    let userID = JSON.parse(dadosid);
    let usertlc = JSON.parse(dadosEquipe);

    const [id, setId] = useState(usertlc.mrv_id || '')
    const [active, setActive] = useState(usertlc.mrv_active != undefined ? Boolean(usertlc.mrv_active) : true)
    const [description, setDescription] = useState(usertlc.mrv_description || '')
    const [descriptionIni, setDescriptionIni] = useState(usertlc.mrv_description || '')
    const [mmc_id, setmmc_id]= useState(userID)
    const [alternativeIdentifier, setAlternativeIdentifier] = useState(usertlc.mrv_integrationid || '')
    const [alternativeIdentifierIni, setAlternativeIdentifierIni] = useState(usertlc.mrv_integrationid || '')
    const [optionsCadastros, setOptionsCadastros] = useState([])

    const [descriptionDisabled, setDescriptionDisabled] = useState(false)
    const [alternativeIdentifierDisabled, setAlternativeIdentifierDisabled] = useState(false)
    const [confirmarDisabled, setConfirmarDisabled] = useState(false)
    const [activeDisabled, setActiveDisabled] = useState(false)

    useEffect(() => {

        validatToken();

      if(secureLocalStorage.getItem('acp') === 'CONSULTOR'){
            setDescriptionDisabled(true)
            setAlternativeIdentifierDisabled(true)
            setConfirmarDisabled(true)
            setActiveDisabled(true)
        }

    }, [])

    async function validatToken() {

    let ret: AxiosResponse;
    let valor: any;

    const _user = secureLocalStorage.getItem('t')

    api.defaults.headers.common['Authorization'] = _user as ''

    try {

        ret = await api.get("/status/validar")

        if (ret.data.status === 200) {

           secureLocalStorage.setItem('t', ret.data.data);
            secureLocalStorage.setItem('acp', ret.data.decodedToken.acp_integrationid);
            secureLocalStorage.setItem('n', ret.data.decodedToken.name);
            secureLocalStorage.setItem('iag', ret.data.decodedToken.id);
            secureLocalStorage.setItem('cy', ret.data.decodedToken.company);
            consultarRecords();

        }

    } catch (e: any) {

        if (e.response.data.status === 403) {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
                <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
            </div>)

            setOpenModal(true)

        }
    }

}

function fecharModal() {

    secureLocalStorage.removeItem('t')
    secureLocalStorage.removeItem('acp')
    secureLocalStorage.removeItem('n')
    secureLocalStorage.removeItem('iag')
    navigate('/login');
    setOpenModal(false)

}

   async function consultarRecords(){
    let ret: AxiosResponse;
		let valor: any;

        try {
            ret = await api.get("mobilemyrecordsvalue/verificacaomyrecordsvalue", {
                params: {
                    id: userID
                }
            })

            if (ret.data.status === 200) {
                
              
                setOptionsCadastros(ret.data.data)
                
            }
        } catch (e) {
            setOptionsCadastros([])
        }
    }

    
    function finalizarModal2() {
        setOpenModal(false)
    }


   async function ConfirmarTeamtype() {

        setQualAlert(<div className='div-alert'><Loading /></div>)
        setOpenModal(true)


        let ret: AxiosResponse;
        let valor: any;

        const _user = secureLocalStorage.getItem('t')

        api.defaults.headers.common['Authorization'] = _user as ''

        try {

            ret = await api.get("/status/validar")

            if (ret.data.status === 200) {




	const FiltrarLogin = optionsCadastros.filter(
			(e: any) => {
				return e.mrv_description.toLowerCase() === description.toLowerCase()
			}
		);

        const Filtrarid = optionsCadastros.filter(
			(e: any) => {
				return e.mrv_integrationid.toLowerCase() === alternativeIdentifier.toLowerCase()
			}
		);

		if(description.toLowerCase() !== descriptionIni.toLowerCase() && FiltrarLogin.length > 0){
	
            setQualAlert(<div className='div-alert'>
            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
            <div className='div-info-alert'><span className="info-alert">A descrição já está em uso.</span></div>
            <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
        </div>)


		}else
        if(alternativeIdentifier.toLowerCase() !== alternativeIdentifierIni.toLowerCase() && Filtrarid.length > 0){
	
            setQualAlert(<div className='div-alert'>
            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
            <div className='div-info-alert'><span className="info-alert">Identificador alternativo já está em uso.</span></div>
            <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
        </div>)


		}else
        if (description === "") {

            setQualAlert(<div className='div-alert'>
            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
            <div className='div-info-alert'><span className="info-alert">Digite a descrição.</span></div>
            <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
        </div>)
            
       
        } else
            if (alternativeIdentifier === "") {

                setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Digite o identificador alternativo.</span></div>
                <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
            </div>)
            
            } else
                if (id === undefined || id === '') {
                    insertTipoAgente();

                } else {
                    UpdateTipoAgente();
                }



            }

        } catch (e: any) {

            if (e.response.data.status === 403) {

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
                    <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)

            }
        }




    }

    async function UpdateTipoAgente() {
        let ret: AxiosResponse;
        const dados = {
            mrv_id: id,
            mrv_active: active,
            mrv_description: description,
            mrv_integrationid: alternativeIdentifier,
            mmc_id: mmc_id
        }
        try {
            ret = await api.put("mobilemyrecordsvalue/update", {
                data: dados,
            });
            if (ret.data.status === 200) {
                props.voltar(userID)
            }
        } catch (e: any) {

            setQualAlert(<div className='div-alert'>
            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
            <div className='div-info-alert'><span className="info-alert">Não foi possível concluir a alteração.</span></div>
            <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
        </div>)

          
        }
    }

    async function insertTipoAgente() {
        let ret: AxiosResponse;

        const dados = [{
            mrv_active: active,
            mrv_description: description,
            mrv_integrationid: alternativeIdentifier,
            mmc_id: mmc_id
        }]

        try {
            ret = await api.post("mobilemyrecordsvalue/insert", {
                data: dados,
            });

         
            if (ret.data.status === 200) {
                 props.voltar(userID)
            }
        } catch (e: any) {

            setQualAlert(<div className='div-alert'>
            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
            <div className='div-info-alert'><span className="info-alert">Não foi possível concluir o cadastramento.</span></div>
            <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
        </div>)

           
        }
    }

    function ValitarActive() {
        let e = document.getElementById("checkbox-active-fab") as HTMLInputElement;

        if (e.checked) {

            setActive(true)
        } else {

            setActive(false)
        }
    }

    function voltar() {

        window.location.reload();
    }

    return (
        <>
            <div className="container-modal-pric">
                <div className="class-checkbox">
                    <input type="checkbox" 
                    id="checkbox-active-fab" 
                    checked={active} 
                    onChange={ValitarActive} 
                    disabled={activeDisabled}
                    />
                    <h4 className="lab-ativo">Ativo</h4>
                </div>
                <div className="container-cad-prod4">
                    <div id="content1">
                        <div className="input-box-ativos">
                            <span className="details">Descrição *</span>
                            <input
                                type="text"
                                onChange={(e) => setDescription(e.target.value)}
                                value={description}
                                autoComplete="off"
                                disabled={descriptionDisabled}
                            />
                        </div>
                    </div>
                    <div id="content2">
                        <div className="input-box-ativos">
                            <span className="details">Identificador Alternativo *</span>
                            <input
                                autoComplete="off"
                                type="text"
                                onChange={(e) => setAlternativeIdentifier(e.target.value)}
                                value={alternativeIdentifier} 
                                disabled={alternativeIdentifierDisabled}
                                />
                        </div>
                    </div>
                </div>
                <div className="Bt-button-cad">
                    <button type="submit" onClick={ConfirmarTeamtype} disabled={confirmarDisabled}>
                        <i className="fa fa-check-circle icon-ok-btn"></i> Salvar
                    </button>
                    <button type="submit" onClick={()=> props.voltar(userID)}>
                        <i className="fa fa-times icon-cancelar-btn"></i> Cancelar
                    </button>
                </div>
            </div>


            <ModalPrincipal isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)}>

            <div className='container-modal-alert'>
                {qualAlert}
            </div>
            </ModalPrincipal>

        </>
    );

}

export default FormMeusCadastros;