import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Nav from "../../components/Nav";
import Header from "../../components/Header";
import GridPrincipal from '../../components/GridPrincipal';
import api from "../../Services/api";
import axios, { AxiosResponse } from 'axios';
import secureLocalStorage from "react-secure-storage";
import ModalPrincipal from '../../components/ModalAlert/modal';
import Loading from '../../components/Loading';
import StepNavigation from "../../components/Step/stepNavigation";
import {
    FaUser, FaMapMarkerAlt, FaCalendarAlt, FaTasks, FaRegClock,
    FaQrcode, FaCamera, FaPen, FaFileAlt
} from 'react-icons/fa';
import './index.css';
import { ImCheckboxChecked, ImCheckboxUnchecked } from 'react-icons/im';


interface Task {
    tsk_id: string;
    ast_description: string;
    loc_description: string;
    tsk_scheduleinitialdatehour: string | null;
    tsk_realinitialdatehour: string | null;
    tsk_realfinaldatehour: string | null;
    stn_description: string;
    src_description: string;
    tsk_observation: string;
}

interface Item {
    act_displayorder: string;
    act_description: string;
    asf_description: string;
    htv_externalvalue: string;
    age_name: string;
    hty_initialdatehour: string;
    hty_finaldatehour: string;
    fte_id: any;
    asf_displayorder: any;
    hty_syncdatehour: string;
    asf_typelist: any;
    asf_truevalue: string;
    asf_falsevalue: string;
}

type Params = {
    company?: string;
    token?: string;
    id?: string;
};

function VisualizarTask() {
    const { company, token, id } = useParams<Params>();
    const navigate = useNavigate();

    // Estados para os dados da tarefa e itens agrupados
    const [taskData, setTaskData] = useState<Task | null>(null);
    const [groupedData, setGroupedData] = useState<Record<string, Item[]>>({});
    const [logo, setLogo] = useState<string | null>(null);
    const [tsk_realinitialdatehour, setTsk_realinitialdatehour] = useState<string | null>(null);
    const [openModal, setOpenModal] = useState(false);
    const [qualAlert, setQualAlert] = useState(<></>);

    useEffect(() => {
        setQualAlert(<div className='div-alert'><Loading /></div>);
        setOpenModal(true);

        validarAmbiente();

    }, []);

    async function validarAmbiente() {
        try {
            const response = await axios.get('https://tudoapi.mobcode.com.br:3000/ambientes/validar', {
                params: { ambiente: company }
            });

            const endPoint = response.data.data[0]?.amb_endpointweb;
            if (!endPoint) throw new Error("Endpoint não encontrado.");

            const taskResponse = await axios.get(`${endPoint}task/report`, {
                params: { tsk_id: id, tsk_accesstoken: token }
            });


            console.log(taskResponse.data.data.imagem[0].etp_logotipo)

            const logoFromStorage = taskResponse.data.data.imagem[0].etp_logotipo as string | null;

            if (logoFromStorage) {
                const image = new Image();
                image.src = logoFromStorage;

                image.onload = () => setLogo(logoFromStorage);
                image.onerror = () => setLogo(null);
            }

            const task = taskResponse.data.data.task[0];
            setTaskData({
                tsk_id: task.tsk_id,
                ast_description: task.ast_description,
                loc_description: task.loc_description,
                tsk_scheduleinitialdatehour: formatDateTime(task.tsk_scheduleinitialdatehour),
                stn_description: task.stn_description,
                src_description: task.src_description,
                tsk_observation: task.tsk_observation,
                tsk_realinitialdatehour: task.tsk_realinitialdatehour,
                tsk_realfinaldatehour: task.tsk_realfinaldatehour,
            });

            // Aqui entra a lógica que você forneceu
            if (task.tsk_realinitialdatehour !== null) {
                if (task.tsk_realfinaldatehour !== null) {
                    setTsk_realinitialdatehour(formatDateRange(task.tsk_realinitialdatehour, task.tsk_realfinaldatehour));
                } else {
                    setTsk_realinitialdatehour(formatDateTime(task.tsk_realinitialdatehour));
                }
            }



            const history: Item[] = taskResponse.data.data.history;

            const sortedAndGroupedData = groupAndSortItems(history);
            setGroupedData(sortedAndGroupedData);

            setOpenModal(false);

        } catch (error) {
            console.error("Erro ao validar ambiente:", error);
        }
    }

    function formatDateTime(dateTime: string | null): string {
        return dateTime ? `${dateTime.substring(8, 10)}/${dateTime.substring(5, 7)}/${dateTime.substring(0, 4)} ${dateTime.substring(11, 16)}` : '';
    }

    function formatDateRange(start: string | null, end: string | null): string {
        if (start && end) {
            return `${formatDateTime(start)} - ${formatDateTime(end)}`;
        }
        return '';
    }

    function groupAndSortItems(items: Item[]): Record<string, Item[]> {
        const sortedData = items.sort((a, b) => parseInt(a.act_displayorder) - parseInt(b.act_displayorder));
        return sortedData.reduce<Record<string, Item[]>>((acc, item) => {
            if (!acc[item.act_description]) acc[item.act_description] = [];
            acc[item.act_description].push(item);
            return acc;
        }, {});
    }

    const formatExecution = (item: Item): string => {
        const initialDate = new Date(item.hty_initialdatehour);
        const finalDate = new Date(item.hty_finaldatehour);

        const formattedDate = initialDate.toLocaleDateString('pt-BR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });

        const formattedInitialTime = initialDate.toLocaleTimeString('pt-BR', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
        });

        const formattedFinalTime = finalDate.toLocaleTimeString('pt-BR', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
        });

        return `${formattedDate} ${formattedInitialTime} - ${formattedFinalTime}`;
    }

    function formatDateTime2(dateTime: string | null): string {
        if (!dateTime) return '';

        const date = new Date(dateTime);
        const day = String(date.getUTCDate()).padStart(2, '0');
        const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Os meses começam em 0
        const year = date.getUTCFullYear();
        const hours = String(date.getUTCHours()).padStart(2, '0');
        const minutes = String(date.getUTCMinutes()).padStart(2, '0');

        return `${day}/${month}/${year} ${hours}:${minutes}`;
    }


    return (
        <div className="ordem-servico-container-principal">
            <div className="ordem-servico-container">
                <div className="container-report1">
                    <div id="content1">
                        {logo ? (
                            <img className="img-dash10" src={logo} alt="Logo" />
                        ) : null}
                    </div>
                </div>

                <div className='title-report'>
                    <h1 className='title-report-text'>Relatório de Tarefas</h1>
                </div>

                <section className="principal-section">
                    <div className="info-content">
                        <p><FaFileAlt className="icon" /> <strong className='inf-principal'> Tarefa:</strong> {taskData?.tsk_id}</p>
                        <p><FaMapMarkerAlt className="icon" /> <strong>Onde:</strong> {taskData?.ast_description || taskData?.loc_description}</p>
                        <p><FaCalendarAlt className="icon" /> <strong>Quando (Previsto):</strong> {taskData?.tsk_scheduleinitialdatehour}</p>
                        <p><FaRegClock className="icon" /> <strong>Quando (Executado):</strong> {tsk_realinitialdatehour}</p>
                        <p><strong>Situação:</strong> {taskData?.stn_description}</p>
                        <p><strong>Origem:</strong> {taskData?.src_description}</p>
                        <p><strong>Observação:</strong> {taskData?.tsk_observation}</p>
                    </div>
                </section>

                {Object.entries(groupedData).map(([act_description, items]) => (
                    <section key={act_description} className="revisao-section">
                        <div className="revisao-header">
                            <FaTasks className="icon" />
                            <h2>{act_description}</h2>
                            {/* Aqui você deve acessar o hty_initialdatehour do primeiro item do array */}

                        </div>

                        <div className="revisao-details">
                            <p>
                                <strong>Execução:</strong>   {items.length > 0
                                    ? ` ${formatExecution(items[0])}`
                                    : 'N/A'}
                            </p>

                            <p>
                                <strong>Transferência:</strong>   {items.length > 0
                                    ? ` ${formatDateTime(items[0].hty_syncdatehour)}`
                                    : 'N/A'}
                            </p>



                        </div>

                        <div className="revisao-details">
                            {items.map((item, index) => (
                                <div key={index}>

                                    {item.fte_id === 13 || item.fte_id === 7 ? (
                                        <>
                                            <div className="foto-gallery" key={index}>
                                                <div className="foto">
                                                    <p><strong>{item.asf_description}</strong></p>
                                                    <img src={item.htv_externalvalue} alt={`Anexo ${index + 1}`} />
                                                    <a href={item.htv_externalvalue} target="_blank" rel="noopener noreferrer">
                                                        <span className='visualizar-img-foto'>
                                                            Visualizar imagem
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>

                                        </>
                                    ) : item.fte_id === 6 && item.asf_typelist === 2 ? (

                                        <div key={index}>
                                            <p><strong>{item.asf_description}</strong></p>
                                            <ul>
                                                {item.htv_externalvalue.split('&').filter(Boolean).map((subItem, subIndex) => (
                                                    <ul key={subIndex}>
                                                        <ImCheckboxChecked className='logico-icone' /> {subItem.trim()}
                                                    </ul>
                                                ))}
                                            </ul>
                                        </div>
                                    ) : item.fte_id === 3 ? (

                                        <div key={index}>
                                            <p><strong>{item.asf_description}</strong></p>
                                            <ul className="icone-logico-container">
                                            <ul className="icone-logico">
                                                {item.htv_externalvalue === item.asf_truevalue ? (
                                                    <ImCheckboxChecked className="logico-icone" />
                                                ) : (
                                                    <ImCheckboxUnchecked className="logico-icone" />
                                                )}
                                                {item.asf_truevalue}
                                            </ul>
                                            <ul className="icone-logico">
                                                {item.htv_externalvalue === item.asf_falsevalue ? (
                                                    <ImCheckboxChecked className="logico-icone" />
                                                ) : (
                                                    <ImCheckboxUnchecked className="logico-icone" />
                                                )}
                                                {item.asf_falsevalue}
                                            </ul>
                                            </ul>
                                        </div>
                                    )



                                        : (
                                            <p key={index}>
                                                <strong>{item.asf_description}</strong> {item.htv_externalvalue}
                                            </p>
                                        )}
                                </div>

                            ))}
                        </div>
                    </section>
                ))}

            </div>

            <ModalPrincipal isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)}>
                <div className='container-modal-alert'>
                    {qualAlert}
                </div>
            </ModalPrincipal>
        </div>
    );
}

export default VisualizarTask;
