import React, { useEffect, useState, useRef } from 'react'
import Nav from "../../components/Nav";
import Header from "../../components/Header";
import GridInventarioMobile from '../../components/GridInventarioMobile'
import api from "../../Services/api";
import { AxiosResponse } from "axios";
import './index.css'
import Loading from '../../components/Loading'
import Modal from '../../components/ModalAlert/modal'
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from 'react-router';
import { FaMobileScreen, FaListCheck, FaBarcode } from "react-icons/fa6";

function MobileInventory() {
    const navigate = useNavigate();
    const [exibirGrid, setExibirGrid] = useState(<></>);

    const [qualAlert, setQualAlert] = useState(<></>)
    const [btnAlert, setBtnAlert] = useState(false)

    const [openModal, setOpenModal] = useState(false)
    const [openModal2, setOpenModal2] = useState(false)


    useEffect(() => {

       // setQualAlert(<div className='div-alert'><Loading /></div>)
       // setOpenModal(true)

        validatToken()

    }, []);

    async function validatToken() {

        let ret: AxiosResponse;
        let valor: any;

        const _user = secureLocalStorage.getItem('t')

        api.defaults.headers.common['Authorization'] = _user as ''

        try {

            ret = await api.get("/status/validar")

            if (ret.data.status === 200) {

                secureLocalStorage.setItem('t', ret.data.data);
                secureLocalStorage.setItem('acp', ret.data.decodedToken.acp_integrationid);
                secureLocalStorage.setItem('n', ret.data.decodedToken.name);
                secureLocalStorage.setItem('iag', ret.data.decodedToken.id);
                secureLocalStorage.setItem('cy', ret.data.decodedToken.company);

                setExibirGrid(<GridInventarioMobile
                    GerarTarefas={GerarTarefas}
                    th1='Ativo'
                    th2='ID'
                    th3='Descrição'
                    th4='Data Inicial'
                    th5='Status'
                    th6='Tarefas'
                    endPoint='mobileinventory/buscarmobileinventory'
                    col1='1'
                    col2='0'
                    col3='2'
                    col4='3'
                    col5='4'
                    col6='5'
                    fromAdd='/mobileinventory/add'
                    fromEdit='/mobileinventory/edit'
                    btnDelete='N'
                    pag="inventariomobile"
                    buscarmobileinventory='mobileinventory/buscarmobileinventory'
                    user='user/listar'
                    team='team/listar'
                    contract='contract/listar'
                    unity='unity/exibir'
                    fecharModal={fecharModal}
                />)

                setOpenModal(false)

            }

        } catch (e: any) {

            if (e.response.data.status === 403) {

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
                    <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)

            }
        }

    }

    function fecharModal() {

        secureLocalStorage.removeItem('t')
        secureLocalStorage.removeItem('acp')
        secureLocalStorage.removeItem('n')
        secureLocalStorage.removeItem('iag')
        navigate('/login');
        setOpenModal(false)

    }



    async function GerarTarefas(obj: any) {

        if(secureLocalStorage.getItem('acp') !== 'CONSULTOR'){
        setQualAlert(<div className='div-alert'><Loading /></div>)
        setOpenModal(true)

        if (obj.ivb_generated === 'N') {

            let ret: AxiosResponse;
            let valor: any;
            try {
                ret = await api.get("mobileinventory/gerarinventariomobile", {
                    params: {
                        dados: obj,
                        idUser: secureLocalStorage.getItem('iag'),
                    }
                })

                if (ret.data.status === 200) {
                    valor = ret.data.data;


                    if (valor.length > 0) {
                        setQualAlert(<div className='div-alert'>
                            <span className="fa-regular fa-circle-check ControleIconAlert"></span>
                            <div className='div-info-alert'><span className="info-alert">Tarefas criadas com sucesso.</span></div>
                            <div className='btn-alert' onClick={teste}><span className="btn-alert-lab">OK</span></div>
                        </div>)
                        window.location.reload();
                    } else {
                        setQualAlert(<div className='div-alert'>
                            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                            <div className='div-info-alert'><span className="info-alert">Não é possível realizar sua solicitação, pois não possui ativo em uso no contrato.</span></div>
                            <div className='btn-alert' onClick={teste}><span className="btn-alert-lab">OK</span></div>
                        </div>)
                    }
                }
            } catch (e) {
                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Não foi possível realizar sua solicitação.</span></div>
                    <div className='btn-alert' onClick={teste}><span className="btn-alert-lab">OK</span></div>
                </div>)
            }
        } else {
            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">O inventário já foi gerado.</span></div>
                <div className='btn-alert' onClick={teste}><span className="btn-alert-lab">OK</span></div>
            </div>)
        }
    }

    }


    function teste() {
        setOpenModal(false)
    }

    return (
        <>
            <Nav />
            <div className="main-content">
                <Header />
                <main>
                    <div className='containerPrincipal'>
                        <div className='ContainerBody'>
                            <div className='div-titulo-pages'>
                            <FaMobileScreen />
                                <h1 className='div-titulo-pages-text'>Inventário Mobile</h1>
                            </div>

                        </div>
                        <div className='grid-container-principal'>
                            {exibirGrid}
                        </div>
                    </div>
                    <div>
                    </div>
                </main>
            </div>


            <Modal isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)}>
                <div className='container-modal-alert'>
                    {qualAlert}
                </div>
            </Modal>

        </>
    );

}

export default MobileInventory;