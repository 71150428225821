import React, { useEffect, useState, useRef } from 'react'
import Nav from "../../components/Nav";
import Header from "../../components/Header";
import GridPrincipal from '../../components/GridPrincipal'
import api from "../../Services/api";
import { AxiosResponse } from "axios";
import secureLocalStorage from "react-secure-storage";
import ModalPrincipal from '../../components/ModalAlert/modal'
import { useNavigate } from 'react-router';
import Loading from '../../components/Loading';
import { BsCalendarRangeFill } from "react-icons/bs";
import WeeklyCalendar from '../../components/WeeklyCalendar/index';
import { IoMdAdd } from "react-icons/io";
import './index.css'

function ActivityCalendar() {

    const navigate = useNavigate();
    const [exibirGrid, setExibirGrid] = useState(<></>);
    const [openModal, setOpenModal] = useState(false)
    const [qualAlert, setQualAlert] = useState(<></>)

    useEffect(() => {

        setQualAlert(<div className='div-alert'><Loading /></div>)
		setOpenModal(true)

        validatToken();

    }, []);
    
    async function validatToken() {

        let ret: AxiosResponse;
        let valor: any;

        const _user = secureLocalStorage.getItem('t')

        api.defaults.headers.common['Authorization'] = _user as ''

        try {

            ret = await api.get("/status/validar")

            if (ret.data.status === 200) {

                secureLocalStorage.setItem('t', ret.data.data);
                secureLocalStorage.setItem('acp', ret.data.decodedToken.acp_integrationid);
                secureLocalStorage.setItem('n', ret.data.decodedToken.name);
                secureLocalStorage.setItem('iag', ret.data.decodedToken.id);
                secureLocalStorage.setItem('cy', ret.data.decodedToken.company);

               await GetAgenda()
                setOpenModal(false)
            }

        } catch (e: any) {

            if (e.response === undefined) {
				setQualAlert(<div className='div-alert'>
					<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
					<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
					<div className='div-info-alert'><span className="info-alert">Sem comunicação com o servidor.</span></div>
					<div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
				</div>)

				setOpenModal(true)
			} else
				if (e.response.status === 403) {

					setQualAlert(<div className='div-alert'>
						<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
						<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
						<div className='div-info-alert'><span className="info-alert">Token inválido ou expirado.</span></div>
						<div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
					</div>)

					setOpenModal(true)

				} else {
					setQualAlert(<div className='div-alert'>
						<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
						<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
						<div className='div-info-alert'><span className="info-alert">Não foi possível concluir sua solicitação.</span></div>
						<div className='btn-alert' onClick={fecharModal2}><span className="btn-alert-lab">OK</span></div>
					</div>)

					setOpenModal(true)
				}
        }

    }



    async function GetAgenda() {
        let ret: AxiosResponse;
        let valor: any;
        try {
            ret = await api.get("task/taskagenda")
            if (ret.data.status === 200) {
                valor = ret.data.data;
                
     

                setExibirGrid(<WeeklyCalendar data={valor}/>)

            }
        } catch (e) {
           
        }
    }

    function fecharModal2() {
		setOpenModal(false)
	}
    
    function fecharModal() {

        secureLocalStorage.removeItem('t')
        secureLocalStorage.removeItem('acp')
        secureLocalStorage.removeItem('n')
        secureLocalStorage.removeItem('iag')
        navigate('/login');
        setOpenModal(false)

    }

    function criarNovo(){
        navigate('/planner/add');
    }

    return (
        <>
        <Nav />
        <div className="main-content">
        <Header />
         <main>
            <div className='containerPrincipal'>
            <div className='div-titulo-pages'>
            <BsCalendarRangeFill />
                    <h1 className='div-titulo-pages-text'>Calendário de Atividades</h1>
                </div>
                <div className="btn-add-cal" onClick={criarNovo}>
                    <IoMdAdd style={{ fontSize: '20px', color: '#fff' }} />
                    <h1  style={{ fontSize: '14px' }}>Adicionar Novo</h1>
                  </div>
              {exibirGrid}
            </div>
            </main>  
         </div>

         <ModalPrincipal isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)}>
                <div className='container-modal-alert'>
                    {qualAlert}
                </div>
            </ModalPrincipal>
        </>
    );

}

export default ActivityCalendar;