import React, { useState, useEffect } from 'react'
import './index.css'


function VisualizacaoMovimentacao(props: any) {
    return (
        <div className='card-visualizacao' key={props.obj[0].mov_id}>
            <h1 className='titulo-card-visuali'>{props.obj[0].ast_description}</h1>

            <div className='card-overflow'>
                {props.obj.map((tdata: any, index: any) => (
                    <div className='inf-card-visualizacao' key={index}>
                        <h1>ID Movimentação: {tdata.mov_id}</h1>
                        <h1>Tipo de Movimentação: {tdata.mov_type} </h1>
                        <h1>ID Ativo: {tdata.ast_id} </h1>
                        {tdata.origem === null || tdata.origem === '' ? (
                            <h1>Local de Origem: Estoque</h1>) :
                            <>
                              <h1>Contrato de Origem: {tdata.contratoAnterior}</h1>
                            <h1>Unidade de Origem: {tdata.origem} </h1>
                            </>
                            }
                        <h1>Data: {tdata.mov_datetimeinsert?.substring(8, 10)}/{tdata.mov_datetimeinsert?.substring(5, 7)}/{tdata.mov_datetimeinsert?.substring(0, 4)} {tdata.mov_datetimeinsert?.substring(11, 16)}</h1>

                    

                        {tdata.destino === null || tdata.destino === '' ? (
                            <h1>Local de Destino: Estoque</h1>) :
                            <>
                            <h1>Contrato de Destino: {tdata.contratoDestino}</h1>
                            <h1>Unidade de Destino: {tdata.destino}</h1>
                            </>
                        }

                        {tdata.mov_destinyloc !== '' ? (
                            <h1>Departamento de Destino: {tdata.loc_description}</h1>
                        ) : null}

                        {tdata.mov_usedby !== '' ? (
                            <h1>Usuário: {tdata.mov_usedby}</h1>
                        ) : null}

                        <h1>Realizado Por: {tdata.age_name}</h1>

                        {tdata.mov_observation !== '' ? (
                            <h1>Observação: {tdata.mov_observation}</h1>
                        ) : null}

                    </div>
                ))}
            </div>

        </div>

    );

}

export default VisualizacaoMovimentacao;