import React, { useEffect, useState } from 'react'
import "./index.css";
import { useNavigate } from 'react-router';
import  secureLocalStorage  from  "react-secure-storage";
import DropdownItem from '../DropdownItem2'
import { FaBars } from "react-icons/fa";

const Header = (props: any) => {
  const navigate = useNavigate();
  
  const [user, setUser] = useState("")

  useEffect(() => {
    setUser(secureLocalStorage.getItem('n') as '')
}, []);


  const handleLogoff = () => {
    secureLocalStorage.removeItem('t')
    secureLocalStorage.removeItem('acp')
    secureLocalStorage.removeItem('n')
    secureLocalStorage.removeItem('iag')
    navigate('/login');

  };

  return (
    <div className="main-content">
      <header>
        <h2>
          <label htmlFor="nav-toggle">
          <FaBars className="ico"/>
          </label>
        </h2>

        <div className="user-wrapper">
          <DropdownItem />
          <div>
        
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
