import React, { useState } from 'react';
import { FaUserCircle } from 'react-icons/fa';
import { useNavigate } from 'react-router';
import  secureLocalStorage  from  "react-secure-storage";

const DropdownItem = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleMouseEnterDropdown = () => {
    setIsOpen(true);
  };

  const handleMouseLeaveDropdown = () => {
    setIsOpen(false);
  };

  const handleMouseEnterItem = (index) => {
    setHoveredIndex(index);
  };

  const handleLogoff = () => {
    secureLocalStorage.removeItem('t')
    secureLocalStorage.removeItem('acp')
    secureLocalStorage.removeItem('n')
    secureLocalStorage.removeItem('iag')
    secureLocalStorage.removeItem('endPoint')
    navigate('/login');

  };

  function chamarFunc(id){

    if(id === 0){
      navigate('/settings');
    }else if(id === 1){
      navigate('/importer');  
    }else if(id === 2){
      navigate('/exporter');  
    }else if(id === 3){
      navigate('/accessprofile');  
     }else if(id === 4){
     handleLogoff()
    }




  }

  const handleMouseLeaveItem = () => {
    setHoveredIndex(null);
  };

  return (
    <div 
      style={styles.container}
      onMouseEnter={handleMouseEnterDropdown}
      onMouseLeave={handleMouseLeaveDropdown}
    >
      <div style={styles.profile}>
     
        <FaUserCircle style={styles.avatar} />
        <div style={styles.userInfo}>
          <span style={styles.userName}>{secureLocalStorage.getItem('n')}</span>
          <span style={styles.userRole}>{secureLocalStorage.getItem('tp')}</span>
        </div>
        <span style={styles.arrow}>{isOpen ? '▲' : '▼'}</span>
      </div>

      {isOpen && (
        <div style={styles.dropdownMenu}>
          {['Configurações', 'Importar dados', 'Exportar dados','Perfil de Acesso', 'Sair'].map((item, index) => (
            <a
              key={index}
              style={{
                ...styles.dropdownItem,
                backgroundColor: hoveredIndex === index ? '#f0f0f0' : 'transparent',
              }}
              onMouseEnter={() => handleMouseEnterItem(index)}
              onMouseLeave={handleMouseLeaveItem}
              onClick={()=> chamarFunc(index)}
            >
              {item}
            </a>
          ))}
        </div>
      )}
    </div>
  );
};

const styles = {
  container: {
    position: 'relative',
    display: 'inline-block',
    width: '200px',
    cursor: 'pointer',
  },
  profile: {
    display: 'flex',
    alignItems: 'center',
    padding: '4px',
    backgroundColor: '#fff',
    borderRadius: '5px',
   
  },
  avatar: {
    fontSize: '40px',
    marginRight: '10px',
    color: '#d6d5d5'
  },
  userInfo: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
  },
  userName: {
    fontSize: '14px',
    fontWeight: 'bold',
  },
  userRole: {
    fontSize: '12px',
    color: '#6c757d',
  },
  arrow: {
    marginLeft: 'auto',
    fontSize: '12px',
    color: '#6c757d',
  },
  dropdownMenu: {
    position: 'absolute',
    top: '100%',
    left: '0',
    backgroundColor: '#fff',
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.1)',
    borderRadius: '5px',
    width: '100%',
    zIndex: 1000,
    padding: '10px 0',
  },
  dropdownItem: {
    display: 'block',
    padding: '10px 20px',
    color: '#333',
    textDecoration: 'none',
    fontSize: '14px',
    cursor: 'pointer',
    transition: 'background-color 0.2s ease',
  },
  divider: {
    margin: '5px 0',
    borderTop: '1px solid #e0e0e0',
  },
};

export default DropdownItem;
