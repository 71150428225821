import React, { useState, useEffect } from 'react'
import { Droppable, DragDropContext, Draggable } from '@hello-pangea/dnd';
import { AiFillDelete, AiOutlineConsoleSql } from "react-icons/ai";
import {
  FcAlphabeticalSortingAz,
  FcNumericalSorting12,
  FcOk,
  FcCalendar,
  FcClock,
  FcViewDetails,
  FcMms,
  FcAbout,
  FcGlobe,
  FcPlus
} from "react-icons/fc";
import './index.css'
import Select from 'react-select'
import api from '../../Services/api'
import { GrEdit } from "react-icons/gr";
import ModalPrincipal from '../ModalAlert/modal'
import Loading from '../Loading'
import ModalIncluirCampoAtvNovo from '../ModalIncluirCampoAtvNovo2/modal'
import secureLocalStorage from "react-secure-storage";
import { FaQrcode, FaCheckSquare } from "react-icons/fa";
import { FaArrowLeft } from "react-icons/fa";
import { FcAddImage } from "react-icons/fc";
import { IoMdBarcode } from "react-icons/io";
import { IoStar } from "react-icons/io5";
import { SiGooglemaps } from "react-icons/si";
import { FcAlarmClock } from "react-icons/fc";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import FormAlfanumerico from '../../components/FormAlfanumerico'
import FormNumerico from '../../components/FormNumerico'
import FormLogico from '../../components/FormLogico'
import FormData from '../../components/FormData'
import FormHora from '../../components/FormHora'
import FormLista from '../../components/FormLista'
import FormMultimidia from '../../components/FormMultimidia'
import FormLabel from '../../components/FormLabel'
import FormCoordenada from '../../components/FormCoordenada'
import FormQrcode from '../../components/FormQrcode'
import FormImagem from '../../components/FormImagem'
import FormCodigo from '../../components/FormCodigo'
import FormQualificacao from '../../components/FormQualificacao'
import FormEndereco from '../../components/FormEndereco'
import FormDuracao from '../../components/FormDuracao'

function Beautiful(props) {

  const [openModalIncluirNovo, setOpenModalIncluirNovo] = useState(false)

  const [openModal, setOpenModal] = useState(false)
  const [qualAlert, setQualAlert] = useState(<></>)

  const [qForm, setQform] = useState(<></>);

  const [titulo, setTitulo] = useState(<></>);
  const [qualGrid, setQualGrid] = useState(<></>)

  const [idUp, setIdUp] = useState('')
  const [descriptionUp, setDescriptionUp] = useState('');
  const [integrationidUp, setIntegrationidUp] = useState('');
  const [tabIndex, setTabIndex] = useState(0);

  const [fte_id, setFte_id] = useState('')

  const [asf_active, setAsf_active] = useState(true);
  const [asf_editable, setAsf_editable] = useState(false);
  const [asf_acceptnull, setAsf_acceptnull] = useState(false);
  const [asf_visible, setAsf_visible] = useState(true);


  const [fdm_id, setFdm_id] = useState('')
  const [dtt_id, setDtt_id] = useState('')

  const [asf_description, setAsf_description] = useState('')
  const [asf_label, setAsf_label] = useState('')

  const [asf_tip, setAsf_tip] = useState('')
  const [asf_integrationid, setAsf_integrationid] = useState('')
  const [asf_size, setAsf_size] = useState('')

  const [asf_observation, setAsf_observation] = useState('')

  const [asf_typelist, setAsf_typelist] = useState('')
  const [asf_originvalues, setAsf_originvalues] = useState('')
  const [asf_internalvalue, setAsf_internalvalue] = useState('')
  const [asf_displayvalue, setAsf_displayvalue] = useState('')
  const [mrg_id, setMrg_id] = useState('')

  let [descricao, setDescricao] = useState("");

  const [optionsFdm, setOptionsFdm] = useState([])
  const [optionsDtt, setOptionsDtt] = useState([])


  const [optionsMrg, setOptionsMrg] = useState([])

  const [listaCampos, setListaCampos] = useState([])

  useEffect(() => {

    if (props.MeusCampos.length > 0) {
   
      setListaCampos(props.MeusCampos)
      props.IncluirCampos(props.MeusCampos)
    }

    GetMascara();
    GetEntradaDados();
    GetRegistrosMobile();
  }, []);

  async function GetRegistrosMobile() {
    let ret;
    let valor;
    try {
      ret = await api.get("mobilemyrecords/listar")
      if (ret.data.status === 200) {


        valor = ret.data.data;
        const arr = [];
        let result = valor;

        result.map((user) => {
          return arr.push({ value: String(user.mmc_id), label: user.mmc_description });
        });

        setOptionsMrg(arr)

      }
    } catch (e) {
      setOptionsMrg([])
    }
  }

  async function GetMascara() {
    let ret;
    let valor;
    try {
      ret = await api.get("fieldmask/listar")
      if (ret.data.status === 200) {
        valor = ret.data.data;
        const arr = [];
        let result = valor;
        result.map((user) => {
          return arr.push({ value: String(user.fdm_id), label: user.fdm_description });
        });
        setOptionsFdm(arr)

      }
    } catch (e) {
      setOptionsFdm([])
    }
  }

  async function GetEntradaDados() {
    let ret;
    let valor;
    try {
      ret = await api.get("dataentrytype/listar")
      if (ret.data.status === 200) {
        valor = ret.data.data;
        const arr = [];
        let result = valor;
        result.map((user) => {
          return arr.push({ value: String(user.dtt_id), label: user.dtt_description });
        });
        setOptionsDtt(arr)

      }
    } catch (e) {
      setOptionsDtt([])
    }
  }

  function handleOnDragEnd(result) {

    if (secureLocalStorage.getItem('acp') === 'DESENVOLVEDOR' || secureLocalStorage.getItem('acp') === 'ADMINISTRADOR') {
      if (!result.destination) return;

      const items = Array.from(listaCampos);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
      setListaCampos(items);

      props.IncluirCampos(items)
    }
  }

  function geraStringAleatoria() {
    var stringAleatoria = '';
    var caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (var i = 0; i < 8; i++) {
      stringAleatoria += caracteres.charAt(Math.floor(Math.random() * caracteres.length));
    }
    return stringAleatoria;
  }

  const onDelete = (id) => {

    if (secureLocalStorage.getItem('acp') === 'DESENVOLVEDOR' || secureLocalStorage.getItem('acp') === 'ADMINISTRADOR') {
      const newArray = listaCampos.filter((transaction) => transaction.id !== id);
      setListaCampos(newArray);

      props.IncluirCampos(newArray)

    }
  };

  async function abrirModal(id) {


    const _type = secureLocalStorage.getItem('acp')

    if (_type === 'DESENVOLVEDOR' || _type === 'ADMINISTRADOR') {

      let ret
      let valor

      const _user = secureLocalStorage.getItem('t')

      api.defaults.headers.common['Authorization'] = _user

      try {

        ret = await api.get("/status/validar")

        if (ret.data.status === 200) {

          setIdUp("")
          setFte_id("")
          setAsf_editable("")
          setAsf_acceptnull("")
          setAsf_visible(true)
          setFdm_id("")
          setDtt_id("")
          setAsf_description("")
          setAsf_label("")
          setAsf_integrationid("")
          setAsf_tip("")
          setAsf_size("")
          setAsf_observation("")
          setAsf_typelist("")
          setAsf_originvalues("")
          setMrg_id("")
          setAsf_internalvalue("")
          setAsf_displayvalue("")
          setFte_id(id)
          setAsf_active(true)
          setTitulo(<div className='title-modal-atv'>{String(id) === '1' ? (<FcAlphabeticalSortingAz className='icone-resumo-icon' />) : null}
            {String(id) === '2' ? (<FcNumericalSorting12 className='icone-resumo-icon' />) : null}
            {String(id) === '3' ? (<FcOk className='icone-resumo-icon' />) : null}
            {String(id) === '4' ? (<FcCalendar className='icone-resumo-icon' />) : null}
            {String(id) === '5' ? (<FcClock className='icone-resumo-icon' />) : null}
            {String(id) === '6' ? (<FcViewDetails className='icone-resumo-icon' />) : null}
            {String(id) === '7' ? (<FcMms className='icone-resumo-icon' />) : null}
            {String(id) === '8' ? (<FcAbout className='icone-resumo-icon' />) : null}
            {String(id) === '9' ? (<FcGlobe className='icone-resumo-icon' />) : null}
            {String(id) === '10' ? (<FaCheckSquare className='icone-resumo-iconCaixa' />) : null}
            {String(id) === '11' ? (<FaQrcode className='icone-resumo-image' />) : null}
            {String(id) === '13' ? (<FcAddImage className='icone-resumo-image' />) : null}
            {String(id) === '14' ? (<IoMdBarcode className='icone-resumo-image' />) : null}
            {String(id) === '16' ? (<IoStar className='icone-resumo-IoStar' />) : null}
            {String(id) === '17' ? (<SiGooglemaps className='icone-resumo-image' />) : null}
            {String(id) === '18' ? (<FcAlarmClock className='icone-resumo-image' />) : null}
            <h1 className='modal-titulo'>Incluir Campo</h1></div>)

          if (id === '1' || id === '2') {
            setFdm_id("1")
            setDtt_id("1")
          }

       

          if (id === '1') {

            setQform(<FormAlfanumerico
              confirmarCampo={confirmarCampo}
              fte_id={id}
              dados={[]}
              fecharOpenModalIncluirNovo={fecharOpenModalIncluirNovo}
              listaCampos={listaCampos}
            />)

          } else if (id === '2') {
            setQform(<FormNumerico
              confirmarCampo={confirmarCampo}
              fte_id={id}
              dados={[]}
              fecharOpenModalIncluirNovo={fecharOpenModalIncluirNovo}
              listaCampos={listaCampos}
            />)
          } else if (id === '3') {
            setQform(<FormLogico
              confirmarCampo={confirmarCampo}
              fte_id={id}
              dados={[]}
              fecharOpenModalIncluirNovo={fecharOpenModalIncluirNovo}
              listaCampos={listaCampos}
            />)
          } else if (id === '4') {
            setQform(<FormData
              confirmarCampo={confirmarCampo}
              fte_id={id}
              dados={[]}
              fecharOpenModalIncluirNovo={fecharOpenModalIncluirNovo}
              listaCampos={listaCampos}
            />)
          } else if (id === '5') {
            setQform(<FormHora
              confirmarCampo={confirmarCampo}
              fte_id={id}
              dados={[]}
              fecharOpenModalIncluirNovo={fecharOpenModalIncluirNovo}
              listaCampos={listaCampos}
            />)
          } else if (id === '6') {
            setQform(<FormLista
              confirmarCampo={confirmarCampo}
              fte_id={id}
              dados={[]}
              fecharOpenModalIncluirNovo={fecharOpenModalIncluirNovo}
              listaCampos={listaCampos}
            />)
          } else if (id === '7') {
            setQform(<FormMultimidia
              confirmarCampo={confirmarCampo}
              fte_id={id}
              dados={[]}
              fecharOpenModalIncluirNovo={fecharOpenModalIncluirNovo}
              listaCampos={listaCampos}
            />)
          } else if (id === '8') {
            setQform(<FormLabel
              confirmarCampo={confirmarCampo}
              fte_id={id}
              dados={[]}
              fecharOpenModalIncluirNovo={fecharOpenModalIncluirNovo}
              listaCampos={listaCampos}
            />)
          } else if (id === '9') {
            setQform(<FormCoordenada
              confirmarCampo={confirmarCampo}
              fte_id={id}
              dados={[]}
              fecharOpenModalIncluirNovo={fecharOpenModalIncluirNovo}
              listaCampos={listaCampos}
            />)
          } else if (id === '11') {
            setQform(<FormQrcode
              confirmarCampo={confirmarCampo}
              fte_id={id}
              dados={[]}
              fecharOpenModalIncluirNovo={fecharOpenModalIncluirNovo}
              listaCampos={listaCampos}
            />)
          } else if (id === '13') {
            setQform(<FormImagem
              confirmarCampo={confirmarCampo}
              fte_id={id}
              dados={[]}
              fecharOpenModalIncluirNovo={fecharOpenModalIncluirNovo}
              listaCampos={listaCampos}
            />)
          } else if (id === '14') {
            setQform(<FormCodigo
              confirmarCampo={confirmarCampo}
              fte_id={id}
              dados={[]}
              fecharOpenModalIncluirNovo={fecharOpenModalIncluirNovo}
              listaCampos={listaCampos}
            />)
          } else if (id === '16') {
            setQform(<FormQualificacao
              confirmarCampo={confirmarCampo}
              fte_id={id}
              dados={[]}
              fecharOpenModalIncluirNovo={fecharOpenModalIncluirNovo}
              listaCampos={listaCampos}
            />)
          } else if (id === '17') {
            setQform(<FormEndereco
              confirmarCampo={confirmarCampo}
              fte_id={id}
              dados={[]}
              fecharOpenModalIncluirNovo={fecharOpenModalIncluirNovo}
              listaCampos={listaCampos}
            />)
          } else if (id === '18') {
            setQform(<FormDuracao
              confirmarCampo={confirmarCampo}
              fte_id={id}
              dados={[]}
              fecharOpenModalIncluirNovo={fecharOpenModalIncluirNovo}
              listaCampos={listaCampos}
            />)
          } else {
            setQform(<></>)
          }






          setOpenModalIncluirNovo(true)


        }

      } catch (e) {

        if (e.response.data.status === 403) {

          setQualAlert(<div className='div-alert'>
            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
            <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
            <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
          </div>)

          setOpenModal(true)

        }
      }
    }

  }

  function ValidarActive() {

    if (asf_active === true) {
      setAsf_active(false)

    } else {
      setAsf_active(true)

    }

  }


  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: '#fff',
      minHeight: '30px',
      height: '30px',
      borderColor: state.isFocused ? 'grey' : '#92989e',

    }),


    valueContainer: (provided, state) => ({
      ...provided,
      height: '30px',
      padding: '0 6px'
    }),

    input: (provided, state) => ({
      ...provided,
      margin: '0px',
      fontSize: 14,

    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '30px',
    }),
  };


  function confirmarCampo(data) {
    setOpenModal(true)
    setQualAlert(<div className='div-alert'><Loading /></div>)


    if (data.descriptionUp === null) {

      const nomeFilter = listaCampos.filter(
        (e) => {
          return e.asf_description === data.asf_description
        }
      );

      const identificadorFilter = listaCampos.filter(
        (e) => {
          return e.asf_alternativeidentifier === data.asf_alternativeidentifier
        }
      );

      if (nomeFilter.length > 0) {

        setQualAlert(<div className='div-alert'>
          <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Já existe na atividade um campo com esta descrição.</span></div>
          <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
        </div>)


      } else if (identificadorFilter.length > 0) {

        setQualAlert(<div className='div-alert'>
          <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Já existe na atividade um campo com esse identificador alternativo.</span></div>
          <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
        </div>)

      }

      else {

        setListaCampos([...listaCampos, data]);

        props.IncluirCampos([...listaCampos, data])

        setOpenModalIncluirNovo(false)
        setOpenModal(false)
      }
    } else {
      let descricao = []
      let identificador = []

      if (data.descriptionUp !== data.asf_description) {

        const nomeFilter = listaCampos.filter(
          (e) => {
            return e.asf_description === data.asf_description
          }
        );

        descricao = nomeFilter;
      }

      if (data.alternativeidentifieUp !== data.asf_alternativeidentifier) {

        const identificadorFilter = listaCampos.filter(
          (e) => {
            return e.asf_alternativeidentifier === data.asf_alternativeidentifier
          }
        );

        identificador = identificadorFilter

      }


      if (descricao.length > 0) {

        setQualAlert(<div className='div-alert'>
          <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Já existe na atividade um campo com esta descrição.</span></div>
          <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
        </div>)

      } else if (identificador.length > 0) {

        setQualAlert(<div className='div-alert'>
          <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Já existe na atividade um campo com esse identificador alternativo.</span></div>
          <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
        </div>)

      }

      else {

        const CampoEncontrada = listaCampos.find((campos) => campos.id === data.id);


        if (CampoEncontrada) {
          CampoEncontrada.fte_id = data.fte_id
          CampoEncontrada.fdm_id = data.fdm_id
          CampoEncontrada.asf_active = data.asf_active
          CampoEncontrada.asf_visible = data.asf_visible
          CampoEncontrada.asf_editable = data.asf_editable
          CampoEncontrada.asf_acceptnull = data.asf_acceptnull
          CampoEncontrada.asf_description = data.asf_description
          CampoEncontrada.asf_label = data.asf_label
          CampoEncontrada.asf_alternativeidentifier = data.asf_alternativeidentifier
          CampoEncontrada.asf_tip = data.asf_tip
          CampoEncontrada.asf_size = data.asf_size
          CampoEncontrada.asf_observation = data.asf_observation
          CampoEncontrada.asf_typelist = data.asf_typelist
          CampoEncontrada.mmc_id = data.mmc_id
          CampoEncontrada.asf_fontformat = data.asf_fontformat
          CampoEncontrada.asf_fontsize = data.asf_fontsize
          CampoEncontrada.asf_truevalue = data.asf_truevalue
          CampoEncontrada.asf_falsevalue = data.asf_falsevalue
          CampoEncontrada.age_id_insert = data.age_id_insert
          CampoEncontrada.age_id_lastupdate = data.age_id_lastupdate
          CampoEncontrada.descriptionUp = data.descriptionUp
          CampoEncontrada.alternativeidentifieUp = data.alternativeidentifieUp
          CampoEncontrada.asf_condition = data.asf_condition
          CampoEncontrada.asf_function = data.asf_function
          CampoEncontrada.asf_valueformulas = data.asf_valueformulas
          CampoEncontrada.asf_labelvalueformulas = data.asf_labelvalueformulas
          CampoEncontrada.asf_validationformulas = data.asf_validationformulas
          CampoEncontrada.asf_validationformulasobj = data.asf_validationformulasobj
        }

        props.IncluirCampos(listaCampos)

        setOpenModalIncluirNovo(false)
        setOpenModal(false)
      }
    }



  }

  function fecharModal() {
    setOpenModal(false)
  }


  function cancelar() {
    setOpenModalIncluirNovo(false)
  }


  const maskOnlyNumbers = (value) => {
    return value.replace(/\D/g, "");
  };

  async function editarCampo(id) {

  console.log(id)
  
    const _type = secureLocalStorage.getItem('acp')

    if (_type === 'DESENVOLVEDOR' || _type === 'ADMINISTRADOR') {

      let ret
      let valor

      const _user = secureLocalStorage.getItem('t')

      api.defaults.headers.common['Authorization'] = _user

      try {

        ret = await api.get("/status/validar")

        if (ret.data.status === 200) {

          const temp_rows = listaCampos.filter(
            (e) => {
              return e.id === id
            }
          );

          setTitulo(<div className='title-modal-atv'>
            {String(temp_rows[0].fte_id) === '1' ? (<FcAlphabeticalSortingAz className='icone-resumo-icon' />) : null}
            {String(temp_rows[0].fte_id) === '2' ? (<FcNumericalSorting12 className='icone-resumo-icon' />) : null}
            {String(temp_rows[0].fte_id) === '3' ? (<FcOk className='icone-resumo-icon' />) : null}
            {String(temp_rows[0].fte_id) === '4' ? (<FcCalendar className='icone-resumo-icon' />) : null}
            {String(temp_rows[0].fte_id) === '5' ? (<FcClock className='icone-resumo-icon' />) : null}
            {String(temp_rows[0].fte_id) === '6' ? (<FcViewDetails className='icone-resumo-icon' />) : null}
            {String(temp_rows[0].fte_id) === '7' ? (<FcMms className='icone-resumo-icon' />) : null}
            {String(temp_rows[0].fte_id) === '8' ? (<FcAbout className='icone-resumo-icon' />) : null}
            {String(temp_rows[0].fte_id) === '9' ? (<FcGlobe className='icone-resumo-icon' />) : null}
            {String(temp_rows[0].fte_id) === '10' ? (<FaCheckSquare className='icone-resumo-iconCaixa' />) : null}
            {String(temp_rows[0].fte_id) === '11' ? (<FaQrcode className='icone-resumo-image' />) : null}
            {String(temp_rows[0].fte_id) === '13' ? (<FcAddImage className='icone-resumo-image' />) : null}
            {String(temp_rows[0].fte_id) === '14' ? (<IoMdBarcode className='icone-resumo-image' />) : null}
            {String(temp_rows[0].fte_id) === '16' ? (<IoStar className='icone-resumo-IoStar' />) : null}
            {String(temp_rows[0].fte_id) === '17' ? (<SiGooglemaps className='icone-resumo-image' />) : null}
            {String(temp_rows[0].fte_id) === '18' ? (<FcAlarmClock className='icone-resumo-image' />) : null}
            <h1 className='modal-titulo'>Incluir Campo</h1></div>)


     

          if (String(temp_rows[0].fte_id) === '1') {

            setQform(<FormAlfanumerico
              confirmarCampo={confirmarCampo}
              fte_id={String(temp_rows[0].fte_id)}
              dados={temp_rows[0]}
              fecharOpenModalIncluirNovo={fecharOpenModalIncluirNovo}
              listaCampos={listaCampos}
            />)

          } else if (String(temp_rows[0].fte_id)=== '2') {
            setQform(<FormNumerico
              confirmarCampo={confirmarCampo}
              fte_id={String(temp_rows[0].fte_id)}
              dados={temp_rows[0]}
              fecharOpenModalIncluirNovo={fecharOpenModalIncluirNovo}
              listaCampos={listaCampos}
            />)
          } else if (String(temp_rows[0].fte_id) === '3') {
            setQform(<FormLogico
              confirmarCampo={confirmarCampo}
              fte_id={String(temp_rows[0].fte_id)}
              dados={temp_rows[0]}
              fecharOpenModalIncluirNovo={fecharOpenModalIncluirNovo}
              listaCampos={listaCampos}
            />)
          } else if (String(temp_rows[0].fte_id) === '4') {
            setQform(<FormData
              confirmarCampo={confirmarCampo}
              fte_id={String(temp_rows[0].fte_id)}
              dados={temp_rows[0]}
              fecharOpenModalIncluirNovo={fecharOpenModalIncluirNovo}
              listaCampos={listaCampos}
            />)
          } else if (String(temp_rows[0].fte_id) === '5') {
            setQform(<FormHora
              confirmarCampo={confirmarCampo}
              fte_id={String(temp_rows[0].fte_id)}
              dados={temp_rows[0]}
              fecharOpenModalIncluirNovo={fecharOpenModalIncluirNovo}
              listaCampos={listaCampos}
            />)
          } else if (String(temp_rows[0].fte_id) === '6') {
            setQform(<FormLista
              confirmarCampo={confirmarCampo}
              fte_id={String(temp_rows[0].fte_id)}
              dados={temp_rows[0]}
              fecharOpenModalIncluirNovo={fecharOpenModalIncluirNovo}
              listaCampos={listaCampos}
            />)
          } else if (String(temp_rows[0].fte_id) === '7') {
            setQform(<FormMultimidia
              confirmarCampo={confirmarCampo}
              fte_id={String(temp_rows[0].fte_id)}
              dados={temp_rows[0]}
              fecharOpenModalIncluirNovo={fecharOpenModalIncluirNovo}
              listaCampos={listaCampos}
            />)
          } else if (String(temp_rows[0].fte_id) === '8') {
            setQform(<FormLabel
              confirmarCampo={confirmarCampo}
              fte_id={String(temp_rows[0].fte_id)}
              dados={temp_rows[0]}
              fecharOpenModalIncluirNovo={fecharOpenModalIncluirNovo}
              listaCampos={listaCampos}
            />)
          } else if (String(temp_rows[0].fte_id) === '9') {
            setQform(<FormCoordenada
              confirmarCampo={confirmarCampo}
              fte_id={String(temp_rows[0].fte_id)}
              dados={temp_rows[0]}
              fecharOpenModalIncluirNovo={fecharOpenModalIncluirNovo}
              listaCampos={listaCampos}
            />)
          } else if (String(temp_rows[0].fte_id) === '11') {
            setQform(<FormQrcode
              confirmarCampo={confirmarCampo}
              fte_id={String(temp_rows[0].fte_id)}
              dados={temp_rows[0]}
              fecharOpenModalIncluirNovo={fecharOpenModalIncluirNovo}
              listaCampos={listaCampos}
            />)
          } else if (String(temp_rows[0].fte_id) === '13') {
            setQform(<FormImagem
              confirmarCampo={confirmarCampo}
              fte_id={String(temp_rows[0].fte_id)}
              dados={temp_rows[0]}
              fecharOpenModalIncluirNovo={fecharOpenModalIncluirNovo}
              listaCampos={listaCampos}
            />)
          } else if (String(temp_rows[0].fte_id) === '14') {
            setQform(<FormCodigo
              confirmarCampo={confirmarCampo}
              fte_id={String(temp_rows[0].fte_id)}
              dados={temp_rows[0]}
              fecharOpenModalIncluirNovo={fecharOpenModalIncluirNovo}
              listaCampos={listaCampos}
            />)
          } else if (String(temp_rows[0].fte_id) === '16') {
            setQform(<FormQualificacao
              confirmarCampo={confirmarCampo}
              fte_id={String(temp_rows[0].fte_id)}
              dados={temp_rows[0]}
              fecharOpenModalIncluirNovo={fecharOpenModalIncluirNovo}
              listaCampos={listaCampos}
            />)
          } else if (String(temp_rows[0].fte_id) === '17') {
            setQform(<FormEndereco
              confirmarCampo={confirmarCampo}
              fte_id={String(temp_rows[0].fte_id)}
              dados={temp_rows[0]}
              fecharOpenModalIncluirNovo={fecharOpenModalIncluirNovo}
              listaCampos={listaCampos}
            />)
          } else if (String(temp_rows[0].fte_id) === '18') {
            setQform(<FormDuracao
              confirmarCampo={confirmarCampo}
              fte_id={String(temp_rows[0].fte_id)}
              dados={temp_rows[0]}
              fecharOpenModalIncluirNovo={fecharOpenModalIncluirNovo}
              listaCampos={listaCampos}
            />)
          } else {
            setQform(<></>)
          }

          setOpenModalIncluirNovo(true)


        }

      } catch (e) {

        if (e.response.data.status === 403) {

          setQualAlert(<div className='div-alert'>
            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
            <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
            <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
          </div>)

          setOpenModal(true)

        }
      }
    }

  }


  function fecharOpenModalIncluirNovo() {
    setOpenModalIncluirNovo(false)
    setTabIndex(0)
  }

  function ChamaResumo() {
    setTabIndex(0)
  }

  return (
    <>
      <div className="container-cad-prod41">

        <div id="content21">

          <div className='list-Beautiful'>

            <div className='list-BeautifulBar'>
              <FaArrowLeft className='list-BeautifulBarIcon' />
              <h1 className='list-BeautifulBarLab'>Campos Selecionados</h1>
            </div>

            <div className='container-beautiful'>
              <div className='container-beautiful1'>
                <div className='container-beautiful2'>
                  <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="characters">
                      {(provided) => (
                        <ul className="characters" {...provided.droppableProps} ref={provided.innerRef}>
                          {listaCampos.map(({ id, asf_description, fte_id }, index) => {
                            return (
                              <Draggable key={String(id)} draggableId={String(id)} index={index}>
                                {(provided) => (
                                  <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>

                                    <div className='icone-resumo-div'>
                                      <div className='icone-resumo-label' >
                                        <div>
                                        {String(fte_id) === '1' ? (<FcAlphabeticalSortingAz className='icone-resumo-icon' />) : null}
                                        {String(fte_id) === '2' ? (<FcNumericalSorting12 className='icone-resumo-icon' />) : null}
                                        {String(fte_id) === '3' ? (<FcOk className='icone-resumo-icon' />) : null}
                                        {String(fte_id) === '4' ? (<FcCalendar className='icone-resumo-icon' />) : null}
                                        {String(fte_id) === '5' ? (<FcClock className='icone-resumo-icon' />) : null}
                                        {String(fte_id) === '6' ? (<FcViewDetails className='icone-resumo-icon' />) : null}
                                        {String(fte_id) === '7' ? (<FcMms className='icone-resumo-icon' />) : null}
                                        {String(fte_id) === '8' ? (<FcAbout className='icone-resumo-icon' />) : null}
                                        {String(fte_id) === '9' ? (<FcGlobe className='icone-resumo-icon' />) : null}
                                        {String(fte_id) === '10' ? (<FaCheckSquare className='icone-resumo-iconCaixa' />) : null}
                                        {String(fte_id) === '11' ? (<FaQrcode className='icone-resumo-image' />) : null}
                                        {String(fte_id) === '13' ? (<FcAddImage className='icone-resumo-image' />) : null}
                                        {String(fte_id) === '14' ? (<IoMdBarcode className='icone-resumo-image' />) : null}
                                        {String(fte_id) === '16' ? (<IoStar className='icone-resumo-IoStar' />) : null}
                                        {String(fte_id) === '17' ? (<SiGooglemaps className='icone-resumo-image' />) : null}
                                        {String(fte_id) === '18' ? (<FcAlarmClock className='icone-resumo-image' />) : null}
                                        </div>
                                        <div className='label-resumo'>
                                          {asf_description}
                                        </div>
                                      </div>

                                      <div className='icon-Beautiful-btn' >
                                        <div className='icon-Beautiful'>
                                          <div onClick={() => editarCampo(id)}>
                                            <GrEdit className='icone-resumo' />
                                          </div>

                                         
                                        </div>
                                      </div>


                                    </div>


                                  </li>
                                )}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </ul>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="content20">

          <div className='list-Beautiful'>

            <div className='list-BeautifulBar'>
              <FaArrowLeft className='list-BeautifulBarIcon' />
              <h1 className='list-BeautifulBarLab'>Campos Disponíveis</h1>
            </div>

            <div className='container-beautiful'>
              <div className='container-beautiful1'>
                <div className='container-beautiful2'>
                  <DragDropContext>
                    <Droppable droppableId="characters">
                      {(provided) => (
                        <ul className="characters" {...provided.droppableProps} ref={provided.innerRef}>
                          {props.OptionFieldtype.map(({ value, label, Icon }, index) => {
                            return (
                              <Draggable key={value} draggableId={value} index={index}>
                                {(provided) => (
                                  <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                    <div className='icone-resumo-label'>
                                      {String(value) === '1' ? (<FcAlphabeticalSortingAz className='icone-resumo-icon' />) : null}
                                      {value === '2' ? (<FcNumericalSorting12 className='icone-resumo-icon' />) : null}
                                      {value === '3' ? (<FcOk className='icone-resumo-icon' />) : null}
                                      {value === '4' ? (<FcCalendar className='icone-resumo-icon' />) : null}
                                      {value === '5' ? (<FcClock className='icone-resumo-icon' />) : null}
                                      {value === '6' ? (<FcViewDetails className='icone-resumo-icon' />) : null}
                                      {value === '7' ? (<FcMms className='icone-resumo-icon' />) : null}
                                      {value === '8' ? (<FcAbout className='icone-resumo-icon' />) : null}
                                      {value === '9' ? (<FcGlobe className='icone-resumo-icon' />) : null}
                                      {value === '10' ? (<FaCheckSquare className='icone-resumo-iconCaixa' />) : null}
                                      {value === '11' ? (<FaQrcode className='icone-resumo-image' />) : null}
                                      {value === '13' ? (<FcAddImage className='icone-resumo-image' />) : null}
                                      {value === '14' ? (<IoMdBarcode className='icone-resumo-image' />) : null}
                                      {value === '16' ? (<IoStar className='icone-resumo-IoStar' />) : null}
                                      {value === '17' ? (<SiGooglemaps className='icone-resumo-image' />) : null}
                                      {value === '18' ? (<FcAlarmClock className='icone-resumo-image' />) : null}
                                      <div className='label-resumo'>
                                        {label}
                                      </div>
                                    </div>

                                    <div className='icone-resumo' onClick={() => abrirModal(value)}>
                                      <FcPlus />
                                    </div>

                                  </li>
                                )}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </ul>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <ModalIncluirCampoAtvNovo isOpen={openModalIncluirNovo} setModalOpen={() => setOpenModalIncluirNovo(!openModalIncluirNovo)}>
        <>
          <div className='title-modal-incluir'>
            <h1 className="modal-IncluirNovo-title">{titulo}</h1>

            <div onClick={fecharOpenModalIncluirNovo}>
              <button className="fa fa-times icone-modal-IncluirNovo" />
            </div>
          </div>

          <div className='container-modal-incluir-atv2'>




            {qForm}







          </div>
        </>
      </ModalIncluirCampoAtvNovo>

      <ModalPrincipal isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)}>
        <div className='container-modal-alert'>
          {qualAlert}
        </div>
      </ModalPrincipal>

    </>
  );
}

export default Beautiful;
