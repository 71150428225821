import React, { useState, useEffect } from 'react'
import './index.css'
import { AxiosResponse } from "axios";
import api from '../../Services/api'
import Select from 'react-select'
import Loading from '../../components/Loading';
import secureLocalStorage from "react-secure-storage";
import ModalAlert from '../../components/ModalAlert/modal'
import { useNavigate } from 'react-router';

function FormModelo(props: any) {
    const dadosMod = (sessionStorage.getItem("dadosFab") || '{}');
    let userMod = JSON.parse(dadosMod);

    const navigate = useNavigate();
    const [id, setId] = useState(userMod.mde_id || '')
    const [active, setActive] = useState(userMod.mde_active != undefined ? Boolean(userMod.mde_active) : true)
    const [description, setDescription] = useState(userMod.mde_description || '')
    const [alternativeIdentifier, setAlternativeIdentifier] = useState(userMod.mde_alternativeidentifier || '')
    const [descriptionIni, setDescriptionIni] = useState(userMod.mde_description || '')
    const [alternativeIdentifierIni, setAlternativeIdentifierIni] = useState(userMod.mde_alternativeidentifier || '')
    const [manufacturer, setManufacturer] = useState(String(userMod.mfr_id || ''))
    const [brand, setBrand] = useState(String(userMod.brn_id || ''))
    const [openModal, setOpenModal] = useState(false)
    const [qualAlert, setQualAlert] = useState(<></>)
    const [dadosMarca, setDadosMarca] = useState([]);
    const [dadosFabricante, setDadosFabricante] = useState([]);
    const [carregaFabricante, setCarregaFabricante] = useState(GetFabricante);
    const [optionsModel, setOptionsModel] = useState([])
const [disabledManufacturer, setDisabledManufacturer] = useState(false)

    useEffect(() => {
      
        if (props.manufacturer === undefined) {
      
           validatToken();
            setDisabledManufacturer(false)
        } 
        else
        if (props.manufacturer !== undefined) {
          
            setDisabledManufacturer(true)
            setManufacturer(String(props.manufacturer));
            setBrand(String(props.brand));
            validatTokenProps();
       
        }

       

       
    }, []);


    async function validatToken() {

        let ret: AxiosResponse;
        let valor: any;

        const _user = secureLocalStorage.getItem('t')

        api.defaults.headers.common['Authorization'] = _user as ''

        try {

            ret = await api.get("/status/validar")

            if (ret.data.status === 200) {

                secureLocalStorage.setItem('t', ret.data.data);
                secureLocalStorage.setItem('acp', ret.data.decodedToken.acp_integrationid);
                secureLocalStorage.setItem('n', ret.data.decodedToken.name);
                secureLocalStorage.setItem('iag', ret.data.decodedToken.id);
                secureLocalStorage.setItem('cy', ret.data.decodedToken.company);

               if (sessionStorage.getItem("dadosFab") !== null){
                GetMarca(userMod.mfr_id);
               }
                
                GetModelo("%");
            }
        } catch (e: any) {

            if (e.response.data.status === 403) {

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
                    <div className='btn-alert' onClick={fecharModalToken}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)

            }
        }

    }

    async function validatTokenProps() {

        let ret: AxiosResponse;
        let valor: any;

        const _user = secureLocalStorage.getItem('t')

        api.defaults.headers.common['Authorization'] = _user as ''

        try {

            ret = await api.get("/status/validar")

            if (ret.data.status === 200) {

                secureLocalStorage.setItem('t', ret.data.data);
                secureLocalStorage.setItem('acp', ret.data.decodedToken.acp_integrationid);
                secureLocalStorage.setItem('n', ret.data.decodedToken.name);
                secureLocalStorage.setItem('iag', ret.data.decodedToken.id);
                secureLocalStorage.setItem('cy', ret.data.decodedToken.company);
                
                GetMarca(props.manufacturer);

                GetModelo(props.brand);

            }
        } catch (e: any) {

            if (e.response.data.status === 403) {

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
                    <div className='btn-alert' onClick={fecharModalToken}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)

            }
        }

    }

    function fecharModalToken() {

        secureLocalStorage.removeItem('t')
        secureLocalStorage.removeItem('acp')
        secureLocalStorage.removeItem('n')
        secureLocalStorage.removeItem('iag')
        navigate('/login');
        setOpenModal(false)

    }

    async function GetModelo(marca: any) {
        let ret: AxiosResponse;
        let valor: any;

        try {
            ret = await api.get("model/verificacaomodel", {
                params: {
                    id: marca
                }
            })
            if (ret.data.status === 200) {
                valor = ret.data.data;

                setOptionsModel(valor)
            }
        } catch (e) {

            setOptionsModel([])

        }

    }

  async  function ConfirmarFabricante() {


        let ret: AxiosResponse;
        let valor: any;

        setOpenModal(true)
        setQualAlert(<div className='div-alert'><Loading /></div>)

        const _user = secureLocalStorage.getItem('t')

        api.defaults.headers.common['Authorization'] = _user as ''

        try {

            ret = await api.get("/status/validar")

            if (ret.data.status === 200) {

                if (id === undefined || id === '') {
                    insertMarca();
        
                } else {
                    UpdateModelo();
        
                }
            }

        } catch (e: any) {

            if (e.response.data.status === 403) {

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
                    <div className='btn-alert' onClick={fecharModalToken}><span className="btn-alert-lab">OK</span></div>
                </div>)


            }
        }

        
    }

    function fecharModal() {
        setOpenModal(false)
    }

    async function UpdateModelo() {
        let ret: AxiosResponse;


        const FiltrarDescricao = optionsModel.filter(
            (e: any) => {
                return e.mde_description.toLowerCase() === description.toLowerCase()
            }
        );

        const FiltrarAlternativeIdentifier = optionsModel.filter(
            (e: any) => {
                return e.mde_alternativeidentifier.toLowerCase() === alternativeIdentifier.toLowerCase()
            }
        );

        if (descriptionIni.toLowerCase() !== description.toLowerCase() && FiltrarDescricao.length > 0) {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Descrição já está em uso.</span></div>
                <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
            </div>)

        } else if (alternativeIdentifierIni.toLowerCase() !== alternativeIdentifier.toLowerCase() && FiltrarAlternativeIdentifier.length > 0) {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Identificador alternativo já está em uso.</span></div>
                <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
            </div>)

        } else

            if (description === "") {
                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Digite a descrição.</span></div>
                    <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                </div>)
            } else
                if (alternativeIdentifier === "") {
                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Digite o identificador alternativo.</span></div>
                        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                    </div>)
                } else
                    if (manufacturer === "" || manufacturer === "Selecione...") {

                        setQualAlert(<div className='div-alert'>
                            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                            <div className='div-info-alert'><span className="info-alert">Selecione o fabricante.</span></div>
                            <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                        </div>)


                    } else
                        if (brand === "" || brand === "Selecione...") {

                            setQualAlert(<div className='div-alert'>
                                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                                <div className='div-info-alert'><span className="info-alert">Selecione a marca.</span></div>
                                <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                            </div>)

                        } else {
                            const dados = {
                                mde_id: id,
                                mde_active: active,
                                mde_description: description,
                                mde_alternativeidentifier: alternativeIdentifier,
                                brn_id: brand,
                                mfr_id: manufacturer
                            }

                            try {
                                ret = await api.put("model/update", {
                                    data: dados,
                                });
                                if (ret.data.status === 200) {
                                    props.voltar()
                                }
                            } catch (e: any) {
                                setQualAlert(<div className='div-alert'>
                                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                                <div className='div-info-alert'><span className="info-alert">Não foi possível concluir a alteração.</span></div>
                                <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                            </div>)
                            }
                        }

    }

    async function insertMarca() {
        let ret: AxiosResponse;

        const FiltrarDescricao = optionsModel.filter(
            (e: any) => {
                return e.mde_description.toLowerCase() === description.toLowerCase()
            }
        );

        const FiltrarAlternativeIdentifier = optionsModel.filter(
            (e: any) => {
                return e.mde_alternativeidentifier.toLowerCase() === alternativeIdentifier.toLowerCase()
            }
        );

        if (FiltrarDescricao.length > 0) {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Descrição já está em uso.</span></div>
                <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
            </div>)

        } else if (FiltrarAlternativeIdentifier.length > 0) {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Identificador alternativo já está em uso.</span></div>
                <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
            </div>)

        } else
            if (description === "") {
                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Digite a descrição.</span></div>
                    <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                </div>)
            } else
                if (alternativeIdentifier === "") {
                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Digite o identificador alternativo.</span></div>
                        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                    </div>)
                } else
                    if (manufacturer === "" || manufacturer === "Selecione...") {

                        setQualAlert(<div className='div-alert'>
                            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                            <div className='div-info-alert'><span className="info-alert">Selecione o fabricante.</span></div>
                            <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                        </div>)

                    } else
                        if (brand === "" || brand === "Selecione...") {

                            setQualAlert(<div className='div-alert'>
                                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                                <div className='div-info-alert'><span className="info-alert">Selecione a marca.</span></div>
                                <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                            </div>)

                        } else {
                            const dados = [{
                                mde_active: active,
                                mde_description: description,
                                mde_alternativeidentifier: alternativeIdentifier,
                                brn_id: brand,
                                mfr_id: manufacturer
                            }]

                            try {
                                ret = await api.post("model/insert", {
                                    data: dados,
                                });
                                if (ret.data.status === 200) {
                                    props.voltar();
                                }
                            } catch (e: any) {
                                setQualAlert(<div className='div-alert'>
                                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                                <div className='div-info-alert'><span className="info-alert">Não foi possível concluir o cadastramento.</span></div>
                                <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                            </div>)
                            }
                        }
    }


    async function GetFabricante() {
        let ret: AxiosResponse;
        let valor: any;
        try {
            ret = await api.get("manufacturer/listar")
            if (ret.data.status === 200) {
                valor = ret.data.data;

                const arr = [] as any;
                let result = valor;
                result.map((user: any) => {

                    return arr.push({ value: String(user.mfr_id), label: user.mfr_description });
                });

                setDadosFabricante(arr);
            }
        } catch (e) { }
    }



    async function GetMarca(Fabricante: any) {
        setManufacturer(String(Fabricante))
        let ret: AxiosResponse;
        let valor: any;
        try {
            ret = await api.get("brand/listar", {
                params: {
                    id: Fabricante
                }
            })
            if (ret.data.status === 200) {
                valor = ret.data.data;
                const arr = [] as any;
                let result = valor;
                result.map((user: any) => {
                    return arr.push({ value: String(user.brn_id), label: user.brn_description });
                });

                setDadosMarca(arr)
            }
        } catch (e) { }
    }

    async function GetMarcaSel(Fabricante: any) {

        if(Fabricante === ''){
            setDadosMarca([])
            setManufacturer(String(Fabricante))
        }else{
        setDadosMarca([])
        setManufacturer(String(Fabricante))
        setBrand("")
        let ret: AxiosResponse;
        let valor: any;
        try {
            ret = await api.get("brand/listar", {
                params: {
                    id: Fabricante
                }
            })
            if (ret.data.status === 200) {
              
                valor = ret.data.data;

                const arr = [] as any;
                let result = valor;
                result.map((user: any) => {
                    return arr.push({ value: String(user.brn_id), label: user.brn_description });
                });

                setDadosMarca(arr)
                
            }
        } catch (e) {
            setDadosMarca([])
         }
    }
    }


    const renderMarca = dadosMarca.map((comp: any) => {
        return (
            <option key={comp.brn_id} value={comp.brn_id}>
                {" "}
                {comp.brn_description}{" "}
            </option>
        );
    });


    function ValitarActive() {
        let e = document.getElementById("checkbox-active-mod") as HTMLInputElement;

        if (e.checked) {

            setActive(true)
        } else {

            setActive(false)
        }
    }

    const customStyles = {
        control: (provided: any, state: any) => ({
            ...provided,
            background: '#fff',
            minHeight: '30px',
            height: '30px',
            borderColor: state.isFocused ? 'grey' : '#92989e',

        }),


        valueContainer: (provided: any, state: any) => ({
            ...provided,
            height: '30px',
            padding: '0 6px'
        }),

        input: (provided: any, state: any) => ({
            ...provided,
            margin: '0px',
            fontSize: 14,

        }),
        indicatorsContainer: (provided: any, state: any) => ({
            ...provided,
            height: '30px',
        }),
    };

    return (
        <>
            <div className="container-modal-pric">
                <div className="class-checkbox">
                    <input type="checkbox" id="checkbox-active-mod" checked={active} onChange={ValitarActive} />
                    <h4 className="lab-ativo">Ativo</h4>
                </div>
                <div className="container-cad-prod4">
                    <div id="content1">
                        <div className="input-box-ativos">
                            <span className="details">Descrição *</span>
                            <input
                                type="text"
                                autoComplete="off"
                                onChange={(e) => setDescription(e.target.value)}
                                value={description} />
                        </div>
                    </div>
                    <div id="content2">
                        <div className="input-box-ativos">
                            <span className="details">Identificador Alternativo *</span>
                            <input
                                type="text"
                                autoComplete="off"
                                onChange={(e) => setAlternativeIdentifier(e.target.value)}
                                value={alternativeIdentifier} />
                        </div>
                    </div>
                </div>

                <div className="container-cad-prod4">
                    <div id="content1">
                        <div className="select-box-ativos-select">
                            <div className='details-flex'>
                                <span className="details">Fabricante *</span>

                            </div>
                            <Select
                                maxMenuHeight={70}
                                placeholder={'Selecione...'}
                                options={dadosFabricante}
                                styles={customStyles}
                                value={dadosFabricante.filter(function (option: any) {
                                    return option.value === manufacturer;
                                })}
                                onChange={(options: any) =>
                                    !options ? GetMarcaSel("") : GetMarcaSel(options.value)
                                }

                                isClearable
                                noOptionsMessage={() => "Não encontrado..."}
                                theme={(theme: any) => ({
                                    ...theme,
                                    borderRadius: 4,
                                    colors: {
                                        ...theme.colors,
                                        primary25: '#ebebeb',
                                        primary: 'black',
                                    },
                                })}
                                isDisabled={disabledManufacturer}
                            />
                        </div>
                    </div>
                    <div id="content2">
                        <div className="select-box-ativos-select">
                            <div className='details-flex'>
                                <span className="details">Marca *</span>

                            </div>
                            <Select
                                maxMenuHeight={70}
                                placeholder={'Selecione...'}
                                options={dadosMarca}
                                styles={customStyles}
                                value={dadosMarca.filter(function (option: any) {
                                    return option.value === brand;
                                })}
                                onChange={(options: any) =>
                                    !options ? setBrand("") : setBrand(options.value)
                                }

                                isClearable
                                noOptionsMessage={() => "Não encontrado..."}
                                theme={(theme: any) => ({
                                    ...theme,
                                    borderRadius: 4,
                                    colors: {
                                        ...theme.colors,
                                        primary25: '#ebebeb',
                                        primary: 'black',
                                    },
                                })}
                                isDisabled={disabledManufacturer}
                            />
                        </div>
                    </div>
                </div>

                <div className="Bt-button-cad">
                    <button type="submit" onClick={ConfirmarFabricante}>
                        <i className="fa fa-check-circle icon-ok-btn"></i> Salvar
                    </button>
                    <button type="submit" onClick={props.voltar}>
                        <i className="fa fa-times icon-cancelar-btn"></i> Cancelar
                    </button>
                </div>
            </div>

            <ModalAlert isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)}>
                <div className='container-modal-alert'>
                    {qualAlert}
                </div>
            </ModalAlert>
        </>
    );

}

export default FormModelo;