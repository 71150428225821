import React, { useState, useEffect } from 'react'
import './index.css'
import { AxiosResponse } from "axios";
import api from '../../Services/api'
import ModalAlert from '../../components/ModalAlert/modal'
import secureLocalStorage from "react-secure-storage";
import Loading from '../../components/Loading';
import { useNavigate } from 'react-router';

function FormFabricantes(props: any) {

    const navigate = useNavigate();
    const dadosFab = (sessionStorage.getItem("dadosFab") || '{}');
    let userFab = JSON.parse(dadosFab);
    const [id, setId] = useState(userFab.mfr_id || '')
    const [active, setActive] = useState(userFab.mfr_active != undefined ? Boolean(userFab.mfr_active) : true)
    const [description, setDescription] = useState(userFab.mfr_description || '')
    const [alternativeIdentifier, setAlternativeIdentifier] = useState(userFab.mfr_alternativeidentifier || '')
    const [descriptionIni, setDescriptionIni] = useState(userFab.mfr_description || '')
    const [alternativeIdentifierIni, setAlternativeIdentifierIni] = useState(userFab.mfr_alternativeidentifier || '')

    const [openModal, setOpenModal] = useState(false)
    const [qualAlert, setQualAlert] = useState(<></>)

    const [optionsManufacturer, setOptionsManufacturer] = useState([])

    useEffect(() => {

            validatToken();
    }, []);

    async function validatToken() {

		let ret: AxiosResponse;
		let valor: any;

		const _user = secureLocalStorage.getItem('t')

		api.defaults.headers.common['Authorization'] = _user as ''

		try {

			ret = await api.get("/status/validar")

			if (ret.data.status === 200) {

				secureLocalStorage.setItem('t', ret.data.data);
				secureLocalStorage.setItem('acp', ret.data.decodedToken.acp_integrationid);
				secureLocalStorage.setItem('n', ret.data.decodedToken.name);
				secureLocalStorage.setItem('iag', ret.data.decodedToken.id);
				secureLocalStorage.setItem('cy', ret.data.decodedToken.company);

                GetFabricante()

            }
		} catch (e: any) {

			if (e.response.data.status === 403) {

				setQualAlert(<div className='div-alert'>
					<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
					<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
					<div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
					<div className='btn-alert' onClick={fecharModalToken}><span className="btn-alert-lab">OK</span></div>
				</div>)

				setOpenModal(true)

			}
		}

	}


    async function GetFabricante() {
        let ret: AxiosResponse;
        let valor: any;
    
        try {
            ret = await api.get("manufacturer/verificacaomanufacturer")
            if (ret.data.status === 200) {
                valor = ret.data.data;

                setOptionsManufacturer(valor)
           
            }
        } catch (e) {
            setOptionsManufacturer([])
          
        }
    }

    async function ConfirmarFabricante(){

        let ret: AxiosResponse;
        let valor: any;

        setOpenModal(true)
        setQualAlert(<div className='div-alert'><Loading /></div>)

        const _user = secureLocalStorage.getItem('t')

        api.defaults.headers.common['Authorization'] = _user as ''

        try {

            ret = await api.get("/status/validar")

            if (ret.data.status === 200) {
                if (id === undefined || id === '') {
                    insertFabricante();

                } else {
                    UpdateFabricante();
                }
            }

        } catch (e: any) {

            if (e.response.data.status === 403) {

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
                    <div className='btn-alert' onClick={fecharModalToken}><span className="btn-alert-lab">OK</span></div>
                </div>)


            }
        }

    }

    function fecharModalToken() {

        secureLocalStorage.removeItem('t')
        secureLocalStorage.removeItem('acp')
        secureLocalStorage.removeItem('n')
        secureLocalStorage.removeItem('iag')
        navigate('/login');
        setOpenModal(false)

    }

    async function UpdateFabricante() {
        let ret: AxiosResponse;

        const FiltrarDescricao = optionsManufacturer.filter(
            (e: any) => {
                return e.mfr_description.toLowerCase() === description.toLowerCase()
            }
        );

        const FiltrarAlternativeIdentifier = optionsManufacturer.filter(
            (e: any) => {
                return e.mfr_alternativeidentifier.toLowerCase() === alternativeIdentifier.toLowerCase()
            }
        );

        if (descriptionIni.toLowerCase() !== description.toLowerCase() && FiltrarDescricao.length > 0) {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Descrição já está em uso.</span></div>
                <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
            </div>)

        } else if (alternativeIdentifierIni.toLowerCase() !== alternativeIdentifier.toLowerCase() && FiltrarAlternativeIdentifier.length > 0) {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Identificador alternativo já está em uso.</span></div>
                <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
            </div>)

        } else
        if (description === "") {
            setQualAlert(<div className='div-alert'>
            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
            <div className='div-info-alert'><span className="info-alert">Digite a descrição.</span></div>
            <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
        </div>)
        } else
            if (alternativeIdentifier === "") {
                setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Digite o identificador alternativo.</span></div>
                        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                    </div>)

            } else{
        const dados = {
            mfr_id: id,
            mfr_active: active,
            mfr_description: description,
            mfr_alternativeidentifier: alternativeIdentifier,
        }
        try {
            ret = await api.put("manufacturer/update", {
                data: dados,
            });
            if (ret.data.status === 200) {
                props.voltar()
            }
        } catch (e: any) {
            setQualAlert(<div className='div-alert'>
            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
            <div className='div-info-alert'><span className="info-alert">Não foi possível concluir a alteração.</span></div>
            <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
        </div>)

        }
    }

    }

    async function insertFabricante() {
        let ret: AxiosResponse;

        
        const FiltrarDescricao = optionsManufacturer.filter(
            (e: any) => {
                return e.mfr_description.toLowerCase() === description.toLowerCase()
            }
        );

        const FiltrarAlternativeIdentifier = optionsManufacturer.filter(
            (e: any) => {
                return e.mfr_alternativeidentifier.toLowerCase() === alternativeIdentifier.toLowerCase()
            }
        );

        if (FiltrarDescricao.length > 0) {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Descrição já está em uso.</span></div>
                <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
            </div>)

        } else if (FiltrarAlternativeIdentifier.length > 0) {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Identificador alternativo já está em uso.</span></div>
                <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
            </div>)

        } else

        if (description === "") {
            setQualAlert(<div className='div-alert'>
            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
            <div className='div-info-alert'><span className="info-alert">Digite a descrição.</span></div>
            <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
        </div>)

        } else
            if (alternativeIdentifier === "") {
                setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Digite o identificador alternativo.</span></div>
                        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                    </div>)


            } else{
        const dados = [{
            mfr_active: active,
            mfr_description: description,
            mfr_alternativeidentifier: alternativeIdentifier,
        }]

        try {
            ret = await api.post("manufacturer/insert", {
                data: dados,
            });

            if (ret.data.status === 200) {
                props.voltar()
            }
        } catch (e: any) {
            setQualAlert(<div className='div-alert'>
            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
            <div className='div-info-alert'><span className="info-alert">Não foi possivel concluir o cadastramento.</span></div>
            <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
        </div>)

        setOpenModal(true)
        }
    }
    }

    
    function fecharModal() {
        setOpenModal(false)
    }
    
    function ValitarActive() {
        let e = document.getElementById("checkbox-active-fab") as HTMLInputElement;

        if (e.checked) {

            setActive(true)
        } else {

            setActive(false)
        }
    }

    function voltar() {

        props.voltar();

    }

    return (
        <>
            <div className="container-modal-pric">
                <div className="class-checkbox">
                    <input type="checkbox" id="checkbox-active-fab" checked={active} onChange={ValitarActive} />
                    <h4 className="lab-ativo">Ativo</h4>
                </div>
                <div className="container-cad-prod4">
                    <div id="content1">
                        <div className="input-box-ativos">
                            <span className="details">Descrição *</span>
                            <input
                                type="text"
                                onChange={(e) => setDescription(e.target.value)}
                                value={description}
                                autoComplete="off"
                            />
                        </div>
                    </div>
                    <div id="content2">
                        <div className="input-box-ativos">
                            <span className="details">Identificador Alternativo *</span>
                            <input
                                autoComplete="off"
                                type="text"
                                onChange={(e) => setAlternativeIdentifier(e.target.value)}
                                value={alternativeIdentifier} />
                        </div>
                    </div>
                </div>
                <div className="Bt-button-cad">
                    <button type="submit" onClick={ConfirmarFabricante}>
                        <i className="fa fa-check-circle icon-ok-btn"></i> Salvar
                    </button>
                    <button type="submit" onClick={props.voltar}>
                        <i className="fa fa-times icon-cancelar-btn"></i> Cancelar
                    </button>
                </div>
            </div>

            
            <ModalAlert isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)}>
                <div className='container-modal-alert'>
                    {qualAlert}
                </div>
            </ModalAlert>
        </>
    );

}

export default FormFabricantes;