import { Container, Tag, Title, ContainerRespondido } from './styles';
import React, { useState, useEffect } from 'react';
import { FcFinePrint } from "react-icons/fc";
import { AxiosResponse } from "axios";
import api from "../../Services/api";
import moment from 'moment';

interface IListagemProps {
  data: any,
  cor: string,
  func: any
}

const Listagem: React.FC<IListagemProps> = ({
  data,
  cor,
  func
}) => {


  return (
    <>
      {data.map((tdata: any, i: any) => (
        <Container color={cor} key={tdata.tck_id} onClick={() => func(tdata)}>
          <div>
            <Title>
              <div>

              {tdata.ast_description !== null ? (<>
                <h1 className='title-list'>#{tdata.tck_id} {tdata.ast_description}</h1>
                </>) :  
                <h1 className='title-list'>#{tdata.tck_id} {tdata.loc_description}</h1>
                }

                <div className='ticket-data'>
                  <span className='assunt-list'>Data: {tdata.tck_datetimeopening?.substring(8, 10)}/{tdata.tck_datetimeopening?.substring(5, 7)}/{tdata.tck_datetimeopening?.substring(0, 4)}</span>
                  <span className='ticket-hora'>Hora: {tdata.tck_datetimeopening?.substring(11, 16)}</span>
                </div>

                <h1 className='assunt-list'>Problema: {tdata.tck_message?.substring(0, 200) + '...'}</h1>
              </div>
            </Title>
          </div>
        </Container>
      ))}

    </>
  );
}

export default Listagem;