import React, { PureComponent, useState } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
import "./index.css";
import api from "../../Services/api";
import { FcSearch, FcPlus, FcFilledFilter } from "react-icons/fc";
import { AxiosResponse } from "axios";
import { Link } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import Select from 'react-select'
import { FaEraser, FaSearch, FaPen, FaTrash } from "react-icons/fa";
import { FaListAlt } from "react-icons/fa";
import Loading from '../../components/Loading';
import ModalPrincipal from '../../components/ModalAlert/modal'

const moment = require('moment');

export class Child extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true, 
      offset: 0,
      tableData: [],
      orgtableData: [],
      perPage: 10,
      currentPage: 0,
      pagAtual: 1,
      length: 0,
      endpoint: this.props.endPoint,
      clicked: false,
      atualiza: 0,
      search: '',
      isChecked: [],
      consult: this.props.consult,
      forcePage: 0,
      active: '',
      description: '',
      alternativeidentifier: '',
      clicked: false,
      token: false,
      Nacesso: false,
    };

    this.handlePageClick = this.handlePageClick.bind(this);
  }

  componentDidMount() {

    this.getData();
    this.setState({ active: '1' });

  }

  getData() {

    this.setState({ isLoading: true });

    let dados = [];

    if (this.props.pag === "ok") {

      dados.push({
        active: '1',
        description: '',
        alternativeidentifier: '',
        params: this.props.params
      })


      const _user = secureLocalStorage.getItem('t')

      api.defaults.headers.common['Authorization'] = _user


      api.get(this.props.endPoint, {
        params: {
          data: dados
        }
      }).then((res) => {
        const data = res.data.data || [];

        if (res.data.status === 200) {
          const slice = data.slice(
            this.state.offset,
            this.state.offset + this.state.perPage
          );

          this.setState({
            pageCount: Math.ceil(data.length / this.state.perPage),
            orgtableData: data,
            tableData: slice,
            dataT: data,
            length: data.length,
          });
        } else {
          const slice = data.slice(
            this.state.offset,
            this.state.offset + this.state.perPage
          );

          this.setState({
            pageCount: Math.ceil(data.length / this.state.perPage),
            orgtableData: [],
            tableData: slice,
            dataT: [],
            length: data.length,
          });
        }

        this.setState({
                    
          isLoading: false,
        });

      }).catch((e) => {
     

        const data = [];
        const slice = data.slice(
          this.state.offset,
          this.state.offset + this.state.perPage
        );

        this.setState({
          pageCount: Math.ceil(data.length / this.state.perPage),
          orgtableData: [],
          tableData: slice,
          dataT: [],
          length: data.length,
        });

        if (!e.response) {

          this.setState({ isLoading: false });
          this.setState({ Nacesso: true });

        } else if (e.response.status === 403) {
        
          this.setState({ isLoading: false });
          this.setState({ token: true });

        } else {
          
          this.setState({ isLoading: false });
          this.setState({ Nacesso: true });

        }

        this.setState({
                    
          isLoading: false,
        });

      });

    }

  }


  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
        pagAtual: selectedPage + 1,
        forcePage: selectedPage
      },
      () => {
        this.loadMoreData();
      }
    );


  };

  loadMoreData() {

    const data = this.state.dataT;

    const slice = data.slice(
      this.state.offset,
      this.state.offset + this.state.perPage,

    );


    //////////////////////aqui
    this.setState({
      pageCount: Math.ceil(data.length / this.state.perPage),
      tableData: slice,
      length: data.length,
    });

  }


  handlePageClick_clear = () => {
    const selectedPage = 0;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
        pagAtual: selectedPage + 1,
        forcePage: selectedPage,
      },
      () => {
        this.loadMoreData();
      }
    );


  };


  handleInputChange = (event) => {
    this.setState({ search: event.target.value });
  };

  do_search = () => {


    this.setState({ isLoading: true });

    let dados = [];

    this.handlePageClick_clear()

    if (this.props.pag === "ok") {

      dados.push({
        active: this.state.active,
        description: this.state.description,
        alternativeidentifier: this.state.alternativeidentifier,
        search: this.state.search,
        params: this.props.params
      })


      api.get(this.props.endPoint, {
        params: {
          data: dados
        }
      }).then((res) => {

        var data = res.data.data;

        if (res.data.status === 200) {

          var slice = data.slice(
            this.state.offset,
            this.state.offset + this.state.perPage
          );

          this.setState({
            pageCount: Math.ceil(data.length / this.state.perPage),
            orgtableData: res.data.data,
            tableData: slice,
            dataT: res.data.data,
            length: data.length,
          });

        }

        this.setState({          
          isLoading: false,
        });
      

      })
        .catch(function (e) {

          const data = [];
          const slice = data.slice(
            this.state.offset,
            this.state.offset + this.state.perPage
          );
  
          this.setState({
            pageCount: Math.ceil(data.length / this.state.perPage),
            orgtableData: [],
            tableData: slice,
            dataT: [],
            length: data.length,
          });

          if (!e.response) {

            this.setState({ isLoading: false });
            this.setState({ Nacesso: true });

          } else if (e.response.status === 403) {
          
            this.setState({ isLoading: false });
            this.setState({ token: true });

          } else {
            
            this.setState({ isLoading: false });
            this.setState({ Nacesso: true });

          }
          
        });

    }
  };

  clear_search = () => {


    this.getData();
    this.setState({
      active: '1',
      clicked: false,
      description: '',
      alternativeidentifier: '',
      search: ''
    });
  };


  toggle = (index) => {
    if (this.state.clicked === index) {
      //if clicked question is already active, then close it
      return this.setState({ clicked: null });
    }
    this.setState({ clicked: index });
  };

  handlecheckbox = (e) => {
    const { value, checked } = e.target;

    if (checked) {

      this.setState({
        isChecked: ([...this.state.isChecked, value])
      });

    } else {

      this.setState({
        isChecked: (this.state.isChecked.filter((e) => e !== value))
      });

    }

  }

  chamarCust = (valor) => {
    this.props.funtoggle(valor, this.props.form);

  }

  exibirFiltro = () => {


    if (this.state.clicked === true) {
      this.setState({
        clicked: false
      });
    } else {
      this.setState({
        clicked: true
      });
    }

  }

  handleInputChangeAtivo = (event) => {


    this.setState({ active: event.target.value });


  };

  funcNsolicitacao = () => {
    this.setState({ Nacesso: false });
    }

  render() {

    const customStyles = {
      control: (provided, state) => ({
        ...provided,
        background: '#fff',
        minHeight: '30px',
        height: '30px',
        borderColor: state.isFocused ? 'grey' : '#92989e',

      }),


      valueContainer: (provided, state) => ({
        ...provided,
        height: '30px',
        padding: '0 6px'
      }),

      input: (provided, state) => ({
        ...provided,
        margin: '0px',
        fontSize: 14,

      }),
      indicatorsContainer: (provided, state) => ({
        ...provided,
        height: '30px',
      }),
    };

    return (
      <div className="ContainerGrid">
        <div className="ContainerBody">
          <div className="InputBusca2">
            <input
              className="inputBuscaRep"
              autoComplete="off"
              placeholder="Pesquisar..."
              onChange={this.handleInputChange}
              value={this.state.search}
            />

            <div className="icone-buscaT" onClick={this.do_search}>
              <FcSearch className="icone-add" />
            </div>

            <div className="icone-buscaT" onClick={this.clear_search}>
              <FaEraser className="icone-add" />
            </div>

            <div className="icone-buscaT" onClick={this.exibirFiltro}>
              <FcFilledFilter className="icone-add" />
            </div>

          </div>

          {secureLocalStorage.getItem('acp') === 'DESENVOLVEDOR' || secureLocalStorage.getItem('acp') === 'ADMINISTRADOR' ? (
          <span className="icone-buscaAdd" onClick={() => {this.props.ChamarCadastro()}}>
            <FcPlus className="icone-add" />
          </span>
 ) : null}

        </div>

        <div className="ContainerDropdown">
          <>

            {this.state.clicked === true ? (

              <div className="container-Dropdown-busca">
                <div className="container-cad-prod33">
                  <div id="content1">
                    <div className="input-box-ativos">
                      <span className="details">Ativo</span>
                      <select id="SELCAT" name="SELCAT" key='CAT0'
                        onChange={this.handleInputChangeAtivo}
                        value={this.state.active}>
                        <option value="">Todos</option>
                        <option value="1">Sim</option>
                        <option value="0">Não</option>
                      </select>
                    </div>
                  </div>

                  <div id="content2">
                    <div className="input-box-ativos">
                      <span className="details">Descrição</span>
                      <input
                        type="text"
                        onChange={(e) => this.setState({ description: e.target.value })}
                        value={this.state.description}
                      />
                    </div>
                  </div>

                  <div id="content3">
                    <div className="input-box-ativos">
                      <span className="details">Identificador alternativo</span>
                      <input
                        type="text"
                        onChange={(e) => this.setState({ alternativeidentifier: e.target.value })}
                        value={this.state.alternativeidentifier}
                      />
                    </div>
                  </div>
                </div>
              </div>

            ) : null}


          </>
        </div>



        {this.state.pageCount > 0 ? (
          <div className="grid-tab-user">
            <table>
              <tbody>
                <tr id="grid-header">
                  <th className="Title">{this.props.th1}</th>
                  <th className="Title">{this.props.th2}</th>
                  <th className="Title">Ações</th>
                </tr>
              </tbody>
              <tbody>
                {this.state.tableData.map((tdata, i) => (
                  <tr className="TR-Coluna" key={Object.values(tdata)[0]}>

                    <td className="Coluna-padrao-sec-desc2" onClick={() => { this.props.PegarValorGrid(Object(tdata)) }}>{Object.values(tdata)[this.props.col2]}</td>
                    <td className="Coluna-padrao-sec-desc2" onClick={() => { this.props.PegarValorGrid(Object(tdata)) }}>{Object.values(tdata)[this.props.col3]}</td>


                    <td className="Coluna-icone-btn2">
                      
                        <button className="btn-icon edit-btn" onClick={() => { this.props.IconeEditar(Object(tdata)) }}>
                          <FaPen />
                        </button>
                    

                        {this.props.Visualizar === 'S' ? (
                        <button className="btn-icon view-btn" onClick={() => { this.props.ChamarModalCad(Object(tdata)) }}>
                          <FaListAlt />
                        </button>
                      ) : null}

                     
                        
                        {this.props.btnDelete === 'S' ? (
                           <button className="btn-icon delete-btn" onClick={() => { this.props.IconeExcluir(Object(tdata)) }}>
                            <i className="fa fa-trash-alt" ></i>
                         </button>
                         ):                     
                       
                         null
                        }
                   
                 
                    </td>
                    

                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : null}
        <div className="ContainerPag">
          <div className="InlineContainery">

            {this.state.pageCount === null || this.state.pageCount === 0 ? (
              <div className="SemResut">
                <h2>
                  Nenhum resultado encontrado.
                </h2>
                <h2>
                  Página 1 de 1
                </h2>
              </div>
            ) : null}

            <div className="InputBusca">
              {this.state.pageCount > 0 ? (
                <div className="Quant">
                  <h2>
                    Página {this.state.pagAtual} de {this.state.pageCount}
                  </h2>
                </div>
              ) : null}

              {this.state.pageCount > 1 ? (
                <ReactPaginate
                  previousLabel={<i className="fas fa-angle-left"></i>}
                  nextLabel={<i className="fas fa-angle-right"></i>}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.state.pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                  forcePage={this.state.forcePage}
                />
              ) : null}
            </div>
          </div>
          {this.state.pageCount > 0 ? (
            <div className="paginate">
              <h2>Total de registros: {this.state.length}</h2>
            </div>
          ) : null}
        </div>

        <ModalPrincipal isOpen={this.state.isLoading}>
        <div className='container-modal-alert'>
          <div className='div-alert'><Loading /></div>
        </div>
      </ModalPrincipal>
      
      <ModalPrincipal isOpen={this.state.token}>
          <div className='container-modal-alert'>
            <div className='div-alert'>
              <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
              <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
              <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
              <div className='btn-alert' onClick={this.props.fecharModal}><span className="btn-alert-lab">OK</span></div>
            </div>
          </div>
        </ModalPrincipal>

        <ModalPrincipal isOpen={this.state.Nacesso}>
          <div className='container-modal-alert'>
            <div className='div-alert'>
              <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
              <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
              <div className='div-info-alert'><span className="info-alert">Não foi possível concluir sua solicitação.</span></div>
              <div className='btn-alert' onClick={this.funcNsolicitacao}><span className="btn-alert-lab">OK</span></div>
            </div>
          </div>
        </ModalPrincipal>

      </div>
    );
  }
}

export default Child;
