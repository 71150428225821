import React, { useState, useEffect } from 'react'
import './index.css'
import { AxiosResponse } from "axios";
import api from '../../Services/api'
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router';

function MobileForm(props: any) {

    const navigate = useNavigate();

    const [drums, setDrums] = useState(props.data.pdc_drums || '')
    const [ram, setRam] = useState(props.data.pdc_ram || '')
    const [storage, setStorage] = useState(props.data.pdc_storage || '')
    const [screenSize, setScreenSize] = useState(props.data.pdc_screensize || '')
    const [chamber, setChamber] = useState(props.data.pdc_chamber || '')
    const [processor, setProcessor] = useState(props.data.pdc_processor || '')
    const [color, setColor] = useState(props.data.pdc_color || '')

    useEffect(() => {

     

            
        if (props.data.pdc_id !== undefined || props.data.pdc_id !== '') {
            props.test({
                pdc_processor: props.data.pdc_processor,
                pdc_drums: props.data.pdc_drums,
                pdc_ram: props.data.pdc_ram,
                pdc_storage: props.data.pdc_storage,
                pdc_screensize: props.data.pdc_screensize,
                pdc_chamber: props.data.pdc_chamber,
                pdc_color: props.data.pdc_color
            })
        }

        let disabledprocessor = document.getElementById("processor") as HTMLInputElement;
        let disableddrums = document.getElementById("drums") as HTMLInputElement;
        let disabledram = document.getElementById("ram") as HTMLInputElement;
        let disabledstorage = document.getElementById("storage") as HTMLInputElement;
        let disabledscreenSize = document.getElementById("screenSize") as HTMLInputElement;
        let disabledchamber = document.getElementById("chamber") as HTMLInputElement;
        let disabledcolor = document.getElementById("color") as HTMLInputElement;

        if (props.disabled === 'S') {
            disabledprocessor.disabled = true;
            disableddrums.disabled = true;
            disabledram.disabled = true;
            disabledstorage.disabled = true;
            disabledscreenSize.disabled = true;
            disabledchamber.disabled = true;
            disabledcolor.disabled = true;
        }

    }, []);


    function incluirColor(data: any) {
        setColor(data)
        props.test({
            pdc_processor: processor,
            pdc_drums: drums,
            pdc_ram: ram,
            pdc_storage: storage,
            pdc_screensize: screenSize,
            pdc_chamber: chamber,
            pdc_color: data
        })
    }

    function incluirChamber(data: any) {
        setChamber(data)
        props.test({
            pdc_processor: processor,
            pdc_drums: drums,
            pdc_ram: ram,
            pdc_storage: storage,
            pdc_screensize: screenSize,
            pdc_chamber: data,
            pdc_color: color

        })
    }


    function incluirScreenSize(data: any) {
        setScreenSize(data)
        props.test({

            pdc_processor: processor,
            pdc_drums: drums,
            pdc_ram: ram,
            pdc_storage: storage,
            pdc_screensize: data,
            pdc_chamber: chamber,
            pdc_color: color
        })
    }

    function incluirStorage(data: any) {
        setStorage(data)
        props.test({
            pdc_processor: processor,
            pdc_drums: drums,
            pdc_ram: ram,
            pdc_storage: data,
            pdc_screensize: screenSize,
            pdc_chamber: chamber,
            pdc_color: color
        })
    }


    function incluirRam(data: any) {
        setRam(data)
        props.test({
            pdc_processor: processor,
            pdc_drums: drums,
            pdc_ram: data,
            pdc_storage: storage,
            pdc_screensize: screenSize,
            pdc_chamber: chamber,
            pdc_color: color
        })
    }

    function incluirDrums(data: any) {
        setDrums(data)
        props.test({
            pdc_processor: processor,
            pdc_drums: data,
            pdc_ram: ram,
            pdc_storage: storage,
            pdc_screensize: screenSize,
            pdc_chamber: chamber,
            pdc_color: color
        })
    }


    function incluirProcessor(data: any) {
        setProcessor(data)
        props.test({
            pdc_processor: data,
            pdc_drums: drums,
            pdc_ram: ram,
            pdc_storage: storage,
            pdc_screensize: screenSize,
            pdc_chamber: chamber,
            pdc_color: color
        })
    }

    return (
        <>

            <div className="title-Page">
                <div className="title-text">
                    <div className="size-x circle">2</div>
                    <h2>Detalhes técnicos</h2>
                </div>
                <hr />
            </div>

            <div className="container-cad-prodMobi3">
                <div id="content1">
                    <div className="input-box-ativos">
                        <span className="details">Processador</span>
                        <input
                            id="processor"
                            type="text"
                            onChange={(e) => incluirProcessor(e.target.value)}
                            value={processor}
                            autoComplete="off"
                            disabled={props.acpDisabled}
                        />
                    </div>
                </div>
                <div id="content2">
                    <div className="input-box-ativos">
                        <span className="details">Bateria</span>
                        <input
                            id="drums"
                            type="text"
                            onChange={(e) => incluirDrums(e.target.value)}
                            value={drums}
                            autoComplete="off"
                            disabled={props.acpDisabled}
                        />
                    </div>
                </div>
                <div id="content3">
                    <div className="input-box-ativos">
                        <span className="details">RAM</span>
                        <input
                            id="ram"
                            type="text"
                            onChange={(e) => incluirRam(e.target.value)}
                            value={ram}
                            autoComplete="off"
                            disabled={props.acpDisabled}
                        />
                    </div>
                </div>

            </div>

            <div className="container-cad-prodMobi1">

                <div id="content1">
                    <div className="input-box-ativos">
                        <span className="details">Armazenamento</span>
                        <input
                            id="storage"
                            type="text"
                            onChange={(e) => incluirStorage(e.target.value)}
                            value={storage}
                            autoComplete="off"
                            disabled={props.acpDisabled}
                        />
                    </div>
                </div>
                <div id="content2">
                    <div className="input-box-ativos">
                        <span className="details">Tamanho da Tela</span>
                        <input
                            id="screenSize"
                            type="text"
                            onChange={(e) => incluirScreenSize(e.target.value)}
                            value={screenSize}
                            autoComplete="off"
                            disabled={props.acpDisabled}
                        />
                    </div>
                </div>
                <div id="content3">
                    <div className="input-box-ativos">
                        <span className="details">Câmera</span>
                        <input
                            id="chamber"
                            type="text"
                            onChange={(e) => incluirChamber(e.target.value)}
                            value={chamber}
                            autoComplete="off"
                            disabled={props.acpDisabled}
                        />
                    </div>
                </div>
                <div id="content4">
                    <div className="input-box-ativos">
                        <span className="details">Cor</span>
                        <input
                            id="color"
                            type="text"
                            onChange={(e) => incluirColor(e.target.value)}
                            value={color}
                            autoComplete="off"
                            disabled={props.acpDisabled}
                        />
                    </div>
                </div>
            </div>
        </>
    );

}

export default MobileForm;