import React from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import Aviso01 from '../Aviso01/index';

const data = [
  { month: "Jan", preventivas: 30, corretivas: 20 },
  { month: "Fev", preventivas: 20, corretivas: 25 },
  { month: "Mar", preventivas: 27, corretivas: 22 },
  { month: "Abr", preventivas: 23, corretivas: 28 },
  { month: "Mai", preventivas: 30, corretivas: 18 },
  { month: "Jun", preventivas: 35, corretivas: 25 },
  { month: "Jul", preventivas: 33, corretivas: 30 },
  { month: "Ago", preventivas: 32, corretivas: 27 },
  { month: "Set", preventivas: 25, corretivas: 30 },
  { month: "Out", preventivas: 29, corretivas: 24 },
  { month: "Nov", preventivas: 34, corretivas: 28 },
  { month: "Dez", preventivas: 40, corretivas: 32 },
];


const PreventivasCorretivasChart = (props) => {
  return (

    <div className="featured-ProgressBar">
      <div className="top">
        <h1 className="title">Manutenções Preventivas e Corretivas por Mês</h1>
      </div>

      {props.obj > 0 ? (
        <ResponsiveContainer width="100%" height={400}>
          <LineChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="preventivas" stroke="#3962AC" activeDot={{ r: 8 }} />
            <Line type="monotone" dataKey="corretivas" stroke="#13293D" />
          </LineChart>
        </ResponsiveContainer>
      ) : (
        <Aviso01 />
      )}

    </div>
  );
};

export default PreventivasCorretivasChart;
