import React, { PureComponent, useState } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
import "./index.css";
import api from "../../Services/api";
import { FcSearch, FcPlus, FcFilledFilter } from "react-icons/fc";
import { AxiosResponse } from "axios";
import { Link } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import Select from 'react-select'
import { FaEraser, FaSearch, FaPen, FaTrash } from "react-icons/fa";
import Loading from '../../components/Loading';
import ModalPrincipal from '../../components/ModalAlert/modal'

const moment = require('moment');


export class Child extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      offset: 0,
      tableData: [],
      orgtableData: [],
      perPage: 10,
      currentPage: 0,
      pagAtual: 1,
      length: 0,
      endpoint: this.props.endPoint,
      clicked: false,
      atualiza: 0,
      search: '',
      isChecked: [],
      consult: this.props.consult,
      forcePage: 0,
      optionsAccessProfile: [],
      optionsAgentgroup: [],
      clickeduser: false,
      acp_id: '',
      age_integrationid: '',
      age_name: '',
      age_active: '',
      agg_id: '',
      age_state: '',
      age_city: '',
      age_neighborhood: '',
      age_login: '',
      clickedteam: false,
      optionsteamtype: [],
      tae_active: '',
      tae_integrationid: '',
      tae_description: '',
      tet_id: '',
      clickedmobileactivity: false,
      optionsmobileactivitytype: [],
      act_description: '',
      act_integrationid: '',
      act_active: '',
      aty_id: '',
      ite_active: '',
      ite_description: '',
      ite_alternativeidentifier: '',
      its_id: '',
      optionitemsubgroup: [],
      clickeditem: false,
      pdc_active: '',
      pdc_description: '',
      pdc_alternativeidentifier: '',
      ctg_id: '',
      stg_id: '',
      mfr_id: '',
      brn_id: '',
      mde_id: '',
      clickedproduto: false,
      optionsCategory: [],
      optionSubcategory: [],
      optionsManufacturer: [],
      optionsManufacturerCad: [],
      optionsBrand: [],
      optionsModel: [],
      ast_active: '',
      ast_description: '',
      ast_alternativeidentifier: '',
      ast_serialnumber: '',
      ast_tombamento: '',
      ssa_id: '',
      pdc_id: '',
      ctc_id: '',
      unt_id: '',
      clickedAsset: false,
      optionsStatusasset: [],
      optionsProduct: [],
      optionsContract: [],
      optionsUnity: [],
      clickedLocal: false,
      loc_active: '',
      loc_description: '',
      loc_pavement: '',
      loc_alternativeidentifier: '',
      lty_id: '',
      ctc_id: '',
      unt_id: '',
      loc_email: '',
      optionslocaltype: [],
      svp_active: '',
      svp_corporatename: '',
      svp_cnpj: '',
      svp_state: '',
      clickedserviceproviders: false,
      inc_active: '',
      inc_provider: '',
      inc_cnpj: '',
      inc_alternativeidentifier: '',
      inc_invoicenumber: '',
      inc_issuedate: '',
      inc_accesskey: '',
      clickedinvoice: false,
      pml_active: '',
      pml_description: '',
      pml_alternativeidentifier: '',
      uts_id: '',
      clickedpartmaterial: false,
      optionspartmaterial: [],
      svc_active: '',
      svc_description: '',
      svc_alternativeidentifier: '',
      clickedservices: false,
      token: false,
      Nacesso: false,
    };

    this.handlePageClick = this.handlePageClick.bind(this);
  }

  componentDidMount() {
    this.getData();

    if (this.props.pag === "user") {
      this.setState({ age_active: '1' });
      this.GetAgentGroup();
      this.GetAccessprofile();
    } else if (this.props.pag === "team") {
      this.setState({ tae_active: '1' });
      this.Getteamtype();
    } else if (this.props.pag === "mobileactivity") {
      this.setState({ act_active: '1' });
      this.Getmobileactivitytype();
    } else if (this.props.pag === "item") {
      this.setState({ ite_active: '1' });
      this.Getitemsubgroup();
    } else if (this.props.pag === "produto") {
      this.setState({ pdc_active: '1' });
      this.GetCategory();
      this.GetSubcategory();
      this.GetManufacturer();
      this.GetBrand()
      this.GetModel()
    } else if (this.props.pag === "asset") {
      this.setState({ ast_active: '1' });
      this.GetStatus();
      this.GetContract();
      this.GetUnity();
      this.GetProduct()
    } else if (this.props.pag === "local") {
      this.setState({ loc_active: '1' });
      this.GetLocaltype()
      this.GetContract();
      this.GetUnity();
    } else if (this.props.pag === "serviceproviders") {
      this.setState({ svp_active: '1' });
    } else if (this.props.pag === "invoice") {
      this.setState({ inc_active: '1' });
    } else if (this.props.pag === "partmaterial") {
      this.setState({ pml_active: '1' });
      this.Getunitmeasure()
    } else if (this.props.pag === "services") {
      this.setState({ svc_active: '1' });
    }

  }

  getData() {

    this.setState({ isLoading: true });

    let dados = [];

    if (this.props.pag === "user") {

      dados.push({
        acp_id: '',
        age_integrationid: '',
        age_name: '',
        age_active: '1',
        search: '',
        agg_id: '',
        age_state: '',
        age_city: '',
        age_neighborhood: '',
        age_login: ''
      })

      const _user = secureLocalStorage.getItem('t')

      api.defaults.headers.common['Authorization'] = _user


      api.get(this.props.endPoint, {
        params: {
          data: dados
        }
      }).then((res) => {
        const data = res.data.data || [];

        if (res.data.status === 200) {
          const slice = data.slice(
            this.state.offset,
            this.state.offset + this.state.perPage
          );

          this.setState({
            pageCount: Math.ceil(data.length / this.state.perPage),
            orgtableData: data,
            tableData: slice,
            dataT: data,
            length: data.length,
          });
        } else {
          const slice = data.slice(
            this.state.offset,
            this.state.offset + this.state.perPage
          );

          this.setState({
            pageCount: Math.ceil(data.length / this.state.perPage),
            orgtableData: [],
            tableData: slice,
            dataT: [],
            length: data.length,
          });
        }

        this.setState({

          isLoading: false,
        });

      }).catch((e) => {

        const data = [];
        const slice = data.slice(
          this.state.offset,
          this.state.offset + this.state.perPage
        );

        this.setState({
          pageCount: Math.ceil(data.length / this.state.perPage),
          orgtableData: [],
          tableData: slice,
          dataT: [],
          length: data.length,
        });


        if (!e.response) {

          this.setState({ isLoading: false });
          this.setState({ Nacesso: true });

        } else if (e.response.status === 403) {

          this.setState({ isLoading: false });
          this.setState({ token: true });

        } else {

          this.setState({ isLoading: false });
          this.setState({ Nacesso: true });

        }

        this.setState({

          isLoading: false,
        });

      });

    } else

      if (this.props.pag === "team") {

        dados.push({
          tae_active: '1',
          tae_integrationid: '',
          tae_description: '',
          tet_id: '',
          search: '',
        })

        const _user = secureLocalStorage.getItem('t')

        api.defaults.headers.common['Authorization'] = _user


        api.get(this.props.endPoint, {
          params: {
            data: dados
          }
        }).then((res) => {
          const data = res.data.data || [];

          if (res.data.status === 200) {
            const slice = data.slice(
              this.state.offset,
              this.state.offset + this.state.perPage
            );

            this.setState({
              pageCount: Math.ceil(data.length / this.state.perPage),
              orgtableData: data,
              tableData: slice,
              dataT: data,
              length: data.length,
            });
          } else {
            const slice = data.slice(
              this.state.offset,
              this.state.offset + this.state.perPage
            );

            this.setState({
              pageCount: Math.ceil(data.length / this.state.perPage),
              orgtableData: [],
              tableData: slice,
              dataT: [],
              length: data.length,
            });
          }

          this.setState({

            isLoading: false,
          });

        }).catch((e) => {


          const data = [];
          const slice = data.slice(
            this.state.offset,
            this.state.offset + this.state.perPage
          );

          this.setState({
            pageCount: Math.ceil(data.length / this.state.perPage),
            orgtableData: [],
            tableData: slice,
            dataT: [],
            length: data.length,
          });

          if (!e.response) {

            this.setState({ isLoading: false });
            this.setState({ Nacesso: true });

          } else if (e.response.status === 403) {

            this.setState({ isLoading: false });
            this.setState({ token: true });

          } else {

            this.setState({ isLoading: false });
            this.setState({ Nacesso: true });

          }

          this.setState({

            isLoading: false,
          });

        });
      } else

        if (this.props.pag === "mobileactivity") {

          dados.push({
            act_description: '',
            act_integrationid: '',
            act_active: '1',
            aty_id: '',
            search: ''
          })

          const _user = secureLocalStorage.getItem('t')

          api.defaults.headers.common['Authorization'] = _user


          api.get(this.props.endPoint, {
            params: {
              data: dados
            }
          }).then((res) => {
            const data = res.data.data || [];

            if (res.data.status === 200) {
              const slice = data.slice(
                this.state.offset,
                this.state.offset + this.state.perPage
              );

              this.setState({
                pageCount: Math.ceil(data.length / this.state.perPage),
                orgtableData: data,
                tableData: slice,
                dataT: data,
                length: data.length,
              });
            } else {
              const slice = data.slice(
                this.state.offset,
                this.state.offset + this.state.perPage
              );

              this.setState({
                pageCount: Math.ceil(data.length / this.state.perPage),
                orgtableData: [],
                tableData: slice,
                dataT: [],
                length: data.length,
              });
            }

            this.setState({

              isLoading: false,
            });

          }).catch((e) => {


            const data = [];
            const slice = data.slice(
              this.state.offset,
              this.state.offset + this.state.perPage
            );

            this.setState({
              pageCount: Math.ceil(data.length / this.state.perPage),
              orgtableData: [],
              tableData: slice,
              dataT: [],
              length: data.length,
            });

            if (!e.response) {

              this.setState({ isLoading: false });
              this.setState({ Nacesso: true });

            } else if (e.response.status === 403) {

              this.setState({ isLoading: false });
              this.setState({ token: true });

            } else {

              this.setState({ isLoading: false });
              this.setState({ Nacesso: true });

            }

            this.setState({

              isLoading: false,
            });

          });

        } else

          if (this.props.pag === "item") {


            dados.push({
              ite_active: '1',
              ite_alternativeidentifier: '',
              ite_description: '',
              its_id: '',
              search: '',
            })

            const _user = secureLocalStorage.getItem('t')

            api.defaults.headers.common['Authorization'] = _user


            api.get(this.props.endPoint, {
              params: {
                data: dados
              }
            }).then((res) => {
              const data = res.data.data || [];

              if (res.data.status === 200) {
                const slice = data.slice(
                  this.state.offset,
                  this.state.offset + this.state.perPage
                );

                this.setState({
                  pageCount: Math.ceil(data.length / this.state.perPage),
                  orgtableData: data,
                  tableData: slice,
                  dataT: data,
                  length: data.length,
                });
              } else {
                const slice = data.slice(
                  this.state.offset,
                  this.state.offset + this.state.perPage
                );

                this.setState({
                  pageCount: Math.ceil(data.length / this.state.perPage),
                  orgtableData: [],
                  tableData: slice,
                  dataT: [],
                  length: data.length,
                });
              }

              this.setState({

                isLoading: false,
              });

            }).catch((e) => {


              const data = [];
              const slice = data.slice(
                this.state.offset,
                this.state.offset + this.state.perPage
              );

              this.setState({
                pageCount: Math.ceil(data.length / this.state.perPage),
                orgtableData: [],
                tableData: slice,
                dataT: [],
                length: data.length,
              });

              if (!e.response) {

                this.setState({ isLoading: false });
                this.setState({ Nacesso: true });

              } else if (e.response.status === 403) {

                this.setState({ isLoading: false });
                this.setState({ token: true });

              } else {

                this.setState({ isLoading: false });
                this.setState({ Nacesso: true });

              }

              this.setState({

                isLoading: false,
              });

            });
          } else
            if (this.props.pag === "produto") {

              dados.push({
                pdc_active: '1',
                pdc_description: '',
                pdc_alternativeidentifier: '',
                ctg_id: '',
                stg_id: '',
                mfr_id: '',
                brn_id: '',
                mde_id: '',
                search: '',
              })

              const _user = secureLocalStorage.getItem('t')

              api.defaults.headers.common['Authorization'] = _user

              /// console.log(this.props.endPoint, dados)

              api.get(this.props.endPoint, {
                params: {
                  data: dados
                }
              }).then((res) => {
                const data = res.data.data || [];

                if (res.data.status === 200) {
                  const slice = data.slice(
                    this.state.offset,
                    this.state.offset + this.state.perPage
                  );

                  this.setState({
                    pageCount: Math.ceil(data.length / this.state.perPage),
                    orgtableData: data,
                    tableData: slice,
                    dataT: data,
                    length: data.length,
                  });
                } else {
                  const slice = data.slice(
                    this.state.offset,
                    this.state.offset + this.state.perPage
                  );

                  this.setState({
                    pageCount: Math.ceil(data.length / this.state.perPage),
                    orgtableData: [],
                    tableData: slice,
                    dataT: [],
                    length: data.length,
                  });
                }

                this.setState({

                  isLoading: false,
                });

              }).catch((e) => {


                const data = [];
                const slice = data.slice(
                  this.state.offset,
                  this.state.offset + this.state.perPage
                );

                this.setState({
                  pageCount: Math.ceil(data.length / this.state.perPage),
                  orgtableData: [],
                  tableData: slice,
                  dataT: [],
                  length: data.length,
                });

                if (!e.response) {

                  this.setState({ isLoading: false });
                  this.setState({ Nacesso: true });

                } else if (e.response.status === 403) {

                  this.setState({ isLoading: false });
                  this.setState({ token: true });

                } else {

                  this.setState({ isLoading: false });
                  this.setState({ Nacesso: true });

                }

                this.setState({

                  isLoading: false,
                });

              });
            }
            else
              if (this.props.pag === "asset") {

                dados.push({
                  ast_active: '1',
                  ast_description: '',
                  ast_alternativeidentifier: '',
                  ast_serialnumber: '',
                  ast_tombamento: '',
                  ssa_id: '',
                  pdc_id: '',
                  ctc_id: '',
                  unt_id: '',
                  search: '',
                })

                const _user = secureLocalStorage.getItem('t')

                api.defaults.headers.common['Authorization'] = _user

                // console.log(this.props.endPoint, dados)

                api.get(this.props.endPoint, {
                  params: {
                    data: dados
                  }
                }).then((res) => {
                  const data = res.data.data || [];

                  if (res.data.status === 200) {
                    const slice = data.slice(
                      this.state.offset,
                      this.state.offset + this.state.perPage
                    );

                    this.setState({
                      pageCount: Math.ceil(data.length / this.state.perPage),
                      orgtableData: data,
                      tableData: slice,
                      dataT: data,
                      length: data.length,
                    });
                  } else {
                    const slice = data.slice(
                      this.state.offset,
                      this.state.offset + this.state.perPage
                    );

                    this.setState({
                      pageCount: Math.ceil(data.length / this.state.perPage),
                      orgtableData: [],
                      tableData: slice,
                      dataT: [],
                      length: data.length,
                    });
                  }

                  this.setState({

                    isLoading: false,
                  });

                }).catch((e) => {


                  const data = [];
                  const slice = data.slice(
                    this.state.offset,
                    this.state.offset + this.state.perPage
                  );

                  this.setState({
                    pageCount: Math.ceil(data.length / this.state.perPage),
                    orgtableData: [],
                    tableData: slice,
                    dataT: [],
                    length: data.length,
                  });

                  if (!e.response) {

                    this.setState({ isLoading: false });
                    this.setState({ Nacesso: true });

                  } else if (e.response.status === 403) {

                    this.setState({ isLoading: false });
                    this.setState({ token: true });

                  } else {

                    this.setState({ isLoading: false });
                    this.setState({ Nacesso: true });

                  }

                  this.setState({

                    isLoading: false,
                  });

                });
              }
              else
                if (this.props.pag === "local") {

                  dados.push({
                    loc_active: '1',
                    loc_description: '',
                    loc_pavement: '',
                    loc_alternativeidentifier: '',
                    lty_id: '',
                    ctc_id: '',
                    unt_id: '',
                    loc_email: '',
                    search: '',
                  })

                  const _user = secureLocalStorage.getItem('t')

                  api.defaults.headers.common['Authorization'] = _user

                  // console.log(this.props.endPoint, dados)

                  api.get(this.props.endPoint, {
                    params: {
                      data: dados
                    }
                  }).then((res) => {
                    const data = res.data.data || [];

                    if (res.data.status === 200) {
                      const slice = data.slice(
                        this.state.offset,
                        this.state.offset + this.state.perPage
                      );

                      this.setState({
                        pageCount: Math.ceil(data.length / this.state.perPage),
                        orgtableData: data,
                        tableData: slice,
                        dataT: data,
                        length: data.length,
                      });
                    } else {
                      const slice = data.slice(
                        this.state.offset,
                        this.state.offset + this.state.perPage
                      );

                      this.setState({
                        pageCount: Math.ceil(data.length / this.state.perPage),
                        orgtableData: [],
                        tableData: slice,
                        dataT: [],
                        length: data.length,
                      });
                    }

                    this.setState({

                      isLoading: false,
                    });

                  }).catch((e) => {


                    const data = [];
                    const slice = data.slice(
                      this.state.offset,
                      this.state.offset + this.state.perPage
                    );

                    this.setState({
                      pageCount: Math.ceil(data.length / this.state.perPage),
                      orgtableData: [],
                      tableData: slice,
                      dataT: [],
                      length: data.length,
                    });

                    if (!e.response) {

                      this.setState({ isLoading: false });
                      this.setState({ Nacesso: true });

                    } else if (e.response.status === 403) {

                      this.setState({ isLoading: false });
                      this.setState({ token: true });

                    } else {

                      this.setState({ isLoading: false });
                      this.setState({ Nacesso: true });

                    }

                    this.setState({

                      isLoading: false,
                    });

                  });
                }
                else
                  if (this.props.pag === "serviceproviders") {

                    dados.push({
                      svp_active: '1',
                      svp_corporatename: '',
                      svp_cnpj: '',
                      svp_state: '',
                      search: '',
                    })

                    const _user = secureLocalStorage.getItem('t')

                    api.defaults.headers.common['Authorization'] = _user

                    //  console.log(this.props.endPoint, dados)
                    api.get(this.props.endPoint, {
                      params: {
                        data: dados
                      }
                    }).then((res) => {
                      const data = res.data.data || [];

                      if (res.data.status === 200) {
                        const slice = data.slice(
                          this.state.offset,
                          this.state.offset + this.state.perPage
                        );

                        this.setState({
                          pageCount: Math.ceil(data.length / this.state.perPage),
                          orgtableData: data,
                          tableData: slice,
                          dataT: data,
                          length: data.length,
                        });
                      } else {
                        const slice = data.slice(
                          this.state.offset,
                          this.state.offset + this.state.perPage
                        );

                        this.setState({
                          pageCount: Math.ceil(data.length / this.state.perPage),
                          orgtableData: [],
                          tableData: slice,
                          dataT: [],
                          length: data.length,
                        });
                      }

                      this.setState({

                        isLoading: false,
                      });

                    }).catch((e) => {


                      const data = [];
                      const slice = data.slice(
                        this.state.offset,
                        this.state.offset + this.state.perPage
                      );

                      this.setState({
                        pageCount: Math.ceil(data.length / this.state.perPage),
                        orgtableData: [],
                        tableData: slice,
                        dataT: [],
                        length: data.length,
                      });

                      if (!e.response) {

                        this.setState({ isLoading: false });
                        this.setState({ Nacesso: true });

                      } else if (e.response.status === 403) {

                        this.setState({ isLoading: false });
                        this.setState({ token: true });

                      } else {

                        this.setState({ isLoading: false });
                        this.setState({ Nacesso: true });

                      }

                      this.setState({

                        isLoading: false,
                      });

                    });
                  }
                  else
                    if (this.props.pag === "invoice") {

                      dados.push({
                        inc_active: '1',
                        inc_provider: '',
                        inc_cnpj: '',
                        inc_alternativeidentifier: '',
                        inc_invoicenumber: '',
                        inc_issuedate: '',
                        inc_accesskey: '',
                        search: '',
                      })

                      const _user = secureLocalStorage.getItem('t')

                      api.defaults.headers.common['Authorization'] = _user

                      //  console.log(this.props.endPoint, dados)
                      api.get(this.props.endPoint, {
                        params: {
                          data: dados
                        }
                      }).then((res) => {
                        const data = res.data.data || [];

                        if (res.data.status === 200) {
                          const slice = data.slice(
                            this.state.offset,
                            this.state.offset + this.state.perPage
                          );

                          this.setState({
                            pageCount: Math.ceil(data.length / this.state.perPage),
                            orgtableData: data,
                            tableData: slice,
                            dataT: data,
                            length: data.length,
                          });
                        } else {
                          const slice = data.slice(
                            this.state.offset,
                            this.state.offset + this.state.perPage
                          );

                          this.setState({
                            pageCount: Math.ceil(data.length / this.state.perPage),
                            orgtableData: [],
                            tableData: slice,
                            dataT: [],
                            length: data.length,
                          });
                        }

                        this.setState({

                          isLoading: false,
                        });

                      }).catch((e) => {


                        const data = [];
                        const slice = data.slice(
                          this.state.offset,
                          this.state.offset + this.state.perPage
                        );

                        this.setState({
                          pageCount: Math.ceil(data.length / this.state.perPage),
                          orgtableData: [],
                          tableData: slice,
                          dataT: [],
                          length: data.length,
                        });

                        if (!e.response) {

                          this.setState({ isLoading: false });
                          this.setState({ Nacesso: true });

                        } else if (e.response.status === 403) {

                          this.setState({ isLoading: false });
                          this.setState({ token: true });

                        } else {

                          this.setState({ isLoading: false });
                          this.setState({ Nacesso: true });

                        }
                        this.setState({

                          isLoading: false,
                        });

                      });
                    }
                    else
                      if (this.props.pag === "partmaterial") {

                        dados.push({
                          pml_active: '1',
                          pml_description: '',
                          pml_alternativeidentifier: '',
                          uts_id: '',
                          search: '',
                        })

                        const _user = secureLocalStorage.getItem('t')

                        api.defaults.headers.common['Authorization'] = _user

                        api.get(this.props.endPoint, {
                          params: {
                            data: dados
                          }
                        }).then((res) => {
                          const data = res.data.data || [];

                          if (res.data.status === 200) {
                            const slice = data.slice(
                              this.state.offset,
                              this.state.offset + this.state.perPage
                            );

                            this.setState({
                              pageCount: Math.ceil(data.length / this.state.perPage),
                              orgtableData: data,
                              tableData: slice,
                              dataT: data,
                              length: data.length,
                            });
                          } else {
                            const slice = data.slice(
                              this.state.offset,
                              this.state.offset + this.state.perPage
                            );

                            this.setState({
                              pageCount: Math.ceil(data.length / this.state.perPage),
                              orgtableData: [],
                              tableData: slice,
                              dataT: [],
                              length: data.length,
                            });
                          }

                          this.setState({

                            isLoading: false,
                          });

                        }).catch((e) => {


                          const data = [];
                          const slice = data.slice(
                            this.state.offset,
                            this.state.offset + this.state.perPage
                          );

                          this.setState({
                            pageCount: Math.ceil(data.length / this.state.perPage),
                            orgtableData: [],
                            tableData: slice,
                            dataT: [],
                            length: data.length,
                          });

                          if (!e.response) {

                            this.setState({ isLoading: false });
                            this.setState({ Nacesso: true });

                          } else if (e.response.status === 403) {

                            this.setState({ isLoading: false });
                            this.setState({ token: true });

                          } else {

                            this.setState({ isLoading: false });
                            this.setState({ Nacesso: true });

                          }

                          this.setState({

                            isLoading: false,
                          });

                        });
                      }
                      else
                        if (this.props.pag === "services") {

                          dados.push({
                            svc_active: '1',
                            svc_description: '',
                            svc_alternativeidentifier: '',
                          })

                          const _user = secureLocalStorage.getItem('t')

                          api.defaults.headers.common['Authorization'] = _user

                          api.get(this.props.endPoint, {
                            params: {
                              data: dados
                            }
                          }).then((res) => {
                            const data = res.data.data || [];

                            if (res.data.status === 200) {
                              const slice = data.slice(
                                this.state.offset,
                                this.state.offset + this.state.perPage
                              );

                              this.setState({
                                pageCount: Math.ceil(data.length / this.state.perPage),
                                orgtableData: data,
                                tableData: slice,
                                dataT: data,
                                length: data.length,
                              });
                            } else {
                              const slice = data.slice(
                                this.state.offset,
                                this.state.offset + this.state.perPage
                              );

                              this.setState({
                                pageCount: Math.ceil(data.length / this.state.perPage),
                                orgtableData: [],
                                tableData: slice,
                                dataT: [],
                                length: data.length,
                              });
                            }

                            this.setState({

                              isLoading: false,
                            });

                          }).catch((e) => {


                            const data = [];
                            const slice = data.slice(
                              this.state.offset,
                              this.state.offset + this.state.perPage
                            );

                            this.setState({
                              pageCount: Math.ceil(data.length / this.state.perPage),
                              orgtableData: [],
                              tableData: slice,
                              dataT: [],
                              length: data.length,
                            });

                            if (!e.response) {

                              this.setState({ isLoading: false });
                              this.setState({ Nacesso: true });

                            } else if (e.response.status === 403) {

                              this.setState({ isLoading: false });
                              this.setState({ token: true });

                            } else {

                              this.setState({ isLoading: false });
                              this.setState({ Nacesso: true });

                            }
                            this.setState({

                              isLoading: false,
                            });

                          });
                        }


  }


  Getunitmeasure() {

    let valor

    api.get(this.props.unitmeasure).then((res) => {
      var data = res.data.data;
      if (res.data.status === 200) {

        valor = res.data.data;

        const arr = [];
        let result = valor;
        result.map((user) => {
          return arr.push({ value: String(user.uts_id), label: user.uts_description });
        });

        this.setState({
          optionspartmaterial: arr
        });

      }

    })
      .catch(function (res) {
        this.setState({
          optionspartmaterial: []
        });

      });
  }


  GetLocaltype() {

    let valor

    api.get(this.props.localtype).then((res) => {
      var data = res.data.data;
      if (res.data.status === 200) {

        valor = res.data.data;

        const arr = [];
        let result = valor;
        result.map((user) => {
          return arr.push({ value: String(user.lty_id), label: user.lty_description });
        });

        this.setState({
          optionslocaltype: arr
        });

      }

    })
      .catch(function (res) {
        this.setState({
          optionslocaltype: []
        });

      });
  }

  GetUnity() {

    let valor

    api.get(this.props.unity).then((res) => {
      var data = res.data.data;
      if (res.data.status === 200) {

        valor = res.data.data;

        const arr = [];
        let result = valor;
        result.map((user) => {
          return arr.push({ value: String(user.unt_id), label: user.unt_description });
        });

        this.setState({
          optionsUnity: arr
        });

      }

    })
      .catch(function (res) {
        this.setState({
          optionsUnity: []
        });

      });
  }

  GetContract() {

    let valor

    api.get(this.props.contract).then((res) => {
      var data = res.data.data;
      if (res.data.status === 200) {

        valor = res.data.data;

        const arr = [];
        let result = valor;
        result.map((user) => {
          return arr.push({ value: String(user.ctc_id), label: user.ctc_description });
        });

        this.setState({
          optionsContract: arr
        });

      }

    })
      .catch(function (res) {
        this.setState({
          optionsContract: []
        });

      });
  }

  GetProduct() {

    let valor

    api.get(this.props.product).then((res) => {
      var data = res.data.data;
      if (res.data.status === 200) {

        valor = res.data.data;

        const arr = [];
        let result = valor;
        result.map((user) => {
          return arr.push({ value: String(user.pdc_id), label: user.pdc_description });
        });

        this.setState({
          optionsProduct: arr
        });

      }

    })
      .catch(function (res) {
        this.setState({
          optionsProduct: []
        });

      });
  }

  GetStatus() {

    let valor

    api.get(this.props.inventorystatus).then((res) => {
      var data = res.data.data;
      if (res.data.status === 200) {

        valor = res.data.data;

        const arr = [];
        let result = valor;
        result.map((user) => {
          return arr.push({ value: String(user.ssa_id), label: user.ssa_description });
        });

        this.setState({
          optionsStatusasset: arr
        });

      }

    })
      .catch(function (res) {
        this.setState({
          optionsStatusasset: []
        });

      });
  }

  GetCategory() {

    let valor

    api.get(this.props.category).then((res) => {
      var data = res.data.data;
      if (res.data.status === 200) {

        valor = res.data.data;

        const arr = [];
        let result = valor;
        result.map((user) => {
          return arr.push({ value: String(user.ctg_id), label: user.ctg_description });
        });

        this.setState({
          optionsCategory: arr
        });

      }

    })
      .catch(function (res) {
        this.setState({
          optionsCategory: []
        });

      });
  }

  GetSubcategory() {

    let valor

    api.get(this.props.subcategory).then((res) => {
      var data = res.data.data;
      if (res.data.status === 200) {

        valor = res.data.data;

        const arr = [];
        let result = valor;
        result.map((user) => {
          return arr.push({ value: String(user.stg_id), label: user.stg_description });
        });

        this.setState({
          optionSubcategory: arr
        });

      }

    })
      .catch(function (res) {
        this.setState({
          optionSubcategory: []
        });

      });
  }


  GetManufacturer() {

    let valor

    api.get(this.props.manufacturer).then((res) => {
      var data = res.data.data;
      if (res.data.status === 200) {

        valor = res.data.data;

        const arr = [];
        let result = valor;
        result.map((user) => {
          return arr.push({ value: String(user.mfr_id), label: user.mfr_description });
        });

        this.setState({
          optionsManufacturer: arr
        });

      }

    })
      .catch(function (res) {
        this.setState({
          optionsManufacturer: []
        });

      });
  }

  GetBrand() {

    let valor

    api.get(this.props.brand).then((res) => {
      var data = res.data.data;
      if (res.data.status === 200) {

        valor = res.data.data;

        const arr = [];
        let result = valor;
        result.map((user) => {
          return arr.push({ value: String(user.brn_id), label: user.brn_description });
        });

        this.setState({
          optionsBrand: arr
        });

      }

    })
      .catch(function (res) {
        this.setState({
          optionsBrand: []
        });

      });
  }

  GetModel() {

    let valor

    api.get(this.props.Model).then((res) => {
      var data = res.data.data;
      if (res.data.status === 200) {

        valor = res.data.data;

        const arr = [];
        let result = valor;
        result.map((user) => {
          return arr.push({ value: String(user.mde_id), label: user.mde_description });
        });

        this.setState({
          optionsModel: arr
        });

      }

    })
      .catch(function (res) {
        this.setState({
          optionsModel: []
        });

      });
  }

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
        pagAtual: selectedPage + 1,
        forcePage: selectedPage
      },
      () => {
        this.loadMoreData();
      }
    );


  };

  loadMoreData() {

    const data = this.state.dataT;

    const slice = data.slice(
      this.state.offset,
      this.state.offset + this.state.perPage,

    );


    //////////////////////aqui
    this.setState({
      pageCount: Math.ceil(data.length / this.state.perPage),
      tableData: slice,
      length: data.length,
    });

  }


  handlePageClick_clear = () => {
    const selectedPage = 0;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
        pagAtual: selectedPage + 1,
        forcePage: selectedPage,
      },
      () => {
        this.loadMoreData();
      }
    );


  };


  handleInputChange = (event) => {
    this.setState({ search: event.target.value });
  };

  do_search = () => {

    this.setState({ isLoading: true });

    let dados = [];

    this.handlePageClick_clear()

    if (this.props.pag === "user") {

      dados.push({
        acp_id: this.state.acp_id,
        age_integrationid: this.state.age_integrationid,
        age_name: this.state.age_name,
        age_login: this.state.age_login,
        age_active: this.state.age_active,
        search: this.state.search,
        agg_id: this.state.agg_id,
        age_state: this.state.age_state,
        age_city: this.state.age_city,
        age_neighborhood: this.state.age_neighborhood,
      })


      api.get(this.props.buscaruser, {
        params: {
          data: dados
        }
      }).then((res) => {

        var data = res.data.data;

        if (res.data.status === 200) {

          var slice = data.slice(
            this.state.offset,
            this.state.offset + this.state.perPage
          );

          this.setState({
            pageCount: Math.ceil(data.length / this.state.perPage),
            orgtableData: res.data.data,
            tableData: slice,
            dataT: res.data.data,
            length: data.length,
          });

        }

        this.setState({
          isLoading: false,
        });

      })
        .catch(function (res) {
          const data = [];
          const slice = data.slice(
            this.state.offset,
            this.state.offset + this.state.perPage
          );

          this.setState({
            pageCount: Math.ceil(data.length / this.state.perPage),
            orgtableData: [],
            tableData: slice,
            dataT: [],
            length: data.length,
          });

          this.setState({
            isLoading: false,
          });
        });

    } else
      if (this.props.pag === "team") {

        dados.push({
          tae_active: this.state.tae_active,
          tae_integrationid: this.state.tae_integrationid,
          tae_description: this.state.tae_description,
          tet_id: this.state.tet_id,
          search: this.state.search,
        })


        api.get(this.props.buscarteam, {
          params: {
            data: dados
          }
        }).then((res) => {

          var data = res.data.data;

          if (res.data.status === 200) {

            var slice = data.slice(
              this.state.offset,
              this.state.offset + this.state.perPage
            );

            this.setState({
              pageCount: Math.ceil(data.length / this.state.perPage),
              orgtableData: res.data.data,
              tableData: slice,
              dataT: res.data.data,
              length: data.length,
            });

          }

          this.setState({
            isLoading: false,
          });


        })
          .catch(function (res) {
            const data = [];
            const slice = data.slice(
              this.state.offset,
              this.state.offset + this.state.perPage
            );

            this.setState({
              pageCount: Math.ceil(data.length / this.state.perPage),
              orgtableData: [],
              tableData: slice,
              dataT: [],
              length: data.length,
            });

            this.setState({
              isLoading: false,
            });
          });

      }
      else
        if (this.props.pag === "mobileactivity") {

          dados.push({
            act_active: this.state.act_active,
            act_integrationid: this.state.act_integrationid,
            act_description: this.state.act_description,
            aty_id: this.state.aty_id,
            search: this.state.search,
          })


          api.get(this.props.buscarmobileactivity, {
            params: {
              data: dados
            }
          }).then((res) => {

            var data = res.data.data;

            if (res.data.status === 200) {

              var slice = data.slice(
                this.state.offset,
                this.state.offset + this.state.perPage
              );

              this.setState({
                pageCount: Math.ceil(data.length / this.state.perPage),
                orgtableData: res.data.data,
                tableData: slice,
                dataT: res.data.data,
                length: data.length,
              });

            }

            this.setState({
              isLoading: false,
            });

          })
            .catch(function (res) {
              const data = [];
              const slice = data.slice(
                this.state.offset,
                this.state.offset + this.state.perPage
              );

              this.setState({
                pageCount: Math.ceil(data.length / this.state.perPage),
                orgtableData: [],
                tableData: slice,
                dataT: [],
                length: data.length,
              });

              this.setState({
                isLoading: false,
              });
            });

        }
    if (this.props.pag === "item") {

      dados.push({
        ite_active: this.state.ite_active,
        ite_description: this.state.ite_description,
        ite_alternativeidentifier: this.state.ite_alternativeidentifier,
        its_id: this.state.its_id,
        search: this.state.search,
      })


      api.get(this.props.buscaritem, {
        params: {
          data: dados
        }
      }).then((res) => {

        var data = res.data.data;

        if (res.data.status === 200) {

          var slice = data.slice(
            this.state.offset,
            this.state.offset + this.state.perPage
          );

          this.setState({
            pageCount: Math.ceil(data.length / this.state.perPage),
            orgtableData: res.data.data,
            tableData: slice,
            dataT: res.data.data,
            length: data.length,
          });

        }

        this.setState({
          isLoading: false,
        });

      })
        .catch(function (res) {
          const data = [];
          const slice = data.slice(
            this.state.offset,
            this.state.offset + this.state.perPage
          );

          this.setState({
            pageCount: Math.ceil(data.length / this.state.perPage),
            orgtableData: [],
            tableData: slice,
            dataT: [],
            length: data.length,
          });

          this.setState({
            isLoading: false,
          });
        });

    } else
      if (this.props.pag === "produto") {

        dados.push({
          pdc_active: this.state.pdc_active,
          pdc_description: this.state.pdc_description,
          pdc_alternativeidentifier: this.state.pdc_alternativeidentifier,
          ctg_id: this.state.ctg_id,
          stg_id: this.state.stg_id,
          mfr_id: this.state.mfr_id,
          brn_id: this.state.brn_id,
          mde_id: this.state.mde_id,
          search: this.state.search,
        })


        api.get(this.props.buscarproduto, {
          params: {
            data: dados
          }
        }).then((res) => {

          var data = res.data.data;

          if (res.data.status === 200) {

            var slice = data.slice(
              this.state.offset,
              this.state.offset + this.state.perPage
            );

            this.setState({
              pageCount: Math.ceil(data.length / this.state.perPage),
              orgtableData: res.data.data,
              tableData: slice,
              dataT: res.data.data,
              length: data.length,
            });

          }

          this.setState({
            isLoading: false,
          });


        })
          .catch(function (res) {
            const data = [];
            const slice = data.slice(
              this.state.offset,
              this.state.offset + this.state.perPage
            );

            this.setState({
              pageCount: Math.ceil(data.length / this.state.perPage),
              orgtableData: [],
              tableData: slice,
              dataT: [],
              length: data.length,
            });

            this.setState({
              isLoading: false,
            });
          });

      } else
        if (this.props.pag === "asset") {

          dados.push({
            ast_active: this.state.ast_active,
            ast_description: this.state.ast_description,
            ast_alternativeidentifier: this.state.ast_alternativeidentifier,
            ast_serialnumber: this.state.ast_serialnumber,
            ast_tombamento: this.state.ast_tombamento,
            ssa_id: this.state.ssa_id,
            pdc_id: this.state.pdc_id,
            ctc_id: this.state.ctc_id,
            unt_id: this.state.unt_id,
            search: this.state.search,
          })


          api.get(this.props.buscaresset, {
            params: {
              data: dados
            }
          }).then((res) => {

            var data = res.data.data;

            if (res.data.status === 200) {

              var slice = data.slice(
                this.state.offset,
                this.state.offset + this.state.perPage
              );

              this.setState({
                pageCount: Math.ceil(data.length / this.state.perPage),
                orgtableData: res.data.data,
                tableData: slice,
                dataT: res.data.data,
                length: data.length,
              });

            }

            this.setState({
              isLoading: false,
            });

          })
            .catch(function (res) {
              const data = [];
              const slice = data.slice(
                this.state.offset,
                this.state.offset + this.state.perPage
              );

              this.setState({
                pageCount: Math.ceil(data.length / this.state.perPage),
                orgtableData: [],
                tableData: slice,
                dataT: [],
                length: data.length,
              });

              this.setState({
                isLoading: false,
              });
            });

        }
        else
          if (this.props.pag === "local") {

            dados.push({
              loc_active: this.state.loc_active,
              loc_description: this.state.loc_description,
              loc_pavement: this.state.loc_pavement,
              loc_alternativeidentifier: this.state.loc_alternativeidentifier,
              lty_id: this.state.lty_id,
              ctc_id: this.state.ctc_id,
              unt_id: this.state.unt_id,
              loc_email: this.state.loc_email,
              search: this.state.search,
            })


            api.get(this.props.buscarlocal, {
              params: {
                data: dados
              }
            }).then((res) => {

              var data = res.data.data;

              if (res.data.status === 200) {

                var slice = data.slice(
                  this.state.offset,
                  this.state.offset + this.state.perPage
                );

                this.setState({
                  pageCount: Math.ceil(data.length / this.state.perPage),
                  orgtableData: res.data.data,
                  tableData: slice,
                  dataT: res.data.data,
                  length: data.length,
                });

              }

              this.setState({
                isLoading: false,
              });

            })
              .catch(function (res) {
                const data = [];
                const slice = data.slice(
                  this.state.offset,
                  this.state.offset + this.state.perPage
                );

                this.setState({
                  pageCount: Math.ceil(data.length / this.state.perPage),
                  orgtableData: [],
                  tableData: slice,
                  dataT: [],
                  length: data.length,
                });

                this.setState({
                  isLoading: false,
                });
              });

          }
          else
            if (this.props.pag === "serviceproviders") {

              dados.push({
                svp_active: this.state.svp_active,
                svp_corporatename: this.state.svp_corporatename,
                svp_cnpj: this.state.svp_cnpj,
                svp_state: this.state.svp_state,
                search: this.state.search,
              })


              api.get(this.props.buscarserviceproviders, {
                params: {
                  data: dados
                }
              }).then((res) => {

                var data = res.data.data;

                if (res.data.status === 200) {

                  var slice = data.slice(
                    this.state.offset,
                    this.state.offset + this.state.perPage
                  );

                  this.setState({
                    pageCount: Math.ceil(data.length / this.state.perPage),
                    orgtableData: res.data.data,
                    tableData: slice,
                    dataT: res.data.data,
                    length: data.length,
                  });

                }

                this.setState({
                  isLoading: false,
                });

              })
                .catch(function (res) {
                  const data = [];
                  const slice = data.slice(
                    this.state.offset,
                    this.state.offset + this.state.perPage
                  );

                  this.setState({
                    pageCount: Math.ceil(data.length / this.state.perPage),
                    orgtableData: [],
                    tableData: slice,
                    dataT: [],
                    length: data.length,
                  });

                  this.setState({
                    isLoading: false,
                  });
                });

            }
            else
              if (this.props.pag === "invoice") {


                let inc_issuedate = ''

                if (this.state.inc_issuedate !== '') {
                  inc_issuedate = moment(this.state.inc_issuedate).format('YYYY-MM-DD')
                }


                dados.push({
                  inc_active: this.state.inc_active,
                  inc_provider: this.state.inc_provider,
                  inc_cnpj: this.state.inc_cnpj,
                  inc_alternativeidentifier: this.state.inc_alternativeidentifier,
                  inc_invoicenumber: this.state.inc_invoicenumber,
                  inc_issuedate: inc_issuedate,
                  inc_accesskey: this.state.inc_accesskey,
                  search: this.state.search,
                })


                api.get(this.props.buscarinvoice, {
                  params: {
                    data: dados
                  }
                }).then((res) => {

                  var data = res.data.data;

                  if (res.data.status === 200) {

                    var slice = data.slice(
                      this.state.offset,
                      this.state.offset + this.state.perPage
                    );

                    this.setState({
                      pageCount: Math.ceil(data.length / this.state.perPage),
                      orgtableData: res.data.data,
                      tableData: slice,
                      dataT: res.data.data,
                      length: data.length,
                    });

                  }

                  this.setState({
                    isLoading: false,
                  });

                })
                  .catch(function (res) {
                    const data = [];
                    const slice = data.slice(
                      this.state.offset,
                      this.state.offset + this.state.perPage
                    );

                    this.setState({
                      pageCount: Math.ceil(data.length / this.state.perPage),
                      orgtableData: [],
                      tableData: slice,
                      dataT: [],
                      length: data.length,
                    });

                    this.setState({
                      isLoading: false,
                    });
                  });

              }
              else
                if (this.props.pag === "partmaterial") {


                  dados.push({
                    pml_active: this.state.pml_active,
                    pml_description: this.state.pml_description,
                    pml_alternativeidentifier: this.state.pml_alternativeidentifier,
                    uts_id: this.state.uts_id,
                    search: this.state.search,
                  })


                  api.get(this.props.buscarpartmaterial, {
                    params: {
                      data: dados
                    }
                  }).then((res) => {

                    var data = res.data.data;

                    if (res.data.status === 200) {

                      var slice = data.slice(
                        this.state.offset,
                        this.state.offset + this.state.perPage
                      );

                      this.setState({
                        pageCount: Math.ceil(data.length / this.state.perPage),
                        orgtableData: res.data.data,
                        tableData: slice,
                        dataT: res.data.data,
                        length: data.length,
                      });

                    }


                    this.setState({
                      isLoading: false,
                    });

                  })
                    .catch(function (res) {
                      const data = [];
                      const slice = data.slice(
                        this.state.offset,
                        this.state.offset + this.state.perPage
                      );

                      this.setState({
                        pageCount: Math.ceil(data.length / this.state.perPage),
                        orgtableData: [],
                        tableData: slice,
                        dataT: [],
                        length: data.length,
                      });

                      this.setState({
                        isLoading: false,
                      });
                    });

                }
                else
                  if (this.props.pag === "services") {


                    dados.push({
                      svc_active: this.state.svc_active,
                      svc_description: this.state.svc_description,
                      svc_alternativeidentifier: this.state.svc_alternativeidentifier,
                      search: this.state.search,
                    })

                    api.get(this.props.buscarservices, {
                      params: {
                        data: dados
                      }
                    }).then((res) => {

                      var data = res.data.data;

                      if (res.data.status === 200) {

                        var slice = data.slice(
                          this.state.offset,
                          this.state.offset + this.state.perPage
                        );

                        this.setState({
                          pageCount: Math.ceil(data.length / this.state.perPage),
                          orgtableData: res.data.data,
                          tableData: slice,
                          dataT: res.data.data,
                          length: data.length,
                        });

                      }

                      this.setState({
                        isLoading: false,
                      });


                    })
                      .catch(function (res) {
                        const data = [];
                        const slice = data.slice(
                          this.state.offset,
                          this.state.offset + this.state.perPage
                        );

                        this.setState({
                          pageCount: Math.ceil(data.length / this.state.perPage),
                          orgtableData: [],
                          tableData: slice,
                          dataT: [],
                          length: data.length,
                        });

                        this.setState({
                          isLoading: false,
                        });
                      });

                  }

  };

  clear_search = () => {

    window.location.reload();

  };


  Getitemsubgroup() {

    let valor

    api.get(this.props.itemsubgroup).then((res) => {
      var data = res.data.data;
      if (res.data.status === 200) {

        valor = res.data.data;

        const arr = [];
        let result = valor;
        result.map((user) => {
          return arr.push({ value: String(user.its_id), label: user.its_description });
        });



        this.setState({
          optionitemsubgroup: arr
        });

      }

    })
      .catch(function (res) {
        // console.log(res)
        this.setState({
          optionitemsubgroup: []
        });

      });
  }

  Getmobileactivitytype() {

    let valor

    api.get(this.props.mobileactivitytype).then((res) => {
      var data = res.data.data;
      if (res.data.status === 200) {

        valor = res.data.data;

        const arr = [];
        let result = valor;
        result.map((user) => {
          return arr.push({ value: String(user.aty_id), label: user.aty_description });
        });



        this.setState({
          optionsmobileactivitytype: arr
        });

      }

    })
      .catch(function (res) {
        //  console.log(res)
        this.setState({
          optionsmobileactivitytype: []
        });

      });
  }

  GetAccessprofile() {

    api.get(this.props.accessprofile).then((res) => {

      var data = res.data.data;

      if (res.data.status === 200) {
        const arr = data.map((user) => ({
          value: String(user.acp_id),
          label: user.acp_description
        }));
        this.setState({
          optionsAccessProfile: arr
        });
      }
    })
      .catch((res) => {
        this.setState({
          optionsAccessProfile: []
        });
      });
  }

  Getteamtype() {

    let valor

    api.get(this.props.teamtype).then((res) => {
      var data = res.data.data;
      if (res.data.status === 200) {

        valor = res.data.data;

        const arr = [];
        let result = valor;
        result.map((user) => {
          return arr.push({ value: String(user.tet_id), label: user.tet_description });
        });



        this.setState({
          optionsteamtype: arr
        });

      }

    })
      .catch(function (res) {
        //  console.log(res)
        this.setState({
          optionsteamtype: []
        });

      });
  }

  GetAgentGroup() {

    let valor

    api.get(this.props.agentgroup).then((res) => {
      var data = res.data.data;
      if (res.data.status === 200) {

        valor = res.data.data;

        const arr = [];
        let result = valor;
        result.map((user) => {
          return arr.push({ value: String(user.agg_id), label: user.agg_description });
        });

        this.setState({
          optionsAgentgroup: arr
        });

      }

    })
      .catch(function (res) {
        //  console.log(res)
        this.setState({
          optionsAgentgroup: []
        });

      });
  }

  toggle = (index) => {
    if (this.state.clicked === index) {
      //if clicked question is already active, then close it
      return this.setState({ clicked: null });
    }
    this.setState({ clicked: index });
  };

  handlecheckbox = (e) => {
    const { value, checked } = e.target;

    if (checked) {

      this.setState({
        isChecked: ([...this.state.isChecked, value])
      });


    } else {


      this.setState({
        isChecked: (this.state.isChecked.filter((e) => e !== value))
      });


    }

  }

  chamarCust = (valor) => {
    this.props.funtoggle(valor, this.props.form);

  }

  exibirFiltro = () => {

    if (this.props.pag === "user") {
      if (this.state.clickeduser === true) {
        this.setState({
          clickeduser: false
        });
      } else {
        this.setState({
          clickeduser: true
        });
      }
    } else if (this.props.pag === "team") {
      if (this.state.clickedteam === true) {
        this.setState({
          clickedteam: false
        });
      } else {
        this.setState({
          clickedteam: true
        });
      }
    }
    else if (this.props.pag === "mobileactivity") {
      if (this.state.clickedmobileactivity === true) {
        this.setState({
          clickedmobileactivity: false
        });
      } else {
        this.setState({
          clickedmobileactivity: true
        });
      }
    } else if (this.props.pag === "item") {
      if (this.state.clickeditem === true) {
        this.setState({
          clickeditem: false
        });
      } else {
        this.setState({
          clickeditem: true
        });
      }
    } else if (this.props.pag === "produto") {
      if (this.state.clickedproduto === true) {
        this.setState({
          clickedproduto: false
        });
      } else {
        this.setState({
          clickedproduto: true
        });
      }
    } else if (this.props.pag === "asset") {
      if (this.state.clickedAsset === true) {
        this.setState({
          clickedAsset: false
        });
      } else {
        this.setState({
          clickedAsset: true
        });
      }
    } else if (this.props.pag === "local") {
      if (this.state.clickedLocal === true) {
        this.setState({
          clickedLocal: false
        });
      } else {
        this.setState({
          clickedLocal: true
        });
      }
    } else if (this.props.pag === "serviceproviders") {
      if (this.state.clickedserviceproviders === true) {
        this.setState({
          clickedserviceproviders: false
        });
      } else {
        this.setState({
          clickedserviceproviders: true
        });
      }
    } else if (this.props.pag === "invoice") {
      if (this.state.clickedinvoice === true) {
        this.setState({
          clickedinvoice: false
        });
      } else {
        this.setState({
          clickedinvoice: true
        });
      }
    } else if (this.props.pag === "partmaterial") {
      if (this.state.clickedpartmaterial === true) {
        this.setState({
          clickedpartmaterial: false
        });
      } else {
        this.setState({
          clickedpartmaterial: true
        });
      }
    } else if (this.props.pag === "services") {
      if (this.state.clickedservices === true) {
        this.setState({
          clickedservices: false
        });
      } else {
        this.setState({
          clickedservices: true
        });
      }
    }


  }

  handleInputChangeAtivo = (event) => {

    if (this.props.pag === "user") {
      this.setState({ age_active: event.target.value });
    } else if (this.props.pag === "team") {
      this.setState({ tae_active: event.target.value });
    } else if (this.props.pag === "mobileactivity") {
      this.setState({ act_active: event.target.value });
    } else if (this.props.pag === "item") {
      this.setState({ ite_active: event.target.value });
    } else if (this.props.pag === "produto") {
      this.setState({ pdc_active: event.target.value });
    } else if (this.props.pag === "asset") {
      this.setState({ ast_active: event.target.value });
    } else if (this.props.pag === "local") {
      this.setState({ loc_active: event.target.value });
    } else if (this.props.pag === "serviceproviders") {
      this.setState({ svp_active: event.target.value });
    } else if (this.props.pag === "invoice") {
      this.setState({ inc_active: event.target.value });
    } else if (this.props.pag === "partmaterial") {
      this.setState({ pml_active: event.target.value });
    } else if (this.props.pag === "services") {
      this.setState({ svc_active: event.target.value });
    }

  };

  funcNsolicitacao = () => {
    this.setState({ Nacesso: false });
  }


  render() {

    const customStyles = {
      control: (provided, state) => ({
        ...provided,
        background: '#fff',
        minHeight: '30px',
        height: '30px',
        borderColor: state.isFocused ? 'grey' : '#92989e',

      }),


      valueContainer: (provided, state) => ({
        ...provided,
        height: '30px',
        padding: '0 6px'
      }),

      input: (provided, state) => ({
        ...provided,
        margin: '0px',
        fontSize: 14,

      }),
      indicatorsContainer: (provided, state) => ({
        ...provided,
        height: '30px',
      }),
    };

    return (
      <div className="ContainerGrid">
        <div className="ContainerBody">
          <div className="InputBusca2">
            <input
              className="inputBuscaRep"
              autoComplete="off"
              placeholder="Pesquisar..."
              onChange={this.handleInputChange}
              value={this.state.search}
            />

            <div className="icone-buscaT" onClick={this.do_search}>
              <FcSearch className="icone-add" />
            </div>

            <div className="icone-buscaT" onClick={this.clear_search}>
              <FaEraser className="icone-add" />
            </div>

            <div className="icone-buscaT" onClick={this.exibirFiltro}>
              <FcFilledFilter className="icone-add" />
            </div>

          </div>

          {secureLocalStorage.getItem('acp') === 'DESENVOLVEDOR' || secureLocalStorage.getItem('acp') === 'ADMINISTRADOR' || (secureLocalStorage.getItem('acp') === 'DIRECIONADOR' && this.props.form !== 'asset') ? (



            <Link to={this.props.fromAdd} className="icone-buscaAdd" >
              <FcPlus className="icone-add" />
            </Link>


          ) : null}

        </div>

        <div className="ContainerDropdown">
          <>

            {this.state.clickeduser === true ? (

              <div className="container-Dropdown-busca">
                <div className="container-cad-prod6">
                  <div id="content1">
                    <div className="input-box-ativos">
                      <span className="details">Ativo</span>
                      <select id="SELCAT" name="SELCAT" key='CAT0' onChange={this.handleInputChangeAtivo} value={this.state.age_active}>
                        <option value="">Todos</option>
                        <option value="1">Sim</option>
                        <option value="0">Não</option>
                      </select>
                    </div>
                  </div>

                  <div id="content3">
                    <div className="input-box-ativos">
                      <span className="details">ID para Integração</span>
                      <input
                        type="text"
                        onChange={(e) => this.setState({ age_integrationid: e.target.value })}
                        value={this.state.age_integrationid}
                      />
                    </div>
                  </div>

                  <div id="content2">
                    <div className="input-box-ativos">
                      <span className="details">Nome</span>
                      <input
                        type="text"
                        onChange={(e) => this.setState({ age_name: e.target.value })}
                        value={this.state.age_name}
                      />
                    </div>
                  </div>
                  <div id="content4">
                    <div className="input-box-ativos">
                      <span className="details">Login</span>
                      <input
                        type="text"
                        onChange={(e) => this.setState({ age_login: e.target.value })}
                        value={this.state.age_login}
                      />
                    </div>
                  </div>

                </div>



                <div className="container-cad-prod16">

                  <div id="content1">

                    <div className="select-box-ativos-select">
                      <span className="details">Perfil de Acesso</span>
                      <Select
                        maxMenuHeight={150}
                        placeholder={'Selecione...'}
                        options={this.state.optionsAccessProfile}
                        styles={customStyles}
                        onChange={(options) =>
                          !options ? this.setState({ acp_id: '' }) : this.setState({ acp_id: options.value })
                        }
                        isClearable
                        noOptionsMessage={() => "Não encontrado..."}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 4,
                          colors: {
                            ...theme.colors,
                            primary25: '#ebebeb',
                            primary: 'black',
                          },
                        })}
                      />
                    </div>
                  </div>

                  <div id="content2">

                    <div className="select-box-ativos-select">
                      <span className="details">Função</span>
                      <Select
                        maxMenuHeight={150}
                        placeholder={'Selecione...'}
                        options={this.state.optionsAgentgroup}
                        styles={customStyles}
                        onChange={(options) =>
                          !options ? this.setState({ agg_id: '' }) : this.setState({ agg_id: options.value })
                        }
                        isClearable
                        noOptionsMessage={() => "Não encontrado..."}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 4,
                          colors: {
                            ...theme.colors,
                            primary25: '#ebebeb',
                            primary: 'black',
                          },
                        })}
                      />
                    </div>

                  </div>

                  <div id="content3">

                    <div className="input-box-ativos">
                      <span className="details">Estado</span>
                      <input
                        type="text"
                        onChange={(e) => this.setState({ age_state: e.target.value })}
                        value={this.state.age_state}
                      />
                    </div>

                  </div>

                  <div id="content4">

                    <div className="input-box-ativos">
                      <span className="details">Cidade</span>
                      <input
                        type="text"
                        onChange={(e) => this.setState({ age_city: e.target.value })}
                        value={this.state.age_city}
                      />
                    </div>

                  </div>

                  <div id="content5">

                    <div className="input-box-ativos">
                      <span className="details">Bairro</span>
                      <input
                        type="text"
                        onChange={(e) => this.setState({ age_neighborhood: e.target.value })}
                        value={this.state.age_neighborhood}
                      />
                    </div>

                  </div>

                </div>

              </div>
            ) : null}

            {this.state.clickedteam === true ? (

              <div className="container-Dropdown-busca">
                <div className="container-cad-prod6">
                  <div id="content1">
                    <div className="input-box-ativos">
                      <span className="details">Ativo</span>
                      <select id="SELCAT" name="SELCAT" key='CAT0'
                        onChange={this.handleInputChangeAtivo}
                        value={this.state.tae_active}>
                        <option value="">Todos</option>
                        <option value="1">Sim</option>
                        <option value="0">Não</option>
                      </select>
                    </div>
                  </div>

                  <div id="content3">
                    <div className="input-box-ativos">
                      <span className="details">ID para Integração</span>
                      <input
                        type="text"
                        onChange={(e) => this.setState({ tae_integrationid: e.target.value })}
                        value={this.state.tae_integrationid}
                      />
                    </div>
                  </div>

                  <div id="content2">
                    <div className="input-box-ativos">
                      <span className="details">Descrição</span>
                      <input
                        type="text"
                        onChange={(e) => this.setState({ tae_description: e.target.value })}
                        value={this.state.tae_description}
                      />
                    </div>
                  </div>
                  <div id="content4">


                    <div className="select-box-ativos-select">
                      <span className="details">Tipo</span>
                      <Select
                        maxMenuHeight={150}
                        placeholder={'Selecione...'}
                        options={this.state.optionsteamtype}
                        styles={customStyles}
                        onChange={(options) =>
                          !options ? this.setState({ tet_id: '' }) : this.setState({ tet_id: options.value })
                        }
                        isClearable
                        noOptionsMessage={() => "Não encontrado..."}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 4,
                          colors: {
                            ...theme.colors,
                            primary25: '#ebebeb',
                            primary: 'black',
                          },
                        })}
                      />
                    </div>

                  </div>

                </div>




              </div>
            ) : null}

            {this.state.clickedmobileactivity === true ? (

              <div className="container-Dropdown-busca">
                <div className="container-cad-prod6">
                  <div id="content1">
                    <div className="input-box-ativos">
                      <span className="details">Ativo</span>
                      <select id="SELCAT" name="SELCAT" key='CAT0'
                        onChange={this.handleInputChangeAtivo}
                        value={this.state.act_active}>
                        <option value="">Todos</option>
                        <option value="1">Sim</option>
                        <option value="0">Não</option>
                      </select>
                    </div>
                  </div>

                  <div id="content3">
                    <div className="input-box-ativos">
                      <span className="details">ID para Integração </span>
                      <input
                        type="text"
                        onChange={(e) => this.setState({ act_integrationid: e.target.value })}
                        value={this.state.act_integrationid}
                      />
                    </div>
                  </div>

                  <div id="content2">
                    <div className="input-box-ativos">
                      <span className="details">Descrição</span>
                      <input
                        type="text"
                        onChange={(e) => this.setState({ act_description: e.target.value })}
                        value={this.state.act_description}
                      />
                    </div>
                  </div>
                  <div id="content4">


                    <div className="select-box-ativos-select">
                      <span className="details">Tipo de Atividade</span>
                      <Select
                        maxMenuHeight={150}
                        placeholder={'Selecione...'}
                        options={this.state.optionsmobileactivitytype}
                        styles={customStyles}
                        onChange={(options) =>
                          !options ? this.setState({ aty_id: '' }) : this.setState({ aty_id: options.value })
                        }
                        isClearable
                        noOptionsMessage={() => "Não encontrado..."}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 4,
                          colors: {
                            ...theme.colors,
                            primary25: '#ebebeb',
                            primary: 'black',
                          },
                        })}
                      />
                    </div>

                  </div>

                </div>

              </div>
            ) : null}

            {this.state.clickeditem === true ? (

              <div className="container-Dropdown-busca">
                <div className="container-cad-prod6">
                  <div id="content1">
                    <div className="input-box-ativos">
                      <span className="details">Ativo</span>
                      <select id="SELCAT" name="SELCAT" key='CAT0'
                        onChange={this.handleInputChangeAtivo}
                        value={this.state.ite_active}>
                        <option value="">Todos</option>
                        <option value="1">Sim</option>
                        <option value="0">Não</option>
                      </select>
                    </div>
                  </div>

                  <div id="content3">
                    <div className="input-box-ativos">
                      <span className="details">Identificador alternativo</span>
                      <input
                        type="text"
                        onChange={(e) => this.setState({ ite_alternativeidentifier: e.target.value })}
                        value={this.state.ite_alternativeidentifier}
                      />
                    </div>
                  </div>

                  <div id="content2">
                    <div className="input-box-ativos">
                      <span className="details">Descrição</span>
                      <input
                        type="text"
                        onChange={(e) => this.setState({ ite_description: e.target.value })}
                        value={this.state.ite_description}
                      />
                    </div>
                  </div>
                  <div id="content4">


                    <div className="select-box-ativos-select">
                      <span className="details">Subgrupo de Item</span>
                      <Select
                        maxMenuHeight={150}
                        placeholder={'Selecione...'}
                        options={this.state.optionitemsubgroup}
                        styles={customStyles}
                        onChange={(options) =>
                          !options ? this.setState({ its_id: '' }) : this.setState({ its_id: options.value })
                        }
                        isClearable
                        noOptionsMessage={() => "Não encontrado..."}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 4,
                          colors: {
                            ...theme.colors,
                            primary25: '#ebebeb',
                            primary: 'black',
                          },
                        })}
                      />
                    </div>

                  </div>

                </div>




              </div>
            ) : null}

            {this.state.clickedproduto === true ? (

              <div className="container-Dropdown-busca">
                <div className="container-cad-prod6">
                  <div id="content1">
                    <div className="input-box-ativos">
                      <span className="details">Ativo</span>
                      <select id="SELCAT" name="SELCAT" key='CAT0'
                        onChange={this.handleInputChangeAtivo}
                        value={this.state.pdc_active}>
                        <option value="">Todos</option>
                        <option value="1">Sim</option>
                        <option value="0">Não</option>
                      </select>
                    </div>
                  </div>

                  <div id="content3">
                    <div className="input-box-ativos">
                      <span className="details">Identificador Alternativo</span>
                      <input
                        type="text"
                        onChange={(e) => this.setState({ pdc_alternativeidentifier: e.target.value })}
                        value={this.state.pdc_alternativeidentifier}
                      />
                    </div>
                  </div>

                  <div id="content2">
                    <div className="input-box-ativos">
                      <span className="details">Descrição</span>
                      <input
                        type="text"
                        onChange={(e) => this.setState({ pdc_description: e.target.value })}
                        value={this.state.pdc_description}
                      />
                    </div>
                  </div>
                  <div id="content4">

                    <div className="select-box-ativos-select">
                      <span className="details">Categoria</span>
                      <Select
                        maxMenuHeight={150}
                        placeholder={'Selecione...'}
                        options={this.state.optionsCategory}
                        styles={customStyles}
                        onChange={(options) =>
                          !options ? this.setState({ ctg_id: '' }) : this.setState({ ctg_id: options.value })
                        }
                        isClearable
                        noOptionsMessage={() => "Não encontrado..."}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 4,
                          colors: {
                            ...theme.colors,
                            primary25: '#ebebeb',
                            primary: 'black',
                          },
                        })}
                      />
                    </div>

                  </div>

                </div>






                <div className="container-cad-prod6">
                  <div id="content1">
                    <div className="select-box-ativos-select">
                      <span className="details">Subcategoria</span>
                      <Select
                        maxMenuHeight={150}
                        placeholder={'Selecione...'}
                        options={this.state.optionSubcategory}
                        styles={customStyles}
                        onChange={(options) =>
                          !options ? this.setState({ stg_id: '' }) : this.setState({ stg_id: options.value })
                        }
                        isClearable
                        noOptionsMessage={() => "Não encontrado..."}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 4,
                          colors: {
                            ...theme.colors,
                            primary25: '#ebebeb',
                            primary: 'black',
                          },
                        })}
                      />
                    </div>
                  </div>

                  <div id="content2">
                    <div className="select-box-ativos-select">
                      <span className="details">Fabricante</span>
                      <Select
                        maxMenuHeight={150}
                        placeholder={'Selecione...'}
                        options={this.state.optionsManufacturer}
                        styles={customStyles}
                        onChange={(options) =>
                          !options ? this.setState({ mfr_id: '' }) : this.setState({ mfr_id: options.value })
                        }
                        isClearable
                        noOptionsMessage={() => "Não encontrado..."}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 4,
                          colors: {
                            ...theme.colors,
                            primary25: '#ebebeb',
                            primary: 'black',
                          },
                        })}
                      />
                    </div>
                  </div>

                  <div id="content3">
                    <div className="select-box-ativos-select">
                      <span className="details">Marca</span>
                      <Select
                        maxMenuHeight={150}
                        placeholder={'Selecione...'}
                        options={this.state.optionsBrand}
                        styles={customStyles}
                        onChange={(options) =>
                          !options ? this.setState({ brn_id: '' }) : this.setState({ brn_id: options.value })
                        }
                        isClearable
                        noOptionsMessage={() => "Não encontrado..."}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 4,
                          colors: {
                            ...theme.colors,
                            primary25: '#ebebeb',
                            primary: 'black',
                          },
                        })}
                      />
                    </div>
                  </div>
                  <div id="content4">

                    <div className="select-box-ativos-select">
                      <span className="details">Modelo</span>
                      <Select
                        maxMenuHeight={150}
                        placeholder={'Selecione...'}
                        options={this.state.optionsModel}
                        styles={customStyles}
                        onChange={(options) =>
                          !options ? this.setState({ mde_id: '' }) : this.setState({ mde_id: options.value })
                        }
                        isClearable
                        noOptionsMessage={() => "Não encontrado..."}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 4,
                          colors: {
                            ...theme.colors,
                            primary25: '#ebebeb',
                            primary: 'black',
                          },
                        })}
                      />
                    </div>

                  </div>

                </div>



              </div>
            ) : null}

            {this.state.clickedAsset === true ? (

              <div className="container-Dropdown-busca">
                <div className="container-cad-prod6">
                  <div id="content1">
                    <div className="input-box-ativos">
                      <span className="details">Ativo</span>
                      <select id="SELCAT" name="SELCAT" key='CAT0' onChange={this.handleInputChangeAtivo}
                        value={this.state.ast_active}>
                        <option value="">Todos</option>
                        <option value="1">Sim</option>
                        <option value="0">Não</option>
                      </select>
                    </div>
                  </div>

                  <div id="content3">
                    <div className="input-box-ativos">
                      <span className="details">Identificador Alternativo</span>
                      <input
                        type="text"
                        onChange={(e) => this.setState({ ast_alternativeidentifier: e.target.value })}
                        value={this.state.ast_alternativeidentifier}
                      />
                    </div>
                  </div>

                  <div id="content2">
                    <div className="input-box-ativos">
                      <span className="details">Descrição</span>
                      <input
                        type="text"
                        onChange={(e) => this.setState({ ast_description: e.target.value })}
                        value={this.state.ast_description}
                      />
                    </div>
                  </div>
                  <div id="content4">
                    <div className="select-box-ativos-select">
                      <span className="details">Status</span>
                      <Select
                        maxMenuHeight={150}
                        placeholder={'Selecione...'}
                        options={this.state.optionsStatusasset}
                        styles={customStyles}
                        onChange={(options) =>
                          !options ? this.setState({ ssa_id: '' }) : this.setState({ ssa_id: options.value })
                        }
                        isClearable
                        noOptionsMessage={() => "Não encontrado..."}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 4,
                          colors: {
                            ...theme.colors,
                            primary25: '#ebebeb',
                            primary: 'black',
                          },
                        })}
                      />
                    </div>
                  </div>

                </div>

                <div className="container-cad-prod16">

                  <div id="content1">

                    <div className="input-box-ativos">
                      <span className="details">Número de Série</span>
                      <input
                        type="text"
                        onChange={(e) => this.setState({ ast_serialnumber: e.target.value })}
                        value={this.state.ast_serialnumber}
                      />
                    </div>


                  </div>

                  <div id="content2">

                    <div className="input-box-ativos">
                      <span className="details">Tombamento</span>
                      <input
                        type="text"
                        onChange={(e) => this.setState({ ast_tombamento: e.target.value })}
                        value={this.state.ast_tombamento}
                      />
                    </div>

                  </div>

                  <div id="content3">

                    <div className="select-box-ativos-select">
                      <span className="details">Produto</span>
                      <Select
                        maxMenuHeight={150}
                        placeholder={'Selecione...'}
                        options={this.state.optionsProduct}
                        styles={customStyles}
                        onChange={(options) =>
                          !options ? this.setState({ pdc_id: '' }) : this.setState({ pdc_id: options.value })
                        }
                        isClearable
                        noOptionsMessage={() => "Não encontrado..."}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 4,
                          colors: {
                            ...theme.colors,
                            primary25: '#ebebeb',
                            primary: 'black',
                          },
                        })}
                      />
                    </div>


                  </div>

                  <div id="content4">

                    <div className="select-box-ativos-select">
                      <span className="details">Contrato</span>
                      <Select
                        maxMenuHeight={150}
                        placeholder={'Selecione...'}
                        options={this.state.optionsContract}
                        styles={customStyles}
                        onChange={(options) =>
                          !options ? this.setState({ ctc_id: '' }) : this.setState({ ctc_id: options.value })
                        }
                        isClearable
                        noOptionsMessage={() => "Não encontrado..."}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 4,
                          colors: {
                            ...theme.colors,
                            primary25: '#ebebeb',
                            primary: 'black',
                          },
                        })}
                      />
                    </div>

                  </div>

                  <div id="content5">

                    <div className="select-box-ativos-select">
                      <span className="details">Unidade</span>
                      <Select
                        maxMenuHeight={150}
                        placeholder={'Selecione...'}
                        options={this.state.optionsUnity}
                        styles={customStyles}
                        onChange={(options) =>
                          !options ? this.setState({ unt_id: '' }) : this.setState({ unt_id: options.value })
                        }
                        isClearable
                        noOptionsMessage={() => "Não encontrado..."}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 4,
                          colors: {
                            ...theme.colors,
                            primary25: '#ebebeb',
                            primary: 'black',
                          },
                        })}
                      />
                    </div>

                  </div>

                </div>

              </div>
            ) : null}

            {this.state.clickedLocal === true ? (

              <div className="container-Dropdown-busca">
                <div className="container-cad-prod6">
                  <div id="content1">
                    <div className="input-box-ativos">
                      <span className="details">Ativo</span>
                      <select id="SELCAT" name="SELCAT" key='CAT0' onChange={this.handleInputChangeAtivo}
                        value={this.state.loc_active}>
                        <option value="">Todos</option>
                        <option value="1">Sim</option>
                        <option value="0">Não</option>
                      </select>
                    </div>
                  </div>

                  <div id="content2">
                    <div className="input-box-ativos">
                      <span className="details">Descrição</span>
                      <input
                        type="text"
                        onChange={(e) => this.setState({ loc_description: e.target.value })}
                        value={this.state.loc_description}
                      />
                    </div>
                  </div>

                  <div id="content3">
                    <div className="input-box-ativos">
                      <span className="details">Identificador Alternativo</span>
                      <input
                        type="text"
                        onChange={(e) => this.setState({ loc_alternativeidentifier: e.target.value })}
                        value={this.state.loc_alternativeidentifier}
                      />
                    </div>
                  </div>
                  <div id="content4">
                    <div className="input-box-ativos">
                      <span className="details">Pavimento</span>
                      <input
                        type="text"
                        onChange={(e) => this.setState({ loc_pavement: e.target.value })}
                        value={this.state.loc_pavement}
                      />
                    </div>
                  </div>

                </div>

                <div className="container-cad-prod6">

                  <div id="content1">

                    <div className="select-box-ativos-select">
                      <span className="details">Tipo de Local</span>
                      <Select
                        maxMenuHeight={150}
                        placeholder={'Selecione...'}
                        options={this.state.optionslocaltype}
                        styles={customStyles}
                        onChange={(options) =>
                          !options ? this.setState({ lty_id: '' }) : this.setState({ lty_id: options.value })
                        }
                        isClearable
                        noOptionsMessage={() => "Não encontrado..."}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 4,
                          colors: {
                            ...theme.colors,
                            primary25: '#ebebeb',
                            primary: 'black',
                          },
                        })}
                      />
                    </div>


                  </div>

                  <div id="content2">

                    <div className="select-box-ativos-select">
                      <span className="details">Contrato</span>
                      <Select
                        maxMenuHeight={150}
                        placeholder={'Selecione...'}
                        options={this.state.optionsContract}
                        styles={customStyles}
                        onChange={(options) =>
                          !options ? this.setState({ ctc_id: '' }) : this.setState({ ctc_id: options.value })
                        }
                        isClearable
                        noOptionsMessage={() => "Não encontrado..."}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 4,
                          colors: {
                            ...theme.colors,
                            primary25: '#ebebeb',
                            primary: 'black',
                          },
                        })}
                      />
                    </div>

                  </div>

                  <div id="content3">

                    <div className="select-box-ativos-select">
                      <span className="details">Unidade</span>
                      <Select
                        maxMenuHeight={150}
                        placeholder={'Selecione...'}
                        options={this.state.optionsUnity}
                        styles={customStyles}
                        onChange={(options) =>
                          !options ? this.setState({ unt_id: '' }) : this.setState({ unt_id: options.value })
                        }
                        isClearable
                        noOptionsMessage={() => "Não encontrado..."}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 4,
                          colors: {
                            ...theme.colors,
                            primary25: '#ebebeb',
                            primary: 'black',
                          },
                        })}
                      />
                    </div>


                  </div>

                  <div id="content4">

                    <div className="input-box-ativos">
                      <span className="details">E-mail</span>
                      <input
                        type="text"
                        onChange={(e) => this.setState({ loc_email: e.target.value })}
                        value={this.state.loc_email}
                      />
                    </div>

                  </div>



                </div>

              </div>
            ) : null}

            {this.state.clickedserviceproviders === true ? (

              <div className="container-Dropdown-busca">
                <div className="container-cad-prod6">
                  <div id="content1">
                    <div className="input-box-ativos">
                      <span className="details">Ativo</span>
                      <select id="SELCAT" name="SELCAT" key='CAT0' onChange={this.handleInputChangeAtivo}
                        value={this.state.svp_active}>
                        <option value="">Todos</option>
                        <option value="1">Sim</option>
                        <option value="0">Não</option>
                      </select>
                    </div>
                  </div>

                  <div id="content2">
                    <div className="input-box-ativos">
                      <span className="details">Razão Social</span>
                      <input
                        type="text"
                        onChange={(e) => this.setState({ svp_corporatename: e.target.value })}
                        value={this.state.svp_corporatename}
                      />
                    </div>
                  </div>

                  <div id="content3">
                    <div className="input-box-ativos">
                      <span className="details">CNPJ</span>
                      <input
                        type="text"
                        onChange={(e) => this.setState({ svp_cnpj: e.target.value })}
                        value={this.state.svp_cnpj}
                      />
                    </div>
                  </div>
                  <div id="content4">
                    <div className="input-box-ativos">
                      <span className="details">Estado</span>
                      <input
                        type="text"
                        onChange={(e) => this.setState({ svp_state: e.target.value })}
                        value={this.state.svp_state}
                      />
                    </div>
                  </div>

                </div>

              </div>
            ) : null}


            {this.state.clickedinvoice === true ? (

              <div className="container-Dropdown-busca">

                <div className="container-cad-prod6">
                  <div id="content1">
                    <div className="input-box-ativos">
                      <span className="details">Ativo</span>
                      <select id="SELCAT" name="SELCAT" key='CAT0' onChange={this.handleInputChangeAtivo}
                        value={this.state.inc_active}>
                        <option value="">Todos</option>
                        <option value="1">Sim</option>
                        <option value="0">Não</option>
                      </select>
                    </div>
                  </div>

                  <div id="content2">
                    <div className="input-box-ativos">
                      <span className="details">Fornecedor</span>
                      <input
                        type="text"
                        onChange={(e) => this.setState({ inc_provider: e.target.value })}
                        value={this.state.inc_provider}
                      />
                    </div>
                  </div>

                  <div id="content3">
                    <div className="input-box-ativos">
                      <span className="details">CNPJ</span>
                      <input
                        type="text"
                        onChange={(e) => this.setState({ inc_cnpj: e.target.value })}
                        value={this.state.inc_cnpj}
                      />
                    </div>
                  </div>
                  <div id="content4">
                    <div className="input-box-ativos">
                      <span className="details">Identificador Alternativo</span>
                      <input
                        type="text"
                        onChange={(e) => this.setState({ inc_alternativeidentifier: e.target.value })}
                        value={this.state.inc_alternativeidentifier}
                      />
                    </div>
                  </div>
                </div>

                <div className="container-cad-prod45">
                  <div id="content1">
                    <div className="input-box-ativos">
                      <span className="details">Numero da Nota</span>
                      <input
                        type="text"
                        onChange={(e) => this.setState({ inc_invoicenumber: e.target.value })}
                        value={this.state.inc_invoicenumber}
                      />
                    </div>
                  </div>

                  <div id="content2">
                    <div className="input-box-ativos">
                      <span className="details">Data de Emissão</span>
                      <input
                        type="date"
                        onChange={(e) => this.setState({ inc_issuedate: e.target.value })}
                        value={this.state.inc_issuedate}
                      />
                    </div>
                  </div>

                  <div id="content3">
                    <div className="input-box-ativos">
                      <span className="details">Chave de Acesso</span>
                      <input
                        type="text"
                        onChange={(e) => this.setState({ inc_accesskey: e.target.value })}
                        value={this.state.inc_accesskey}
                      />
                    </div>
                  </div>

                </div>

              </div>
            ) : null}

            {this.state.clickedpartmaterial === true ? (

              <div className="container-Dropdown-busca">
                <div className="container-cad-prod6">
                  <div id="content1">
                    <div className="input-box-ativos">
                      <span className="details">Ativo</span>
                      <select id="SELCAT" name="SELCAT" key='CAT0'
                        onChange={this.handleInputChangeAtivo}
                        value={this.state.pml_active}>
                        <option value="">Todos</option>
                        <option value="1">Sim</option>
                        <option value="0">Não</option>
                      </select>
                    </div>
                  </div>

                  <div id="content3">
                    <div className="input-box-ativos">
                      <span className="details">Identificador Alternativo</span>
                      <input
                        type="text"
                        onChange={(e) => this.setState({ pml_alternativeidentifier: e.target.value })}
                        value={this.state.pml_alternativeidentifier}
                      />
                    </div>
                  </div>

                  <div id="content2">
                    <div className="input-box-ativos">
                      <span className="details">Descrição</span>
                      <input
                        type="text"
                        onChange={(e) => this.setState({ pml_description: e.target.value })}
                        value={this.state.pml_description}
                      />
                    </div>
                  </div>
                  <div id="content4">
                    <div className="select-box-ativos-select">
                      <span className="details">Unidade de medida</span>
                      <Select
                        maxMenuHeight={150}
                        placeholder={'Selecione...'}
                        options={this.state.optionspartmaterial}
                        styles={customStyles}
                        onChange={(options) =>
                          !options ? this.setState({ uts_id: '' }) : this.setState({ uts_id: options.value })
                        }
                        isClearable
                        noOptionsMessage={() => "Não encontrado..."}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 4,
                          colors: {
                            ...theme.colors,
                            primary25: '#ebebeb',
                            primary: 'black',
                          },
                        })}
                      />
                    </div>

                  </div>

                </div>




              </div>
            ) : null}

            {this.state.clickedservices === true ? (

              <div className="container-Dropdown-busca">
                <div className="container-cad-prod6">
                  <div id="content1">
                    <div className="input-box-ativos">
                      <span className="details">Ativo</span>
                      <select id="SELCAT" name="SELCAT" key='CAT0'
                        onChange={this.handleInputChangeAtivo}
                        value={this.state.svc_active}>
                        <option value="">Todos</option>
                        <option value="1">Sim</option>
                        <option value="0">Não</option>
                      </select>
                    </div>
                  </div>

                  <div id="content3">
                    <div className="input-box-ativos">
                      <span className="details">Identificador Alternativo</span>
                      <input
                        type="text"
                        onChange={(e) => this.setState({ svc_alternativeidentifier: e.target.value })}
                        value={this.state.svc_alternativeidentifier}
                      />
                    </div>
                  </div>

                  <div id="content2">
                    <div className="input-box-ativos">
                      <span className="details">Descrição</span>
                      <input
                        type="text"
                        onChange={(e) => this.setState({ svc_description: e.target.value })}
                        value={this.state.svc_description}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

          </>
        </div>



        {this.state.pageCount > 0 ? (
          <div className="grid-tab-user">
            <table>
              <tbody>
                <tr id="grid-header">
                  <th className="Title">{this.props.th1}</th>
                  <th className="Title">{this.props.th2}</th>
                  <th className="Title">{this.props.th3}</th>
                  <th className="Title">{this.props.th4}</th>
                  <th className="Title">{this.props.th5}</th>
                  <th className="Title">{this.props.th6}</th>
                  <th className="Title">Ações</th>
                </tr>
              </tbody>
              <tbody>
                {this.state.tableData.map((tdata, i) => (
                  <tr className="TR-Coluna" key={Object.values(tdata)[0]}>
                    <td className="ColunaCheck-user" align="center"><input type='checkbox' checked={Object.values(tdata)[this.props.col1]} onChange={() => { }} /></td>
                    <td className="Coluna-id">{Object.values(tdata)[this.props.col2]}</td>
                    <td className="Coluna-descricao">{Object.values(tdata)[this.props.col3]}</td>
                    <td className="Coluna-padrao">{Object.values(tdata)[this.props.col4]}</td>

                    {this.props.btn === 'S' ? (
                      <td className="Coluna-btn"><h1 className="btn-grid-incl" onClick={() => { this.props.incluirInventario(Object(tdata.inc_id)) }}>Adicionar itens</h1></td>
                    ) :
                      <td className="Coluna-padrao">{Object.values(tdata)[this.props.col5]}</td>}

                    <td className="Coluna-data">{Object.values(tdata)[this.props.col6]?.substring(8, 10)}/{Object.values(tdata)[this.props.col6]?.substring(5, 7)}/{Object.values(tdata)[this.props.col6]?.substring(0, 4)} {Object.values(tdata)[this.props.col6]?.substring(11, 16)}</td>


                    <td className="Coluna-icone-btn2">
                      <Link to={this.props.fromEdit + '/' + Object.values(tdata)[0]}>
                        <button className="btn-icon edit-btn">
                          <FaPen />
                        </button>
                      </Link>

                      {this.props.btnDelete === 'N' ? null : (
                        <button className="btn-icon delete-btn" onClick={() => { this.props.iconeExcluir(Object(tdata)) }}>
                          <FaTrash />
                        </button>
                      )}

                      {(secureLocalStorage.getItem('acp') === 'DESENVOLVEDOR' ||
                        secureLocalStorage.getItem('acp') === 'ADMINISTRADOR' ||
                        secureLocalStorage.getItem('acp') === 'CONSULTOR') && this.state.consult === 'S' ? (
                        <Link to={this.props.fromVisualizar + '/' + Object.values(tdata)[0]}>
                          <button className="btn-icon view-btn">
                            <FaSearch />
                          </button>
                        </Link>
                      ) : null}
                    </td>


                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : null}
        <div className="ContainerPag">
  <div className="InlineContainery">

    {/* Exibe mensagem de "Nenhum resultado encontrado" quando não há resultados */}
    {(!this.state.pageCount || this.state.pageCount === 0) && (
      <div className="SemResut">
        <h2>Nenhum resultado encontrado.</h2>
        <h2>Página 1 de 1</h2>
      </div>
    )}

    {/* Área de busca e paginação */}
    <div className="InputBusca">
      
      {/* Exibe número da página atual se houver mais de uma página */}
      {this.state.pageCount > 0 && (
        <div className="Quant">
          <h2>Página {this.state.pagAtual} de {this.state.pageCount}</h2>
        </div>
      )}

      {/* Renderiza a paginação se houver mais de uma página */}
      {this.state.pageCount > 1 && (
        <ReactPaginate
          previousLabel={<i className="fas fa-angle-left"></i>}
          nextLabel={<i className="fas fa-angle-right"></i>}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={this.state.pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={this.handlePageClick}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
          forcePage={this.state.forcePage}
        />
      )}

    </div>
  </div>

  {/* Total de registros exibido fora da área de busca/paginação */}
  {this.state.pageCount > 0 && (
    <div className="paginate">
      <h2>Total de registros: {this.state.length}</h2>
    </div>
  )}
</div>


        <ModalPrincipal isOpen={this.state.isLoading}>
          <div className='container-modal-alert'>
            <div className='div-alert'><Loading /></div>
          </div>
        </ModalPrincipal>

        <ModalPrincipal isOpen={this.state.token}>
          <div className='container-modal-alert'>
            <div className='div-alert'>
              <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
              <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
              <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
              <div className='btn-alert' onClick={this.props.fecharModal}><span className="btn-alert-lab">OK</span></div>
            </div>
          </div>
        </ModalPrincipal>

        <ModalPrincipal isOpen={this.state.Nacesso}>
          <div className='container-modal-alert'>
            <div className='div-alert'>
              <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
              <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
              <div className='div-info-alert'><span className="info-alert">Não foi possível concluir sua solicitação.</span></div>
              <div className='btn-alert' onClick={this.funcNsolicitacao}><span className="btn-alert-lab">OK</span></div>
            </div>
          </div>
        </ModalPrincipal>

      </div>
    );
  }
}

export default Child;
