import React, { useState } from 'react';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from 'recharts';
import './DonutChart.css'; // Importe o arquivo CSS
import Aviso01 from '../Aviso01/index';

const DonutChart = (props) => {
  const [period, setPeriod] = useState('Month');

  // Dados de exemplo para o gráfico
  const data = [
    { name: 'Corretivas', value: 30 },
    { name: 'Preventivas', value: 70 },
  ];

  // Cores usadas no gráfico
  const COLORS = ['#13293D', '#3962AC'];

  return (
    <div className="donut-chart-container">


      {/* Título no estilo da imagem */}
      <h1 className="donut-chart-title">Tipo de Tarefas</h1>



      {props.obj > 0 ? (
        <div className="donut-chart-content">
          {/* Legenda à esquerda (ou acima em telas pequenas) */}
          <div className="donut-chart-legend">
            <div className="donut-chart-legend-item">
              <div
                className="donut-chart-legend-color-box"
                style={{ backgroundColor: '#3962AC' }}
              ></div>
              <span>30% Corretivas</span>
            </div>
            <div className="donut-chart-legend-item">
              <div
                className="donut-chart-legend-color-box"
                style={{ backgroundColor: '#13293D' }}
              ></div>
              <span>70% Preventivas</span>
            </div>
          </div>

          {/* Gráfico à direita (ou abaixo em telas pequenas) */}
          <ResponsiveContainer width="100%" height={200}>
            <PieChart>
              <Pie
                data={data}
                cx="50%"
                cy="50%"
                innerRadius={60}
                outerRadius={80}
                fill="#8884d8"
                paddingAngle={5}
                dataKey="value"
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
        </div>

      ) : (
        <Aviso01 />
      )}


    </div>
  );
};

export default DonutChart;
