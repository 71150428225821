import React, { createContext, useState, useEffect } from "react";
import { IContext, IAuthProvider, IUser } from "./types";
import { AxiosResponse } from 'axios';
import api from '../Services/api';
import { setUserLocalStorage, getUserLocalStorage } from "./util"
import secureLocalStorage from "react-secure-storage";
import ModalAlert from '../components/ModalAlert/modal'
import Swal from 'sweetalert2';
import axios from 'axios';


export const AuthContext = createContext<IContext>({} as IContext)

export const AuthProvider = ({ children }: IAuthProvider) => {
   
    const [user, setUser] = useState<IUser | null>()
    const [openModal, setOpenModal] = useState(false)
    const [qualAlert, setQualAlert] = useState(<></>)

    useEffect(() => {

        const user = secureLocalStorage.getItem('t') as null;

        if (user) {
            setUser(user)
        }

    }, []);

    function fecharModal() {
        setOpenModal(false)
    }

    async function authenticate(company: string, login: string, password: string) {

        let ret: AxiosResponse;

        if (login === '' || password === '') {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">todos os campos devem ser preenchidos.</span></div>
                <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
            </div>)

            setOpenModal(true)
        } else {





            axios.get('http://localhost:3335/user/login', {
                params: {
                    company: company,
                    login: login,
                    senha: password
                }
              })
                .then(async function (response) {
      



                  

                    secureLocalStorage.setItem('endPoint', 'http://localhost:3335/');
                    secureLocalStorage.setItem('t', response.data.userToken);
                    secureLocalStorage.setItem('acp', response.data.data[0].acp_integrationid);
                    secureLocalStorage.setItem('n', response.data.data[0].age_name);
                    secureLocalStorage.setItem('iag', response.data.data[0].age_id);
                    secureLocalStorage.setItem('cy', response.data.data[0].age_company);
      
  

                   // const payload = { token: response.data.userToken, login }

                  //  setUser(payload)



      
                })
                .catch(async function (error) {
      
      
      
      
      
                });

          

      








            
        }
    }

    function logout() {
        setUser(null)

    }

    return (
        <AuthContext.Provider value={{ ...user, authenticate, logout }}>
            <>{children}

                <ModalAlert isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)}>
                    <div className='container-modal-alert'>
                        {qualAlert}
                    </div>
                </ModalAlert>



            </>
        </AuthContext.Provider>
    )
}