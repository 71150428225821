import React, { useEffect, useState } from 'react'
import Nav from "../../components/Nav";
import Header from "../../components/Header";
import GridPrincipal from '../../components/GridPrincipal'
import api from "../../Services/api";
import { AxiosResponse } from "axios";
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from 'react-router';
import ModalPrincipal from '../../components/ModalAlert/modal'
import Loading from '../../components/Loading';
import DropdownItem from '../../components/DropdownItem'
import {FaLayerGroup} from "react-icons/fa";

function Inventario() {

    const navigate = useNavigate();

    const [exibirGrid, setExibirGrid] = useState(<></>);
    const [openModal, setOpenModal] = useState(false)
    const [qualAlert, setQualAlert] = useState(<></>)

    useEffect(() => {

      //  setQualAlert(<div className='div-alert'><Loading /></div>)
     //   setOpenModal(true)

        validatToken();
    }, []);


    async function validatToken() {

        let ret: AxiosResponse;
        let valor: any;

        const _user = secureLocalStorage.getItem('t')

        api.defaults.headers.common['Authorization'] = _user as ''

        try {

            ret = await api.get("/status/validar")

            if (ret.data.status === 200) {

                sessionStorage.setItem("QUALTAB", '0');


                setExibirGrid(<GridPrincipal
                    th1='Ativo'
                    th2='ID'
                    th3='Descrição'
                    th4='Identificador Alternativo'
                    th5='Status'
                    th6='Data da Inclusão'
                    endPoint='asset/buscaresset'
                    col1='1'
                    col2='0'
                    col3='2'
                    col4='3'
                    col5='4'
                    col6='5'
                    fromAdd='/asset/add'
                    fromEdit='/asset/edit'
                    fromVisualizar='/asset/visualizar'
                    btnDelete='N'
                    pag="asset"
                    buscaresset='asset/buscaresset'
                    inventorystatus='assetstatus/listar'
                    product='product/listar'
                    contract='contract/listar'
                    unity='unity/exibir'
                    consult='S'
                    fecharModal={fecharModal}
                />)

                setOpenModal(false)
            }

        } catch (e: any) {

            if (e.response.data.status === 403) {

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
                    <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)

            }
        }

    }

    function fecharModal() {

        secureLocalStorage.removeItem('t')
        secureLocalStorage.removeItem('acp')
        secureLocalStorage.removeItem('n')
        secureLocalStorage.removeItem('iag')
        navigate('/login');
        setOpenModal(false)

    }

    function funtoggle(form: any) {


        if(secureLocalStorage.getItem('acp') === 'DESENVOLVEDOR' || secureLocalStorage.getItem('acp') === 'ADMINISTRADOR'){
        navigate('/importdata');
        }

    }


    return (
        <>
            <Nav />
            <div className="main-content">
                <Header />
                <main>

                    <div className='containerPrincipal'>
                        <div className='ContainerBody'>
                            <div className='div-titulo-pages'>
                            <FaLayerGroup />
                                <h1 className='div-titulo-pages-text'>Cadastro de Ativos</h1>
                            </div>
                        </div>
                        <div className='grid-container-principal'>
                            {exibirGrid}
                        </div>
                    </div>




                </main>
            </div>

            <ModalPrincipal isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)}>
                <div className='container-modal-alert'>
                    {qualAlert}
                </div>
            </ModalPrincipal>

        </>
    );

}

export default Inventario;