import React, { useState, useEffect } from 'react'
import './index.css'
import { AxiosResponse } from "axios";
import api from '../../Services/api'
import ModalAlert from '../ModalAlert/modal'
import secureLocalStorage from "react-secure-storage";
import Loading from '../Loading';
import { useNavigate } from 'react-router';
import { MdOutlineAddCircle } from 'react-icons/md';
import Select from 'react-select'

function FormCorrectivesubgroup(props: any) {

    const navigate = useNavigate();
    const dadosFab = (sessionStorage.getItem("dadosFab") || '{}');
    let userFab = JSON.parse(dadosFab);
    const [id, setId] = useState(userFab.csg_id || '')
    const [active, setActive] = useState(userFab.csg_active != undefined ? Boolean(userFab.csg_active) : true)
    const [description, setDescription] = useState(userFab.csg_description || '')
    const [alternativeIdentifier, setAlternativeIdentifier] = useState(userFab.csg_alternativeidentifier || '')
    const [descriptionIni, setDescriptionIni] = useState(userFab.csg_description || '')
    const [alternativeIdentifierIni, setAlternativeIdentifierIni] = useState(userFab.csg_alternativeidentifier || '')

    const [openModal, setOpenModal] = useState(false)
    const [qualAlert, setQualAlert] = useState(<></>)

    const [optionssubgroupCorretiva, setOptionssubgroupCorretiva] = useState([])
    const [optionsCorretiva, setOptionsCorretiva] = useState([])

    useEffect(() => {

        validatToken();
    }, []);


    const customStyles = {
        control: (provided: any, state: any) => ({
            ...provided,
            background: '#fff',
            minHeight: '30px',
            height: '30px',
            borderColor: state.isFocused ? 'grey' : '#92989e',

        }),


        valueContainer: (provided: any, state: any) => ({
            ...provided,
            height: '30px',
            padding: '0 6px'
        }),

        input: (provided: any, state: any) => ({
            ...provided,
            margin: '0px',
            fontSize: 14,

        }),
        indicatorsContainer: (provided: any, state: any) => ({
            ...provided,
            height: '30px',
        }),
    };


    async function validatToken() {

        let ret: AxiosResponse;
        let valor: any;

        const _user = secureLocalStorage.getItem('t')

        api.defaults.headers.common['Authorization'] = _user as ''

        try {

            ret = await api.get("/status/validar")

            if (ret.data.status === 200) {

                secureLocalStorage.setItem('t', ret.data.data);
                secureLocalStorage.setItem('acp', ret.data.decodedToken.acp_integrationid);
                secureLocalStorage.setItem('n', ret.data.decodedToken.name);
                secureLocalStorage.setItem('iag', ret.data.decodedToken.id);
                secureLocalStorage.setItem('cy', ret.data.decodedToken.company);

                GetsubgroupCorretiva();
                GetCorretiva();
            }
        } catch (e: any) {

            if (e.response.data.status === 403) {

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
                    <div className='btn-alert' onClick={fecharModalToken}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)

            }
        }

    }


    async function GetCorretiva() {
        let ret: AxiosResponse;
        let valor: any;

        try {
            ret = await api.get("correctivetype/listar")
            if (ret.data.status === 200) {
                valor = ret.data.data;

                const arr = [] as any;
                let result = valor;
                result.map((user: any) => {
                    return arr.push({ value: String(user.crt_id), label: user.crt_description });
                });

                setOptionsCorretiva(arr)

            }
        } catch (e) {
            setOptionsCorretiva([])

        }
    }

    async function GetsubgroupCorretiva() {
        let ret: AxiosResponse;
        let valor: any;

        try {
            ret = await api.get("correctivesubgroup/listar")
            if (ret.data.status === 200) {
                valor = ret.data.data;

                setOptionssubgroupCorretiva(valor)

            }
        } catch (e) {
            setOptionssubgroupCorretiva([])

        }
    }

    function fecharModalToken() {

        secureLocalStorage.removeItem('t')
        secureLocalStorage.removeItem('acp')
        secureLocalStorage.removeItem('n')
        secureLocalStorage.removeItem('iag')
        navigate('/login');
        setOpenModal(false)

    }

    function ConfirmarContrato() {

        if (id === undefined || id === '') {
            insertContrato();
        } else {
            UpdateContrato();
        }
    }


    function fecharModal() {
        setOpenModal(false)
    }

    async function UpdateContrato() {



        const FiltrarDescricao = optionssubgroupCorretiva.filter(
            (e: any) => {
                return e.csg_description.toLowerCase() === description.toLowerCase()
            }
        );

        const FiltrarAlternativeIdentifier = optionssubgroupCorretiva.filter(
            (e: any) => {
                return e.csg_alternativeidentifier.toLowerCase() === alternativeIdentifier.toLowerCase()
            }
        );


        if (descriptionIni.toLowerCase() !== description.toLowerCase() && FiltrarDescricao.length > 0) {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Descrição já está em uso.</span></div>
                <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
            </div>)
            setOpenModal(true)
        } else
            if (description === "") {
                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Digite a descrição.</span></div>
                    <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                </div>)
                setOpenModal(true)
            } else
                if (alternativeIdentifier === "") {
                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Digite o identificador alternativo.</span></div>
                        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                    </div>)
                    setOpenModal(true)
                } else if (alternativeIdentifierIni.toLowerCase() !== alternativeIdentifier.toLowerCase() && FiltrarAlternativeIdentifier.length > 0) {

                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Identificador alternativo já está em uso.</span></div>
                        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                    </div>)
                    setOpenModal(true)
                }

                else {

                    let ret: AxiosResponse;
                    const dados = {
                        csg_id: id,
                        csg_active: active,
                        csg_description: description,
                        csg_alternativeidentifier: alternativeIdentifier,
                      
                    }
                
                    try {
                        ret = await api.put("correctivesubgroup/update", {
                            data: dados,
                        });
                        if (ret.data.status === 200) {
                            props.voltar()
                        }
                    } catch (e: any) {
                        setQualAlert(<div className='div-alert'>
                            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                            <div className='div-info-alert'><span className="info-alert">Não foi possível concluir a alteração.</span></div>
                            <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                        </div>)
                    setOpenModal(true)
                    }
                }

    }

    async function insertContrato() {
        let ret: AxiosResponse;

        const FiltrarDescricao = optionssubgroupCorretiva.filter(
            (e: any) => {
                return e.csg_description.toLowerCase() === description.toLowerCase()
            }
        );

        const FiltrarAlternativeIdentifier = optionssubgroupCorretiva.filter(
            (e: any) => {
                return e.csg_alternativeidentifier.toLowerCase() === alternativeIdentifier.toLowerCase()
            }
        );


        if (FiltrarDescricao.length > 0) {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Descrição já está em uso.</span></div>
                <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
            </div>)
            setOpenModal(true)
        } else
            if (description === "") {
                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Digite a descrição.</span></div>
                    <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                </div>)
                setOpenModal(true)
            } else
                if (alternativeIdentifier === "") {
                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Digite o identificador alternativo.</span></div>
                        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                    </div>)
                    setOpenModal(true)
                } else if (FiltrarAlternativeIdentifier.length > 0) {

                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Identificador alternativo já está em uso.</span></div>
                        <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                    </div>)
                    setOpenModal(true)
                }

                else {
                    const dados = [{
                        csg_active: active,
                        csg_description: description,
                        csg_alternativeidentifier: alternativeIdentifier,
                       
                    }]

                    try {
                        ret = await api.post("correctivesubgroup/insert", {
                            data: dados,
                        });

                        if (ret.data.status === 200) {
                            props.voltar()
                        }
                    } catch (e: any) {
                        setQualAlert(<div className='div-alert'>
                            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                            <div className='div-info-alert'><span className="info-alert">Não foi possivel concluir o cadastramento.</span></div>
                            <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                        </div>)

                        setOpenModal(true)
                    }
                }
    }

    function ValitarActive() {
        let e = document.getElementById("checkbox-active") as HTMLInputElement;

        if (e.checked) {

            setActive(true)
        } else {

            setActive(false)
        }
    }


    function voltar() {

        props.voltar();

    }


    //------------------------SubgrupoPreventiva-----------------------------------

    function incluirSubgrupoPreventiva() {

      
    }
  


    //-------------------------------------------------ADD CONTRATO

    return (
        <>
            <div className="container-modal-pric">
            <div className="class-checkbox">
                    <input type="checkbox" id="checkbox-active" checked={active} onChange={ValitarActive} />
                    <h4 className="lab-ativo">Ativo</h4>
                </div>
                <div className="container-cad-prod4">
                    <div id="content1">
                        <div className="input-box-ativos">
                            <span className="details">Descrição *</span>
                            <input
                             autoComplete="off"
                                type="text"
                                onChange={(e) => setDescription(e.target.value)}
                                value={description} />
                        </div>
                    </div>
                    <div id="content2">
                        <div className="input-box-ativos">
                            <span className="details">Identificador Alternativo *</span>
                            <input
                             autoComplete="off"
                                type="text"
                                onChange={(e) => setAlternativeIdentifier(e.target.value)}
                                value={alternativeIdentifier} />
                        </div>
                    </div>

                </div>
                <div className="Bt-button-cad">
                    <button type="submit" onClick={ConfirmarContrato}>
                        <i className="fa fa-check-circle icon-ok-btn"></i> Salvar
                    </button>
                    <button type="submit" onClick={props.voltar}>
                        <i className="fa fa-times icon-cancelar-btn"></i> Cancelar
                    </button>
                </div>

            </div>

            <ModalAlert isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)}>
                <div className='container-modal-alert'>
                    {qualAlert}
                </div>
            </ModalAlert>
        </>
    );

}

export default FormCorrectivesubgroup;