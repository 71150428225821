
import React, { useState, useEffect } from 'react'
import Nav from "../../components/Nav";
import Header from "../../components/Header";
import './index.css'
import { AxiosResponse } from "axios";
import api from '../../Services/api'
import Form from "../../components/eFormItem";
import { useNavigate } from 'react-router';
import Loading from '../../components/Loading'
import ModalAlert from '../../components/ModalAlert/modal'
import secureLocalStorage from "react-secure-storage";

function NotaFiscalAdd() {
    
    const navigate = useNavigate();

    const [id, setId] = useState('')
    const [active, setActive] = useState(true)
    const [provider, setProvider] = useState('');
    const [alternativeIdentifier, setAlternativeIdentifier] = useState('');
    const [cnpj, setCnpj] = useState('');
    const [invoiceNumber, setInvoiceNumber] = useState('');
    const [series, setSeries] = useState('');
    const [accesskey, setAccesskey] = useState('');
    const [issueDate, setIssueDate] = useState('');
    const [noteValue, setNoteValue] = useState('');
    const [observation, setObservation] = useState('');
    const [created_at, setCreated_at] = useState('');
    const [updated_at, setUpdated_at] = useState('');

    const [warrantyMonth, setWarrantyMonth] = useState('');
    const [inventory, SetInventory] = useState('')
    const [deleteItem, SetDeleteItem] = useState('')
    const [transactionsList, setTransactionsList] = useState([]);
    const [optionsNotafiscal, setOptionsNotafiscal] = useState([]);

    const [openModal, setOpenModal] = useState(false)
    const [qualAlert, setQualAlert] = useState(<></>)

    useEffect(() => {

        setQualAlert(<div className='div-alert'><Loading /></div>)
        setOpenModal(true)


        let today = new Date();
        let date =
            today.getFullYear() +
            "-" +
            (today.getMonth() + 1).toString().padStart(2, "0") +
            "-" +
            today.getDate().toString().padStart(2, "0");
        let time =
            today.getHours().toString().padStart(2, "0") +
            ":" +
            today.getMinutes().toString().padStart(2, "0");
        let total = date + "T" + time;

        setCreated_at(total)

        validatToken();


    }, []);


    async function validatToken() {

        let ret: AxiosResponse;
        let valor: any;

        const _user = secureLocalStorage.getItem('t')

        api.defaults.headers.common['Authorization'] = _user as ''

        try {

            ret = await api.get("/status/validar")

            if (ret.data.status === 200) {

                secureLocalStorage.setItem('t', ret.data.data);
                secureLocalStorage.setItem('acp', ret.data.decodedToken.acp_integrationid);
                secureLocalStorage.setItem('n', ret.data.decodedToken.name);
                secureLocalStorage.setItem('iag', ret.data.decodedToken.id);
                secureLocalStorage.setItem('cy', ret.data.decodedToken.company);


                SetDeleteItem('S')
                await GetNotafiscal()

                setOpenModal(false)

            }

        } catch (e: any) {

            if (e.response.data.status === 403) {

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
                    <div className='btn-alert' onClick={fecharModalToken}><span className="btn-alert-lab">OK</span></div>
                </div>)


            }
        }

    }

    async function GetNotafiscal() {
        let ret: AxiosResponse;
        let valor: any;
        try {
            ret = await api.get("invoice/listar")
            if (ret.data.status === 200) {
                valor = ret.data.data;

                setOptionsNotafiscal(valor);

            }
        } catch (e) {

            setOptionsNotafiscal([]);

        }
    }


    function fecharModalToken() {

        secureLocalStorage.removeItem('t')
        secureLocalStorage.removeItem('acp')
        secureLocalStorage.removeItem('n')
        secureLocalStorage.removeItem('iag')
        navigate('/login');
        setOpenModal(false)

    }

    function finalizarModal2() {
        setOpenModal(false)
    }

    function ConfirmareNota() {

        setOpenModal(true)
        setQualAlert(<div className='div-alert'><Loading /></div>)

        const FiltrarAlternativeIdentifier = optionsNotafiscal.filter(
            (e: any) => {
                return e.inc_alternativeidentifier.toLowerCase() === alternativeIdentifier.toLowerCase()
            }
        );


        if (provider === "") {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Digite o fornecedor.</span></div>
                <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
            </div>)
            setOpenModal(true)

        } else
            if (cnpj === "") {

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Digite o CNPJ.</span></div>
                    <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                </div>)
                setOpenModal(true)

            } else
            if (alternativeIdentifier !== "" && FiltrarAlternativeIdentifier.length > 0) {

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Identificador Alternativo já está em uso.</span></div>
                    <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                </div>)
   setOpenModal(true)

            } else
                if (invoiceNumber === "") {

                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Digite o numero da nota.</span></div>
                        <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                    </div>)
                    setOpenModal(true)

                } else
                    if (issueDate === "") {

                        setQualAlert(<div className='div-alert'>
                            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                            <div className='div-info-alert'><span className="info-alert">Digite a data de emissão.</span></div>
                            <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                        </div>)
                        setOpenModal(true)

                    } else
                        if (noteValue === "") {

                            setQualAlert(<div className='div-alert'>
                                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                                <div className='div-info-alert'><span className="info-alert">Digite o valor da nota.</span></div>
                                <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                            </div>)
                            setOpenModal(true)

                        } else if (transactionsList.length <= 0) {


                            setQualAlert(<div className='div-alert'>
                                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                                <div className='div-info-alert'><span className="info-alert">Informe os itens da nota.</span></div>
                                <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                            </div>)
                            setOpenModal(true)

                        } else {
                            InsertNota()
                        }
    }


    async function InsertNota() {
        let ret: AxiosResponse;
        let i = 0
        let newArray = [] as any

        let dataexp = ''

        const dados = [{
            inc_active: active,
            inc_provider: provider,
            inc_cnpj: cnpj,
            inc_alternativeidentifier: alternativeIdentifier,
            inc_invoicenumber: invoiceNumber,
            inc_series: series,
            inc_accesskey: accesskey,
            inc_issuedate: issueDate,
            inc_notevalue: noteValue,
            inc_warrantymonth: warrantyMonth,
            inc_observation: observation,
            age_id_insert: secureLocalStorage.getItem('iag'),
            inc_inclusion: 'N',
            inc_expirationdate: dataexp
        }];
        try {
            ret = await api.post("invoice/insert", {
                data: dados,
                datalist: transactionsList
            });
            if (ret.data.status === 200) {

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-check ControleIconAlert"></span>
                    <div className='div-info-alert'><span className="info-alert">A nota fiscal foi incluido com sucesso.</span></div>
                    <div className='btn-alert' onClick={finalizarModal1}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)

            }
        } catch (e: any) {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Não foi possivel concluir o cadastramento.</span></div>
                <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
            </div>)

            setOpenModal(true)

        }
    }

    function finalizarModal1() {
        navigate('/invoice');
    }


    const handleAdd = (transaction: any) => {
        const newArrayTransactions = [...transactionsList, transaction];
        let transfor = JSON.stringify(newArrayTransactions)
        setTransactionsList(JSON.parse(transfor));
    };

    function ValitarActive() {
        let e = document.getElementById("checkbox-active-nota") as HTMLInputElement;
        if (e.checked) {
            setActive(true)
        } else {
            setActive(false)
        }
    }

    function Cancelar() {
        navigate('/invoice');
    }

    function incluirValor(value: any) {
        var valor = value
        valor = valor + '';
        valor = parseInt(valor.replace(/[\D]+/g, ''));
        valor = valor + '';
        valor = valor.replace(/([0-9]{2})$/g, ".$1");
        if (valor.length > 6) {
            valor = valor.replace(/([0-9]{3}),([0-9]{2}$)/g, "$1.$2");
        }
        setNoteValue(valor)
        if (valor == 'NaN') {
            setNoteValue('');
        }
    }


    function validarcnpj(cnpj: any) {
        var valor = cnpj

        valor = valor + '';
        valor = valor.replace(/\D/g, "");
        valor = valor + '';

        if (valor == 'NaN') {
            setCnpj('');
        } else {
            if (valor.length > 14) {

            } else {
                setCnpj(valor.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5"))
            }

        }
    }

    const maskOnlyNumbers = (value: any) => {
        return value.replace(/\D/g, "");
    };


    function showDate(dateString: any, daysMonthsYears: any) {
        const dateToShow = new Date(dateString);

        if (!dateString || !dateToShow.getTime()) return '';

        dateToShow.setUTCHours(3);

        const { days, months, years } = daysMonthsYears;

        if (days) dateToShow.setDate(dateToShow.getDate() + Number(days));
        if (months) dateToShow.setMonth(dateToShow.getMonth() + Number(months));
        if (years) dateToShow.setFullYear(dateToShow.getFullYear() + Number(years));
        return dateToShow.toISOString().split('T')[0];
    }


    const [daysMonthsYears, setDaysMonthsYears] = useState({
        days: 0,
        months: 0,
        years: 0,
    });



    return (
        <>
            <Nav />
            <div className="main-content">
                <Header />
                <main>

                    <div className='container-principal'>
                        <div className="title-Page">
                            <div className="title-text">
                                <div className="size-x circle">1</div>
                                <h2>Defina as informações gerais sobre a nota fiscal</h2>
                            </div>

                        </div>

                        <div className="class-checkbox">
                            <input type="checkbox" id="checkbox-active-nota" checked={active} onChange={ValitarActive} />
                            <h4 className="lab-ativo">Ativo</h4>
                        </div>
                        <div className="container-cad-prod5">
                            <div className="input-box-ativos">
                                <span className="details">Fornecedor *</span>
                                <input
                                autoComplete="off"
                                    type="text"
                                    onChange={(e) => setProvider(e.target.value)} value={provider}
                                />
                            </div>
                            <div className="input-box-ativos">
                                <span className="details">CNPJ *</span>
                                <input
                                autoComplete="off"
                                    type="text"
                                    onChange={(e) => validarcnpj(e.target.value)} value={cnpj}
                                />
                            </div>
                            <div className="input-box-ativos">
                                <span className="details">Identificador Alternativo</span>
                                <input
                                autoComplete="off"
                                    type="text"
                                    onChange={(e) => setAlternativeIdentifier(e.target.value)} value={alternativeIdentifier}
                                />
                            </div>
                        </div>
                        <div className="container-cad-prod13">


                            <div className="input-box-ativos">
                                <span className="details">Numero da Nota *</span>
                                <input
                                autoComplete="off"
                                    type="text"
                                    onChange={(e) => setInvoiceNumber(maskOnlyNumbers(e.target.value))} value={invoiceNumber}
                                />
                            </div>

                            <div className="input-box-ativos">
                                <span className="details">Série</span>
                                <input
                                autoComplete="off"
                                    type="text"
                                    onChange={(e) => setSeries(e.target.value)} value={series}
                                />
                            </div>

                            <div className="input-box-ativos">
                                <span className="details">Data de Emissão *</span>
                                <input
                                autoComplete="off"
                                    type="date"
                                    onChange={(e) => setIssueDate(e.target.value)} value={issueDate}
                                />
                            </div>

                            <div className="input-box-ativos">
                                <span className="details">Chave de Acesso</span>
                                <input
                                autoComplete="off"
                                    type="text"
                                    onChange={(e) => setAccesskey(e.target.value)} value={accesskey}
                                />
                            </div>


                        </div>
                        <div className="container-cad-prod6">

                            <div className="input-box-ativos">
                                <span className="details">Valor da Nota *</span>
                                <input
                                autoComplete="off"
                                    className='input-nota'
                                    type="text"
                                    placeholder='R$'
                                    onChange={(e) => incluirValor(e.target.value)} value={noteValue}
                                />
                            </div>

                            <div className="input-box-ativos">
                                <span className="details">Garantia</span>
                                <div className='input-flex-ativos'>

                                    <input
                                     placeholder='Quan. Mês'
                                    autoComplete="off"
                                    type="number"
                                        value={warrantyMonth}
                                        maxLength={2}
                                        onChange={(e) => setWarrantyMonth(e.target.value)}
                                    />
                                 
                                </div>
                            </div>

                            <div className="input-box-ativos">
                                <span className="details">Data da inclusão</span>
                                <input
                                    type="datetime-local"
                                    id="DATA-TIME"
                                    name="DATE-TIME"
                                    disabled
                                    value={created_at}
                                />
                            </div>

                            <div className="input-box-ativos">
                                <span className="details">Data da última atualização</span>
                                <input
                                    type="datetime-local"
                                    id="DATA-TIME"
                                    name="DATE-TIME"
                                    disabled
                                    value={updated_at}
                                />
                            </div>
                        </div>

                        <div className="container-cad-prod3">
                            <div className="textareaBox-ativo">
                                <span className="details">Observação</span>
                                <textarea
                                    onChange={(e) => setObservation(e.target.value)} value={observation}
                                />
                            </div>
                        </div>

                        <div className="title-Page">
                            <div className="title-text">
                                <div className="size-x circle">2</div>
                                <h2>Itens Notas Fiscais</h2>
                            </div>
                            <hr />
                        </div>

                        <div className="itens">

                            <Form
                                handleAdd={handleAdd}
                                transactionsList={transactionsList}
                                setTransactionsList={setTransactionsList}
                                inventory={inventory}
                            />

                        </div>

                        <div className="Bt-button-cad">
                            <button type="submit" onClick={ConfirmareNota}>
                                <i className="fa fa-check-circle icon-ok-btn"></i> Salvar
                            </button>
                            <button type="submit" onClick={Cancelar}>
                                <i className="fa fa-times icon-cancelar-btn"></i> Cancelar
                            </button>
                        </div>

                    </div>
                </main>
            </div>


            <ModalAlert isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)}>
                <div className='container-modal-alert'>
                    {qualAlert}
                </div>
            </ModalAlert>
        </>
    );

}

export default NotaFiscalAdd;