import { v4 as uuidv4 } from 'uuid';


export const columnsFromBackend = {
  preparacao: {
    title: 'Em Preparação',
    items: [],
  },
  aguardando: {
    title: 'Aguardando Envio',
    items: [],
  },
  campo: {
    title: 'Em Campo',
    items: [],
  },
  alteracao: {
    title: 'Alteração',
    items: [],
  }
};
