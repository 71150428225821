import React, { useState } from "react";
import api from "../../Services/api";
import "./index.css";

export class Painel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tableData: []
        };
    }

    componentDidMount() {
        this.getData();
     
   
      }

    getData() {

        if(this.props.id === ""){


            try {
                api.get("userproductcustomfieid/listar").then((res) => {
                  var data = res.data.data;

                  if (res.data.status === 200) {

                         
                     this.setState({
                            tableData: data
                        });
                      
                  }
                });
        
        
              }
              catch (e) { }









        }else{
        try {
            api.get("/managementpanel/listar", {
              params: {
               id: this.props.id
              }
            }).then((res) => {
              var data = res.data.data;

              if (res.data.status === 200) {
                 this.setState({
                        tableData: data
                    });
                   
              }
            });
    
    
          }
          catch (e) { }

        }

    }

    renderRow(row, i) {
        return <div key={i}>{row}</div>
      }


    render() {
  
        let rows = []
        let I = 0;
        let J = 0;
        let k = 0;

        for (I = 0; I < this.state.tableData.length; I = I + 4) {

            for (J = I, k = 1; J < this.state.tableData.length; J++, k++) {
                if (J < I + 4) {
                 
                    rows.push(
                        <div id={`content${k}`}>
                           <div className="card-painel">                 
                                <span className='descricao-painel'>{this.state.tableData[J].mnp_description}</span>
                           </div>
                        </div>
                    )
                }
            }

        }

      return (
            <div id="container-cad-cust1">
               {rows.map((tdata, i) => (
                this.renderRow(tdata, i)
             ))}
            </div>
        )
    }
}

export default Painel;