import React, { useState, useEffect } from "react";
import Grid from "../eGrid_Item/index";
import FormProduto from '../../components/FormProduto';
import "./index.css";
import { MdOutlineAddCircle } from 'react-icons/md';
import GridSecundario from '../../components/GridSecundario'
import { AxiosResponse } from "axios";
import api from '../../Services/api'
import Loading from '../../components/Loading';
import Select from 'react-select'
import ModalIncluirNovo from '../../components/ModalIncluirNovo/modal'
import ModalAlert from '../../components/ModalAlert/modal'
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from 'react-router';

interface EfromItemProps {
  handleAdd: any;
  transactionsList: any;
  setTransactionsList: any;
  inventory: any
}

const EfromItem: React.FC<EfromItemProps> = ({ handleAdd, transactionsList, setTransactionsList, inventory }) => {

  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false)
	const [qualAlert, setQualAlert] = useState(<></>)
  const [id, setId] = useState("");
  const [productCode, setProductCode] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [product, setProduct] = useState("");
  const [theAmount, setTheAmount] = useState("");
  const [unitValue, setUnitValue] = useState("");
  const [isExpense, setExpense] = useState(false);
  const [itensNota, setItensNota] = useState();
  const [qualGrid, setQualGrid] = useState(<></>)
  const [qualTitulo, setQualTitulo] = useState('')
  const generateID = () => Math.round(Math.random() * 1000000000000000);
  const [dadosProduto, setDadosProduto] = useState([]);
  const [carregaProduto, setCarregaProduto] = useState(GetProduto);
  const [optionsProduto, setOptionsProduto] = useState([])
  const [openModalIncluirNovo, setOpenModalIncluirNovo] = useState(false)

  const [productCodeDisabled, setProductCodeDisabled] = useState(false)
  const [productDescriptionDisabled, setProductDescriptionDisabled] = useState(false)
  const [productDisabled, setProductDisabled] = useState(false)
  const [unitValueDisabled, setUnitValueDisabled] = useState(false)
  const [button_itensDisabled, setButton_itensDisabled] = useState(false)
  const [theAmountDisabled, setTheAmountDisabled]= useState(false)


  useEffect(() => {
    validatToken();
  }, []);


  async function validatToken() {

    let ret: AxiosResponse;
    let valor: any;

    const _user = secureLocalStorage.getItem('t')

    api.defaults.headers.common['Authorization'] = _user as ''

    try {

        ret = await api.get("/status/validar")

        if (ret.data.status === 200) {

            secureLocalStorage.setItem('t', ret.data.data);
            secureLocalStorage.setItem('acp', ret.data.decodedToken.acp_integrationid);
            secureLocalStorage.setItem('n', ret.data.decodedToken.name);
            secureLocalStorage.setItem('iag', ret.data.decodedToken.id);
            secureLocalStorage.setItem('cy', ret.data.decodedToken.company);

            if(ret.data.decodedToken.acp_integrationid === 'CONSULTOR'){
              setProductCodeDisabled(true)
              setProductDescriptionDisabled(true)
              setProductDisabled(true)
              setUnitValueDisabled(true)
              setButton_itensDisabled(true)
              setTheAmountDisabled(true)
            }

        }

    } catch (e: any) {

        if (e.response.data.status === 403) {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
                <div className='btn-alert' onClick={fecharModalToken}><span className="btn-alert-lab">OK</span></div>
            </div>)
        }
    }
}

function fecharModalToken() {

  secureLocalStorage.removeItem('t')
  secureLocalStorage.removeItem('acp')
  secureLocalStorage.removeItem('n')
  secureLocalStorage.removeItem('iag')
  navigate('/login');
  setOpenModal(false)

}

  function geraStringAleatoria() {
    var stringAleatoria = '';
    var caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (var i = 0; i < 8; i++) {
      stringAleatoria += caracteres.charAt(Math.floor(Math.random() * caracteres.length));
    }
    return stringAleatoria;
  }

  const handleSave = () => {

    if (inventory !== 'S') {
      if (productCode === "") {

        setQualAlert(<div className='div-alert'>
						<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
						<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
						<div className='div-info-alert'><span className="info-alert">Digite o Código do produto.</span></div>
						<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
					</div>)
					setOpenModal(true)

      } else if (productDescription === "") {

        setQualAlert(<div className='div-alert'>
        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
        <div className='div-info-alert'><span className="info-alert">Digite a descrição do produto.</span></div>
        <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
      </div>)
      setOpenModal(true)

      } else if (product === "") {

        setQualAlert(<div className='div-alert'>
        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
        <div className='div-info-alert'><span className="info-alert">Selecione o produto.</span></div>
        <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
      </div>)

      setOpenModal(true)

      } else if (theAmount === "") {

        setQualAlert(<div className='div-alert'>
        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
        <div className='div-info-alert'><span className="info-alert">Digite a quatidade.</span></div>
        <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
      </div>)
      
      setOpenModal(true)

      } else if (unitValue === "") {

        setQualAlert(<div className='div-alert'>
        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
        <div className='div-info-alert'><span className="info-alert">Digite o valor unitário.</span></div>
        <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
      </div>)
      
      setOpenModal(true)

      } else {

        const transaction = {
          int_id: geraStringAleatoria(),
          int_productcode: productCode,
          int_productdescription: productDescription,
          pdc_id: product,
          int_theamount: theAmount,
          int_unitvalue: unitValue,
          expense: isExpense,
        };

        handleAdd(transaction);
        setProductCode('');
        setProductDescription('');
        setProduct('');
        setTheAmount('');
        setUnitValue('');
      }
    } else {

      setQualAlert(<div className='div-alert'>
      <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
      <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
      <div className='div-info-alert'><span className="info-alert">Não é possivel alterar nota vinculada ao cadastro de ativo.</span></div>
      <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
    </div>)
    
    setOpenModal(true)

    }
  };

  function finalizarModal2() {
		setOpenModal(false)
	}

	function fecharOpenModalIncluirNovo() {
		setOpenModalIncluirNovo(false)
	}

  async function GetProduto() {
    let ret: AxiosResponse;
    let valor: any;
    try {
      ret = await api.get("product/listar")
      if (ret.data.status === 200) {
        valor = ret.data.data;

        const arr = [] as any;
        let result = valor;
        result.map((user: any) => {
          return arr.push({ value: String(user.pdc_id), label: user.pdc_description });
        });

        setOptionsProduto(arr)


      }
    } catch (e) {
      setOptionsProduto([])
    }
  }

  const renderProduto = dadosProduto.map((comp: any) => {
    return (
      <option key={comp.pdc_id} value={comp.pdc_id}>
        {" "}
        {comp.pdc_description}{" "}
      </option>
    );
  });

  function Updatestatus(data: any) {
    setItensNota(data)
  }

  function PegarValorGridProduct(valor: any) {

    IncluirProduto(String(valor.pdc_id))
    setOpenModalIncluirNovo(false)

}

  function incluirProduto() {

    if(secureLocalStorage.getItem('acp') !== 'CONSULTOR'){
    setOpenModalIncluirNovo(true);
    setQualTitulo('Produto')
    setQualGrid(<><><GridSecundario
      PegarValorGrid={PegarValorGridProduct}
      Iconeadd={ChamarCadastroProduto}
      VoltarModalGrid={VoltarModalGrid}
      iconeExcluir={ModalDeletar}
      Iconeditar={EditarCustomizado}
      th1='Ativo'
      th2='Descrição'
      th3='Identificador Alternativo'
      th4='Subcategoria'
      th5='Data da Inclusão'
      th6='Data Atualização'
      endPoint='product/listar'
      consult='N'
      col1='1'
      col2='2'
      col3='3'
      ambiente=''
      dbtnDelete="N"
    /></>
    </>)
    }
  }

  function VoltarModalGrid() {
    GetProduto();
    setQualTitulo('Produto')
    setQualGrid(<><><GridSecundario
      PegarValorGrid={PegarValorGridProduct}
      Iconeadd={ChamarCadastroProduto}
      VoltarModalGrid={VoltarModalGrid}
      iconeExcluir={ModalDeletar}
      Iconeditar={EditarCustomizado}
      th1='Ativo'
      th2='Descrição'
      th3='Identificador Alternativo'
      th4='Subcategoria'
      th5='Data da Inclusão'
      th6='Data Atualização'
      endPoint='product/listar'
      consult='N'
      col1='1'
      col2='2'
      col3='3'
      ambiente='%'
      dbtnDelete="N"
    /></>
    </>)
  }

  function ModalDeletar(obj: any) {

    setQualAlert(<div className='div-alert'>
    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
    <div className='div-info-alert-atencao'><span className="info-alert-atencao">Deseja deletar o registro?</span></div>
    <div className='div-info-alert'><span className="info-alert">Você não poderá reverter.</span></div>

    <div className='btn-alert-div'>
      <span className='btn-alert-esc' onClick={() => deletarProduto(obj.pdc_id)}>
        <span className="btn-alert-lab">SIM</span>
      </span>
      <span className='btn-alert-dir' onClick={finalizarModal2}>
        <span className="btn-alert-lab">NÃO</span>
      </span>
    </div>
  </div>)

  setOpenModal(true)

  }

  function finalizarModal1() {
    VoltarModalGrid();
	}

  async function deletarProduto(id: any) {

    setQualGrid(<><Loading /></>)

    let ret: AxiosResponse;

    try {
      ret = await api.get("product/encontrarprodinv", {
        params: {
          id: id
        }
      })
      if (ret.data.status === 200) {
        if (ret.data.data.length > 0) {

          setQualAlert(<div className='div-alert'>
          <span className="fa-regular fa-circle-check ControleIconAlert"></span>
          <div className='div-info-alert'><span className="info-alert">Não é possível excluir um produto vinculado ao cadastro de ativos.</span></div>
          <div className='btn-alert' onClick={finalizarModal1}><span className="btn-alert-lab">OK</span></div>
        </div>)

        setOpenModal(true)

          setQualGrid(<><><GridSecundario
            PegarValorGrid={PegarValorGridProduct}
            Iconeadd={ChamarCadastroProduto}
            VoltarModalGrid={VoltarModalGrid}
            iconeExcluir={ModalDeletar}
            Iconeditar={EditarCustomizado}
            th1='Ativo'
            th2='Descrição'
            th3='Identificador Alternativo'
            th4='Subcategoria'
            th5='Data da Inclusão'
            th6='Data Atualização'
            endPoint='product/listar'
            consult='N'
            col1='1'
            col2='2'
            col3='3'
            ambiente='%'
            dbtnDelete="N"
          /></>
          </>)
        } else {
          let ret: AxiosResponse;
          const dados = [{
            id: id,
          }]

          try {
            ret = await api.delete("product/delete", {
              data: { id: id }
            });
            if (ret.data.status === 200) {

              
							setQualAlert(<div className='div-alert'>
              <span className="fa-regular fa-circle-check ControleIconAlert"></span>
              <div className='div-info-alert'><span className="info-alert">O produto foi excluída com sucesso!</span></div>
              <div className='btn-alert' onClick={finalizarModal1}><span className="btn-alert-lab">OK</span></div>
            </div>)

             
            }
          } catch (e: any) {
            setQualGrid(<><><GridSecundario
              PegarValorGrid={PegarValorGridProduct}
              Iconeadd={ChamarCadastroProduto}
              VoltarModalGrid={VoltarModalGrid}
              iconeExcluir={ModalDeletar}
              Iconeditar={EditarCustomizado}
              th1='Ativo'
              th2='Descrição'
              th3='Identificador Alternativo'
              th4='Subcategoria'
              th5='Data da Inclusão'
              th6='Data Atualização'
              endPoint='product/listar'
              consult='N'
              col1='1'
              col2='2'
              col3='3'
              ambiente='%'
              dbtnDelete="N"
            /></>
            </>)
          }
        }
      }
    } catch (e) {
      setQualGrid(<><><GridSecundario
        PegarValorGrid={PegarValorGridProduct}
        Iconeadd={ChamarCadastroProduto}
        VoltarModalGrid={VoltarModalGrid}
        iconeExcluir={ModalDeletar}
        Iconeditar={EditarCustomizado}
        th1='Ativo'
        th2='Descrição'
        th3='Identificador Alternativo'
        th4='Subcategoria'
        th5='Data da Inclusão'
        th6='Data Atualização'
        endPoint='product/listar'
        consult='N'
        col1='1'
        col2='2'
        col3='3'
        ambiente='%'
        dbtnDelete="N"
      /></>
      </>)
    }
  }

  async function EditarCustomizado(obj: any) {
    sessionStorage.setItem("dadosProd", JSON.stringify(obj));
    setQualTitulo('Cadastrar Produto')

    setQualGrid(<><FormProduto VoltarModalGrid={VoltarModalGrid} /></>)
  }

  function ChamarCadastroProduto() {
    setQualTitulo('Cadastrar Produto')
    sessionStorage.removeItem("dadosProd");
    setQualGrid(<><FormProduto VoltarModalGrid={VoltarModalGrid} /></>)
  }

  function produtoValor(e: any) {
    const { option, value, id } = e.target
 
  }


  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      background: '#fff',
      minHeight: '30px',
      height: '30px',
      borderColor: state.isFocused ? 'grey' : '#92989e',

    }),


    valueContainer: (provided: any, state: any) => ({
      ...provided,
      height: '30px',
      padding: '0 6px'
    }),

    input: (provided: any, state: any) => ({
      ...provided,
      margin: '0px',
      fontSize: 14,

    }),
    indicatorsContainer: (provided: any, state: any) => ({
      ...provided,
      height: '30px',
    }),
  };

  async function IncluirProduto(produto: any) {
    setProduct(produto)
  }

  function incluirValor(value: any) {
    var valor = value
    valor = valor + '';
    valor = parseInt(valor.replace(/[\D]+/g, ''));
    valor = valor + '';
    valor = valor.replace(/([0-9]{2})$/g, ".$1");
    if (valor.length > 6) {
        valor = valor.replace(/([0-9]{3}),([0-9]{2}$)/g, "$1.$2");
    }
    setUnitValue(valor)
    if (valor == 'NaN') {
        setUnitValue('');
    }
  }


  function validarNumber(number: any) {
    var valor = number

    valor = valor + '';
    valor = parseInt(valor.replace(/[\D]+/g, ''));
    valor = valor + '';

    if (valor == 'NaN') {
      setTheAmount('')
    } else {
      setTheAmount(valor)
    }

  }


  return (
    <>
      <div className="Container">
        <div className="user-details">
          <div className="container-cad-prod7">
            <div id="content1">
              <div className="input-box-ativos">
                <span className="details">Código produto</span>
                <input
                 autoComplete="off"
                  type="text"
                  value={productCode}
                  onChange={(e) => setProductCode(e.target.value)}
                  disabled={productCodeDisabled}
                />
              </div>
            </div>
            <div id="content2">
              <div className="input-box-ativos">
                <span className="details">Descrição do Produto</span>
                <input
                 autoComplete="off"
                  type="text"
                  value={productDescription}
                  onChange={(e) => setProductDescription(e.target.value)}
                  disabled={productDescriptionDisabled}
                />
              </div>
            </div>
            <div id="content3">
              <div className="select-box-ativos-select">
                <div className='details-flex'>
                  <span className="details">Produto</span>
                </div>
                <Select
                 maxMenuHeight={120}
                  placeholder={'Selecione...'}
                  options={optionsProduto}
                  styles={customStyles}
                  value={optionsProduto.filter(function (option: any) {
                    return option.value === product;
                  })}
                  onChange={(options: any) =>
                    !options ? IncluirProduto("") : IncluirProduto(options.value)
                  }
                  isClearable
                  noOptionsMessage={() => "Não encontrado..."}
                  theme={(theme: any) => ({
                    ...theme,
                    borderRadius: 4,
                    colors: {
                      ...theme.colors,
                      primary25: '#ebebeb',
                      primary: 'black',
                    },
                  })}
                  isDisabled={productDisabled}
                />
              </div>
            </div>
            <div id="content4">
              <div className="input-box-ativos">
                <span className="details">Quantidade</span>
                <input
                 autoComplete="off"
                  type="text"
                  value={theAmount}
                  onChange={(e) => validarNumber(e.target.value)}
                  disabled={theAmountDisabled}
                />
              </div>
            </div>
            <div id="content5">
              <div className="input-box-ativos">
                <span className="details">Valor Unit.</span>
                <input
                 autoComplete="off"
                  type="text"
                  placeholder='R$'
                  value={unitValue}
                  onChange={(e) => incluirValor(e.target.value)}
                  disabled={unitValueDisabled}

                />
              </div>
            </div>
            <div id="content6">
              <div className="input-box-ativos">
                <button className="button_itens" onClick={handleSave} disabled={button_itensDisabled}>
                  ADICIONAR
                </button>
              </div>
            </div>
          </div>

        </div>
      </div>

      <Grid itens={transactionsList} setItens={setTransactionsList} Updatestatus={Updatestatus} inventory={inventory} />


      <ModalIncluirNovo isOpen={openModalIncluirNovo} setModalOpen={() => setOpenModalIncluirNovo(!openModalIncluirNovo)}>
				<>
					<div className='title-modal-incluir'>
						<h1 className="modal-IncluirNovo-title">{qualTitulo}</h1>
						<button className="fa fa-times icone-modal-IncluirNovo" data-dismiss="modal" aria-label="Close" onClick={fecharOpenModalIncluirNovo}></button>
					</div>

					<div className='container-modal-incluir'>
						{qualGrid}
					</div>
				</>
			</ModalIncluirNovo>

			<ModalAlert isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)}>
				<div className='container-modal-alert'>
					{qualAlert}
				</div>
			</ModalAlert>

    </>
  );
};

export default EfromItem;
