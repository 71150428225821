
import React, { useState, useEffect } from 'react'
import Nav from "../../components/Nav";
import Header from "../../components/Header";
import './index.css'
import api from "../../Services/api";
import { AxiosResponse } from "axios";
import Accordion from '../../components/Accordion/Accordion'
import Loading from '../../components/Loading';
import Swal from 'sweetalert2';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import ModalAlert from '../../components/ModalAlert/modal'
import secureLocalStorage from "react-secure-storage";

function DetalhamentoItensNotas() {

    let { id } = useParams();
    const navigate = useNavigate();
    const [qualInf, setQualInf] = useState(<></>)
    const dadosInv = (localStorage.getItem("detalhamentoNotas") || '{}');
    let userInv = JSON.parse(dadosInv);
    const [titulo, setTitulo] = useState("")
    const [openModal, setOpenModal] = useState(false)
    const [qualAlert, setQualAlert] = useState(<></>)

    useEffect(() => {

        setQualAlert(<div className='div-alert'><Loading /></div>)
		setOpenModal(true)

        validatToken();
        
    }, []);


    async function validatToken() {

		let ret: AxiosResponse;
		let valor: any;

		const _user = secureLocalStorage.getItem('t')

		api.defaults.headers.common['Authorization'] = _user as ''

		try {

			ret = await api.get("/status/validar")

			if (ret.data.status === 200) {

				secureLocalStorage.setItem('t', ret.data.data);
				secureLocalStorage.setItem('acp', ret.data.decodedToken.acp_integrationid);
				secureLocalStorage.setItem('n', ret.data.decodedToken.name);
				secureLocalStorage.setItem('iag', ret.data.decodedToken.id);
				secureLocalStorage.setItem('cy', ret.data.decodedToken.company);


               await incluirInventario(userInv)

				setOpenModal(false)

			}

		} catch (e: any) {

			if (e.response.data.status === 403) {

				setQualAlert(<div className='div-alert'>
					<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
					<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
					<div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
					<div className='btn-alert' onClick={fecharModalToken}><span className="btn-alert-lab">OK</span></div>
				</div>)


			}
		}

	}

    function fecharModalToken() {

		secureLocalStorage.removeItem('t')
		secureLocalStorage.removeItem('acp')
		secureLocalStorage.removeItem('n')
		secureLocalStorage.removeItem('iag')
		navigate('/login');
		setOpenModal(false)

	}

    async function incluirInventario(obj: any) {
        setQualInf(<><Loading /></>)
        let ret: AxiosResponse;
        let valor: any;

        if (/^\d+$/.test(id || '') === true) {
            try {
                ret = await api.get("invoiceitems/exibiritens", {
                    params: {
                        id: id,
                    }
                })

                if (ret.data.status === 200) {

                    valor = ret.data.data;

                    setTitulo(ret.data.data[0].inc_invoicenumber || '')
                    setQualInf(<>
                        <section className="info"> {valor.map((question: any) => {
                            return <Accordion key={question.int_id} obj={question} confirmarInclusao={confirmarInclusao} />;
                        })} </section></>)

                } else {
                    navigate('/invoice/detailingitemsnotes/' + id + '/n');
                }
            } catch (e) {
   
                navigate('/invoice/detailingitemsnotes/' + id + '/n');
            }
        } else {
            navigate('/invoice/detailingitemsnotes/' + id + '/n');
        }

    }



    function confirmarInclusao(obj: any, id: any) {

        setQualAlert(<div className='div-alert'>
            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
            <div className='div-info-alert-atencao'><span className="info-alert-atencao">Deseja deletar o registro?</span></div>
            <div className='div-info-alert'><span className="info-alert">Você não poderá alterar os itens da nota, após a inclusão no cadastro de ativo. Deseja continuar?</span></div>

            <div className='btn-alert-div'>
                <span className='btn-alert-esc' onClick={() => insertInventario(obj, id)}>
                    <span className="btn-alert-lab">SIM</span>
                </span>
                <span className='btn-alert-dir' onClick={finalizarModal2}>
                    <span className="btn-alert-lab">NÃO</span>
                </span>
            </div>
        </div>)

        setOpenModal(true)

    }

    function finalizarModal2() {
        setOpenModal(false)
    }

    async function insertInventario(obj: any, id: any) {
        setOpenModal(false)

        let ret: AxiosResponse;
        let valor: any;

  
        try {
            ret = await api.post("asset/insert", {
                data: obj,
                id: id,
            });
            if (ret.data.status === 200) {
                incluirInventario(id)
            }

        } catch (e: any) {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Não foi possivel concluir o cadastramento.</span></div>
                <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
            </div>)

        }
    }

    function fecharModal() {
        setOpenModal(false)
    }

    async function UpdateItensNota(obj: any, id: any, obj2: any) {

        let ret: AxiosResponse;
        const dados = {
            int_status: 'S',
            int_id: obj[0].int_id
        };

        try {
            ret = await api.put("invoiceitems/update", {
                data: dados,
            });
            if (ret.data.status === 200) {
                // setQualInf(<><Loading/></>)
                //incluirInventario2(id)
                // insertCustos(obj2, id)
            }
        } catch (e: any) {
          
        }
    }

    async function insertCustos(obj: any, id: any) {
        let ret: AxiosResponse;
        let newArray = [];
        let i = 0;



        for (i = 0; i < obj.length; i++) {
            newArray.push({
                age_id: secureLocalStorage.getItem('iag'),
                ast_id: obj[i].ast_id,
                cst_typecost: 'Compra',
                cst_value: obj[i].ivt_cust
            })
        }

        try {
            ret = await api.post("costs/insert", {
                data: newArray,
            });
            if (ret.data.status === 200) {
                // inclurAtividadeInsert(obj, ret.data.data, id)

            }
        } catch (e: any) {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Não foi possível concluir o cadastramento.</span></div>
                <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
            </div>)

            setOpenModal(true)

        }

    }

    async function inclurAtividadeInsert(obj: any, obj2: any, id: any) {
        let ret: AxiosResponse;
        let newArray = []
        let i = 0

        for (i = 0; i < obj.length; i++) { 
            newArray.push({ ast_id: obj[i].ast_id, age_id: secureLocalStorage.getItem('iag'), atv_type: 'ativo', atv_description: `Incluiu o ativo ${obj[i].ast_description} ao cadastro.`, atv_idcontrol: obj[i].ast_id })
        }

        try {
            ret = await api.post("activity/insert", {
                data: newArray,
            });
            if (ret.data.status === 200) {
                //  inclurAtividadeDespesas(obj2, id)
            }
        } catch (e: any) {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Não foi possível concluir o cadastramento.</span></div>
                <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
            </div>)


            setOpenModal(true)
        }
    }

    async function inclurAtividadeDespesas(obj: any, id: any) {

        let ret: AxiosResponse;
        let newArray = []
        let i = 0

        for (i = 0; i < obj.length; i++) {
            newArray.push({ ast_id: obj[i].ast_id, 
                age_id: obj[i].age_id, 
                atv_type: 'custos', 
                atv_description: `Incluiu um custo do tipo ${obj[i].cst_typecost} no valor de R$ ${obj[i].cst_value}.`, atv_idcontrol: obj[i].cst_id })
        }

        try {
            ret = await api.post("activity/insert", {
                data: newArray,
            });
            if (ret.data.status === 200) {
                //  setQualInf(<><Loading/></>)
                // UpdateNota(id)
            }
        } catch (e: any) {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Não foi possível concluir o cadastramento.</span></div>
                <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
            </div>)

            setOpenModal(true)


        }
    }

    async function UpdateNota(id: any) {


        let ret: AxiosResponse;
        const dados = {
            inc_inclusion: 'S',
            inc_id: id
        };

        try {
            ret = await api.put("invoice/updateinventory", {
                data: dados,
            });
            if (ret.data.status === 200) {
                setQualInf(<><Loading /></>)

                //   incluirInventario(id)

            }
        } catch (e: any) {

        }
    }



    return (
        <>
            <Nav />
            <div className="main-content">
                <Header />
                <main>
                    <div className='cad-prod'>

                        <h1 className='titulo-card-visualizacao'>ITEMS NOTA FISCAL Nº {titulo}</h1>
                        {qualInf}

                    </div>
                </main>
            </div>

            <ModalAlert isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)}>
                <div className='container-modal-alert'>
                    {qualAlert}
                </div>
            </ModalAlert>


        </>
    );

}

export default DetalhamentoItensNotas;