import React, { useEffect, useState } from 'react'
import Nav from "../../components/Nav";
import Header from "../../components/Header";
import GridPrincipal from '../../components/GridPrincipal'
import { AxiosResponse } from "axios";
import api from "../../Services/api";
import DropdownItem from '../../components/DropdownItem'
import GridSecundario from '../../components/GridSecundario'
import FormComposCustomizados from '../../components/FormComposCustomizados'
import Loading from '../../components/Loading';
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from 'react-router';
import ModalIncluirNovo from '../../components/ModalIncluirNovo/modal'
import ModalPrincipal from '../../components/ModalAlert/modal'
import { FaBarcode } from "react-icons/fa6";

function Produto() {

    const navigate = useNavigate();

    const [qualInf, setQualInf] = useState(<></>)
    const [titulo, setTitulo] = useState('');
    const [exibirGrid, setExibirGrid] = useState(<></>);

    const [openModal, setOpenModal] = useState(false)
    const [qualAlert, setQualAlert] = useState(<></>)

    const [openModalIncluirNovo, setOpenModalIncluirNovo] = useState(false)
    const [tipoUser, setTipoUser] = useState('')

    useEffect(() => {

        setQualAlert(<div className='div-alert'><Loading /></div>)
        setOpenModal(true)

        validatToken()
    }, []);


    async function validatToken() {

        let ret: AxiosResponse;
        let valor: any;

        const _user = secureLocalStorage.getItem('t')

        api.defaults.headers.common['Authorization'] = _user as ''

        try {

            ret = await api.get("/status/validar")

            if (ret.data.status === 200) {

                setTipoUser(ret.data.decodedToken.acp_integrationid)
                secureLocalStorage.setItem('t', ret.data.data);
                secureLocalStorage.setItem('acp', ret.data.decodedToken.acp_integrationid);
                secureLocalStorage.setItem('n', ret.data.decodedToken.name);
                secureLocalStorage.setItem('iag', ret.data.decodedToken.id);
                secureLocalStorage.setItem('cy', ret.data.decodedToken.company);

                setExibirGrid(<GridPrincipal
                    iconeExcluir={ModalDeletar}
                    th1='Ativo'
                    th2='ID'
                    th3='Descrição'
                    th4='Categoria'
                    th5='Subcategoria'
                    th6='Data da Inclusão'
                    endPoint='product/buscarproduto'
                    col1='1'
                    col2='0'
                    col3='2'
                    col4='3'
                    col5='4'
                    col6='5'
                    fromAdd='/product/add'
                    fromEdit='/product/edit'
                    btnDelete='N'
                    pag="produto"
                    buscarproduto='product/buscarproduto'
                    category='category/listar'
                    subcategory='subcategory/listarsubcategorytotal'
                    manufacturer='manufacturer/listar'
                    brand='brand/listarcomp'
                    Model='model/listarcomp'
                    fecharModal={fecharModal}
                />)

                setOpenModal(false)
            }

        } catch (e: any) {

            if (e.response.data.status === 403) {

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
                    <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)

            }
        }

    }

    function fecharModal() {

        secureLocalStorage.removeItem('t')
        secureLocalStorage.removeItem('acp')
        secureLocalStorage.removeItem('n')
        secureLocalStorage.removeItem('iag')
        navigate('/login');
        setOpenModal(false)

    }

    function confirmarPerfil() {
        setOpenModal(false)
    }

    function ModalDeletar(obj: any) {

        const _type = secureLocalStorage.getItem('acp')

        if (_type === 'DESENVOLVEDOR' || _type === 'ADMINISTRADOR') {
            setOpenModal(true)

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">Deseja deletar o registro?</span></div>
                <div className='div-info-alert'><span className="info-alert">Você não poderá reverter.</span></div>

                <div className='btn-alert-div'>
                    <span className='btn-alert-esc' onClick={() => deletarProduto(obj.pdc_id)}>
                        <span className="btn-alert-lab">SIM</span>
                    </span>
                    <span className='btn-alert-dir' onClick={confirmarPerfil}>
                        <span className="btn-alert-lab">NÃO</span>
                    </span>
                </div>
            </div>
            )
        } else if (_type === 'CONSULTOR') {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">O seu perfil de acesso não permite deletar registros.</span></div>
                <div className='btn-alert' onClick={confirmarPerfil}><span className="btn-alert-lab">OK</span></div>
            </div>)

            setOpenModal(true)

        }

    }

    function finalizarModal1() {
        window.location.reload();
    }

    async function deletarProduto(id: any) {
        let ret: AxiosResponse;

        try {
            ret = await api.get("product/encontrarprodinv", {
                params: {
                    id: id
                }
            })
            if (ret.data.status === 200) {
                if (ret.data.data.length > 0) {

                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-check ControleIconAlert"></span>
                        <div className='div-info-alert'><span className="info-alert">Não é possível excluir um produto vinculado ao cadastro de ativos.</span></div>
                        <div className='btn-alert' onClick={finalizarModal1}><span className="btn-alert-lab">OK</span></div>
                    </div>)

                } else {
                    let ret: AxiosResponse;
                    const dados = [{
                        id: id,
                    }]

                    try {
                        ret = await api.delete("product/delete", {
                            data: { id: id }
                        });
                        if (ret.data.status === 200) {

                            setQualAlert(<div className='div-alert'>
                                <span className="fa-regular fa-circle-check ControleIconAlert"></span>
                                <div className='div-info-alert'><span className="info-alert">O produto foi excluída com sucesso.</span></div>
                                <div className='btn-alert' onClick={finalizarModal1}><span className="btn-alert-lab">OK</span></div>
                            </div>)

                        }
                    } catch (e: any) {

                        setQualAlert(<div className='div-alert'>
                            <span className="fa-regular fa-circle-check ControleIconAlert"></span>
                            <div className='div-info-alert'><span className="info-alert">Não foi possível deletar o produto.</span></div>
                            <div className='btn-alert' onClick={finalizarModal1}><span className="btn-alert-lab">OK</span></div>
                        </div>)

                    }
                }
            }
        } catch (e) {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-check ControleIconAlert"></span>
                <div className='div-info-alert'><span className="info-alert">Não foi possível deletar o produto.</span></div>
                <div className='btn-alert' onClick={finalizarModal1}><span className="btn-alert-lab">OK</span></div>
            </div>)


        }

    }


    function ChamarCadastroCustomizado() {
        sessionStorage.removeItem("dadosFab");
        setTitulo('Cadastrar Campos Customizados')
        setQualInf(<FormComposCustomizados voltar={BtnVoltarCustomizado} />)

    }

    function customizadoDeletar(obj: any) {


        const _type = secureLocalStorage.getItem('acp')

        if (_type === 'DESENVOLVEDOR' || _type === 'ADMINISTRADOR') {
            setOpenModal(true)

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">Deseja deletar o registro?</span></div>
                <div className='div-info-alert'><span className="info-alert">Você não poderá reverter.</span></div>

                <div className='btn-alert-div'>
                    <span className='btn-alert-esc' onClick={() => deletarcustomizado(obj.pdt_id)}>
                        <span className="btn-alert-lab">SIM</span>
                    </span>
                    <span className='btn-alert-dir' onClick={confirmarPerfil}>
                        <span className="btn-alert-lab">NÃO</span>
                    </span>
                </div>
            </div>
            )
        } else if (_type === 'CONSULTOR') {
            setOpenModal(true)
            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">O seu perfil de acesso não permite deletar registros.</span></div>
                <div className='btn-alert' onClick={confirmarPerfil}><span className="btn-alert-lab">OK</span></div>
            </div>)

        }

    }

    function PegarValorGrid() {

    }

    async function deletarcustomizado(id: any) {
        setQualInf(<><Loading /></>)
        let ret: AxiosResponse;
        try {
            ret = await api.delete("userproductcustomfieid/delete", {
                data: { id: id }
            });
            if (ret.data.status === 200) {
                setTitulo('Cadastrar Campos Customizados')
                setQualInf(<GridSecundario
                    PegarValorGrid={PegarValorGrid}
                    Iconeadd={ChamarCadastroCustomizado}
                    Iconeditar={EditarCustomizado}
                    iconeExcluir={customizadoDeletar}
                    th1='Ativo'
                    th2='Descrição'
                    th3='Identificador Alternativo'
                    th4='Subcategoria'
                    th5='Data da Inclusão'
                    th6='Data Atualização'
                    endPoint='userproductcustomfieid/listar'
                    consult='N'
                    col1='1'
                    col2='2'
                    col3='3'
                    ambiente='%'
                />)
            }
        } catch (e: any) {
          
        }
    }

    async function EditarCustomizado(obj: any) {
        sessionStorage.setItem("dadosFab", JSON.stringify(obj));
        setTitulo('Cadastrar Campos Customizados')
        setQualInf(<FormComposCustomizados voltar={BtnVoltarCustomizado} />)
    }

    function BtnVoltarCustomizado() {
        setTitulo('Cadastrar Campos Customizados')
        setQualInf(<GridSecundario
            PegarValorGrid={PegarValorGrid}
            Iconeadd={ChamarCadastroCustomizado}
            Iconeditar={EditarCustomizado}
            iconeExcluir={customizadoDeletar}
            th1='Ativo'
            th2='Descrição'
            th3='Identificador Alternativo'
            th4='Subcategoria'
            th5='Data da Inclusão'
            th6='Data Atualização'
            endPoint='userproductcustomfieid/listar'
            consult='N'
            col1='1'
            col2='2'
            col3='3'
            ambiente='%'
            dbtnDelete='N'
        />)

    }


    async function funtoggle(form: any) {
        let ret: AxiosResponse;
        let valor: any;

        const _user = secureLocalStorage.getItem('t')

        api.defaults.headers.common['Authorization'] = _user as ''



        try {

            ret = await api.get("/status/validar")

            if (ret.data.status === 200) {

                secureLocalStorage.setItem('t', ret.data.data);
                secureLocalStorage.setItem('acp', ret.data.decodedToken.acp_integrationid);
                secureLocalStorage.setItem('n', ret.data.decodedToken.name);
                secureLocalStorage.setItem('iag', ret.data.decodedToken.id);
                secureLocalStorage.setItem('cy', ret.data.decodedToken.company);

                if (tipoUser === 'DESENVOLVEDOR' || tipoUser === 'ADMINISTRADOR') {

                    if (form === 'Customizados') {

                        setTitulo('Campos Customizados')
                        setQualInf(<GridSecundario
                            PegarValorGrid={PegarValorGrid}
                            Iconeadd={ChamarCadastroCustomizado}
                            Iconeditar={EditarCustomizado}
                            iconeExcluir={customizadoDeletar}
                            th1='Ativo'
                            th2='Descrição'
                            th3='Identificador Alternativo'
                            th4='Subcategoria'
                            th5='Data da Inclusão'
                            th6='Data Atualização'
                            endPoint='userproductcustomfieid/listar'
                            consult='N'
                            col1='1'
                            col2='2'
                            col3='3'
                            ambiente='%'
                            dbtnDelete='N'

                        />)


                        setOpenModalIncluirNovo(true)

                    }
                } else if (tipoUser === 'CONSULTOR') {

                    setOpenModal(true)
                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">O seu perfil de acesso não permite deletar registros.</span></div>
                        <div className='btn-alert' onClick={confirmarPerfil}><span className="btn-alert-lab">OK</span></div>
                    </div>)

                }





            }

        } catch (e: any) {

            if (e.response.data.status === 403) {

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
                    <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)

            }
        }



    }

    function fecharOpenModalIncluirNovo() {
        setOpenModalIncluirNovo(false)
    }


    return (
        <>
            <Nav />
            <div className="main-content">
                <Header />
                <main>
                    <div className='containerPrincipal'>
                        <div className='ContainerBody'>
                            <div className='div-titulo-pages'>
                                <FaBarcode />
                                <h1 className='div-titulo-pages-text'>Produto</h1>
                            </div>
                          
                        </div>
                        <div className='grid-container-principal'>
                            {exibirGrid}
                        </div>
                    </div>
                </main>
            </div>


            <ModalIncluirNovo isOpen={openModalIncluirNovo} setModalOpen={() => setOpenModalIncluirNovo(!openModalIncluirNovo)}>
                <>
                    <div className='title-modal-incluir'>
                        <h1 className="modal-IncluirNovo-title">{titulo}</h1>
                        <div onClick={fecharOpenModalIncluirNovo} >
                            <button className="fa fa-times icone-modal-IncluirNovo" data-dismiss="modal" aria-label="Close"></button>
                        </div>
                    </div>

                    <div className='scrol-modal'>
                        {qualInf}
                    </div>
                </>
            </ModalIncluirNovo>

            <ModalPrincipal isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)}>
                <div className='container-modal-alert'>
                    {qualAlert}
                </div>
            </ModalPrincipal>

        </>
    );

}

export default Produto;