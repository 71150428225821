import React, { useState, useEffect } from 'react'
import Nav from "../../components/Nav";
import Header from "../../components/Header";
import './index.css'
import Select from 'react-select'
import api from '../../Services/api'
import { AxiosResponse } from "axios";
import { MdOutlineAddCircle } from 'react-icons/md';
import { useNavigate } from 'react-router';
import ModalAlert from '../../components/ModalAlert/modal'
import Loading from '../../components/Loading'
import Beautiful from '../../components/Beautiful'
import GridSecundario from '../../components/GridSecundario'
import FormTipoAtividade from '../../components/FormTipoAtividade'
import DualListBox from "react-dual-listbox";
import secureLocalStorage from "react-secure-storage";
import ModalIncluirNovo from '../../components/ModalIncluirNovo/modal'
import FormContrato from '../../components/FormContrato';
import FormUnidade from '../../components/FormUnidade';
import FormTipoLocal from '../../components/FormTipoLocal';

function PrestadoresAdd() {
	const navigate = useNavigate();

	const [qualTitulo, setQualTitulo] = useState('')
	const [qualGrid, setQualGrid] = useState(<></>)
	const [openModal, setOpenModal] = useState(false)
	const [qualAlert, setQualAlert] = useState(<></>)
	const [titulo, setTitulo] = useState('');

	const [svp_active, setSvp_active] = useState(true)
	const [svp_enablecall, setSvp_enablecall] = useState(false)
	const [svp_corporatename, setSvp_corporatename] = useState('')
	const [svp_cnpj, setSvp_cnpj] = useState('')
	const [svp_country, setSvp_country] = useState('')
	const [svp_state, setSvp_state] = useState('')
	const [svp_city, setSvp_city] = useState('')
	const [svp_cityneighborhood, setSvp_cityneighborhood] = useState('')
	const [svp_streettype, setSvp_streettype] = useState('')
	const [svp_street, setSvp_street] = useState('')
	const [svp_streetnumber, setSvp_streetnumber] = useState('')
	const [svp_streetcomplement, setSvp_streetcomplement] = useState('')
	const [svp_zipcode, setSvp_zipcode] = useState('')
	const [svp_phoneddd, setSvp_phoneddd] = useState('')
	const [svp_phoneddi, setSvp_phoneddi] = useState('')
	const [svp_phone, setSvp_phone] = useState('')
	const [svp_mobilephoneddd, setSvp_mobilephoneddd] = useState('')
	const [svp_mobilephoneddi, setSvp_mobilephoneddi] = useState('')
	const [svp_mobilephone, setSvp_mobilephone] = useState('')
	const [svp_email, setSvp_email] = useState('')
	const [svp_observation, setSvp_observation] = useState('')
	const [svp_contracttime, setSvp_contracttime] = useState('')
	const [svp_datetimeinsert, setSvp_datetimeinsert] = useState('')
	const [svp_datetimeupdate, setSvp_datetimeupdate] = useState('')

	const [datetimeinsert, setDatetimeinsert] = useState('')
	const [datetimeupdate, setDatetimeupdate] = useState('')


	const [openModalIncluirNovo, setOpenModalIncluirNovo] = useState(false)
	const [confirmarUserDisabled, setConfirmarUserDisabled] = useState(false)
	const [cancelarDisabled, setCancelarDisabled] = useState(false)

	const [optionsServiceproviders, setOptionsServiceproviders] = useState([])

	const [loc_activeDisabled, setLoc_activeDisabled] = useState(false)

	const [svp_corporatenameDisabled, setsvp_corporatenameDisabled] = useState(false)

	const [svp_cnpjDisabled, setSvp_cnpjDisabled] = useState(false)
	const [svp_countryDisabled, setSvp_countryDisabled] = useState(false)
	const [svp_stateDisabled, setSvp_stateDisabled] = useState(false)
	const [svp_cityDisabled, setSvp_cityDisabled] = useState(false)
	const [svp_cityneighborhoodDisabled, setSvp_cityneighborhoodDisabled] = useState(false)
	const [svp_streettypeDisabled, setSvp_streettypeDisabled] = useState(false)
	const [svp_mobilephoneddiDisabled, setSvp_mobilephoneddiDisabled] = useState(false)
	const [svp_mobilephonedddDisabled, setSvp_mobilephonedddDisabled] = useState(false)
	const [svp_mobilephoneDisabled, setSvp_mobilephoneDisabled] = useState(false)
	const [svp_phonedddDisabled, setSvp_phonedddDisabled] = useState(false)
	const [svp_phoneddiDisabled, setSvp_phoneddiDisabled] = useState(false)
	const [svp_phoneDisabled, setSvp_phoneDisabled] = useState(false)
	const [svp_observationDisabled, setSvp_observationDisabled] = useState(false)
	const [svp_activeDisabled, setSvp_activeDisabled] = useState(false)
	const [svp_emailDisabled, setSvp_emailDisabled] = useState(false)
	const [svp_streetDisabled, setSvp_streetDisabled] = useState(false)
	const [svp_streetnumberDisabled, setSvp_streetnumberDisabled] = useState(false)
	const [svp_streetcomplementDisabled, setSvp_streetcomplementDisabled] = useState(false)
	const [svp_zipcodeDisabled, setSvp_zipcodeDisabled] = useState(false)




	useEffect(() => {

		setQualAlert(<div className='div-alert'><Loading /></div>)
		setOpenModal(true)


		let today = new Date();
		let date =
			today.getFullYear() +
			"-" +
			(today.getMonth() + 1).toString().padStart(2, "0") +
			"-" +
			today.getDate().toString().padStart(2, "0");
		let time =
			today.getHours().toString().padStart(2, "0") +
			":" +
			today.getMinutes().toString().padStart(2, "0");
		let JuntarDate = date + "T" + time;
		setDatetimeinsert(JuntarDate)

		validatToken();

	}, []);

	function fecharOpenModalIncluirNovo() {
		setOpenModalIncluirNovo(false)
	}

	async function validatToken() {

		let ret: AxiosResponse;
		let valor: any;

		const _user = secureLocalStorage.getItem('t')

		api.defaults.headers.common['Authorization'] = _user as ''

		try {

			ret = await api.get("/status/validar")

			if (ret.data.status === 200) {

				secureLocalStorage.setItem('t', ret.data.data);
				secureLocalStorage.setItem('acp', ret.data.decodedToken.acp_integrationid);
				secureLocalStorage.setItem('n', ret.data.decodedToken.name);
				secureLocalStorage.setItem('iag', ret.data.decodedToken.id);
				secureLocalStorage.setItem('cy', ret.data.decodedToken.company);
				GetServiceproviders()
				if (ret.data.decodedToken.acp_integrationid === 'DESENVOLVEDOR' || ret.data.decodedToken.acp_integrationid === 'ADMINISTRADOR') {


				} else if (ret.data.decodedToken.acp_integrationid === 'CONSULTOR') {

					setsvp_corporatenameDisabled(true)
					setSvp_cnpjDisabled(true)
					setSvp_countryDisabled(true)
					setSvp_stateDisabled(true)
					setSvp_cityDisabled(true)
					setSvp_cityneighborhoodDisabled(true)
					setSvp_streettypeDisabled(true)
					setSvp_mobilephoneddiDisabled(true)
					setSvp_mobilephonedddDisabled(true)
					setSvp_mobilephoneDisabled(true)
					setSvp_phonedddDisabled(true)
					setSvp_phoneddiDisabled(true)
					setSvp_phoneDisabled(true)
					setSvp_observationDisabled(true)
					setSvp_activeDisabled(true)
					setSvp_emailDisabled(true)
					setSvp_streetDisabled(true)
					setSvp_streetnumberDisabled(true)
					setSvp_streetcomplementDisabled(true)
					setSvp_zipcodeDisabled(true)

				}

				setOpenModal(false)

			}

		} catch (e: any) {

			if (e.response.data.status === 403) {

				setQualAlert(<div className='div-alert'>
					<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
					<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
					<div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
					<div className='btn-alert' onClick={fecharModalToken}><span className="btn-alert-lab">OK</span></div>
				</div>)


			}
		}

	}


	async function GetServiceproviders() {
		let ret: AxiosResponse;
		let valor: any;
		try {
			ret = await api.get("serviceproviders/verificacaoserviceproviders")
			if (ret.data.status === 200) {
				valor = ret.data.data;


				setOptionsServiceproviders(valor)

			}
		} catch (e) {
			setOptionsServiceproviders([])
		}
	}


	function fecharModalToken() {

		secureLocalStorage.removeItem('t')
		secureLocalStorage.removeItem('acp')
		secureLocalStorage.removeItem('n')
		secureLocalStorage.removeItem('iag')
		navigate('/login');
		setOpenModal(false)

	}



	const customStyles = {
		control: (provided: any, state: any) => ({
			...provided,
			background: '#fff',
			minHeight: '30px',
			height: '30px',
			borderColor: state.isFocused ? 'grey' : '#92989e',

		}),


		valueContainer: (provided: any, state: any) => ({
			...provided,
			height: '30px',
			padding: '0 6px'
		}),

		input: (provided: any, state: any) => ({
			...provided,
			margin: '0px',
			fontSize: 14,

		}),
		indicatorsContainer: (provided: any, state: any) => ({
			...provided,
			height: '30px',
		}),
	};

	function ValitarActive() {
		let e = document.getElementById("checkbox-active") as HTMLInputElement;

		if (e.checked) {

			setSvp_active(true)
		} else {

			setSvp_active(false)
		}
	}



	function ValitarEnablecall() {
		let e = document.getElementById("checkbox-enablecall") as HTMLInputElement;

		if (e.checked) {

			setSvp_enablecall(true)
		} else {

			setSvp_enablecall(false)
		}
	}


	function cancelar() {
		navigate('/serviceproviders');
	}



	function fecharModal() {
		setOpenModal(false)
	}




	const maskOnlyNumbers = (value: any) => {
		return value.replace(/\D/g, "");
	};



	function finalizarModal2() {
		setOpenModal(false)
	}

	//=================================ADD UNIDADE=============================================  

	async function confirmarLocal() {

		let ret: AxiosResponse;
		let valor: any;

		setQualAlert(<div className='div-alert'><Loading /></div>)
		setOpenModal(true)


		const _user = secureLocalStorage.getItem('t')

		api.defaults.headers.common['Authorization'] = _user as ''

		try {

			ret = await api.get("/status/validar")

			if (ret.data.status === 200) {

			

				const FiltrarNome = optionsServiceproviders.filter(
					(e: any) => {
						return e.svp_corporatename.toLowerCase() === svp_corporatename.toLowerCase()
					}
				);

			
				const FiltrarCnpj = optionsServiceproviders.filter(
					(e: any) => {
						return e.svp_cnpj.toLowerCase() === svp_cnpj.toLowerCase()
					}
				);

				if (svp_corporatename === "") {

					setQualAlert(<div className='div-alert'>
						<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
						<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
						<div className='div-info-alert'><span className="info-alert">Digite a razão social.</span></div>
						<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
					</div>)

				} else if (FiltrarNome.length > 0) {

					setQualAlert(<div className='div-alert'>
						<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
						<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
						<div className='div-info-alert'><span className="info-alert">Razão social já está em uso.</span></div>
						<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
					</div>)


				}

				else if (FiltrarCnpj.length > 0) {

					setQualAlert(<div className='div-alert'>
						<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
						<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
						<div className='div-info-alert'><span className="info-alert">CNPJ já está em uso.</span></div>
						<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
					</div>)
				}

				else if (svp_cnpj === "") {

					setQualAlert(<div className='div-alert'>
						<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
						<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
						<div className='div-info-alert'><span className="info-alert">Digite o CNPJ.</span></div>
						<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
					</div>)
				} else if (svp_email === "") {

					setQualAlert(<div className='div-alert'>
						<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
						<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
						<div className='div-info-alert'><span className="info-alert">Digite o e-mail.</span></div>
						<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
					</div>)

				}
				else {
					let ret: AxiosResponse;

					const dados = [{
						svp_active,
						svp_corporatename,
						svp_cnpj,
						svp_country,
						svp_state,
						svp_city,
						svp_cityneighborhood,
						svp_streettype,
						svp_street,
						svp_streetnumber,
						svp_streetcomplement,
						svp_zipcode,
						svp_phoneddd,
						svp_phoneddi,
						svp_phone,
						svp_mobilephoneddd,
						svp_mobilephoneddi,
						svp_mobilephone,
						svp_email,
						svp_observation,
						svp_contracttime,
						svp_enablecall,
						age_id_insert: secureLocalStorage.getItem('iag'),
					}];

					try {
						ret = await api.post("serviceproviders/insert", {
							data: dados,
						});
						if (ret.data.status === 200) {

							setQualAlert(<div className='div-alert'>
								<span className="fa-regular fa-circle-check ControleIconAlert"></span>
								<div className='div-info-alert'><span className="info-alert">Cadastro realizado com sucesso!</span></div>
								<div className='btn-alert' onClick={finalizarModal1}><span className="btn-alert-lab">OK</span></div>
							</div>)
						}
					} catch (e: any) {

						setQualAlert(<div className='div-alert'>
							<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
							<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
							<div className='div-info-alert'><span className="info-alert">Não foi possivel concluir o cadastramento.</span></div>
							<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
						</div>)


					}
				}

			}

		} catch (e: any) {

			if (e.response.data.status === 403) {

				setQualAlert(<div className='div-alert'>
					<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
					<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
					<div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
					<div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
				</div>)


			}
		}


	}

	function finalizarModal1() {
		navigate('/serviceproviders');
	}

	function validarcnpj(cnpj: any) {
		var valor = cnpj

		valor = valor + '';
		valor = valor.replace(/\D/g, "");
		valor = valor + '';

		if (valor == 'NaN') {
			setSvp_cnpj('');
		} else {
			if (valor.length > 14) {

			} else {
				setSvp_cnpj(valor.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5"))
			}

		}
	}

	return (
		<>
			<Nav />
			<div className="main-content">
				<Header />
				<main>
					<div className='containerPrincipal'>



						<div className="title-Page">
							<div className="title-text">
								<div className="size-x circle">1</div>
								<h2>Informações Gerais</h2>
							</div>
							<hr />
						</div>


						<div className="container-cad-prod44">
							<div id="content1">
								<div className="class-checkbox-user2">
									<input type="checkbox" id="checkbox-active" 
									checked={svp_active} onChange={ValitarActive} disabled={svp_activeDisabled} />
									<h4 className="lab-ativo">Ativo</h4>
								</div>
							</div>

							<div id="content2">
								<div className="class-checkbox-user2">
									<input type="checkbox" id="checkbox-enablecall" checked={svp_enablecall} onChange={ValitarEnablecall} disabled={svp_activeDisabled} />
									<h4 className="lab-ativo">Habilitar abertura de chamado</h4>
								</div>
							</div>

						</div>


						<div className="container-cad-prod37">
							<div id="content1">
								<div className="input-box-ativos">
									<span className="details">Razão Social *</span>
									<input
										type="text"
										onChange={(e) => setSvp_corporatename(e.target.value)}
										value={svp_corporatename}
										autoComplete="off"
										disabled={svp_corporatenameDisabled}
									/>

								</div>
							</div>

							<div id="content2">
								<div className="input-box-ativos">
									<span className="details">CNPJ *</span>
									<input
										type="text"
										onChange={(e) => validarcnpj(e.target.value)}
										value={svp_cnpj}
										autoComplete="off"
										disabled={svp_cnpjDisabled}
									/>
								</div>
							</div>

							<div id="content3">
								<div className="input-box-ativos">
									<span className="details">E-mail *</span>
									<input
										type="text"
										onChange={(e) => setSvp_email(e.target.value)}
										value={svp_email}
										autoComplete="off"
										disabled={svp_emailDisabled}
									/>
								</div>
							</div>

						</div>




						<div className="container-cad-prod36">


							<div id="content1">
								<div className="input-box-ativos">
									<span className="details">DDI</span>
									<input
										autoComplete="off"
										maxLength={3}
										type="text"
										onChange={(e) => setSvp_mobilephoneddi(maskOnlyNumbers(e.target.value))}
										value={svp_mobilephoneddi}
										disabled={svp_mobilephoneddiDisabled}
									/>
								</div>
							</div>


							<div id="content2">
								<div className="input-box-ativos">
									<span className="details">DDD</span>
									<input
										autoComplete="off"
										maxLength={3}
										type="text"
										onChange={(e) => setSvp_mobilephoneddd(maskOnlyNumbers(e.target.value))}
										value={svp_mobilephoneddd}
										disabled={svp_mobilephonedddDisabled}
									/>
								</div>
							</div>

							<div id="content3">
								<div className="input-box-ativos">
									<span className="details">Celular</span>
									<input
										autoComplete="off"
										maxLength={18}
										type="text"
										onChange={(e) => setSvp_mobilephone(maskOnlyNumbers(e.target.value))}
										value={svp_mobilephone}
										disabled={svp_mobilephoneDisabled}
									/>
								</div>
							</div>

							<div id="content4">
								<div className="input-box-ativos">
									<span className="details">DDI</span>
									<input
										autoComplete="off"
										maxLength={3}
										type="text"
										onChange={(e) => setSvp_phoneddi(maskOnlyNumbers(e.target.value))}
										value={svp_phoneddi}
										disabled={svp_phoneddiDisabled}
									/>
								</div>
							</div>

							<div id="content5">
								<div className="input-box-ativos">
									<span className="details">DDD</span>
									<input
										autoComplete="off"
										maxLength={3}
										type="text"
										onChange={(e) => setSvp_phoneddd(maskOnlyNumbers(e.target.value))}
										value={svp_phoneddd}
										disabled={svp_phonedddDisabled}
									/>
								</div>
							</div>

							<div id="content6">
								<div className="input-box-ativos">
									<span className="details">Telefone</span>
									<input
										autoComplete="off"
										maxLength={18}
										type="text"
										onChange={(e) => setSvp_phone(maskOnlyNumbers(e.target.value))}
										value={svp_phone}
										disabled={svp_phoneDisabled}
									/>
								</div>
							</div>

							<div id="content7">
								<div className="input-box-ativos">
									<span className="details">Data da inclusão</span>
									<input
										type="datetime-local"
										id="DATA-TIME"
										name="DATE-TIME"
										disabled
										value={datetimeinsert}
									/>
								</div>
							</div>
							<div id="content8">
								<div className="input-box-ativos">
									<span className="details">Data da última atualização</span>
									<input
										type="datetime-local"
										id="DATA-TIME"
										name="DATE-TIME"
										disabled
										value={datetimeupdate}
									/>
								</div>
							</div>

						</div>




						<div className="container-cad-prod3">
							<div className="textareaBox-ativo">
								<span className="details">Observação</span>
								<textarea
									onChange={(e) => setSvp_observation(e.target.value)}
									value={svp_observation}
									disabled={svp_observationDisabled}
								/>
							</div>
						</div>

						<div className="title-Page">
							<div className="title-text">
								<div className="size-x circle">2</div>
								<h2>Endereço</h2>
							</div>
							<hr />
						</div>

						<div className="container-cad-prod6">
							<div id="content1">
								<div className="input-box-ativos">
									<span className="details">País</span>
									<input
										autoComplete="off"
										type="text"
										onChange={(e) => setSvp_country(e.target.value)}
										value={svp_country}
										disabled={svp_countryDisabled}
									/>

								</div>
							</div>
							<div id="content2">
								<div className="input-box-ativos">
									<span className="details">Estado</span>
									<input
										type="text"
										onChange={(e) => setSvp_state(e.target.value)}
										value={svp_state}
										autoComplete="off"
										disabled={svp_stateDisabled}
									/>

								</div>
							</div>
							<div id="content3">
								<div className="input-box-ativos">
									<span className="details">Município</span>
									<input
										type="text"
										onChange={(e) => setSvp_city(e.target.value)}
										value={svp_city}
										autoComplete="off"
										disabled={svp_cityDisabled}
									/>
								</div>
							</div>
							<div id="content4">
								<div className="input-box-ativos">
									<span className="details">Bairro</span>
									<input
										type="text"
										onChange={(e) => setSvp_cityneighborhood(e.target.value)}
										value={svp_cityneighborhood}
										autoComplete="off"
										disabled={svp_cityneighborhoodDisabled}
									/>

								</div>
							</div>
						</div>

						<div className="container-cad-prod38">
							<div id="content1">
								<div className="input-box-ativos">
									<span className="details">Tipo logradouro</span>
									<input
										type="text"
										onChange={(e) => setSvp_streettype(e.target.value)}
										value={svp_streettype}
										autoComplete="off"
										disabled={svp_streettypeDisabled}
									/>

								</div>
							</div>
							<div id="content2">
								<div className="input-box-ativos">
									<span className="details">Logradouro</span>
									<input
										type="text"
										onChange={(e) => setSvp_street(e.target.value)}
										value={svp_street}
										autoComplete="off"
										disabled={svp_streetDisabled}
									/>

								</div>
							</div>
							<div id="content3">
								<div className="input-box-ativos">
									<span className="details">Número</span>
									<input
										type="text"
										onChange={(e) => setSvp_streetnumber(e.target.value)}
										value={svp_streetnumber}
										autoComplete="off"
										disabled={svp_streetnumberDisabled}
									/>
								</div>
							</div>
							<div id="content4">
								<div className="input-box-ativos">
									<span className="details">Complemento</span>
									<input
										type="text"
										onChange={(e) => setSvp_streetcomplement(e.target.value)}
										value={svp_streetcomplement}
										autoComplete="off"
										disabled={svp_streetcomplementDisabled}
									/>
								</div>
							</div>
							<div id="content5">
								<div className="input-box-ativos">
									<span className="details">CEP</span>
									<input
										type="text"
										onChange={(e) => setSvp_zipcode(e.target.value)}
										value={svp_zipcode}
										autoComplete="off"
										disabled={svp_zipcodeDisabled}
									/>
								</div>
							</div>
						</div>
						<div className="Bt-button-cad">
							<button type="submit" disabled={confirmarUserDisabled} onClick={confirmarLocal}>
								<i className="fa fa-check-circle icon-ok-btn"></i> Salvar
							</button>
							<button type="submit" onClick={cancelar} disabled={cancelarDisabled}>
								<i className="fa fa-times icon-cancelar-btn"></i> Cancelar
							</button>
						</div>
					</div>




				</main>
			</div>


			<ModalIncluirNovo isOpen={openModalIncluirNovo} setModalOpen={() => setOpenModalIncluirNovo(!openModalIncluirNovo)}>
				<div className='title-modal-incluir'>
					<h1 className="modal-IncluirNovo-title">{titulo}</h1>
					<button className="fa fa-times icone-modal-IncluirNovo" data-dismiss="modal" aria-label="Close" onClick={fecharOpenModalIncluirNovo}></button>
				</div>
				<div className='scrol-modal'>

					{qualGrid}

				</div>
			</ModalIncluirNovo>


			<ModalAlert isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)}>
				<div className='container-modal-alert'>
					{qualAlert}
				</div>
			</ModalAlert>



		</>
	);

}

export default PrestadoresAdd;