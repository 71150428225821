import React from 'react'
import { useNavigate } from 'react-router'

function PermissionDenied() {

	const navigate = useNavigate();

	function Voltar() {
       
        navigate('/dashboard');
     
    }

	return (
		<>


			<div className='containerPrincipalpage'>
				<i className="fa fa-exclamation-triangle icon404" aria-hidden="true"></i>
				<h1 className="titlepage1">PÁGINA NÃO ENCONTRADA</h1>

				<h1 className="titlepage2">Você não tem permissão para acessar esse conteúdo.</h1>

				<div className="container-cad-prod2">
					<div id="content1">

					</div>
					<div id="content2">
							<div className='btn-ini' >
								<div className='btn-ini2' onClick={Voltar}>
									<h1 className='btn-text2'>Início</h1>
								</div>
							</div>
					</div>
					<div id="content3">

					</div>
				</div>

			</div>


		</>
	);

}

export default PermissionDenied;