import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import Dashboard from "../pages/Dashboard"
import Login from "../pages/Login";
import NoPage from "../pages/NoPage"
import ForgotPassword from '../pages/ForgotPassword'

import Usuarios from '../pages/Usuarios'
import UsuariosAdd from "../pages/UsuariosAdd";
import UsuariosEdit from "../pages/UsuariosEdit";

import Produto from "../pages/Produto";
import ProdutoAdd from "../pages/ProdutoAdd"
import ProdutoEdit from '../pages/ProdutoEdit'

import Asset from '../pages/asset'
import AssetAdd from '../pages/assetAdd'
import AssetEdit from '../pages/assetEdit'
import AssetVisualizacao from '../pages/assetVisualizacao'

import NotaFiscal from '../pages/NotaFiscal'
import NotaFiscalAdd from '../pages/NotaFiscalAdd'
import NotaFiscalEdit from '../pages/NotaFiscalEdit'
import DetalhamentoItensNotas from '../pages/DetalhamentoItensNotas'

import MeusCadastros from '../pages/MeusCadastros'

import Mobilemyrecords from '../pages/Mobilemyrecords'

import Movimentacoes from '../pages/Movimentacoes'
import MovimentacoesAdd from '../pages/MovimentacoesAdd'

import Relatorios from '../pages/Relatorios'

import Tickets from '../pages/Tickets'

import MobileInventory from '../pages/MobileInventory'
import MobileInventoryAdd from "../pages/MobileInventoryAdd"
import MobileInventoryEdit from "../pages/MobileInventoryEdit"

import Tarefas from "../pages/Tarefas"
import TarefasAdd from "../pages/TarefasAdd"
import TarefasEdit from "../pages/TarefasEdit"

import Team from '../pages/Team'
import TeamAdd from "../pages/TeamAdd";
import TeamEdit from "../pages/TeamEdit"

import Activity from '../pages/Activity'
import ActivityAdd from "../pages/ActivityAdd";
import ActivityEdit from "../pages/ActivityEdit";

import TaskDirection from '../pages/TaskDirection'

import Importdata from "../pages/ImportData"

import ProtectedRoutes from "./ProtectedRoutes"
import PublicRoutes from "./PublicRoutes"
import PermissionDenied from "./PermissionDenied"
import InnerContent from "./InnerContent"

import Planner from "../pages/Planner"
import PlannerAdd from "../pages/PlannerAdd"
import PlannerEdit from "../pages/PlannerEdit"

import PainelGestao from "../pages/PainelGestao"
import VisualizarTask from "../pages/visualizarTask"

import Local from "../pages/Local"
import LocalAdd from "../pages/LocalAdd"
import LocalEdit from "../pages/LocalEdit"
import LocalVisualizacao from '../pages/localVisualizacao'

import Serviceproviders from "../pages/Prestadores"
import ServiceprovidersAdd from "../pages/PrestadoresAdd"
import ServiceprovidersEdit from "../pages/PrestadoresEdit"
import ServiceprovidersVisualizacao from "../pages/PrestadoresVisualizacao"

import Itens from "../pages/Itens"
import ItensAdd from "../pages/ItensAdd"
import ItensEdit from "../pages/ItensEdit"

import ActivityCalendar from "../pages/ActivityCalendar"
import Relatorios2 from "../pages/Relatorios2"

import Partmaterial from "../pages/Partmaterial"
import PartmaterialAdd from "../pages/PartmaterialAdd"
import PartmaterialEdit from "../pages/PartmaterialEdit"

import Servicos from "../pages/Servicos"
import ServicosAdd from "../pages/ServicosAdd"
import ServicosEdit from "../pages/ServicosEdit"

import Configuracoes from "../pages/Configuracoes"
import ImportData from "../pages/ImportData"
import ExportarData from "../pages/ExportarData"

import PerfilAcesso from "../pages/PerfilAcesso"
import PerfilacessoAdd from "../pages/perfilacessoAdd"
import PerfilacessoEdit from "../pages/perfilacessoEdit"
import PerfilacessoAddCopy from "../pages/perfilacessoAddCopy"


export const Router = () => {

  return (
    <BrowserRouter>
      <Routes>

        <Route path="/" element={<ProtectedRoutes />}>
          <Route path="/" element={<InnerContent />}>


          <Route path="/" element={<Navigate replace to="dashboard" />} />
            <Route path="dashboard" element={<Dashboard />} />


            <Route element={<ProtectedRoutes roleRequired="Usuários" />}>
              <Route path="/user" element={<Usuarios />} />
            </Route>

            <Route element={<ProtectedRoutes roleRequired="Usuários" />}>
              <Route path="/user/add" element={<UsuariosAdd />} />
            </Route>

   
            <Route element={<ProtectedRoutes roleRequired="Usuários" />}>
              <Route path="/user/edit/:id" element={<UsuariosEdit />} />
            </Route>
            
            <Route element={<ProtectedRoutes roleRequired="Produto" />}>
              <Route path="/product" element={<Produto />} />
            </Route>
            <Route element={<ProtectedRoutes roleRequired="Produto" />}>
              <Route path="/product/add" element={<ProdutoAdd/>} />
            </Route>
            <Route element={<ProtectedRoutes roleRequired="Produto" />}>
              <Route path="/product/edit/:id" element={<ProdutoEdit/>} />
            </Route>
          
            <Route element={<ProtectedRoutes roleRequired="Ativos" />}>
              <Route path="/asset" element={<Asset/>} />
            </Route>
            <Route element={<ProtectedRoutes roleRequired="Ativos" />}>
              <Route path="/asset/add" element={<AssetAdd/>} />
            </Route>
            <Route element={<ProtectedRoutes roleRequired="Ativos" />}>
              <Route path="/asset/edit/:id" element={<AssetEdit/>} />
            </Route>
            <Route element={<ProtectedRoutes roleRequired="Ativos" />}>
              <Route path="/asset/visualizar/:id" element={<AssetVisualizacao/>} />
            </Route>
        
            <Route element={<ProtectedRoutes roleRequired="Notas Fiscais" />}>
              <Route path="/invoice" element={<NotaFiscal/>} />
            </Route>
            <Route element={<ProtectedRoutes roleRequired="Notas Fiscais" />}>
              <Route path="/invoice/add" element={<NotaFiscalAdd/>} />
            </Route>
            <Route element={<ProtectedRoutes roleRequired="Notas Fiscais" />}>
              <Route path="/invoice/edit/:id" element={<NotaFiscalEdit/>} />
            </Route>
            <Route element={<ProtectedRoutes roleRequired="Notas Fiscais" />}>
              <Route path="/invoice/detailingitemsnotes/:id" element={<DetalhamentoItensNotas/>} />
            </Route>

            <Route element={<ProtectedRoutes roleRequired="Meus Cadastros" />}>
              <Route path="/customentity" element={<MeusCadastros/>} />
            </Route>

            <Route element={<ProtectedRoutes roleRequired="Cadastros Customizados" />}>
              <Route path="/customregistrations" element={<Mobilemyrecords/>} />
            </Route>

            <Route element={<ProtectedRoutes roleRequired="Movimentações" />}>
              <Route path="/movement" element={<Movimentacoes/>} />
            </Route>
            <Route element={<ProtectedRoutes roleRequired="Movimentações" />}>
              <Route path="/movement/add" element={<MovimentacoesAdd/>} />
            </Route>

            <Route element={<ProtectedRoutes roleRequired="Relatórios" />}>
              <Route path="/report" element={<Relatorios/>} />
            </Route>
           
            <Route element={<ProtectedRoutes roleRequired="Inventário Mobile" />}>
              <Route path="/mobileinventory" element={<MobileInventory/>} />
            </Route>
            <Route element={<ProtectedRoutes roleRequired="Inventário Mobile" />}>
              <Route path="/mobileinventory/add" element={<MobileInventoryAdd/>} />
            </Route>
            <Route element={<ProtectedRoutes roleRequired="Inventário Mobile" />}>
              <Route path="/mobileinventory/edit/:id" element={<MobileInventoryEdit/>} />
            </Route>

            <Route element={<ProtectedRoutes roleRequired="Tarefas" />}>
              <Route path="/task" element={<Tarefas/>} />
            </Route>
            <Route element={<ProtectedRoutes roleRequired="Tarefas" />}>
              <Route path="/task/add" element={<TarefasAdd/>} />
            </Route>
            <Route element={<ProtectedRoutes roleRequired="Tarefas" />}>
              <Route path="/task/edit/:id" element={<TarefasEdit/>} />
            </Route>

            <Route element={<ProtectedRoutes roleRequired="Equipes" />}>
              <Route path="/team" element={<Team/>} />
            </Route>
            <Route element={<ProtectedRoutes roleRequired="Equipes" />}>
              <Route path="/team/add" element={<TeamAdd/>} />
            </Route>
            <Route element={<ProtectedRoutes roleRequired="Equipes" />}>
              <Route path="/team/edit/:id" element={<TeamEdit/>} />
            </Route>

            <Route element={<ProtectedRoutes roleRequired="Atividades" />}>
              <Route path="/activity" element={<Activity/>} />
            </Route>
            <Route element={<ProtectedRoutes roleRequired="Atividades" />}>
              <Route path="/activity/add" element={<ActivityAdd/>} />
            </Route>
            <Route element={<ProtectedRoutes roleRequired="Atividades" />}>
              <Route path="/activity/edit/:id" element={<ActivityEdit/>} />
            </Route>
         
            <Route element={<ProtectedRoutes roleRequired="Tarefas" />}>
              <Route path="/taskdirection" element={<TaskDirection/>} />
            </Route>

            <Route element={<ProtectedRoutes roleRequired="Planos Automáticos" />}>
              <Route path="/planner" element={<Planner/>} />
            </Route>

            <Route element={<ProtectedRoutes roleRequired="Planos Automáticos" />}>
              <Route path="/planner/add" element={<PlannerAdd/>} />
            </Route>
            <Route element={<ProtectedRoutes roleRequired="Planos Automáticos" />}>
              <Route path="/planner/edit/:id" element={<PlannerEdit/>} />
            </Route>

            <Route element={<ProtectedRoutes roleRequired="DESENVOLVEDOR,ADMINISTRADOR,CONSULTOR" />}>
              <Route path="/managementpanel" element={<PainelGestao/>} />
            </Route>
            
            <Route element={<ProtectedRoutes roleRequired="Importar dados" />}>
              <Route path="/importdata" element={<Importdata/>} />
            </Route>

            <Route element={<ProtectedRoutes roleRequired="Tarefas" />}>
              <Route path="/task/report/:id" element={<VisualizarTask/>} />
            </Route>

            <Route element={<ProtectedRoutes roleRequired="Locais" />}>
              <Route path="/local" element={<Local/>} />
            </Route>
            <Route element={<ProtectedRoutes roleRequired="Locais" />}>
              <Route path="/local/add" element={<LocalAdd/>} />
            </Route>
            <Route element={<ProtectedRoutes roleRequired="Locais" />}>
              <Route path="/local/edit/:id" element={<LocalEdit/>} />
            </Route>
            <Route element={<ProtectedRoutes roleRequired="Locais" />}>
              <Route path="/local/visualizar/:id" element={<LocalVisualizacao/>} />
            </Route>
       
            <Route element={<ProtectedRoutes roleRequired="Terceiros" />}>
              <Route path="/serviceproviders" element={<Serviceproviders/>} />
            </Route>
            <Route element={<ProtectedRoutes roleRequired="Terceiros" />}>
              <Route path="/serviceproviders/add" element={<ServiceprovidersAdd/>} />
            </Route>
            <Route element={<ProtectedRoutes roleRequired="Terceiros" />}>
              <Route path="/serviceproviders/edit/:id" element={<ServiceprovidersEdit/>} />
            </Route>
            <Route element={<ProtectedRoutes roleRequired="Terceiros" />}>
              <Route path="/serviceproviders/visualizar/:id" element={<ServiceprovidersVisualizacao/>} />
            </Route>
            
            <Route element={<ProtectedRoutes roleRequired="Itens da Atividade" />}>
              <Route path="/item" element={<Itens/>} />
            </Route>
            <Route element={<ProtectedRoutes roleRequired="Itens da Atividade" />}>
              <Route path="/item/add" element={<ItensAdd/>} />
            </Route>
            <Route element={<ProtectedRoutes roleRequired="Itens da Atividade" />}>
              <Route path="/item/edit/:id" element={<ItensEdit/>} />
            </Route>

            <Route element={<ProtectedRoutes roleRequired="Calendário Atividades" />}>
              <Route path="/activitycalendar" element={<ActivityCalendar/>} />
            </Route>
            
            <Route element={<ProtectedRoutes roleRequired="Peças e Materiais" />}>
              <Route path="/partmaterial" element={<Partmaterial/>} />
            </Route>

            <Route element={<ProtectedRoutes roleRequired="Peças e Materiais" />}>
              <Route path="/partmaterial/add" element={<PartmaterialAdd/>} />
            </Route>

            <Route element={<ProtectedRoutes roleRequired="Peças e Materiais" />}>
              <Route path="/partmaterial/edit/:id" element={<PartmaterialEdit/>} />
            </Route>

            <Route element={<ProtectedRoutes roleRequired="Serviços" />}>
              <Route path="/services" element={<Servicos/>} />
            </Route>
            
            <Route element={<ProtectedRoutes roleRequired="Serviços" />}>
              <Route path="/services/add" element={<ServicosAdd/>} />
            </Route>

            <Route element={<ProtectedRoutes roleRequired="Serviços" />}>
              <Route path="/services/edit/:id" element={<ServicosEdit/>} />
            </Route>

            <Route element={<ProtectedRoutes roleRequired="DIRECIONADOR,DESENVOLVEDOR,ADMINISTRADOR,CONSULTOR" />}>
              <Route path="/tickets" element={<Tickets/>} />
            </Route>

            <Route element={<ProtectedRoutes roleRequired="Configurações" />}>
              <Route path="/settings" element={<Configuracoes/>} />
            </Route>

            <Route element={<ProtectedRoutes roleRequired="Importar dados" />}>
              <Route path="/importer" element={<ImportData/>} />
            </Route>
            
            <Route element={<ProtectedRoutes roleRequired="Exportar dados" />}>
              <Route path="/exporter" element={<ExportarData/>} />
            </Route>
            
            <Route element={<ProtectedRoutes roleRequired="Perfil de Acesso" />}>
              <Route path="/accessprofile" element={<PerfilAcesso/>} />
            </Route>
            
            <Route element={<ProtectedRoutes roleRequired="Ativos" />}>
              <Route path="/accessprofile/add" element={<PerfilacessoAdd/>} />
            </Route>

            <Route element={<ProtectedRoutes roleRequired="Serviços" />}>
              <Route path="/accessprofile/edit/:id" element={<PerfilacessoEdit/>} />
            </Route>

            <Route element={<ProtectedRoutes roleRequired="Serviços" />}>
              <Route path="/accessprofile/add/copy/:id" element={<PerfilacessoAddCopy/>} />
            </Route>
            

          </Route>

        </Route>

        <Route path="login" element={<PublicRoutes />}>
          <Route path="/login" element={<Login />} />
        </Route>
      

        <Route path="task/report/:company/:token/:id" element={<VisualizarTask />} />



        <Route path="passwordrecover" element={<PublicRoutes />}>
          <Route path="/passwordrecover" element={<ForgotPassword />} />
        </Route>

        
        <Route path="/denied" element={<PermissionDenied />} />
        <Route path="*" element={<NoPage />} />



      </Routes>
    </BrowserRouter>
  )
}
export default Router
