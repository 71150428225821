import React, { useEffect, useState, useRef } from 'react'
import Nav from "../../components/Nav";
import Header from "../../components/Header";
import api from "../../Services/api";
import { AxiosResponse } from "axios";
import Gridmobilemyrecords from '../../components/Gridmobilemyrecords'
import FormMeusCadastros from '../../components/FormMeusCadastros'
import FormMyrecordsvalue from '../../components/FormMyrecordsvalue'
import Loading from '../../components/Loading';
import secureLocalStorage from "react-secure-storage";
import ModalPrincipal from '../../components/ModalAlert/modal'
import ModalIncluirNovo from '../../components/ModalIncluirNovo/modal'
import { useNavigate } from 'react-router';
import { FaListCheck } from "react-icons/fa6";

function Myrecords() {

    const navigate = useNavigate();

    const [openModal, setOpenModal] = useState(false)
    const [qualAlert, setQualAlert] = useState(<></>)
    const [openModalIncluirNovo, setOpenModalIncluirNovo] = useState(false)
    const [exibirGrid, setExibirGrid] = useState(<></>);

    const [qualTitulo, setQualTitulo] = useState('')
	const [qualGrid, setQualGrid] = useState(<></>)
  

    useEffect(() => {
       // setQualAlert(<div className='div-alert'><Loading /></div>)
		//setOpenModal(true)
        validatToken();
    }, []);

    async function validatToken() {

        let ret: AxiosResponse;
        let valor: any;

        const _user = secureLocalStorage.getItem('t')


        api.defaults.headers.common['Authorization'] = _user as ''

        try {

            ret = await api.get("/status/validar")

            if (ret.data.status === 200) {

                secureLocalStorage.setItem('t', ret.data.data);
                secureLocalStorage.setItem('acp', ret.data.decodedToken.acp_integrationid);
                secureLocalStorage.setItem('n', ret.data.decodedToken.name);
                secureLocalStorage.setItem('iag', ret.data.decodedToken.id);
                secureLocalStorage.setItem('cy', ret.data.decodedToken.company);

             setExibirGrid(<Gridmobilemyrecords
                            th1='Descrição'
                            th2='Identificador Alternativo'
                            endPoint='mobilemyrecords/buscarmobilemyrecords'
                            consult='N'
                            col1='0'
                            col2='2'
                            col3='3'
                            ambiente='%'
                            converter="S"
                            ChamarModalCad={ChamarModalCad}
                            ChamarCadastro={ChamarCadastro}
                            IconeEditar={IconeEditar}
                            params={null}
                            Visualizar={'S'}
                            pag="ok"
                            PegarValorGrid={PegarValorGrid}
                            fecharModal={fecharModal}
                        />)

                        setOpenModal(false)
            }

        } catch (e: any) {

            if (e.response.data.status === 403) {

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
                    <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)

            }
        }

    }

    function fecharModal() {

        secureLocalStorage.removeItem('t')
        secureLocalStorage.removeItem('acp')
        secureLocalStorage.removeItem('n')
        secureLocalStorage.removeItem('iag')
        navigate('/login');
        setOpenModal(false)

    }



    function IconeEditar(obj: any){
        sessionStorage.setItem("dadosMeusCadastro", JSON.stringify(obj));
        setQualTitulo('Cadastros Customizados')
        setQualGrid(<><FormMeusCadastros/></>)
        setOpenModalIncluirNovo(true)
    }


    function ChamarCadastro(){

        sessionStorage.removeItem("dadosMeusCadastro");
            setQualTitulo('Cadastros Customizados')
			setQualGrid(<><FormMeusCadastros/></>)
            setOpenModalIncluirNovo(true)
    }

//--------------------CADASTRO----------------------------------------------------

    function ChamarModalCad(obj: any) {

        sessionStorage.setItem("idcadastro", JSON.stringify(obj.mmc_id));
        
        let titulo = obj.mmc_description
        setQualGrid(<><Loading/></>)
        setQualTitulo(titulo)
        setQualGrid(<Gridmobilemyrecords
            th1='Descrição'
            th2='Identificador Alternativo'
            endPoint='mobilemyrecordsvalue/buscarmyrecordsValue'
            consult='N'
            col1='0'
            col2='2'
            col3='3'
            ambiente='%'
            converter="S"
            ChamarModalCad={ChamarModalCad}
            ChamarCadastro={ChamarCadastroMyrecordsvalue}
            IconeEditar={IconeEditarMyrecordsvalue}
            params={obj.mmc_id}
            IconeExcluir={ModalDeletar}
            PegarValorGrid={PegarValorGrid}
            btnDelete={'S'}
             pag="ok"
          />)
          setOpenModalIncluirNovo(true)
        
    }

    function PegarValorGrid(){

    }
    
    function ChamarCadastroMyrecordsvalue(){     
        sessionStorage.removeItem("dadosCadastro");
        setQualTitulo('Cadastros')
        setQualGrid(<><FormMyrecordsvalue voltar={BtnVoltarMyrecordsvalue}/></>)
        setOpenModalIncluirNovo(true)
  
    }


    function IconeEditarMyrecordsvalue(obj: any){
        sessionStorage.setItem("dadosCadastro", JSON.stringify(obj));
        setQualTitulo('Cadastros Customizados')
        setQualGrid(<><FormMyrecordsvalue voltar={BtnVoltarMyrecordsvalue}/></>)
        setOpenModalIncluirNovo(true)
    }

    function BtnVoltarMyrecordsvalue(obj: any) {

        setQualGrid(<Gridmobilemyrecords
            th1='Descrição'
            th2='Identificador Alternativo'
            endPoint='mobilemyrecordsvalue/buscarmyrecordsValue'
            consult='N'
            col1='0'
            col2='2'
            col3='3'
            ambiente='%'
            converter="S"
            ChamarModalCad={ChamarModalCad}
            ChamarCadastro={ChamarCadastroMyrecordsvalue}
            IconeEditar={IconeEditarMyrecordsvalue}
            params={obj}
            IconeExcluir={ModalDeletar}
            btnDelete={'S'}
             pag="ok"
             PegarValorGrid={PegarValorGrid}
          />)



	
	}

    function ModalDeletar(obj: any) {
    
        const _type = secureLocalStorage.getItem('acp')

        if (_type === 'DESENVOLVEDOR' || _type === 'ADMINISTRADOR') {
            setOpenModal(true)

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">Deseja deletar o registro?</span></div>
                <div className='div-info-alert'><span className="info-alert">Você não poderá reverter.</span></div>

                <div className='btn-alert-div'>
                    <span className='btn-alert-esc' onClick={() => deletarCadastro(obj)}>
                        <span className="btn-alert-lab">SIM</span>
                    </span>
                    <span className='btn-alert-dir' onClick={finalizarModal2}>
                        <span className="btn-alert-lab">NÃO</span>
                    </span>
                </div>
            </div>
            )
        } else if (_type === 'CONSULTOR') {
            setOpenModal(true)
            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">O seu perfil de acesso não permite deletar registros.</span></div>
                <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
             </div>)

        }

    }

    function finalizarModal2() {
        setOpenModal(false)
    }

    async function deletarCadastro(obj: any,) {
        let ret: AxiosResponse;
    

        setQualGrid(<><Loading/></>)
        try {
            ret = await api.delete("mobilemyrecordsvalue/delete", {
                data: { id: obj.mrv_id}
            });
            if (ret.data.status === 200) {
                setOpenModal(false)

                setQualGrid(<Gridmobilemyrecords
                    th1='Descrição'
                    th2='Identificador Alternativo'
                    endPoint='mobilemyrecordsvalue/buscarmyrecordsValue'
                    consult='N'
                    col1='0'
                    col2='2'
                    col3='3'
                    ambiente='%'
                    converter="S"
                    ChamarModalCad={ChamarModalCad}
                    ChamarCadastro={ChamarCadastroMyrecordsvalue}
                    IconeEditar={IconeEditarMyrecordsvalue}
                    params={obj.mmc_id}
                    IconeExcluir={ModalDeletar}
                    btnDelete={'S'}
                     pag="ok"
                     PegarValorGrid={PegarValorGrid}
                  />)
                  
               

                
            }
        } catch (e: any) {
            setOpenModal(false)


            setQualGrid(<Gridmobilemyrecords
                th1='Descrição'
                th2='Identificador Alternativo'
                endPoint='mobilemyrecordsvalue/buscarmyrecordsValue'
                consult='N'
                col1='0'
                col2='2'
                col3='3'
                ambiente='%'
                converter="S"
                ChamarModalCad={ChamarModalCad}
                ChamarCadastro={ChamarCadastroMyrecordsvalue}
                IconeEditar={IconeEditarMyrecordsvalue}
                params={obj.mmc_id}
                IconeExcluir={ModalDeletar}
                btnDelete={'S'}
                 pag="ok"
                 PegarValorGrid={PegarValorGrid}
              />)
              
        }
        setOpenModal(false)
        
    }

    function fecharOpenModalIncluirNovo() {
		setOpenModalIncluirNovo(false)
	}

    return (
        <>
        <Nav />
        <div className="main-content">
        <Header />
         <main>
            <div className='containerPrincipal'>
            <div className='div-titulo-pages'>
                    <FaListCheck />
                    <h1 className='div-titulo-pages-text'>Cadastros Customizados</h1>
                </div>
                 {exibirGrid}
            </div>
            </main>  
         </div>


         <ModalIncluirNovo isOpen={openModalIncluirNovo} setModalOpen={() => setOpenModalIncluirNovo(!openModalIncluirNovo)}>
				<>
					<div className='title-modal-incluir'>
						<h1 className="modal-IncluirNovo-title">{qualTitulo}</h1>
                        <div  onClick={fecharOpenModalIncluirNovo} >
						<button className="fa fa-times icone-modal-IncluirNovo" data-dismiss="modal" aria-label="Close" onClick={fecharOpenModalIncluirNovo}></button>
                        </div>
                    </div>

                    <div className='scrol-modal'>
						{qualGrid}
					</div>
				</>
			</ModalIncluirNovo>

			<ModalPrincipal isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)}>

				<div className='container-modal-alert'>
					{qualAlert}
				</div>
			</ModalPrincipal>

        </>
    );

}

export default Myrecords;